<template>
  <div>
    <tasks-table
      :project_id="Number(milestone.project_id)"
      :milestone_id="Number($route.params.mid)"
      :from="'Milestone Task'" 
      :showProject="'true'" 
      :task_link="'project-milestone-tasks-preview-show'"
    ></tasks-table>
    <router-view></router-view>
  </div>
</template>
<script>
import TasksTable from "@/components/Tables/Tasks/Index";
export default {
  components: {  TasksTable },
  data() {
    return {
      task: {
        id: 1
      },
      loaded: false,
      breadCrumbs: [
        {
          text: "Home",
          href: "#"
        },
        {
          text: "Milestones",
          active: true
        },
        {
          text: "Milestone",
          active: true
        }
      ]
    };
  },
  computed: {
    milestone() {
      return this.$store.getters["projects/milestone"];
    },
    milestoneStatuses() {
      return this.$store.getters["global/milestone_statuses"];
    }
  },
  created() {
    Promise.all([
      this.$store.dispatch("projects/getMilestone", this.$route.params.mid),
      this.$store.dispatch("global/getMilestoneStatuses")
    ]).finally(() => {
      this.loaded = true;
    });
  }
};
</script>
