<template>
  <div class="row p-4 pt-4 pb-5" v-if="loaded">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-sm-12" style>
          <h6 class="h7 text-opacity-pale text-bold">TASK DETAILS</h6>
        </div>
        <div class="col-sm-12 col-lg-6 pt-3">
          <small class="text-opacity">Title</small>
          <sup class="mt-1 col-sm-3" v-if="isEditing.name">
            <button
              class="ml-1 mt-2"
              @click="updateTask('name', task.name)"
              :disabled="isSubmitting"
              title="Save"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
              "
            >
              <i class="zmdi zmdi-check-circle"></i>
            </button>
          </sup>
          <sup class="mt-1" v-else>
            <button
              class="ml-1 mt-2"
              @click="isEditing.name = true"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
              "
            >
              <i class="zmdi zmdi-edit"></i>
            </button>
          </sup>
          <div v-if="!isEditing.name">
            <h6 class="h7 pt-2 text-opacity-pale">{{ task.name }}</h6>
          </div>
          <div
            v-else-if="isEditing.name"
            class="row"
            @focusout="updateTask('name', task.name)"
          >
            <input
              v-model="task.name"
              class="form-control auth-input d-input col-sm-9 pl-2"
              maxlength="100"
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-6 pt-3">
          <small class="text-opacity">Status</small>
          <sup class="mt-1 col-sm-3" v-if="isEditing.status">
            <button
              class="ml-1 mt-2"
              @click="updateTask('status', task.status, 'tast_status_id')"
              :disabled="isSubmitting"
              title="Save"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
              "
            >
              <i class="zmdi zmdi-check-circle"></i>
            </button>
          </sup>
          <sup class="mt-1" v-else>
            <button
              class="ml-1 mt-2"
              @click="isEditing.status = true"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
              "
            >
              <i class="zmdi zmdi-edit"></i>
            </button>
          </sup>

          <div v-if="!isEditing.status">
            <h6 class="h7 pt-2 text-opacity-pale">
              {{ task.status && task.status.name }}
            </h6>
          </div>
          <div
            v-else-if="isEditing.status"
            @focusout="updateTask('status', task.status, 'task_status_id')"
          >
            <multiselect
              track-by="id"
              label="name"
              v-model="task.status"
              :options="taskStatuses"
              :show-labels="false"
            >
            </multiselect>
          </div>
        </div>
        <div class="col-sm-12 col-lg-12 pt-3">
          <small class="text-opacity">Description</small>
          <sup class="mt-1 col-sm-3" v-if="isEditing.description">
            <button
              class="ml-1 mt-2"
              @click="updateTask('description', task.description)"
              :disabled="isSubmitting"
              title="Save"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
              "
            >
              <i class="zmdi zmdi-check-circle"></i>
            </button>
          </sup>
          <sup class="mt-1" v-else>
            <button
              class="ml-1 mt-2"
              @click="isEditing.description = true"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
              "
            >
              <i class="zmdi zmdi-edit"></i>
            </button>
          </sup>
          <div v-if="!isEditing.description">
            <h6 class="h7 pt-2 text-opacity-pale">
              <span v-html="task.description"></span>
            </h6>
          </div>
          <div
            v-else-if="isEditing.description"
            class="row"
            @focusout="updateTask('description', task.description)"
          >
            <quill-editor
              ref="myQuillEditor"
              id="task-desc-edit"
              class="mb-4"
              v-model="task.description"
              @ready="_html(task.description)"
              placeholder="Enter Message"
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-6 pt-3">
          <small class="text-opacity">Assignee</small>
          <sup class="mt-1 col-sm-3" v-if="isEditing.employee">
            <button
              class="ml-1 mt-2"
              @click="updateTask('employee', task.employee, 'employee_id')"
              :disabled="isSubmitting"
              title="Save"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
              "
            >
              <i class="zmdi zmdi-check-circle"></i>
            </button>
          </sup>
          <sup class="mt-1" v-else>
            <button
              class="ml-1 mt-2"
              @click="isEditing.employee = true"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
              "
            >
              <i class="zmdi zmdi-edit"></i>
            </button>
          </sup>
          <div v-if="!isEditing.employee">
            <h6 class="h7 pt-2 text-opacity-pale">
              <router-link
                :to="{
                  name: 'users-show',
                  params: {
                    id: task.employee.user_id,
                  },
                }"
              >
                {{ task.employee.id ? task.employee.name : "Unassigned" }}

                {{
                  task.employee.user_id === this.$auth.user().id ? " (Me)" : ""
                }}
              </router-link>
            </h6>
          </div>
          <div
            v-else-if="isEditing.employee"
            @focusout="updateTask('employee', task.employee, 'employee_id')"
          >
            <multiselect
              track-by="id"
              label="name"
              v-model="task.employee"
              :options="employees"
              :show-labels="false"
            >
            </multiselect>
          </div>
        </div>
        <div class="col-sm-6 col-lg-6 pt-3">
          <small class="text-opacity">Linked Milestone</small>
          <sup class="mt-1 col-sm-3" v-if="isEditing.milestone">
            <button
              class="ml-1 mt-2"
              @click="updateTask('milestone', task.milestone, 'milestone_id')"
              :disabled="isSubmitting"
              title="Save"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
              "
            >
              <i class="zmdi zmdi-check-circle"></i>
            </button>
          </sup>
          <sup class="mt-1" v-else>
            <button
              class="ml-1 mt-2"
              @click="isEditing.milestone = true"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
              "
            >
              <i class="zmdi zmdi-edit"></i>
            </button>
          </sup>
          <div v-if="!isEditing.milestone">
            <h6 class="h7 pt-2 text-opacity-pale">
              {{ optional(task, "milestone.name", "Unassigned") }}
            </h6>
          </div>
          <div
            v-else-if="isEditing.milestone"
            @focusout="updateTask('milestone', task.milestone, 'milestone_id')"
          >
            <multiselect
              track-by="id"
              label="name"
              v-model="task.milestone"
              :options="milestones"
              :show-labels="false"
            >
            </multiselect>
          </div>
        </div>
        <div class="col-sm-12 col-lg-6 pt-3">
          <small class="text-opacity">Total Time Estimate</small>
          <h6 class="h7 pt-2 text-opacity-pale">
            {{ secondsToDhms(task.estimated_hours * 3600) }}
          </h6>
        </div>
        <div class="col-sm-12 col-lg-6 pt-3">
          <small class="text-opacity">
            Complexity
            <sup class="mt-1 col-sm-3" v-if="isEditing.complexity">
              <button
                class="ml-1 mt-2"
                @click="updateTask('complexity', task.complexity)"
                :disabled="isSubmitting"
                title="Save"
                type="button"
                style="
                  height: 25px;
                  width: 25px;
                  border-radius: 25px;
                  background: #f5f5f5;
                  border: none;
                "
              >
                <i class="zmdi zmdi-check-circle"></i>
              </button>
            </sup>
            <sup class="mt-1" v-else>
              <button
                class="ml-1 mt-2"
                @click="isEditing.complexity = true"
                type="button"
                style="
                  height: 25px;
                  width: 25px;
                  border-radius: 25px;
                  background: #f5f5f5;
                  border: none;
                "
              >
                <i class="zmdi zmdi-edit"></i>
              </button>
            </sup>
            <div v-if="!isEditing.complexity">
              <h6 class="h7 pt-2 text-opacity-pale">
                {{ task.complexity || 'N/A' }}
              </h6>
            </div>
            <div
              v-else-if="isEditing.complexity"
              @focusout="updateTask('complexity', task.complexity)"
            >
              <multiselect
                v-model="task.complexity"
                :options="complexities"
                :show-labels="false"
              >
              </multiselect>
            </div>
          </small>
        </div>
        <div class="col-sm-6 mt-4">
          <small class="text-opacity">Manager</small>
          <div class="d-flex flex-row pt-2 align-items-center">
            <button
              style="
                height: 35px;
                width: 35px;
                border: 2px solid #e4e4e4;
                background: #f5f5f5;
                border-radius: 35px;
                position: relative;
                cursor: pointer;
                font-size: 12px;
              "
              v-b-tooltip.hover
              :title="
                optional(task, 'project.manager', null)
                  ? task.project.manager.first_name +
                    ' ' +
                    task.project.manager.last_name
                  : '-'
              "
            >
              {{
                optional(task, "project.manager", null)
                  ? task.project.manager.first_name.charAt(0) +
                    task.project.manager.last_name.charAt(0)
                  : "-"
              }}
            </button>
            <h6 class="h7 pl-2 text-opacity-pale">
              {{
                optional(task, "project.manager", null)
                  ? task.project.manager.first_name +
                    " " +
                    task.project.manager.last_name
                  : "-"
              }}
            </h6>
          </div>
        </div>
        <div class="col-sm-6 mt-4">
          <small class="text-opacity">Created By</small>
          <div class="d-flex pt-2 flex-row align-items-center">
            <button
              style="
                height: 35px;
                width: 35px;
                border: 2px solid #e4e4e4;
                background: #f5f5f5;
                border-radius: 35px;
                position: relative;
                cursor: pointer;
                font-size: 12px;
              "
              v-b-tooltip.hover
              :title="
                optional(creator, 'user.data', null)
                  ? creator.user.data.first_name +
                    ' ' +
                    creator.user.data.last_name
                  : '-'
              "
            >
              {{
                optional(creator, "user.data", null)
                  ? creator.user.data.first_name.charAt(0) +
                    creator.user.data.last_name.charAt(0)
                  : "-"
              }}
            </button>
            <h6 class="pl-2 h7 text-opacity-pale">
              {{
                optional(creator, "user.data", null)
                  ? `${creator.user.data.first_name} ${creator.user.data.last_name}`
                  : ""
              }}
              {{ task.created_by == $auth.user().id ? "(Me)" : "" }}
            </h6>
          </div>
        </div>
        <div class="col-sm-12 col-lg-6 pt-3">
          <small class="text-opacity">Start Date</small>

          <sup class="mt-1 col-sm-3" v-if="isEditing.start_date">
            <button
              class="ml-1 mt-2"
              @click="updateTask('start_date', task.start_date)"
              :disabled="isSubmitting"
              title="Save"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
              "
            >
              <i class="zmdi zmdi-check-circle"></i>
            </button>
          </sup>
          <sup class="mt-1" v-else>
            <button
              class="ml-1 mt-2"
              @click="isEditing.start_date = true"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
              "
            >
              <i class="zmdi zmdi-edit"></i>
            </button>
          </sup>

          <div v-if="!isEditing.start_date">
            <h6 class="h7 pt-2 text-opacity-pale">
              {{
                task.start_date
                  ? toLocal(task.start_date).format("MM/DD/YYYY")
                  : "N/A"
              }}
            </h6>
          </div>
          <div
            v-else-if="isEditing.start_date"
            class="date-range"
            @focusout="
              (e) => updateTask('start_date', task.start_date, null, e)
            "
          >
            <vc-date-picker
              :masks="{ input: ['YYYY-MM-DD'] }"
              :input-props="{
                class: `d-input no-margin-top auth-input pl-3`,
                style: `margin-top:0px !important`,
                placeholder: `Start date`,
              }"
              v-model="task.start_date"
              :attributes="[
                {
                  mode: 'date',
                  'update-on-input': false,
                  popover: { visibility: 'focus', keepVisibleOnInput: true },
                },
              ]"
              name="start_date"
              ref="startFrom"
              data-vv-as="Start date"
            />
            <small class="dpicker-icon">
              <i class="zmdi zmdi-calendar-alt"></i>
            </small>
          </div>
        </div>
        <div class="col-sm-12 col-lg-6 pt-3">
          <small class="text-opacity">Due Date</small>

          <sup class="mt-1 col-sm-3" v-if="isEditing.due_date">
            <button
              class="ml-1 mt-2"
              @click="updateTask('due_date', task.due_date)"
              :disabled="isSubmitting"
              title="Save"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
              "
            >
              <i class="zmdi zmdi-check-circle"></i>
            </button>
          </sup>
          <sup class="mt-1" v-else>
            <button
              class="ml-1 mt-2"
              @click="isEditing.due_date = true"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
              "
            >
              <i class="zmdi zmdi-edit"></i>
            </button>
          </sup>

          <div v-if="!isEditing.due_date">
            <h6 class="h7 pt-2 text-opacity-pale">
              {{
                task.due_date
                  ? toLocal(task.due_date).format("MM/DD/YYYY")
                  : "N/A"
              }}
            </h6>
          </div>
          <div
            v-else-if="isEditing.due_date"
            @focusout="(e) => updateTask('due_date', task.due_date, null, e)"
          >
            <vc-date-picker
              :masks="{ input: ['YYYY-MM-DD'] }"
              :input-props="{
                class: `d-input no-margin-top auth-input pl-3`,
                style: `margin-top:0px !important`,
                placeholder: `Due date`,
              }"
              v-model="task.due_date"
              name="due_date"
              :attributes="[{ popover: { visibility: 'focus' } }]"
              ref="dueDate"
              data-vv-as="Due date"
            />
            <small class="dpicker-icon">
              <i class="zmdi zmdi-calendar-alt"></i>
            </small>
          </div>
        </div>
        <div class="col-sm-6 col-lg-6 pt-3">
          <small class="text-opacity">Preceding Task</small>

          <sup class="mt-1 col-sm-3" v-if="isEditing.parent_task">
            <button
              class="ml-1 mt-2"
              @click="
                updateTask('parent_task', task.parent_task, 'parent_task_id')
              "
              :disabled="isSubmitting"
              title="Save"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
              "
            >
              <i class="zmdi zmdi-check-circle"></i>
            </button>
          </sup>
          <sup class="mt-1" v-else>
            <button
              class="ml-1 mt-2"
              @click="isEditing.parent_task = true"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
              "
            >
              <i class="zmdi zmdi-edit"></i>
            </button>
          </sup>

          <div v-if="!isEditing.parent_task">
            <h6 class="h7 pt-2" v-if="task.parent_task">
              <router-link
                class="text-main"
                :to="{
                  name: 'task-show-full',
                  params: { id: task.parent_task && task.parent_task.id },
                }"
              >
                {{ task.parent_task && task.parent_task.name }}
                <sup>
                  <i class="zmdi zmdi-arrow-right-top pl-1"></i>
                </sup>
              </router-link>
            </h6>
            <h6 class="h7 pt-2" v-else>N/A</h6>
          </div>
          <div
            v-else-if="isEditing.parent_task"
            @focusout="
              updateTask('parent_task', task.parent_task, 'parent_task_id')
            "
          >
            <multiselect
              track-by="id"
              label="name"
              v-model="task.parent_task"
              :options="tasks"
              :show-labels="false"
            >
            </multiselect>
          </div>
        </div>
        <div class="col-sm-12 col-lg-6 pt-3">
          <small class="text-opacity">Completion Date</small>

          <sup class="mt-1 col-sm-3" v-if="isEditing.completed_date">
            <button
              class="ml-1 mt-2"
              @click="updateTask('completed_date', task.completed_date)"
              :disabled="isSubmitting"
              title="Save"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
              "
            >
              <i class="zmdi zmdi-check-circle"></i>
            </button>
          </sup>
          <sup class="mt-1" v-else>
            <button
              class="ml-1 mt-2"
              @click="isEditing.completed_date = true"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
              "
            >
              <i class="zmdi zmdi-edit"></i>
            </button>
          </sup>

          <div v-if="!isEditing.completed_date">
            <h6 class="h7 pt-2 text-opacity-pale">
              {{
                task.completed_date
                  ? toLocal(task.completed_date).format("MM/DD/YYYY")
                  : "N/A"
              }}
            </h6>
          </div>

          <div
            v-else-if="isEditing.completed_date"
            @focusout="
              (e) => updateTask('completed_date', task.completed_date, null, e)
            "
          >
            <vc-date-picker
              :masks="{ input: ['YYYY-MM-DD'] }"
              :input-props="{
                class: `d-input no-margin-top auth-input pl-3`,
                style: `margin-top:0px !important`,
                placeholder: `Completion date`,
              }"
              v-model="task.completed_date"
              name="completed_date"
              ref="CompletedDate"
              :attributes="[{ popover: { visibility: 'focus' } }]"
              data-vv-as="Completion date"
            />
            <small class="dpicker-icon">
              <i class="zmdi zmdi-calendar-alt"></i>
            </small>
          </div>
        </div>  
        <div class="col-sm-6 mt-4">
          <small class="text-opacity">Created At</small>
          <h6 class="pt-2 h7 text-opacity-pale">
            {{toLocal(task.created_at).fromNow()}}
          </h6>
        </div>
        <div class="col-sm-6 mt-4">
          <small class="text-opacity">Updated At</small>
          <h6 class="pt-2 h7 text-opacity-pale">
            {{ toLocal(task.updated_at).fromNow() }}
          </h6>
        </div>
      </div>
    </div>
    <div class="col-lg-12 pt-4">
      <div class="row">
        <div class="col-sm-12">
          <h6 class="h7 text-opacity-pale text-bold">ABOUT PROJECT</h6>
        </div>
        <div class="col-sm-12 col-lg-6 pt-3">
          <small class="text-opacity">Project</small>
          <h6 class="h7 pt-2">
            <router-link
              class="text-main"
              :to="{
                name: 'projects-show-general',
                params: { id: task.project_id },
              }"
            >
              {{ optional(task, "project.name", "Unassigned") }}
              <sup>
                <i class="zmdi zmdi-arrow-right-top pl-1"></i>
              </sup>
            </router-link>
          </h6>
        </div>
        <div class="col-sm-12 col-lg-6 pt-3">
          <small class="text-opacity">Customer</small>
          <h6 class="h7 pt-2 text-opacity-pale">
            <router-link
              class="text-main"
              :to="{
                name: 'customers-show',
                params: {
                  id: optional(task, 'project.customer.id', ''),
                },
              }"
            >
              {{
                optional(task, "project.customer.company_name", "Unassigned")
              }}
              <sup>
                <i class="zmdi zmdi-arrow-right-top pl-1"></i>
              </sup>
            </router-link>
          </h6>
        </div>
        <div class="col-sm-12 col-lg-6 pt-3">
          <small class="text-opacity">Project Type</small>
          <h6 class="h7 pt-2 text-opacity-pale">
            {{ optional(task, "project.billing_type.name", "Unassigned") }}
          </h6>
        </div>
        <div class="col-sm-6 col-lg-6 pt-3">
          <small class="text-opacity">Status</small>
          <h6 class="h7 pt-2 text-opacity-pale">
            {{ optional(task, "project.project_status.name", "Unassigned") }}
          </h6>
        </div>
        <div class="col-sm-12 col-lg-6 pt-3">
          <small class="text-opacity">Complexity</small>

          <sup class="mt-1 col-sm-3" v-if="isEditing.complexity">
            <button
              class="ml-1 mt-2"
              @click="updateTask('complexity', task.complexity)"
              :disabled="isSubmitting"
              title="Save"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
              "
            >
              <i class="zmdi zmdi-check-circle"></i>
            </button>
          </sup>
          <sup class="mt-1" v-else>
            <button
              class="ml-1 mt-2"
              @click="isEditing.complexity = true"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
              "
            >
              <i class="zmdi zmdi-edit"></i>
            </button>
          </sup>
          <div v-if="!isEditing.complexity">
            <h6 class="h7 pt-2 text-opacity-pale">
              {{ task.complexity || "N/A" }}
            </h6>
          </div>
          <div
            v-else-if="isEditing.complexity"
            class="row"
            @focusout="updateTask('complexity', task.complexity)"
          >
            <input
              v-model="task.complexity"
              type="number"
              class="form-control auth-input d-input col-sm-9 pl-2"
              min="1"
              max="100"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toLocal } from "@/helpers/date";
import { secondsToDhms } from "@/helpers/duration";
import { optional, html2text, truncate } from "@/helpers/random";
import Literals from "@/helpers/dashboard/literals";
import { quillEditor } from "vue-quill-editor";
import moment from "moment";

export default {
  components: { quillEditor },
  data() {
    return {
      loading: false,
      loaded: false,
      taskTitle: "",
      task: {},
      isEditing: {
        name: false,
        description: false,
        status: false,
        employee: false,
        milestone: false,
        parent_task: false,
        start_date: false,
        due_date: false,
        completed_date: false,
        manager: false,
        complexity: false,
      },
      isSubmitting: false,
      complexities: [1, 2, 3, 4, 5, 6, 7],
    };
  },
  computed: {
    taskStatuses() {
      return this.$store.getters["global/task_statuses"];
    },
    originalTask() {
      return this.$store.getters["tasks/task"];
    },
    employees() {
      return this.$store.getters["employees/employees_simple"];
    },
    creator() {
      return (
        this.$store.getters["employees/employees"].find(
          (s) => s.user_id == this.task.created_by
        ) || {}
      );
    },
    milestones() {
      return this.$store.getters["projects/milestones"];
    },
    tasks() {
      return this.$store.getters["projects/tasks"].filter(
        (s) => s.id != this.$route.params.id
      );
    },
  },
  methods: {
    optional,
    toLocal,
    secondsToDhms,
    updateTask(column, value, columnName = null, $event) {
      this.isEditing[column] = false;
      this.isSubmitting = true;

      let data = {
        id: this.task.id,
      };

      columnName = columnName || column;

      data[columnName] = value && value.id ? value.id : value;

      if (data[columnName] == this.originalTask[columnName]) {
        this.isSubmitting = false;
        return;
      }

      if (["completed_date", "due_date", "start_date"].includes(columnName)) {
        value =
          value || $event.target.arialLabel || $event.relatedTarget.arialLabel;
        data[columnName] = moment(value).format("YYYY-MM-DD HH:mm:ss");
      }

      this.$store
        .dispatch("tasks/createUpdate", data)
        .then((updatedTask) => {
          value =
            value && value.name
              ? value.name
              : value
              ? ["description", "name", "complexity"].includes(columnName)
                ? truncate(html2text(value), 50)
                : moment(value).format("MM/DD/YYYY")
              : "null";
          this.formatData(updatedTask);

          this.$toast.success(
            `Task ${column} successfully updated to [${value}]`,
            {
              position: "top-right",
              duration: 3000,
            }
          );
        })
        .catch((e) => {
          this.$toast.error("Failed to update task!", {
            position: "top-right",
            duration: 3000,
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    formatData(task) {
      task.manager = this.task.manager || task.manager;
      Object.assign(this.task, task);
      this.task.employee = this.task.employee
        ? {
            id: this.task.employee.id,
            user_id: this.task.employee.user_id,
            name: optional(this.task, "employee.user")
              ? `${this.task.employee.user.first_name} ${this.task.employee.user.last_name}`
              : "N/A",
          }
        : {};
      this.task.start_date = this.task.start_date
        ? new Date(this.task.start_date)
        : null;
      this.task.due_date = this.task.due_date
        ? new Date(this.task.due_date)
        : null;
      this.task.completed_date = this.task.completed_date
        ? new Date(this.task.completed_date)
        : null;
    },
  },
  created() {
    Promise.all([
      this.$store.dispatch("tasks/getSingle", this.$route.params.id),
      this.taskStatuses.length ||
        this.$store.dispatch("global/getTaskStatuses"),
      this.employees.length || this.$store.dispatch("employees/index"),
    ]).finally(() => {
      this.formatData(this.originalTask);
      Promise.all([
        this.milestones.length ||
          this.$store.dispatch("projects/getMilestones", this.task.project_id),
        this.tasks.length ||
          this.$store.dispatch("projects/getTasks", this.task.project_id),
      ]).finally(() => {
        this.loaded = true;
      });
    });
  },
};
</script>
