<template>
  <!-- Modal -->
  <b-modal ref="my-modal" :no-close-on-backdrop="true">
    <template v-slot:modal-header>
      <h2
        class="pl-3 pt-3 pr-3 f-24"
      >{{$route.params && $route.params.mid ? 'Edit Milestone' : 'Create New Milestone' }}</h2>
      <button class="close" @click="close">
        Cancel
        <i class="zmdi zmdi-close-circle pl-2" style="font-size:20px"></i>
      </button>
    </template>
    <div class="d-block pl-3 pb-4">
      <form class="d-form" @submit.prevent="save">
        <div class="row">
          <div class="col-sm-12 col-lg-6">
            <h6 class="text-bold text-opacity-pale">
              Milestone Name
              <b>*</b>
            </h6>
            <input
              type="text"
              v-validate="'required'"
              v-model="body.name"
              name="name"
              class="form-control auth-input d-input"
              placeholder="Enter Milestone name"
              data-vv-as="Milestone Name"
            >
            <small
              class="form-text text-danger"
              v-show="errors.has('name')"
            >{{errors.first('name')}}</small>
          </div>
          <div class="col-sm-12 col-lg-6">
            <h6 class="text-bold text-opacity-pale">
              Status
              <b>*</b>
            </h6>
            <multiselect
              track-by="id"
              label="name"
              v-model="body.status"
              v-validate="'required'"
              name="status_id"
              :options="statuses"
              :show-labels="false"
              data-vv-as="Milestone Status"
            ></multiselect>
            <small
              class="form-text text-danger"
              v-show="errors.has('status_id')"
            >{{errors.first('status_id')}}</small>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-12 col-lg-12">
            <h6 class="text-bold text-opacity-pale">
              Description
              <b>*</b>
            </h6>
            
            <quill-editor
          ref="myQuillEditor"
          class="mt-1"
          v-validate="'required'"
          v-model="body.description"
           @ready="_html(body.description)"
          placeholder="Enter Milestone Description"
        
        />
            <small
              class="form-text text-danger"
              v-show="errors.has('description')"
            >{{errors.first('description')}}</small>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-sm-12 col-lg-6">
            <div class="row">
              <div class="col-sm-6 col-lg-6 date-range">
                <h6 class="text-bold text-opacity-pale">Start date</h6>
                <vc-date-picker
                  :masks="{ input: ['YYYY-MM-DD']}"
                  :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: `Start date`}"
                  v-model="body.start_date"
                  name="start_date"
                  ref="startFrom"
                  data-vv-as="Expected Start date"
                />
                <small class="dpicker-icon">
                  <i class="zmdi zmdi-calendar-alt"></i>
                </small>
              </div>
              <div class="col-sm-6 col-lg-6 date-range">
                <h6 class="text-bold text-opacity-pale">End date</h6>
                <vc-date-picker
                  :masks="{ input: ['YYYY-MM-DD']}"
                  :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: ` Due date`}"
                  v-model="body.due_date"
                  v-validate="'after:startFrom'"
                  name="due_date"
                  data-vv-as="Expected Due Date"
                />
                <small class="dpicker-icon">
                  <i class="zmdi zmdi-calendar-alt"></i>
                </small>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-lg-6">
            <h6 class="text-bold text-opacity-pale">Amount</h6>
            <input
              class="form-control auth-input d-input"
              v-model="body.cost"
              type="number"
              name="amount"
              data-vv-as="Amount"
            >
            <small
              class="form-text text-danger"
              v-show="errors.has('amount')"
            >{{errors.first('amount')}}</small>
          </div>
        </div>

        <div class="row">
          <div class="co-sm-12 col-lg-12 mt-4">
            <input
              style="margin-left: 10px"
              name="is_billable"
              type="checkbox"
              id="billable"
              v-model="body.is_billable"
            >
            <label for="billable" class="pl-2 text-small">
              <h6>Is Billable</h6>
            </label>
            <small
              class="form-text text-danger"
              v-show="errors.has('is_billable')"
            >{{errors.first('is_billable')}}</small>
          </div>
        </div>
      </form>
    </div>
    <template v-slot:modal-footer>
      <dashboard-button @submit="save" :loading="loading" :text="$route.params && $route.params.mid  ? 'Save' : 'Save'"></dashboard-button>
    </template>
  </b-modal>
</template>

<script>
import DashboardButton from "@/components/Buttons/Dashboard";
import Literals from "@/helpers/dashboard/literals";
import { setTimeout } from "timers";
import { toLocal, toUTC } from "@/helpers/date";
import handleError from "@/helpers/error";

export default {
  data() {
    return {
      Literals,
      activeSettlement: 1,
      loading: false,
      showTaskForm: false,
      defaultBody: {
        name: "",
        description: "",
        status: {}
      },
      body: {},
  
    };
  },
  components: { DashboardButton },
  props: ["milestone"],
  computed: {
    statuses() {
      return this.$store.getters["global/milestone_statuses"];
    }
  },
  methods: {
    handleError,
    toLocal,
    toUTC,
    close() {
      return this.$router.go(-1);
    },
    toggleSettlement(id) {
      this.activeSettlement = id;
      this.body.billing_type_id = this.activeSettlement;
    },
    save() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
      let data = {
        name: this.body.name,
        description: this.body.description,
        cost: this.body.cost,
        is_billable: !!this.body.is_billable,
        start_date: this.body.start_date
          ? toUTC(this.body.start_date).format("YYYY-MM-DD")
          : null,
        due_date: this.body.due_date
          ? toUTC(this.body.due_date).format("YYYY-MM-DD")
          : null,
        project_id: this.$route.params.id,
        mid: this.$route.params.mid,
        status_id: this.body.status && this.body.status.id
      };
      let msgPass = Literals.MILESTONE_CREATE_SUCCESS;
      if (this.body.id) {
        Object.assign(data, { id: this.body.id, project_id : this.body.project_id });
        msgPass = Literals.MILESTONE_UPDATE_SUCCESS;
      }
      this.$store
        .dispatch("projects/createUpdateMilestone", data)
        .then(s => {
          this.$toast.success(msgPass, {
            position: "top-right",
            duration: 3000
          });


          this.$emit("project");

          let vm = this;
          setTimeout(function() {
            if (data.id) {
              vm.$router.push({name:'milestones', params:{mid: data.id}});
            } else {
              vm.$router.push({name:'projects-show-general', params:{id:data.project_id}});
            }
          }, 1000);
        })
        .catch(error => {
          let errorMessage = this.$route.params.mid
            ? Literals.MILESTONE_UPDATE_ERROR
            : Literals.MILESTONE_CREATE_ERROR;
          this.handleError(this, error, errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.$refs["my-modal"].show();
  },
  created() {
    this.statuses.length || this.$store.dispatch("global/getMilestoneStatuses");
    this.body = this.milestone || this.defaultBody;
    if(this.milestone) {
      this.body.project_id = this.milestone.project.id;
      this.body.start_date = this.milestone.start_date
              ? new Date(this.body.start_date)
              : null;
      this.body.due_date = this.milestone.due_date
              ? new Date(this.body.due_date)
              : null;
    }
  }
};
</script>
