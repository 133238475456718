<template>
  <div class="home">
    <navbar></navbar>
      <router-view></router-view>
    <!-- <div class="timer m-5 p-2 pt-3 pb-3 pr-3 pl-3 d-flex flex-row align-items-center">
      <div class="timer-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#0a5685"
        >
          <path
            d="M13 12l-.688-4h-.609l-.703 4c-.596.347-1 .984-1 1.723 0 1.104.896 2 2 2s2-.896 2-2c0-.739-.404-1.376-1-1.723zm-1-8c-5.522 0-10 4.477-10 10s4.478 10 10 10 10-4.477 10-10-4.478-10-10-10zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm-2-19.819v-2.181h4v2.181c-1.438-.243-2.592-.238-4 0zm9.179 2.226l1.407-1.407 1.414 1.414-1.321 1.321c-.462-.484-.964-.926-1.5-1.328zm-12.679 9.593c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zm12 0c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zm-6 6c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zm-4-2c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zm8 0c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zm-8-9c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zm8 0c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5z"
          ></path>
        </svg>
      </div>
      <div class="pl-3">
        <small class="text-opacity">Timer for Task #2</small>
        <h6 class="h7 text-bolder text-opacity-pale pt-1" style="font-size:24px !important">00:00:00</h6>
      </div>
    </div>-->
      <time-log :data="null" ></time-log>
  </div>
</template>

<style lang="scss" scoped>
// .timer {
//   position: fixed;
//   width: 230px;
//   background: white;
//   border-radius: 10px;
//   box-shadow: 0 7px 30px 0 rgba(217, 217, 217, 0.36);
//   bottom: 0;
//   right: 0;
//   border: 2px solid #f7f7f7;
//   cursor: pointer;

//   .timer-icon {
//     height: 40px;
//     background: #daf1ff;
//     width: 40px;
//     border-radius: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }

//   &:hover {
//     background: #0a5685;
//     small,
//     h6 {
//       color: white !important;
//     }
//   }
// }
</style>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar/Index";
import TimeLog from "@/components/Modals/Time";
export default {
  name: "Home",
  title: "Home",
  components: {
    Navbar,
    TimeLog
  },
  methods: {

  },
  data() {
    return {

    };
  },
};
</script>
