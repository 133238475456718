<template>
  <div class="container-fluid tab-wrapper pb-5" :class="{blur:!loaded}">
    <div class="row pl-3 pr-4 pb-4 pt-4 ignite-wrapper">
      <div class="col-sm-12 col-lg-3">
        <ul class="invoice-customer_info">
          <li>
            <svg
              width="50px"
              height="50px"
              viewBox="0 0 45 45"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Work-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Invoice-6" transform="translate(-32.000000, -304.000000)">
                  <g id="Invoice---Customer" transform="translate(32.000000, 304.000000)">
                    <circle id="Oval" fill="#D5EFFF" cx="22" cy="22" r="22"></circle>
                    <g
                      transform="translate(10.000000, 10.000000)"
                      stroke="#0A5685"
                      stroke-linecap="round"
                      stroke-width="1.5"
                    >
                      <path
                        d="M7.476,7.084 C9.11834331,8.78804894 11.3833412,9.75051057 13.75,9.75000035 C14.9363094,9.75033697 16.1102269,9.50874816 17.2,9.04"
                        id="Path"
                      ></path>
                      <circle id="Oval" cx="12" cy="9.75" r="5.25"></circle>
                      <path
                        d="M18.913,20.876 C17.0835478,19.035059 14.5953726,17.9998579 12,17.9998579 C9.40462739,17.9998579 6.91645218,19.035059 5.087,20.876"
                        id="Path"
                      ></path>
                      <circle id="Oval" cx="12" cy="12" r="11.25"></circle>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </li>
          <li>
            <h6 class="text-opacity">Employees</h6>
            <h5 class="text-bold">{{employees.length}} employees total</h5>
          </li>
        </ul>
      </div>
      <div class="col-sm-12 col-lg-3">
        <ul class="invoice-customer_info">
          <li>
            <svg
              width="50px"
              height="50px"
              viewBox="0 0 45 45"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Work-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Invoice-6" transform="translate(-389.000000, -304.000000)">
                  <g id="Invoice---Status" transform="translate(389.000000, 304.000000)">
                    <circle id="Oval" fill="#D5EFFF" cx="22" cy="22" r="22"></circle>
                    <g
                      id="Invoice---status"
                      transform="translate(16.000000, 10.000000)"
                      stroke="#0A5685"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    >
                      <path
                        d="M11.848,11.306 C12.0421347,10.9904081 12.0504476,10.5945114 11.8697317,10.2710486 C11.6890157,9.94758569 11.3475219,9.74712305 10.977,9.747 L7.5,9.747 L7.5,0.747 L0.152,12.689 C-0.0423027635,13.0044392 -0.0506807724,13.4003308 0.130103121,13.7237078 C0.310887015,14.0470847 0.65252009,14.2473026 1.023,14.247 L4.5,14.247 L4.5,23.247 L11.848,11.306 Z"
                        id="Path"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </li>
          <li>
            <h6 class="text-opacity">Debit Amount</h6>
            <h5 class="text-bold">{{currency && currency.symbol}} {{totalAmount}}</h5>
          </li>
        </ul>
      </div>
      <div class="col-sm-12 col-lg-3">
        <ul class="invoice-customer_info">
          <li>
            <svg
              width="50px"
              height="50px"
              viewBox="0 0 45 45"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Work-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Invoice-6" transform="translate(-1103.000000, -304.000000)">
                  <g id="Invoice---Due-Date" transform="translate(1103.000000, 304.000000)">
                    <circle id="Oval-Copy-5" fill="#D5EFFF" cx="22" cy="22" r="22"></circle>
                    <g
                      transform="translate(10.000000, 10.000000)"
                      stroke="#0A5685"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    >
                      <path
                        d="M18.75,14.2499991 L16.717,14.2499991 C16.0734033,14.2492534 15.519894,14.7055212 15.3978091,15.337433 C15.2757241,15.9693448 15.6194422,16.5989563 16.217,16.838 L18.281,17.663 C18.8771734,17.9028758 19.2196241,18.5317157 19.0976786,19.1626613 C18.9757332,19.7936069 18.4236219,20.2495512 17.781,20.2499991 L15.75,20.2499991"
                        id="Path"
                        stroke-linecap="round"
                      ></path>
                      <line
                        x1="17.25"
                        y1="14.25"
                        x2="17.25"
                        y2="13.5"
                        id="Path"
                        stroke-linecap="round"
                      ></line>
                      <line
                        x1="17.25"
                        y1="21"
                        x2="17.25"
                        y2="20.25"
                        id="Path"
                        stroke-linecap="round"
                      ></line>
                      <circle id="Oval" stroke-linecap="round" cx="17.25" cy="17.25" r="6"></circle>
                      <path
                        d="M8.25,17.25 L2.25,17.25 C1.42157288,17.25 0.75,16.5784271 0.75,15.75 L0.75,3.765 C0.75,2.93657288 1.42157288,2.265 2.25,2.265 L15.75,2.265 C16.5784271,2.265 17.25,2.93657288 17.25,3.765 L17.25,8.25"
                        id="Path"
                        stroke-linecap="round"
                      ></path>
                      <line x1="0.75" y1="6.75" x2="17.25" y2="6.75" id="Path"></line>
                      <line
                        x1="5.243"
                        y1="3.75"
                        x2="5.243"
                        y2="0.75"
                        id="Path"
                        stroke-linecap="round"
                      ></line>
                      <line
                        x1="12.743"
                        y1="3.75"
                        x2="12.743"
                        y2="0.75"
                        id="Path"
                        stroke-linecap="round"
                      ></line>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </li>
          <li>
            <h6 class="text-opacity">Debit Date</h6>
            <h5 class="text-bold">{{ toLocal(payroll.created_at).format('ll') }}</h5>
          </li>
        </ul>
      </div>
      <div class="col-sm-12 col-lg-3">
        <ul class="invoice-customer_info">
          <li>
            <svg
              width="50px"
              height="50px"
              viewBox="0 0 45 45"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Work-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Invoice-6" transform="translate(-1103.000000, -304.000000)">
                  <g id="Invoice---Due-Date" transform="translate(1103.000000, 304.000000)">
                    <circle id="Oval-Copy-5" fill="#D5EFFF" cx="22" cy="22" r="22"></circle>
                    <g
                      transform="translate(10.000000, 10.000000)"
                      stroke="#0A5685"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    >
                      <path
                        d="M18.75,14.2499991 L16.717,14.2499991 C16.0734033,14.2492534 15.519894,14.7055212 15.3978091,15.337433 C15.2757241,15.9693448 15.6194422,16.5989563 16.217,16.838 L18.281,17.663 C18.8771734,17.9028758 19.2196241,18.5317157 19.0976786,19.1626613 C18.9757332,19.7936069 18.4236219,20.2495512 17.781,20.2499991 L15.75,20.2499991"
                        id="Path"
                        stroke-linecap="round"
                      ></path>
                      <line
                        x1="17.25"
                        y1="14.25"
                        x2="17.25"
                        y2="13.5"
                        id="Path"
                        stroke-linecap="round"
                      ></line>
                      <line
                        x1="17.25"
                        y1="21"
                        x2="17.25"
                        y2="20.25"
                        id="Path"
                        stroke-linecap="round"
                      ></line>
                      <circle id="Oval" stroke-linecap="round" cx="17.25" cy="17.25" r="6"></circle>
                      <path
                        d="M8.25,17.25 L2.25,17.25 C1.42157288,17.25 0.75,16.5784271 0.75,15.75 L0.75,3.765 C0.75,2.93657288 1.42157288,2.265 2.25,2.265 L15.75,2.265 C16.5784271,2.265 17.25,2.93657288 17.25,3.765 L17.25,8.25"
                        id="Path"
                        stroke-linecap="round"
                      ></path>
                      <line x1="0.75" y1="6.75" x2="17.25" y2="6.75" id="Path"></line>
                      <line
                        x1="5.243"
                        y1="3.75"
                        x2="5.243"
                        y2="0.75"
                        id="Path"
                        stroke-linecap="round"
                      ></line>
                      <line
                        x1="12.743"
                        y1="3.75"
                        x2="12.743"
                        y2="0.75"
                        id="Path"
                        stroke-linecap="round"
                      ></line>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </li>
          <li>
            <h6 class="text-opacity">Employee pay date</h6>
            <h5 class="text-bold">{{ toLocal(payroll.start_date).format('ll') }} - {{toLocal(payroll.end_date).format('ll')}}</h5>
          </li>
        </ul>
      </div>
    </div>
    <div class="x d-flex justify-content-center mt-5 mb-5">
      <div class="card w-50 p-5">
        <div class="text-center">
          <svg
            class="ft-green-tick"
            xmlns="http://www.w3.org/2000/svg"
            height="50"
            width="50"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <circle class="circle" fill="#5bb543" cx="24" cy="24" r="22"></circle>
            <path
              class="tick"
              fill="none"
              stroke="#FFF"
              stroke-width="6"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              d="M14 27l5.917 4.917L34 17"
            ></path>
          </svg>
        </div>
        <div class="mt-3 ">
          <h5 class="text-bold text-opacity-pale text-center">Payroll created successfully.</h5>
          <h6 class="text-opacity-pale h7">
            Leaf will debit a total of
            <b class="text-bold">{{currency && currency.symbol}} {{totalAmount}}</b> across your account with {{paymentMethods.map(s => s.name).join(', ')}} between
            <b class="text-bold">{{ toLocal(payroll.start_date).format('ll') }} - {{toLocal(payroll.end_date).format('ll')}}</b>. Please make sure you have these funds available in the accounts
          </h6>
          <div class="text-center">
          <router-link :to="{name:'payments-show', params:{id:payroll.id}}">
            <button class="btn btn-primary auth-btn mt-3">
              View Payroll
              <i class="zmdi zmdi-arrow-right-top pl-2"></i>
            </button>
          </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tab-wrapper {
  .row {
    background: white;
    padding-bottom: 20px;
  }
  .ignite-wrapper {
    background: #fbfdff;
    transform: translateX(-12px);
    width: 100vw;
    padding-left: 30px !important;
    border-top: 2px solid #f5f5f5;
    border-bottom: 2px solid #f5f5f5;
  }
}
.invoice-customer_info {
  list-style-type: none;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  li {
    &:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        height: 50px;
        width: 50px;
        border-radius: 50px;
        background: #d5efff;
        border: none;
        outline: none;
        svg {
          fill: #0a5685;
        }
      }
    }
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      padding-left: 15px;
      padding-top: 10px;
      h5 {
        font-size: 18px;
        color: #00133e;
      }
    }
  }
}
</style>

<script>
import logosvg from "@/helpers/logosvg.js";
import {toLocal} from "@/helpers/date";
import handleError from "@/helpers/error";
export default {
  data() {
    return {
      loaded:false,
      loading:false,
    };
  },
  methods : {
    toLocal,
  },
  computed: {

    employees() {
      return this.$store.getters["payrolls/employees"];
    },
    payroll() {
      return this.$store.getters["payrolls/payroll"];
    },
    paymentMethods() {
      let obj = this.$store.getters["payrolls/payroll_data"];
      let methods = [];
      Object.keys(obj).forEach((key) => {
        if(key != 'quotes' && key != 'errors' ) {
          obj[key]['name'] = key;
          methods.push(obj[key]);
        }
      });
      return methods;
    },
    totalAmount() {
      let result = parseFloat(this.paymentMethods.reduce((a, b) => a+parseFloat(b.amount), 0));
      return isNaN(result) ? '0.00' : result.toFixed(2);
    },
    currencies() {
      return this.$store.getters['global/currencies'];
    },
    currency() {
      let org = this.$auth.user().organization;
      let curr = {};

      if(org) {
        let currencyId = org.default_currency_id;
        curr = this.currencies.find(s => s.id == currencyId);
      }

      return curr;
    }
  },
  created() {
    let id = this.$route.params.id
    Promise.all([
      this.$store.dispatch("global/getCurrencies"),
      this.$store.dispatch("payrolls/getSingle", id),
      this.employees.length || this.$store.dispatch("payrolls/getEmployees", id)
    ]).finally(() =>{
      this.loaded = true;
    })
  },
};
</script>
