<template>
  <b-modal
    :no-close-on-backdrop="true"
    hide-footer
    @hide="close"
    :visible="showModal"
    id="expenseModal"
    ref="expenseModal"
  >
    <template v-slot:modal-header>
      <h2 class="pl-1 pt-3 pr-3 f-24" style="width: 80%">
        {{ resource.data && resource.data.name }}
      </h2>
      <button type="button" class="close" @click="close" style="width: 30%">
        Cancel
        <i class="zmdi zmdi-close-circle pl-2" style="font-size: 20px"></i>
      </button>
    </template>
    <div class="d-block pb-4">
      <div class="mt-3 text-right d-flex flex-row justify-content-between">
        <small style="opacity: 0.3">*Drag item to resort order </small>
        <button
          class="btn-text mr-2 mb-2"
          @click="addNewResource"
          style="
            font-weight: bold;
            font-size: 14px;
            background: #daf1ff;
            cursor: pointer;
            border: none;
          "
        >
          + Add New Item
        </button>
      </div>
      <div class="border-top">
        <form class="auth-form">
          <draggable v-if="optional(resource, 'data')"
            class="d-flex flex-column overflow-hidden"
            v-model="resource.data.value"
            v-bind="taskDragOptions"
            :sort="true"
            @change="handleTaskMoved($event)"
            @end="moved"
          >
            <transition-group class="" tag="div">
              <div
                v-for="(resourcex, index) in resource.data &&
                resource.data.value"
                :key="index + 0"
                class="d-flex flex-row align-items-center"
              >
                <button
                  class="auth-remove"
                  type="button"
                  style="margin-top: 10px"
                  @click="deleteResource(index, resourcex.id)"
                >
                  -
                </button>
                <input
                  v-model="resourcex.name"
                  class="form-control auth-input"
                  placeholder="Enter title for this column"
                />
                <button
                  type="button"
                  class="auth-button pl-3 pr-3"
                  @click="saveResource(index, resourcex.name, resourcex.id)"
                  :disabled="isSavingResource"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    v-if="isSavingResource"
                    role="status"
                    style="margin-top: 5px"
                    aria-hidden="true"
                  ></span>
                  <i v-else class="zmdi zmdi-check"></i>
                </button>
              </div>
            </transition-group>
          </draggable>
        </form>
      </div>
    </div>
  </b-modal>
</template>

<style lang="scss">
.auth-remove {
  height: 18px;
  width: 18px;
  background: darkred;
  color: white;
  border: none;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: black;
  }
}
.auth-button {
  height: 40px;
  background: rgb(45, 204, 45);
  color: white;
  border: none;
  margin-top: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  &:hover {
    opacity: 0.7;
  }
}
</style>
<script>
import draggable from "vuedraggable";
import {optional} from "@/helpers/random";

export default {
  components: { draggable },
  props: ["showModal", "resource", "isSavingResource"],
  computed: {
    taskDragOptions() {
      return {
        animation: 200,
        group: "tasks",
        dragClass: "status-drag",
        sort: true,
        ghostClass: "ghost-card",
      };
    },
  },
  methods: {
    optional,
    close() {
      this.$emit("close");
      // this.showModal = false;
    },
    addNewResource() {
      this.$emit("addNewResource", this.resource.name);
    },
    deleteResource(index, value) {
      let data = {
        key: this.resource.name,
        value: value,
        index: index,
      };
      this.$emit("deleteResource", data);
    },
    saveResource(index, value, id) {
      let data = {
        key: this.resource.name,
        value: value,
        index: index,
        order: index,
        id: id,
      };
      this.$emit("saveResource", data);
    },
    handleTaskMoved(newIndex) {
 
       this.resource.data.value[newIndex.moved.newIndex] = newIndex.moved.element;

      let data = {
        id: null,
        key: 'task_statuses',
        value: null,
        order: this.resource.data.value.map(s => s.id),
        move : true
      };
      
      this.$emit("saveResource", data);
    },
    moved() {},
    // handleTaskMoved() {},
  },
};
</script>