<template>
  <!-- <div class="row profile-area">
        <div class="col-sm-12 col-lg-12">
          <div class="card">
            <div class="row">
              <div class="col-sm-12 col-lg-3 card-profile border-right pt-5">
                <div class="card-profile_wrapper">
                  <div class="card-profile_wrapper-img">
                    <img src="@/assets/img/baobab_logo_2.png">
                    <div class="card-profile_wrapper-img-overlay">
                      <i class="zmdi zmdi-camera"></i>
                    </div>
                  </div>
                  <div class="pt-4 text-left">
                    <h6 class="text-left text-opacity-pale text-bold h7">@{{body.org_name}}</h6>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-lg-9 pb-0 ml-0 pl-0">
                <div class="card-content_wrapper">
                  <form class="d-form" @submit.prevent="save" >
                    <div class="card-caption pt-3 pb-2 pl-4">
                      <h6 class="text-bold text-opacity-pale">Basic Information</h6>
                    </div>
                    <div class="card-description">
                      <div class="row pl-4 pt-3 pr-4">
                        <div class="col-sm-12 col-lg-6">
                          <small class="text-opacity">First Name</small>
                          <input
                            type="text"
                            v-validate="'required'"
                            v-model="body.first_name"
                            name="first_name"
                            class="form-control auth-input d-input"
                            placeholder="Enter your first name"
                            data-vv-as="First Name"
                          >
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('first_name')"
                          >{{errors.first('first_name')}}</small>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <small class="text-opacity">Last Name</small>
                          <input
                            type="text"
                            v-validate="'required'"
                            v-model="body.last_name"
                            name="last_name"
                            class="form-control auth-input d-input"
                            placeholder="Enter your last name"
                            data-vv-as="Last Name"
                          >
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('last_name')"
                          >{{errors.first('last_name')}}</small>
                        </div>
                      </div>
                      <div class="row pl-4 pt-3 pr-4">
                        <div class="col-sm-12 col-lg-6">
                          <small class="text-opacity">Email Address</small>
                          <input
                            type="email"
                            v-validate="'required|email'"
                            v-model="body.email"
                            name="email"
                            class="form-control auth-input d-input"
                            placeholder="Enter your Email Address"
                            data-vv-as="Email Address"
                          >
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('email')"
                          >{{errors.first('email')}}</small>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <small class="text-opacity">Telephone</small>
                          <div class="row">
                            <div class="col-lg-12 d-contact mt-2" style="margin-top:1px">
                              <span>
                                <multiselect
                                  v-model="body.country"
                                  track-by="id"
                                  class="mobile_dropdown"
                                  return="id"
                                  label="d_code"
                                  name="d_code"
                                  placeholder="Dial No."
                                  :options="countries"
                                  :show-labels="false"
                                  data-vv-as="Dial Code"
                                >
                                  <template slot="option" slot-scope="props">
                                    <span class="text-opacity">({{`+${props.option.d_code}`}})</span>
                                    {{props.option.name}}
                                  </template>
                                  <template
                                    slot="singleLabel"
                                    slot-scope="props"
                                  >{{`+${props.option.d_code}`}}</template>
                                </multiselect>
                              </span>
                              <span>
                                <input
                                  class="form-control auth-input d-input"
                                  data-vv-as="Phone Number"
                                  name="phone_number"
                                  placeholder="Enter phone number"
                                  type="tel"
                                  v-model="body.phone_number"
                                >
                              </span>
                            </div>
                          </div>
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('phone_number')"
                          >{{errors.first('phone_number')}}</small>
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('country_code')"
                          >{{errors.first('country_code')}}</small>
                        </div>
                      </div>
                      <div class="row pl-4 pt-3 pr-4">
                        <div class="col-sm-12 col-lg-6">
                          <small class="text-opacity">Country</small>
                          <multiselect
                            track-by="id"
                            return="id"
                            label="name"
                            v-model="body.country"
                            v-validate="'required'"
                            name="country_id"
                            :options="countries"
                            class="mt-2"
                            :show-labels="false"
                            placeholder="Select Country"
                            data-vv-as="Countries"
                          ></multiselect>
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('country_id')"
                          >{{errors.first('country_id')}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="card-caption pt-3 pb-2 pl-4 mt-4">
                      <h6 class="text-bold text-opacity-pale">Organizational Information</h6>
                    </div>
                    <div class="card-description" v-if="$auth.user().organization">
                      <div class="row pl-4 pt-3 pr-4">
                        <div class="col-sm-12 col-lg-6">
                          <small class="text-opacity">Name</small>
                          <input
                            type="text"
                            v-validate="'required'"
                            v-model="body.org_name"
                            name="company_name"
                            class="form-control auth-input d-input"
                            placeholder="Enter your Company Name"
                            data-vv-as="Company Name"
                          >
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('company_name')"
                          >{{errors.first('company_name')}}</small>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <small class="text-opacity">Business Email</small>
                          <input
                            type="email"
                            v-validate="'required|email'"
                            v-model="body.business_email"
                            name="business_email"
                            class="form-control auth-input d-input"
                            placeholder="Enter your Business Email"
                            data-vv-as="Business Email"
                          >
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('business_email')"
                          >{{errors.first('business_email')}}</small>
                        </div>
                      </div>
                      <div class="row pl-4 pt-3 pr-4">
                        <div class="col-sm-12 col-lg-6">
                          <small class="text-opacity">Website</small>
                          <input
                            type="url"
                            v-validate="'required'"
                            v-model="body.website"
                            name="website"
                            class="form-control auth-input d-input"
                            placeholder="Enter your Business Email"
                            data-vv-as="Business Email"
                          >
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('website')"
                          >{{errors.first('website')}}</small>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <small class="text-opacity">Industry</small>
                          <multiselect
                            track-by="id"
                            return="id"
                            label="name"
                            v-model="body.industry"
                            v-validate="'required'"
                            name="industry_id"
                            :options="industries"
                            :show-labels="false"
                            class="mt-2"
                            placeholder="Select Industry"
                            data-vv-as="Industry"
                          ></multiselect>
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('industry_id')"
                          >{{errors.first('industry_id')}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer text-right mt-5">
                      <dashboard-button @submit="save" :loading="loading" :text="'Update'"></dashboard-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
  </div>-->
  <div class="col-sm-12 col-lg-9 pb-0 ml-0 pl-0">
    <div class="card-content_wrapper" :class="{ blur: !loaded }">
      <form class="d-form" @submit.prevent="save">
        <div class="card-caption pt-3 pb-2 pl-4">
          <h6 class="text-bold text-opacity-pale">Basic Information</h6>
        </div>
        <div class="card-description">
          <div class="row pl-4 pt-3 pr-4">
            <div class="col-sm-12 col-lg-6">
              <small class="text-opacity">First Name <b>*</b></small>
              <input
                type="text"
                v-validate="'required'"
                v-model="body.first_name"
                name="first_name"
                class="form-control auth-input d-input"
                placeholder="Enter your first name"
                data-vv-as="First Name"
              />
              <small
                class="form-text text-danger"
                v-show="errors.has('first_name')"
                >{{ errors.first("first_name") }}</small
              >
            </div>
            <div class="col-sm-12 col-lg-6">
              <small class="text-opacity">Last Name <b>*</b></small>
              <input
                type="text"
                v-validate="'required'"
                v-model="body.last_name"
                name="last_name"
                class="form-control auth-input d-input"
                placeholder="Enter your last name"
                data-vv-as="Last Name"
              />
              <small
                class="form-text text-danger"
                v-show="errors.has('last_name')"
                >{{ errors.first("last_name") }}</small
              >
            </div>
          </div>
          <div class="row pl-4 pt-3 pr-4">
            <div class="col-sm-12 col-lg-6">
              <small class="text-opacity">Email Address <b>*</b></small>
              <input
                type="email"
                v-validate="'required|email'"
                v-model="body.email"
                name="email"
                class="form-control auth-input d-input"
                placeholder="Enter your Email Address"
                data-vv-as="Email Address"
              />
              <small
                class="form-text text-danger"
                v-show="errors.has('email')"
                >{{ errors.first("email") }}</small
              >
            </div>
            <div class="col-sm-12 col-lg-6">
              <small class="text-opacity">Telephone <b>*</b></small>
              <div class="row">
                <div class="col-lg-12 d-contact mt-2" style="margin-top: 1px">
                  <span>
                    <multiselect
                      v-model="body.country"
                      track-by="id"
                      class="mobile_dropdown"
                      return="id"
                      label="d_code"
                      name="d_code"
                      placeholder="Dial No."
                      :options="countries"
                      :show-labels="false"
                      data-vv-as="Dial Code"
                      v-validate="'required'"
                    >
                      <template slot="option" slot-scope="props">
                        <span class="text-opacity"
                          >({{ props.option.d_code }})</span
                        >
                        {{ props.option.name }}
                      </template>
                      <template slot="singleLabel" slot-scope="props">{{
                        props.option.d_code
                      }}</template>
                    </multiselect>
                  </span>
                  <span>
                    <input
                      class="form-control auth-input d-input"
                      data-vv-as="Phone Number"
                      name="phone_number"
                      placeholder="Enter phone number"
                      type="tel"
                      v-model="body.phone_number"
                    />
                  </span>
                </div>
              </div>
              <small class="form-text text-danger" v-show="errors.has('d_code')"
                >{{ errors.first("d_code") }}
              </small>
              <small
                class="form-text text-danger"
                v-show="errors.has('phone_number')"
                >{{ errors.first("phone_number") }}</small
              >
            </div>
          </div>
          <div class="row pl-4 pt-3 pr-4">
            <div class="col-sm-12 col-lg-6">
              <small class="text-opacity">Country <b>*</b></small>
              <multiselect
                track-by="id"
                return="id"
                label="name"
                v-model="body.country"
                v-validate="'required'"
                name="country_id"
                :options="countries"
                class="mt-2"
                :show-labels="false"
                placeholder="Select Country"
                data-vv-as="Countries"
              ></multiselect>
              <small
                class="form-text text-danger"
                v-show="errors.has('country_id')"
                >{{ errors.first("country_id") }}</small
              >
            </div>
          </div>
        </div>

        <div class="card-footer text-right mt-5">
          <dashboard-button
            @submit="save"
            :loading="loading"
            :text="'Update'"
          ></dashboard-button>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-footer {
  background: transparent;
  height: 80px;
}
</style>

<script>
import DashboardButton from "@/components/Buttons/Dashboard";
import Literals from "@/helpers/dashboard/literals";
import { toLocal } from "@/helpers/date";
import handleError from "@/helpers/error";
import Accordion from "@/components/Collapse/Index";
export default {
  components: { DashboardButton },
  name: "EditProfile",
  title: "Edit Profile",
  data() {
    return {
      Literals,
      body: {
        first_name: "",
        last_name: "",
        email: "",
        country: "",
        name: "",
        website: "",
        business_email: "",
        org_id: "",
        org_name: "",
        industry: "",
      },
      loaded: false,
      loading: false,
    };
  },
  methods: {
    toLocal,
    handleError,
    toggleCollapse(index) {
      this.accordions[index].visible =
        this.accordions[index].visible === false ? true : false;
    },
    save() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
      let data = {
        id: this.body.id,
        first_name: this.body.first_name,
        last_name: this.body.last_name,
        email: this.body.email,
        country_code: this.body.country.d_code,
        phone_number: this.body.phone_number,
        country_id: this.body.country ? this.body.country.id : null,
      };
      let org_data = {
        id: this.body.org_id,
        name: this.body.org_name,
        business_email: this.body.business_email,
        website: this.body.website,
        industry_id: this.body.industry ? this.body.industry.id : null,
      };
      this.$store
        .dispatch("global/updateProfile", data)
        .then(async (s) => {
          if (org_data.id) {
            await this.$store.dispatch(
              "global/updateOrganizationalProfile",
              org_data
            );
          }
          this.refreshData(Literals.USER_UPDATE_SUCCESS);
        })
        .catch((error) => {
          let errorMessage = Literals.USER_UPDATE_ERROR;
          handleError(this, error, errorMessage);
        })
        .finally((s) => {
          this.loading = false;
        });
    },
    refreshData(message) {
      // ref-fetch and set updated user data
      this.$auth.refresh().then(({ data: { data: user } }) => {
        this.$auth.user(user);
        this.$toast.success(message, {
          position: "top-right",
          duration: 3000,
        });
      });
    },
  },
  computed: {
    industries() {
      return this.$store.getters["global/industries"];
    },
    breadCrumbs() {
      return [
        {
          text: "Home",
          to: { name: "home" },
        },
        {
          text: "Organization",
          to: { name: "users-index" },
        },
        {
          text:
            this.$auth.user().organization &&
            this.$auth.user().organization.name,
          to: "",
        },
      ];
    },
    countries() {
      return this.$store.getters["global/countries"];
    },
    currencies() {
      return this.$store.getters["global/currencies"];
    },
  },
  created() {
    Promise.all([
      this.industries.length || this.$store.dispatch("global/getIndustries"),
      this.countries.length || this.$store.dispatch("global/getCountries"),
      this.currencies.length || this.$store.dispatch("global/getCurrencies"),
    ]).finally(() => {
      let body = {
        id: this.$auth.user().id,
        first_name: this.$auth.user().first_name,
        last_name: this.$auth.user().last_name,
        email: this.$auth.user().email,
        phone_number: this.$auth.user().phone_number,
        country: this.countries.find(
          (s) => s.id == this.$auth.user().country_id
        ),
      };
      if (this.$auth.user().organization) {
        body.business_email = this.$auth.user().organization.business_email;
        body.website = this.$auth.user().organization.website;
        body.org_id = this.$auth.user().organization.id;
        body.default_currency_id = this.$auth.user().organization
          .default_currency_id
          ? this.currencies.find(
              (s) => s.id == this.$auth.user().organization.default_currency_id
            )
          : {};
        body.org_name = this.$auth.user().organization.name;
        body.industry = this.industries.find(
          (s) => s.id == this.$auth.user().organization.industry_id
        );
      }
      this.body = { ...this.body, ...body };
      this.loaded = true;
    });
  },
};
</script>
