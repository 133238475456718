import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const payrolls = {
    namespaced: true,
    state: {
        payroll: {},
        payrolls: [],
        employees: [],
        payroll_data: {},
        transactions: [],
    },
    actions,
    getters,
    mutations
}
