export function customToolbar(vm) {
    return {
    modules: {
      mention: {
        allowedChars: /^[a-zA-Z0-9_]*$/,
        mentionDenotationChars: ["@"],
        renderItem: (item) => {
          return item.display;
        },
        source: (searchTerm, renderList, mentionChar) => {
          let users = vm.$store.getters["employees/users"];
          users.filter((s) => {
            s["display"] = s.name;
            s["value"] = s.name;
            s["target"] = window.location.origin + "/users/" + s.id;
            s["link"] = window.location.origin + "/users/" + s.id;
          });

          let values = users;

          if (searchTerm.length === 0) {
            renderList(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                ~values[i].value
                  .toLowerCase()
                  .indexOf(searchTerm.toLowerCase())
              )
                matches.push(values[i]);
            renderList(matches, searchTerm);
          }
        },
      },
    },
}
}
