<template>
  <div class="home pb-5" :class="{blur:!loaded}">
    <record-payment-modal :showPaymentModal="showPaymentModal" @close="showPaymentModal = false"></record-payment-modal>
    <send-invoice-modal
      :showSendInvoiceModal="showSendInvoiceModal"
      @close="showSendInvoiceModal = false"
    ></send-invoice-modal>
    <schedule-invoice-modal
      :showScheduleInvoiceModal="showScheduleInvoiceModal"
      @close="showScheduleInvoiceModal = false"
    ></schedule-invoice-modal>
    <headers class="bg-ggray pb-5">
      <div slot="breadcrumbs">
        <breadcrumbs :items="breadCrumbs"></breadcrumbs>
      </div>
      <div slot="title">
        <h2>{{`#${invoice.id}`}}</h2>
      </div>
      <div slot="action">
        <b-dropdown id="dropdown-1" class="m-md-2 options-dropdown">
          <template class="btn btn-ds btn-outline" v-slot:button-content>
            <i class="zmdi zmdi-more" style="font-size:25px"></i>
            <!-- </button> -->
          </template>
          <b-dropdown-item>Duplicate</b-dropdown-item>
          <b-dropdown-item>Delete</b-dropdown-item>
          <b-dropdown-item>Export</b-dropdown-item>
        </b-dropdown>
      </div>
    </headers>

    <div class="container-fluid tab-wrapper pb-5">
      <div class="row pl-3 pb-4 pr-4">
        <div class="col-sm-12 col-lg-6 pt-3">
          <h6 class="text-bold text-opacity-pale">INVOICE STAGE</h6>
        </div>
        <div class="col-sm-12 col-lg-10 mt-3 opportunity-wrapper">
          <ul class="opportunity-stages invoice-send">
            <li
              v-for="(stage,index) in statuses"
              :id="invoice.status_id > stage.id ? 'done' : invoice.status_id == stage.id ? 'active' : 'pending' "
              :key="index"
              :class="stage.name+ ' ' + 'button'"
            >
              <span class="pr-3" v-if="stage.name == 'done'">
                <i v-if="stage.id == invoice.status_id" class="zmdi zmdi-flag"></i>
                <i v-else-if="invoice.status_id > stage.id" class="zmdi zmdi-check-circle"></i>
              </span>
              {{stage.name}}
              <span></span>
              <span></span>
            </li>
          </ul>
        </div>
        <div class="col-sm-12 col-lg-2 mt-3">
          <router-link
            v-if="invoice.status_id === 1 || !invoice.approved_date"
            :to="{name:'invoices-show', params:{id:invoice.id}}"
          >
            <button class="btn btn-outline outline outline-primary__clicked pl-3 pr-3">Edit Invoice</button>
          </router-link>
          <button
            v-else-if="(invoice.status_id > 2 && invoice.status_id < 5) || !invoice.billed_date"
            @click="showSendInvoiceModal = true"
            class="btn btn-outline outline-primary pl-3 pr-3"
          >
            <span class="pr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="#0a5685"
                viewBox="0 0 24 24"
              >
                <path
                  d="M0 12l11 3.1 7-8.1-8.156 5.672-4.312-1.202 15.362-7.68-3.974 14.57-3.75-3.339-2.17 2.925v-.769l-2-.56v7.383l4.473-6.031 4.527 4.031 6-22z"
                ></path>
              </svg>
            </span> Send Invoice
          </button>
          <button
            v-else-if="!(invoice.status_id !== 4 || invoice.payment_completed_date)"
            @click="showPaymentModal = true"
            class="btn btn-outline outline-primary pl-3 pr-3"
          >
            <span class="pr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="#0a5685"
              >
                <path
                  d="M9.963 8.261c-.566-.585-.536-1.503.047-2.07l5.948-5.768c.291-.281.664-.423 1.035-.423.376 0 .75.146 1.035.44l-8.065 7.821zm-9.778 14.696c-.123.118-.185.277-.185.436 0 .333.271.607.607.607.152 0 .305-.057.423-.171l.999-.972-.845-.872-.999.972zm8.44-11.234l-3.419 3.314c-1.837 1.781-2.774 3.507-3.64 5.916l1.509 1.559c2.434-.79 4.187-1.673 6.024-3.455l3.418-3.315-3.892-4.019zm9.97-10.212l-8.806 8.54 4.436 4.579 8.806-8.538c.645-.626.969-1.458.969-2.291 0-2.784-3.373-4.261-5.405-2.29z"
                ></path>
              </svg>
            </span> Record Payment
          </button>
        </div>
      </div>
      <div class="row pl-3 pr-4 pb-4 pt-4 ignite-wrapper">
        <div class="col-sm-12 col-lg-3">
          <ul class="invoice-customer_info">
            <li>
              <svg
                width="50px"
                height="50px"
                viewBox="0 0 45 45"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <!-- Generator: Sketch 61 (89581) - https://sketch.com -->
                <g id="Work-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Invoice-6" transform="translate(-32.000000, -304.000000)">
                    <g id="Invoice---Customer" transform="translate(32.000000, 304.000000)">
                      <circle id="Oval" fill="#D5EFFF" cx="22" cy="22" r="22"></circle>
                      <g
                        transform="translate(10.000000, 10.000000)"
                        stroke="#0A5685"
                        stroke-linecap="round"
                        stroke-width="1.5"
                      >
                        <path
                          d="M7.476,7.084 C9.11834331,8.78804894 11.3833412,9.75051057 13.75,9.75000035 C14.9363094,9.75033697 16.1102269,9.50874816 17.2,9.04"
                          id="Path"
                        ></path>
                        <circle id="Oval" cx="12" cy="9.75" r="5.25"></circle>
                        <path
                          d="M18.913,20.876 C17.0835478,19.035059 14.5953726,17.9998579 12,17.9998579 C9.40462739,17.9998579 6.91645218,19.035059 5.087,20.876"
                          id="Path"
                        ></path>
                        <circle id="Oval" cx="12" cy="12" r="11.25"></circle>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </li>
            <li>
              <h6 class="text-opacity">Customer</h6>
              <h5 class="text-bold">{{customer && customer.company_name}}</h5>
            </li>
          </ul>
        </div>
        <div class="col-sm-12 col-lg-3">
          <ul class="invoice-customer_info">
            <li>
              <svg
                width="50px"
                height="50px"
                viewBox="0 0 45 45"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Work-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Invoice-6" transform="translate(-389.000000, -304.000000)">
                    <g id="Invoice---Status" transform="translate(389.000000, 304.000000)">
                      <circle id="Oval" fill="#D5EFFF" cx="22" cy="22" r="22"></circle>
                      <g
                        id="Invoice---status"
                        transform="translate(16.000000, 10.000000)"
                        stroke="#0A5685"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      >
                        <path
                          d="M11.848,11.306 C12.0421347,10.9904081 12.0504476,10.5945114 11.8697317,10.2710486 C11.6890157,9.94758569 11.3475219,9.74712305 10.977,9.747 L7.5,9.747 L7.5,0.747 L0.152,12.689 C-0.0423027635,13.0044392 -0.0506807724,13.4003308 0.130103121,13.7237078 C0.310887015,14.0470847 0.65252009,14.2473026 1.023,14.247 L4.5,14.247 L4.5,23.247 L11.848,11.306 Z"
                          id="Path"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </li>
            <li>
              <h6 class="text-opacity">Invoice Status</h6>
              <h5 class="text-bold">{{invoice && invoice.status && invoice.status.name}}</h5>
            </li>
          </ul>
        </div>
        <div class="col-sm-12 col-lg-3">
          <ul class="invoice-customer_info">
            <li>
              <svg
                width="50px"
                height="50px"
                viewBox="0 0 45 45"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Work-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Invoice-6" transform="translate(-746.000000, -304.000000)">
                    <g id="Invoice---Amount-Due" transform="translate(746.000000, 304.000000)">
                      <circle id="Oval-Copy-4" fill="#D5EFFF" cx="22" cy="22" r="22"></circle>
                      <g
                        id="Invoice---amount-due"
                        transform="translate(10.000000, 14.000000)"
                        stroke="#0A5685"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      >
                        <path
                          d="M4.125,3.5 C4.33210678,3.5 4.5,3.66789322 4.5,3.875 C4.5,4.08210678 4.33210678,4.25 4.125,4.25 C3.91789322,4.25 3.75,4.08210678 3.75,3.875 C3.75,3.66789322 3.91789322,3.5 4.125,3.5"
                          id="Path"
                        ></path>
                        <path
                          d="M16.875,8.75 C17.0821068,8.75 17.25,8.91789322 17.25,9.125 C17.25,9.33210678 17.0821068,9.5 16.875,9.5 C16.6678932,9.5 16.5,9.33210678 16.5,9.125 C16.5,8.91789322 16.6678932,8.75 16.875,8.75"
                          id="Path"
                        ></path>
                        <rect id="Rectangle" x="0.75" y="0.5" width="19.5" height="12" rx="1.5"></rect>
                        <circle id="Oval" cx="10.5" cy="6.5" r="3"></circle>
                        <path
                          d="M23.25,5 L23.25,14 C23.25,14.8284271 22.5784271,15.5 21.75,15.5 L5.25,15.5"
                          id="Path"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </li>
            <li>
              <h6 class="text-opacity">Amount Due</h6>
              <h5
                class="text-bold"
              >{{optional(invoice, 'currency.symbol', '')}} {{optional(invoice, 'amount_due', '0') }}</h5>
            </li>
          </ul>
        </div>
        <div class="col-sm-12 col-lg-3">
          <ul class="invoice-customer_info">
            <li>
              <svg
                width="50px"
                height="50px"
                viewBox="0 0 45 45"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Work-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Invoice-6" transform="translate(-1103.000000, -304.000000)">
                    <g id="Invoice---Due-Date" transform="translate(1103.000000, 304.000000)">
                      <circle id="Oval-Copy-5" fill="#D5EFFF" cx="22" cy="22" r="22"></circle>
                      <g
                        transform="translate(10.000000, 10.000000)"
                        stroke="#0A5685"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      >
                        <path
                          d="M18.75,14.2499991 L16.717,14.2499991 C16.0734033,14.2492534 15.519894,14.7055212 15.3978091,15.337433 C15.2757241,15.9693448 15.6194422,16.5989563 16.217,16.838 L18.281,17.663 C18.8771734,17.9028758 19.2196241,18.5317157 19.0976786,19.1626613 C18.9757332,19.7936069 18.4236219,20.2495512 17.781,20.2499991 L15.75,20.2499991"
                          id="Path"
                          stroke-linecap="round"
                        ></path>
                        <line
                          x1="17.25"
                          y1="14.25"
                          x2="17.25"
                          y2="13.5"
                          id="Path"
                          stroke-linecap="round"
                        ></line>
                        <line
                          x1="17.25"
                          y1="21"
                          x2="17.25"
                          y2="20.25"
                          id="Path"
                          stroke-linecap="round"
                        ></line>
                        <circle id="Oval" stroke-linecap="round" cx="17.25" cy="17.25" r="6"></circle>
                        <path
                          d="M8.25,17.25 L2.25,17.25 C1.42157288,17.25 0.75,16.5784271 0.75,15.75 L0.75,3.765 C0.75,2.93657288 1.42157288,2.265 2.25,2.265 L15.75,2.265 C16.5784271,2.265 17.25,2.93657288 17.25,3.765 L17.25,8.25"
                          id="Path"
                          stroke-linecap="round"
                        ></path>
                        <line x1="0.75" y1="6.75" x2="17.25" y2="6.75" id="Path"></line>
                        <line
                          x1="5.243"
                          y1="3.75"
                          x2="5.243"
                          y2="0.75"
                          id="Path"
                          stroke-linecap="round"
                        ></line>
                        <line
                          x1="12.743"
                          y1="3.75"
                          x2="12.743"
                          y2="0.75"
                          id="Path"
                          stroke-linecap="round"
                        ></line>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </li>
            <li>
              <h6 class="text-opacity">Due Date</h6>
              <h5
                class="text-bold"
              >{{invoice && invoice.due_date ? toLocal(invoice.due_date).format('ll') : 'N/A'}}</h5>
            </li>
          </ul>
        </div>
      </div>
      <div class="ml-3 mr-4 mt-4">
        <div class="tab-wrapper invoice-card">
          <ul class="tabs">
            <router-link :to="{name: 'invoices-send'}">
              <li>ACTIVITIES</li>
            </router-link>
          </ul>
          <div style="background:white;position:relative" class="mt-1">
            <div class="thin-overlap"></div>
            <ul class="invoice-card__items">
              <li :class="{active:invoice.status_id === 1 }">
                <div class="invoice-cart__items-wrapper">
                  <div class="item">
                    <h6 class="text-bold marker">Invoice created</h6>
                    <h6 class="text-opacity">
                      <small>{{toLocal(invoice.created_at).format('lll')}}</small>
                    </h6>
                  </div>
                  <div class="item item-row">
                    <button
                      @click="updateInvoice()"
                      :disabled="invoice.status_id !== 1 || invoice.approved_date || loading"
                      class="btn btn-outline outline-primary outline-primary__clicked pl-3 pr-3"
                    >Approve Invoice</button>
                    <router-link
                      :disabled="invoice.status_id !== 1 || invoice.approved_date"
                      :to="{name:'invoices-show', params:{id:invoice.id}}"
                    >
                      <button
                        class="btn btn-outline outline outline-primary__clicked pl-3 pr-3"
                      >Edit Invoice</button>
                    </router-link>
                  </div>
                </div>
              </li>
              <li :class="{active:invoice.status_id === 2 || invoice.status_id === 3}">
                <div class="invoice-cart__items-wrapper">
                  <div class="item">
                    <h6
                      class="text-bold marker"
                    >{{invoice.approved_date ? `Invoice has been approved by ${invoice.approver ? invoice.approver.data.first_name+' '+invoice.approver.data.last_name : 'N/A'}` : 'Invoice has not been approved'}}</h6>
                    <h6 class="text-opacity" v-if="invoice.approved_date">
                      <small>{{toLocal(invoice.approved_date).format('lll')}}</small>
                    </h6>
                  </div>
                  <div class="item item-row">
                    <button
                      :disabled="invoice.status_id > 4 || invoice.billed_date"
                      @click="showScheduleInvoiceModal = true"
                      class="btn btn-outline outline-primary pl-3 pr-3"
                    >
                      <span class="p">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#0a5685"
                        >
                          <path
                            d="M17 3v-2c0-.552.447-1 1-1s1 .448 1 1v2c0 .552-.447 1-1 1s-1-.448-1-1zm-12 1c.553 0 1-.448 1-1v-2c0-.552-.447-1-1-1-.553 0-1 .448-1 1v2c0 .552.447 1 1 1zm13 13v-3h-1v4h3v-1h-2zm-5 .5c0 2.481 2.019 4.5 4.5 4.5s4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5-4.5 2.019-4.5 4.5zm11 0c0 3.59-2.91 6.5-6.5 6.5s-6.5-2.91-6.5-6.5 2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5zm-14.237 3.5h-7.763v-13h19v1.763c.727.33 1.399.757 2 1.268v-9.031h-3v1c0 1.316-1.278 2.339-2.658 1.894-.831-.268-1.342-1.111-1.342-1.984v-.91h-9v1c0 1.316-1.278 2.339-2.658 1.894-.831-.268-1.342-1.111-1.342-1.984v-.91h-3v21h11.031c-.511-.601-.938-1.273-1.268-2z"
                          ></path>
                        </svg>
                      </span>
                    </button>
                    <button
                      :disabled="invoice.status_id > 4 || invoice.billed_date"
                      @click="showSendInvoiceModal = true"
                      class="btn btn-outline outline-primary outline-primary__clicked pl-3 pr-3"
                    >
                      <span class="pr-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          fill="#0a5685"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M0 12l11 3.1 7-8.1-8.156 5.672-4.312-1.202 15.362-7.68-3.974 14.57-3.75-3.339-2.17 2.925v-.769l-2-.56v7.383l4.473-6.031 4.527 4.031 6-22z"
                          ></path>
                        </svg>
                      </span> Send Invoice
                    </button>
                     <button
                      :disabled="invoice.status_id > 4 || invoice.billed_date || loading"
                       @click="updateInvoice(true)"
                      class="btn btn-outline outline-primary outline-primary__clicked pl-3 pr-3"
                    >
                      <span class="pr-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          fill="#0a5685"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M0 12l11 3.1 7-8.1-8.156 5.672-4.312-1.202 15.362-7.68-3.974 14.57-3.75-3.339-2.17 2.925v-.769l-2-.56v7.383l4.473-6.031 4.527 4.031 6-22z"
                          ></path>
                        </svg>
                      </span> Mark as Sent
                    </button>
                  </div>
                </div>
              </li>
              <li :class="{active:invoice.status_id === 4 }">
                <div class="invoice-cart__items-wrapper">
                  <div class="item">
                    <h6
                      class="text-bold marker"
                    >{{invoice.billed_date ? `Invoice has been sent` : `Invoice has not been sent`}}</h6>
                    <h6 class="text-opacity" v-if="invoice.billed_date">
                      <small>{{toLocal(invoice.billed_date).format('lll')}}</small>
                    </h6>
                  </div>
                  <div class="item item-row">
                    <button
                      :disabled="invoice.status_id !== 4 || invoice.payment_completed_date"
                      @click="showPaymentModal = true"
                      class="btn btn-outline outline-primary outline-primary__clicked pl-3 pr-3"
                    >
                      <span class="pr-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="#0a5685"
                        >
                          <path
                            d="M9.963 8.261c-.566-.585-.536-1.503.047-2.07l5.948-5.768c.291-.281.664-.423 1.035-.423.376 0 .75.146 1.035.44l-8.065 7.821zm-9.778 14.696c-.123.118-.185.277-.185.436 0 .333.271.607.607.607.152 0 .305-.057.423-.171l.999-.972-.845-.872-.999.972zm8.44-11.234l-3.419 3.314c-1.837 1.781-2.774 3.507-3.64 5.916l1.509 1.559c2.434-.79 4.187-1.673 6.024-3.455l3.418-3.315-3.892-4.019zm9.97-10.212l-8.806 8.54 4.436 4.579 8.806-8.538c.645-.626.969-1.458.969-2.291 0-2.784-3.373-4.261-5.405-2.29z"
                          ></path>
                        </svg>
                      </span> Record Payment
                    </button>
                  </div>
                </div>
              </li>
              <li :class="{active:invoice.status_id === 5}">
                <div class="invoice-cart__items-wrapper">
                  <div class="item">
                    <h6
                      class="text-bold marker"
                    >{{invoice.payment_completed_date ? `Invoice has been paid for` : `Invoice has not been paid for`}}</h6>
                    <h6 class="text-opacity" v-if="invoice.payment_completed_date">
                      <small>{{toLocal(invoice.payment_completed_date).format('lll')}}</small>
                    </h6>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="ml-3 mr-4 mb-5" style="margin-top:450px">
        <button disabled type="button" class="btn btn-outline pt-2">
          <h6 class="text-bold text-opacity-pale">
            <span class="pr-2">
              <svg
                fill="#0a5685"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
              >
                <path
                  d="M0 0v24h24v-24h-24zm10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"
                ></path>
              </svg>
            </span>Include Timesheets
          </h6>
        </button>
      </div>
      <div class="pr-4 pb-4 pt-4 ignite-wrapper text-center">
        <h6 class="text-opacity">Invoice Preview</h6>
      </div>
      <div class="mt-5">
        <div class="invoice-pdf">
          <div class="invoice-pdf_actions">
            <ul class="actions">
              <li>
                <span class="btn-text">
                  <small class="pr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.21 0-4 1.791-4 4s1.79 4 4 4c2.209 0 4-1.791 4-4s-1.791-4-4-4zm-.004 3.999c-.564.564-1.479.564-2.044 0s-.565-1.48 0-2.044c.564-.564 1.479-.564 2.044 0s.565 1.479 0 2.044z"
                      ></path>
                    </svg>
                  </small>
                  <small class="text-bold" @click="showPreview = !showPreview">View Invoice</small>
                </span>
              </li>
              <li>
                <span class="btn-text">
                  <small class="pr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M8 20h3v-5h2v5h3l-4 4-4-4zm11.479-12.908c-.212-3.951-3.473-7.092-7.479-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h3.5v-2h-3.5c-1.93 0-3.5-1.57-3.5-3.5 0-2.797 2.479-3.833 4.433-3.72-.167-4.218 2.208-6.78 5.567-6.78 3.453 0 5.891 2.797 5.567 6.78 1.745-.046 4.433.751 4.433 3.72 0 1.93-1.57 3.5-3.5 3.5h-3.5v2h3.5c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408z"
                      ></path>
                    </svg>
                  </small>
                  <small class="text-bold" @click="invoiceDownload()">Download as PDF</small>
                </span>
              </li>
            </ul>
          </div>
          <div class="row pt-5 p-5" v-if="showPreview">
            <iframe width="560" height="315" :src="pdfUrl"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.invoice-pdf {
  background: white;
  box-shadow: 0 7px 30px 0 rgba(217, 217, 217, 0.36);
  height: 300px;
  // margin-top: 50px;
  width: 60vw;
  margin: 0 auto;
  position: relative;
  .invoice-pdf_actions {
    position: absolute;
    top: 0;
    width: 100%;

    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      li {
        margin-right: 7px;
        margin-top: 15px;
        cursor: pointer;
      }
    }
  }
}
.thin-overlap {
  position: absolute;
  height: 90%;
  width: 0px;
  background: transparent;
  border: 0.05px dashed rgb(10, 86, 133);
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: translate(34px, 10px);
  transform: translate(34px, 10px);
  border-width: 0.01px;
  border-width: 0.1px;
  border-color: rgba(10, 86, 133, 0.2);
}
.outline-primary {
  color: #0a5685 !important;
}
.invoice-card {
  background: white;
  box-shadow: 0 1px 3px rgba(192, 147, 147, 0.09), 0 1px 2px rgba(0, 0, 0, 0.09);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  .invoice-card__items {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    li {
      width: 100%;
      height: 100px;
      background: white;
      position: relative;
      &:not(:last-child) {
        border-bottom: 1px solid #d7e1ea;
      }
      &:not(.active) {
        color: #778699;

        &::after {
          background: #72809d;
        }
      }

      &.active,
      &:hover {
        background: #d7e1ea;
        color: #0a5685;
        &::before {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 10px 0 10px;
          border-color: white transparent transparent transparent;
          position: absolute;

          transform: translate(100px, -1px);
          overflow: hidden;
        }
      }

      .invoice-cart__items-wrapper {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding-left: 5.5em;
        padding-right: 2.5em;

        .item {
          height: 60px;
          display: flex;
          //   align-items: center;
          position: relative;
          flex-direction: column;
          text-align: left;
          align-items: flex-start;
          //   align-items: flex-start;
          justify-content: center;

          &.item-row {
            flex-direction: row;
            button {
              margin-left: 10px;
            }
          }

          h6 {
            small {
              font-size: 14px;
              font-style: italic;
            }
          }

          &:first-child {
            &::before {
              content: "";
              position: absolute;
              -webkit-transform: translate(-50px, -7px);
              transform: translate(-50px, -7px);
              width: 30px;
              background: #0a5685;
              height: 1px;
            }
          }
          h6 {
            &.marker {
              font-size: 15px !important;
              position: relative;
            }
          }
          span {
            font-style: italic;
            font-size: 16px;
            margin: 0;
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background: #0a5685;
        border-radius: 10px;
        display: flex;
        transform: translate(30px, 37px);
      }
      &::before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: white transparent transparent transparent;
        position: absolute;

        transform: translate(100px, 0px);
        overflow: hidden;
      }
    }
  }
}
.tab-wrapper {
  .row {
    background: white;
    padding-bottom: 20px;
  }
  .ignite-wrapper {
    background: #fbfdff;
    transform: translateX(-12px);
    width: 100vw;
    padding-left: 30px !important;
    border-top: 2px solid #f5f5f5;
    border-bottom: 2px solid #f5f5f5;
  }
}
.unclickable {
  pointer-events: none;
}
.invoice-customer_info {
  list-style-type: none;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  li {
    &:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        height: 50px;
        width: 50px;
        border-radius: 50px;
        background: #d5efff;
        border: none;
        outline: none;
        svg {
          fill: #0a5685;
        }
      }
    }
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      padding-left: 15px;
      padding-top: 10px;
      h5 {
        font-size: 18px;
        color: #00133e;
      }
    }
  }
}
</style>

<script>
import Breadcrumbs from "@/components/Breadcrumbs/Index";
import Headers from "@/components/Layouts/Headers";
import RecordPaymentModal from "@/components/Modals/RecordPayment.vue";
import SendInvoiceModal from "@/components/Modals/SendInvoice.vue";
import ScheduleInvoiceModal from "@/components/Modals/ScheduleInvoice.vue";
// import Accordion from "@/components/Collapse/Index";
// import TabLayout from "@/components/Layouts/Projects/Tab";
import Literals from "@/helpers/dashboard/literals";
import { toUTC, toLocal } from "@/helpers/date";
import { secondsToDhms } from "@/helpers/duration";
import handleError from "@/helpers/error";
import getOrganization from "@/helpers/getOrganization";
import {optional} from "@/helpers/random";
import moment from 'moment'

export default {
  title: "Send Invoice | Invoices",
  components: {
    Headers,
    Breadcrumbs,
    RecordPaymentModal,
    SendInvoiceModal,
    ScheduleInvoiceModal
  },
  data() {
    return {
      body: { customer: {}, projects: [] },
      data: {},
      showPaymentModal: false,
      showSendInvoiceModal: false,
      showScheduleInvoiceModal: false,
      loading: false,
      token: window.localStorage.getItem("default_auth_token"),
      loaded: false,
      showPreview: false,
      pdfUrl: ""
    };
  },
  computed: {
    statuses() {
      return this.$store.getters["global/invoice_statuses"];
    },
    organizationId() {
      return getOrganization.organization_id();
    },
    breadCrumbs() {
      return [
        {
          text: "Home",
          href: "#"
        },
        {
          text: "All Invoice",
          active: true
        },
        {
          text: `#${this.$route.params.id}`,
          active: true
        }
      ];
    },
    linkToPdf() {
      return `https://docs.google.com/viewer?url=${this.pdfUrl}`;
    },
    invoice() {
      return this.$store.getters["invoices/invoice"];
    },
    projects() {
      return this.invoice.projects ? this.invoice.projects.data : [];
    },
    timeLogs() {
      return this.invoiceItems.filter(s => s.timelog);
    },
    productsAndServices() {
      //this is a combination of both expenses and timelogs
      // this.product_services = [];
      this.product_services = [
        {
          item: "Billable Hours",
          unit_price: "-",
          quantity: "1",
          tax: "0",
          amount: this.timeLogsTotalAmount
        },
        {
          item: "Project expenses",
          unit_price: "-",
          quantity: "1",
          tax: "0",
          amount: this.expensesTotalAmount
        }
      ];

      return this.product_services;
    },
    invoiceItems() {
      return this.$store.getters["invoices/invoice_items"];
    },
    invoiceItemsTotalAmount() {
      // console.log(parseInt(this.expensesTotalAmount,10) + parseInt(this.timeLogsTotalAmount,10))
      // return parseFloat(
      //   this.invoiceItems.reduce(
      //     (total, obj) => total + (obj ? obj.amount : 0),
      //     0
      //   )
      // ).toFixed(2);
      return (
        parseInt(this.expensesTotalAmount, 10) +
        parseInt(this.timeLogsTotalAmount, 10)
      );
    },
    expensesTotalAmount() {
      return parseFloat(
        this.expenses.reduce(
          (total, obj) => total + (obj ? parseFloat(obj.amount) : 0),
          0
        )
      ).toFixed(2);
    },
    timeLogsTotalAmount() {
      return parseFloat(
        this.timeLogs
          .map(s => s.timelog.data)
          .reduce((total, obj) => total + parseFloat(obj.cost), 0)
      ).toFixed(2);
    },
    expenses() {
      return this.invoiceItems.filter(s => s.expense);
    },
    customer() {
      return (
        this.invoice && this.invoice.customer && this.invoice.customer.data
      );
    },
    activities() {
      return [];
    }
  },
  methods: {
    toLocal,
    optional,
    setInvoiceStatus(invoice) {},
    invoiceDownload() {
      this.$toast.success(
        "Your download should start shortly. Ensure your browser is not blocking downloads",
        {
          position: "top-right",
          duration: 3000
        }
      );
      this.$store.dispatch("invoices/downloadPdf", this.$route.params.id);
    },
    updateInvoice(sent) {
      let r = confirm(`Are you sure you want to mark this invoice as ${sent ? 'sent' : 'approved'}`)
      if(!r) {
        return;
      }
      this.loading = true;

      let msgPass = sent ? "Invoice has been marked as sent" : "Invoice has been approved";
      let payload = {
        id: this.invoice.id,
        status_id: 4, // sent,
        billed_date: toUTC(moment()).format("YYYY-MM-DD"),
        payment_source_id: 6 // offline
      };

      let request = sent ? this.$store
        .dispatch("invoices/createUpdate", payload) : this.$store
        .dispatch("invoices/approve", this.invoice.id)

      request.then(s => {
          this.$toast.success(msgPass, {
            position: "top-right",
            duration: 3000
          });
          this.$store.dispatch("invoices/getSingle", this.invoice.id);
        })
        .catch(error => {
          let errorMessage = Literals.INVOICE_UPDATE_ERROR;
          handleError(this, error, errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  created() {
    Promise.all([
      this.$store.dispatch("invoices/getSingle", this.$route.params.id),
      this.$store.dispatch("invoices/getInvoiceItems", this.$route.params.id),
      this.$store.dispatch("global/getInvoiceStatuses")
    ]).finally(() => {
      this.loaded = true;
      if (this.$route && this.$route.query && this.$route.query.contact_id) {
        this.showSendInvoiceModal = true;
      }
      this.$store
        .dispatch("invoices/getPdf", this.$route.params.id)
        .then(url => {
          this.pdfUrl = url;
        });
    });
  }
};
</script>
