<template>
  <div class="container-fluid">
    <div class="table pt-2 pb-4 pl-3 pr-4">
      <div class="table-wrapper mt-4">
        <vuetable
          ref="vuetable"
          :api-url="api"
          :append-params="moreParams"
          :http-options="{ headers:{ 'Authorization' : 'Bearer ' + $auth.token(),'xorg' : getOrganization.organization_id() } }"
          @vuetable:loaded="loadTable"
          @vuetable:cell-clicked="gotoProject"
          :fields="columns.filter(s => s.active === true)"
          data-path="data"
          pagination-path
          :no-data-template="rowCount === null ? 'Loading data' : 'No Data Available'"
          @vuetable:pagination-data="onPaginationData"
        >
          <template slot="sn" slot-scope="props">
            <router-link
              :to="{name: 'projects-show-general', params: {id: props.rowData.id}}"
            >{{props.rowIndex + 1}}</router-link>
          </template>
          <template
            slot="billing_type_id"
            slot-scope="props"
          >{{props.rowData.billing_type && props.rowData.billing_type.name}}</template>
          <template slot="budget" slot-scope="props">{{props.rowData.estimated_hours}} hours</template>
          <template slot="actions" slot-scope="props">
            <router-link :to="{name: 'projects-show-general', params: {id: props.rowData.id}}">
              <button class="btn-lightgray-round">
                <i class="zmdi zmdi-plus pr-2"></i>View Project
              </button>
            </router-link>
          </template>
        </vuetable>
        <vuetable-pagination
          v-show="paginationData.total"
          :css="table_css.pagination"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
        <div class="text-center" v-if="rowCount <=0 || rowCount === null">
          <slot name="empty"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import getOrganization from "@/helpers/getOrganization";
import getPaginationData from "@/helpers/pagination";
import VuetablePagination from "@/components/Layouts/VueTablePagination";
import {toLocal} from "@/helpers/date";

export default {
  components: { VuetablePagination },
  data() {
    return {
      getOrganization,
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      api: this.$baseApi + `/schedule?${getOrganization.append}`,

      //   ['__checkbox','Project Title', 'Customer', 'Project Type', 'Time Budget','Start Date', 'End Date','Outstanding tasks']
      columns: [
        {
          name: "__checkbox",
          titleClass: "center aligned",
          dataClass: "center aligned",
          cvisible: false,
          active: true
        },
        {
          name: "name",
          title: "Status",
          sortField: "name",
          cvisible: true,
          active: true
        },
        {
          name: "description",
          title: "Period",
          cvisible: true,
          active: false,
          callback: s => {
            return s;
          }
        },
        {
          name: "customer",
          title: "Salary amount",
          sortField: "customer_id",
          cvisible: true,
          active: true,
          callback: d => {
            return "$4000";
          }
        },
        {
          name: "project_status",
          title: "One time compensations",
          sortField: "project_status_id",
          cvisible: true,
          active: true,
          callback: d => {
            return "$0";
          }
        },
        {
          name: "currency",
          title: "Amount paid",
          cvisible: true,
          active: false,
          callback: d => {
            return "$4,000.00";
          }
        },
        {
          name: "end_date",
          title: "Date paid",
          cvisible: true,
          active: true,
          callback: s => {
            return toLocal(s).format("MM/DD/YYYY");
          }
        }
      ],
      moreParams: {},
      paginationData: {}
    };
  },
  methods: {
    toLocal,
    getPaginationData,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    gotoProject(e) {
      this.$router.push({
        name: "projects-show-general",
        params: { id: e.id }
      });
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
    },
    setFilters(d) {
      for (let [key, value] of Object.entries(this.moreParams)) {
        if (typeof value === "object") {
          //append id to params
          this.moreParams[key + "_id"] = value.id;
        }
        if (value === "") {
          delete this.moreParams[key];
        }
      }
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onChangePage(page) {
      this.moreParams.page = page;
      this.setFilters();
    },
    refreshTable() {
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find(s => s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    }
  },
  computed: {


  },
  created() {

  }
};
</script>

