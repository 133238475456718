<template>
  <div>
    <transition name="slide-fade">
      <div class="filters-form" v-show="visibleFilter">
        <form class="d-form">
          <div class="row mt-3">
            <div class="col-sm">
              <h6 class="text-bold text-opacity-pale">Project</h6>
              <div style="position:relative">
                <multiselect
                  @input="setFilters('project_id')"
                  class="no-caret-select"
                  v-model="params['project_id']"
                  :custom-label="getProjectName"
                  :options="projects.map(type=>type.id)"
                  :show-labels="false"
                >
                  <template slot="caret">
                    <span class="close-icon" v-if="params && params.project_id">
                      <i
                        class="zmdi zmdi-close"
                        @click="params['project_id']='';setFilters('project_id')"
                      ></i>
                    </span>
                    <div v-else>
                      <div class="multiselect__select">
                        <i class="zmdi zmdi-chevron-down pt-1" style="font-size: 20px"></i>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
            <div class="col-sm">
              <h6 class="text-bold text-opacity-pale">Assigned to</h6>
              <div style="position:relative">
                <multiselect
                  @input="setFilters('employee_id');filterMembers(params)"
                  class="no-caret-select"
                  v-model="params['employee_id']"
                  :custom-label="getEmployeeName"
                  :options="employees.map(type=>type.id)"
                  :show-labels="false"
                >
                  <template slot="caret">
                    <span class="close-icon" v-if="params && params.employee_id">
                      <i
                        class="zmdi zmdi-close"
                        @click="params['employee_id']='';setFilters('employee_id')"
                      ></i>
                    </span>
                    <div v-else>
                      <div class="multiselect__select">
                        <i class="zmdi zmdi-chevron-down pt-1" style="font-size: 20px"></i>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
            <div class="col-sm">
              <h6 class="text-bold text-opacity-pale">Task Status</h6>
              <div style="position:relative">
                <multiselect
                  @input="setFilters('task_status_id')"
                  v-model="params['task_status_id']"
                  class="no-caret-select"
                  :custom-label="opt => taskStatuses.find(x => x.id == opt).name"
                  :options="taskStatuses.map(type=>type.id)"
                  :show-labels="false"
                >
                  <template slot="caret">
                    <span class="close-icon" v-if="params && params.task_status_id">
                      <i
                        class="zmdi zmdi-close"
                        @click="params['task_status_id']='';setFilters('task_status_id')"
                      ></i>
                    </span>
                    <div v-else>
                      <div class="multiselect__select">
                        <i class="zmdi zmdi-chevron-down pt-1" style="font-size: 20px"></i>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>

            <div class="col-sm">
              <h6 class="text-bold text-opacity-pale">Due Date (From)</h6>
              <div style="position:relative">
                <vc-date-picker
                  :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important; height:42px !important`,placeholder: `Start From date`}"
                  v-model="params['filter_start']"
                  @input="setFilters('filter_start',params.start_from);params['range_filter']='due_date'"
                  name="end"
                />
                <span class="close-icon" v-if="params && params.filter_start">
                  <i class="zmdi zmdi-close" @click="params['filter_start']='';params['range_filter'] = '';setFilters('filter_start')"></i>
                </span>
              </div>
            </div>
            <div class="col-sm">
              <h6 class="text-bold text-opacity-pale">- To</h6>
              <div style="position:relative">
                <vc-date-picker
                  :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important; height:42px !important`,placeholder: `End To date`}"
                  v-model="params['filter_end']"
                  @input="setFilters('filter_end');params['range_filter']='due_date'"
                  name="lend"
                />
                <span class="close-icon" v-if="params && params.filter_end">
                  <i class="zmdi zmdi-close" @click="params['filter_end']='';params['range_filter'] = '';setFilters('filter_end')"></i>
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["params", "projects", "employees", "visibleFilter", "taskStatuses"],
  methods: {
    setFilters(name) {
      this.$emit("setFilters", this.params, name);
      // this.$emit("filterMembers",this.params[name]);
    },
    filterMembers(param) {
      // console.log(id.employee_id)
      this.$emit("filterMembers",param.employee_id);
    },
    getProjectName(opt) {
      let option = this.projects.find(x => x.id == opt);
      return option && option.name;
    },
    getEmployeeName(opt) {
      let option = this.employees.find(x => x.id == opt);
      return option && option.name;
    }
  }
};
</script>
