<template>
  <div id="app">
    <nprogress-container></nprogress-container>
      <div class="preload" v-if="!authReady">
      <div class="">
        <div class="has-text-centered">
          <ripple color="black"></ripple>
        </div>
        <!-- <div class="preloader-title"><strong>Leaf</strong></div> -->
      </div>
    </div>
    <transition mode="out-in" name="view">
      <router-view v-if="authReady"/>
    </transition>
  </div>
</template>

<script>
import NprogressContainer from "vue-nprogress/src/NprogressContainer";
import Ripple from '@/components/preloaders/Ripple'
import {optional} from "@/helpers/random";

export default {
  components: {
    NprogressContainer,
    Ripple
  },
  data: () => ({
    authReady: false
  }),
  computed: {
    organizations() {
      // if user is org owner, this will exist
      let org = optional(this.$auth.user(), 'organization.data', []);


// this will always exist as long as user belongs to an org
      if(this.$auth.user() && this.$auth.user().organizations) {
        org = [org, ...optional(this.$auth.user(), 'organizations.data', [])];
      }

      let seen = [];

// we wanna only show unique orgs
      org = org.filter(s => {
        let notSeen = !seen.includes(s.id);
        seen.push(s.id);
        return notSeen;
      })

      return org;
    
    }
  },
  beforeMount() {
    this.$auth.ready(() => {
      // console.log('holla')
      let org = window.localStorage.getItem('selected_organization')
      if(org) {
        let organization = this.organizations.find(s => s.id == org)
        this.$store.dispatch("global/setActiveOrganization", organization);
      }

      this.$store.dispatch("global/getStripeKey").finally(() => {
        this.authReady = true;
      })
    });
  },
};
</script>

<style lang="scss">
/*@import '../../sass/app';*/
.lds-ripple div {
  border-color: #0a5685;
}
.preload {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .preloader-title {
    font-size: 2em;

    h6 {
      text-align: center;
    }
  }
}
.view-enter-active,
.view-leave-active {
  transition: opacity 0.1s ease-in-out;
}
.view-enter-to,
.view-leave {
  opacity: 1;
}
.view-enter,
.view-leave-to {
  opacity: 0;
}
.has-text-centered {
  padding-left: 25px
}
</style>
