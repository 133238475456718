<template>
  <div>
    <button type="submit" :disabled="loading ? true: false" class="btn btn-primary auth-btn">
      <div v-if="!loading">{{text}}</div>
      <div v-else class="spinner-grow spinner-grow-sm text-light" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  props: ["loading", "text"]
};
</script>
