<template>
  <!-- Modal -->
  <div class="invoice-modal" v-show="visible">
    <div class="invoice-modal__overlay">
      <div class="invoice-modal__overlay-wrapper">
        <div class="invoice-modal-card">
          <div class="invoice-modal-card__main">
            <div class="invoice-modal-card__main-list">
              <ul class="invoice-modal__items">
                <li :class="invoice_stage == 1 ? 'active' : invoice_stage > 1 ? 'done' : ''">
                  <button>1</button>
                </li>
                <li :class="invoice_stage == 2 ? 'active' : invoice_stage > 2 ? 'done' : '' ">
                  <button>2</button>
                </li>
                <li :class="invoice_stage == 3 ? 'active' : invoice_stage > 3 ? 'done' : ''">
                  <button>3</button>
                </li>
                <li :class="invoice_stage == 4 ? 'active' : ''">
                  <button>4</button>
                </li>
              </ul>
            </div>
            <div class="invoice-modal-card__main-content">
              <div class="invoice-modal-close pt-4 pr-3">
                <button class="close" @click="cancel">
                  Cancel
                  <i class="zmdi zmdi-close-circle pl-2" style="font-size: 20px;"></i>
                </button>
              </div>

              <div class="invoice-modal-card-content pt-5">
                <div class="invoice-modal-title text-center">
                  <button class="invoice-modal-title__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M8 24l2.674-9h-9.674l16-15-2.674 9h8.674l-15 15zm-1.586-11h6.912l-1.326 4 5.739-6h-6.065l1.304-4-6.564 6z"
                      ></path>
                    </svg>
                  </button>
                  <h5 class="text-bold h7 pt-2">Generate Invoice</h5>
                </div>
                <div class="invoice-modal-content pt-5 pl-3 pr-3">
                  <form class="d-form">
                    <div v-if="invoice_stage ==1">
                      <h5 class="text-opacity-pale text-bold">Select Invoice Period</h5>

                      <ul class="invoice-select-list mt-3">
                        <li
                          @click="setInvoicePeriod(index)"
                          :class="selected_period.name === period.name ? 'active' : ''"
                          v-for="(period,index) in invoice_period"
                          :key="index"
                        >
                          <span class="pt-1">
                            <svg
                              v-if="selected_period.name === period.name"
                              fill="#0a5685"
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M0 0v24h24v-24h-24zm10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"
                              ></path>
                            </svg>
                            <svg
                              v-else
                              xmlns="http://www.w3.org/2000/svg"
                              fill="#E4E4E4"
                              width="17"
                              height="17"
                              viewBox="0 0 24 24"
                            >
                              <path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24z"></path>
                            </svg>
                          </span>
                          <aside class="pl-3">
                            <h6 class="text-bold h7 text-opacity-pale">{{period.name}}</h6>
                            <div
                              class="mt-2"
                              v-if="period.can_select && selected_period.name === period.name"
                            >
                              <div class="row mt-3">
                                <div class="col-sm-6">
                                  <vc-date-picker
                                    :masks="{ input: ['YYYY-MM-DD']}"
                                    :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: ` Start date`}"
                                    v-model="period.from"
                                    v-validate="'after:startFrom'"
                                    name="end_date"
                                    data-vv-as="Start Date"
                                  />
                                  <small class="dpicker-icon">
                                    <i class="zmdi zmdi-calendar-alt"></i>
                                  </small>
                                </div>
                                <div class="col-sm-6">
                                  <vc-date-picker
                                    :masks="{ input: ['YYYY-MM-DD']}"
                                    :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: ` End date`}"
                                    v-model="period.to"
                                    v-validate="'after:startFrom'"
                                    name="end_date"
                                    data-vv-as="End Date"
                                  />
                                  <small class="dpicker-icon">
                                    <i class="zmdi zmdi-calendar-alt"></i>
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div v-if="!period.can_select">
                              <small
                                class="text-opacity"
                              >Period between {{period.from}} - {{period.to}}</small>
                            </div>
                          </aside>
                        </li>
                      </ul>
                    </div>
                    <div v-if="invoice_stage == 2">
                      <h5 class="text-opacity-pale text-bold">Select Project</h5>
                      <ul class="invoice-select-list mt-3">
                        <li
                                @click="setProjects(project)"
                                v-for="(project,index) in projects"
                                :key="index"
                        >
                          <span class>
                            <svg
                              v-if="selected_project.includes(project.id)"
                              fill="#0a5685"
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M0 0v24h24v-24h-24zm10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"
                              ></path>
                            </svg>
                            <svg
                                    v-else
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#E4E4E4"
                                    width="17"
                                    height="17"
                                    viewBox="0 0 24 24"
                            >
                              <path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24z"></path>
                            </svg>
                          </span>
                          <aside class="pl-3 pt-1">
                            <h6 class="text-bold h7 text-opacity-pale">{{project.name}}</h6>
                          </aside>
                        </li>
                      </ul>
                      <small
                              class="form-text text-danger"
                              v-show="errors.has('currency_id')"
                      >{{errors.first('currency_id')}}</small>
                    </div>
                    <div v-if="invoice_stage == 3">
                      <h5 class="text-opacity-pale text-bold">Invoice items</h5>
                      <div class="mt-4 invoice-selected-project text-bold text-opacity-pale p-4">
                        <span>{{getProjectName(selected_project)}}</span>
                      </div>
                      <ul class="invoice-select-list mt-3">
                        <li
                          @click="setInvoiceItem(item.id)"
                          :class="selected_invoice_option.includes(item.id)  ? 'active' : ''"
                          v-for="(item,index) in invoice_items_option"
                          :key="index"
                        >
                          <span class="pt-1">
                            <svg
                              v-if="selected_invoice_option.includes(item.id)"
                              fill="#0a5685"
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M0 0v24h24v-24h-24zm10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"
                              ></path>
                            </svg>
                            <svg
                              v-else
                              xmlns="http://www.w3.org/2000/svg"
                              fill="#E4E4E4"
                              width="17"
                              height="17"
                              viewBox="0 0 24 24"
                            >
                              <path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24z"></path>
                            </svg>
                          </span>
                          <aside class="pl-3">
                            <h6 class="text-bold h7 text-opacity-pale">Include {{item.name}}</h6>
                            <div>
                              <small
                                class="text-opacity"
                              >This project contains {{item.number}} {{item.name}}</small>
                            </div>
                          </aside>
                        </li>
                      </ul>
                    </div>

                    <div v-if="invoice_stage==4" class="text-center">
                      <svg
                        class="ft-green-tick"
                        xmlns="http://www.w3.org/2000/svg"
                        height="70"
                        width="70"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <circle class="circle" fill="#5bb543" cx="24" cy="24" r="22"></circle>
                        <path
                          class="tick"
                          fill="none"
                          stroke="#FFF"
                          stroke-width="6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-miterlimit="10"
                          d="M14 27l5.917 4.917L34 17"
                        ></path>
                      </svg>
                      <h5 class="text-bold pt-3">Invoice Generated Successfully</h5>
                      <h6 style="width:60%;margin:0 auto;line-height:23px" class="text-opacity">
                        Your invoice has been generated with REF,
                        <b>#{{invoice.id}}</b>. You can click on the link below to see invoice or close the modal to continue working on this page
                      </h6>
                      <router-link :to="{name:'invoices-show', params:{id:invoice.id}}">
                        <button class="btn btn-primary auth-btn mt-3">
                          Open Invoice
                          <i class="zmdi zmdi-arrow-right-top pl-2"></i>
                        </button>
                      </router-link>
                    </div>
                  </form>
                </div>
              </div>

              <div class="invoice-modal-card__footer pl-3 pt-5 pr-3">
                <div class="row" v-show="invoice_stage < 4">
                  <div class="col-sm-6">
                    <button
                      @click="prevStage"
                      v-if="invoice_stage > 1"
                      class="btn btn-ds btn-transparent"
                    >Previous</button>
                  </div>
                  <div class="col-sm-6 text-right">
                    <button :disabled="loading" @click="nextStage" class="btn btn-ds pl-5 pr-5">
                      <div v-if="!loading">Next</div>
                      <div v-else class="spinner-grow spinner-grow-sm text-light" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@supports (animation: grow 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards) {
  .tick {
    stroke-opacity: 0;
    stroke-dasharray: 29px;
    stroke-dashoffset: 29px;
    animation: draw 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards;
    animation-delay: 0.6s;
  }

  .circle {
    fill-opacity: 0;
    stroke: #219a00;
    stroke-width: 16px;
    transform-origin: center;
    transform: scale(0);
    animation: grow 1s cubic-bezier(0.25, 0.25, 0.25, 1.25) forwards;
  }
}

@keyframes grow {
  60% {
    transform: scale(0.8);
    stroke-width: 4px;
    fill-opacity: 0;
  }
  100% {
    transform: scale(0.9);
    stroke-width: 8px;
    fill-opacity: 1;
    fill: #219a00;
  }
}

@keyframes draw {
  0%,
  100% {
    stroke-opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

button {
  &.btn-transparent {
    background: transparent;
    color: #0a5685 !important;
    opacity: 0.7;

    &:hover {
      color: #0a5685 !important;
      opacity: 1;
    }
  }
}
div {
  &.invoice-selected-project {
    background: #d7e1ea;
    border-radius: 5px;
  }
}
.invoice-modal {
  position: relative;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;

  .invoice-modal__overlay {
    background: linear-gradient(rgba(20, 20, 20, 0.5), rgba(20, 20, 20, 0.5));
    height: 100vh;
    position: fixed;
    width: 100%;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    .invoice-modal__overlay-wrapper {
      position: relative;
      max-width: 750px;
      margin-top: 70px !important;
      border: 0px;
      margin: 0 auto;

      .invoice-modal-card {
        position: relative;
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        // border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0.3rem;
        outline: 0;
        height: 650px;
        margin: 0 auto;

        .invoice-modal-card__main {
          display: grid;
          grid-template-columns: 13% 87%;
          grid-template-rows: 100%;
          position: relative;
          height: 100%;

          .invoice-modal-card__main-list {
            background: #f5f5f5;
            height: inherit;
            border-top-left-radius: 0.3rem;
            border-bottom-left-radius: 0.3rem;

            .invoice-modal__items {
              position: absolute;
              display: flex;
              flex-direction: column;
              justify-content: center;
              //   align-items: center;
              height: inherit;
              //   height: 100%;
              width: 13%;

              li {
                padding-bottom: 70px;
                position: relative;
                display: flex;
                justify-content: center;
                flex-direction: row;

                button {
                  height: 35px;
                  width: 35px;
                  border-radius: 35px;
                  font-size: 13px;
                  background: transparent;
                  border: 2px solid #e4e4e4;
                  color: #e4e4e4;
                  outline: none;

                  &:after {
                    content: "";
                    height: 50px;
                    width: 1px;
                    position: absolute;
                    background: #e4e4e4;
                    //   bottom: 10px;
                    transform: translate(-4px, 34px);
                  }
                }
                &.active {
                  button {
                    background: #0a5685;
                    border: 2px solid #0a5685;
                    color: white;
                  }
                  &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    background: #0a5685;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    height: 35px;
                    width: 5px;
                  }
                }
                &.done {
                  button {
                    background: #616263;
                    border: 2px solid #616263;
                    color: #616263;

                    &:before {
                      content: "✓";
                      color: white;
                      font-size: 14px;
                      position: absolute;
                      transform: translate(-3px);
                    }

                    &:after {
                      background: #0a5685;
                    }
                  }
                }
              }
              li {
                &:last-child {
                  button {
                    &:after {
                      // display: none;
                      background: transparent;
                    }
                  }
                }
              }
            }
          }
          .invoice-modal-card-content {
            .invoice-modal-title {
              .invoice-modal-title__icon {
                background: #edf3ff;
                border: none;
                height: 40px;
                width: 40px;
                border-radius: 5px;
                svg {
                  fill: #0a5685 !important;
                }
              }
              h5 {
                font-size: 18px !important;
                color: #0a5685;
              }
            }
            .invoice-modal-content {
              h5 {
                font-size: 16px !important;
              }
              .invoice-select-list {
                display: grid;
                li {
                  small {
                    font-size: 13px !important;
                  }
                  h6 {
                    font-size: 15px !important;
                    margin: 0;
                  }

                  border: 2px solid #f5f5f5;
                  padding: 17px;
                  padding-left: 15px;
                  border-radius: 5px;
                  margin-bottom: 10px;
                  cursor: pointer;

                  &.active {
                    border-color: #0a5685;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
 {
  opacity: 0;
}
</style>

<script>
import { setTimeout } from "timers";
import { toLocal, toUTC } from "@/helpers/date";
import moment from "moment";
import getOrganization from "@/helpers/getOrganization";
import Literals from "@/helpers/dashboard/literals";
import handleError from "@/helpers/error";

export default {
  props: ["visible", "customer", "timelogs", "expenses"],
  data() {
    return {
      loading: false,
      invoice: {},
      selected_invoice_option: [],
      selected_period: {},
      selected_project: [],
      invoice_stage: 1
    };
  },
  computed: {
    projects() {
      return this.$store.getters["customers/projects"];
    },
    selectedProjects() {
      return this.projects.filter(s => this.selected_project.includes(s.id));
    },
    invoice_items_option() {
      let projects = this.projects
        .filter(s => this.selected_project.includes(s.id))
        .map(d => {
          return {
            timeLogs: d.time_logs,
            expenses: d.expenses.data
          };
        });
      let timeLogCount = projects
        .map(c => c.timeLogs.length)
        .reduce((a, b) => a + b, 0);
      let expenseCount = projects
        .map(c => c.expenses.length)
        .reduce((a, b) => a + b, 0);
      this.selected_invoice_option = ["has_expense", "has_timelog"];
      return [
        {
          id: "has_timelog",
          name: "billable time entries",
          number: timeLogCount
        },
        {
          id: "has_expense",
          name: "logged expenses",
          number: expenseCount
        }
      ];
    },
    invoice_period() {
      let lastMonth = toLocal(moment().subtract(1, "M")).format("ll");
      let today = toLocal(moment()).format("ll");
      let customerCreation = toLocal(this.customer.created_at).format("ll");

      return [
        { name: "Last month", from: lastMonth, to: today },
        { name: "All time", from: customerCreation, to: today },
        { name: "Custom period", from: "", to: "", can_select: true }
      ];
    },
  },
  methods: {
    handleError,
    cancel() {
      this.invoice_stage = 1;
      this.$emit("cancel");
    },
    submit() {
      this.loading = true;
      let data = {
        template_id: null,
        start_date: toUTC(Date.now()).format("YYYY-MM-DD"),
        end_date: toUTC(this.selected_period.to).format("YYYY-MM-DD"),
        customer_id: this.customer.id,
        organization_id: getOrganization.organization_id(),
        tax_id: null,
        currency_id: this.selectedProjects[0] ? this.selectedProjects[0].currency_id : null,
        discount_percent: 0,
        created_by: this.$auth.user().id,
        approved_by: null,
        hours: null,
        is_grouped: true,
        payment_instructions: null
      };
      Object.values(this.selected_invoice_option).forEach(key => {
        data[key] = true;
      });

      let msgPass = Literals.INVOICE_CREATE_SUCCESS;
      this.$store
        .dispatch("invoices/createUpdate", data)
        .then(parent => {

          let queue = [];
          for(let i in this.selectedProjects) {
            data.is_grouped = false;
            data.amount = this.selectedProjects[i].actual_cost;
            data.hourly_rate = this.selectedProjects[i].hourly_rate;
            data.parent_invoice_id = parent.id;
            data.project_id = this.selectedProjects[i].id;
            data.currency_id = this.selectedProjects[i].currency_id;
            queue.push(this.$store
                    .dispatch("invoices/createUpdate", data))
          }
          Promise.all(queue).then(data => {
            this.invoice = parent;
            this.invoice_stage = this.invoice_stage + 1;
            this.$toast.success(msgPass, {
              position: "top-right",
              duration: 3000
            });
          }).catch(error => {
              let errorMessage = "Error occurred while creating projects invoice";
              let vm = this
              handleError(vm, error, errorMessage);
          })
        })
              .catch(error => {
                  let errorMessage = "Failed to create Invoice";
                  let vm = this
                  handleError(vm, error, errorMessage);
              })
              .finally(() => {
                this.loading = false;
              });
    },
    setInvoicePeriod(index) {
      this.selected_period = this.invoice_period[index];
    },
    setProjects(project) {
      if (this.selected_project.find(s => s == project.id)) {
        this.selected_project.splice(this.selected_project.indexOf(project.id), 1);
      } else {
        // if one or more projects have been selected before
        // check if the current project currency differs from the currency of previously selected projects

        // previously selected projects;
        let projects = this.projects.filter(s => this.selected_project.includes(s.id));
        if (
                this.selected_project.length > 0
                && projects.filter(s => s.currency_id != project.currency_id).length > 0
        ) {
          return this.errors.add({
            field: "currency_id",
            msg: "All selected projects must have the same currency"
          });
        }
        this.selected_project.push(project.id);
      }

    },
    setInvoiceItem(id) {
      if (!this.selected_invoice_option.includes(id)) {
        this.selected_invoice_option.push(id);
      } else {
        this.selected_invoice_option.splice(this.selected_invoice_option.indexOf(id),1);
      }
    },
    getProjectName(ids) {
      return this.projects.filter(s => ids.includes(s.id)).map(d => d.name).join(', ');
    },
    nextStage() {
      if (this.invoice_stage !== 4) {
        if (this.invoice_stage == 3) {
          this.submit();
        } else {
          this.invoice_stage = this.invoice_stage + 1;
        }
      }
    },
    prevStage() {
      if (this.invoice_stage !== 1) {
        this.invoice_stage = this.invoice_stage - 1;
      }
    }
  },
  created() {
    this.$store.dispatch("customers/getProjects", this.$route.params.id);
  }
};
</script>
