export default {
    SET_TASK(state, array) {
        state.task = array;
    },
    SET_TIME_LOGS(state, array) {
        state.time_logs = array;
    },
    SET_ALL_TIME_LOGS(state, array) {
        state.all_time_logs = array;
    },
    SET_EXPENSES(state, array) {
        state.expenses = array;
    },
    SET_TASKS(state, array) {
        state.tasks = array;
    },
    SET_COMMENTS(state, array) {
        state.comments = array
    },
    SET_ATTACHMENTS(state, array) {
        state.attachments = array
    },
    SET_DASHBOARD_METRICS(state,array) {
        state.metrics = array;
    },
    SET_TABLE_TYPE(state, array) {
        state.table_type = array;
    },
    SET_CACHED(state, array) {
        state.cached = array;
    },
}
