var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid",class:{ blur: !_vm.apiLoaded }},[_c('div',{staticClass:"table pb-4 pl-3 pr-4"},[_c('div',{staticClass:"table-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-lg-4 float-left text-left"},[_c('small',{staticClass:"text-opacity"},[_vm._v(_vm._s(this.$refs.vuetable && this.$refs.vuetable.selectedTo && this.$refs.vuetable.selectedTo.length)+" Employees selected")])]),_c('div',{staticClass:"col-sm-12 col-lg-8 float-right text-right"},[_c('payment-filters',{attrs:{"columns":_vm.columns,"params":_vm.moreParams,"visibleFilter":_vm.visibleFilter},on:{"setFilters":_vm.setFilters,"showMoreFilters":_vm.showVisibleFilter,"removeColumnFromTable":_vm.removeColumnFromTable}})],1)])]),_c('div',{staticClass:"table-wrapper mt-4"},[_c('vuetable',{ref:"vuetable",attrs:{"first-page":0,"api-mode":false,"track-by":'employee_id',"data":_vm.employees,"fields":_vm.columns.filter(function (s) { return s.active === true; }),"pagination-path":"","no-data-template":_vm.rowCount === null ? 'Loading data' : 'No Data Available'},on:{"vuetable:loaded":_vm.loadTable,"vuetable:cell-clicked":_vm.gotoInvoice,"vuetable:pagination-data":_vm.onPaginationData},scopedSlots:_vm._u([{key:"sn",fn:function(props){return [_c('router-link',{attrs:{"to":{ name: 'users-show', params: { id: _vm.optional(_vm.allEmployees.find(function (s) { return s.id == props.rowData.employee_id; }), 'user_id', null) } }}},[_vm._v(_vm._s(props.rowIndex + 1))])]}},{key:"hourly_rate",fn:function(ref){
var rowData = ref.rowData;
return [_c('h6',{staticClass:"text-bold h7"},[_vm._v(" "+_vm._s(rowData.currency_symbol)+" "+_vm._s(rowData.hourly_rate == 0 ? rowData.salary_rate : rowData.hourly_rate)+" ")])]}},{key:"amount",fn:function(ref){
var rowData = ref.rowData;
return [_c('h6',{staticClass:"text-bold h7"},[_vm._v(" "+_vm._s(rowData.currency_symbol)+" "+_vm._s(_vm.numberWithCommas( parseFloat(rowData.amount_payable || 0).toFixed(2) ))+" ")])]}},{key:"logged_hours",fn:function(ref){
var rowData = ref.rowData;
return [_c('h6',{staticClass:"text-bold h7"},[_vm._v(_vm._s(rowData.hours_logged || 0))])]}},{key:"additional_earnings",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-form",staticStyle:{"position":"relative","width":"100%"}},[_c('span',{attrs:{"id":"discount_label"}},[_vm._v(_vm._s(rowData.currency_symbol))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(rowData.additional_earnings),expression:"rowData.additional_earnings"}],staticClass:"form-control auth-input d-input pl-4",staticStyle:{"width":"150px","margin-top":"0px"},attrs:{"type":"number","name":"discount","id":"discount"},domProps:{"value":(rowData.additional_earnings)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(rowData, "additional_earnings", $event.target.value)}}})])]}},{key:"payment_method",fn:function(ref){
var rowData = ref.rowData;
return [_c('multiselect',{staticClass:"mobile_dropdown",attrs:{"track-by":"id","multiple":false,"return":"id","label":"name","name":"payment_source","placeholder":"Select Payment Method","options":_vm.paymentSources,"show-labels":false,"data-vv-as":"Payment Method"},model:{value:(rowData.payment_source),callback:function ($$v) {_vm.$set(rowData, "payment_source", $$v)},expression:"rowData.payment_source"}})]}},{key:"actions",fn:function(props){return [_c('router-link',{attrs:{"to":{
              name: 'users-show',
              params: { id: _vm.optional(_vm.allEmployees.find(function (s) { return s.id == props.rowData.employee_id; }), 'user_id', null) },
            }}},[_c('button',{staticClass:"btn-lightgray-round"},[_c('i',{staticClass:"zmdi zmdi-plus pr-2"}),_vm._v("View Employee ")])])]}}])}),_c('vuetable-pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.paginationData.total),expression:"paginationData.total"}],ref:"pagination",attrs:{"css":_vm.table_css.pagination},on:{"vuetable-pagination:change-page":_vm.onChangePage}}),(_vm.rowCount <= 0 || _vm.rowCount === null)?_c('div',{staticClass:"text-center"},[_vm._t("empty")],2):_vm._e()],1),_c('div',{staticClass:"mt-3 text-right"},[_c('button',{staticClass:"btn btn-outline outline-primary text-primary pl-3 pr-3 mr-3",on:{"click":function($event){return _vm.cancelPayroll()}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"btn btn-outline outline-primary outline-primary__clicked pl-3 pr-3",attrs:{"disabled":_vm.loading},on:{"click":_vm.save}},[_vm._v(" Save and Continue ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }