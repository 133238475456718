<template>
  <div>
    <div class="table">
      <div class="table-wrapper">
        <div
          id="expensesTable"
          style="padding-left:0px !important"
          class="no-padding container-fluid"
        >
          <vuetable
            ref="vuetable"
            :css="table_css"
            :api-url="api"
            :first-page="0"
            :append-params="moreParams"
            :http-options="{ headers:{ 'Authorization' : 'Bearer ' + $auth.token(),'xorg' : getOrganization.organization_id() } }"
            @vuetable:loaded="loadTable"
            :fields="columns.filter(s => s.active === true)"
            data-path="data"
            pagination-path
            @vuetable:pagination-data="onPaginationData"
            :no-data-template="rowCount === null ? 'Loading data' : 'No Data Available'"
          >
            <template slot="sn" slot-scope="props">
              <router-link
                :to="{name: 'projects-show-general', params: {id: props.rowData.id}}"
              >{{props.rowIndex + 1}}</router-link>
            </template>
            <template
              slot="amount"
              slot-scope="props"
            >{{props.rowData.currency && props.rowData.currency.symbol}} {{props.rowData.amount}}</template>
            <!-- <template slot="task" slot-scope="props">
              <router-link
                v-if="props.rowData.task"
                :to="{name: 'tasks-show', params: {id: props.rowData.task.id}}"
              >{{props.rowData.task.name}}</router-link>
            </template> -->
            <template slot="project" slot-scope="props">
              <span
                v-b-tooltip.hover
                :title="props.rowData.project && props.rowData.project.data && props.rowData.project.data.name"
              >{{props.rowData.project && props.rowData.project.data && props.rowData.project.data.name | truncate(20)}}</span>
            </template>
            <template slot="task" slot-scope="props">
              <span v-b-tooltip.hover :title="optional(props.rowData, 'task.data.name', 'N/A')">{{ optional(props.rowData, 'task.data.name', 'N/A') | truncate(30)}} </span>
            </template>
            <template slot="actions" slot-scope="props">
               <button v-if="optional($auth.user(), 'org_role.role_id') == 1"
                        v-b-tooltip.hover
                        title="Approve Expense"
                        :disabled="loading"
                        class="btn-lightgray-round-secondary btn-danger ml-2"
                        @click="approveExpense(props.rowData.id)"
                      >
                         <i class="zmdi zmdi-check"></i>
                      </button>
            </template>
          </vuetable>
          <vuetable-pagination
            v-show="paginationData.total"
            :css="table_css.pagination"
            class="pl-2 pt-3 pr-2"
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          ></vuetable-pagination>
          <div class="text-center" v-if="rowCount <=0 || rowCount === null">
            <slot name="empty"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import moment from "moment";
import getOrganization from "@/helpers/getOrganization";
import VuetablePagination from "@/components/Layouts/VueTablePagination";
import getPaginationData from "@/helpers/pagination";
import { toLocal } from "@/helpers/date";
import truncate from "@/mixins/truncate";
import {optional} from "@/helpers/random";

export default {
  components: { VuetablePagination },
  mixins: [truncate],
  props: {
    api: {
      type: String,
      default: `${process.env.VUE_APP_BASE_URI}/expenses`,
      required: false
    },
    moreParams: {
      required: false
    }
  },
  data() {
    return {
      loading:false,
      getOrganization,
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      columns: [
        {
          name: "__checkbox",
          titleClass: "center aligned",
          dataClass: "center aligned",
          cvisible: false,
          active: true
        },
        {
          name: "subject",
          title: "Subject",
          cvisible: true,
          active: true
        },
        {
          name: "__slot:project",
          title: "Project",
          cvisible: true,
          active: true
        },
        {
          name: "__slot:amount",
          title: "Amount",
          sortField: "amount",
          cvisible: true,
          active: true
        },
        {
          name: "category",
          title: "Expense Category",
          sortField: "expense_category_id",
          cvisible: true,
          active: true,
          callback: d => {
            return d ? d.name : "N/A";
          }
        },
        {
          name: "__slot:task",
          title: "Task",
          cvisible: true,
          active: true,
          
        },
        {
          name: "is_reimbursed",
          title: "Is Reimbursable",
          cvisible: true,
          active: true,
          callback: s => {
            return s ? "Yes" : "No";
          }
        },
        {
          name: "expense_date",
          title: "Expense Date",
          cvisible: true,
          active: true,
          callback: d => {
            return toLocal(d).format("MM/DD/YYYY");
          }
        },
         {
          name: "__slot:actions",
          title: "Actions",
          cvisible: true,
          active: true,
          
        }
      ],
      // moreParams: {
      //   include: "project,task,category",
      //   per_page: 10
      // },
      paginationData: {}
    };
  },
  methods: {
    optional,
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
      this.$emit("getExpensesMeta", this.$refs.vuetable.tablePagination);
    },
    setFilters(d) {
      let sm = this;
      // this.moreParams = this.moreParams.filter(s => typeof s !== "object");
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },

    refreshTable() {
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find(s => s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    },
    approveExpense(id) {
      this.loading = true;
      this.$store.dispatch('tasks/approveExpense', id)
      .then(() => {
        this.$toast.success('Expense marked as approved', {
            position: "top-right",
            duration: 3000
          });
      }).catch(() => {
        this.$toast.error('Failed to approve expense. Please try again.', {
            position: "top-right",
            duration: 3000
          });
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  computed: {},
  mounted() {},
  watch: {
    moreParams: {
      deep: true,
      handler(m) {
        this.setFilters();
      }
    },
    $route(to, from) {
      this.refreshTable();
    }
    // deep: true
  }
};
</script>

