<template>
  <div class="container-fluid" v-if="loaded" :class="{ blur: !loaded }">
    <div class="table pb-4 pl-3 pr-4">
      <div class="table-header">
        <div class="row">
          <div class="col-sm-12 col-lg-2 float-left text-left">
            <div class="row mt-1">
              <div class="col-sm-12 col-md-12">
                <div class>
                  <div class>
                    <multiselect
                      track-by="name"
                      label="name"
                      class="table-select"
                      v-validate="'required'"
                      v-model="table_mode.type"
                      @input="setTableType(table_mode.type)"
                      name="country"
                      :options="tableModeOptions"
                      :show-labels="false"
                      :custom-label="customTableOptionLabel"
                    >
                      <template slot="caret">
                        <div>
                          <div class="multiselect__select">
                            <i
                              class="zmdi zmdi-chevron-down pt-1"
                              style="font-size: 20px"
                            ></i>
                          </div>
                        </div>
                      </template>
                      <template slot="singleLabel" slot-scope="props">
                        <span
                          v-if="props.option && props.option.name === 'Table'"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                          >
                            <g
                              fill="none"
                              fill-rule="evenodd"
                              transform="translate(-2 -2)"
                            >
                              <path
                                fill="#FFF"
                                fill-opacity="0"
                                d="M0 0H24V24H0z"
                              ></path>
                              <rect
                                width="17.182"
                                height="17.182"
                                x="3.409"
                                y="3.407"
                                stroke="#254050"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                rx="1.227"
                              ></rect>
                              <path
                                stroke="#254050"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                d="M3.409 8.316L20.591 8.316M8.318 8.316L8.318 20.588M15.682 8.316L15.682 20.588"
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <span
                          v-else-if="
                            props.option && props.option.name === 'Table Groups'
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                          >
                            <g
                              fill="none"
                              fill-rule="evenodd"
                              transform="translate(-2 -2)"
                            >
                              <path
                                fill="#FFF"
                                fill-opacity="0"
                                d="M0 0H24V24H0z"
                              ></path>
                              <rect
                                width="17.182"
                                height="17.182"
                                x="3.614"
                                y="3.611"
                                stroke="#254050"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                rx="1.227"
                              ></rect>
                              <path
                                stroke="#254050"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                d="M3.614 12.202L4.841 12.202M7.295 12.202L8.523 12.202M10.977 12.202L13.432 12.202M15.886 12.202L17.114 12.202M19.568 12.202L20.795 12.202M12.205 3.611L12.205 4.838M12.205 7.293L12.205 8.52M12.205 15.884L12.205 17.111M12.205 19.566L12.205 20.793M12.205 10.975L12.205 13.429"
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <span
                          v-else-if="
                            props.option && props.option.name === 'Kanban'
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                          >
                            <g
                              fill="none"
                              fill-rule="evenodd"
                              transform="translate(-2 -2)"
                            >
                              <path
                                fill="#FFF"
                                fill-opacity="0"
                                d="M0 0H24V24H0z"
                              ></path>
                              <rect
                                width="7.875"
                                height="16.875"
                                x="8.063"
                                y="3.56"
                                stroke="#254050"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                rx="1.125"
                              ></rect>
                              <rect
                                width="2.25"
                                height="12.375"
                                x="3.563"
                                y="5.81"
                                stroke="#254050"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                rx=".563"
                              ></rect>
                              <rect
                                width="2.25"
                                height="12.375"
                                x="18.188"
                                y="5.81"
                                stroke="#254050"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                rx=".563"
                              ></rect>
                            </g>
                          </svg>
                        </span>
                        <span v-else>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="20"
                            viewBox="0 0 18 20"
                          >
                            <g
                              fill="none"
                              fill-rule="evenodd"
                              transform="translate(-3 -2)"
                            >
                              <path
                                fill="#FFF"
                                fill-opacity="0"
                                d="M0 0H24V24H0z"
                              ></path>
                              <rect
                                width="15.75"
                                height="14.625"
                                x="4.375"
                                y="5.813"
                                stroke="#254050"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                rx="1.125"
                              ></rect>
                              <path
                                stroke="#254050"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                d="M4.375 10.313L20.125 10.313M8.875 7.5L8.875 3.563M15.625 7.5L15.625 3.563"
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <span class="option__desc pl-2">
                          <span class="option__title">
                            {{ props.option && props.option.name }}
                          </span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <!-- todo: Temporal fix for including svg as props -->
                        <span
                          v-if="props.option && props.option.name === 'Table'"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                          >
                            <g
                              fill="none"
                              fill-rule="evenodd"
                              transform="translate(-2 -2)"
                            >
                              <path
                                fill="#FFF"
                                fill-opacity="0"
                                d="M0 0H24V24H0z"
                              ></path>
                              <rect
                                width="17.182"
                                height="17.182"
                                x="3.409"
                                y="3.407"
                                stroke="#254050"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                rx="1.227"
                              ></rect>
                              <path
                                stroke="#254050"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                d="M3.409 8.316L20.591 8.316M8.318 8.316L8.318 20.588M15.682 8.316L15.682 20.588"
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <span
                          v-else-if="
                            props.option && props.option.name === 'Table Groups'
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                          >
                            <g
                              fill="none"
                              fill-rule="evenodd"
                              transform="translate(-2 -2)"
                            >
                              <path
                                fill="#FFF"
                                fill-opacity="0"
                                d="M0 0H24V24H0z"
                              ></path>
                              <rect
                                width="17.182"
                                height="17.182"
                                x="3.614"
                                y="3.611"
                                stroke="#254050"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                rx="1.227"
                              ></rect>
                              <path
                                stroke="#254050"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                d="M3.614 12.202L4.841 12.202M7.295 12.202L8.523 12.202M10.977 12.202L13.432 12.202M15.886 12.202L17.114 12.202M19.568 12.202L20.795 12.202M12.205 3.611L12.205 4.838M12.205 7.293L12.205 8.52M12.205 15.884L12.205 17.111M12.205 19.566L12.205 20.793M12.205 10.975L12.205 13.429"
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <span
                          v-else-if="
                            props.option && props.option.name === 'Kanban'
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                          >
                            <g
                              fill="none"
                              fill-rule="evenodd"
                              transform="translate(-2 -2)"
                            >
                              <path
                                fill="#FFF"
                                fill-opacity="0"
                                d="M0 0H24V24H0z"
                              ></path>
                              <rect
                                width="7.875"
                                height="16.875"
                                x="8.063"
                                y="3.56"
                                stroke="#254050"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                rx="1.125"
                              ></rect>
                              <rect
                                width="2.25"
                                height="12.375"
                                x="3.563"
                                y="5.81"
                                stroke="#254050"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                rx=".563"
                              ></rect>
                              <rect
                                width="2.25"
                                height="12.375"
                                x="18.188"
                                y="5.81"
                                stroke="#254050"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                rx=".563"
                              ></rect>
                            </g>
                          </svg>
                        </span>
                        <span v-else>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="20"
                            viewBox="0 0 18 20"
                          >
                            <g
                              fill="none"
                              fill-rule="evenodd"
                              transform="translate(-3 -2)"
                            >
                              <path
                                fill="#FFF"
                                fill-opacity="0"
                                d="M0 0H24V24H0z"
                              ></path>
                              <rect
                                width="15.75"
                                height="14.625"
                                x="4.375"
                                y="5.813"
                                stroke="#254050"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                rx="1.125"
                              ></rect>
                              <path
                                stroke="#254050"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                d="M4.375 10.313L20.125 10.313M8.875 7.5L8.875 3.563M15.625 7.5L15.625 3.563"
                              ></path>
                            </g>
                          </svg>
                        </span>

                        <span class="option__title pl-3">
                          {{ props.option && props.option.name }}
                        </span>
                      </template>
                    </multiselect>
                  </div>
                </div>
              </div>
              <div
                class="col-sm-12 col-md-6"
                v-if="optional(table_mode, 'type.name') === 'Table Groups'"
              >
                <transition name="slide-fade">
                  <div
                    v-if="optional(table_mode, 'type.name') === 'Table Groups'"
                  >
                    <multiselect
                      track-by="name"
                      label="name"
                      class="table-select"
                      placeholder="Group By:"
                      v-model="table_group_mode"
                      v-validate="'required'"
                      @input="loadTable"
                      name="country"
                      :options="tableGroupOptions"
                      :show-labels="false"
                    >
                      <template slot="caret">
                        <div>
                          <div class="multiselect__select">
                            <i
                              class="zmdi zmdi-chevron-down pt-1"
                              style="font-size: 20px"
                            ></i>
                          </div>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </transition>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-lg-10 float-right text-right">
            <div
              class="d-flex float-right align-items-center justify-items-center"
            >
              <!-- Listing of users -->
              <div class="task-pane mr-1">
                <ul
                  class="
                    task-pane__profiles
                    d-flex
                    align-items-center
                    justify-items-center
                  "
                >
                  <li
                    class="d-flex align-items-center justify-content-center"
                    @click="
                      setKanbanFilters('employee_id', activeUser.employee_id);
                      setFilters('employee_id', activeUser.employee_id);
                      filterMembers(activeUser.employee_id, moreParams.employee_id);
                    "
                    :id="
                      moreParams.employee_id &&
                      moreParams.employee_id == activeUser.employee_id
                        ? 'selected'
                        : ''
                    "
                    v-b-tooltip.hover
                    :title="activeUser.name"
                  >
                    {{ activeUser.f_initial }}{{ activeUser.l_initial }}
                  </li>
                  <li
                    class="d-flex align-items-center justify-content-center"
                    @click="
                      setKanbanFilters('employee_id', employee.id);
                      setFilters('employee_id', employee.id);
                    "
                    v-for="(employee, index) in employees.slice(0, 2)"
                    :id="
                      moreParams.employee_id &&
                      moreParams.employee_id == employee.id
                        ? 'selected'
                        : ''
                    "
                    :key="index"
                    v-b-tooltip.hover
                    :title="employee.name"
                  >
                    {{ employee.f_initial }}{{ employee.l_initial }}
                  </li>
                  <li
                    class="d-flex align-items-center justify-content-center"
                    :id="showMoreUsers ? 'selected' : ''"
                    @click="toggleHover"
                    v-if="employees.length > 2"
                  >
                    +{{ employees.length - 2 }}
                  </li>
                  <div
                    class="comment"
                    id="task-pane__profiles-more"
                    v-if="showMoreUsers"
                  >
                    <div class="com-comp">
                      <div class="com-dropdown">
                        <ul class="com-dropdown__list pt-2 pb-3">
                          <li
                            class="pl-4 pr-5 pb-3"
                            @click="
                              showMoreUsers = !showMoreUsers;
                              setKanbanFilters('employee_id', employee.id);
                              setFilters('employee_id', employee.id);
                            "
                            :style="
                              moreParams.employee_id &&
                              moreParams.employee_id == employee.id
                                ? 'background:#daf1ff'
                                : ''
                            "
                            :key="index"
                            v-for="(employee, index) in employees"
                          >
                            {{ employee.name }}
                          </li>
                          <!-- <li class="pl-4 pr-5 pb-3" :key="index" v-for="(user,index) in employees.slice(0,3)">{{user.name}}</li> -->
                        </ul>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
              <!-- Filter by project -->
              <div class="mr-1" v-if="!showProject && !task_link">
                <multiselect
                  @input="
                    setFilters('project_id');
                    task.project = projects.find(
                      (x) => x.id == moreParams['project_id']
                    );
                  "
                  class="no-caret-select"
                  style="max-width: 200px"
                  v-model="moreParams['project_id']"
                  :custom-label="
                    (opt) => projects.find((x) => x.id == opt).name
                  "
                  :options="projects.map((type) => type.id)"
                  :show-labels="false"
                  placeholder="Filter by Project"
                >
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <i
                          class="zmdi zmdi-chevron-down pt-1"
                          style="font-size: 20px"
                        ></i>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
              <!-- Filter by milestone -->
              <div class="mr-1" v-if="from">
                <multiselect
                  @input="setFilters('project_milestone_id')"
                  class="no-caret-select"
                  style="max-width: 200px"
                  v-model="moreParams['project_milestone_id']"
                  :custom-label="
                    (opt) =>
                      optional(
                        milestones.find((x) => x.id == opt),
                        'name',
                        {}
                      )
                  "
                  :options="milestones.map((type) => type.id)"
                  :show-labels="false"
                  placeholder="Filter by Milestones"
                >
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <i
                          class="zmdi zmdi-chevron-down pt-1"
                          style="font-size: 20px"
                        ></i>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
              <!-- Filters parameter -->
              <div v-if="from">
                <tasks-filters
                  @setFilters="setFilters"
                  @showMoreFilters="showVisibleFilter"
                  @removeColumnFromTable="removeColumnFromTable"
                  :params="moreParams"
                  :columns="columns"
                  :visibleFilter="visibleFilter"
                ></tasks-filters>
              </div>
            </div>
          </div>
        </div>
        <!-- Reset Filter Button -->
        <div class="text-right mt-3" v-if="visibleFilter">
          <button
            @click="moreParams = {}"
            class="p-2 pl-3 pr-3"
            style="
              background: #e4e4e4;
              font-size: 13px;
              border-radius: 50px;
              border: none;
            "
          >
            Reset Filter
          </button>
        </div>
        <!-- Filter dropdowns -->
        <tasks-filters-dropdown
          v-if="projects && employees"
          @setFilters="setFilters"
          @filterMembers="filterMembers"
          :params="moreParams"
          :projects="projects"
          :taskStatuses="taskStatuses"
          :employees="employees"
          :visibleFilter="visibleFilter"
        ></tasks-filters-dropdown>
      </div>
      <div class="table-wrapper mt-4">
        <!-- Table -->
        <transition name="slide-fade">
          <div v-if="isTable">
            {{ isFetchingTasks }}
            <vuetable
              ref="vuetable"
              :api-url="api"
              @vuetable:cell-clicked="gotoTask"
              :first-page="0"
              :append-params="moreParams"
              :http-options="{
                headers: {
                  Authorization: 'Bearer ' + $auth.token(),
                  xorg: getOrganization.organization_id(),
                },
              }"
              @vuetable:loaded="loadTable"
              :fields="columns.filter((s) => s.active === true)"
              :row-class="onRowClass"
              data-path="data"
              pagination-path
              :no-data-template="
                isFetchingTasks
                  ? 'Loading Data'
                  : rowCount === null
                  ? 'Loading data'
                  : 'No Data Available'
              "
              @vuetable:pagination-data="onPaginationData"
            >
              <template slot="sn" class="xc" slot-scope="props">
                <router-link
                  :to="{
                    name: 'task-show-full',
                    params: { id: props.rowData.id },
                  }"
                  >{{ props.rowIndex + 1 }}</router-link
                >
              </template>
              <template slot="status" slot-scope="props">
                <div class="status">
                  {{ props.rowData.id }}
                </div>
              </template>
              <template slot="description" slot-scope="props">
                <div class="status">
                  {{ html2text(props.rowData.description) | truncate(50) }}>
                </div>
              </template>
              <template slot="actions" slot-scope="props">
                <router-link
                  :to="{
                    name: 'task-show-full',
                    params: { id: props.rowData.id },
                  }"
                >
                  <button class="btn-lightgray-round">
                    <i class="zmdi zmdi-plus pr-2"></i>View Task
                  </button>
                </router-link>

                <router-link
                  :to="{
                    name: 'tasks-edit',
                    params: { id: props.rowData.id },
                    query: { from: 'index' },
                  }"
                >
                  <button class="btn-lightgray-round no-hover ml-2">
                    <i class="zmdi zmdi-plus pr-2"></i>Edit Task
                  </button>
                </router-link>
              </template>
            </vuetable>
          </div>
        </transition>
        <!-- Table Groups -->
        <transition name="slide-fade">
          <div v-if="optional(table_mode, 'type.name') === 'Table Groups'">
            <table
              class="
                vuetable
                ui
                blue
                selectable
                celled
                stackable
                attached
                table
              "
              style="width: 100%"
            >
              <thead>
                <tr>
                  <th :key="index" v-for="(column, index) in columns">
                    <span v-if="column && column.name === '__checkbox'">
                      <input type="checkbox" />
                    </span>
                    <span v-else>{{ column.title }}</span>
                  </th>
                </tr>
              </thead>
              <tbody :key="indexx" v-for="(table, indexx) in rowCount">
                <tr style="height: 50px !important">
                  <!-- this cell will occupy 3 columns -->
                  <td>
                    <input type="checkbox" />
                  </td>
                  <td>{{ indexx }}</td>
                  <td class="text-bold" colspan="3">
                    {{
                      table_group_mode &&
                      table_group_mode.include &&
                      table &&
                      table[0] &&
                      table[0][table_group_mode.include]
                        ? table[0][table_group_mode.include].name
                        : "N/A"
                    }}
                  </td>
                </tr>

                <tr :key="index" v-for="(group_data, index) in table">
                  <td>
                    <input type="checkbox" />
                  </td>
                  <!-- <td>{{'-'}}</td> -->
                  <td>{{ group_data.project_id }}</td>
                  <td>{{ group_data.name }}</td>
                  <td
                    :class="
                      optional(group_data, 'group_data.status.name', null)
                        ? 'status ' +
                          group_data.status.name
                            .toLowerCase()
                            .replace(' ', '_')
                            .replace('/', '')
                        : ''
                    "
                  >
                    {{ optional(group_data, "status.name", "") }}
                  </td>
                  <td>
                    <span
                      :inner-html.prop="group_data.description | truncate(50)"
                    ></span>
                  </td>
                  <td>
                    {{
                      optional(group_data, "employee.data.user.data", null)
                        ? `${group_data.employee.data.user.data.first_name} ${group_data.employee.data.user.data.last_name}`
                        : "N/A"
                    }}
                  </td>
                  <td>{{ group_data.created_at }}</td>
                  <td>{{ group_data.estimated_hours }} hrs</td>
                  <td>
                    <router-link
                      :to="{
                        name: 'task-show-full',
                        params: { id: group_data.id },
                      }"
                    >
                      <button class="btn-lightgray-round">
                        <i class="zmdi zmdi-plus pr-2"></i>View Task
                      </button>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </transition>
        <!-- Kanban -->
        <transition name="slide-fade">
          <div v-if="optional(table_mode, 'type.name') === 'Kanban'">
            <div class="p-4" style="background: #daf1ff1a; border-radius: 4px">
              <div class="d-flex flex-row kanban-board">
                <div
                  v-for="status in taskStatuses"
                  :key="status.id"
                  :id="status.name.replace(' ', '').replace('/', '')"
                  :style="status.length == 0 ? 'height:500px' : ''"
                  class="mr-3 kanban-board__status p-3"
                >
                  <!-- For adding a new task in any pf the tast status -->
                  <div
                    class="kanban-board__status-task"
                    v-if="newTaskBar == status.id"
                  >
                    <div class="d-flex flex-row justify-content-between">
                      <h6 class="text-bold text-opacity-pale h6">New Task</h6>
                      <span @click="newTaskBar = ''" style="cursor: pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          fill="#c39e9e"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <form
                      class="d-form"
                      @submit.prevent="handleTaskAdded(status)"
                    >
                      <div class="row">
                        <div class="col-sm-12">
                          <input
                            type="text"
                            :name="'name'"
                            v-model="task.name"
                            :style="
                              errors.has('name') ? 'border:1px solid red' : ''
                            "
                            v-validate="'required'"
                            class="form-control auth-input"
                            :placeholder="'Title of task'"
                          />
                        </div>
                        <div class="col-sm-12 mt-3">
                          <multiselect
                            track-by="id"
                            label="name"
                            v-validate="moreParams.project_id ? '' : 'required'"
                            class="no-caret-select"
                            :style="
                              errors.has('project')
                                ? 'border:1px solid red'
                                : ''
                            "
                            name="project"
                            v-model="task.project"
                            :options="projects"
                            placeholder="What project is this for?"
                            :show-labels="false"
                          >
                            <template slot="caret">
                              <div>
                                <div class="multiselect__select">
                                  <i
                                    class="zmdi zmdi-chevron-down pt-1"
                                    style="font-size: 20px"
                                  ></i>
                                </div>
                              </div>
                            </template>
                          </multiselect>
                        </div>
                        <div class="col-sm-12 mt-3">
                          <multiselect
                            track-by="id"
                            label="name"
                            :style="
                              errors.has('assignee')
                                ? 'border:1px solid red'
                                : ''
                            "
                            v-validate="
                              moreParams.employee_id ? '' : 'required'
                            "
                            class="no-caret-select"
                            name="assignee"
                            v-model="task.assignee"
                            :options="allEmployees"
                            placeholder="Who's to work on this task?"
                            :show-labels="false"
                          >
                            <template slot="caret">
                              <div>
                                <div class="multiselect__select">
                                  <i
                                    class="zmdi zmdi-chevron-down pt-1"
                                    style="font-size: 20px"
                                  ></i>
                                </div>
                              </div>
                            </template>
                          </multiselect>
                        </div>
                        <div class="col-sm-12">
                          <input
                            type="text"
                            :name="'description'"
                            v-model="task.description"
                            :style="
                              errors.has('description')
                                ? 'border:1px solid red;height:50px !important'
                                : 'height:50px !important'
                            "
                            class="form-control auth-input"
                            :placeholder="'Description'"
                          />
                        </div>
                        <div class="col-sm-12 mt-3">
                          <vc-date-picker
                            :masks="{ input: ['YYYY-MM-DD'] }"
                            :style="
                              errors.has('due_date')
                                ? 'border:1px solid red'
                                : ''
                            "
                            :input-props="{
                              class: `d-input no-margin-top auth-input pl-3`,
                              style: `margin-top:0px !important`,
                              placeholder: `Due date`,
                            }"
                            v-model="task.due_date"
                            :popover="{ visibility: 'click' }"
                            name="due_date"
                            ref="DueDate"
                            data-vv-as="Task Due date"
                          />
                        </div>
                        <div class="col-sm-12 text-right mt-3">
                          <Auth-Button :loading="loading" text="Save" />
                        </div>
                      </div>
                    </form>
                  </div>
          
                  <div
                    class="
                      d-flex
                      flex-row
                      justify-content-between
                      align-items-center
                    "
                  >
                    <h5 class="h6 text-bold text-opacity-pale">
                      {{ status.name.toUpperCase() }}
                    </h5>
                    <h6
                      @click="showTaskBar(status.id)"
                      style="
                        color: #3f92cc !important;
                        cursor: pointer;
                        text-decoration: none !important;
                      "
                    >
                      <span class="pl-2 pr-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill="#3f92cc"
                            d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 4c-.552 0-1 .448-1 1v2H5c-.552 0-1 .448-1 1s.448 1 1 1h2v2c0 .552.448 1 1 1s1-.448 1-1V9h2c.552 0 1-.448 1-1s-.448-1-1-1H9V5c0-.552-.448-1-1-1z"
                          ></path>
                        </svg>
                      </span>
                      Add Task
                    </h6>
                  </div>
                  <draggable
                    class="d-flex flex-column overflow-hidden"
                    v-model="status.tasks"
                    v-if="!isFetchingTasks"
                    v-bind="taskDragOptions"
                    :sort="false"
                    :style="
                      status.tasks && status.tasks.length == 0
                        ? 'height:100px'
                        : 'height: 500px;max-height: 500px;overflow-y: scroll !important;'
                    "
                    @change="handleTaskMoved($event, status)"
                    @end="moved"
                  >
                    <transition-group
                      class="
                        d-flex
                        mt-4
                        flex-column
                        h-100
                        overflow-x-hidden overflow-y-auto
                        rounded
                        shadow-xs
                      "
                      tag="div"
                    >
                      <div
                        class="mb-3"
                        v-for="task in status.tasks"
                        :key="task.id"
                      >
                        <div class="kanban-board__tasks p-2 pl-3">
                          <!-- Task Card -->
                          <router-link
                            :to="{
                              name: task_link
                                ? task_link
                                : 'tasks-preview-show',
                              params: { tid: task.id, filterBy: moreParams },
                              query: { from_path: $route.path },
                            }"
                          >
                            <div class="d-flex flex-column">
                              <div
                                class="d-flex flex-row justify-content-between"
                              >
                                <h6
                                  class="
                                    pl-1
                                    mb-0
                                    text-bolder text-opacity-pale
                                  "
                                  style="
                                    font-size: 13px !important;
                                    color: black;
                                    opacity: 0.5;
                                  "
                                >
                                  {{ task.reference ? task.reference : "-" }}
                                </h6>
                                <button
                                  class="kanban-board__tasks-priority"
                                  v-b-tooltip.hover
                                  :title="taskPriority(task.priority_id)"
                                >
                                  <!-- <i class="zmdi zmdi-arrow-right-top"></i> -->
                                  <i
                                    class="zmdi zmdi-trending-up"
                                    v-if="task.priority_id == 4"
                                  ></i>
                                  <i
                                    class="zmdi zmdi-long-arrow-up"
                                    v-else-if="task.priority_id == 2"
                                  ></i>
                                  <i
                                    class="zmdi zmdi-long-arrow-down"
                                    v-else-if="task.priority_id == 1"
                                  ></i>
                                  <i
                                    class="zmdi zmdi-arrow-right-top"
                                    v-else
                                  ></i>
                                </button>
                              </div>
                              <div class="d-flex justify-content-between">
                                <h6
                                  class="
                                    h6
                                    text-medium text-opacity-pale
                                    kanban-board__tasks-title
                                  "
                                >
                                  {{ task.name | truncate(80) }}
                                </h6>
                                <!-- <h6 class="kanban-board__tasks-status-icon"></h6> -->
                              </div>
                              <div
                                class="
                                  mt-1
                                  d-flex
                                  align-items-center
                                  justify-content-between
                                "
                              >
                                <div>
                                  <!-- TO show user details info here -->
                                  <button
                                    class="kanban-board__tasks-profile"
                                    v-b-tooltip.hover
                                    v-if="
                                      task.employee &&
                                      task.employee.data &&
                                      task.employee.data.user &&
                                      task.employee.data.user.data
                                    "
                                    :title="
                                      optional(task, 'employee.data.user.data', null)
                                        ? task.employee.data.user.data.first_name +
                                          ' ' +
                                          task.employee.data.user.data.last_name
                                        : '-'
                                    "
                                  >
                                    {{
                                      optional(task, "employee.data.user.data", null)
                                        ? task.employee.data.user.data.first_name.charAt(
                                            0
                                          ) +
                                          task.employee.data.user.data.last_name.charAt(
                                            0
                                          )
                                        : "-"
                                    }}
                                  </button>
                                  <button
                                    class="kanban-board__tasks-profile"
                                    v-else
                                    v-b-tooltip.hover
                                    :title="
                                      optional(task, 'employee.data.user.data', null)
                                        ? task.employee.data.user.data.first_name +
                                          ' ' +
                                          task.employee.data.user.data.last_name
                                        : '-'
                                    "
                                  >
                                    {{
                                      optional(task, "employee.data.userdata.", null)
                                        ? task.employee.data.user.data.first_name.charAt(
                                            0
                                          ) +
                                          task.employee.data.user.data.last_name.charAt(0)
                                        : "-"
                                    }}
                                  </button>
                                </div>
                                <div class="text-right">
                                  <h6
                                    class="
                                      h6
                                      p-1
                                      pl-2
                                      mt-2
                                      pr-3
                                      text-bold
                                      kanban-board__tasks-project
                                      text-opacity
                                    "
                                    style="color: initial"
                                  >
                                    {{
                                      task.project && task.project.data
                                        ? optional(
                                            task.project,
                                            "data.name",
                                            "N/A"
                                          )
                                        : optional(task.project, "name", "N/A")
                                          | truncate(17)
                                    }}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </router-link>
                          <div class="d-flex flex-column justify-content-end">
                            <a
                              href="#"
                              class="
                                flex
                                justify-content-end
                                subtask-hover
                                text-right
                                mt-2
                                mb-3
                              "
                            >
                              <small
                                v-if="task.subtasks && task.subtasks.length > 0"
                                @click="toggleSubTasks(task.id)"
                                style="
                                  background: #f5f5f5;
                                  color: black;
                                  opacity: 0.6;
                                  border-radius: 5px;
                                "
                                class="p-2 pl-2 pr-2"
                              >
                                <i class="zmdi zmdi-share pr-2"></i>
                                {{ task.subtasks && task.subtasks.length }}
                                Subtask{{
                                  task.subtasks && task.subtasks.length > 1
                                    ? "s"
                                    : ""
                                }}
                                <i
                                  class="zmdi zmdi-chevron-down pl-3"
                                  style="font-size: 17px"
                                ></i>
                              </small>
                            </a>
                            <div class="d-flex justify-content-end">
                              <ul class="pb-2 d-flex align-items-center flex-row">
                                <!-- {{task.subtasks}} -->
                                <!-- {{getSubTaskStatus(task.subtasks,'Not Started')}} -->
                                <!-- {{getSubTaskStatus(task.subtasks,'In Progress')}} -->
                                <li v-if="getSubTaskStatus(task.subtasks,'In Progress')" class="d-flex align-items-center"><button class="mr-1" style="height:5px;width:5px;border-radius:5px;border:none;background:#cccc11"></button><small style="opacity:.7">{{getSubTaskStatus(task.subtasks,'In Progress')}} in-progress</small></li>
                                <li v-if="getSubTaskStatus(task.subtasks,'Done')" class="ml-2 d-flex align-items-center"><button class="mr-1" style="height:5px;width:5px;border-radius:5px;border:none;background:green"></button><small style="opacity:.7">{{getSubTaskStatus(task.subtasks,'Done')}} completed</small></li>
                              </ul>
                            </div>
                          </div>
                          
                        </div>
                        <!-- {{task.subtasks && task.subtasks.data}} -->
                        <transition name="fade">
                          <div
                            class="mt-2 subtasks-pane"
                            v-if="
                              task.subtasks &&
                              task.subtasks.length > 0 &&
                              toggledTask == task.id
                            "
                          >
                            <div
                              :key="keyIndex"
                              v-for="(subtask, keyIndex) in task.subtasks"
                              class="ml-3 mb-2 kanban-board__tasks kanban-board__subtask p-2 pl-3"
                              :id="subtask && subtask.status && subtask.status.name.replace(' ', '').replace('/', '')"
                            >
                              <router-link
                                :to="{
                                  name: task_link
                                    ? task_link
                                    : 'tasks-preview-show',
                                  params: { tid: subtask.id },
                                }"
                              >
                                <div class="d-flex flex-column">
                                  <div class="d-flex justify-content-between">
                                    <h6
                                      class="
                                        h6
                                        text-medium text-opacity-pale
                                        kanban-board__tasks-title
                                        mr-2
                                      "
                                    >
                                      {{ subtask.name | truncate(80) }}
                                    </h6>
                                    <!-- {{subtask.employee.user.name}} -->
                                    <button
                                      class="kanban-board__tasks-profile d-flex align-items-center justify-content-center"
                                      style="
                                        width: 25px;
                                        height: 25px;
                                        border-radius: 25px;
                                      "
                                      v-b-tooltip.hover
                                      :title="
                                        optional(
                                          subtask,
                                          'employee.user',
                                          null
                                        )
                                          ? subtask.employee.user
                                              .first_name +
                                            ' ' +
                                            subtask.employee.user
                                              .last_name
                                          : '-'
                                      "
                                    >
                                      {{
                                        optional(
                                          subtask,
                                          "employee.user",
                                          null
                                        )
                                          ? subtask.employee.user.first_name.charAt(
                                              0
                                            ) +
                                            subtask.employee.user.last_name.charAt(
                                              0
                                            )
                                          : "-"
                                      }}
                                    </button>
                                  </div>
                                  <!-- <div class="flex justify-content-end text-right mt-3">
                                  <small
                                    v-if="task.subtasks && task.subtasks.data.length > 0"
                                    style="background:#F5F5F5;color:black;opacity:.6;border-radius:5px"
                                    class="p-1 pl-2 pr-2"
                                  >
                                    <i class="zmdi zmdi-share pr-2"></i>
                                    {{task.subtasks && task.subtasks.data.length}} Subtask{{task.subtasks && task.subtasks.data.length > 1 ? 's' : ''}}
                                  </small>
                                  </div>-->
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </transition>
                      </div>
                    </transition-group>
                  </draggable>
                  <div
                    class="mt-3 kanban-board__tasks-footer"
                    v-if="!isFetchingTasks"
                  >
                    <h6
                      class="text-bold h6 text-opacity-pale text-center"
                      v-if="status.tasks && status.tasks.length == 0"
                    >
                      No Task created on this column yet!
                    </h6>
                    <button
                      class="kanban-board__tasks-footer-button p-3"
                      @click="showTaskBar(status.id)"
                    >
                      Add New Task
                    </button>
                  </div>
                  <div
                    class="mt-3 mb-3 text-center kanban-loader"
                    v-if="isFetchingTasks"
                  >
                    <div
                      class="spinner-grow spinner-grow-sm text-main"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <draggable v-model="tasks" group="project_id" @start="drag=true" @end="drag=false">
                <transition-group
                  class="d-flex flex-column"
                  tag="div"
                >
                  <div v-for="element in tasks" :key="element.id">{{element.name}}</div>
                </transition-group>
              </draggable>-->
            </div>
          </div>
        </transition>

        <!-- Pagination -->
        <vuetable-pagination
          v-if="isTable"
          :css="table_css.pagination"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
        <div
          v-if="
            (optional(table_mode, 'type.name') === 'Table' && rowCount <= 0) ||
            rowCount === null
          "
          class="text-center"
        >
          <slot name="empty"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthButton from "@/components/Buttons/Auth.vue";
import truncate from "@/mixins/truncate";
import { optional, html2text, objectEqual } from "@/helpers/random";
import VuetableCss from "@/helpers/vuetable_styling.js";
import ldash from "@/helpers/ldash.js";
import getOrganization from "@/helpers/getOrganization";
import TasksFilters from "@/components/Filters/Tasks/Index";
import TasksFiltersDropdown from "@/components/Filters/Tasks/More";
import VuetablePagination from "@/components/Layouts/VueTablePagination";
import DetailRow from "./DetailRow";
import Vue from "vue";
import getPaginationData from "@/helpers/pagination";
import { toLocal } from "@/helpers/date";
import { toUTC } from "@/helpers/date";
import draggable from "vuedraggable";
import Literals from "@/helpers/dashboard/literals";
import _ from "lodash";
Vue.component("task-detail-row", DetailRow);

export default {
  directives: {
    clickoutside: {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          // here I check that click was outside the el and his childrens
          if (!(el == event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
        document.body.addEventListener("touchstart", el.clickOutsideEvent);
      },
      unbind: function (el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
        document.body.removeEventListener("touchstart", el.clickOutsideEvent);
      },
      stopProp(event) {
        event.stopPropagation();
      },
    },
  },
  components: {
    TasksFilters,
    TasksFiltersDropdown,
    VuetablePagination,
    draggable,
    AuthButton,
  },
  mixins: [truncate],
  data() {
    return {
      Literals,
      getOrganization,
      drag: "",
      ldash,
      loaded: false,
      toggledTask: "",
      showMoreUsers: false,
      isFetchingTasks: false,
      loading: "",
      task: {
        name: "",
        project: "",
        assignee: "",
        due_date: "",
        description: "",
      },
      tableModeOptions: [
        {
          name: "Table",
        },
        {
          name: "Table Groups",
        },
        {
          name: "Kanban",
        },
        {
          name: "Calendar",
        },
      ],
      tableGroupOptions: [
        {
          name: "Task name",
          slug: "name",
        },
        {
          name: "Project Title",
          slug: "project_id",
          include: "project",
        },
        {
          name: "Status",
          slug: "task_status_id",
          include: "status",
        },
        {
          name: "Assignee",
          slug: "employee_id",
          include: "employee",
        },
      ],
      table_mode: {
        type: { name: "Kanban" },
      },
      newTaskBar: "",
      table_group_mode: {
        name: "Project Title",
        slug: "project_id",
        include: "project",
      },
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      api: this.$baseApi + `/tasks`,
      columns: [
        {
          name: "__checkbox",
          titleClass: "center aligned",
          dataClass: "center aligned",
          cvisible: false,
          table_group: false,
          active: true,
          callback: (table_group) => {},
        },
        {
          title: "Task Title",
          name: "name",
          cvisible: true,
          active: true,
          table_group: true,
          sortable: true,
          callback: (d) => {
            if (d) {
              if (d.length > 20) {
                return d.substring(0, 20) + "...";
              }
              return d;
            } else {
              return "N/A";
            }
          },
        },
        {
          title: "Project",
          name: "project",
          cvisible: true,
          active: true,
          table_group: true,
          sortable: true,
          callback: (s) => {
            return s && s.data ? s.data.name : s ? s.name :  "N/A";
          },
        },
        {
          name: "status",
          title: "Status",
          cvisible: true,
          active: true,
          table_group: false,
          callback: (d) => {
            let x = this.$refs.vuetable.tableFields.find(
              (s) => s && s.name && s.name === "status"
            );
            x["dataClass"] = `status`;
            return d ? d.name : "";
          },
        },
        {
          name: "__slot:description",
          title: "Description",
          cvisible: true,
          active: true,
          table_group: false,
          // callback: d => {
          //   return d.length >=1 ? this.truncate(d) : ''
          // }
        },
        {
          name: "employee.data.user.data",
          title: "Assigned To",
          cvisible: true,
          active: true,
          sortable: true,
          table_group: false,
          callback: (s) => {
            return s ? s.first_name + " " + s.last_name : "N/A";
          },
        },
        {
          name: "created_at",
          title: "Date Created",
          cvisible: true,
          active: true,
          table_group: false,
          callback: (s) => {
            return toLocal(s).format("MM/DD/YYYY");
          },
        },
        {
          name: "estimated_hours",
          title: "Estimated Hours",
          cvisible: true,
          active: true,
          table_group: false,
          callback: (s) => {
            return s + "hrs";
          },
        },
        {
          name: "__slot:actions",
          title: "Actions",
          table_group: false,
          active: true,
          cvisible: false,
        },
      ],
      moreParams: {
        include: "employee,project,milestone,subtasks,subtasks.employee",
        employee_id: null,
        per_page: this.isTable ? '' : 400
      },
      paginationData: {},
      filterColumns: [],
      cachedTaskStatuses: null,
      originalPage: null,
      oldParams: {
        include: null,
        employee_id: null,
      },
      IsSetKanbanFilters: false,
      isSsetFilters: false,
    };
  },
  props: {
    project_id: {
      type: Number,
      required: false,
    },
    milestone_id: {
      type: Number,
      required: false,
    },
    from: {
      type: String,
      required: false,
    },
    showProject: {
      type: String,
      required: false,
    },
    task_link: {
      type: String,
      required: false,
    },
  },
  methods: {
    html2text,
    optional,
    taskPriority(id) {
      let priorities = [
        { id: 1, name: "Low" },
        { id: 2, name: "Medium" },
        { id: 3, name: "High" },
        { id: 4, name: "Highest" },
      ];
      let priority = priorities.find((s) => s.id == id);
      if (priority) {
        return priority.name + " Priority";
      }
      return "High Priority";
    },
    getSubTaskStatus(subtasks,name) {
      let status =  subtasks && subtasks.data && subtasks.data.filter(s => s.status && s.status.name == name);
      return status && status.length;
    },
    customTableOptionLabel({ name, svg }) {
      return name && svg ? `${svg} – ${name}` : "";
    },
    toggleSubTasks(id) {
      this.toggledTask = this.toggledTask == id ? "" : id;
    },
    showTaskBar(id) {
      this.newTaskBar = id;
    },
    toggleHover() {
      this.showMoreUsers = this.showMoreUsers ? false : true;
    },
    onRowClass(dataItem, index) {
      let status =
        dataItem.status && dataItem.status.name ? dataItem.status.name : " ";
      status = status.toLowerCase().replace(" ", "_").replace("/", "");
      return status;
    },
    gotoTask(e) {
      this.$router.push({
        name: "task-show-full",
        params: { id: e.id },
      });
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;

      if (d && d.length > 0) {
        this.rowCount = d;
        const result = {};
        let key = this.table_group_mode && this.table_group_mode.slug;

        this.rowCount = d.forEach((item) => {
          if (!result[item[key]]) {
            result[item[key]] = [];
          }
          result[item[key]].push(item);
        });
        this.rowCount = result;
      }
    },
    loadTableByGroup() {
      let d = this.$refs.vuetable.tableData;
      if (d && d.length > 0) {
        let key = "project_id";
      }
    },
    onCellClicked(data, field, event) {
      this.$refs.vuetable2.toggleDetailRow(data.id);
    },
    setFilters(name, val) {
      //reset everything to default whenever you select new filter
      this.taskStatuses = val !== this.moreParams[name] ? {} : this.taskStatuses;

      if(!name) return;
      this.moreParams[name] = val || this.moreParams[name];
      
      if (this.moreParams && this.moreParams[name] == "") {
        delete this.moreParams[name];
      } 
     
      // if (!name || this.isFetchingTasks || this.IsSetKanbanFilters) return;
      // this.isSetFilters = true;

      // if (this.moreParams[name] && this.moreParams[name] == val) {
      //   this.moreParams[name] = null;
      // } else {
      //   this.moreParams[name] = val || this.moreParams[name] || null;

      // }

      let sm = this;
      
      this.$nextTick(() => {
        if (this.isTable) {
          sm.$refs.vuetable.refresh();
          sm.rowCount = sm.$refs.vuetable.tableData;
          sm.loadTable();
        }
      });
    },
    setKanbanFilters(key, value) {
      if(this.isFetchingTasks || this.isSetFilters) return;
       this.IsSetKanbanFilters = true

      if (
        this.moreParams[key] == value
      ) {
        this.moreParams[key] = null;
        this.isTable ? '' : this.moreParams['per_page'] = 400;
      } else {
        this.moreParams[key] = value;
        this.isTable ? '' : this.moreParams['per_page'] = 400;
      }
    
    },
    onChangePage(page) {
      this.moreParams.page = page;
      this.setFilters('employee_id', this.activeUser.employee_id);
      
    },
    refreshTable() {
      delete this.moreParams['per_page'];
      this.$nextTick(() => this.isTable && this.$refs.vuetable.refresh())
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find((s) => s && s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        if (this.isTable) {
          sm.$refs.vuetable.refresh();
          sm.$refs.vuetable.normalizeFields();
        }
      });
    },
    onPaginationData(paginationData) {
      // delete paginationData.data;
      this.paginationData = getPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    handleTaskAdded(newTask) {
      let data = {
        name: this.task.name,
        project_milestone_id: this.milestone_id,
        employee_id:
          this.moreParams && this.moreParams.employee_id
            ? this.moreParams.employee_id
            : this.task.assignee && this.task.assignee.id,
        due_date: this.task.due_date
          ? toUTC(this.task.due_date).format("YYYY-MM-DD")
          : null,
        project_id:
          this.moreParams && this.moreParams.project_id
            ? this.moreParams.project_id
            : this.task.project && this.task.project.id,
        description:
          this.task && this.task.description ? this.task.description : null,
        task_status_id: newTask.id,
      };
      let msgPass = Literals.TASK_CREATE_SUCCESS;
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("tasks/createUpdate", data)
            .then((k) => {
              this.$toast.success(msgPass, {
                position: "top-right",
                duration: 3000,
              });
              this.taskStatuses = this.taskStatuses.map((s) => {
                if (s.id == newTask.id) {
                  k.employee['user'] = k.employee && k.employee.data && k.employee.data.user && k.employee.data.user.data;
                  s.tasks.push(k);
                }
                return s;
              });

              let params = new URLSearchParams(this.moreParams).toString();
              this.$store.dispatch("tasks/index", params);
              this.$store.dispatch("global/getTaskStatuses");

              this.newTaskBar = "";
              this.task = {
                name: "",
                assignee: "",
                project: "",
                due_date: "",
              };
            })
            .catch((err) => {
              this.$toast.error("Failed to update task status", {
                position: "top-right",
                duration: 3000,
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    moved(l, k, j) {},
    updateStatusOnMove(task, newStatus) {
      let msgPass = Literals.TASK_UPDATE_SUCCESS;
      let data = {};
      data["id"] = task.id;
      data["task_status_id"] = task.task_status_id;
      data.task_status_id = newStatus;
      // delete task.project;
      // delete task.employee;
      // delete task.status;

      this.$store
        .dispatch("tasks/createUpdate", data)
        .then((updatedTask) => {
          this.$toast.success(msgPass, {
            position: "top-right",
            duration: 3000,
          });
          this.taskStatuses = this.taskStatuses.map((s) => {
            if (s.id == updatedTask.task_status_id) {
              s["tasks"] = s.tasks.map((d) => {
                if (d.id == updatedTask.id) {
                  d.task_status_id = updatedTask.task_status_id;
                }
                return d;
              });
            }
            return s;
          });
        })
        .catch((err) => {
          this.$toast.success(Literals.TASK_UPDATE_ERROR, {
            position: "top-right",
            duration: 3000,
          });
        });
    },
    handleTaskMoved(evt, k, l) {
      let statuses = this.taskStatuses;

      let index = statuses.findIndex((status) => status.id == k.id);

      statuses[index] = k;
      this.taskStatuses = statuses;

      if (evt && evt.added) {
        let element = evt.added && evt.added.element;
        this.updateStatusOnMove(element, k.id);
      }
    },
    filterMembers(id, emp_id) {},
    filterTask(newVal, oldVal) {
     
      if (!this.loaded) return;

      this.isFetchingTasks = true;

      this.cachedTaskStatuses = null;

      let filters = { ...this.moreParams, ...this.$route.params.filterBy };
      if (filters["search"] && filters["search"].length < 4) {
        delete filters["search"];
      }

      let filteredParams = this.$_.omitBy(filters, _.isNil);
      Object.keys(filteredParams).forEach((key) => {
        if (!filteredParams[key]) {
          delete filteredParams[key];
        }
      });

      this.oldParams =
        Object.keys(this.oldParams).length > 0
          ? this.oldParams
          : JSON.parse(JSON.stringify(this.moreParams));

      if (objectEqual(filteredParams, this.oldParams)) {
        Object.keys(filteredParams).forEach((key) => {
          if (key != "include" && filteredParams[key] == this.oldParams[key]) {
            delete filteredParams[key];
            this.moreParams[key] = null;
          }
        });

        
        this.oldParams = Object.keys(this.oldParams).length > 0 ? this.oldParams : JSON.parse(JSON.stringify(this.moreParams));
        
        if(objectEqual(filteredParams, this.oldParams)) {
        
          Object.keys(filteredParams).forEach(key => {
            if(key != 'include' && filteredParams[key] == this.oldParams[key]) {
              delete filteredParams[key];
              this.moreParams[key] = null;
            }
          });
          
        }
       
        this.oldParams = JSON.parse(JSON.stringify(filteredParams));
        let projectId = this.project_id || this.moreParams["project_id"];
        if (projectId) {
          filteredParams['project_id'] = this.project_id;
        }
        let params = new URLSearchParams(filteredParams).toString();
        Promise.all([
          this.$store.dispatch("tasks/index", params),
           (this.moreParams && this.moreParams.project_id) && this.$store.dispatch("projects/getMilestones", this.moreParams.project_id)
        ]).finally((s) => {
          
          this.isFetchingTasks = false;
        });

      }

      this.oldParams = JSON.parse(JSON.stringify(filteredParams));
      let params = new URLSearchParams(filteredParams).toString();
      Promise.all([
        this.$store.dispatch("tasks/index", params),
        this.moreParams.project_id && this.$store.dispatch("projects/getMilestones",this.moreParams.project_id),
        (this.project_id || this.moreParams["project_id"]) &&
        this.$store.dispatch(
          "projects/getCollaborators",
          this.project_id || this.moreParams["project_id"]
        ),
      ]).finally((s) => {
       
        this.isFetchingTasks = false;
        this.IsSetFilters = false
        this.IsSetKanbanFilters = false
      });
    },
    setTableType(type) {
      this.$store.commit("tasks/SET_TABLE_TYPE", type && type.name);
    },
  },
  computed: {
    isTable() {
      return this.$store.getters["tasks/table_type"] === "Table";
    },
    taskDragOptions() {
      return {
        animation: 200,
        group: "tasks",
        dragClass: "status-drag",
        sort: true,
        ghostClass: "ghost-card",
      };
    },
    activeUser() {
      let user = optional(this.$auth.user(), "employee.data", {});

      let data = {
        employee_id: user.id,
        f_initial: optional(user, 'user.data.first_name', '').charAt(0),
        l_initial: optional(user, 'user.data.last_name', '').charAt(0),
        name: optional(user, 'user.data.first_name', null) ? `${optional(user, 'user.data.first_name', '')} ${optional(user, 'user.data.last_name', '')}` : null
      };

      return data;
    },
    projects() {
      return this.$store.getters["projects/projects_simple"];
    },
    employees() {
      let employees = this.$store.getters["employees/employees_simple"];
      employees.push({
        id: '0',
        user_id: '0',
        name: 'Unassigned Task',
        f_initial: 'U',
        l_initial: 'T',
      });
      let projectId = this.project_id || this.moreParams["project_id"];

      let active = employees.filter((s) => {
        let notActive = s.id !== this.activeUser.employee_id;
        // either no project_id was specified or we only filter for employees in this project
        let isCollaborator = !projectId || !!this.collaborators.find((e) => e.id == s.user_id);
        return notActive && isCollaborator;
      });

      return active;
    },
    allEmployees() {
      let employees = this.$store.getters["employees/employees_simple"];
      return employees;
    },
    collaborators() {
      return this.$store.getters["projects/collaborators_simple"];
    },
    milestones() {
      return this.$store.getters["projects/milestones"];
    },
    taskStatuses: {
      get() {
        if (this.cachedTaskStatuses) {
          let statusList = this.cachedTaskStatuses;
          this.cachedTaskStatuses = null; // we need to clear the cache so computed value is not stuck with stale data
          return statusList;
        }
        let statuses = this.$store.getters["global/task_statuses"] || [];

        let tasks = [];

        // if this wasn't triggered from original task page, fetch cached content
        if (this.$route.name != this.originalPage) {
          tasks = this.$store.getters["tasks/cached"] || [];
        } else {
          tasks = this.$store.getters["tasks/tasks"] || [];

          this.$store.commit("tasks/SET_CACHED", tasks);
                  }

        statuses = statuses.filter((s) => {
          s["tasks"] = tasks
            .filter((j) => {
              return ( (!this.moreParams["employee_id"] || j.employee_id == null || j.employee_id == this.moreParams["employee_id"]) && (!this.moreParams["project_id"] || j.project_id == this.moreParams["project_id"]) && (!this.moreParams["milestone_id"] || optional(j, "milestone.id", null) == this.moreParams["milestone_id"])
              );
            })
            .filter((k) => k.task_status_id == s.id)
            .map((b) => {
              b["order"] = b.order || b.id;
              return b;
            });
          return s;
        });

        return statuses;
      },
      set(value) {
        this.cachedTaskStatuses = value;
      },
    },
    tasks() {
      return this.$store.getters["tasks/tasks"];
    },
  },
  created() {
    // since this component is called from various parent components, we need to save the actual route name

    this.originalPage = this.$route.name;
    if (this.$auth.user()) {
      let employee_id = optional(this.$auth.user(), "employee.data.id", null);

      this.task.assignee = this.employees.find((s) => s.id == employee_id);

      this.moreParams["employee_id"] = employee_id;
    }
    if (this.project_id) {
      this.moreParams["project_id"] = this.project_id;
    }
    if (this.milestone_id) {
      this.moreParams["project_milestone_id"] = this.milestone_id;
    }

    let filteredParams = this.$_.omitBy(this.moreParams, _.isNil);
    Object.keys(filteredParams).forEach((key) => {
      if (!filteredParams[key]) {
        delete filteredParams[key];
      }
    });
    let params = new URLSearchParams(filteredParams).toString();

    Promise.all([
      this.$store.dispatch("projects/index"),
      (this.project_id || this.moreParams["project_id"]) &&
        this.$store.dispatch(
          "projects/getCollaborators",
          this.project_id || this.moreParams["project_id"]
        ),
      this.$store.dispatch("employees/index").then((s) => {
        let employee_id = optional(this.$auth.user(), "employee.data.id", null);
        this.task.assignee = this.employees.find((s) => s.id == employee_id);
      }),
      this.$store.dispatch("tasks/index", params),
      this.$store.dispatch("global/getTaskStatuses"),
      this.project_id && this.$store.dispatch("projects/getMilestones", this.project_id),
    ]).finally(() => {
      this.loaded = true;
      this.isFetchingTasks = false;
    });
  },

  watch: {
    $route(to, from) {
      this.refreshTable();
    },
    table_mode: {
      handler(val) {
        if (this.isTable) {
          this.refreshTable();
        }
      },
      deep: true,
    },

    moreParams: {
      handler(newVal, oldVal) {
        this.filterTask(newVal, oldVal);
      },
      deep: true,
      immediate: false,
    },
  },
};
</script>

<style lang="scss" scoped>
$done: green;
$inprogress: #cccc11;
$pending: red;
$cancelled: #714242;
$testing: #0a5685;
.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 2px solid #4299e1 !important;
}
.kanban-loader {
  position: absolute;
  top: 50%;
  left: 45%;
}
.status-drag {
  background: red;
}
.kanban-board {
  .kanban-board__status {
    width: 300px;
    border-radius: 5px;
    background: #f5f5f5;
    position: relative;

    .kanban-board__tasks-priority {
      height: 24px;
      width: 24px;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: navajowhite;
      color: brown;
      border-width: 2px;

      i {
        font-size: 15px;
      }

      &:hover {
        border: 2px solid brown;
      }
    }

    &#NotStarted {
      // border-left:2px solid red;
      .kanban-board__tasks {
        border-left: 3px solid $pending;
      }
      .kanban-board__tasks-status-icon {
        background: $pending !important;
      }
    }
    &#InProgress {
      // border-left:2px solid red;
      .kanban-board__tasks {
        border-left: 3px solid $inprogress;
      }
      .kanban-board__tasks-status-icon {
        background: $inprogress !important;
      }
    }
    &#TestingReview {
      // border-left:2px solid red;
      .kanban-board__tasks {
        border-left: 3px solid $testing;
      }
      .kanban-board__tasks-status-icon {
        background: $testing !important;
      }
    }
    &#Done {
      // border-left:2px solid red;
      .kanban-board__tasks {
        border-left: 3px solid $done;
      }
      .kanban-board__tasks-status-icon {
        background: $done !important;
      }
    }
  }
  .kanban-board__tasks {
    background: white;
    box-shadow: 0 7px 30px 0 rgba(217, 217, 217, 0.36);
    border-radius: 3px;
    border: 2px solid white;
    cursor: pointer;
    &:hover {
      border: 2px solid #002579;
      border-left: 2px solid #002579 !important;
      font-family: "sansMedium";
    }
    .kanban-board__tasks-profile {
      height: 35px;
      width: 35px;
      border: 2px solid #e4e4e4;
      background: #f5f5f5;
      border-radius: 35px;
      position: relative;
      cursor: pointer;
      font-size: 12px;
    }
    .kanban-board__tasks-title {
      width: 89%;
      white-space: pre-wrap;
      line-height: 23px !important;
    }
    .kanban-board__tasks-project {
      background: white;
      border: 2px solid #e4e4e4;
      border-radius: 10px;
    }
    .kanban-board__tasks-status-icon {
      height: 7px;
      width: 7px;
      background: red;
      border-radius: 4px;
      // width: 20%;
    }
  }
  .kanban-board__status-task {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 400px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    padding: 20px;
    // backdrop-filter: blur(70px);
    background: white;
    border: 2px solid #e4e4e4;
    border-bottom: navajowhite;
    z-index: 2;
  }
  .kanban-board__tasks-footer {
    .kanban-board__tasks-footer-button {
      background: transparent;
      width: 100%;
      border: none;
      font-size: 13px;
      border-radius: 5px;
      font-family: "sansMedium";
      color: #0a5685;
      &:hover {
        background: white;
      }
    }
  }
}
.task-pane__profiles {
  &::after {
    content: "";
    width: 60px;
    background: black;
    opacity: 0.07;
    height: 2px;
    margin-left: 14px;
  }
  li {
    height: 40px;
    width: 40px;
    border: 2px solid #e4e4e4;
    background: #f5f5f5;
    border-radius: 40px;
    margin: -3px;
    position: relative;
    cursor: pointer;
    font-size: 14px;

    &#selected,
    &:hover {
      z-index: 9;
      margin-top: -10px;
      background: orange;
      font-family: "sansMedium";
    }
    &#selected {
      border-color: #002579;
    }
  }
}
</style>