<template>
  <div class="wrapper">
    <div class="row">
      <div class="col-sm-12 col-lg-6 wrapper-content-left">
        <div class="logo pl-4 mb-4">
          <img src="@/assets/img/leaf.svg">
        </div>
      </div>
      <div class="col-sm-12 col-lg-6 wrapper-content-right">
        <div class="content">
          <div class="row mt-5 pt-5 pl-5 pr-5">
            <div class="col-sm-12 pt-5 mt-2">
              <div class>
                <h2>{{'My Organizations'}}</h2>
                <small
                  class="text-opacity"
                >{{'Select from your registered organizations to continue!'}}</small>
              </div>
            </div>

            <div class="col-sm-12 pt-5 mt-2">
              <form @submit.prevent="saveOrganization">
                <div class="row">
                  <div class="col-sm-10 mt-3" :key="index" v-for="(org,index) in organizations">
                    <div
                      @click="setActive(index+1)"
                      :id="activeCard ===  index+1 ? 'card-selected' : ''"
                      class="card card-org p-4 pl-5"
                    >
                      <div class="row">
                        <div class="col-sm-2" style="margin-right:0;padding-right:0">
                          <div
                            class="logo"
                            style="height:50px;width:50px;background:orange; border-radius:50px;text-align:center; padding-top:13px;font-size: 16px;font-weight: bold;word-spacing: 200px;letter-spacing: 2px;opacity: .8;color: black;"
                          >
                            <span class="text-center">{{org && org.name && org.name.charAt(0)}}</span>
                          </div>
                        </div>
                        <div class="col-sm-8 pt-3">
                          <h6 style="font-size:15px !important">{{org.name}}</h6>
                        </div>
                        <div class="col-sm-2 text-right pt-2">
                          <i class="zmdi zmdi-chevron-right" style="font-size:25px"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="mt-5 col-sm-10 text-right float-right">
                    <Auth-Button :loading="loading" text="Proceed"/>
                  </div> -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Literals from "@/helpers/auth/literals.js";
import getOrganization from "@/helpers/getOrganization";
import {optional} from "@/helpers/random";

export default {
  data() {
    return {
      Literals,
      getOrganization,
      passwordType: "password",
      loading: false,
      errorMsg: [],
      successMsg: "",
      activeCard: 0
    };
  },
  computed: {
    organizations() {
      // if user is org owner, this will exist
      let org = optional(this.$auth.user(), 'organization.data', []);


// this will always exist as long as user belongs to an org
      if(this.$auth.user() && this.$auth.user().organizations) {
        org = [org, ...optional(this.$auth.user(), 'organizations.data', [])];
      }

      let seen = [];

// we wanna only show unique orgs
      org = org.filter(s => {
        let notSeen = !seen.includes(s.id);
        seen.push(s.id);
        return notSeen;
      })

      return org.filter(value => Object.keys(value).length !== 0);
    
    }
  },
  methods: {
    optional,
    saveOrganization() {
      let selected_org = this.organizations[this.activeCard - 1];
      let redirectTo = {name: 'dashboard'};
      console.log(selected_org.id)

      window.localStorage.setItem('selected_organization', selected_org.id);
      this.$auth.refresh().then(({
          data: {
              data: user
          }
      }) => {
          let { orgRole } = user;
          let organization = this.organizations.find(s => s.id == selected_org.id)
          this.$store.dispatch("global/setActiveOrganization", organization);
          
          user.role = orgRole;
          this.$auth.user(user)
          this.$router.push(redirectTo)
      })  


      // this.$router.push({ name: "dashboard" });
    },
    setActive(id) {
      this.activeCard = id;
      this.saveOrganization();
    }
  },
  created() {
    // if (this.$auth.user() && this.$auth.user().organization) {
    //   //set local storage variable after login
    //   window.localStorage.setItem('selected_organization', this.$auth.user().organization.id);
    //   let path = window.localStorage.getItem('redirect_from');
    //   window.localStorage.removeItem('redirect_from')
    //   this.$router.push(path ? { path } : {name: "projects-index"});
      
    // }
  }
};
</script>
