import axios from 'axios';
import {
    updatePending
} from "@/helpers/axiosIntercept";
const API = process.env.VUE_APP_BASE_URI;

export default {
    createUpdate({
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            let url = `${API}/tasks${payload && payload.id ? `/${payload.id}` : ''}`;
            let method = payload && payload.id ? axios.put(url, payload) : axios.post(url, payload);
            method.then(({
                    data : {
                        data
                    }
                }) => {
                    commit('SET_TASK', data);
                    resolve(data);
                })
                .catch((error) => {
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },

    getDashboardMetrics({
        commit
    }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/dashboard/metrics`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit("SET_DASHBOARD_METRICS", data);
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },

    getSingle({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/tasks/${id}?include=employee,project,status,parentTask,milestone,subtasks,project.manager,project.customer`;
            axios.get(url)
                .then(({
                    data : {data}
                }) => {
                 
                    commit('SET_TASK', data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_TASK', {});
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },

    getAttachments({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/tasks/${id}?include=attachments`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {

                    commit('SET_ATTACHMENTS', data.attachments);
                    resolve(data.attachments);
                })
                .catch(error => {
                    commit('SET_ATTACHMENTS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },

    delete({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/tasks/${id}`;
            axios.delete(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },

    getAllTimeLogs({commit}, payload) {
        const { employee_id, project_id, task_id, range_filter, filter_start, filter_end } = payload;
        
        return new Promise((resolve, reject) => {
            let url = `${API}/timelogs?employee_id=${employee_id}`;

            if (project_id){
                url += `&project_id=${project_id}`;
            }

            if (task_id) {
                url += `&task_id=${task_id}`;
            }

            if (filter_start && filter_end ) {
                url += `&range_filter=${range_filter}&filter_start=${filter_start}&filter_end=${filter_end}`;
            }

            axios.get(url)
                .then(({
                    data
                }) => {
                    commit('SET_ALL_TIME_LOGS', data);
                    resolve(data.data);
                })
                .catch(error => {
                    commit('SET_ALL_TIME_LOGS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });

    },

    getTimeLogs({commit}, id) {
        return new Promise((resolve, reject) => {
            let url = !isNaN(id) ? `${API}/timelogs?task_id=${id}&include=project,task` : `${API}/timelogs?include=project,task&${id}`;
            axios.get(url)
                .then(({
                    data
                }) => {
                   
                    commit('SET_TIME_LOGS', data);
                    resolve(data.data);
                })
                .catch(error => {
                    commit('SET_TIME_LOGS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },

    deleteTimeLog({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/timelogs/${id}`;
            axios.delete(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data);
                })
                .catch(error => {
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    deleteExpense({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/expenses/${id}`;
            axios.delete(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data);
                })
                .catch(error => {
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    createUpdateTimeLog({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/timelogs${data && data.id ? `/${data.id}` : ''}`;
            let method = data && data.id ? axios.put(url, data) : axios.post(url, data);
            method.then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data.id);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getExpenses({
        commit
    }, task_id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/expenses?task_id=${task_id}&include=currency,category,employee,managers`
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {

                    commit('SET_EXPENSES', data);
                    resolve(data.id);
                })
                .catch(error => {
                    commit('SET_EXPENSES', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    createUpdateExpense({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/expenses${data && data.id ? `/${data.id}` : ''}`;
            let method = data && data.id ? axios.put(url, data) : axios.post(url, data);
            method.then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data.id);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    approveExpense({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/expenses/${id}/approve`;

            axios.post(url).then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data.id);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    reAssign({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/tasks/${data.id}/assignments/${data.employee_id}`;
            axios.put(url, data)
                .then(({
                    data
                }) => {

                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    index({
        commit
    },params=null) {
        return new Promise((resolve, reject) => {
           
          params = params == null ? '&include=subtasks,subtasks.employee' : params;
            let url = `${API}/tasks?${params}`;
            axios.get(url)
                .then((data) => {
                    commit('SET_TASKS', data);
                    resolve(data.data.data);
                })
                .catch(error => {
                    commit('SET_TASKS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    tasks({commit}) {},
    getComments({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/tasks/${id}/comments`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {

                    commit('SET_COMMENTS', data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_COMMENTS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    deleteTaskComment({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/comments/${id}`;
            axios.delete(url)
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    reject(error.response);
                })
                .finally(() => updatePending(url));
        });
    },
    createUpdateComment({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = data && data.id ? `${API}/comments/${data.id}` : `${API}/tasks/${data.task_id}/comments`;
            let method = data && data.id ? axios.put(url, data) : axios.post(url, data);
            method.then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    deleteAttachment({
        commit
    }, {
        task_id,
        id
    }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/attachments/${id}`;
            axios.delete(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    
}