export function string(length = 16, number = false) {
    var result = '';
    var characters = number ? "1234567890" : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function arrayCompare(a1, a2) {
    if (a1.length != a2.length) return false;
    var length = a2.length;
    for (var i = 0; i < length; i++) {
        if (a1[i] !== a2[i]) return false;
    }
    return true;
}

function inArray(needle, haystack) {
    var length = haystack.length;
    for (var i = 0; i < length; i++) {
        if (typeof haystack[i] == 'object') {
            if (arrayCompare(haystack[i], needle)) return true;
        } else {
            if (haystack[i] == needle) return true;
        }
    }
    return false;
}


function password(len) {
    var length = (len) ? (len) : (10);
    var string = "abcdefghijklmnopqrstuvwxyz"; //to upper
    var numeric = '0123456789';
    var punctuation = "!@#$%^&*()_+~`|}{[]:;?><,./-=";
    var password = "";
    var character = "";
    while (password.length < length) {
        let entity1;
        entity1 = Math.ceil(string.length * Math.random() * Math.random());
        let entity2;
        entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
        let entity3;
        entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
        let hold;
        hold = string.charAt(entity1);
        hold = (password.length % 2 == 0) ? (hold.toUpperCase()) : (hold);
        character += hold;
        character += numeric.charAt(entity2);
        character += punctuation.charAt(entity3);
        password = character;
    }
    password = password.split('').sort(function () {
        return 0.5 - Math.random()
    }).join('');
    return password.substr(0, len);
}

export function handleRedirect(vm) {
    // console.log(vm)
    // handle conditional redirect here after user is logged in
    vm.$auth
        .refresh()
        .then(({
            data: {
                data: user
            }
        }) => {
            // let redirectFrom =  window.localStorage.getItem("redirect_from" );
            window.localStorage.removeItem('redirect_from');
            let redirectTo = null;
            user.selected_organization = 3
            vm.$auth.user(user);
            
            if (user.organizations && user.organizations.data && user.organizations.data.length > 1) {
                redirectTo = { name: "organization" };
            } else if (user.organizations && user.organizations.data && user.organizations.data.length == 1) {
                window.localStorage.setItem(
                    "selected_organization",
                    user.organizations && user.organizations.data && user.organizations.data[0].id
                );
                vm.$store.dispatch('global/setActiveOrganization', user.organizations && user.organizations.data && user.organizations.data[0])
                //console.log(window.localStorage.getItem("selected_organization"));
                redirectTo = { name: "dashboard" };
            } else {
                if (user.organization && user.organization.data && user.organization.data.id) {
                    window.localStorage.setItem(
                        "selected_organization",
                        user.organization && user.organization.data && user.organization.data.id
                    );
                    vm.$store.dispatch('global/setActiveOrganization', user.organization && user.organization.data && user.organization.data)
                    //console.log(window.localStorage.getItem("selected_organization"));
                    redirectTo = { name: "dashboard" };
                } else {
                    window.localStorage.setItem(
                        "selected_organization",
                        user.organization && user.organization.id
                    );
                    vm.$store.dispatch('global/setActiveOrganization', user && user.organization)
                    //console.log(window.localStorage.getItem("selected_organization"));
                    redirectTo = { name: "dashboard" };
                }
            }

            //set user role here
            // let userData = vm.$auth.user();
            // userData.role = userData.orgRole;
            // console.log(userData)
            // vm.$auth.user(userData)
                vm.$auth.refresh().then(({
                    data: {
                        data: user
                    }
                }) => {
                    let { orgRole } = user;
                    // console.log(vm.$store.getters['global/selected_org'])
                    user.role = orgRole;
                    vm.$auth.user(user)
                    vm.$router.push(redirectTo)
                })   
            
        })
}

export function optional(obj, key, defaultValue = null) {
    // if falsy value was passed, return immediately
    if (!obj) return isFunction(defaultValue) ? defaultValue(obj, key) : defaultValue;

    let localValue = obj;
    let keys = key.split('.');

    for (let i of keys) {
        localValue = localValue[i];

        if (!localValue) break;
    }

    return localValue ? localValue : (isFunction(defaultValue) ? defaultValue(obj, key) : defaultValue);
}

function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export function timerExist() {
    return !!window.localStorage.getItem('prevState');
}

export function html2text(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

export function parseQuery(search) {
    var args = search.substring(1).split('&');

    var argsParsed = {};

    var i, arg, kvp, key, value;

    for (i = 0; i < args.length; i++) {

        arg = args[i];

        if (-1 === arg.indexOf('=')) {

            argsParsed[decodeURIComponent(arg).trim()] = true;
        }
        else {

            kvp = arg.split('=');

            key = decodeURIComponent(kvp[0]).trim();

            value = decodeURIComponent(kvp[1]).trim();

            argsParsed[key] = value;
        }
    }

    return argsParsed;
}

export function truncate(val, length = 20) {
    return val.substring(0, length) + (val.length > length ? "..." : '');
}

export function resolveAddress(cust) {
    let address = cust && cust.address ? cust.address : null;

    if (cust) {
        let country = cust && cust.country;
        let region = cust && cust.region;
        let city = cust && cust.city;


        if (city && city.name) {
            address += ",<br>" + city.name;
        }
        if (region && region.name) {
            address += ",<br>" + region.name;
        }
        if (cust.post_code) {
            address += ",<br>" + cust.post_code;
        }
        if (country && country.name) {
            address += ",<br>" + country.name;
        }


    }

    return address;
}

export function objectEqual(x, y) {
    const ok = Object.keys, tx = typeof x, ty = typeof y;
    return x && y && tx === 'object' && tx === ty ? (
        ok(x).length === ok(y).length &&
        ok(x).every(key => objectEqual(x[key], y[key]))
    ) : (x === y);
}

export function linkify(inputText) {
    inputText = inputText || ''


    // experimenting with the above
    let doc = document.createElement("div");
    doc.innerHTML = inputText;

    doc.querySelectorAll("a").forEach((a) => {

        let url = window.location.href;
        url = url.substring(0, url.lastIndexOf("/"));
        let link = a.href.replace(`${url}/`, "");
        if (!(link.includes("https") || link.includes("http"))) {

            a.href = `https://${link}`;
        }
    });


    //return doc.innerHTML;

    inputText = doc.innerHTML

    let replacedText, replacePattern1, replacePattern2, replacePattern3;

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
    let matches = inputText.match(replacePattern1, '<a href="$1" target="_blank">$1</a>') || [];

    replacedText = inputText


    matches.map(s => {

        if (!s.includes(`${window.location.origin}/users/`)) {

            replacedText = replacedText.replace(s, `<a href="${s}" target="_blank">${s}</a>`)

        }
    })



    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText;
}

export default {
    string,
    password,
    inArray,
    handleRedirect,
    optional,
    timerExist,
    html2text,
    parseQuery,
    truncate,
    resolveAddress,
    objectEqual,
    linkify,
}