
let prioritizedCountryIds = [231,230,38,13,160,202];
let prioritizedCurrencyIds = [138,45,42,25,96,149];
export default {
    countries: (state) => {
        let countryArray = state.countries;
        if(countryArray.length) {
            let prioritized = [];
            // we're looping instead of using filter() so we can maintain the order of the IDs in prioritizedIds
            for (let id of prioritizedCountryIds) {
                prioritized.push(countryArray.find(s => s.id == id));
            }
            let others = countryArray.filter(s => !prioritizedCountryIds.includes(s.id));
            countryArray = [...prioritized, ...others];
        }
        return countryArray;
    },
    pay_periods: state => state.pay_periods,
    selected_org: state => state.selected_org,
    states: state => state.states,
    project_billing_types: state => state.project_billing_types,
    project_statuses: state => state.project_statuses,
    task_statuses: state => state.task_statuses,
    milestones: state => state.milestones,
    tasks: state => state.tasks,
    currencies: (state) => {
        if(state.currencies.length) {
            let prioritized = [];

            for (let id of prioritizedCurrencyIds) {
                prioritized.push(state.currencies.filter(s => s.id == id)[0]);
            }

            let others = state.currencies.filter(s => !prioritizedCurrencyIds.includes(s.id));
            return [...prioritized, ...others];
        }
        return state.currencies;
    },
    organization_key: state => state.organization_key,
    industries: state => state.industries,
    cities: state => state.cities,
    city: state => state.city,
    state: state => state.state,
    roles: state => state.roles,
    job_roles: state => state.job_roles,
    banks: state => state.banks,
    recipient_types: state => state.recipient_types,
    opportunity_stages: state => state.opportunity_stages,
    customer_sources: state => state.customer_sources,
    employee_types: state => state.employee_types,
    expense_categories: state => state.expense_categories,
    invoice_statuses: state => state.invoice_statuses,
    payment_sources: state => state.payment_sources,
    milestone_statuses: state => state.milestone_statuses,
    organization_addresses: state => state.organization_addresses,
    license: state => state.license,
    subscription: state => state.subscription,
}
