<template>
  <div class="container-fluid">
    <div class="table pb-4 pl-3 pr-4">
      <div class="table-header">
        <div class="row">
          <div class="col-sm-12 col-lg-4 float-left text-left">
            <small class="text-opacity">{{paginationData.total}} Total Payments</small>
          </div>

          <!--          <div class="col-sm-12 col-lg-8 float-right text-right">-->
          <!--            <invoice-filters-->
          <!--              @setFilters="setFilters"-->
          <!--              @showMoreFilters="showVisibleFilter"-->
          <!--              @removeColumnFromTable="removeColumnFromTable"-->
          <!--              :columns="columns"-->
          <!--              :params="moreParams"-->
          <!--              :visibleFilter="visibleFilter"-->
          <!--            ></invoice-filters>-->
          <!--          </div>-->
        </div>
        <!--        <invoice-filters-dropdown-->
        <!--          @setFilters="setFilters"-->
        <!--          :params="moreParams"-->
        <!--          :projects="projects"-->
        <!--          :customers="customers"-->
        <!--          :visibleFilter="visibleFilter"-->
        <!--        ></invoice-filters-dropdown>-->
      </div>
      <div class="table-wrapper mt-4">
        <vuetable
          ref="vuetable"
          :api-url="api"
          :first-page="0"
          :append-params="api.includes('/payment-batches/search/') ? {} : moreParams"
          :http-options="{ headers:{ 'Authorization' : 'Bearer ' + $auth.token(),'xorg' : getOrganization.organization_id() } }"
          @vuetable:loaded="loadTable"
          @vuetable:cell-clicked="gotoInvoice"
          :fields="columns.filter(s => s.active === true)"
          data-path="data"
          pagination-path
          :no-data-template="rowCount === null ? 'Loading data' : 'No Data Available'"
          @vuetable:pagination-data="onPaginationData"
        >
          <template slot="sn" slot-scope="props">
            <router-link
              :to="{path: `/payments/${props.rowData.id}/process`}"
            >{{props.rowIndex + 1}}</router-link>
          </template>

          <template
            slot="amount"
            slot-scope="{rowData}"
          >{{rowData.currency && rowData.currency.symbol}} {{numberWithCommas(parseFloat(rowData.amount).toFixed(2))}}</template>
          <template slot="budget" slot-scope="props">{{props.rowData.estimated_hours}} hours</template>

          <template slot="actions" slot-scope="props">
            <router-link :to="{path: `/payments/${props.rowData.id}/process`}">
              <button class="btn-lightgray-round">
                <i class="zmdi zmdi-plus pr-2"></i>View Payroll
              </button>
            </router-link>
            <router-link class="ml-2" :to="{name: `payments-show`,params: {id: props.rowData.id}}">
              <button class="btn-lightgray-round">
                <i class="zmdi zmdi-plus pr-2"></i>View Transactions Status
              </button>
            </router-link>
          </template>
        </vuetable>
        <vuetable-pagination
          v-show="paginationData.total"
          :css="table_css.pagination"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
        <div class="text-center" v-if="rowCount <=0 || rowCount === null">
          <slot name="empty"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import getOrganization from "@/helpers/getOrganization";
import InvoiceFilters from "@/components/Filters/Invoices/Index";
import InvoiceFiltersDropdown from "@/components/Filters/Invoices/More";
import VuetablePagination from "@/components/Layouts/VueTablePagination";
import getPaginationData from "@/helpers/pagination";
import { numberWithCommas } from "@/helpers/number";
import { toLocal } from "@/helpers/date";

export default {
  components: {
    // InvoiceFilters,
    // InvoiceFiltersDropdown,
    VuetablePagination
  },
  data() {
    return {
      getOrganization,
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      api: `${process.env.VUE_APP_BASE_URI}/payment-batches`,
      columns: [
        {
          name: "__slot:sn",
          title: "S/N",
          cvisible: true,
          active: true
        },
        {
          name: "employee_type.name",
          title: "Type",
          cvisible: true,
          active: true
        },
        {
          name: "purpose",
          title: "Subject",
          cvisible: true,
          active: true
        },
        {
          name: "start_date",
          title: "Start Date",
          cvisible: true,
          active: true,
          callback: s => {
            return toLocal(s).format("ll");
          }
        },
        {
          name: "end_date",
          title: "End Date",
          cvisible: true,
          active: true,
          callback: s => {
            return toLocal(s).format("ll");
          }
        },
        {
          name: "creator.data",
          title: "Creator",
          cvisible: true,
          active: true,
          callback: m => {
            return m ? m.first_name + " " + m.last_name : "N/A";
          }
        },
        {
          name: "__slot:actions",
          title: "Actions",
          active: true,
          cvisible: false
        }
      ],
      moreParams: {
        include: "creator,employeeType"
      },
      paginationData: {},
      filterColumns: []
    };
  },
  methods: {
    numberWithCommas,
    getPaginationData,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    gotoInvoice(e) {
      this.$router.push({ path: `/payments/${props.rowData.id}/process` });
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
    },
    setFilters(d) {
      for (let [key, value] of Object.entries(this.moreParams)) {
        if (typeof value === "object") {
          //append id to params
          this.moreParams[key + "_id"] = value.id;
        }
        if (value === "") {
          delete this.moreParams[key];
        }
      }
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onChangePage(page) {
      this.moreParams.page = page;
      this.setFilters();
    },
    refreshTable() {
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find(s => s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    }
  },
  computed: {
    projects() {
      return this.$store.getters["projects/projects_simple"];
    },
    customers() {
      return this.$store.getters["customers/customers_simple"];
    }
  },
  created() {
    this.projects.length ||
      this.$store.dispatch("customers/index", { per_page: 500 });
    this.customers.length ||
      this.$store.dispatch("projects/index", { per_page: 500 });
  }
};
</script>

