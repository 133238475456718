<template>
  <div>
    <delete-modal
      @trigger="setDeleteState"
      @cancel="cancelDelete"
      @deleteAction="deleteItem"
      :showDelete="showDelete"
      :name="showDeleteName"
      ref="delete"
    ></delete-modal>
    <log-time
      v-if="showLogTime"
      @trigger="setTimeLog"
      @cancelTimeLog="cancelTimeLog"
      :showLogTime="showLogTime"
      :singleTask="task"
      ref="timelog"
    ></log-time>
    <log-expense
      v-if="showExpenseForm"
      @trigger="setExpenseLog"
      @cancelExpense="cancelExpense"
      :showExpenseForm="showExpenseForm"
      :task="task"
      ref="expenselog"
    ></log-expense>
    <div class="row">
      <div class="col-sm-12 col-lg-4">
        <div class="row">
          <div class="col-sm-12">
            <accordion
              :visible="accordions[0].visible"
              :name="'PROJECT DETAILS'"
              @toggleCollapse="toggleCollapse(0)"
            >
              <div slot="content">
                <ul class="accordion-list">
                  <li>
                    <div class="row">
                      <div class="col-sm-4 col-lg-4">
                        <small class="text-opacity h7">Project Name:</small>
                      </div>
                      <div class="col-sm-4 col-lg-8">
                        <small class="text-bold text-opacity h7">{{
                          project.name
                        }}</small>
                      </div>
                    </div>
                  </li>
                  <li class="mt-3">
                    <div class="row">
                      <div class="col-sm-4 col-lg-4">
                        <small class="text-opacity h7">Customer:</small>
                      </div>
                      <div class="col-sm-4 col-lg-8">
                        <router-link
                          v-if="project.customer"
                          :to="{
                            name: 'customers-show',
                            params: { id: project.customer_id },
                          }"
                        >
                          <small class="text-bold text-opacity h7">{{
                            optional(
                              project,
                              "customer.data.company_name",
                              optional(project, "customer.company_name")
                            )
                          }}</small>
                        </router-link>
                      </div>
                    </div>
                  </li>
                  <li class="mt-3">
                    <div class="row">
                      <div class="col-sm-4 col-lg-4">
                        <small class="text-opacity h7">Project Manager:</small>
                      </div>
                      <div class="col-sm-4 col-lg-8">
                        <router-link
                          v-if="
                            project && project.manager && project.manager.data
                          "
                          :to="{
                            name: 'users-show',
                            params: { id: project.manager.data.id },
                          }"
                        >
                          <small class="text-bold text-opacity h7">{{
                            `${project.manager.data.first_name} ${project.manager.data.last_name}`
                          }}</small>
                        </router-link>
                      </div>
                    </div>
                  </li>
                  <li class="mt-3">
                    <div class="row">
                      <div class="col-sm-4 col-lg-4">
                        <small class="text-opacity h7">Project Type:</small>
                      </div>
                      <div class="col-sm-4 col-lg-8">
                        <small class="text-bold text-opacity h7">{{
                          project.billingType && project.billingType.name
                        }}</small>
                      </div>
                    </div>
                  </li>
                  <li class="mt-3">
                    <div class="row">
                      <div class="col-sm-4 col-lg-4">
                        <small class="text-opacity h7">Project Status</small>
                      </div>
                      <div class="col-sm-4 col-lg-8">
                        <small class="text-bold text-opacity h7">{{
                          project.projectStatus && project.projectStatus.name
                        }}</small>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </accordion>
          </div>
          <div class="col-sm-12 mt-4">
            <accordion
              :visible="accordions[3].visible"
              :name="'TIME TRACKING'"
              @toggleCollapse="toggleCollapse(3)"
            >
              <div slot="content">
                <ul class="accordion-list">
                  <li>
                    <div class="row">
                      <div class="col-sm-4 col-lg-4">
                        <small class="text-opacity h7">Time Budget:</small>
                      </div>
                      <div class="col-sm-4 col-lg-5">
                        <small class="text-bold text-opacity h7"
                          >{{ project.estimated_hours }} hours</small
                        >
                      </div>
                    </div>
                  </li>
                  <li class="mt-3">
                    <div class="row">
                      <div class="col-sm-4 col-lg-4">
                        <small class="text-opacity h7">Time Logged:</small>
                      </div>
                      <div class="col-sm-4 col-lg-5">
                        <small class="text-bold text-opacity h7"
                          >{{ getTotalLoggedHours }} hours</small
                        >
                      </div>
                    </div>
                  </li>
                  <li class="mt-3">
                    <div class="row">
                      <div class="col-sm-4 col-lg-4">
                        <small class="text-opacity h7">Time Balance:</small>
                      </div>
                      <div class="col-sm-4 col-lg-5">
                        <small class="text-bold text-opacity h7">{{ project.estimated_hours - getTotalLoggedHours }} hours</small>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </accordion>
          </div>
          <div class="col-sm-12 mt-4">
            <accordion
              :visible="accordions[4].visible"
              :name="'ADDITIONAL INFORMATION'"
              @toggleCollapse="toggleCollapse(4)"
            >
              <div slot="content">
                <ul class="accordion-list">
                  <li>
                    <div class="row">
                      <div class="col-sm-4 col-lg-4">
                        <small class="text-opacity h7">Description</small>
                      </div>
                      <div class="col-sm-4 col-lg-5">
                        <small class="text-bold text-opacity h7" v-html="project.description"></small>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </accordion>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-8">
        <div class="row">
          <div class="col-sm-12">
            <accordion
              :visible="accordions[1].visible"
              :name="'PROJECT MILESTONES'"
              @toggleCollapse="toggleCollapse(1)"
            >
              <span slot="icon">
                <router-link
                  style="
                    color: #3f92cc !important;
                    text-decoration: none !important;
                  "
                  :to="{ name: 'projects-milestones-new' }"
                >
                  <span class="pl-2 pr-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="#3f92cc"
                        d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 4c-.552 0-1 .448-1 1v2H5c-.552 0-1 .448-1 1s.448 1 1 1h2v2c0 .552.448 1 1 1s1-.448 1-1V9h2c.552 0 1-.448 1-1s-.448-1-1-1H9V5c0-.552-.448-1-1-1z"
                      ></path>
                    </svg>
                  </span>
                  Create a Milestone
                </router-link>
              </span>
              <div slot="content">
                <div class="mb-2 mt-2 scroll-table">
                  <vuetable
                    v-if="projectMilestoneApi"
                    class="in-tab-table"
                    ref="vuetableMilestone"
                    :api-url="projectMilestoneApi"
                    :http-options="{
                      headers: {
                        Authorization: 'Bearer ' + $auth.token(),
                        xorg: getOrganization.organization_id(),
                      },
                    }"
                    :fields="milestoneColumns"
                    data-path="data"
                    @vuetable:loaded="loadMilestoneTable()"
                    :no-data-template="
                      milestoneCount === null
                        ? 'Loading data'
                        : 'No Data Available'
                    "
                    pagination-path="meta.pagination"
                  >
                    <template slot="name" slot-scope="props">
                      <router-link
                        v-b-tooltip.hover
                        :title="props.rowData.name"
                        :to="{
                          name: 'milestones',
                          params: { mid: props.rowData.id },
                        }"
                        >{{ props.rowData.name | truncate(35) }}</router-link
                      >
                    </template>
                    <template slot="actions" slot-scope="props">
                      <router-link
                        v-b-tooltip.hover
                        title="Edit Milestone"
                        :to="{
                          name: 'projects-milestones-edit',
                          params: { mid: props.rowData.id },
                        }"
                      >
                        <button class="btn-lightgray-round">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z"
                            ></path>
                          </svg>
                        </button>
                      </router-link>
                      <button
                        v-b-tooltip.hover
                        title="Delete Milestone"
                        class="btn-lightgray-round-secondary btn-danger ml-2"
                        @click="setDeleteState('Milestone', props.rowData.id)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="20"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M9 13v6c0 .552-.448 1-1 1s-1-.448-1-1v-6c0-.552.448-1 1-1s1 .448 1 1zm7-1c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm-4 0c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm4.333-8.623c-.882-.184-1.373-1.409-1.189-2.291l-5.203-1.086c-.184.883-1.123 1.81-2.004 1.625l-5.528-1.099-.409 1.958 19.591 4.099.409-1.958-5.667-1.248zm4.667 4.623v16h-18v-16h18zm-2 14v-12h-14v12h14z"
                          ></path>
                        </svg>
                      </button>
                    </template>
                  </vuetable>
                </div>
              </div>
            </accordion>
          </div>
          <div class="col-sm-12 mt-4">
            <accordion
              :visible="accordions[2].visible"
              :name="'PROJECT TASKS'"
              @toggleCollapse="toggleCollapse(2)"
            >
              <span slot="icon">
                <router-link
                  style="
                    color: #3f92cc !important;
                    text-decoration: none !important;
                  "
                  :to="{ name: 'projects-tasks-new' }"
                >
                  <span class="pl-2 pr-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="#3f92cc"
                        d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 4c-.552 0-1 .448-1 1v2H5c-.552 0-1 .448-1 1s.448 1 1 1h2v2c0 .552.448 1 1 1s1-.448 1-1V9h2c.552 0 1-.448 1-1s-.448-1-1-1H9V5c0-.552-.448-1-1-1z"
                      ></path>
                    </svg>
                  </span>
                  Create new task
                </router-link>
              </span>
              <div slot="content" v-if="tasksReady">
                <div class>
                  <h6 class="h7 text-bold text-opacity-pale">Task Status</h6>
                  <multiselect
                    track-by="id"
                    label="name"
                    data-vv-as="Task Status"
                    name="task_status_id"
                    class="no-inline-select"
                    v-model="statuses"
                    :options="taskStatuses"
                    :show-labels="false"
                    :multiple="true"
                    :searchable="true"
                  ></multiselect>
                </div>
                <div class="mb-2 mt-2 scroll-table">
                  <vuetable
                    v-if="projectTasksApi"
                    ref="vuetableTask"
                    class="in-tab-table"
                    :api-url="projectTasksApi"
                    :append-params="taskMoreParams"
                    :http-options="{
                      headers: {
                        Authorization: 'Bearer ' + $auth.token(),
                        xorg: getOrganization.organization_id(),
                      },
                    }"
                    :fields="taskColumns"
                    data-path="data"
                    @vuetable:loaded="loadTaskTable"
                    :no-data-template="
                      taskCount === null ? 'Loading data' : 'No Data Available'
                    "
                    pagination-path
                    @vuetable:pagination-data="onPaginationData"
                  >
                    <template slot="id" slot-scope="props">
                      <router-link
                        v-b-tooltip.hover
                        :title="props.rowData.name"
                        :to="{
                          name: 'project-tasks-show-general',
                          params: { tid: props.rowData.id },
                        }"
                        >{{ props.rowData.name | truncate(30) }}</router-link
                      >
                    </template>
                    <template slot="status" slot-scope="props">
                      <i
                        :class="
                          'task-dot' +
                          ' ' +
                          props.rowData.status.name
                            .toLowerCase()
                            .replace(' ', '_')
                            .replace('/', '')
                        "
                      ></i>
                      {{ props.rowData.status && props.rowData.status.name }}
                    </template>
                    <template slot="actions" slot-scope="props">
                      <router-link
                        v-b-tooltip.hover
                        title="Edit Task"
                        :to="{
                          name: 'projects-tasks-edit',
                          params: { mid: props.rowData.id },
                        }"
                      >
                        <button class="btn-lightgray-round">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z"
                            ></path>
                          </svg>
                        </button>
                      </router-link>
                      <button
                        v-b-tooltip.hover
                        title="Delete Task"
                        @click="setDeleteState('Task', props.rowData.id)"
                        class="btn-lightgray-round-secondary btn-danger ml-2"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="20"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M9 13v6c0 .552-.448 1-1 1s-1-.448-1-1v-6c0-.552.448-1 1-1s1 .448 1 1zm7-1c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm-4 0c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm4.333-8.623c-.882-.184-1.373-1.409-1.189-2.291l-5.203-1.086c-.184.883-1.123 1.81-2.004 1.625l-5.528-1.099-.409 1.958 19.591 4.099.409-1.958-5.667-1.248zm4.667 4.623v16h-18v-16h18zm-2 14v-12h-14v12h14z"
                          ></path>
                        </svg>
                      </button>
                      <button
                        :disabled="timerExist"
                        v-b-tooltip.hover
                        title="Log Time for task"
                        @click="setTimeLog(props.rowData)"
                        class="btn-lightgray-round-secondary btn-success ml-2"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="20"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M15.91 13.34l2.636-4.026-.454-.406-3.673 3.099c-.675-.138-1.402.068-1.894.618-.736.823-.665 2.088.159 2.824.824.736 2.088.665 2.824-.159.492-.55.615-1.295.402-1.95zm-3.91-10.646v-2.694h4v2.694c-1.439-.243-2.592-.238-4 0zm8.851 2.064l1.407-1.407 1.414 1.414-1.321 1.321c-.462-.484-.964-.927-1.5-1.328zm-18.851 4.242h8v2h-8v-2zm-2 4h8v2h-8v-2zm3 4h7v2h-7v-2zm21-3c0 5.523-4.477 10-10 10-2.79 0-5.3-1.155-7.111-3h3.28c1.138.631 2.439 1 3.831 1 4.411 0 8-3.589 8-8s-3.589-8-8-8c-1.392 0-2.693.369-3.831 1h-3.28c1.811-1.845 4.321-3 7.111-3 5.523 0 10 4.477 10 10z"
                          ></path>
                        </svg>
                      </button>
                      <button
                        v-b-tooltip.hover
                        title="Log Expense for task"
                        @click="setExpenseLog(props.rowData)"
                        class="btn-lightgray-round-secondary btn-warning ml-2"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="20"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M18.5 15c-2.485 0-4.5 2.015-4.5 4.5s2.015 4.5 4.5 4.5 4.5-2.015 4.5-4.5-2.015-4.5-4.5-4.5zm0 2l2.5 3h-2v2h-1v-2h-2l2.5-3zm-6.346 3.894c-.918.506-1.996.839-3.154 1.001v2.105h-2v-1.996c-2.069-.037-4.213-.53-6-1.453l.909-3.289c1.912.742 4.457 1.53 6.45 1.079 2.299-.521 2.769-2.885.229-4.021-1.863-.867-7.557-1.611-7.557-6.487 0-2.725 2.078-5.165 5.969-5.7v-2.133h2v2.036c1.447.038 3.071.29 4.885.841l-.725 3.295c-1.535-.539-3.232-1.029-4.885-.929-2.979.174-3.24 2.752-1.162 3.832 2.854 1.342 6.425 2.401 7.534 5.203-2.025 1.497-3.058 4.039-2.493 6.616z"
                          ></path>
                        </svg>
                      </button>
                    </template>
                  </vuetable>
                  <vuetable-pagination
                    ref="pagination"
                    v-show="paginationData.total"
                    @vuetable-pagination:change-page="onChangePage"
                  ></vuetable-pagination>
                </div>
              </div>
            </accordion>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import Accordion from "@/components/Collapse/Index";
import getOrganization from "@/helpers/getOrganization";
import DeleteModal from "@/components/Modals/Delete";
import LogTime from "@/components/Modals/Time";
import LogExpense from "@/components/Modals/Expense";
import VuetablePagination from "@/components/Layouts/VueTablePagination";
import getPaginationData from "@/helpers/pagination";
import { toLocal } from "@/helpers/date";
import truncate from "@/mixins/truncate";
import { timerExist, optional } from "@/helpers/random";
export default {
  mixins: [truncate],
  data() {
    return {
      showExpenseForm: false,
      showLogTime: false,
      task: {},
      getOrganization,
      milestone_acc: false,
      showDelete: false,
      showDeleteName: "",
      selectedDeleteId: "",
      accordions: [
        { name: "project", visible: true },
        { name: "milestone", visible: true },
        { name: "tasks", visible: true },
        { name: "timetracking", visible: false },
        { name: "additional", visible: false },
      ],
      milestoneColumns: [
        {
          name: "__slot:name",
          title: "Name",
        },
        {
          name: "description",
          title: "Description",
          callback: (d) => {
            if (d && d.length > 30) {
              return d.substring(0, 30) + "...";
            }
            return d;
          },
        },
        {
          name: "status",
          title: "Status",
          callback: (d) => {
            return d && d.name;
          },
        },
        {
          name: "cost",
          title: "Amount",
          callback: (d) => {
            let amount = d;
            return `${optional(this.project, "currency.symbol", "")}  ${
              amount == null ? "0" : parseFloat(d).toFixed(2)
            }`;
          },
        },
        {
          name: "created_at",
          title: "Created At",
          callback: (d) => {
            return toLocal(d).format("MM/DD/YYYY");
          },
        },
        {
          name: "__slot:actions",
          title: "Actions",
        },
      ],
      taskColumns: [
        {
          name: "__slot:id",
          title: "Title",
          callback: (d) => {
            if (d) {
              if (d.length > 20) {
                return d.substring(0, 10) + "...";
              }
              return d;
            } else {
              return "N/A";
            }
          },
        },
        {
          name: "employee",
          title: "Assigned To",
          callback: (d) => {
            return d && d.data && d.data.user && d.data.user.data
              ? d.data.user.data.first_name
              : "Unassigned";
          },
        },
        {
          name: "__slot:status",
          title: "Status",
        },
        {
          name: "created_at",
          title: "Created At",
          callback: (d) => {
            return toLocal(d).format("MM/DD/YYYY");
          },
        },
        {
          name: "__slot:actions",
          title: "Actions",
        },
      ],
      projectMilestoneApi: "",
      taskMoreParams: {},
      paginationData: {},
      statuses: [],
      negatives: null,
      tasksReady: false,
      taskCount: null,
      milestoneCount: null,
    };
  },
  components: {
    Accordion,
    DeleteModal,
    LogExpense,
    LogTime,
    VuetablePagination,
  },
  computed: {
    timerExist,
    project() {
      return this.$store.getters["projects/project"];
    },
    taskStatuses() {
      return this.$store.getters["global/task_statuses"];
    },
    projectTasksApi() {
      let url = `${this.$baseApi}/projects/${this.$route.params.id}/tasks?include=employee`;

      if (this.statuses.length) {
        url += `&task_status_id=${this.statuses.map((s) => s.id)}`;
      }

      return url;
    },
    getTotalLoggedHours() {
      return this.$store.getters["projects/total_time_logs"];
    },
  },

  methods: {
    optional,
    getPaginationData,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    filterTasks() {
      this.$nextTick(() => {
        return this.$refs.vuetableTask && this.$refs.vuetableTask.tableData;
      });
    },
    onChangePage(page) {
      this.taskMoreParams.page = page;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetableTask.refresh();
      });
    },
    loadMilestoneTable() {
      let d = this.$refs.vuetableMilestone && this.$refs.vuetableMilestone.tableData;
      this.milestoneCount = parseInt(d && d.length);
    },
    loadTaskTable() {
      let d = this.$refs.vuetableTask && this.$refs.vuetableTask.tableData;
      this.taskCount = parseInt(d && d.length);
    },
    toggleCollapse(index) {
      this.accordions[index].visible = this.accordions[index].visible === false ? true : false;
    },
    setDeleteState(name, id) {
      this.showDelete = true;
      this.showDeleteName = name;
      this.selectedDeleteId = id;
    },
    cancelDelete() {
      this.showDelete = false;
    },
    deleteItem() {
      if (this.showDeleteName == "Milestone") {
        //delete milestone
        this.$store
          .dispatch("projects/deleteMilestone", this.selectedDeleteId)
          .then((s) => {
            this.$refs.vuetableMilestone.refresh();
            this.$toast.success("🚮 Milestone Successfully Deleted", {
              position: "top-right",
              duration: 3000,
            });
            this.showDelete = false;
          });
      } else if (this.showDeleteName == "Task") {
        //delete task
        this.$store
          .dispatch("tasks/delete", this.selectedDeleteId)
          .then((s) => {
            this.$refs.vuetableTask.refresh();
            this.$toast.success("🚮 Task Successfully Deleted", {
              position: "top-right",
              duration: 3000,
            });
            this.showDelete = false;
          });
      }
    },
    setTimeLog(task) {
      this.showLogTime = true;
      this.task = task;
      this.task.project = this.task.project && this.task.project.data;
    },
    cancelTimeLog() {
      this.showLogTime = false;
      this.task = {};
    },
    setExpenseLog(task) {
      this.showExpenseForm = true;
      this.task = task;
    },
    cancelExpense() {
      this.showExpenseForm = false;
      this.task = {};
    },
  },
  watch: {
    $route(from, to) {
      let vm = this;
      vm.$refs.vuetableMilestone.refresh();
      vm.$refs.vuetableTask.refresh();
      vm.$refs.vuetableMilestone.normalizeFields();
      vm.$refs.vuetableTask.normalizeFields();
    },
  },
  created() {
    this.filterTasks();
    this.$store.dispatch("projects/getTimeLogs", this.$route.params.id),
      this.$store.dispatch("global/getTaskStatuses").then((data) => {
        this.statuses = data.filter((s) => s.id != 4);
        this.tasksReady = true;
      });
    this.projectMilestoneApi = this.$baseApi + "/projects/" + this.$route.params.id + `/milestones?${getOrganization.append}`;
    //todo: this is a WIP
    // console.log(this.$refs.vuetable && this.$refs.vuetable.tableData);
    // this.$nextTick(() => {
    //  this.$
    // })
  },
};
</script>

<style scoped>
.blur {
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);

  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
</style>
