<template>
  <div class="container-fluid">
  <delete-modal
      @trigger="deleteState"
      @cancel="cancelDelete"
      @deleteAction="deleteItem"
      :showDelete="showDelete"
      :name="showDeleteName"
      ref="delete"
    ></delete-modal>
    <div class="table pt-2 pb-4 pl-3 pr-4">
<!--      <form class="d-form">-->
<!--        <div class="row">-->
<!--          <div class="col-sm-12 col-lg-2">-->
<!--            <h6 class="text-bold text-opacity-pale">Project</h6>-->
<!--            <multiselect-->
<!--              track-by="id"-->
<!--              return="id"-->
<!--              label="name"-->
<!--              name="city"-->
<!--              :options="['Project']"-->
<!--              :show-labels="false"-->
<!--              data-vv-as="City"-->
<!--            ></multiselect>-->
<!--          </div>-->
<!--          <div class="col-sm-12 col-lg-2">-->
<!--            <h6 class="text-bold text-opacity-pale">Task Status</h6>-->
<!--            <multiselect-->
<!--              track-by="id"-->
<!--              return="id"-->
<!--              label="name"-->
<!--              name="city"-->
<!--              :options="['Done']"-->
<!--              :show-labels="false"-->
<!--              data-vv-as="City"-->
<!--            ></multiselect>-->
<!--          </div>-->
<!--          <div class="col-sm-12 col-lg-3">-->
<!--            <div class="row">-->
<!--              <div class="col-sm-12 col-lg-6 date-range">-->
<!--                <h6 class="text-bold text-opacity-pale">Date From</h6>-->
<!--                <vc-date-picker-->
<!--                  :masks="{ input: ['YYYY-MM-DD']}"-->
<!--                  :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: `Start date`}"-->
<!--                  v-validate="'required'"-->
<!--                  name="start_from"-->
<!--                  ref="startFrom"-->
<!--                  data-vv-as="Project Start date"-->
<!--                />-->
<!--              </div>-->
<!--              <div class="col-sm-12 col-lg-6">-->
<!--                <h6 class="text-bold text-opacity-pale">Date From</h6>-->
<!--                <vc-date-picker-->
<!--                  :masks="{ input: ['YYYY-MM-DD']}"-->
<!--                  :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: `Start date`}"-->
<!--                  v-validate="'required'"-->
<!--                  name="start_from"-->
<!--                  ref="startFrom"-->
<!--                  data-vv-as="Project Start date"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-sm-12 col-lg-3">-->
<!--            <h6 class="text-bold text-opacity-pale">Search Keyword</h6>-->
<!--            <input-->
<!--              type="text"-->
<!--              style="font-size:14px"-->
<!--              class="form-control auth-input d-input"-->
<!--              placeholder="Enter Search keyword"-->
<!--            >-->
<!--          </div>-->
<!--          <div class="col-sm-12 col-lg-2 mt-4">-->
<!--            <button-->
<!--              type="submit"-->
<!--              :disabled="loading ? true: false"-->
<!--              class="btn pl-5 pr-5 btn-primary"-->
<!--            >-->
<!--              <div v-if="!loading">{{'Apply'}}</div>-->
<!--              <div v-else class="spinner-grow spinner-grow-sm text-light" role="status">-->
<!--                <span class="sr-only">Loading...</span>-->
<!--              </div>-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </form>-->
      <div class="table-wrapper mt-4">
        <transition name="slide-fade">
          <div v-if="user" v-show="table_mode && table_mode.type && table_mode.type.name === 'Table'">
            <vuetable
              ref="vuetable"
              :api-url="api"
              @vuetable:cell-clicked="gotoTask"
              :first-page="0"
              :append-params="moreParams"
              :http-options="{ headers:{ 'Authorization' : 'Bearer ' + $auth.token(),'xorg' : getOrganization.organization_id() } }"
              @vuetable:loaded="loadTable"
              :fields="columns.filter(s => s.active === true)"
              :row-class="onRowClass"
              data-path="data"
              pagination-path
              :no-data-template="rowCount === null ? 'Loading data' : 'No Data Available'"
              @vuetable:pagination-data="onPaginationData"
            >
              <template slot="sn" class="xc" slot-scope="props">
                <router-link
                  :to="{name: 'tasks-show', params: {id: props.rowData.id}}"
                >{{props.rowIndex + 1}}</router-link>
              </template>
              <template slot="status" slot-scope="props">
                <div class="status">Nothing goes here {{props.rowData.id}}</div>
              </template>
               <template slot="actions" slot-scope="{rowData}">
           <span
          @click="deleteState(rowData.id)"
          title="Delete Timelog"
          
        >
         
          <span class="pl-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="#72809D"
              viewBox="0 0 30 30"
            >
              <path
                d="M9 13v6c0 .552-.448 1-1 1s-1-.448-1-1v-6c0-.552.448-1 1-1s1 .448 1 1zm7-1c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm-4 0c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm4.333-8.623c-.882-.184-1.373-1.409-1.189-2.291l-5.203-1.086c-.184.883-1.123 1.81-2.004 1.625l-5.528-1.099-.409 1.958 19.591 4.099.409-1.958-5.667-1.248zm4.667 4.623v16h-18v-16h18zm-2 14v-12h-14v12h14z"
              ></path>
            </svg>
          </span>
        </span>
        </template>
            </vuetable>
          </div>
        </transition>
        <vuetable-pagination
          v-show="paginationData.total"
          :css="table_css.pagination"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
        <div class="text-center" v-if="rowCount <=0 || rowCount === null">
          <slot name="empty"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import { pagination } from "@/helpers/pagination.js";
import ldash from "@/helpers/ldash.js";
import getOrganization from "@/helpers/getOrganization";
import VuetablePagination from "@/components/Layouts/VueTablePagination";
import Vue from "vue";
import getPaginationData from "@/helpers/pagination";
import {toLocal} from "@/helpers/date";
import DeleteModal from "@/components/Modals/Delete";

export default {
  components: {
    VuetablePagination,
    DeleteModal
  },
  data() {
    return {
      getOrganization,
      ldash,
      loading: false,
      table_mode: {
        type: { name: "Table" }
      },
      table_group_mode: {
        name: "Project Title",
        slug: "project_id",
        include: "project"
      },
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      columns: [
        {
          name: "__checkbox",
          titleClass: "center aligned",
          dataClass: "center aligned",
          cvisible: false,
          table_group: false,
          active: true,
          callback: table_group => {
            console.log(table_group);
          }
        },
        {
          title: "Project",
          name: "project",
          cvisible: true,
          active: true,
          table_group: true,
          callback: s => {
            return s && s.data && s.data.name ? s.data.name : "N/A";
          }
        },
        {
          title: "Task Title",
          name: "task",
          cvisible: true,
          active: true,
          table_group: true,
          sortable: true,
          callback: s => {
            return s && s.data && s.data.name ? s.data.name : "N/A";
          }
        },
        {
          name: "note",
          title: "Description",
          cvisible: true,
          active: true,
          table_group: false,
        },
        {
          name: "hours",
          title: "Time Logged",
          cvisible: true,
          active: true,
          table_group: false,
          callback: s => {
            return `${s} hours`;
          }
        },
        {
          name: "start_time",
          title: "Start Time",
          cvisible: true,
          active: true,
          table_group: false,
          callback: s => {
            return toLocal(s).format('LLL').toLocaleString();
          }
        },
        {
          name: "end_time",
          title: "End Time",
          cvisible: true,
          active: true,
          table_group: false,
          callback: s => {
            return toLocal(s).format('LLL').toLocaleString();
          }
        },
        {
          name: "date_billed",
          title: "Date Billed",
          cvisible: true,
          active: true,
          table_group: false,
          callback: s => {
            return s ? toLocal(s).format('LLL').toLocaleString() : 'N/A';
          }
        },
        {
          name: "date_paid",
          title: "Date Paid",
          cvisible: true,
          active: true,
          table_group: false,
          callback: s => {
            return s ? toLocal(s).format('LLL').toLocaleString() : 'N/A';
          }
        },
        {
          name: "__slot:actions",
          title: "Actions",
          cvisible: true,
          active: true,
          table_group: false,
          
        }
      ],
      moreParams: {
        include: "employee,status,project,milestone"
      },
      paginationData: {},
      filterColumns: [],
      statuses: [{id: true, name: "Active"}, {id: false, name: "Inactive"}],
      showDelete: false,
      showDeleteName: "",
      selectedItem : null
    };
  },
  methods: {
    customTableOptionLabel({ name, svg }) {
      return name && svg ? `${svg} – ${name}` : "";
    },
    onRowClass(dataItem, index) {
      let status =
        dataItem.status && dataItem.status.name ? dataItem.status.name : " ";
      status = status
        .toLowerCase()
        .replace(" ", "_")
        .replace("/", "");
      return status;
    },
    gotoTask(e) {
      this.$router.push({
        name: "tasks-show",
        params: { id: e.id }
      });
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
      if (d && d.length > 0) {
        this.rowCount = d;
        const result = {};
        let key = this.table_group_mode && this.table_group_mode.slug;
        this.rowCount = d.forEach(item => {
          if (!result[item[key]]) {
            result[item[key]] = [];
          }
          result[item[key]].push(item);
        });
        this.rowCount = result;
      }
    },
    loadTableByGroup() {
      let d = this.$refs.vuetable.tableData;
      if (d && d.length > 0) {
        let key = "project_id";
      }
    },
    onCellClicked(data, field, event) {
      this.$refs.vuetable2.toggleDetailRow(data.id);
    },
    setFilters(d) {
      for (let [key, value] of Object.entries(this.moreParams)) {
        if (typeof value === "object") {
          //append id to params
          this.moreParams[key + "_id"] = value.id;
        }
        if (value === "") {
          delete this.moreParams[key];
        }
      }
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onChangePage(page) {
      this.moreParams.page = page;
      this.setFilters();
    },
    refreshTable() {
      
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find(s => s && s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    },
    onPaginationData(paginationData) {
      delete paginationData.data;
      this.paginationData = getPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    deleteState(id) {
      this.showDelete = true;
      this.showDeleteName = "User Timelog";
      this.selectedItem = id;
    },
    cancelDelete() {
      this.showDelete = false;
      this.selectedItem = null;
    },
    deleteItem(s) {
      this.$store
        .dispatch("tasks/deleteTimeLog", this.selectedItem)
        .then((s) => {
          this.showDelete = false;
          this.selectedItem = null;

          this.refreshTable();
          this.$toast.success("🚮 User TimeLog Successfully Deleted", {
            position: "top-right",
            duration: 3000,
          });
        });
    },
  },
  computed: {
    employee() {
      return this.$store.getters["employees/employee"];
    },
    user() {
      return this.$store.getters["employees/user"]
    },
    api() {
      return `${this.$baseApi}/timelogs?employee_id=${this.employee.id}`
    }
  },
  mounted() {},
  watch: {
    $route(to, from) {
      this.refreshTable();
    }
  }
};
</script>

