export default {
    SET_CUSTOMERS(state, array) {
        state.customers = array;
    },
    SET_CUSTOMER(state, array) {
        state.customer = array;
    },
    SET_CUSTOMER_METRICS(state,array) {
        state.customer['customer_metrics'] = array;
    },
    SET_PROJECTS(state, array) {
        state.projects = array;
    },
    SET_TASKS(state, array) {
        state.tasks = array;
    },
    SET_INVOICES(state, array) {
        state.invoices = array;
    },
    SET_ATTACHMENTS(state, array) {
        state.attachments = array;
    },
    SET_OPPORTUNITIES(state, array) {
        state.opportunities = array;
    },
    SET_CONTACTS(state, array) {
        state.contacts = array;
    },
    SET_CONTACT(state, array) {
        state.contact = array;
    },
    ADD_CUSTOMER(state, array) {
        if(state.customers.length) {
            let customers = state.customers;
            customers.push(array);
            state.customers = customers;
        }
    },

}
