import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const customers = {
    namespaced: true,
    state: {
        customers: [],
        customer: {},
        projects: [],
        tasks: [],
        invoices: [],
        attachments: [],
        opportunities: [],
        contacts: [],
        contact: {},
    },
    actions,
    getters,
    mutations
}
