<template>
  <div class="container-fluid">
    <div class="table pb-4 pl-3 pr-4">
      <div class="table-header">
        <div class="row">
          <div class="col-sm-12 col-lg-4 float-left text-left">
            <small class="text-opacity"
              >{{ reports.length }} total reports</small
            >
            <button
              v-if="reports.length"
              class="bg-black btn btn-ds mx-2"
              @click="csvExport()"
            >
              Export to CSV
            </button>
          </div>
        </div>
      </div>
      <div class="table-wrapper mt-4">
        <!-- <div class="d-flex copy-btn flex-wrap justify-content-start">
          <h6 class="mr-2 icon">Op</h6>
          <h6 class="mr-2 link">https://leafbmp.com/reports/ilo00fsf</h6>
          <h6 class="mr-2 link-copy">Copy link</h6>
        </div> -->
        <vuetable
          ref="vuetable"
          :api-mode="false"
          :fields="columns"
          :data="reports"
          :no-data-template="'No Data Available'"
        >
        </vuetable>

        <div class="text-center" v-if="reports.length <= 0">
          <slot name="empty"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import getOrganization from "@/helpers/getOrganization";
import getPaginationData from "@/helpers/pagination";
import { toLocal } from "@/helpers/date";
import random from "@/helpers/random";
import { parseQuery, html2text, optional } from "@/helpers/random";
import truncate from "@/mixins/truncate";

export default {
  mixins: [truncate],
  components: {},
  data() {
    return {
      getOrganization,
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      moreParams: {},
      paginationData: {},
      filterColumns: [],
    };
  },
  methods: {
    getPaginationData,
    html2text,
    toLocal,
    onChangePage(page) {
      this.moreParams.page = page;
      this.setFilters();
    },
    refreshTable() {
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find((s) => s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    },
    csvExport() {
      let arrData = this.reports;
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        this.columns.map((s) => `"${s.name}"`).join(","),
        ...arrData.map((item) => {
          let value = {};
          for (let column of this.columns) {
            item[column.name] = String(item[column.name]).replaceAll('"', '""')
            value[column.name] = `"${html2text(item[column.name])}"`;
          }
          return Object.values(value).join(",");
        }),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      const date = toLocal(Date.now()).format("LL").toLocaleString().replaceAll(' ', '-').replaceAll(',', '');
      link.setAttribute("href", data);
      link.setAttribute("download", `Report - ${date}.csv`);
      link.click();
    },
  },
  computed: {
    reports() {
      let key = optional(this.$route, 'query.url', this.$route.name).toString();
      let cached = this.$store.getters["reports/cached"];

      if(cached[key]) {
        return cached[key];
      }

      let data = this.$store.getters["reports/reports"].map(s => {
        let data = {};
        Object.keys(s).forEach(k => {
          data[k] = html2text(s[k]);
        })
        return data;
      });

      cached[key] = data;
      this.$store.commit("reports/SET_CACHED", cached);
      return data;
    },
    columns() {
      let { columns } = parseQuery(this.$route.query.url || '');

      if(!columns) return [];

      return columns.split(",").map((key) => {
        return {
          title: key.replaceAll("_", " ").toUpperCase(),
          name: key,
          cvisible: true,
          active: true,
          table_group: true,
          sortable: true,
        };
      });
    },
  },
  mounted() {
   
  },
  created() {
    
  },
  watch: {
    $route($to, $from) {
      $to.name == "reporting-index" && this.refreshTable();
      if($from.query.url) {
        let cached = this.$store.getters["reports/cached"];
        delete cached[$from.query.url];
        this.$store.commit("reports/SET_CACHED", cached);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.copy-btn {
  background: wheat;
  width: 100%;
}
</style>
