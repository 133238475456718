<template>
  <div v-if="renderComponent">
    <b-modal
      v-if="loaded && !isHidden"
      @hide="close"
      :no-close-on-backdrop="true"
      :visible="showLogTime"
      id="timeLogModal"
      :hide-footer="(timerStopped && !timerCompleted) || log_type == ''"
      ref="timeLogModal"
    >
      <template v-slot:modal-header>
        <h2 class="pl-1 pt-3 pr-3 f-24">{{ "Log time" }}</h2>
        <button class="close" @click="close">
          Cancel
          <i class="zmdi zmdi-close-circle pl-2" style="font-size:20px"></i>
        </button>
      </template>
      <div class="d-block pb-4">
        <div class="mb-4" v-if="log_type == '' && !timerRunning">
          <h6 class="h6 text-opacity-pale text-bold">How do you want to log this time ?</h6>

          <ul class="d-flex flex-column dfs mt-4" >
            <li
              style="background:#eaeaea;border-radius:5px;cursor:pointer"
              @click="startStopTimer"
              class="p-2 pl-4 d-flex flex-row justify-content-between align-items-center"
            >
              <h6 class="h7 text-bold text-opacity-pale">Log using Stopwatch</h6>
              <h6 class="pr-3">
                <i style="font-size:20px" class="zmdi zmdi-chevron-right"></i>
              </h6>
            </li>
            <li
              style="background:#eaeaea;border-radius:5px;cursor:pointer"
              @click="log_type = 'manually'"
              class="p-2 mt-3 pl-4 d-flex flex-row justify-content-between align-items-center"
            >
              <h6 class="h7 text-bold text-opacity-pale">Log manually</h6>
              <h6 class="pr-3">
                <i style="font-size:20px" class="zmdi zmdi-chevron-right"></i>
              </h6>
            </li>
          </ul>
        </div>
        <div class="text-center" v-if="timerState !== 'Completed'">
          <button
            @click="startStopTimer"
            v-show="timerRunning"
            v-bind:class="{
              stopwatch: true,
              'stop-red': timerRunning,
              'stop-green': !timerRunning,
              'mr-4': true,
            }"
          >
            <span class="pr-1">
              <svg
                width="16px"
                height="20px"
                viewBox="0 0 16 20"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g id="Work-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="24-C" transform="translate(-579.000000, -137.000000)">
                    <g id="Group-20" transform="translate(521.000000, 54.000000)">
                      <g id="Group-15" transform="translate(24.000000, 76.000000)">
                        <g id="Group-16" transform="translate(30.000000, 5.000000)">
                          <g id="Group-17">
                            <g id="Group-2-Copy">
                              <rect
                                id="Rectangle"
                                fill-opacity="0"
                                fill="#FFFFFF"
                                x="0"
                                y="0"
                                width="24"
                                height="24"
                                rx="4"
                              ></rect>
                              <g
                                id="time-stopwatch"
                                transform="translate(5.000000, 3.000000)"
                                stroke="#FFFFFF"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                              >
                                <line x1="11.2335" y1="5.6415" x2="12.65625" y2="4.21875" id="Path"></line>
                                <line x1="12.375" y1="3.9375" x2="12.9375" y2="4.5" id="Path"></line>
                                <line x1="6.75" y1="3.9375" x2="6.75" y2="0.5625" id="Path"></line>
                                <line x1="8.4375" y1="0.5625" x2="5.0625" y2="0.5625" id="Path"></line>
                                <circle id="Oval" cx="6.75" cy="10.6875" r="6.75"></circle>
                                <line x1="6.75" y1="6.1875" x2="6.75" y2="6.1875" id="Path"></line>
                                <path
                                  d="M6.75,6.1875 C6.59466991,6.1875 6.46875,6.31341991 6.46875,6.46875 C6.46875,6.62408009 6.59466991,6.75 6.75,6.75 C6.90533009,6.75 7.03125,6.62408009 7.03125,6.46875 C7.03125,6.31341991 6.90533009,6.1875 6.75,6.1875"
                                  id="Path"
                                ></path>
                                <path
                                  d="M6.75,14.625 C6.59466991,14.625 6.46875,14.7509199 6.46875,14.90625 C6.46875,15.0615801 6.59466991,15.1875 6.75,15.1875 C6.90533009,15.1875 7.03125,15.0615801 7.03125,14.90625 C7.03125,14.7509199 6.90533009,14.625 6.75,14.625 L6.75,14.625"
                                  id="Path"
                                ></path>
                                <path
                                  d="M2.53125,10.40625 C2.37591991,10.40625 2.25,10.5321699 2.25,10.6875 C2.25,10.8428301 2.37591991,10.96875 2.53125,10.96875 C2.68658009,10.96875 2.8125,10.8428301 2.8125,10.6875 C2.8125,10.5321699 2.68658009,10.40625 2.53125,10.40625 L2.53125,10.40625"
                                  id="Path"
                                ></path>
                                <path
                                  d="M10.96875,10.40625 C10.8134199,10.40625 10.6875,10.5321699 10.6875,10.6875 C10.6875,10.8428301 10.8134199,10.96875 10.96875,10.96875 C11.1240801,10.96875 11.25,10.8428301 11.25,10.6875 C11.25,10.5321699 11.1240801,10.40625 10.96875,10.40625 L10.96875,10.40625"
                                  id="Path"
                                ></path>
                                <path
                                  d="M3.76725,7.425 C3.65343537,7.42479646 3.55071734,7.49320837 3.50703571,7.59830703 C3.46335408,7.70340568 3.48732068,7.82447089 3.56775,7.905 C3.67751659,8.0149737 3.8556513,8.01514159 3.965625,7.905375 C4.0755987,7.79560841 4.07576659,7.6174737 3.966,7.5075 C3.91340002,7.45459998 3.84185001,7.42489997 3.76725,7.425 L3.76725,7.425"
                                  id="Path"
                                ></path>
                                <path
                                  d="M9.73275,13.38975 C9.65813341,13.3895664 9.58655248,13.4192792 9.534,13.47225 C9.43917672,13.5671915 9.42462893,13.715896 9.4992542,13.8274147 C9.57387946,13.9389333 9.71689138,13.9822032 9.84082257,13.93076 C9.96475375,13.8793169 10.035084,13.7474899 10.0087986,13.6159057 C9.98251323,13.4843214 9.8669339,13.3896319 9.73275,13.38975 L9.73275,13.38975"
                                  id="Path"
                                ></path>
                                <path
                                  d="M3.76725,13.38975 C3.65343537,13.3895465 3.55071734,13.4579584 3.50703571,13.563057 C3.46335408,13.6681557 3.48732068,13.7892209 3.56775,13.86975 C3.63875654,13.9408905 3.74232229,13.9687455 3.83943505,13.9428223 C3.93654781,13.9168991 4.01245383,13.841136 4.03856005,13.7440723 C4.06466627,13.6470086 4.03700654,13.5433905 3.966,13.47225 C3.91340002,13.41935 3.84185001,13.38965 3.76725,13.38975 L3.76725,13.38975"
                                  id="Path"
                                ></path>
                                <path
                                  d="M9.93225,7.506 C9.87941952,7.45298577 9.80759331,7.4232832 9.73275,7.4235 L9.73275,7.4235 C9.65813341,7.42331635 9.58655248,7.4530292 9.534,7.506 C9.42436932,7.61590818 9.42451927,7.79385655 9.53433503,7.9035798 C9.64415078,8.01330306 9.82209922,8.01330306 9.93191497,7.9035798 C10.0417307,7.79385655 10.0418807,7.61590818 9.93225,7.506"
                                  id="Path"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
            {{ timerState }} Clock
          </button>
        </div>
        <div v-if="timerRunning" class="text-center mt-4 timer-countdown" id="stopwatch">
          <span id="hour">{{ hourOut }}</span> :
          <span id="min">{{ minOut }}</span> :
          <span id="sec">{{ secOut }}</span>
          <!-- <span id="milisec">{{miliSecOut}}</span> -->
        </div>

        <transition name="slide-fade" v-if="!timerRunning">
          <div class="mt-1" v-if="timerStopped && !timerCompleted">
            <h6 class="text-opacity-pale h7 text-bold border-bottom pb-3">Timer Summary</h6>
            <div class="border-bottom pb-4">
              <div class="w-50 mx-auto">
                <ul class="d-flex rrr flex-row justify-content-between mt-4">
                  <li class="d-flex flex-column justify-content-center align-items-center">
                    <h6
                      class="text-bold text-opacity-pale"
                      style="font-size:23px !important"
                    >{{ hourOut }}</h6>
                    <small class="text-opacity">hours</small>
                  </li>
                  <li class="d-flex flex-column justify-content-center align-items-center">
                    <h6
                      class="text-bold text-opacity-pale"
                      style="font-size:23px !important"
                    >{{ minOut }}</h6>
                    <small class="text-opacity">minutes</small>
                  </li>
                  <li class="d-flex flex-column justify-content-center align-items-center">
                    <h6
                      class="text-bold text-opacity-pale"
                      style="font-size:23px !important"
                    >{{ secOut }}</h6>
                    <small class="text-opacity">seconds</small>
                  </li>
                </ul>
              </div>
            </div>
            <div class="mt-5 d-flex flex-row justify-content-center">
              <button
                @click="startStopTimer"
                id="resume-btn"
                class="mr-3 btn bbt d-flex flex-row align-items-center justify-content-center"
                style="background:#F7F7F7"
              >
                <h6 class="pb-0 mb-0 h7 text-bold">
                  <i class="zmdi zmdi-play pr-2"></i> Resume
                </h6>
              </button>
              <button
                @click="reset()"
                id="reset-btn"
                class="mr-3 btn bbt d-flex flex-row align-items-center justify-content-center"
                style="background:#F7F7F7"
              >
                <h6 class="pb-0 mb-0 h7 text-bold">
                  <i class="zmdi zmdi-stop pr-2"></i>Reset
                </h6>
              </button>
              <button
                @click="timerCompleted = true;timerState='Start'; log_type = 'manually'"
                class="mr-3 btn bbt d-flex flex-row align-items-center justify-content-center"
                style="background:#0a5685;color:white"
              >
                <h6 class="pb-0 mb-0 h7 text-bold">
                  <i class="zmdi zmdi-plus-circle pr-2"></i>Save Timer
                </h6>
              </button>
            </div>
          </div>
          <div class="mt-0" v-else>
            <form @submit.prevent="save" v-show="log_type == 'manually'">
              <div class="row auth-form">
                <div id="date_span" class="col-sm-12 col-lg-12 mt-3 date-range">
                  <h6 class="text-bold text-opacity-pale">
                    Date
                    <b>*</b>
                  </h6>
                  <datetime
                    v-model="body.start_date"
                    :type="'date'"
                    input-class="form-control auth-input d-input"
                    v-validate="'required'"
                    name="start_date"
                    placeholder="Start Date"
                    data-vv-as="Start Date"
                  />
                  <span @click="() => (body.start_date = null)" v-if="timerRan">
                    <i class="zmdi zmdi-close-circle pl-2" style="font-size: 20px"></i>
                  </span>
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('start_time')"
                  >{{ errors.first("start_time") }}</small>
                </div>
                <div
                  class="col-sm-12 col-lg-6 mt-3 date-range"
                  id="date_span"
                  v-if="timerRan || !body.hours"
                >
                  <h6 class="text-bold text-opacity-pale">
                    Start Time
                    <b>*</b>
                  </h6>
                  <datetime
                    type="time"
                    input-class="form-control auth-input d-input"
                    v-validate="!body.hours ? 'required' : ''"
                    name="start_time"
                    :disabled="timerRan"
                    v-model="body.start_time"
                    placeholder="Start Time"
                    data-vv-as="Start Time"
                  />
                  <span @click="() => (body.start_time = null)" v-if="!timerRan">
                    <i class="zmdi zmdi-close-circle pl-2" style="font-size: 20px"></i>
                  </span>
                </div>
                <div
                  class="col-sm-12 col-lg-6 mt-3 date-range"
                  id="date_span"
                  v-if="timerRan || !body.hours"
                >
                  <h6 class="text-bold text-opacity-pale">
                    End Time
                    <b>*</b>
                  </h6>
                  <datetime
                    type="time"
                    input-class="form-control auth-input d-input"
                    v-validate="!body.hours ? 'required' : ''"
                    name="end_time"
                    :disabled="timerRan"
                    v-model="body.end_time"
                    placeholder="End Time"
                    data-vv-as="End Time"
                  />
                  <span @click="() => (body.end_time = null)" v-if="!timerRan">
                    <i class="zmdi zmdi-close-circle pl-2" style="font-size: 20px"></i>
                  </span>
                </div>

                <div class="col-sm-12 col-lg-12 mt-3" v-if="!timerRan">
                  <h6 class="text-bold text-opacity-pale">
                    Hours
                    <b>*</b>
                  </h6>
                  <vue-timepicker
                    title="Hours & Minutes"
                    v-validate="
                      !body.start_time || !body.end_time ? 'required' : ''
                    "
                    name="end_time"
                    class="w-100"
                    :disabled="timerRan"
                    v-model="body.hours"
                    :format="'HH:mm'"
                    placeholder="Hours"
                    data-vv-as="Hours"
                  ></vue-timepicker>

                  <small
                    class="form-text text-danger"
                    v-show="errors.has('hours')"
                  >{{ errors.first("hours") }}</small>
                </div>

                <div class="col-sm-12 col-lg-12 mt-3">
                  <div class="row">
                    <div class="col-sm-12">
                      <h6 class="text-bold text-opacity-pale">Project</h6>
                    </div>
                    <div class="col-sm-12 col-lg-12">
                      <multiselect
                        track-by="id"
                        label="name"
                        class="no-caret-select"
                        v-validate="'required'"
                        name="project"
                        v-model="body.project"
                        @input="fetchTasks"
                        :options="projects"
                        :show-labels="false"
                      >
                        <template slot="caret">
                          <div>
                            <div class="multiselect__select">
                              <i class="zmdi zmdi-chevron-down pt-1" style="font-size:20px"></i>
                            </div>
                          </div>
                        </template>
                      </multiselect>
                      <small
                        class="form-text text-danger"
                        v-show="errors.has('project_id')"
                      >{{ errors.first("project_id") }}</small>
                      <small
                        class="form-text text-danger"
                        v-show="errors.has('project')"
                      >{{ errors.first("project") }}</small>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-lg-12 mt-3">
                  <div class="row">
                    <div class="col-sm-12">
                      <h6 class="text-bold text-opacity-pale">Task</h6>
                      <div class="text-bold text-opacity-pale">
                        <router-link
                          style="color:#3f92cc !important; text-decoration:none !important"
                          :to="{
                            name: 'tasks-create',
                            query: { from: 'time', project_id : body.project && body.project.id},
                          }"
                        >
                          <h6 class="h7">
                            <span class="pl-2 pr-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill="#3f92cc"
                                  d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 4c-.552 0-1 .448-1 1v2H5c-.552 0-1 .448-1 1s.448 1 1 1h2v2c0 .552.448 1 1 1s1-.448 1-1V9h2c.552 0 1-.448 1-1s-.448-1-1-1H9V5c0-.552-.448-1-1-1z"
                                ></path>
                              </svg>
                            </span>
                            Create Task
                          </h6>
                        </router-link>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-12">
                      <multiselect
                        track-by="id"
                        label="name"
                        class="no-caret-select"
                        name="task"
                        :disabled="fetchingTasks"
                        @input="body.project = body.task && body.task.project_id ? projects.find(s => s.id == body.task.project_id) : {}"
                        v-model="body.task"
                        :options="tasks"
                        :show-labels="false"
                      >
                        <template slot="caret">
                          <div>
                            <div class="multiselect__select">
                              <i class="zmdi zmdi-chevron-down pt-1" style="font-size:20px"></i>
                            </div>
                          </div>
                        </template>
                      </multiselect>
                      <small
                        class="form-text text-danger"
                        v-show="errors.has('task_id')"
                      >{{ errors.first("task_id") }}</small>
                      <small
                        class="form-text text-danger"
                        v-show="errors.has('task')"
                      >{{ errors.first("task") }}</small>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-lg-12 mt-3">
                  <div class="row">
                    <div class="col-sm-12">
                      <h6 class="text-bold text-opacity-pale">Completed By</h6>
                    </div>
                    <div class="col-sm-12 col-lg-12">
                      <multiselect
                        track-by="id"
                        label="name"
                        class="no-caret-select"
                        name="employee"
                        v-model="body.employee"
                        :options="employees"
                        :show-labels="false"
                      >
                        <template slot="caret">
                          <div>
                            <div class="multiselect__select">
                              <i class="zmdi zmdi-chevron-down pt-1" style="font-size:20px"></i>
                            </div>
                          </div>
                        </template>
                      </multiselect>
                      <small
                        class="form-text text-danger"
                        v-show="errors.has('employee_id')"
                      >{{ errors.first("employee_id") }}</small>
                      <small
                        class="form-text text-danger"
                        v-show="errors.has('employee')"
                      >{{ errors.first("employee") }}</small>
                    </div>
                  </div>
                </div>

                <div class="co-sm-12 col-lg-12 mt-3">
                  <h6 class="text-bold text-opacity-pale">Additional notes</h6>
                  
                    <quill-editor
          ref="myQuillEditor"
          class="mt-1"
          v-model="body.note"
           @ready="_html(body.note)"
           placeholder="Additional Note"
        />
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('note')"
                    >{{ errors.first("note") }}</small
                  >
                </div>
                <div class="co-sm-12 col-lg-12 mt-3">
                  <input
                    style="margin-left: 10px"
                    name="is_billable"
                    id="isbillable"
                    type="checkbox"
                    v-model="body.is_billable"
                  >
                  <label for="isbillable" class="pl-2">
                    <h6>Is Billable</h6>
                  </label>
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('is_billable')"
                  >{{ errors.first("is_billable") }}</small>
                </div>
              </div>
            </form>
          </div>
        </transition>
      </div>
      <template v-slot:modal-footer>
        <div v-if="!timerRunning">
          <dashboard-button @submit="save" :loading="loading" :text="timeLog && timeLog.id ? 'Update' : 'Create'"></dashboard-button>
        </div>
        <div v-else>
          <button class="btn btn-primary" @click="hideTimer">Minimize</button>
        </div>
      </template>
    </b-modal>


    <div
      class="timer m-5 p-2 pt-3 pb-3 pr-3 pl-3 d-flex flex-row align-items-center"
      v-if="isHidden && !cancelled"
      @click="unhideTimer()"
    >
      <div class="timer-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#0a5685"
        >
          <path
            d="M13 12l-.688-4h-.609l-.703 4c-.596.347-1 .984-1 1.723 0 1.104.896 2 2 2s2-.896 2-2c0-.739-.404-1.376-1-1.723zm-1-8c-5.522 0-10 4.477-10 10s4.478 10 10 10 10-4.477 10-10-4.478-10-10-10zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm-2-19.819v-2.181h4v2.181c-1.438-.243-2.592-.238-4 0zm9.179 2.226l1.407-1.407 1.414 1.414-1.321 1.321c-.462-.484-.964-.926-1.5-1.328zm-12.679 9.593c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zm12 0c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zm-6 6c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zm-4-2c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zm8 0c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zm-8-9c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zm8 0c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5z"
          ></path>
        </svg>
      </div>
      <div class="pl-3">
        <small class="text-opacity">Timer for Task -</small>
        <h6 class="h7 text-bolder text-opacity-pale pt-1" style="font-size:24px !important">
          <span id="hour">{{ hourOut }}</span> :
          <span id="min">{{ minOut }}</span> :
          <span id="sec">{{ secOut }}</span>
          <!-- <div id="milisec" style="width:200px">{{miliSecOut}}</div> -->
        </h6>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dfs {
  li {
    border: 1px solid white;
    &:hover {
      background: white !important;
      border: 1px solid #0a5685;
    }
  }
}
.bbt#resume-btn:hover {
  background: #639100 !important;
}
.bbt#reset-btn:hover {
  background: #a22a2a !important;
}
.bbt:hover {
  background: #0a5685 !important;
  color: white !important;
}
.rrr {
  li {
    &:not(:last-child) {
      h6 {
        &::after {
          content: ":";
          padding-left: 20px;
          position: absolute;
          opacity: 0.5;
        }
      }
    }
  }
}
.modal-dialog {
  display: none;
  max-width: 400px !important;
}
.timer-countdown {
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #2c3f58;
}
.animation {
}
.stopwatch {
  width: 158px;
  height: 36px;
  border-radius: 4px;
  background-color: #d7e1ea;
  border: 0px !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #778699;

  svg {
    g {
      &#time-stopwatch {
        stroke: #778699;
      }
    }
  }

  &.stop-green {
    background-color: #639100;
    color: white;

    svg {
      g {
        &#time-stopwatch {
          stroke: #fff;
        }
      }
    }
  }

  &.stop-red {
    &:hover {
      background-color: #a22a2a;
      color: white;

      svg {
        g {
          &#time-stopwatch {
            stroke: #fff;
          }
        }
      }
    }
  }
}

div#date_span {
  span {
    position: absolute;
    right: 25px;
    bottom: 7px;
    color: #778699 !important;
  }
}
.timer {
  position: fixed;
  width: 250px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 7px 30px 0 rgba(217, 217, 217, 0.36);
  bottom: 0;
  right: 0;
  z-index: 100000;
  border: 2px solid #f7f7f7;
  cursor: pointer;

  .timer-icon {
    height: 40px;
    background: #daf1ff;
    width: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background: #0a5685;
    small,
    h6 {
      color: white !important;
    }
  }
}
</style>

<script>
import DashboardButton from "@/components/Buttons/Dashboard";
import VueTimepicker from "vue2-timepicker";
import moment from "moment";
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";
import "vue2-timepicker/dist/VueTimepicker.css";
import { toLocal, toUTC } from "@/helpers/date";
import { optional } from "@/helpers/random";

export default {
  components: { Datetime, DashboardButton, VueTimepicker },
  // props: ["showLogTime", "singleTask", "timeLog"],
  props: {
    showLogTime: {
      required: false
    },
    singleTask: {
      required: false
    },
    timeLog: {
      required: false
    },
    xmiliSecOut: {
      default: "00",
      required: false
    },
    xsecOut: {
      default: "00",
      required: false
    },
    xminOut: {
      default: "00",
      required: false
    },
    xhourOut: {
      default: "00",
      required: false
    },
    data: {
      required: false
    }
  },
  data() {
    return {
      loaded: false,
      cancelled: false,
      loadingDetails: false,
      loading: false,
      timerRan: false,
      showLogForm: true,
      timerRunning: false,
      timerStopped: false,
      timerCompleted: false,
      body: {
        is_billable: true,
        task: { id: null },
        project: { id: null },
        employee: { id: null }
      },
      log_type:"",
      task: {},
      x: 0,
      timeout: null,
      startstop: 0,
      timerState: "Start",
      milisec: 0,
      sec: 0 /* holds incrementing value */,
      min: 0,
      hour: 0,
      /* Contains and outputs returned value of  function checkTime */
      miliSecOut: "00",
      secOut: "00",
      minOut: "00",
      hourOut: "00",
      isHidden: false,
 
      renderComponent: true,
      fetchingTasks : false
    };
  },
  methods: {
    moment,
    toLocal,
    toUTC,
    setTimeState() {
      this.$emit("trigger");
    },
    close() {
      this.isHidden = false;
      this.cancelled = true;
      window.sessionStorage.setItem("cancelled", 1);
      this.reset();
      this.startstop = 0;
      this.hideTimer();
      this.stop();
      this.forceRerender();

      window.document.title = window.sessionStorage.getItem("page_title");
      this.$emit("cancelTimeLog");
    },
    hideTimer() {
      this.isHidden = true;
      window.sessionStorage.setItem("timeLogHidden", this.isHidden);
      window.sessionStorage.setItem(
        "prevState",
        JSON.stringify({
          loaded: this.loaded,
          loadingDetails: this.loadingDetails,
          loading: this.loading,
          timerRan: this.timerRan,
          showLogForm: this.showLogForm,
          timerRunning: this.timerRunning,
          timerStopped: this.timerStopped,
          body: this.body,
          task: this.task,
          x: this.x,
          startstop: this.startstop,
          timerState: this.timerState,
          milisec: this.milisec,
          sec: this.sec,
          min: this.min,
          hour: this.hour,
          miliSecOut: this.miliSecOut,
          secOut: this.secOut,
          minOut: this.minOut,
          hourOut: this.hourOut,
          isHidden: this.isHidden,
          log_type: this.log_type
        })
      );
    },
    unhideTimer() {
      this.isHidden = false;
      this.showLogTime = true;

      window.sessionStorage.setItem("timeLogHidden", this.isHidden);
    },
    startStopTimer() {
      this.timerRan = true;
      this.log_type = 'timer';
      this.startstop = this.startstop + 1;

      if (this.startstop === 1) {
        if (this.body.start_time) {
          this.body.start_time = moment()
            .subtract("hours", parseInt(this.hourOut))
            .subtract("minutes", parseInt(this.minOut))
            .subtract("seconds", parseInt(this.secOut))
            .format();
        }
        this.timerRunning = true;
        this.body.start_time = this.body.start_time || moment().format();
        this.start();
        this.timerState = "Pause";
        this.timerStopped = false;
        this.timerCompleted = false;
      } else if (this.startstop === 2) {
        this.timerState = "Completed";
        this.startstop = 0;
        this.timerStopped = true;
        this.body.hours = this.minOut / 60 + parseInt(this.hourOut);
        this.body.end_time = moment().format();
        this.stop();
        this.timerRunning = false;
      }
    },
    start() {
      window.sessionStorage.removeItem("cancelled");
      this.x = setInterval(this.timer, 10);
      this.timeout = setTimeout(function() {
        clearInterval(this.timer);
      }, 10);
    },
    stop() {
      clearInterval(this.x);
      clearTimeout(this.timeout);
      this.data = {};
      window.sessionStorage.removeItem("timeLogHidden");
      window.sessionStorage.removeItem("prevState");
    },
    timer() {
      if (window.sessionStorage.getItem("cancelled") == 1 || !this.timerRunning)
        return;
      let startTime = moment(this.body.start_time).format();
      let timeElapsed = moment.duration(moment().diff(startTime));

      this.milisec = timeElapsed.asMilliseconds() % 100;
      this.sec = parseInt(timeElapsed.asSeconds() % 60);
      this.min = parseInt(timeElapsed.asMinutes() % 60);
      this.hour = parseInt(timeElapsed.asHours() % 60);

      /* Main Timer */
      this.miliSecOut = this.checkTime(this.milisec);
      this.secOut = this.checkTime(this.sec);
      this.minOut = this.checkTime(this.min);
      this.hourOut = this.checkTime(this.hour);

      if (!window.document.hasFocus()) {
        window.document.title = `${this.hourOut}:${this.minOut}:${
          this.secOut
        } | ${window.sessionStorage.getItem("page_title")}`;
      } else {
        window.document.title = window.sessionStorage.getItem("page_title");
      }
    },
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    reset() {
      /*Reset*/
      this.body.start_time = null;
      this.milisec = 0;
      this.sec = 0;
      this.min = 0;
      this.hour = 0;

      this.miliSecOut = "00";
      this.hourOut = "00";
      this.secOut = "00";
      this.minOut = "00";
    },
    save() {
      this.errors.clear();
      this.$validator.validateAll().then(valid => {
        if (valid) {
          let errors = 0;

          if (
            this.body.start_time &&
            this.body.end_time &&
            moment(this.body.end_time).diff(this.body.start_time) < 0
          ) {
            this.errors.add({
              field: "end_time",
              msg: "End time cannot be less than start time"
            });

            errors++;
          }

          if (moment(this.body.start_date).diff(moment(), "seconds") > 0) {
            this.errors.add({
              field: "start_date",
              msg: "Date cannot be in the future"
            });
            errors++;
          }

          if (errors === 0) {
            this.submit();
          }
        }
      });
    },
    calculateHours(start_date, end_date) {
      let now = moment(start_date); //start date
      let end = moment(end_date); // another date
      let duration = moment.duration(end.diff(now));
      let minutes = duration.asMinutes();

      return parseFloat(minutes/60).toFixed(2)
    },
    submit() {
  
      this.loading = true;
      
      if (this.timerRunning) {
        return this.$toast.error(
          "Timer is still running. Please stop time first!",
          {
            position: "top-right",
            duration: 3000
          }
        );
      }
      let hours = this.body.hours;
    
      if(!hours) {
        hours = this.calculateHours(this.body.start_time, this.body.end_time);

      }
      // if the time spent were selected from dropdown
      else if(hours && isNaN(hours)) {
        let splitTime = hours.split(':');
        let hour = parseInt(splitTime[0] || 0);
        let minutes = parseInt(splitTime[1] || 0);
        hours = parseFloat(hour + (minutes ? minutes/60 : 0)).toFixed(2);
        
      }
      
      // if hours was entered manually as number
      let data = {
        id: this.body.id,
        is_billable: this.body.is_billable ? 1 : 0,
        project_id: this.body.project.id,
        task_id: this.body.task.id,
        employee_id: this.body.employee.id,
        hours: hours,
        note: this.body.note,
        start_time: toUTC(this.body.start_time || this.body.start_date).format("YYYY-MM-DD HH:mm").toString()
      };
      data.end_time = toUTC(this.body.end_time ? moment(this.body.end_time) : moment(data.start_time).add(data.hours, "hours")).format("YYYY-MM-DD HH:mm").toString()

      this.$store
        .dispatch("tasks/createUpdateTimeLog", data)
        .then(async s => {
          // clear form
          this.body = {
            hours: null,
            note: null,
            start_date: null,
            start_time: null,
            end_time: null,
            is_billable: true
          };
          this.timerRan = false;

          // reset timer
          this.reset();
          this.$toast.success(data.id ? `Timelog has been updated` : `Time Successfully Logged!`, {
            position: "top-right",
            duration: 3000
          });

          // re-fetch time logs
          this.$emit("timeLogSaved");
          if (
            (this.$route.params.id || this.$route.params.tid) &&
            this.$route.name != "projects-show-general"
          ) {
            let id = this.$route.params.tid || this.$route.params.id;
            await Promise.resolve([
              this.$store.dispatch("tasks/getSingle", id),
              this.$store.dispatch("tasks/getTimeLogs", id)
            ]);
          }
          this.$refs["timeLogModal"].hide();
        })
        .catch(() => {
          this.$toast.error(`Failed to ${data.id ? 'edit' : ''} log time!`, {
            position: "top-right",
            duration: 3000
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatData() {
      this.body.hours = "01:00";
      // TODO: figure out why a day needs to be added to get today's date
      this.body.start_date = moment().add(1, 'day').format("YYYY-MM-DD");
      
      if (optional(this.timeLog, 'id')) {
        Object.assign(this.body, this.timeLog);
        delete this.body.start_time;
        delete this.body.end_time;

        this.body.start_date = toLocal(this.timeLog.start_time).format("YYYY-MM-DD").toString();

        this.body.hours = this.body.hours
        ? (`${this.body.hours < 10 ? `0${this.body.hours}` : this.body.hours}:00`)
        : toLocal(this.timeLog.end_time)
          .format("HH:mm")
          .toString();
        Object.assign(this.task, optional(this.timeLog, 'task.data'));
       
      } else if (this.singleTask) {
        Object.assign(this.task, this.singleTask);
      } else {
        Object.assign(this.task, this.currentTask);
      }

      Object.assign(this.body.task, {
        id: this.task.id,
        name: this.task.name
      });

      // if (optional(this.task, 'project')) {
      //   Object.assign(this.body.project, {
      //     id: this.task.project.id,
      //     name: this.task.project.name
      //   });
      // }
      if(this.task.project_id) {
        let project = this.$route.params.id ? this.projects.find(s => s.id == this.$route.params.id) : [];
        const {id, name} =project;
        Object.assign(this.body.project, { id, name });
      }

      if (optional(this.$auth.user(), 'employee.data.user.data')) {
        Object.assign(this.body.employee, {
          id: this.$auth.user().employee.data.id,
          name: `${this.$auth.user().employee.data.user.data.first_name} ${
            this.$auth.user().employee.data.user.data.last_name
          }`
        });
      } else if (optional(this.task, 'employee.data.user.data')) {
        Object.assign(this.body.employee, {
          id: this.task.employee.data.id,
          name: `${this.task.employee.data.user.data.first_name} ${
            this.task.employee.data.user.data.last_name
          }`
        });
      } else {
        let emp = this.employees.find(s => s.user_id == this.$auth.user().id);
        if (emp) {
          this.body.employee = {
            id: emp.id,
            name: `${this.$auth.user().first_name} ${
              this.$auth.user().last_name
            }`
          };
        }
      }
    
      this.loaded = true;
    },
    restorePrevState(data) {
      this.loaded = data.loaded;
      this.loadingDetails = data.loadingDetails;
      this.loading = data.loading;
      this.timerRan = data.timerRan;
      this.showLogForm = data.showLogForm;
      this.timerRunning = data.timerRunning;
      this.timerStopped = data.timerStopped;
      this.body = data.body;
      this.task = data.task;
      this.x = data.x;
      this.startstop = data.startstop;
      this.timerState = data.timerState;
      this.milisec = data.milisec;
      this.sec = data.sec;
      this.min = data.min;
      this.hour = data.hour;
      this.miliSecOut = data.miliSecOut;
      this.secOut = data.secOut;
      this.minOut = data.minOut;
      this.hourOut = data.hourOut;
      this.isHidden = data.isHidden;
      this.log_type = data.log_type;

      this.start();
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    fetchTasks(project) {
      this.fetchingTasks = true; 
      this.errors.add({
        field :'task_id',
        'msg':'Please wait while fetching project tasks...'
      })
      this.$store.dispatch("tasks/index", `project_id=${project.id}`).then(() => {
        this.fetchingTasks = false; 
        this.errors.clear();
      });
    }
  },
  computed: {
    
    currentTask() {
      return this.$store.getters["tasks/task"];
    },
    projects() {
      return this.$store.getters["projects/projects_simple"];
    },
    tasks() {
      return this.$store.getters["tasks/tasks_simple"];
    },
    employees() {
      return this.$store.getters["employees/employees_simple"];
    },
    isEmployee() {
      return !!this.$auth.user().employee;
    }
  },
  created() {
    
    if(this.timeLog && this.timeLog.id) {

      this.log_type = 'manually';
      this.isHidden = false;
      return this.formatData();
    }
  
    let data = JSON.parse(window.sessionStorage.getItem("prevState")) || this.data;

    if (data) {
      data.isHidden = false;
      data.showLogTime = true;
      data.loaded = true;
      this.$emit("duplicateTimer");
      this.restorePrevState(data); //bring timer to focus if exists already
    } else {
      Promise.all([
        this.projects.length || this.$store.dispatch("projects/index"),
        this.employees.length || this.$store.dispatch("employees/index")
      ]).finally(() => {
        this.formatData();
    
      });
    }
  },
  watch: {
    $route(to, from) {
      let data = JSON.parse(window.sessionStorage.getItem("prevState"));
      data && this.restorePrevState(data);
 
    },
   
  }
};
</script>
