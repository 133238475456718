export default {
    SET_INVOICES(state, array) {
        state.invoices = array;
    },
    SET_INVOICE_ITEMS(state, array) {
        state.invoice_items = array;
    },
    SET_INVOICE_ITEMS_EXPENSES(state, array) {
        state.invoice_items_expenses = array;
    },
    SET_INVOICE_ITEMS_TIMELOGS(state, array) {
        state.invoice_items_timelogs = array;
    },
    SET_INVOICE_ITEMS_MILESTONES(state, array) {
        state.invoice_items_milestones = array;
    },
    SET_INVOICE(state, array) {
        state.invoice = array;
    },
    SET_INVOICE_METRICS(state, array) {
        state.invoice_metrics = array;
    },
}
