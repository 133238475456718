<template>
  <div class="mb-5 pl-3 pt-4" :class="{blur:!loaded}">
    <invoice-modal
      :timelogs="project && project.timeLogs && project.timeLogs.data ? project.timeLogs.data  : ''"
      :expenses="project && project.expenses && project.expenses.data ? project.expenses.data : ''"
      :customer="customer"
      :visible="showInvoiceModal"
      @cancel="cancelGenerateInvoice"
    ></invoice-modal>
    <div class="rt">
      <div class="card w-60 pl-3 pt-3 card-p-active">
        <h6 class="text-bold text-opacity-pale">Expected Revenue</h6>
        <div class="pt-1 pb-3">
          <h6
            class="h7 pt-1"
          >{{customer.currency && customer.currency.symbol}}{{finance.metrics && finance.metrics.projected_revenue ? parseFloat(finance.metrics.projected_revenue ).toFixed(2) : '0'}}</h6>
        </div>
      </div>

      <div class="card w-60 pl-3 pt-3">
        <h6 class="text-bold text-opacity-pale">Customer Revenue</h6>
        <div class="pt-1 pb-3">
          <h6
            class="h7 pt-1"
          >{{customer.currency && customer.currency.symbol}}{{finance.metrics && finance.metrics.total_revenue ? parseFloat(finance.metrics.total_revenue).toFixed(2) : '0'}}</h6>
        </div>
      </div>

      <div class="card w-60 pl-3 pt-3">
        <h6 class="text-bold text-opacity-pale">Total project costs</h6>
        <div class="pt-1 pb-3">
          <h6
            class="h7 pt-1"
          >{{customer.currency && customer.currency.symbol}}{{finance.metrics && finance.metrics.total_cost ? parseFloat(finance.metrics.total_cost).toFixed(2) : '0'}}</h6>
        </div>
      </div>

      <div class="card w-60 pl-3 pt-3">
        <h6 class="text-bold text-opacity-pale">Total project expenses</h6>
        <div class="pt-1 pb-3">
          <h6
            class="h7 pt-1"
          >{{customer.currency && customer.currency.symbol}}{{finance.metrics && finance.metrics.total_expenses ? parseFloat(finance.metrics.total_expenses).toFixed(2) :'0'}}</h6>
        </div>
      </div>
      <div class="card w-60 pl-3 pt-3">
        <h6 class="text-bold text-opacity-pale">Project P/L</h6>
        <div class="pt-1 pb-3">
          <h6
            class="h7 pt-1"
          >{{customer.currency && customer.currency.symbol}}{{finance.metrics && finance.metrics.total_profit ? parseFloat(finance.metrics.total_profit).toFixed(2) : '0'}}</h6>
        </div>
      </div>
    </div>

    <!-- new row -->
    <div class="mt-4 row">
      <div class="col-sm-12 col-lg-3">
        <div class="row">
          <div class="col-sm-12">
            <div class="card w-70">
              <div class="card-header no-right-padding-card">
                Customer Details
                <span class="btn-icon-sm float-right">
                  <svg
                    width="20"
                    height="20"
                    fill="#72809D"
                    viewBox="0 0 30 30"
                    xmlns="http://www.w3.org/2000/svg"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                  >
                    <path
                      d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
                    ></path>
                  </svg>
                </span>
                <router-link :to="{name: 'projects-edit'}">
                  <span class="btn-icon-sm float-right">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#72809D"
                      viewBox="0 0 30 30"
                    >
                      <path
                        d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"
                      ></path>
                    </svg>
                  </span>
                </router-link>
              </div>
              <div class="card-content p-3">
                <div class="row">
                  <div class="col-sm-6">Customer Name:</div>
                  <div class="col-sm-6 float-right text-right">{{customer.company_name}}</div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-6">Industry:</div>
                  <div
                    class="col-sm-6 float-right text-right"
                  >{{customer.industry && customer.industry.name}}</div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-6">Phone:</div>
                  <div
                    class="col-sm-6 float-right text-right"
                  >{{customer.country_code && customer.phone_number ? customer.full_phone_number : ''}}</div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-6">Address:</div>
                  <div
                    class="col-sm-6 float-right text-right"
                    v-if="customer_address"
                  >{{customer_address}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 mt-4">
            <div class="card w-70">
              <div class="card-header">
                REVENUE
                <a class="float-right" style="text-transform:none !important" href="#">
                  Go to Invoices
                  <i style="font-size:15px" class="zmdi pl-2 zmdi-chevron-right"></i>
                </a>
              </div>
              <div class="card-content p-3">
                <div class="row card-border-between">
                  <div class="col-sm-4 list">
                    <h5>{{project.currency && project.currency.symbol}}{{invoicesDue}}</h5>
                    <h6 style="font-size:11px !important;" class="text-opacity">Due</h6>
                  </div>
                  <div class="col-sm-4 list">
                    <h5
                      class="text-lightblue"
                    >{{project.currency && project.currency.symbol}}{{invoicesBilled}}</h5>
                    <h6 style="font-size:11px !important;" class="text-opacity">Billed for</h6>
                  </div>
                  <div class="col-sm-4 list">
                    <h5
                      class="text-lemon"
                    >{{project.currency && project.currency.symbol}}{{invoicesPaid}}</h5>
                    <h6 style="font-size:11px !important;" class="text-opacity">Paid for</h6>
                  </div>
                </div>
                <div class="row card-border-top">
                  <div :key="index" v-for="(milestone,index) of milestones" class="col-sm-12 list">
                    <div class="row mt-3">
                      <div class="col-sm-6">
                        <h6 class="h8">{{milestone.name}}</h6>
                        <h6 class="text-opacity h10">{{milestone.description}}</h6>
                      </div>
                      <div class="col-sm-6 float-right text-right">{{milestone.status.name}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-9">
        <div class="row">
          <div class="col-sm-12">
            <div class="card w-70 full-width">
              <div class="card-header">
                REVENUE BREAKDOWN
                <span
                  @click="showInvoiceModal = true"
                  class="btn-text float-right"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M8 24l2.674-9h-9.674l16-15-2.674 9h8.674l-15 15zm-1.586-11h6.912l-1.326 4 5.739-6h-6.065l1.304-4-6.564 6z"
                      ></path>
                    </svg>
                  </span>
                  Generate Invoice
                </span>
              </div>
              <div class="card-stats">
                <div class="row">
                  <div class="col-sm-12 col-lg-6 pl-5 pr-4 pt-3 pb-3">
                    <ul class="card-stats__list">
                      <li class="w--25">
                        <span>
                          <small class="text-opacity-pale">Total Entries</small>
                        </span>
                        <span>
                          <h6>{{invoicesTotal.meta && invoicesTotal.meta.meta && invoicesTotal.meta.meta.pagination.total ? invoicesTotal.meta.meta.pagination.total : 0}} entries</h6>
                        </span>
                      </li>
                      <li class="w--25">
                        <span>
                          <small class="text-opacity-pale">Total Amount</small>
                        </span>
                        <span>
                          <h6>{{project && project.currency && project.currency.symbol}} {{invoicesTotal.meta && invoicesTotal.meta.meta && invoicesTotal.meta.meta.totalAmount ? invoicesTotal.meta.meta.totalAmount : 0}}</h6>
                        </span>
                      </li>
                      <li class="w--25">
                        <span>
                          <small class="text-opacity-pale">Billed For</small>
                        </span>
                        <span>
                          <h6
                            class="text-lightblue"
                          >{{project && project.currency && project.currency.symbol}} {{invoicesTotal.meta && invoicesTotal.meta.meta && invoicesTotal.meta.meta.totalBilled ? invoicesTotal.meta.meta.totalBilled : 0}}</h6>
                        </span>
                      </li>
                      <li class="w--25">
                        <span>
                          <small class="text-opacity-pale">Paid For</small>
                        </span>
                        <span>
                          <h6
                            class="text-lemon"
                          >{{project && project.currency && project.currency.symbol}} {{invoicesTotal.meta && invoicesTotal.meta.meta && invoicesTotal.meta.meta.totalPaid ? invoicesTotal.meta.meta.totalPaid : 0}}</h6>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div class="col-sm-12 col-lg-6 pl-5 pt-1">
                    <div>
                      <small class="text-opacity text-right">Filters</small>
                      <div>
                        <form class="d-form">
                          <div class="d-form__filters">
                            <div class="li date-range">
                              <vc-date-picker
                                :masks="{ input: ['YYYY-MM-DD']}"
                                :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important;width:150px`,placeholder: `Start date`}"
                                name="start_date"
                                ref="startFrom"
                                :value="null"
                                data-vv-as="Project Start date"
                              />
                            </div>
                            <div class="li date-range">
                              <vc-date-picker
                                :masks="{ input: ['YYYY-MM-DD']}"
                                :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important; width:150px`,placeholder: `End date`}"
                                name="start_date"
                                ref="startFrom"
                                :value="null"
                                data-vv-as="Project Start date"
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-content">
                <revenue-break-down
                  :id="this.$route.params.id"
                  :moreParams="tableFilters.invoicesParams"
                  @getInvoiceTotal="setInvoiceMeta"
                  :project_type="project.billing_type && project.billing_type.name"
                ></revenue-break-down>
              </div>
            </div>
          </div>
          <div class="col-sm-12 mt-3">
            <div class="card w-70 full-width">
              <div class="card-header no-right-padding-card">
                HOURS LOGGED
                <span class="btn-icon-sm float-right">
                  <svg
                    width="20"
                    height="20"
                    fill="#72809D"
                    viewBox="0 0 30 30"
                    xmlns="http://www.w3.org/2000/svg"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                  >
                    <path
                      d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
                    ></path>
                  </svg>
                </span>
              </div>
              <div class="card-stats">
                <div class="row">
                  <div class="col-sm-12 col-lg-6 pl-5 pr-4 pt-3 pb-3">
                    <ul class="card-stats__list">
                      <li class="w--25">
                        <span>
                          <small class="text-opacity-pale">Total Entries</small>
                        </span>
                        <span>
                          <h6>{{hoursLoggedTotal && hoursLoggedTotal.meta && hoursLoggedTotal.meta.meta && hoursLoggedTotal.meta.meta.pagination.total ? hoursLoggedTotal.meta.meta.pagination.total : 0}} entries</h6>
                        </span>
                      </li>
                      <li class="w--25">
                        <span>
                          <small class="text-opacity-pale">Hours Logged</small>
                        </span>
                        <span>
                          <h6>{{hoursLoggedTotal && hoursLoggedTotal.meta && hoursLoggedTotal.meta.meta && hoursLoggedTotal.meta.meta.totalHours ? hoursLoggedTotal.meta.meta.totalHours : 0}} hrs</h6>
                        </span>
                      </li>
                      <li class="w--25">
                        <span>
                          <small class="text-opacity-pale">Total Cost</small>
                        </span>
                        <span>
                          <h6>{{customer && customer.currency && customer.currency.symbol}} {{ hoursLoggedTotal && hoursLoggedTotal.meta && hoursLoggedTotal.meta.meta && hoursLoggedTotal.meta.meta.totalCost ? hoursLoggedTotal.meta.meta.totalCost : 0}}</h6>
                        </span>
                      </li>
                      <li class="w--25">
                        <span>
                          <small class="text-opacity-pale">Total Revenue</small>
                        </span>
                        <span>
                          <h6>{{customer && customer.currency && customer.currency.symbol}} {{hoursLoggedTotal && hoursLoggedTotal.meta && hoursLoggedTotal.meta.meta && hoursLoggedTotal.meta.meta.totalBilledCost ? hoursLoggedTotal.meta.meta.totalBilledCost : 0}}</h6>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div class="col-sm--12 col-lg-6 pl-5 pt-1">
                    <div>
                      <small class="text-opacity">Filters</small>
                      <div>
                        <form class="d-form">
                          <div class="d-form__filters">
                            <div class="li date-range">
                              <vc-date-picker
                                :masks="{ input: ['YYYY-MM-DD']}"
                                :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: `Start date`}"
                                name="start_date"
                                ref="startFrom"
                                data-vv-as="Project Start date"
                              />
                            </div>
                            <div class="li date-range">
                              <vc-date-picker
                                :masks="{ input: ['YYYY-MM-DD']}"
                                :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: `End date`}"
                                name="start_date"
                                ref="startFrom"
                                data-vv-as="Project Start date"
                              />
                            </div>
                            <div class="li">
                              <multiselect
                                track-by="id"
                                return="id"
                                label="name"
                                class="no-inline-select"
                                v-validate="'required'"
                                name="project_status_id"
                                @input="setExpenseFilter(tableFilters.hoursLoggedParams)"
                                v-model="tableFilters.hoursLoggedParams.employee"
                                :options="collaborators"
                                :show-labels="false"
                                placeholder="Employee"
                                data-vv-as="Project Status"
                              >
                                <template
                                  slot="option"
                                  slot-scope="{option}"
                                >{{ option.first_name }} {{ option.last_name }}</template>
                                <template
                                  slot="singleLabel"
                                  slot-scope="{option}"
                                >{{ option.first_name }} {{ option.last_name }}</template>
                              </multiselect>
                            </div>
                            <div class="li pt-2 pl-2">
                              <input
                                v-model="tableFilters.hoursLoggedParams.is_billable"
                                @change="setExpenseFilter(tableFilters.hoursLoggedParams)"
                                type="checkbox"
                                id="billable"
                              >
                              <label for="billable" class="pl-2">
                                <h6 class="h7">Is Billable?</h6>
                              </label>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-content">
                <hourly-table
                  :moreParams="tableFilters.hoursLoggedParams"
                  :api="hoursLoggedApi"
                  @getHoursLogged="setTimeLoggedMeta"
                ></hourly-table>
              </div>
            </div>
          </div>
          <div class="col-sm-12 mt-3">
            <div class="card w-70 full-width">
              <div class="card-header no-right-padding-card">
                PROJECT MILESTONES
                <span class="btn-icon-sm float-right">
                  <svg
                    width="20"
                    height="20"
                    fill="#72809D"
                    viewBox="0 0 30 30"
                    xmlns="http://www.w3.org/2000/svg"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                  >
                    <path
                      d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
                    ></path>
                  </svg>
                </span>
              </div>
              <div class="card-stats">
                <div class="row">
                  <div class="col-sm-12 col-lg-5 pl-5 pr-4 pt-3 pb-3">
                    <ul class="card-stats__list">
                      <li class="w--33">
                        <span>
                          <small class="text-opacity-pale">Total Entries</small>
                        </span>
                        <span>
                          <h6>{{milesStoneTotal.meta && milesStoneTotal.meta.meta && milesStoneTotal.meta.meta.pagination.total ? milesStoneTotal.meta.meta.pagination.total : 0}} entries</h6>
                        </span>
                      </li>
                      <li class="w--33">
                        <span>
                          <small class="text-opacity-pale">Total Hours Logged</small>
                        </span>
                        <span>
                          <h6>{{milesStoneTotal.meta && milesStoneTotal.meta.meta && milesStoneTotal.meta.meta.totalHours ? milesStoneTotal.meta.meta.totalHours : 0}} hrs</h6>
                        </span>
                      </li>
                      <li class="w--33">
                        <span>
                          <small class="text-opacity-pale">Total Amount</small>
                        </span>
                        <span>
                          <h6>{{customer && customer.currency && customer.currency.symbol}} {{milesStoneTotal.meta && milesStoneTotal.meta.meta && milesStoneTotal.meta.meta.totalCost ? milesStoneTotal.meta.meta.totalCost : 0}}</h6>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div class="col-sm--12 col-lg-7 pl-5 pt-1">
                    <div>
                      <small class="text-opacity">Filters</small>
                      <div>
                        <form class="d-form">
                          <div class="d-form__filters">
                            <div class="li date-range">
                              <vc-date-picker
                                :masks="{ input: ['YYYY-MM-DD']}"
                                :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: `Start date`}"
                                name="start_date"
                                ref="startFrom"
                                data-vv-as="Project Start date"
                              />
                            </div>
                            <div class="li date-range">
                              <vc-date-picker
                                :masks="{ input: ['YYYY-MM-DD']}"
                                :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: `End date`}"
                                name="start_date"
                                ref="startFrom"
                                data-vv-as="Project Start date"
                              />
                            </div>
                            <div class="li">
                              <multiselect
                                track-by="id"
                                return="name"
                                label="name"
                                @input="setExpenseFilter(tableFilters.milestoneParams)"
                                class="no-inline-select"
                                v-validate="'required'"
                                name="project_status_id"
                                :options="collaborators"
                                v-model="tableFilters.milestoneParams.employee"
                                :show-labels="false"
                                placeholder="Employee"
                                data-vv-as="Project Status"
                              >
                                <template
                                  slot="option"
                                  slot-scope="{option}"
                                >{{ option.first_name }} {{ option.last_name }}</template>
                                <template
                                  slot="singleLabel"
                                  slot-scope="{option}"
                                >{{ option.first_name }} {{ option.last_name }}</template>
                              </multiselect>
                            </div>
                            <div class="li pt-2 pl-2">
                              <input
                                v-model="tableFilters.milestoneParams.is_billable"
                                @change="setExpenseFilter(tableFilters.milestoneParams)"
                                type="checkbox"
                                id="billable"
                              >
                              <label for="billable" class="pl-2">
                                <h6 class="h7">Is Billable?</h6>
                              </label>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-content">
                <milestone-table
                  :api="mileStoneApi"
                  :moreParams="tableFilters.milestoneParams"
                  @getMilestoneTotal="setMileStoneMeta"
                ></milestone-table>
              </div>
            </div>
          </div>
          <div class="col-sm-12 mt-3">
            <div class="card w-70 full-width">
              <div class="card-header">
                PROJECT EXPENSES
                <span class="float-right btn-icon-sm">
                  <svg
                    width="20"
                    height="20"
                    fill="#72809D"
                    viewBox="0 0 30 30"
                    xmlns="http://www.w3.org/2000/svg"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                  >
                    <path
                      d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
                    ></path>
                  </svg>
                </span>
              </div>
              <div class="card-stats">
                <div class="row">
                  <div class="col-sm-12 col-lg-4 pl-5 pr-4 pt-3 pb-3">
                    <ul class="card-stats__list">
                      <li class="w--50">
                        <span>
                          <small class="text-opacity-pale">Total Entries</small>
                        </span>
                        <span>
                          <h6>{{expensesTotal.meta && expensesTotal.meta.meta && expensesTotal.meta.meta && expensesTotal.meta.meta.pagination.total ? expensesTotal.meta.meta.pagination.total : 0}} {{'entries'}}</h6>
                        </span>
                      </li>
                      <li class="w--50">
                        <span>
                          <small class="text-opacity-pale">Total Expenses</small>
                        </span>
                        <span>
                          <h6>{{customer && customer.currency && customer.currency.symbol}} {{expensesTotal.meta && expensesTotal.meta.meta && expensesTotal.meta.meta.totalAmount ? expensesTotal.meta.meta.totalAmount : 0}}</h6>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div class="col-sm-12 col-lg-8 pl-5 pt-1">
                    <div>
                      <small class="text-opacity">Filters</small>
                      <div>
                        <form class="d-form">
                          <div class="d-form__filters">
                            <div class="li date-range" style="max-width:100px">
                              <vc-date-picker
                                :masks="{ input: ['YYYY-MM-DD']}"
                                :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important;`,placeholder: `Start date`}"
                                name="start_date"
                                value
                                ref="startFrom"
                                v-model="tableFilters.expensesParams.start_from"
                                data-vv-as="Project Start date"
                              />
                            </div>
                            <div class="li date-range" style="max-width:100px">
                              <vc-date-picker
                                :masks="{ input: ['YYYY-MM-DD']}"
                                :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important;width:100px`,placeholder: `End date`}"
                                name="start_date"
                                ref="startFrom"
                                value
                                v-model="tableFilters.expensesParams.end_to"
                                data-vv-as="Project Start date"
                              />
                            </div>
                            <div class="li">
                              <multiselect
                                track-by="id"
                                return="id"
                                label="name"
                                @input="setExpenseFilter(tableFilters.expensesParams)"
                                class="no-inline-select"
                                v-validate="'required'"
                                name="project_status_id"
                                :options="collaborators"
                                v-model="tableFilters.expensesParams.employee"
                                :show-labels="false"
                                placeholder="Employee"
                                data-vv-as="Project Status"
                              >
                                <template
                                  slot="option"
                                  slot-scope="{option}"
                                >{{ option.first_name }} {{ option.last_name }}</template>
                                <template
                                  slot="singleLabel"
                                  slot-scope="{option}"
                                >{{ option.first_name }} {{ option.last_name }}</template>
                              </multiselect>
                            </div>
                            <div class="li pt-2 pl-2">
                              <input
                                @change="setExpenseFilter(tableFilters.expensesParams)"
                                type="checkbox"
                                id="billable"
                                v-model="tableFilters.expensesParams.is_reimbursed"
                              >
                              <label for="billable" class="pl-2">
                                <h6 class="h7">Is Reimbursable?</h6>
                              </label>
                            </div>
                            <div class="li pt-2 pl-2">
                              <input
                                @change="setExpenseFilter(tableFilters.expensesParams)"
                                type="checkbox"
                                id="billable"
                                v-model="tableFilters.expensesParams.is_billable"
                              >
                              <label for="billable" class="pl-2">
                                <h6 class="h7">Is Billable?</h6>
                              </label>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-content">
                <!-- {{Object.keys(tableFilters.expensesParams)}} -->
                <expenses-table
                  :api="api"
                  :moreParams="tableFilters.expensesParams"
                  @setExpenseFilter="setExpenseFilter"
                  @getExpensesMeta="setExpensesMeta"
                ></expenses-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end -->
  </div>
</template>

<style lang="scss" scoped>
.rt {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .card {
    flex-basis: 100%;
    // margin-right: 10px;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  @media (max-width: 576px) {
    flex-wrap: wrap !important;

    .card {
      margin-top: 10px !important;
    }
  }
}
</style>

<script>
import ExpensesTable from "@/components/Tables/Expenses/Index";
import HourlyTable from "@/components/Tables/Customers/Revenue-BreakDown/Hourly";
import MilestoneTable from "@/components/Tables/Customers/Revenue-BreakDown/Milestone";
import RevenueBreakDown from "@/components/Tables/Customers/Revenue-BreakDown/Index";
import InvoiceModal from "@/components/Modals/CustomerInvoice";
import moment from "moment";

export default {
  title: "Index | Customer Finance",
  components: {
    ExpensesTable,
    RevenueBreakDown,
    HourlyTable,
    InvoiceModal,
    MilestoneTable
  },
  data() {
    return {
      milestone_acc: false,
      showInvoiceModal: false,
      accordions: [
        { name: "project", visible: true },
        { name: "milestone", visible: false },
        { name: "tasks", visible: false },
        { name: "timetracking", visible: false },
        { name: "additional", visible: false }
      ],
      table_data: [
        { name: "expenses", meta: "" },
        { name: "time_logs", meta: "" },
        { name: "milestone", meta: "" },
        { name: "invoices", meta: "" }
      ],
      visible: false,
      tableFilters: {
        expensesParams: {
          include: "project,task,category",
          per_page: 20,
          is_reimbursed: 1,
          end_to: "",
          start_from: ""
        },
        milestoneParams: {
          include: "project,task,category",
          per_page: 20,
          is_billable: 1,
          end_to: "",
          start_from: ""
        },
        hoursLoggedParams: {
          include: "project,task,category,employee",
          per_page: 10,
          is_billable: 1,
          end_to: "",
          start_from: ""
        },
        invoicesParams: {
          include: "project,task,category,employee",
          per_page: 10,
          is_billable: 1,
          end_to: "",
          start_from: ""
        }
      },
      finance: {
        metrics: {}
      },
      loaded: false
    };
  },
  computed: {
    project() {
      return this.$store.getters["projects/project"];
    },
    customerMetrics() {
      return (
        this.customer &&
        this.customer.customer_metrics &&
        this.customer.customer_metrics
      );
    },
    customer() {
      return this.$store.getters["customers/customer"];
    },
    customer_address() {
      let cust = this.customer.billing_address;
      let address = cust && cust.address ? cust.address : null;
      if (cust) {
        let country = cust && cust.country;
        let region = cust && cust.region;
        let city = cust && cust.city;

        if (country) {
          address += address ? ", " + country.name : country.name;
        }
        if (region) {
          address += address ? ", " + region.name : region.name;
        }
        if (city) {
          address += address ? ", " + city.name : city.name;
        }
        if (cust.post_code) {
          address += address ? ", " + cust.post_code : cust.post_code;
        }
      }

      return address;
    },
    collaborators() {
      return [];
    },
    api() {
      return `${this.$baseApi}/customers/${
        this.$route.params.id
      }/expenses?include=category`;
    },
    expensesTotal() {
      return this.table_data.find(s => s.name == "expenses");
    },
    hoursLoggedTotal() {
      return this.table_data.find(s => s.name == "time_logs");
    },
    milesStoneTotal() {
      return this.table_data.find(s => s.name == "milestone");
    },
    invoicesTotal() {
      return this.table_data.find(s => s.name == "invoices");
    },
    hoursLoggedApi() {
      return `${this.$baseApi}/customers/${this.$route.params.id}/timelogs`;
    },
    mileStoneApi() {
      return `${this.$baseApi}/customers/${this.$route.params.id}/milestones`;
    },
    profitLoss() {
      let expenses = parseFloat(this.project.total_expenses);
      let revenue = parseFloat(this.project.revenue);

      return revenue - expenses;
    },
    milestones() {
      return this.$store.getters["projects/milestones"];
    },
    invoices() {
      return (this.project.invoices && this.project.invoices.data) || [];
    },
    invoicesBilled() {
      return this.invoices.reduce((t, s) => t + parseFloat(s.amount), 0);
    },
    invoicesPaid() {
      return this.invoices.reduce((t, s) => t + parseFloat(s.amount_paid), 0);
    },
    invoicesDue() {
      return this.invoices
        .filter(d => moment(d.due_date).isBefore(moment()))
        .reduce((t, s) => t + parseFloat(s.amount), 0);
    }
  },
  methods: {
    moment,
    cancelGenerateInvoice() {
      this.showInvoiceModal = false;
    },
    setExpenseFilter(payload) {
      for (let [key, value] of Object.entries(payload)) {
        if (typeof value === "object") {
          //append id to params
          payload[key + "_id"] = value.id;
          // delete this.tableFilters.expensesParams[key];
        }
        if (typeof value === "boolean") {
          payload[key] = value ? 1 : 0;
        }
        if (value === "") {
          delete payload[key];
        }
      }
    },
    toggleCollapse(index) {
      this.accordions[index].visible =
        this.accordions[index].visible === false ? true : false;
    },
    setExpensesMeta(value) {
      let expense = this.table_data.find(s => s.name == "expenses");
      expense.meta = value;
    },
    setTimeLoggedMeta(value) {
      let timeLogged = this.table_data.find(s => s.name == "time_logs");
      timeLogged.meta = value;
    },
    setMileStoneMeta(value) {
      let milestone = this.table_data.find(s => s.name == "milestone");
      milestone.meta = value;
    },
    setInvoiceMeta(value) {
      let invoice = this.table_data.find(s => s.name == "invoices");
      invoice.meta = value;
    }
  },
  created() {
    Promise.all([
      this.$store
        .dispatch("customers/getCustomerMetrics", this.$route.params.id)
        .then(s => {
          this.finance = s;
        }),
      // this.$store.dispatch("projects/getCollaborators", this.$route.params.id),
      this.$store.dispatch("customers/getMilestones", this.$route.params.id),
      this.$store.dispatch("customers/getSingle", this.$route.params.id)
    ]).finally(() => {
      this.loaded = true;
    });
  }
};
</script>

