function replaceQueryParam(param, newval, search) {
    var questionIndex = search.indexOf('?');

    if (questionIndex < 0) {
        search = search + '?';
        search = search + param + '=' + newval;
        return search;
    }

    var regex = new RegExp("([?;&])" + param + "[^&;]*[;&]?");
    var query = search.replace(regex, "$1").replace(/&$/, '');

    var indexOfEquals = query.indexOf('=');

    return (indexOfEquals >= 0 ? query + '&' : query + '') + (newval ? param + '=' + newval : '');
}

function getPaginationData(data)
{
    let paginationMeta = {};
    if(data && data.meta && data.links) {
        let pagination = data.meta.pagination;
        let links = data.links;
        let current_url = links.self;
        let current_page = parseInt(pagination.current_page);
        let prev_page = current_page > 1 ? current_page - 1 : current_page;
        paginationMeta = {
            'total': pagination.total,
            'per_page': pagination.per_page,
            'current_page': current_page,
            'last_page': pagination.total_pages,
            'next_page_url': links.next,
            'prev_page_url': replaceQueryParam('page', prev_page, current_url),
            'from': ((parseInt(pagination.current_page) * parseInt(pagination.per_page)) - parseInt(pagination.per_page)) + 1,
            'to': parseInt(pagination.current_page) * parseInt(pagination.per_page)
        };
    }
    return paginationMeta;
}
export default getPaginationData
