<template>
  <div class="home mb-4" :class="{ blur: !loaded }">
    <log-time
      v-if="showLogTime"
      @trigger="setTimeLog"
      @cancelTimeLog="cancelTimeLog"
      @timeLogSaved="refreshTimeLogs"
      :showLogTime="showLogTime"
      :singleTask="''"
      :timeLog="timeLog"
      ref="delete"
    ></log-time>
    <log-expense
      v-if="showExpenseForm"
      @trigger="setExpenseLog"
      @cancelExpense="cancelExpense"
      :showExpenseForm="showExpenseForm"
      :expense="expense"
      :task="''"
      ref="delete"
    ></log-expense>
    <log-multiple-time
      v-if="showMultipleTime"
      @trigger="showMultipleTime = true"
      @cancelMultiple="cancelMultiple"
      :showMultipleTime="showMultipleTime"
      :expense="expense"
      :tasks="tasks"
      :projects="projects"
      :task="''"
      ref="delete"
    ></log-multiple-time>
    <div class="container mt-1 pt-4" v-if="loaded">
      <div class="row xbg pl-5 pt-0 pb-0">
        <div class="col-sm-6 pt-5 pb-5 col-lg-6">
          <button id="welcomeBtn">👋</button>
          <!-- <h6 class="h10 text-opacity-pale pt-2">
            🌘 {{ moment().format("llll") }}
          </h6> -->
          <h4 class="mt-3 text-bold">
            Welcome, {{ $auth.user().first_name }}.
          </h4>
          <h6 class="h6 text-bolder text-opacity pt-2">
            Quickly get started on leaf by logging time/expense for a
            <br />task or general breakdown below.
          </h6>
          <!-- <div class="mt-4">
            <button
              :disabled="timerExist"
              class="btn-text pt-2 pl-4 pr-4 pb-2"
              @click="showLogTime = true"
              style="
                font-weight: bold;
                font-size: 14px;
                cursor: pointer;
                border: none;
              "
            >
              Log Time
            </button>
            <button
              @click="showExpenseForm = true"
              class="btn-text pt-2 pl-4 pr-4 pb-2 ml-3"
              style="
                font-weight: bold;
                font-size: 14px;
                cursor: pointer;
                border: none;
              "
            >
              Log Expense
            </button>
          </div> -->
        </div>

        <div
          class="col-sm-12 col-lg-6 float-right text-right xxr"
          style="position: relative"
        >
          <!-- <img src="@/assets/img/bss.png" /> -->
          <div
            class="h-100 w-100 d-flex justify-content-end align-items-center"
          >
            <b-dropdown
              id="dropdown-1"
              text="New Action"
              class="m-md-2 actions-btn"
            >
              <b-dropdown-item @click="showLogTime = true"
                ><h6 class="h6 text-bold mb-0">Time Sheet</h6></b-dropdown-item
              >
              <b-dropdown-item @click="showExpenseForm = true"
                ><h6 class="h6 text-bold mb-0">Expense</h6></b-dropdown-item
              >
              <b-dropdown-item :to="{ name: 'tasks-create' }"
                ><h6 class="h6 text-bold mb-0">Task</h6></b-dropdown-item
              >
            </b-dropdown>
          </div>
        </div>
      </div>
      <div v-if="optional($auth.user(), 'org_role.role_id') == 1">
        <div class="pl-0 pt-3 pb-1">
          <h6 class="h7 text-bold text-opacity-pale">OVERVIEW</h6>
        </div>
        <div class="row_card row mt-3">
          <div class="col-sm-12 col-lg-3 pl-0 mb-3">
            <router-link :to="{ name: 'projects-index' }" class="w-100">
              <div class="card invoice-metrics pt-4 pb-4 pr-3 pl-3">
                <div class="card-content">
                  <ul>
                    <li>
                      <svg
                        width="44px"
                        height="44px"
                        viewBox="0 0 44 44"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <g
                          id="Work-Page"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            id="Invoice-1---Landing"
                            transform="translate(-1098.000000, -171.000000)"
                          >
                            <g
                              id="Group-3"
                              transform="translate(1082.000000, 140.000000)"
                            >
                              <g
                                id="Invoice-Dashboard---Draft-Invoinces"
                                transform="translate(16.000000, 31.000000)"
                              >
                                <circle
                                  id="Oval-Copy-5"
                                  fill="#D5EFFF"
                                  cx="22"
                                  cy="22"
                                  r="22"
                                ></circle>
                                <polygon
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  points="18.492 21.265 17.432 25.508 21.674 24.447 28.038 18.083 24.856 14.901"
                                ></polygon>
                                <path
                                  d="M31.751,14.371 L32.811,15.431 C33.0925125,15.7123322 33.2506798,16.0940087 33.2506798,16.492 C33.2506798,16.8899913 33.0925125,17.2716678 32.811,17.553 L29.5,20.863"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M28.038,18.083 L31.751,14.371 C32.3365729,13.7852502 32.3365729,12.8357498 31.751,12.25 L30.69,11.189 C30.1042502,10.6034271 29.1547498,10.6034271 28.569,11.189 L24.856,14.9"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M28.75,24.25 L28.75,31.75 C28.75,32.5784271 28.0784271,33.25 27.25,33.25 L12.25,33.25 C11.4215729,33.25 10.75,32.5784271 10.75,31.75 L10.75,16.75 C10.75,15.9215729 11.4215729,15.25 12.25,15.25 L19.75,15.25"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </li>
                    <li>
                      <h6
                        class="text-bold text-small text-opacity"
                        style="color: initial; opacity: 0.5"
                      >
                        Total Projects
                      </h6>
                      <div class="d-flex flex-row align-item-center">
                        <div>
                          <h5 class="text-bold text-opacity-pale">
                            {{ metrics.projects }}
                          </h5>
                        </div>
                        <div class="pt-1 pl-2">
                          <small class="text-opacity-pale text-bold"
                            >Projects</small
                          >
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-sm-12 col-lg-3 pl-0 mb-3">
            <router-link :to="{ name: 'tasks-index' }" class="w-100">
              <div class="card invoice-metrics pt-4 pb-4 pr-3 pl-3">
                <div class="card-content">
                  <ul>
                    <li>
                      <svg
                        width="44px"
                        height="44px"
                        viewBox="0 0 44 44"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <g
                          id="Work-Page"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            id="Invoice-1---Landing"
                            transform="translate(-748.000000, -171.000000)"
                          >
                            <g
                              id="Group-3-Copy"
                              transform="translate(732.000000, 140.000000)"
                            >
                              <g
                                id="Invoice-Dashboard---Due-Within-the-next-30-Days"
                                transform="translate(16.000000, 31.000000)"
                              >
                                <circle
                                  id="Oval-Copy-5"
                                  fill="#D5EFFF"
                                  cx="22"
                                  cy="22"
                                  r="22"
                                ></circle>
                                <path
                                  d="M18.25,27.25 L12.25,27.25 C11.4215729,27.25 10.75,26.5784271 10.75,25.75 L10.75,13.75 C10.75,12.9215729 11.4215729,12.25 12.25,12.25 L25.75,12.25 C26.5784271,12.25 27.25,12.9215729 27.25,13.75 L27.25,18.25"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <line
                                  x1="10.75"
                                  y1="16.75"
                                  x2="27.25"
                                  y2="16.75"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></line>
                                <line
                                  x1="15.25"
                                  y1="13.75"
                                  x2="15.25"
                                  y2="10.75"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></line>
                                <line
                                  x1="22.75"
                                  y1="13.75"
                                  x2="22.75"
                                  y2="10.75"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></line>
                                <circle
                                  id="Oval"
                                  stroke="#0A5685"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  cx="27.25"
                                  cy="27.25"
                                  r="6"
                                ></circle>
                                <polyline
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  points="29.902 27.25 27.25 27.25 27.25 24.599"
                                ></polyline>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </li>
                    <li>
                      <h6
                        class="text-bold text-small text-opacity"
                        style="color: initial; opacity: 0.5"
                      >
                        Percent Completed
                      </h6>
                      <div class="d-flex flex-row align-item-center">
                        <div>
                          <!-- {{tasks.find(s => s.task_status_id == 1).length}} -->
                          <h5 class="text-bold text-opacity-pale">
                            {{ metrics.percentCompleted }}{{ "%" }}
                          </h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-sm-12 col-lg-3 pl-0 mb-3">
            <router-link :to="{ name: 'users-index' }" class="w-100">
              <div class="card invoice-metrics pt-4 pb-4 pr-3 pl-3">
                <div class="card-content">
                  <ul>
                    <li>
                      <svg
                        width="44px"
                        height="44px"
                        viewBox="0 0 44 44"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <g
                          id="Work-Page"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            id="Invoice-1---Landing"
                            transform="translate(-48.000000, -171.000000)"
                          >
                            <g
                              id="Group"
                              transform="translate(32.000000, 140.000000)"
                            >
                              <g
                                id="Invoice-Dashboard---Total-Unpaid"
                                transform="translate(16.000000, 31.000000)"
                              >
                                <circle
                                  id="Oval"
                                  fill="#D5EFFF"
                                  cx="22"
                                  cy="22"
                                  r="22"
                                ></circle>
                                <path
                                  d="M32.3142845,32.3714272 C32.3142845,33.2235237 31.6235239,33.9142843 30.7714275,33.9142843 L13.7999999,33.9142843 C12.9479035,33.9142843 12.2571428,33.2235237 12.2571428,32.3714272 L12.2571428,12.3142856 C12.2571428,11.4621892 12.9479035,10.7714285 13.7999999,10.7714285 L24.732685,10.7714285 C25.1415906,10.7714285 25.533738,10.933924 25.8229706,11.2229714 L31.8627417,17.2627424 C32.151789,17.551975 32.3142845,17.9441224 32.3142845,18.3530281 L32.3142845,32.3714272 Z"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M32.3142845,18.4857138 L26.1428563,18.4857138 C25.2907599,18.4857138 24.5999992,17.7949531 24.5999992,16.9428567 L24.5999992,10.7714285"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M16.3199997,21.9252564 C16.7720818,22.519033 17.4853864,22.8549541 18.2310853,22.8252564 C19.4026281,22.8252564 20.3530281,22.1124564 20.3530281,21.2340565 C20.3530281,20.3556565 19.4057138,19.6438851 18.2351996,19.6438851 C17.0646854,19.6438851 16.1142855,18.9310852 16.1142855,18.0516567 C16.1142855,17.1722281 17.0646854,16.4604568 18.2351996,16.4604568 C18.9810059,16.4302294 19.694543,16.7662601 20.1462852,17.3604567"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <line
                                  x1="18.2351996"
                                  y1="22.8252564"
                                  x2="18.2351996"
                                  y2="23.8857135"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></line>
                                <line
                                  x1="18.2351996"
                                  y1="15.3999997"
                                  x2="18.2351996"
                                  y2="16.4604568"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></line>
                                <line
                                  x1="22.2857137"
                                  y1="25.4285705"
                                  x2="28.4571419"
                                  y2="25.4285705"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></line>
                                <line
                                  x1="16.885714"
                                  y1="30.0571417"
                                  x2="28.4571419"
                                  y2="30.0571417"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></line>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </li>
                    <li>
                      <h6
                        class="text-bold text-small text-opacity"
                        style="color: initial; opacity: 0.5"
                      >
                        Total Employees
                      </h6>
                      <div class="d-flex flex-row align-item-center">
                        <div>
                          <h5 class="text-bold text-opacity-pale">
                            {{ metrics.employees }}
                          </h5>
                        </div>
                        <div class="pt-1 pl-2">
                          <small class="text-opacity-pale text-bold"
                            >Employees</small
                          >
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-sm-12 col-lg-3 pl-0 mb-3">
            <router-link :to="{ name: 'users-index' }" class="w-100">
              <div class="card invoice-metrics pt-4 pb-4 pr-3 pl-3">
                <div class="card-content">
                  <ul>
                    <li>
                      <svg
                        width="44px"
                        height="44px"
                        viewBox="0 0 44 44"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <g
                          id="Work-Page"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            id="Invoice-1---Landing"
                            transform="translate(-48.000000, -171.000000)"
                          >
                            <g
                              id="Group"
                              transform="translate(32.000000, 140.000000)"
                            >
                              <g
                                id="Invoice-Dashboard---Total-Unpaid"
                                transform="translate(16.000000, 31.000000)"
                              >
                                <circle
                                  id="Oval"
                                  fill="#D5EFFF"
                                  cx="22"
                                  cy="22"
                                  r="22"
                                ></circle>
                                <path
                                  d="M32.3142845,32.3714272 C32.3142845,33.2235237 31.6235239,33.9142843 30.7714275,33.9142843 L13.7999999,33.9142843 C12.9479035,33.9142843 12.2571428,33.2235237 12.2571428,32.3714272 L12.2571428,12.3142856 C12.2571428,11.4621892 12.9479035,10.7714285 13.7999999,10.7714285 L24.732685,10.7714285 C25.1415906,10.7714285 25.533738,10.933924 25.8229706,11.2229714 L31.8627417,17.2627424 C32.151789,17.551975 32.3142845,17.9441224 32.3142845,18.3530281 L32.3142845,32.3714272 Z"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M32.3142845,18.4857138 L26.1428563,18.4857138 C25.2907599,18.4857138 24.5999992,17.7949531 24.5999992,16.9428567 L24.5999992,10.7714285"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M16.3199997,21.9252564 C16.7720818,22.519033 17.4853864,22.8549541 18.2310853,22.8252564 C19.4026281,22.8252564 20.3530281,22.1124564 20.3530281,21.2340565 C20.3530281,20.3556565 19.4057138,19.6438851 18.2351996,19.6438851 C17.0646854,19.6438851 16.1142855,18.9310852 16.1142855,18.0516567 C16.1142855,17.1722281 17.0646854,16.4604568 18.2351996,16.4604568 C18.9810059,16.4302294 19.694543,16.7662601 20.1462852,17.3604567"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <line
                                  x1="18.2351996"
                                  y1="22.8252564"
                                  x2="18.2351996"
                                  y2="23.8857135"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></line>
                                <line
                                  x1="18.2351996"
                                  y1="15.3999997"
                                  x2="18.2351996"
                                  y2="16.4604568"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></line>
                                <line
                                  x1="22.2857137"
                                  y1="25.4285705"
                                  x2="28.4571419"
                                  y2="25.4285705"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></line>
                                <line
                                  x1="16.885714"
                                  y1="30.0571417"
                                  x2="28.4571419"
                                  y2="30.0571417"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></line>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </li>
                    <li>
                      <h6
                        class="text-bold text-small text-opacity"
                        style="color: initial; opacity: 0.5"
                      >
                        Total Customers
                      </h6>
                      <div class="d-flex flex-row align-item-center">
                        <div>
                          <h5 class="text-bold text-opacity-pale">
                            {{ metrics.customers }}
                          </h5>
                        </div>
                        <div class="pt-1 pl-2">
                          <small class="text-opacity-pale text-bold"
                            >Customers</small
                          >
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-sm-12 col-lg-3 pl-0 mb-3" v-if="optional($auth.user(), 'org_role.role_id') == 1"
          >
            <div class="card invoice-metrics pt-4 pb-4 pr-3 pl-3">
              <div class="card-content">
                <ul>
                  <li>
                    <svg
                      width="44px"
                      height="44px"
                      viewBox="0 0 44 44"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        id="Work-Page"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="Invoice-1---Landing"
                          transform="translate(-398.000000, -171.000000)"
                        >
                          <g
                            id="Group-2"
                            transform="translate(382.000000, 140.000000)"
                          >
                            <g
                              id="Invoice-Dashboard---Amount-Overdue"
                              transform="translate(16.000000, 31.000000)"
                            >
                              <circle
                                id="Oval-Copy-4"
                                fill="#D5EFFF"
                                cx="22"
                                cy="22"
                                r="22"
                              ></circle>
                              <path
                                d="M29.5,22.88 L29.5,12.25 C29.5,11.4215729 28.8284271,10.75 28,10.75 L16,10.75 C15.1715729,10.75 14.5,11.4215729 14.5,12.25 L14.5,22.88"
                                id="Path"
                                stroke="#0A5685"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M31.284,21.465 C31.5093503,21.2863025 31.8170422,21.2522091 32.0760466,21.3772383 C32.3350509,21.5022675 32.4997471,21.764397 32.5,22.052 L32.5,31.752 C32.5,32.5804271 31.8284271,33.252 31,33.252 L13,33.252 C12.1715729,33.252 11.5,32.5804271 11.5,31.752 L11.5,22.052 C11.5002529,21.764397 11.6649491,21.5022675 11.9239534,21.3772383 C12.1829578,21.2522091 12.4906497,21.2863025 12.716,21.465 L20.147,27.359 C21.2346554,28.21324 22.7653446,28.21324 23.853,27.359 L31.284,21.465 Z"
                                id="Path"
                                stroke="#0A5685"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <line
                                x1="25.808"
                                y1="25.808"
                                x2="28.75"
                                y2="28.75"
                                id="Path"
                                stroke="#0A5685"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></line>
                              <line
                                x1="18.192"
                                y1="25.808"
                                x2="15.25"
                                y2="28.75"
                                id="Path"
                                stroke="#0A5685"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></line>
                              <path
                                d="M22.2,20.094 C22.6395243,20.6712828 23.3330149,20.9978728 24.058,20.969 C25.197,20.969 26.121,20.276 26.121,19.422 C26.121,18.568 25.2,17.876 24.062,17.876 C22.924,17.876 22,17.183 22,16.328 C22,15.473 22.924,14.781 24.062,14.781 C24.7870895,14.7516123 25.4808062,15.0783088 25.92,15.656"
                                id="Path"
                                stroke="#0A5685"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <line
                                x1="24.062"
                                y1="20.969"
                                x2="24.062"
                                y2="22"
                                id="Path"
                                stroke="#0A5685"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></line>
                              <line
                                x1="24.062"
                                y1="13.75"
                                x2="24.062"
                                y2="14.781"
                                id="Path"
                                stroke="#0A5685"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></line>
                              <line
                                x1="17.5"
                                y1="14.5"
                                x2="19"
                                y2="14.5"
                                id="Path"
                                stroke="#0A5685"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></line>
                              <line
                                x1="17.5"
                                y1="17.5"
                                x2="19"
                                y2="17.5"
                                id="Path"
                                stroke="#0A5685"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></line>
                              <line
                                x1="17.5"
                                y1="20.5"
                                x2="19"
                                y2="20.5"
                                id="Path"
                                stroke="#0A5685"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></line>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </li>
                  <li>
                    <h6 class="text-bold text-small text-opacity">
                      Total Billable.
                    </h6>
                    <div class="d-flex flex-row align-item-center">
                      <div>
                        <h5 class="text-bold text-opacity-pale">
                          {{
                            organizationCurrency && organizationCurrency.symbol
                          }}{{ metrics.totalBillable }}
                        </h5>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-3 pl-0 mb-3" v-if="optional($auth.user(), 'org_role.role_id') == 1"
          >
            <router-link :to="{ name: 'tasks-index' }" class="w-100">
              <div class="card invoice-metrics pt-4 pb-4 pr-3 pl-3">
                <div class="card-content">
                  <ul>
                    <li>
                      <svg
                        width="44px"
                        height="44px"
                        viewBox="0 0 44 44"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <g
                          id="Work-Page"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            id="Invoice-1---Landing"
                            transform="translate(-748.000000, -171.000000)"
                          >
                            <g
                              id="Group-3-Copy"
                              transform="translate(732.000000, 140.000000)"
                            >
                              <g
                                id="Invoice-Dashboard---Due-Within-the-next-30-Days"
                                transform="translate(16.000000, 31.000000)"
                              >
                                <circle
                                  id="Oval-Copy-5"
                                  fill="#D5EFFF"
                                  cx="22"
                                  cy="22"
                                  r="22"
                                ></circle>
                                <path
                                  d="M18.25,27.25 L12.25,27.25 C11.4215729,27.25 10.75,26.5784271 10.75,25.75 L10.75,13.75 C10.75,12.9215729 11.4215729,12.25 12.25,12.25 L25.75,12.25 C26.5784271,12.25 27.25,12.9215729 27.25,13.75 L27.25,18.25"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <line
                                  x1="10.75"
                                  y1="16.75"
                                  x2="27.25"
                                  y2="16.75"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></line>
                                <line
                                  x1="15.25"
                                  y1="13.75"
                                  x2="15.25"
                                  y2="10.75"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></line>
                                <line
                                  x1="22.75"
                                  y1="13.75"
                                  x2="22.75"
                                  y2="10.75"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></line>
                                <circle
                                  id="Oval"
                                  stroke="#0A5685"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  cx="27.25"
                                  cy="27.25"
                                  r="6"
                                ></circle>
                                <polyline
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  points="29.902 27.25 27.25 27.25 27.25 24.599"
                                ></polyline>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </li>
                    <li>
                      <h6
                        class="text-bold text-small text-opacity"
                        style="color: initial; opacity: 0.5"
                      >
                        Total Billed
                      </h6>
                      <div class="d-flex flex-row align-item-center">
                        <div>
                          <!-- {{tasks.find(s => s.task_status_id == 1).length}} -->
                          <h5 class="text-bold text-opacity-pale">
                            {{
                              organizationCurrency &&
                              organizationCurrency.symbol
                            }}{{ metrics.totalBilled }}
                          </h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-sm-12 col-lg-3 pl-0 mb-3" v-if="optional($auth.user(), 'org_role.role_id') == 1"
          >
            <router-link :to="{ name: 'users-index' }" class="w-100">
              <div class="card invoice-metrics pt-4 pb-4 pr-3 pl-3">
                <div class="card-content">
                  <ul>
                    <li>
                      <svg
                        width="44px"
                        height="44px"
                        viewBox="0 0 44 44"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <g
                          id="Work-Page"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            id="Invoice-1---Landing"
                            transform="translate(-48.000000, -171.000000)"
                          >
                            <g
                              id="Group"
                              transform="translate(32.000000, 140.000000)"
                            >
                              <g
                                id="Invoice-Dashboard---Total-Unpaid"
                                transform="translate(16.000000, 31.000000)"
                              >
                                <circle
                                  id="Oval"
                                  fill="#D5EFFF"
                                  cx="22"
                                  cy="22"
                                  r="22"
                                ></circle>
                                <path
                                  d="M32.3142845,32.3714272 C32.3142845,33.2235237 31.6235239,33.9142843 30.7714275,33.9142843 L13.7999999,33.9142843 C12.9479035,33.9142843 12.2571428,33.2235237 12.2571428,32.3714272 L12.2571428,12.3142856 C12.2571428,11.4621892 12.9479035,10.7714285 13.7999999,10.7714285 L24.732685,10.7714285 C25.1415906,10.7714285 25.533738,10.933924 25.8229706,11.2229714 L31.8627417,17.2627424 C32.151789,17.551975 32.3142845,17.9441224 32.3142845,18.3530281 L32.3142845,32.3714272 Z"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M32.3142845,18.4857138 L26.1428563,18.4857138 C25.2907599,18.4857138 24.5999992,17.7949531 24.5999992,16.9428567 L24.5999992,10.7714285"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M16.3199997,21.9252564 C16.7720818,22.519033 17.4853864,22.8549541 18.2310853,22.8252564 C19.4026281,22.8252564 20.3530281,22.1124564 20.3530281,21.2340565 C20.3530281,20.3556565 19.4057138,19.6438851 18.2351996,19.6438851 C17.0646854,19.6438851 16.1142855,18.9310852 16.1142855,18.0516567 C16.1142855,17.1722281 17.0646854,16.4604568 18.2351996,16.4604568 C18.9810059,16.4302294 19.694543,16.7662601 20.1462852,17.3604567"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <line
                                  x1="18.2351996"
                                  y1="22.8252564"
                                  x2="18.2351996"
                                  y2="23.8857135"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></line>
                                <line
                                  x1="18.2351996"
                                  y1="15.3999997"
                                  x2="18.2351996"
                                  y2="16.4604568"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></line>
                                <line
                                  x1="22.2857137"
                                  y1="25.4285705"
                                  x2="28.4571419"
                                  y2="25.4285705"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></line>
                                <line
                                  x1="16.885714"
                                  y1="30.0571417"
                                  x2="28.4571419"
                                  y2="30.0571417"
                                  id="Path"
                                  stroke="#0A5685"
                                  stroke-width="1.34999992"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></line>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </li>
                    <li>
                      <h6
                        class="text-bold text-small text-opacity"
                        style="color: initial; opacity: 0.5"
                      >
                        Total Paid For
                      </h6>
                      <div class="d-flex flex-row align-item-center">
                        <div>
                          <h5 class="text-bold text-opacity-pale">
                            {{
                              organizationCurrency &&
                              organizationCurrency.symbol
                            }}{{ metrics.totalPaid }}
                          </h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="row row-eq-height mt-5">
        <!-- MY TIMESHEETS -->
        <div class="col-sm-12 col-lg-12 pl-0 mb-4">
          <accordion
            :visible="accordions[1].visible"
            :name="'MY TIMESHEETS'"
            :notoggable="true"
          >
            <div slot="content">
              <div
                class="d-flex flex-row align-items-end justify-content-between"
              >
                <div
                  class="
                    d-flex
                    flex-column
                    align-items-start
                    justify-content-start
                  "
                >
                  <small class="text-left">Total Logged</small>
                  <h6 class="h6 text-opacity-pale text-bold">
                    {{ totalHoursLogged }} hours
                  </h6>
                </div>
                <!-- Filter Strategy -->
                <div class="d-flex flex-row justify-content-end">
                  <div
                    class="
                      d-flex
                      flex-column
                      align-items-end
                      justify-content-end
                    "
                  >
                    <small
                      class="text-opacity-pale text-bold"
                      style="width: 150px"
                      >Filter by:</small
                    >
                    <select
                      v-model="filter_type"
                      @change="clearFilter"
                      placeholder="Select Filter Type"
                      style="
                        height: 45px;
                        width: 150px;
                        padding-right: 20px;
                        padding-left: 10px;
                        background: #f5f5f5;
                        border: none;
                        border-radius: 5px;
                        font-size: 15px;
                        -webkit-appearance: none;
                      "
                    >
                      <option value>Select Type</option>
                      <option value="project">By Projects</option>
                      <option value="task">By Tasks</option>
                      <option value="interval">By Period</option>
                    </select>
                  </div>
                  <div
                    v-if="filter_type == 'project'"
                    class="
                      d-flex
                      flex-column
                      align-items-end
                      justify-content-end
                      ml-2
                    "
                  >
                    <small
                      class="text-opacity-pale text-bold"
                      style="width: 150px"
                      >Projects</small
                    >
                    <select
                      v-model="filters.project_id"
                      placeholder="Select Project"
                      @change="refreshTimeLogs"
                      style="
                        height: 45px;
                        width: 150px;
                        padding-right: 20px;
                        padding-left: 10px;
                        background: #f5f5f5;
                        border: none;
                        border-radius: 5px;
                        font-size: 15px;
                        -webkit-appearance: none;
                      "
                    >
                      <option value>Select Project</option>
                      <option
                        :value="project.id"
                        v-for="(project, index) in projects"
                        :key="index"
                      >
                        {{ project.name }}
                      </option>
                    </select>
                  </div>
                  <div
                    v-else-if="filter_type == 'task'"
                    class="
                      d-flex
                      flex-column
                      align-items-end
                      justify-content-end
                      ml-2
                    "
                  >
                    <small
                      class="text-opacity-pale text-bold"
                      style="width: 150px"
                      >Tasks</small
                    >
                    <select
                      v-model="filters.task_id"
                      @change="refreshTimeLogs"
                      placeholder="Select Task"
                      style="
                        height: 45px;
                        width: 150px;
                        padding-right: 20px;
                        padding-left: 10px;
                        background: #f5f5f5;
                        border: none;
                        border-radius: 5px;
                        font-size: 15px;
                        -webkit-appearance: none;
                      "
                    >
                      <option value>Select Task</option>
                      <option
                        :value="task.id"
                        v-for="(task, index) in tasks"
                        :key="index"
                      >
                        {{ task.name }}
                      </option>
                    </select>
                  </div>
                  <div
                    v-else-if="filter_type == 'interval'"
                    class="
                      d-flex
                      flex-column
                      align-items-end
                      justify-content-end
                      ml-2
                    "
                  >
                    <small
                      class="text-opacity-pale text-bold"
                      style="width: 150px"
                      >Duration</small
                    >
                    <select
                      v-model="filters_interval"
                      @change="refreshTimeLogs"
                      placeholder="Select Period"
                      style="
                        height: 45px;
                        width: 150px;
                        padding-right: 20px;
                        padding-left: 10px;
                        background: #f5f5f5;
                        border: none;
                        border-radius: 5px;
                        font-size: 15px;
                        -webkit-appearance: none;
                      "
                    >
                      <option value>Select Period</option>
                      <option value="7">Last Week</option>
                      <option value="30">Last Month</option>
                    </select>
                  </div>
                  <div v-if="filters_interval.length != 0 && filter_type == 'interval'" class="d-flex flex-row">
                    <div class="ml-2">
                      <small class="text-opacity-pale text-bold">From: </small>
                      <p class="mt-2">{{this.filters.filter_start}}</p>
                    </div>
                    <div class="ml-2">
                      <small class="text-opacity-pale text-bold">End: </small>
                      <p class="mt-2">{{this.filters.filter_end}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <div
                  class="submit-sec p-2 d-flex justify-content-end"
                  style="
                    position: sticky;
                    top: 0;
                    z-index: 2;
                    background: #f7f7f7;
                    border: 1px dashed #e4e4e4;
                  "
                >
                  <button
                    @click="addEntry()"
                    class="btn-text mr-2 pt-2 pl-4 pr-4 pb-2 ml-3"
                    style="
                      font-weight: bold;
                      font-size: 14px;
                      cursor: pointer;
                      border: none;
                    "
                  >
                    + New Entry
                  </button>
                </div>
              </div>
              <h6
                v-if="timeLogs && timeLogs.length == 0"
                class="h7 text-opacity"
              >
                You have not logged-in any hour yet
              </h6>
              <form id="timesheets_form" @submit.prevent="saveEntries">
                <table id="customers" class="mt-3">
                  <tr>
                    <th>
                      <h6 class="h7 text-opacity-pale text-bold">Project</h6>
                    </th>
                    <th>
                      <h6 class="h7 text-opacity-pale text-bold">Task</h6>
                    </th>
                    <th>
                      <h6 class="h7 text-opacity-pale text-bold">Note</h6>
                    </th>
                    <th>
                      <h6 class="h7 text-opacity-pale text-bold">Hours</h6>
                    </th>
                    <th>
                      <h6 class="h7 text-opacity-pale text-bold">
                        Date Logged
                      </h6>
                    </th>
                  </tr>
                  <tr v-for="(time, index) in timeLogs" :key="index" @click="setTimeLog(time)" style="cursor: pointer">
                    <td>
                      {{ optional(time, "project.data.name", "") }}
                    </td>
                    <td>
                      {{
                        optional(time, "task.data.name", null) &&
                        time.task.data.name.substring(0, 35) + "..."
                      }}
                    </td>
                    <td>{{ html2text(time.note).substring(0, 50) }}</td>
                    <td>{{ time && time.hours }} hour(s)</td>
                    <td>
                      {{
                        time && time.created_at
                          ? toLocal(time.created_at).format("MM/DD/YYYY")
                          : "N/A"
                      }}
                    </td>
                  </tr>
                  <tr
                    id="timesheets_form"
                    class="table-input__fields"
                    v-for="(entry, keyIndex) in timesheets"
                    :key="'A' + keyIndex"
                  >
                    <td class="with_delete">
                      <button
                        type="button"
                        @click="timesheets.splice(keyIndex, 1)"
                      >
                        -
                      </button>
                      <multiselect
                        track-by="id"
                        @input="fetchTasks(entry.project_id)"
                        label="name"
                        class="no-caret-select"
                        v-validate="'required'"
                        :name="'project' + keyIndex"
                        :style="
                          errors.has('project' + keyIndex)
                            ? 'border:1px solid red;max-width:250px'
                            : 'max-width:250px'
                        "
                        :options="projects"
                        v-model="entry.project_id"
                        :show-labels="false"
                        placeholder="Select Project"
                      >
                        <template slot="caret">
                          <div>
                            <div class="multiselect__select">
                              <i
                                class="zmdi zmdi-chevron-down pt-1"
                                style="font-size: 20px"
                              ></i>
                            </div>
                          </div>
                        </template>
                      </multiselect>
                    </td>
                    <td>
                      <multiselect
                        track-by="id"
                        label="name"
                        :disabled="fetchingTasks"
                        class="no-caret-select"
                        :name="'task' + keyIndex"
                        v-validate="'required'"
                        :style="
                          errors.has('task' + keyIndex)
                            ? 'border:1px solid red;max-width:250px'
                            : 'max-width:250px;'
                        "
                        :options="projectTasks"
                        v-model="entry.task_id"
                        :show-labels="false"
                        placeholder="Select Task"
                      >
                        <template slot="caret">
                          <div>
                            <div class="multiselect__select">
                              <i
                                class="zmdi zmdi-chevron-down pt-1"
                                style="font-size: 20px"
                              ></i>
                            </div>
                          </div>
                        </template>
                      </multiselect>
                    </td>
                    <td>
                      <input
                        type="text"
                        v-validate="'min:5'"
                        :name="'note' + keyIndex"
                        :style="
                          errors.has('note' + keyIndex)
                            ? 'border:1px solid red'
                            : ''
                        "
                        v-model="entry.note"
                        class="form-control auth-input d-input"
                        placeholder="Enter additional note"
                        data-vv-as="Note"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        v-validate="'required|min:1|max:100'"
                        :name="'hours' + keyIndex"
                        :style="
                          errors.has('hours' + keyIndex)
                            ? 'border:1px solid red'
                            : ''
                        "
                        v-model="entry.hours"
                        class="form-control auth-input d-input"
                        placeholder="Enter number of hours"
                        data-vv-as="Task Hours"
                      />
                    </td>
                    <td>
                      <vc-date-picker
                        :masks="{ input: ['YYYY-MM-DD'] }"
                        popover-align="center"
                        :placement="'top'"
                        :input-props="{
                          class: `d-input no-margin-top auth-input pl-3`,
                          style: `width: 100%;min-height: 40px !important;text-align: left;color: black;height: 40px !important;border-radius: inherit;font-size: 14px;border: 1px solid #dce1e7;`,
                          placeholder: `Task Start date`,
                        }"
                        :name="'start_date' + keyIndex"
                        v-validate="'required'"
                        :style="
                          errors.has('start_date' + keyIndex)
                            ? 'border:1px solid red'
                            : ''
                        "
                        v-model="entry.start_date"
                        ref="startFrom"
                        data-vv-as="Project Start date"
                      />
                    </td>
                  </tr>
                </table>
              </form>
              <div class="mt-0">
                <div
                  class="submit-sec p-2 d-flex justify-content-end"
                  style="background: #f7f7f7; border: 1px dashed #e4e4e4"
                >
                  <!-- <button
                    @click="addEntry()"
                    class="btn-text mr-2 pt-2 pl-4 pr-4 pb-2 ml-3"
                    style="
                      font-weight: bold;
                      font-size: 14px;
                      cursor: pointer;
                      border: none;
                    "
                  >
                    + New Entry
                  </button> -->
                  <button
                    :disabled="savingTimeSheet"
                    form="timesheets_form"
                    type="submit"
                    class="btn btn-primary auth-btn"
                  >
                    <div>Save Entries</div>
                  </button>
                </div>
              </div>
            </div>
          </accordion>
        </div>
        <!-- MY PROJECTS -->
        <div class="col-sm-12 col-lg-6 pl-0 mb-4">
          <accordion
            :visible="accordions[0].visible"
            :name="'MY PROJECTS'"
            :notoggable="true"
          >
            <div slot="content">
              <h6
                v-if="projects && projects.length == 0"
                class="h7 text-opacity"
              >
                You have not been assigned to any Project yet
              </h6>
              <ul v-else class="d-flex flex-column justify-content-center">
                <li
                  class="border-bottom pt-3 pb-3"
                  :key="index"
                  v-for="(project, index) in projects.slice(0, 5)"
                >
                  <router-link
                    :to="{
                      name: 'projects-show-general',
                      params: { id: project.id },
                    }"
                    class="w-100"
                  >
                    <div
                      class="
                        d-flex
                        flex-row
                        justify-content-between
                        align-items-center
                        w-100
                      "
                    >
                      <div class>
                        <h6 class="h7 text-bold text-opacity-pale">
                          {{ project.name }}
                        </h6>
                      </div>
                      <!-- TODO: to be modified back, when project uses or have progress status -->
                      <!-- <div class="w-50">
                        <b-progress :value="65" :max="100" variant="success" show-progress></b-progress>
                      </div> -->
                    </div>
                  </router-link>
                </li>

                <li class="pt-3 d-flex flex-column align-items-center">
                  <router-link
                    class="text-right"
                    :to="{ name: 'projects-index' }"
                  >
                    <h6 class="h7 text-bold">See all Projects</h6>
                  </router-link>
                </li>
              </ul>
            </div>
          </accordion>
        </div>
        <!-- MY TASKS -->
        <div class="col-sm-12 col-lg-6 pl-0 mb-4">
          <accordion
            :visible="accordions[1].visible"
            :name="'MY TASKS'"
            :notoggable="true"
          >
            <div slot="content">
              <h6 v-if="tasks && tasks.length == 0" class="h7 text-opacity">
                You have not been assigned to any Task yet
              </h6>
              <ul v-else class="d-flex flex-column justify-content-center">
                <li
                  class="border-bottom pt-3 pb-3"
                  v-for="(task, index) in tasks.slice(0, 5)"
                  :key="index"
                >
                  <router-link
                    :to="{ name: 'task-show-full', params: { id: task.id } }"
                    class="w-100"
                  >
                    <div
                      class="
                        d-flex
                        flex-row
                        justify-content-between
                        align-items-center
                        w-100
                      "
                    >
                      <div class>
                        <h6 class="h7 text-bold text-opacity-pale">
                          {{ task.name }}
                        </h6>
                        <button
                          :class="{
                            status: true,
                            done: optional(task, 'status.name', '') == 'Done',
                            not_started:
                              optional(task, 'status.name', '') ==
                              'Not Started',
                            in_progress:
                              optional(task, 'status.name', '') ==
                              'In Progress',
                            testing_review:
                              optional(task, 'status.name', '') ==
                              'Testing/Review',
                          }"
                        >
                          {{ optional(task, "status.name", "N/A") }}
                        </button>
                      </div>
                      <div class="w-50 text-right">
                        <i
                          class="zmdi zmdi-chevron-right"
                          style="font-size: 30px"
                        ></i>
                      </div>
                    </div>
                  </router-link>
                </li>
                <li
                  class="pt-3 d-flex sticky-btn flex-column align-items-center"
                >
                  <router-link class="text-right" :to="{ name: 'tasks-index' }">
                    <h6 class="h7 text-bold">See all Tasks</h6>
                  </router-link>
                </li>
              </ul>
            </div>
          </accordion>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.task-field {
  background: white;
  border: 2px solid #f7f7f7;
  border-radius: 5px;
}
.new-entry:hover {
  background: #daf1ff !important;
}
#timesheets_form {
  input {
    width: 100%;
    min-height: 40px !important;
    text-align: left;
    height: 40px !important;
    border-radius: inherit;
    font-size: 14px;
    border: 1px solid #dce1e7;

    &::placeholder {
      color: #adadad !important;
    }
  }
}
td.with_delete {
  // display: none;
  // &::before {
  //   content: '00';
  // }
  button {
    background: red;
    height: 15px;
    width: 15px;
    border-radius: 15px;
    position: absolute;
    z-index: 2;
    left: 12px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    &:hover {
      background: darkred;
    }
  }
}
</style>

<script>
import Accordion from "@/components/Collapse/Index";
import LogTime from "@/components/Modals/Time";
import LogExpense from "@/components/Modals/Expense";
import LogMultipleTime from "@/components/Modals/MultipleTime";
import moment from "moment";
import { timerExist } from "@/helpers/random";
import getOrganization from "@/helpers/getOrganization";
import { optional, html2text } from "@/helpers/random";
import { toLocal, toUTC } from "@/helpers/date";
import { filter } from "minimatch";

export default {
  components: { Accordion, LogTime, LogExpense, LogMultipleTime },
  data() {
    return {
      loaded: false,
      savingTimeSheet: false,
      showLogTime: false,
      showExpenseForm: false,
      showMultipleTime: false,
      filters_interval: '',
      timeLog: {},
      expense: {},
      timesheets: [
        {
          task_id: "",
          project_id: "",
          hours: "",
          note: "",
          start_date: "",
        },
      ],
      filters: {
        project_id: "",
        task_id: "",
        employee_id: null,
        range_filter: "created_at",
        filter_start: '',
        filter_end: toUTC(Date.now()).format("YYYY-MM-DD"),
      },
      filter_type: "",
      accordions: [
        { name: "project", visible: true },
        { name: "milestone", visible: true },
        { name: "tasks", visible: true },
        { name: "timetracking", visible: false },
        { name: "additional", visible: false },
      ],
      totalProjects: 0,
      projectTasks: [],
      fetchingTasks: false,
    };
  },
  computed: {
    timerExist,
    projects() {
      return this.$store.getters["projects/projects_collaborating"];
    },
    organizationCurrency() {
      if (
        this.$auth.user().organizations &&
        this.$auth.user().organizations.data &&
        this.$auth.user().organizations.data.length > 0
      ) {
        let userOrgs = optional(this.$auth.user(), "organizations.data", []);
        let loggedInUserOrg = userOrgs.find(
          (s) => s.id == getOrganization.organization_id()
        );
        return this.currencies.find(
          (s) => s.id == loggedInUserOrg.default_currency_id
        );
      } else {
        let curr_id =
          this.$auth.user().organization && this.$auth.user().organization.data;
        if (curr_id) {
          return this.currencies.find(
            (s) => s.id == curr_id.default_currency_id
          );
        }
      }
      return {};
    },
    currencies() {
      return this.$store.getters["global/currencies"];
    },
    metrics() {
      return this.$store.getters["tasks/metrics"];
    },
    projectsTotal() {
      return this.$store.getters["projects/projects_total"];
    },
    tasks() {
      return this.$store.getters["tasks/tasks"];
    },
    employeesTotal() {
      return this.$store.getters["employees/employees_total"];
    },
    tasksTotal() {
      return this.$store.getters["tasks/tasks_total"];
    },
    timeLogs() {
      return this.$store.getters["tasks/all_time_logs"];
    },
    totalHoursLogged() {
      return this.$store.getters["tasks/total_time_logs"];
    },
    pendingTasks() {
      let tasks = this.$store.getters["tasks/tasks"];
      if (tasks) {
        let pending = tasks.filter((s) => s.task_status_id == 1);
        return pending && pending.length;
      }
      return 0;
    },
  },
  methods: {
    moment,
    toLocal,
    toUTC,
    html2text,
    optional,
    setTimeLog(timeLog) {
      this.timeLog = timeLog;
      this.showLogTime = true;
    },
    addEntry() {
      this.timesheets.push({
        task_id: "",
        project_id: "",
        hours: "",
        note: "",
        start_date: "",
      });
      let arrayIndex = this.timesheets.length - 1;
      let card = "time_card" + arrayIndex;
      var elmnt = document.getElementById("time_card2");
    },

    saveEntries() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.savingTimeSheet = true;
          var saveEntries = new Promise((resolve, reject) => {
            this.timesheets.forEach((s, index) => {
              let data = {};
              let user = this.$auth.user();
              s["employee_id"] =
                user.employee && user.employee.data && user.employee.data.id;
              Object.assign(data, s);
              data.project_id = data.project_id && data.project_id.id;
              data.task_id = data.task_id && data.task_id.id;
              data.start_time = toUTC(data.start_date).format(
                "YYYY-MM-DD HH:mm"
              );
              data["end_time"] = toUTC(data.start_date).format(
                "YYYY-MM-DD HH:mm"
              );

              this.$store
                .dispatch("tasks/createUpdateTimeLog", data)
                .then((s) => {
                  if (index === this.timesheets.length - 1) resolve();
                })
                .catch(() => reject());
            });
          });
          saveEntries
            .then((s) => {
              // this.$store.dispatch("tasks/index");
              this.timesheets = [];
              this.refreshTimeLogs();
              this.$toast.success("Time entries successfully loggged", {
                position: "top-right",
                duration: 3000,
              });
            })
            .catch((s) => {
              this.$toast.error(
                "Something went wrong, Please confirm your input",
                {
                  position: "top-right",
                  duration: 3000,
                }
              );
            })
            .finally(() => (this.savingTimeSheet = false));
        } else {
          this.$toast.error("Please correct errors on form to proceed", {
            position: "top-right",
            duration: 3000,
          });
        }
      });
    },

    clearFilter() {
      this.filters = {};
      this.filters  = {
        employee_id: optional(this.$auth.user(), "employee.data.id", null) || optional(this.employees.find((s) => s.user_id == this.$auth.user().id, "id",null)),
        project_id: "",
        task_id: "",
        range_filter: "created_at",
        filter_start: "",
        filter_end: toUTC(Date.now()).format("YYYY-MM-DD"),
      }
    },

    refreshTimeLogs() {
      if (this.filter_type == 'interval') {
        this.filters['filter_start'] = this.filters_interval ? toUTC(Date.now() - this.filters_interval * 24 * 60 * 60 * 1000).format("YYYY-MM-DD") : '';
      } else {
        this.filters;
      }

      this.$store.dispatch(
        "tasks/getAllTimeLogs",
        this.filters,
      );

      this.timesheets.map((s) => {
        if (this.filters) {
          s.project_id =
            this.filters && this.filters.project_id
              ? this.projects.find((s) => s.id == this.filters.project_id)
              : "";
          s.task_id =
            this.filters && this.filters.task_id
              ? this.tasks.find((s) => s.id == this.filters.task_id)
              : "";
        }
        return s;
      });
    },
    setExpenseLog(expense) {
      this.expense = expense;
      this.showExpenseForm = true;
    },
    cancelExpense(expense) {
      this.expense = expense;
      this.showExpenseForm = false;
    },
    cancelMultiple(time) {
      this.showMultipleTime = time;
    },
    cancelTimeLog() {
      this.timeLog = {};
      this.showLogTime = false;
    },
    toggleCollapse(index) {
      this.accordions[index].visible =
        this.accordions[index].visible === false ? true : false;
      this.accordions[index].visible =
        this.accordions[index].visible === false ? true : false;
    },
    fetchTasks(project) {
      if (!project) return; // if no project selected, don't fetch
      this.fetchingTasks = true;
      this.$store
        .dispatch(`tasks/index`, `project_id=${project.id}`)
        .then((data) => {
          this.projectTasks = data;
        })
        .finally(() => {
          this.fetchingTasks = false;
        });
    },
  },
  created() {
    this.filters.employee_id = optional(this.$auth.user(), "employee.data.id", null)
    let filterByUser = {};
    let employee_id = optional(this.$auth.user(), "employee.data.id", null)
    filterByUser["employee_id"] = employee_id;
    
    Promise.all([
      this.$store.dispatch("projects/collaborating"),
      this.$store.dispatch("tasks/index", new URLSearchParams(filterByUser).toString()),
      this.$store.dispatch("employees/index"),
      this.$store.dispatch("tasks/getAllTimeLogs", this.filters),
      this.$store.dispatch("global/getCurrencies"),
      this.$store.dispatch("tasks/getDashboardMetrics"),
    ]).finally(() => (this.loaded = true));
    

  },


};
</script>

<style lang="scss" scoped>
#customers {
  width: 100%;
}
.c-form {
  height: 1000px;
  .c-form__entry {
    background: red;
    height: 70px !important;

    .auth-input {
      height: 70px !important;
    }
  }
}
#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f7f7f7;
  height: 40px;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f5f5f5;
  color: black;
}
#customers tr:not(:first-child) {
  // display: none;
  font-size: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 60px;
}
.border-bottom {
  padding-left: 10px;
  padding-right: 10px;
  &:hover {
    background: #f5f5f5;
    &:not(.status) {
      color: #0a5685;
    }
  }
}

span.btn-text {
  &.hover {
    display: none;
  }
}
.status {
  height: 30px;
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  font-size: 12px;
  &.done {
    background: #d4f195;
  }
  &.not_started {
    background: #f9bebe;
  }
  &.in_progress {
    background: #fff5cb;
  }
}
#welcomeBtn {
  background: #daf1ff;
  height: 40px;
  width: 40px;
  font-size: 20px;
  border-radius: 40px;
  border: none;
}
.xbg {
  background: #f0f9ff85;
  border-radius: 20px;
}
.xxr {
  background-color: #f0f9ff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='96' viewBox='0 0 60 96'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23daf1ff' fill-opacity='0.4'%3E%3Cpath d='M36 10a6 6 0 0 1 12 0v12a6 6 0 0 1-6 6 6 6 0 0 0-6 6 6 6 0 0 1-12 0 6 6 0 0 0-6-6 6 6 0 0 1-6-6V10a6 6 0 1 1 12 0 6 6 0 0 0 12 0zm24 78a6 6 0 0 1-6-6 6 6 0 0 0-6-6 6 6 0 0 1-6-6V58a6 6 0 1 1 12 0 6 6 0 0 0 6 6v24zM0 88V64a6 6 0 0 0 6-6 6 6 0 0 1 12 0v12a6 6 0 0 1-6 6 6 6 0 0 0-6 6 6 6 0 0 1-6 6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  img {
    width: 65%;
    transform: rotate(-45deg);
    opacity: 0.1;
  }
}
</style>
