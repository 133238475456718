<template>
  <div class="container-fluid">
    <div class="table pb-4 pl-3 pr-4">
      <div class="table-header">
        <div class="row">
          <div class="col-sm-12 col-lg-4 float-left text-left">
            <small class="text-opacity">{{paginationData.total}} total customers</small>
          </div>

          <div class="col-sm-12 col-lg-8 float-right text-right">
            <customers-filters
              @setFilters="setFilters"
              @showMoreFilters="showVisibleFilter"
              @removeColumnFromTable="removeColumnFromTable"
              :columns="columns"
              :params="moreParams"
              :visibleFilter="visibleFilter"
            ></customers-filters>
          </div>
        </div>
        <div class="text-right mt-3" v-show="visibleFilter">
          <button
            @click="moreParams = {}"
            class="p-2 pl-3 pr-3"
            style="background:#E4E4E4; font-size:13px; border-radius:50px; border:none"
          >Reset Filter</button>
        </div>
        <customers-filters-dropdown
          @setFilters="setFilters"
          :params="moreParams"
          :sources="sources"
          :industries="industries"
          :visibleFilter="visibleFilter"
        ></customers-filters-dropdown>
      </div>
      <div class="table-wrapper mt-4">
        <vuetable
          ref="vuetable"
          :api-url="api"
          :first-page="0"
          :append-params="api.includes('/customers/search/') ? {} : moreParams"
          :http-options="{ headers:{ 'Authorization' : 'Bearer ' + $auth.token(),'xorg' : getOrganization.organization_id() } }"
          @vuetable:loaded="loadTable"
          @vuetable:cell-clicked="gotoCustomer"
          :fields="columns.filter(s => s.active === true)"
          data-path="data"
          pagination-path
          :no-data-template="rowCount === null ? 'Loading data' : 'No Data Available'"
          @vuetable:pagination-data="onPaginationData"
        >
          <template slot="sn" slot-scope="props">
            <router-link
              :to="{name: 'customers-show', params: {id: props.rowData.id}}"
            >{{props.rowIndex + 1}}</router-link>
          </template>
          <template
            slot="name"
            slot-scope="props"
          >{{props.rowData && props.rowData.first_name}} {{props.rowData && props.rowData.last_name}}</template>
          <template
            slot="billing_type_id"
            slot-scope="props"
          >{{props.rowData.billing_type && props.rowData.billing_type.name}}</template>
          <template slot="budget" slot-scope="props">{{props.rowData.estimated_hours}} hours</template>
          <template slot="actions" slot-scope="props">
            <router-link :to="{name: 'customers-show', params: {id: props.rowData.id}}">
              <button class="btn-lightgray-round">
                <i class="zmdi zmdi-plus pr-2"></i>View Customer
              </button>
            </router-link>
          </template>
        </vuetable>
        <vuetable-pagination
          v-show="paginationData.total"
          :css="table_css.pagination"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
        <div class="text-center" v-if="rowCount <=0 || rowCount === null">
          <slot name="empty"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import getOrganization from "@/helpers/getOrganization";
import CustomersFilters from "@/components/Filters/Customers/Index";
import CustomersFiltersDropdown from "@/components/Filters/Customers/More";
import VuetablePagination from "@/components/Layouts/VueTablePagination";
import getPaginationData from "@/helpers/pagination";

export default {
  components: {
    CustomersFilters,
    CustomersFiltersDropdown,
    VuetablePagination
  },
  data() {
    return {
      getOrganization,
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      api: `${process.env.VUE_APP_BASE_URI}/customers?include=primaryContact`,
      columns: [
        {
          name: "__checkbox",
          titleClass: "center aligned",
          dataClass: "center aligned",
          cvisible: false,
          active: true
        },
        {
          name: "company_name",
          title: "Customer Name",
          cvisible: true,
          active: true
        },
        {
          name: "primary_contact",
          title: "Primary Contact",
          cvisible: true,
          active: true,
          callback: s => {
            return s ? s.first_name + " " + s.last_name : "N/A";
          }
        },
        {
          name: "full_phone_number",
          title: "Phone Number",
          cvisible: true,
          active: true,
          sortable: true,
          callback: s => {
            return s ? s : 'N/A'
          }
        },
        {
          name: "email",
          title: "Email Address",
          cvisible: true,
          active: true,
          sortable: true,
          callback: s => {
            return s ? s : 'N/A'
          }
        },
        {
          name: "source",
          title: "Source",
          cvisible: true,
          active: true,
          callback: s => {
            return s && s.name ? s.name.toUpperCase() : 'N/A';
          }
        },
        {
          name: "__slot:actions",
          title: "Actions",
          active: true,
          cvisible: false
        }
      ],
      moreParams: {},
      paginationData: {},
      filterColumns: []
    };
  },
  methods: {
    getPaginationData,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    gotoCustomer(e) {
      this.$router.push({
        name: "customers-show",
        params: { id: e.id }
      });
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
    },
    setFilters(d, l) {
      if (this.moreParams && this.moreParams[l] == "") {
        delete this.moreParams[l];
      }
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onChangePage(page) {
      this.moreParams.page = page;
      this.setFilters();
    },
    refreshTable() {
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find(s => s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    }
  },
  computed: {
    industries() {
      return this.$store.getters["global/industries"];
    },
    sources() {
      return this.$store.getters["global/customer_sources"];
    }
  },
  created() {
    this.industries.length || this.$store.dispatch("global/getCustomerSources");
    this.sources.length || this.$store.dispatch("global/getIndustries");
  },
  watch: {
    $route(to, from) {
      this.refreshTable();
    }
  }
};
</script>

