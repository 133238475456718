function waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
    var startTimeInMs = Date.now();
    (function loopSearch() {
      if (document.querySelector(selector) != null) {
        callback();
        return;
      }
      else {
        setTimeout(function () {
          if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs)
            return;
          loopSearch();
        }, checkFrequencyInMs);
      }
    })();
  }

export default {
    /**
     * This hack is for the quill editor so it doesn't mess up the html structure of the data
     */
    methods: {
        _html(val) {
        
        waitForElementToDisplay(".ql-editor", function() {
          
            let el = document.querySelector('.ql-editor');
            el.innerHTML = val || '';

        },1000,9000);
    }
}
}