import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const employees = {
    namespaced: true,
    state: {
        employees: [],
        users: [],
        bank_accounts: [],
        bank_account: {},
        user: {},
        employee: {},
        employee_users: []
    },
    actions,
    getters,
    mutations
}
