export default {
    task: state => state.task,
    tasks_pagination: state => state.tasks && state.tasks.data && state.tasks.data.meta && state.tasks.data.meta.pagination ? state.tasks.data.meta.pagination : {},
    tasks_total: state => state.tasks && state.tasks.data && state.tasks.data.meta && state.tasks.data.meta.pagination && state.tasks.data.meta.pagination.total ? state.tasks.data.meta.pagination.total : 0,
    time_logs: state => state.time_logs && state.time_logs.data ? state.time_logs.data : [],
    all_time_logs: state => state.all_time_logs && state.all_time_logs.data ? state.all_time_logs.data : [],
    total_time_logs: state => state.time_logs && state.time_logs.data && state.time_logs.data.meta && state.time_logs.data.meta.totalHours ? state.time_logs.data.meta.totalHours : 0,
    expenses: state => state.expenses,
    tasks: state => state.tasks && state.tasks.data && state.tasks.data.data  && state.tasks.data.data.filter(s => s.parent_task_id == null),
    tasks_simple: state => state.tasks &&  state.tasks.data && state.tasks.data.data.length > 0 ? state.tasks.data.data.map((t) => {
        return  {
            id:t.id,
            name:t.name,
            project_id: t.project_id
        }
    }) : [],
    comments: state => state.comments && state.comments.length > 0 ? state.comments.map((data) => {
        return {
            id: data.id,
            comment: data.comment,
            parent_comment_id: data.parent_comment_id,
            created_at: data.created_at,
            user: {
                id: data.user.data.id,
                first_name: data.user.data.first_name,
                last_name: data.user.data.last_name
            }
        };
    }) : [],
    attachments : state => state.attachments,
    metrics: state => state.metrics,
    table_type : state => state.table_type,
    cached: state => state.cached,
}
