<template>
  <div class>
    <div class="table-wrapper">
      <transition name="slide-fade">
        <div v-show="table_mode && table_mode.type && table_mode.type.name === 'Table'">
          <vuetable
            ref="vuetable"
            :api-url="api"
            @vuetable:cell-clicked="gotoTask"
            :first-page="0"
            :append-params="moreParams"
            :http-options="{ headers:{ 'Authorization' : 'Bearer ' + $auth.token(),'xorg' : getOrganization.organization_id() } }"
            @vuetable:loaded="loadTable"
            :fields="columns.filter(s => s.active === true)"
            :row-class="onRowClass"
            data-path="data"
            pagination-path
            :no-data-template="rowCount === null ? 'Loading data' : 'No Data Available'"
            @vuetable:pagination-data="onPaginationData"
          >
            <template slot="sn" class="xc" slot-scope="props">
              <router-link
                :to="{name: 'tasks-show', params: {id: props.rowData.id}}"
              >{{props.rowIndex + 1}}</router-link>
            </template>
            <template slot="status" slot-scope="props">
              <div class="status">Nothing goes here {{props.rowData.id}}</div>
            </template>
          </vuetable>
        </div>
      </transition>
      <vuetable-pagination
        v-show="paginationData.total"
        :css="table_css.pagination"
        ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
      <div class="text-center" v-if="rowCount <=0 || rowCount === null">
        <slot name="empty"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import { pagination } from "@/helpers/pagination.js";
import ldash from "@/helpers/ldash.js";
import getOrganization from "@/helpers/getOrganization";
import VuetablePagination from "@/components/Layouts/VueTablePagination";
import Vue from "vue";
import getPaginationData from "@/helpers/pagination";

export default {
  components: {
    VuetablePagination
  },
  data() {
    return {
      getOrganization,
      ldash,
      loading: false,
      table_mode: {
        type: { name: "Table" }
      },
      table_group_mode: {
        name: "Project Title",
        slug: "project_id",
        include: "project"
      },
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      api: "", //TODO: No endpoit to fetch tasks by opportunity
      columns: [
        {
          name: "__checkbox",
          titleClass: "center aligned",
          dataClass: "center aligned",
          cvisible: false,
          table_group: false,
          active: true,
          callback: table_group => {
            console.log(table_group);
          }
        },
        {
          title: "Task Title",
          name: "name",
          cvisible: true,
          active: true,
          table_group: true,
          sortable: true
        },
        {
          name: "status",
          title: "Status",
          cvisible: true,
          active: true,
          table_group: false,
          callback: d => {
            let x = this.$refs.vuetable.tableFields.find(
              s => s && s.name && s.name === "status"
            );
            x["dataClass"] = `status`;
            return d && d.name ? d.name : "";
          }
        },
        {
          name: "description",
          title: "Description",
          cvisible: true,
          active: true,
          table_group: false,
          callback: d => {
            return "Lorem ipsum dlssljkfjs fgjkskljgkjskg..";
          }
        },
        {
          name: "employee",
          title: "Date Completed",
          cvisible: true,
          active: true,
          sortable: true,
          table_group: false,
          callback: s => {
            return "Completed Date";
          }
        },
      ],
      moreParams: {
        include: "employee,status,project,milestone"
      },
      paginationData: {},
      filterColumns: []
    };
  },
  methods: {
    customTableOptionLabel({ name, svg }) {
      return name && svg ? `${svg} – ${name}` : "";
    },
    onRowClass(dataItem, index) {
      let status =
        dataItem.status && dataItem.status.name ? dataItem.status.name : " ";
      status = status
        .toLowerCase()
        .replace(" ", "_")
        .replace("/", "");
      return status;
    },
    gotoTask(e) {
      this.$router.push({
        name: "tasks-show",
        params: { id: e.id }
      });
    },
    loadTable() {
      let d = this.$refs.vuetable && this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
      if (d && d.length > 0) {
        this.rowCount = d;
        const result = {};
        let key = this.table_group_mode && this.table_group_mode.slug;
        this.rowCount = d.forEach(item => {
          if (!result[item[key]]) {
            result[item[key]] = [];
          }
          result[item[key]].push(item);
        });
        this.rowCount = result;
      }
    },
    loadTableByGroup() {
      let d = this.$refs.vuetable.tableData;
      if (d && d.length > 0) {
        let key = "project_id";
      }
    },
    onCellClicked(data, field, event) {
      this.$refs.vuetable2.toggleDetailRow(data.id);
    },
    setFilters(d) {
      for (let [key, value] of Object.entries(this.moreParams)) {
        if (typeof value === "object") {
          //append id to params
          this.moreParams[key + "_id"] = value.id;
        }
        if (value === "") {
          delete this.moreParams[key];
        }
      }
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onChangePage(page) {
      this.moreParams.page = page;
      this.setFilters();
    },
    refreshTable() {
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find(s => s && s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    },
    onPaginationData(paginationData) {
      delete paginationData.data;
      this.paginationData = getPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(this.paginationData);
    }
  },
  computed: {},
  mounted() {},
  watch: {
    $route(to, from) {
      this.refreshTable();
    }
  }
};
</script>

