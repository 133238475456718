<template>
  <b-modal
    :no-close-on-backdrop="true"
    @hide="cancel"
    :visible="showCollaboratorsModal"
    id="collaboratorsModal"
  >
    <button @click="cancel" class="close-mox">
      <i class="zmdi zmdi-close"></i>
    </button>
    <template v-slot:modal-header>
      <h6 class="pt-2 pb-2 pr-3 h6 text-bold text-opacity-palee f-24">
        Collaborators
      </h6>
    </template>
    <div class="d-block text-center pb-4">
      <div class="input-dropdown">
        <ul>
          <li class="row pb-2">
      
              <div class="col-lg-6">
                <h6 class="text-bold text-opacity-pale">First Name  <b>*</b></h6>
                <input
                  type="text"
                  v-model="body.user.first_name"
                  v-validate="`${isNaN(optional(body, 'user.id', 1)) ? 'required' : 'min:2'}`"
                  name="first_name"
                  class="form-control auth-input d-input"
                  placeholder="First name"
                  data-vv-as="First name"
                />
                <small
                  class="form-text text-danger"
                  v-show="errors.has('first_name')"
                  >{{ errors.first("first_name") }}</small
                >
              </div>
              <div class="col-lg-6">
                <h6 class="text-bold text-opacity-pale">
                  Last Name
                  <b>*</b>
                </h6>
                <input
                  type="text"
                  v-validate="`${isNaN(optional(body, 'user.id', 2)) ? 'required' : 'min:2'}`"
                  v-model="body.user.last_name"
                  name="last_name"
                  class="form-control auth-input d-input"
                  placeholder="Last name"
                  data-vv-as="Last name"
                />
                <small
                  class="form-text text-danger"
                  v-show="errors.has('last_name')"
                  >{{ errors.first("last_name") }}</small
                >
              </div>
        
          </li>
          <li class="w-70">
            <multiselect
              :taggable="true"
              tag-placeholder="Add this as new user"
              @tag="addUser"
              v-model="body.user"
              id="email"
              ref="collaboratorsInput"
              label="name"
              track-by="email"
              placeholder="Type to search User"
              open-direction="bottom"
              :options="users"
              :multiple="false"
              :searchable="true"
              :loading="isLoading"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="300"
              :limit="3"
              :limit-text="limitText"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
              @search-change="asyncFind"
              :show-labels="false"
              data-vv-as="Enter Email"
            ></multiselect>
             <small
                  class="form-text text-danger"
                  v-show="errors.has('email')"
                  >{{ errors.first("email") }}</small
                >
          </li>
          <li class="ml-2">
            <button
              @click="save"
              :disabled="loading"
              class="btn btn-sm btn-primary pl-3 pr-3"
            >
              <div v-if="!loading">Send Invite</div>
              <div class="pr-3 pl-3" v-else>
                <div
                  class="spinner-grow spinner-grow-sm text-light"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </button>
          </li>
        </ul>
      </div>
      <div
        style="
          width: 100%;
          height: 1px;
          background: #d7e1ea;
          position: absolute;
          margin-left: 0px !important;
          left: 0;
          margin-top: 20px;
        "
      ></div>
      <div class="content-block mt-5 ml-2">
        <div
          :key="collaborator.id"
          v-for="collaborator in collaborators"
          class="row mt-3"
        >
          <div class="col-sm-8">
            <div class="row">
              <div class="col-sm-1">
                <div class="user-img">
                  {{
                    `${collaborator.first_name.charAt(
                      0
                    )}${collaborator.last_name.charAt(0)}`
                  }}
                </div>
              </div>
              <div class="col-sm-7">
                <div class="user-details">
                  <h5 class="text-bold text-opacity-pale h6">
                    {{ `${collaborator.first_name} ${collaborator.last_name}` }}
                  </h5>
                  <h6 class="text-opacity">{{ collaborator.email }}</h6>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="user-role-select">
              <select
                v-model="collaborator.role_id"
                @change="setCollaboratorRole(collaborator, selected)"
                :disabled="true"
              >
                <option>Select Role</option>
                <option :key="role.id" v-for="role in roles" :value="role.id">
                  {{ role.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="collaborators.length == 0">
          <h6 class="text-opacity-pale">
            No Collaborator added this Project yet!
          </h6>
        </div>
      </div>
    </div>
    <template v-slot:modal-footer>
      <div class="footer-content pt-2 pb-2" v-show="false">
        <small class="pl-3">
          <span class="pr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 30 30"
            >
              <path
                d="M13.723 18.654l-3.61 3.609c-2.316 2.315-6.063 2.315-8.378 0-1.12-1.118-1.735-2.606-1.735-4.188 0-1.582.615-3.07 1.734-4.189l4.866-4.865c2.355-2.355 6.114-2.262 8.377 0 .453.453.81.973 1.089 1.527l-1.593 1.592c-.18-.613-.5-1.189-.964-1.652-1.448-1.448-3.93-1.51-5.439-.001l-.001.002-4.867 4.865c-1.5 1.499-1.5 3.941 0 5.44 1.517 1.517 3.958 1.488 5.442 0l2.425-2.424c.993.284 1.791.335 2.654.284zm.161-16.918l-3.574 3.576c.847-.05 1.655 0 2.653.283l2.393-2.389c1.498-1.502 3.94-1.5 5.44-.001 1.517 1.518 1.486 3.959 0 5.442l-4.831 4.831-.003.002c-1.438 1.437-3.886 1.552-5.439-.002-.473-.474-.785-1.042-.956-1.643l-.084.068-1.517 1.515c.28.556.635 1.075 1.088 1.528 2.245 2.245 6.004 2.374 8.378 0l4.832-4.831c2.314-2.316 2.316-6.062-.001-8.377-2.317-2.321-6.067-2.313-8.379-.002z"
              ></path>
            </svg>
          </span>
          Anyone with the link has Guest privileges
        </small>
        <small class="text-right float-right">Copy Link</small>
      </div>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped>
.close-mox {
  position: absolute;
  top: -95px;
  right: 0;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  background: #f5f5f5;
  border: 0px;
}
.modal-dialog {
  display: none;
  max-width: 500px !important;
  margin-top: 22% !important;
}
#email {
  // display: none;
}
.input-dropdown {
  padding-left: 0px;
  text-align: left;
}
.btn-sm {
  height: 36px !important;
}
.content-block {
  max-height: 500px;
  overflow-y: scroll;
}
select {
  // -webkit-appearance: none;
  background: transparent;
  &:focus,
  &:hover {
    outline: 0px !important;
  }
}
.user-img {
  height: 45px;
  width: 45px;
  background: #c1c8cc;
  border-radius: 45px;
  padding-top: 10px;
}
.user-details {
  text-align: left;
  padding-left: 30px;
}

.user-role-select {
  select {
    height: 40px;
    width: 100px;
    // -webkit-appearance: none;
    border: 0px;
    font-size: 14px;
    background: none;
    margin-top: 10px;
  }
}
.w-70 {
  width: 75%;
  border: solid 1px #dce1e7;
  padding-right: 10px;
  border-radius: 2px;
  height: 39px;
  font-size: 14px;

  input,
  select {
    border: 0px !important;
    font-size: 14px !important;
  }
  select {
    // -webkit-appearance: none;
    background: transparent;
    padding-right: 10px;
    &:focus,
    &:hover {
      outline: 0px !important;
    }

    &::after {
      content: "00sfs";
    }
  }
}
</style>

<script>
import handleError from "@/helpers/error";
import { optional, string } from "@/helpers/random";

export default {
  props: ["showCollaboratorsModal", "collaborators"],
  data() {
    return {
      selected: null,
      loading: false,
      isLoading: false,
      users: [],
      roleChanging: false,
      body: {
        user: {
          first_name: '',
          last_name: '',
        },
        
      },
      extraUser: null,
    };
  },
  computed: {
    roles() {
      return this.$store.getters["global/roles"];
    },
    project() {
      return this.$store.getters["projects/project"];
    },
  },
  methods: {
    handleError,
    optional,
    string,
    setDeleteState() {
      this.$emit("trigger");
    },
    deleteProject() {
      this.$emit("deleteAction", true);
    },
    close() {
      this.$emit("cancel");
    },
    cancel() {
      this.$emit("cancel");
    },
    addUser(tag) {
      let users = JSON.parse(JSON.stringify(this.users));
      let user = {
        id: string(6),
        name: `${this.body.user.first_name || 'New'} ${this.body.user.last_name || 'User'}`,
        first_name: this.body.user.first_name || 'New',
        last_name: this.body.user.last_name || 'User',
        email: tag,
        
      };
      this.extraUser = user;
      users.push(user);
      this.body.user = user;
      this.users = users;
    },
    save() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      this.$validator.validateAll().then((valid) => {
        if (valid) {
          if (this.body.user.first_name.match(/^[0-9]+$/) != null) {
            return this.errors.add({
              field: "first_name",
              msg: "First name cannot be all numbers",
            });
          } else if (this.body.user.last_name.match(/^[0-9]+$/) != null) {
            return this.errors.add({
              field: "last_name",
              msg: "Last name cannot be all numbers",
            });
          }
          else if (!re.test(this.body.user.email)) {
            return this.errors.add({
              field: "email",
              msg: "Valid Email must be specified",
            });
          }
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
  
      let data = {
        email: this.body.user.email,
        first_name: this.body.user.first_name,
        last_name: this.body.user.last_name,
        organization_id: this.project.organization_id,
        created_by: this.$auth.user().id,
        project_id: this.project.id,
      };
      this.$store
        .dispatch("projects/createUpdateInvite", data)
        .then((result) => {
          this.$toast.success("🤝 Collaborator invited successfully", {
            position: "top-right",
            duration: 3000,
          });
          this.body = {user:{first_name:'', last_name:''}};
        })
        .catch((error) => {
          let message = "Error occurred while inviting collaborator";
          this.handleError(this, error, message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setCollaboratorRole(collaborator, role_id) {
      this.roleChanging = true;
      collaborator.role_id = role_id;
      collaborator.project_id = this.project.id;
      this.$store
        .dispatch("projects/createUpdateCollaborator", collaborator)
        .then((result) => {
          this.$toast.success("🚮 Collaborator role updated successfully", {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((error) => {
          let message = "Error occurred while updating collaborator role";
          this.handleError(this, error, message);
        })
        .finally(() => {
          this.roleChanging = false;
        });
    },
    limitText(count) {
      return `and ${count} other users`;
    },
    asyncFind(data = null) {
      if (data === "" || data.length < 3) return;
      this.isLoading = true;
      let keyword = data && data != "" ? data : null;
      let handler = keyword
        ? this.$store.dispatch("employees/searchUsers", keyword)
        : this.$store.dispatch("employees/index");
      handler
        .then((data) => {
          
          let users = keyword ? data.map(s => {
            return {
                id: s.id,
            name: `${s.first_name} ${s.last_name}`,
            first_name: s.first_name,
            last_name: s.last_name,
            email: s.email
            }
          }) : data.map(s => {
            return {
                id: s.user_id,
            name: `${optional(s, 'user.data.first_name', '')} ${optional(s, 'user.data.last_name', '')}`,
              first_name: optional(s, 'user.data.first_name', ''),
            last_name: optional(s, 'user.data.last_name', ''),
            email: s.email
            }
          }) ;

          this.users = users.filter((s) => s.id !== this.$auth.user().id);

          if (this.extraUser) {
            this.extraUser.first_name = this.extraUser.first_name || this.body.user.first_name 
            this.extraUser.last_name = this.extraUser.last_name || this.body.user.last_name 
            this.users.push(this.extraUser);
          }
       
        })
        .catch((e) => console.log("fetch users error", e))
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.$refs &&
      this.$refs.collaboratorsInput &&
      this.$refs.collaboratorsInput.$refs.search.setAttribute(
        "autocomplete",
        "off"
      );
  },
  created() {
    this.roles.length || this.$store.dispatch("global/getRoles");
    this.asyncFind(false);
  },
};
</script>
