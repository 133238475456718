export default function handleError(vm, error, message = 'Failed to perform action. Please try again.') {
    if (error && error.data && error.data.status_code == 422) {
        message = error.data.message;
        let errors = error.data.errors;
        
        Object.values(errors).forEach(function(key) {
            let msg = key.join(" ");
            vm.errors.add({
                field: key,
                msg: msg
            });

            message += ` ${msg}`
        });
    }
    vm.$toast.error(message, {
        position: "top-right",
        duration: 3000
    });
}
