<template>
  <div class="overlay" :class="{ blur: !loaded }">
    <log-time
      v-if="showLogTime"
      @trigger="setTimeLog"
      @cancelTimeLog="cancelTimeLog"
      :showLogTime="showLogTime"
      :singleTask="task"
      :timeLog="timeLog"
      ref="delete"
    ></log-time>
    <log-expense
      v-if="task.id"
      @trigger="setExpenseLog"
      @cancelExpense="cancelExpense"
      :showExpenseForm="showExpenseForm"
      :expense="expense"
      :task="task"
      ref="delete"
    ></log-expense>
    <div @click="closeTask" class="container task-wrapperx"></div>
    <div class="container back-btn" v-if="task && task.project">
      <router-link
        :to="
          $route.name.includes('project-milestone')
            ? {
                name: 'milestones',
                params: { mid: task.project_milestone_id },
              }
            : $route.path.includes('milestones')
            ? {
                name: 'projects-show-milestones',
                params: { mid: task.project_milestone_id },
              }
            : $route.path.includes('users-task')
            ? {
                name: 'users-show-tasks',
              }
            : { name: 'tasks-index', params: { id: task.project_id } }
        "
        class="pl-1"
        id="backLink"
        style="color: white !important; opacity: 0.7"
      >
        <button class="mr-2 btn-icon">
          <span class="text-bold text-opacity-pale">
            <span>
              <i
                class="zmdi zmdi-long-arrow-left"
                style="font-size: 20px; color: #72809d"
              ></i>
            </span>
          </span>
        </button>
        <small>{{
          `Back to ${
            $route.path.includes("milestones") ? "Milestones" : "Tasks"
          }`
        }}</small>
      </router-link>
    </div>
    <div class>
      <div class="container task-wrapper">
        <div class="row pt-4">
          <div class="col-sm-12">
            <div class="mb-3" v-show="$route.query && $route.query.from">
              <router-link
                :to="{ path: `/tasks/preview/${$route.query.from}` }"
              >
                <button
                  style="
                    background: #fff7f7;
                    border: none;
                    border-radius: 5px;
                    height: 33px;
                  "
                  class="
                    d-flex
                    p-1
                    pt-2
                    pl-2
                    pr-2
                    align-items-center
                    justify-content-between
                  "
                >
                  <span
                    class="
                      d-flex
                      flex-row
                      align-items-center
                      justify-content-between
                    "
                  >
                    <h6 class="mr-2 pt-1">
                      <i
                        style="font-size: 20px"
                        class="zmdi zmdi-chevron-left text-opacity"
                      ></i>
                    </h6>
                    <h6 class="h6">Go back</h6>
                  </span>
                </button>
              </router-link>
            </div>
            <div class="row">
              <div class="col-sm-8">
                <button @click="setTimeLog" class="btn-action pr-3 pl-2 mr-2">
                  <span class="text-bold h7 text-opacity-pale">
                    <span class="pr-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g fill="none" fill-rule="evenodd">
                          <rect
                            width="24"
                            height="24"
                            fill="#FFF"
                            rx="4"
                          ></rect>
                          <g
                            stroke="#72809D"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            transform="translate(5 3)"
                          >
                            <path
                              d="M11.233 5.641L12.656 4.219M12.375 3.938L12.938 4.5M6.75 3.938L6.75.563M8.438.563L5.063.563"
                            ></path>
                            <circle cx="6.75" cy="10.688" r="6.75"></circle>
                            <path
                              d="M6.75 6.188L6.75 6.188M6.75 6.188c-.155 0-.281.125-.281.28 0 .156.126.282.281.282.155 0 .281-.126.281-.281 0-.156-.126-.282-.281-.282M6.75 14.625c-.155 0-.281.126-.281.281 0 .156.126.281.281.281.155 0 .281-.125.281-.28 0-.156-.126-.282-.281-.282h0M2.531 10.406c-.155 0-.281.126-.281.281 0 .156.126.282.281.282.156 0 .281-.126.281-.281 0-.156-.125-.282-.28-.282h0M10.969 10.406c-.156 0-.281.126-.281.281 0 .156.125.282.28.282.156 0 .282-.126.282-.281 0-.156-.126-.282-.281-.282h0M3.767 7.425c-.114 0-.216.068-.26.173-.044.105-.02.226.06.307.11.11.289.11.399 0 .11-.11.11-.288 0-.397-.053-.053-.124-.083-.199-.083h0M9.733 13.39c-.075 0-.146.03-.199.082-.095.095-.11.244-.035.355.075.112.218.155.342.104.124-.052.194-.184.168-.315-.026-.132-.142-.226-.276-.226h0M3.767 13.39c-.114 0-.216.068-.26.173-.044.105-.02.226.06.307.072.07.175.099.272.073.098-.026.173-.102.2-.199.026-.097-.002-.2-.073-.272-.053-.053-.124-.082-.199-.082h0M9.932 7.506c-.053-.053-.124-.083-.2-.083h0c-.074 0-.145.03-.198.083-.11.11-.11.288 0 .398.11.11.288.11.398 0 .11-.11.11-.288 0-.398"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </span>
                    Log Time
                  </span>
                </button>
                <button @click="setExpenseLog" class="btn-action pr-3 pl-2">
                  <span class="text-bold h7 text-opacity-pale">
                    <span class="pr-1">
                      <svg
                        height="24px"
                        version="1.1"
                        viewBox="0 0 24 24"
                        width="24px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <!-- Generator: sketchtool 61 (101010) - https://sketch.com -->
                        <title>7367F8A9-B1B4-4E36-A24F-AEB0121877BF</title>
                        <desc>Created with sketchtool.</desc>
                        <g
                          id="Work-Page"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            id="24-Tasks-view,-detailed-task"
                            transform="translate(-1100.000000, -24.000000)"
                          >
                            <g
                              id="Group-2-Copy-2"
                              transform="translate(1100.000000, 24.000000)"
                            >
                              <rect
                                id="Rectangle"
                                fill="#FFFFFF"
                                x="0"
                                y="0"
                                width="24"
                                height="24"
                                rx="4"
                              ></rect>
                              <g
                                id="accounting-bills"
                                transform="translate(0.000000, 4.000000)"
                                stroke="#72809D"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                              >
                                <path
                                  d="M4.125,3.5 C4.33210678,3.5 4.5,3.66789322 4.5,3.875 C4.5,4.08210678 4.33210678,4.25 4.125,4.25 C3.91789322,4.25 3.75,4.08210678 3.75,3.875 C3.75,3.66789322 3.91789322,3.5 4.125,3.5"
                                  id="Path"
                                ></path>
                                <path
                                  d="M16.875,8.75 C17.0821068,8.75 17.25,8.91789322 17.25,9.125 C17.25,9.33210678 17.0821068,9.5 16.875,9.5 C16.6678932,9.5 16.5,9.33210678 16.5,9.125 C16.5,8.91789322 16.6678932,8.75 16.875,8.75"
                                  id="Path"
                                ></path>
                                <rect
                                  id="Rectangle"
                                  x="0.75"
                                  y="0.5"
                                  width="19.5"
                                  height="12"
                                  rx="1.5"
                                ></rect>
                                <circle
                                  id="Oval"
                                  cx="10.5"
                                  cy="6.5"
                                  r="3"
                                ></circle>
                                <path
                                  d="M23.25,5 L23.25,14 C23.25,14.8284271 22.5784271,15.5 21.75,15.5 L5.25,15.5"
                                  id="Path"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                    Log Expense
                  </span>
                </button>
              </div>
              <div class="col-sm-4 float-right text-right">
                <router-link
                  :to="{
                    name: 'task-show-full',
                    params: { id: this.$route.params.tid },
                  }"
                >
                  <button
                    class="mr-2 btn-icon"
                    v-b-tooltip.hover
                    title="Open Task in Fullpage"
                  >
                    <span class="text-bold text-opacity-pale">
                      <span>
                        <i
                          class="zmdi zmdi-open-in-new"
                          style="font-size: 20px; color: #72809d"
                        ></i>
                      </span>
                    </span>
                  </button>
                </router-link>
                <router-link
                  v-if="!task.parent_task_id"
                  :to="{
                    name: `${
                      this.$route.name.includes('project-milestone')
                        ? 'project-milestone-'
                        : $route.path.includes('milestones')
                        ? 'milestone-'
                        : $route.path.includes('users-task')
                        ? 'user-'
                        : ''
                    }tasks-preview-subtasks-new`,
                    params: { id: this.$route.params.tid },
                  }"
                >
                  <button
                    class="btn-icon mr-2"
                    v-b-tooltip.hover
                    title="Create Sub Task"
                  >
                    <span class="text-bold text-opacity-pale">
                      <span class>
                        <i
                          class="zmdi zmdi-close"
                          style="
                            font-size: 20px;
                            color: #72809d;
                            transform: rotate(45deg);
                          "
                        ></i>
                      </span>
                    </span>
                  </button>
                </router-link>
                <button
                  class="btn-icon"
                  @click="closeTask"
                  v-b-tooltip.hover
                  title="Close task"
                >
                  <span class="text-bold text-opacity-pale">
                    <span class>
                      <i
                        class="zmdi zmdi-close"
                        style="font-size: 20px; color: #72809d"
                      ></i>
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div class="col-sm-12 mt-3 pl-4">
            <div class>

              <div v-if="!isEditingTask" class="d-flex flex-row justify-content-between">
                <div>
                  <h6 class="mb-0 text-medium text-opacity-pale" style="font-size: 13px !important; color: black; opacity: 0.5">
                    {{ task.reference ? task.reference : "-" }}
                  </h6>
                  <h5 class="text-gray mt-0"  style="position: relative">
                    <span>
                      {{ task.name }}
                      <sup class="mt-1">
                        <button
                          class="ml-1 mt-2"
                          @click="isEditingTask = true"
                          type="button"
                          style="
                            height: 25px;
                            width: 25px;
                            border-radius: 25px;
                            background: #f5f5f5;
                            border: none;
                          "
                        >
                          <i class="zmdi zmdi-edit"></i>
                        </button>
                      </sup>
                    </span>
                  </h5>

                  <h5 class="mb-0 text-small text-opacity-pale mb-2" style="font-size: 13px !important; font-weight: 500; color: black">
                    Project: 
                    <span style="color: black; opacity: 0.5">{{ optional(task, 'project.name', 'N/A')}}</span> 
                  </h5>
                </div>
                <span>
                  <h6 class="mb-2 text-medium text-opacity-pale" style="font-size: 13px !important; color: black; opacity: 0.5">
                    Priority
                  </h6>
                  <sup>
                    <priority-badge
                      v-if="task && task.id"
                      :id="task.priority_id"
                      :task_id="task.id"
                    ></priority-badge>
                  </sup>
                </span>
              </div>

              <div class="d-flex flex-column editable" v-else>
                <textarea
                  name="task_name"
                  ref="editableField"
                  class="editable-field text-gray pt-2 pb-2 w-100"
                  v-model="task.name"
                  maxlength="100"
                ></textarea>
                <div class="d-flex justify-content-end mt-1">
                  <button
                    class="editable-save pl-3 pr-3"
                    @click="updateTaskInfo"
                  >
                    <h6 class="h6 text-opacity-pale text-bold mb-0">Save</h6>
                  </button>
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-sm-12 mt-0 pl-4">
            <div class="row">
              <div class="col-sm-5">
                <multiselect
                  track-by="id"
                  label="name"
                  :disabled="taskStatusUpdating"
                  :class="generateTaskClass()"
                  v-model="task.status"
                  :options="taskStatuses"
                  @input="changeTaskStatus"
                  :show-labels="false"
                >
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <i
                          class="zmdi zmdi-chevron-down pt-1"
                          style="font-size: 20px"
                        ></i>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-12 mt-4">
            <div class="tab-wrapper tab-wrapper-mobile">
              <ul class="tabs">
                <router-link
                  :class="
                    $route.name ==
                    `${
                      $route.path.includes('milestones')
                        ? 'milestone-'
                        : $route.path.includes('users-task')
                        ? 'user-'
                        : ''
                    }tasks-preview-show`
                      ? 'router-link-exact-active'
                      : ''
                  "
                  :to="{
                    name: `${
                      this.$route.name.includes('project-milestone')
                        ? 'project-milestone-'
                        : $route.path.includes('milestones')
                        ? 'milestone-'
                        : $route.path.includes('users-task')
                        ? 'user-'
                        : ''
                    }tasks-preview-show`,
                  }"
                >
                  <li>General</li>
                </router-link>
                <router-link
                  :to="{
                    name: `${
                      this.$route.name.includes('project-milestone')
                        ? 'project-milestone-'
                        : $route.path.includes('milestones')
                        ? 'milestone-'
                        : $route.path.includes('users-task')
                        ? 'user-'
                        : ''
                    }tasks-preview-subtasks`,
                  }"
                  v-if="!task.parent_task_id"
                >
                  <li>
                    Subtasks
                    <span
                      class="badge ml-2"
                      style="background: #e61919; height: 15px; color: white"
                      >{{ task.subtasks && task.subtasks.length }}</span
                    >
                  </li>
                </router-link>
                <router-link
                  :to="{
                    name: `${
                      this.$route.name.includes('project-milestone')
                        ? 'project-milestone-'
                        : $route.path.includes('milestones')
                        ? 'milestone-'
                        : $route.path.includes('users-task')
                        ? 'user-'
                        : ''
                    }tasks-preview-comments`,
                  }"
                >
                  <li>Comments</li>
                </router-link>
                <router-link
                  :to="{
                    name: `${
                      this.$route.name.includes('project-milestone')
                        ? 'project-milestone-'
                        : $route.path.includes('milestones')
                        ? 'milestone-'
                        : $route.path.includes('users-task')
                        ? 'user-'
                        : ''
                    }tasks-preview-attachments`,
                  }"
                >
                  <li>Attachments</li>
                </router-link>
              </ul>
            </div>
            <div class="mt-1 pl-2 scrollable" style="height: 65vh !important">
              <attachments
                v-show="$route.name.includes('tasks-preview-attachments')"
              ></attachments>
              <router-view
                @setTimeLog="setTimeLog"
                @setExpenseLog="setExpenseLog"
              ></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.editable {
  position: relative;
  .editable-field {
    font-size: 1.25rem;
    border: 2px solid #f5f5f5;
    outline: none;
    resize: none;
    height: min-content;

    &:focus {
      outline: none;
    }
    &:hover {
      border: 2px solid #f5f5f5;
    }
  }
  .editable-save {
    visibility: hidden;
    background: #e4e4e4;
    border: none;
    height: 35px;
    border-radius: 10px;
    position: absolute;
    bottom: -35px;
    z-index: 5;

    &:hover {
      background: #2c3f58;
      color: white !important;
      h6 {
        color: white !important;
      }
    }
  }
  &:focus-within {
    .editable-save {
      visibility: visible;
    }
  }
}
.overlay {
  position: fixed;
  background: linear-gradient(rgba(20, 20, 20, 0.8), rgba(20, 20, 20, 0.8));
  height: 100%;
  top: 0;
  width: 100%;
  z-index: 4;
  left: 0;

  .scrollable {
    max-height: 98vh;
    overflow-y: scroll;
  }
  .back-btn {
    width: 200px;
    position: absolute;
    z-index: 100;
    margin-top: 30px;
    left: 0;
    top: 0;

    #backLink {
      &:hover {
        opacity: 1 !important;
      }
    }
  }
  .task-paned {
    background: linear-gradient(rgba(20, 20, 20, 0.8), rgba(20, 20, 20, 0.8));
    height: inherit;

    .task-wrapper {
      max-width: 100vw !important;
      width: 100vw !important;

      .scrollable {
        width: 600px !important;
        margin: auto;
      }
    }
  }
  .task-wrapper {
    background: white;
    position: absolute;
    width: 520px;
    right: 0;
    height: 100vh;
  }
  .task-wrapperx {
    position: absolute;
    height: 100vh;
    width: 100%;
  }
}
.text-gray {
  color: #2c3f58;
}
button {
  &.btn-icon {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    border: 0;
    &:hover {
      background: #f7f7f7;
    }
  }
  &.btn-action {
    height: 40px;
    border-radius: 5px;
    border: 0px;
    &:hover {
      background: #f7f7f7;
    }
  }
}
</style>

<script>
import LogTime from "@/components/Modals/Time";
import LogExpense from "@/components/Modals/Expense";
import Literals from "@/helpers/dashboard/literals";
import Attachments from "@/components/Attachments/Index";
import PriorityBadge from "@/components/Badges/Priority";
import handleError from "@/helpers/error";
// import Priority from "@/components/Badges/Priority.vue";
import {optional} from "@/helpers/random";

export default {
  title: "Show | Tasks",
  components: { LogTime, LogExpense, Attachments, PriorityBadge },
  data() {
    return {
      Literals,
      loaded: false,
      isEditingTask: false,
      showLogTime: false,
      showExpenseForm: false,
      taskStatusUpdating: false,
      currentTaskStatus: {},
      timeLog: {},
      expense: {},
    };
  },
  methods: {
    handleError,
    optional,
    closeTask() {
      if (this.$route.name.includes("project-milestone")) {
        this.$router.push({
          name: "milestones",
          params: { mid: this.task.project_milestone_id },
        });
      } else if (this.$route.name.includes("milestone")) {
        this.$router.push({
          name: "projects-show-milestones",
        });
      } else if (this.$route.name.includes("user-tasks")) {
        this.$router.push({
          name: "users-show-tasks",
        });
      } else if (this.$route.name == "projects-show-tasks") {
        this.$router.push({
          name: "projects-show-milestones",
          params: { mid: this.task.project_milestone_id },
        });
      } else {
        this.$router.push({ name: "tasks-index" });
      }
    },
    updateFieldStatus(status) {
      this.isEditingTask = status;
      if (this.isEditingTask) {
        document.querySelector(".editable-field").spellcheck = true;
      } else {
        // textarea.focus();
        let textarea = document.querySelector(".editable-field");
        textarea.spellcheck = false;
        textarea.removeAttribute("spellcheck");
      }
    },
    updateTaskInfo() {
      let data = {
        id: this.task.id,
        name: this.task.name,
        description: this.task.description,
      };
      this.$store.dispatch("tasks/createUpdate", data).then((s) => {
        this.refreshTasks();
        this.$toast.success(Literals.TASK_UPDATE_SUCCESS, {
          position: "top-right",
          duration: 3000,
        });
        this.isEditingTask = false;
        // this.$store.dispatch("tasks/index");
      });
    },
    setTimeLog(timeLog) {
      this.timeLog = timeLog;
      this.showLogTime = true;
    },
    setExpenseLog(expense) {
      this.expense = expense;
      this.showExpenseForm = true;
    },
    refreshTasks() {
      let filters = this.$route.params.filterBy;
      let filteredParams = this.$_.omitBy(filters, _.isNil);
      let params = new URLSearchParams(filteredParams).toString();
      this.$store.dispatch("tasks/index", params);
    },
    cancelExpense(expense) {
      this.expense = expense;
      this.showExpenseForm = false;
    },
    cancelTimeLog() {
      this.timeLog = {};
      this.showLogTime = false;
    },
    changeTaskStatus($event) {
      this.taskStatusUpdating = true;
      this.$store
        .dispatch("tasks/createUpdate", {
          id: this.task.id,
          task_status_id: this.task && this.task.status && this.task.status.id,
        })
        .then(async (s) => {
          this.$store
            .dispatch("tasks/getSingle", this.$route.params.tid)
            .then(async (y) => {
              await this.refreshTasks();
              this.$toast.success(
                `Task status successfully changed to ${this.task.status.name}`,
                {
                  position: "top-right",
                  duration: 3000,
                }
              );
            }),
            (this.taskStatusUpdating = false);
        })
        .catch(() => {
          this.$toast.error("Failed to update status!", {
            position: "top-right",
            duration: 3000,
          });
          this.taskStatusUpdating = false;
        });
    },
    generateTaskClass() {
      return this.task.status
        ? this.task.status.name
            .toLowerCase()
            .replace(" ", "_")
            .replace("/", "") + " table-select task-status "
        : "table-select task-status";
    },
  },
  computed: {
    taskStatuses() {
      return this.$store.getters["global/task_statuses"];
    },
    task() {
      return this.$store.getters["tasks/task"];
    },
  },
  created() {
    Promise.all([
      this.$store.dispatch("tasks/getSingle", this.$route.params.tid),
      this.taskStatuses.length || this.$store.dispatch("global/getTaskStatuses"),
    ]).finally(() => {
      this.loaded = true;
    });
  },
  watch: {
    $route(from) {
      Promise.all([
        this.$store.dispatch("tasks/getSingle", this.$route.params.tid),
      ]);
    },
  },
};
</script>
