<template>
  <div class="home">
    <headers>
      <div slot="breadcrumbs">
        <breadcrumbs :items="breadCrumbs"></breadcrumbs>
      </div>
      <div slot="title">
        <h2>Customers</h2>
      </div>
      <div slot="action">
<!--        <router-link :to="{name: 'projects-create'}">-->
<!--          <button class="btn text-primary btn-ds btn-outline mr-2">-->
<!--            <span class="text-main">Import Customers</span>-->
<!--          </button>-->
<!--        </router-link>-->
        <router-link :to="{name: 'customers-create'}">
          <button class="btn btn-ds">
            <span class="pr-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path
                  fill="#FFF"
                  d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 4c-.552 0-1 .448-1 1v2H5c-.552 0-1 .448-1 1s.448 1 1 1h2v2c0 .552.448 1 1 1s1-.448 1-1V9h2c.552 0 1-.448 1-1s-.448-1-1-1H9V5c0-.552-.448-1-1-1z"
                ></path>
              </svg>
            </span>
            Create New Customer
          </button>
        </router-link>
      </div>
    </headers>

    <customers-table>
      <div slot="empty" class="pt-4">
        <h6 class="text-opacity">You have not created any customer yet.</h6>
        <h6 class="text-opacity">Click on the blue button to get started.</h6>
        <div class="mt-4">
          <router-link :to="{name: 'customers-create'}">
            <button class="btn btn-ds">
              <span class="pr-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <path
                    fill="#FFF"
                    d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 4c-.552 0-1 .448-1 1v2H5c-.552 0-1 .448-1 1s.448 1 1 1h2v2c0 .552.448 1 1 1s1-.448 1-1V9h2c.552 0 1-.448 1-1s-.448-1-1-1H9V5c0-.552-.448-1-1-1z"
                  ></path>
                </svg>
              </span>
              Create New Customer
            </button>
          </router-link>
        </div>
      </div>
    </customers-table>
    <router-view></router-view>
  </div>
</template>

<script>
import Headers from "@/components/Layouts/Headers";
import Breadcrumbs from "@/components/Breadcrumbs/Index";
import CustomersTable from "@/components/Tables/Customers/Index";
export default {
  title: 'Index | Customers',
  components: {
    Headers,
    CustomersTable,
    Breadcrumbs
  },
  data() {
    return {
      project_meta: {},
      breadCrumbs: [
        {
          text: "Home",
          href: "#"
        },
        {
          text: "All Customers",
          active: true
        }
      ]
    };
  },
  mounted() {}
};
</script>
