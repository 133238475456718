<template>
  <table class="vuetable ui blue selectable celled stackable attached table">
    <thead data-v-5cc42bfc>
      <tr data-v-5cc42bfc>
        <th data-v-5cc42bfc class="vuetable-th-checkbox-id center aligned">
          <input data-v-5cc42bfc type="checkbox">
        </th>
        <!---->
        <!---->
        <!---->
        <!---->
        <!---->
        <!---->
        <th data-v-5cc42bfc class="vuetable-th-slot-sn">S/N</th>
        <!---->
        <!---->
        <th data-v-5cc42bfc id="_name" class="vuetable-th-name">Task Title</th>
        <th data-v-5cc42bfc id="_status" class="vuetable-th-status">Status</th>
        <th data-v-5cc42bfc id="_description" class="vuetable-th-description">Description</th>
        <th data-v-5cc42bfc id="_employee" class="vuetable-th-employee">Assigned To</th>
        <th data-v-5cc42bfc id="_created_at" class="vuetable-th-created_at">Date Created</th>
        <th
          data-v-5cc42bfc
          id="_estimated_hours"
          class="vuetable-th-estimated_hours"
        >Estimated Hours</th>
        <!---->
        <!---->
        <th data-v-5cc42bfc class="vuetable-th-slot-actions">Actions</th>
        <!---->
        <!---->
      </tr>
    </thead>
    <tbody data-v-5cc42bfc class="vuetable-body">
      <tr data-v-5cc42bfc item-index="0" render="true" class="in_progress">
        <!---->
        <!---->
        <td data-v-5cc42bfc class="vuetable-checkboxes center aligned">
          <input data-v-5cc42bfc type="checkbox">
        </td>
        <!---->
        <!---->
        <!---->
        <!---->
        <!---->
        <!---->
        <td data-v-5cc42bfc class="vuetable-slot">
          <a href="/tasks/1/" class data-v-5cc42bfc>1</a>
        </td>
        <td data-v-5cc42bfc class>Dennis Mcmillan</td>
        <td data-v-5cc42bfc class>Testing/Review</td>
        <td data-v-5cc42bfc class>	Dignissimos deserunt</td>
        <td data-v-5cc42bfc class>Kimberly Dicki</td>
        <td data-v-5cc42bfc class>2020-04-02 16:54:48</td>
        <td data-v-5cc42bfc class>0hrs</td>
        <!---->
        <!---->
        <!---->
        <!---->
        <td data-v-5cc42bfc class="vuetable-slot">
          <a href="/tasks/1/" class data-v-5cc42bfc>
            <button class="btn-lightgray-round">
              <i class="zmdi zmdi-plus pr-2"></i>View Task
            </button>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

  <script>
export default {
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  methods: {
    onClick(event) {
      console.log("my-detail-row: on-click", event.target);
    }
  }
};
</script>