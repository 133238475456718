<template>
  <div>
    <div class="mt-2 upload-wrapper" @click="uploadErrors = ''">
      <vue-dropzone
        ref="attachments"
        id="dropzone"
        @vdropzone-sending="sendingEvent"
        :options="dropzoneOptions"
        :useCustomSlot="true"
        @vdropzone-files-added="setFileStatus"
        @vdropzone-total-upload-progress="setProgressStatus"
        @vdropzone-success="updateFileStatus"
      >
        <div class="dropzone-custom-content">
          <h6 class="h6">Drag-n-drop your file or</h6>
          <button class="btn btn-primary" style="width:100%">Choose File</button>
        </div>
      </vue-dropzone>
      <div class="progress-u-bar" v-if="uploading">
        <h6 class="h6">Uploading your file,Please hold on.</h6>
      </div>
      <div class="progress-u-bar" v-if="uploaded">
        <h6 class="h6">💪 File Successfully Uploaded</h6>
      </div>
      <div class="progress" v-show="uploading">
        <div
          class="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          :aria-valuenow="uploadNumber"
          aria-valuemin="0"
          aria-valuemax="100"
          :style="'width:' + uploadNumber + '%'"
        ></div>
      </div>
    </div>
    <div class="progress-u-bar mt-3" v-if="uploadErrors">
      <h6 class="h6" style="color:red">{{uploadErrors.message}}</h6>
    </div>
    <div class="mt-4">
      <h6 class="h6">Your Files</h6>
      <div class="mt-2">
        <div class v-if="uploadedFiles.length > 0">
          <div class="uploaded-images mt-3">
            <div class="u-img pl-3">
              <div class="row mt-2" :key="index" v-for="(att,index) in uploadedFiles">
                <div class="col-sm-10 main">
                  <span class="text-opacity-pale text-bold h6">{{att.upload && att.upload.filename}}</span>
                  <span
                    class="text-opacity-pale text-bold float-right text-right h6"
                  >{{toLocal(att.created_at).fromNow()}}</span>
                </div>
                <div class="col-sm-2 toggle">
                  <button class="btn-icon">
                    <i class="zmdi zmdi-more-vert" style="font-size:20px"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-4 assignee-card mt-4" v-else>
          <h6 class="h6">No File has been uploaded yet!</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped >
.u-img {
  .main {
    border-radius: 4px;
    background: #d7e1ea;
    padding: 10px;
  }
}
button {
  &.btn-icon {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    border: 0;
    &:hover {
      background: #f7f7f7;
    }
  }
  &.btn-action {
    height: 40px;
    border-radius: 5px;
    border: 0px;
    &:hover {
      background: #f7f7f7;
    }
  }
}
.assignee-card {
  background: #fafbfd;
  font-size: 14px;
  font-family: "sansMedium";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #254050;

  .as-card-img {
    height: 30px;
    width: 30px;
    border-radius: 30px;
  }
}
</style>

<script>
import { toLocal } from "@/helpers/date";
import getOrganization from "@/helpers/getOrganization";

export default {
  data() {
    return {
      loaded: false,
      uploading: false,
      uploaded: false,
      uploadNumber: 0,
      uploadedFiles: [],
      uploadErrors: []
    };
  },
  methods: {
    toLocal,
    fetchFiles() {
      return this.uploadedFiles.push(this.$refs.attachments.getAcceptedFiles());
    },
    setFileStatus(file) {
      this.uploading = true;
      this.uploaded = false;
      this.uploadNumber = 0;
    },
    updateUploadError(file, message, xhr) {
      this.uploadErrors = message;
      this.uploading = false;
      this.addMore = true;
      this.$refs.attachments.removeFile(file);
    },
    setProgressStatus(f, i, b) {
      this.uploadNumber = f;
    },
    updateFileStatus(f) {
      this.uploaded = true;
      this.uploading = false;
      f["created_at"] = Date.now();
      this.uploadedFiles.push(f);
    },
    sendingEvent(file, xhr, formData) {
      formData.append("paramName", "some value or other");
    }
  },
  computed: {
    dropzoneOptions() {
      return {
        url: `${this.$baseApi}/tasks/${this.$route.params.id}/attachments`,
        thumbnailWidth: 150,
        maxFilesize: 5,
        sendingMultiple: true,
        headers: {
          Authorization: "Bearer " + this.$auth.token(),
          xorg: getOrganization.organization_id()
        }
      };
    }
  },
  created() {
    //you can update the uploaded files array from value sent from server here
    //this.$store.dispatch('tasks/getAttachments', this.$route.params.id)
  }
};
</script>
