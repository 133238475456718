<template>
  <div :class="{ blur: !loaded }">
    <subTaskModal
      v-if="showSubTask"
      :showSubTaskModal="showSubTask"
      @close="cancelNewSubtask"
    ></subTaskModal>

    <div class="p-4 pt-4 pb-5">
      <div class="d-flex justify-content-end mb-4">
        <button @click="setAddNewSubtask" class="btn-action pr-3 pl-2 mr-2">
          <span class="text-bold h7 text-opacity-pale">
            <span data-v-7301eedf="" class="pl-2 pr-1"><svg data-v-7301eedf="" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 16 16"><path data-v-7301eedf="" fill="#3f92cc" d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 4c-.552 0-1 .448-1 1v2H5c-.552 0-1 .448-1 1s.448 1 1 1h2v2c0 .552.448 1 1 1s1-.448 1-1V9h2c.552 0 1-.448 1-1s-.448-1-1-1H9V5c0-.552-.448-1-1-1z"></path></svg></span>
            Add New SubTask
          </span>
        </button>
      </div>

      <div id="subtasks" class="kanban-board" v-if="subtasks.length > 0">
        <div
          class="kanban-board__status w-100"
          v-for="(sub, index) in subtasks"
          :key="index"
          :id="sub.status && sub.status.name.replace(' ', '').replace('/', '')"
        >
          <router-link
            :to="{
              name: 'tasks-preview-show',
              params: { tid: sub.id },
              query: { subtask: true, from: $route.params.tid },
            }"
          >
            <div class="kanban-board__tasks p-3">
              <div class="d-flex flex-row align-items-center">
                <div class="mr-3">
                  <button
                    class="kanban-board__tasks-profile"
                    v-b-tooltip.hover
                    :title="
                      optional(sub, 'employee.user', null)
                        ? sub.employee.user.first_name +
                          ' ' +
                          sub.employee.user.last_name
                        : '-'
                    "
                  >
                    {{
                      optional(sub, "employee.user", null)
                        ? sub.employee.user.first_name.charAt(0) +
                          sub.employee.user.last_name.charAt(0)
                        : "-"
                    }}
                  </button>
                </div>
                <div class="mr-3">
                  <h6 class="text-opacity-pale mb-0">{{ sub.name }}</h6>
                </div>
                <div class="mr-3">
                  <span class="badge">{{ sub.status && sub.status.name }}</span>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <h6 v-else class="h7 text-opacity text-opacity-pale text-center">
        No subtask as being created for this task yet
      </h6>
    </div>
  </div>
</template>

<style lang="scss" >
$done: green;
$inprogress: #cccc11;
$pending: red;
$cancelled: #714242;
$testing: #0a5685;
#subtasks {
  .kanban-board__tasks {
    border: 2px solid #f5f5f5;
    &:not(:last-child) {
      border-bottom: none !important;
      display: none;
    }
  }
  .badge {
    padding: 6px;
  }
  #NotStarted {
    .badge {
      background: $pending;
      color: white !important;
    }
  }
  #InProgress {
    .badge {
      background: $inprogress;
      color: white !important;
    }
  }
  #TestingReview {
    .badge {
      background: $testing;
      color: white !important;
    }
  }
  #Done {
    .badge {
      background: $done;
      color: white !important;
    }
  }
  .kanban-board__status {
    &:not(:last-child) {
      .kanban-board__tasks {
        border-bottom: none !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
    }
  }
}
</style>

<script>
import { optional } from "@/helpers/random";
import SubTaskModal from "@/components/Modals/SubTasks";
export default {
  title: "Tasks | Subtasks",
  components: { SubTaskModal },
  data() {
    return {
      optional,
      loaded: false,
      showSubTask: false,
    };
  },

  methods: {
    setAddNewSubtask() {
      this.showSubTask = true;
    },

    cancelNewSubtask() {
      this.showSubTask = false;
    },
  },

  computed: {
    subtasks() {
      return optional(this.$store.getters["tasks/task"], 'subtasks', []);
    },
  },

  created() {
    Promise.all([
      this.$store.dispatch("tasks/getSingle", this.$route.params.id),
    ]).finally(() => {
      this.loaded = true;
    });
  },
};
</script>