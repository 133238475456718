<template>
  <div>
    <transition name="slide-fade">
      <div class="filters-form" v-show="visibleFilter">
        <form class="d-form">
          <div class="row mt-3">
            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">Invoice Subject</h6>
              <div style="position:relative">
                <input
                  type="text"
                  v-model="params['subject']"
                  @input="setFilters('subject')"
                  class="form-control auth-input d-input"
                  placeholder="Invoice Subject"
                >
                <span class="close-icon" v-if="params && params.subject">
                  <i class="zmdi zmdi-close" @click="params['subject']='';setFilters('subject')"></i>
                </span>
              </div>
            </div>

            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">Project</h6>
              <div style="position:relative">
                <multiselect
                  @input="setFilters('project_id')"
                  class="no-caret-select"
                  :custom-label="opt => projects.find(x => x.id == opt).name"
                  :options="projects.map(type=>type.id)"
                  v-model="params['project_id']"
                  :show-labels="false"
                >
                  <template slot="caret">
                    <span class="close-icon" v-if="params && params.project_id">
                      <i
                        class="zmdi zmdi-close"
                        @click="params['project_id']='';setFilters('project_id')"
                      ></i>
                    </span>
                    <div v-else>
                      <div class="multiselect__select">
                        <i class="zmdi zmdi-chevron-down pt-1" style="font-size: 20px"></i>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>

            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">Customer</h6>
              <div style="position:relative">
                <multiselect
                  @input="setFilters('customer_id')"
                  class="no-caret-select"
                  :custom-label="opt => customers.find(x => x.id == opt).name"
                  :options="customers.map(type=>type.id)"
                  v-model="params['customer_id']"
                  :show-labels="false"
                >
                  <template slot="caret">
                    <span class="close-icon" v-if="params && params.customer_id">
                      <i
                        class="zmdi zmdi-close"
                        @click="params['customer_id']='';setFilters('customer_id')"
                      ></i>
                    </span>
                    <div v-else>
                      <div class="multiselect__select">
                        <i class="zmdi zmdi-chevron-down pt-1" style="font-size: 20px"></i>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>

            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">Created At Date (From)</h6>
              <div style="position:relative">
                <vc-date-picker
                  :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important; height:42px !important`,placeholder: `Start From date`}"
                  v-model="params['filter_start']"
                  @input="setFilters('filter_start');params['range_filter'] = 'created_at'"
                  name="end"
                />
                <span class="close-icon" v-if="params && params.filter_start">
                  <i class="zmdi zmdi-close" @click="params['filter_start']='';setFilters('filter_start')"></i>
                </span>
              </div>
            </div>

            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">- To</h6>
              <div style="position:relative">
                <vc-date-picker
                  :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important; height:42px !important`,placeholder: `End To date`}"
                  v-model="params['filter_end']"
                  @input="setFilters('filter_end');params['range_filter'] = 'created_at'"
                  name="end"
                />
                <span class="close-icon" v-if="params && params.filter_end">
                  <i class="zmdi zmdi-close" @click="params['filter_end']='';setFilters('filter_end')"></i>
                </span>
              </div>
            </div>

            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">Invoice Date</h6>
              <div style="position:relative">
                <vc-date-picker
                  :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important; height:42px !important`,placeholder: `End To date`}"
                  v-model="params['invoice_date']"
                  @input="setFilters('invoice_date');params['range_filter'] = 'invoice_date'"
                  name="end"
                />
                <span class="close-icon" v-if="params && params.invoice_date">
                  <i class="zmdi zmdi-close" @click="params['invoice_date']='';setFilters('invoice_date')"></i>
                </span>
              </div>
            </div>


            <!--            <div class="col-sm-12 col-lg-2">-->
            <!--              <h6 class="text-bold text-opacity-pale">- To</h6>-->
            <!--              <vc-date-picker-->
            <!--                :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important; height:42px !important`,placeholder: `End date`}"-->
            <!--                v-model="params['end_to']"-->
            <!--                @input="setFilters('end_to',params.end_to)"-->
            <!--                name="end"-->
            <!--              />-->
            <!--            </div>-->
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["params", "customers", "visibleFilter", "projects"],
  methods: {
    setFilters(name, val) {
      this.$emit("setFilters", this.params, name);
    }
  }
};
</script>
