import axios from 'axios';
import {
    updatePending
} from "@/helpers/axiosIntercept";

const API = process.env.VUE_APP_BASE_URI;
export default {
    addPaymentMethod({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/payment-sources/${data.id}/data`;
            let method = axios.post(url, data.payment_data);
            method.then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data);
                })
                .catch(error => reject(error))
                .finally(() => {
                    updatePending(url)
                });
        });
    },
    deletePaymentMethod({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/organizations/payment-sources/${id}`;
            let method = axios.delete(url);
            method.then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data);
                })
                .catch(error => reject(error))
                .finally(() => {
                    updatePending(url)
                });
        });
    },
    getAllPaymentMethods({
        commit
    }, params = '') {
        return new Promise((resolve, reject) => {
            let url = `${API}/payment-sources?${params}`;
            axios.get(url).then(({
                    data: {
                        data
                    }
                }) => {
                    // if (!data) data = [];
                    commit("SET_PAYMENT_METHODS", data);
                    resolve(data);
                })
                .catch(error => {
                    // commit("SET_USER_PAYMENT_METHODS", []);
                    reject(error)
                })
                .finally(() => {
                    updatePending(url)
                });
        });
    },
    getPaymentMethods({
        commit
    }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/organizations/payment-sources`;
            axios.get(url).then(({
                    data: {
                        data
                    }
                }) => {
                    // if (!data) data = [];
                    commit("SET_USER_PAYMENT_METHODS", data);
                    resolve(data);
                })
                .catch(error => {
                    commit("SET_USER_PAYMENT_METHODS", []);
                    reject(error)
                })
                .finally(() => {
                    updatePending(url)
                });
        });
    },
    getSinglePaymentMethod({
        commit
    },id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/payment-sources/${id}/data`;
            axios.get(url).then(({
                    data
                }) => {
                    // if (!data) data = [];
                    commit("SET_SINGLE_PAYMENT_METHOD", data && data.data);
                    resolve(data);
                })
                .catch(error => {
                    commit("SET_SINGLE_PAYMENT_METHOD", []);
                    reject(error)
                })
                .finally(() => {
                    updatePending(url)
                });
        });
    },
    removeCard({
        commit
    },id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/subscriptions/card/${id}`;
            axios.delete(url).then(({
                    data
                }) => {
                
                    resolve(data);
                })
                .catch(error => {
                 
                    reject(error)
                })
                .finally(() => {
                    updatePending(url)
                });
        });
    },
}
