<template>
  <div class>
    <div class="table-wrapper">
      <vuetable
              :api-url="api"
              :fields="columns"
              :http-options="{ headers:{ 'Authorization' : 'Bearer ' + $auth.token(),'xorg' : getOrganization.organization_id() } }"
              @vuetable:loaded="loadTable"
              @vuetable:pagination-data="onPaginationData"
              data-path="data"
              pagination-path=""
              :no-data-template="rowCount === null ? 'Loading data' : 'No Data Available'"
              ref="vuetable"
      >
          <template slot="cost" slot-scope="props">
              {{props.rowData.currency && props.rowData.currency.symbol}}{{props.rowData.project_cost}}
          </template>

      </vuetable>
      <vuetable-pagination v-show="paginationData.total"
                           :css="table_css.pagination"
                           ref="pagination"
      ></vuetable-pagination>
      <div class="text-center" v-if="rowCount <=0 || rowCount === null">
        <slot name="empty"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import getOrganization from "@/helpers/getOrganization";
import VuetableCss from "@/helpers/vuetable_styling.js";
import getPaginationData from "@/helpers/pagination.js";
import VuetablePagination from "@/components/Layouts/VueTablePagination";

export default {
    components: {VuetablePagination},
    data() {
        return {
            columns: [
                {
                    name: "name",
                    title: "Project Title",
                    sortField: "name",
                    cvisible: true,
                    active: true
                },
        {
          name: "customer",
          title: "Customer",
          sortField: "customer_id",
          cvisible: true,
          active: true,
          callback: d => {
              return d.data && d.data.company_name;
          }
        },
                {
                    name: "opportunity_stage.name",
                    title: "Stage",
                    sortField: "opportunity_stage_id",
                    cvisible: true,
                    active: true,
                },
        {
          name: "hourly_rate",
          title: "Project Hourly Rate",
          cvisible: true,
          active: false
        },
                {
                    name: "__slot:cost",
                    title: "Cost",
                    cvisible: true,
                    active: false,
                    callback: s => {
                        return s;
                    }
                }
            ],
            api: `${this.$baseApi}/opportunities?customer_id=${this.$route.params.id}&include=customer,employee,opportunity_stage,billing_type,currency`,
            rowCount: null,
            table_css: VuetableCss,
            getOrganization,
            paginationData: {},
        };
  },
  methods: {
    getPaginationData,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(this.paginationData)
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
    },
  }
};
</script>

