<template>
  <div class>
    <payment-users-table></payment-users-table>
  </div>
</template>

<script>
import PaymentUsersTable from "@/components/Tables/Payments/PayrollUsers";
export default {
  components: { PaymentUsersTable }
};
</script>
