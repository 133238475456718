export default {
    customers: state => state.customers,
    customers_simple: state => state.customers.map((c) => {
        return {
            id: c.id,
            name:c.company_name,
            currency: c.currency_id,
            hourly_rate: c.standard_hourly_rate,
        }
    }),
    customer: state => state.customer,
    projects: state => state.projects,
    tasks: state => state.tasks,
    attachments: state => state.attachments,
    invoices: state => state.invoices,
    opportunities: state => state.opportunities,
    contacts: state => state.contacts,
    contact: state => state.contact,
}
