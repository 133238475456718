<template>
  <div>
    <div class="table">
      <div class="table-wrapper">
        <div
          id="expensesTable"
          style="padding-left:0px !important"
          class="no-padding container-fluid"
        >
          <vuetable
            ref="vuetable"
            :css="table_css"
            :api-url="api"
            :first-page="0"
            :append-params="moreParams"
            :http-options="{ headers:{ 'Authorization' : 'Bearer ' + $auth.token(),'xorg' : getOrganization.organization_id() } }"
            @vuetable:loaded="loadTable"
            :fields="columns.filter(s => s.active === true)"
            data-path="data"
            pagination-path
            :no-data-template="rowCount === null ? 'Loading data' : 'No Data Available'"
            @vuetable:pagination-data="onPaginationData"
          >
            <template slot="sn" slot-scope="props">
              <router-link
                :to="{name: 'projects-show-general', params: {id: props.rowData.id}}"
              >{{props.rowIndex + 1}}</router-link>
            </template>
            <template
              slot="amount"
              slot-scope="props"
            >{{props.rowData.currency && props.rowData.currency.symbol}} {{parseFloat(props.rowData.cost).toFixed(2)}}</template>
            <template
              slot="billed_cost"
              slot-scope="props"
            >{{props.rowData.currency && props.rowData.currency.symbol}} {{parseFloat(props.rowData.billed_cost).toFixed(2)}}</template>
            <template slot="task" slot-scope="props">
              <router-link
                v-if="props.rowData.task"
                :to="{name: 'tasks-show', params: {id: props.rowData.task.id}}"
              >{{props.rowData.task.name}}</router-link>
            </template>
          </vuetable>
          <vuetable-pagination
            v-show="paginationData.total"
            :css="table_css.pagination"
            ref="pagination"
            class="pl-2 pt-3 pr-2"
            @vuetable-pagination:change-page="onChangePage"
          ></vuetable-pagination>
          <div class="text-center" v-if="rowCount <=0 || rowCount === null">
            <slot name="empty"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import moment from "moment";
import getOrganization from "@/helpers/getOrganization";
import VuetablePagination from "@/components/Layouts/VueTablePagination";
import getPaginationData from "@/helpers/pagination";
import { toLocal } from "@/helpers/date";
export default {
  components: { VuetablePagination },
  props: {
    api: {
      type: String,
      default: `${process.env.VUE_APP_BASE_URI}/expenses`,
      required: false
    },
    moreParams: {
      required: false
    }
  },
  data() {
    return {
      getOrganization,
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      columns: [
        {
          name: "__checkbox",
          titleClass: "center aligned",
          dataClass: "center aligned",
          cvisible: false,
          active: true
        },
        {
          name: "task",
          title: "Task",
          cvisible: true,
          active: true,
          callback: t => {
            return t && t.data ? t.data.name : "N/A";
          }
        },
        {
          name: "start_time",
          title: "Date",
          cvisible: true,
          active: true,
          callback: s => {
            return toLocal(s).format("MM/DD/YYYY");
          }
        },
        {
          name: "hours",
          title: "Hours",
          cvisible: true,
          active: true,
          callback: h => {
            return h;
          }
        },
        {
          name: "__slot:amount",
          title: "Project Cost",
          sortField: "amount",
          cvisible: true,
          active: true
        },
        {
          name: "__slot:billed_cost",
          title: "Project Revenue",
          sortField: "billed_cost",
          cvisible: true,
          active: true
        },
        {
          name: "employee.data.user.data",
          title: "Employee",
          cvisible: true,
          active: true,
          callback: d => {
            return d ? `${d.first_name} ${d.last_name}` : "N/A";
          }
        },
        {
          name: "is_billable",
          title: "Is Billable?",
          cvisible: true,
          active: true,
          callback: s => {
            return s ? "Yes" : "No";
          }
        }
      ],
      paginationData: {}
    };
  },
  methods: {
    toLocal,
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
      this.$emit("getHoursLogged", this.$refs.vuetable.tablePagination);
    },
    setFilters(d) {
      let sm = this;
      this.moreParams.is_billable = this.moreParams.is_billable ? 1 : 0;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },

    refreshTable() {
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find(s => s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    }
  },
  computed: {},
  mounted() {},
  watch: {
    moreParams: {
      deep: true,
      handler(m) {
        this.setFilters();
      }
    }
    // deep: true
  }
};
</script>

