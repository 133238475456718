<template>
  <!-- Modal -->
  <b-modal @hide="close" :no-close-on-backdrop="true" id="expenseModal" ref="my-modal">
    <template v-slot:modal-header>
      <h2 class="pl-1 pt-3 pr-3 f-24">{{ 'Run Payroll' }}</h2>
      <button class="close" @click="close">
        Cancel
        <i class="zmdi zmdi-close-circle pl-2" style="font-size:20px"></i>
      </button>
    </template>
    <div class="d-block payroll-modal pl-1 pb-1">
      <div class="invoice-modal-card-content pt-1 pl-1 pr-1">
        <div class="invoice-modal-content">
          <form class="d-form">
            <div>
              <h6 class="text-bold text-opacity-pale">Select payroll period <b>*</b></h6>

              <ul class="invoice-select-list">
                <li
                  @click="setDateRange(index)"
                  :class="selected_period.name === period.name ? 'active' : ''"
                  v-for="(period,index) in invoice_period"
                  :key="index"
                >
                  <span class="pt-1">
                    <svg
                      v-if="selected_period.name === period.name"
                      fill="#0a5685"
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M0 0v24h24v-24h-24zm10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"
                      ></path>
                    </svg>
                    <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#E4E4E4"
                      width="17"
                      height="17"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24z"></path>
                    </svg>
                  </span>
                  <aside class="pl-3">
                    <h6 class="text-bold h7 text-opacity-pale">{{period.name}}</h6>
                    <div
                      class="mt-2"
                      v-if="period.can_select && selected_period.name === period.name"
                    >
                      <div class="row mt-3">
                        <div class="col-sm-6">
                          <vc-date-picker
                            :masks="{ input: ['YYYY-MM-DD']}"
                            :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: ` Start date`}"
                            v-model="period.from"
                            ref="startFrom"
                            data-vv-as="Start Date"
                          />
                          <small class="dpicker-icon">
                            <i class="zmdi zmdi-calendar-alt"></i>
                          </small>
                        </div>
                        <div class="col-sm-6">
                          <vc-date-picker
                            :masks="{ input: ['YYYY-MM-DD']}"
                            :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: ` End date`}"
                            v-model="period.to"
                            v-validate="'after:startFrom'"
                            ref="endAt"
                            data-vv-as="End Date"
                          />
                          <small class="dpicker-icon">
                            <i class="zmdi zmdi-calendar-alt"></i>
                          </small>
                        </div>
                      </div>
                    </div>

                    <div v-if="!period.can_select">
                      <small class="text-opacity">Period between {{period.from}} - {{period.to}}</small>
                    </div>
                  </aside>
                </li>
              </ul>
            </div>

            <div>
              <h6 class="text-bold text-opacity-pale">Payment Description <b>*</b></h6>
              
              <quill-editor
          ref="myQuillEditor"
          class="mt-1"
          v-validate="'required'"
          v-model="body.purpose"
           @ready="_html(body.purpose)"
          placeholder="Enter Payment Description"
         
        />
              <small
                class="form-text text-danger"
                v-show="errors.has('description')"
              >{{errors.first('description')}}</small>
            </div>
          </form>
        </div>
      </div>
    </div>
    <template v-slot:modal-footer>
      <dashboard-button @submit="save" :loading="loading" :text="'Save'"></dashboard-button>
    </template>
  </b-modal>
</template>

<script>
import DashboardButton from "@/components/Buttons/Dashboard";
import Literals from "@/helpers/dashboard/literals";
import { setTimeout } from "timers";
import handleError from "@/helpers/error";
import { toUTC, toLocal } from "@/helpers/date";
import moment from "moment";

export default {
  data() {
    return {
      Literals,
      activeSettlement: 1,
      loading: false,
      invoice_stage: 1,
      selected_period: {},
      body:{
        employee_type_id:null,
      }
    };
  },
  components: { DashboardButton },
  computed: {
    invoice_period() {
      let lastMonth = toLocal(moment().subtract(1, "M")).format("ll");
      let today = toLocal(moment()).format("ll");

      return [
        { name: "Last month", from: lastMonth, to: today },
        { name: "Custom period", from: "", to: "", can_select: true }
      ];
    },

  },
  methods: {
    handleError,
    toUTC,
    close() {
      return this.$router.go(-1);
    },
    save() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    setDateRange(index) {
      this.selected_period = this.invoice_period[index];
    },
    submit() {
      let data = {
        start_date: toUTC(this.selected_period.from).format("YYYY-MM-DD"),
        end_date: toUTC(this.selected_period.to).format("YYYY-MM-DD"),
        purpose : this.body.purpose,
        employee_type_id : this.body.employee_type_id
      };

      this.$store
          .dispatch("payrolls/createUpdate", data)
          .then(data => {

            this.$toast.success('Payroll Started', {
              position: "top-right",
              duration: 3000
            });
            this.$router.push({path:`/payments/${data.id}/process`})
          })
          .catch(error => {
            handleError(this, error, 'Error occurred while initializing payroll');
          })
          .finally(() => {
            this.loading = false;
          });
    },

  },
  mounted() {
    this.$refs["my-modal"].show();
    this.body.employee_type_id = this.$route.query && this.$route.query.employee_type_id;
  },
};
</script>
