<template>
  <div class>
    <delete-modal
      @trigger="deleteBankState"
      @cancel="showDeleteBank=false"
      @deleteAction="deleteBank"
      :showDelete="showDeleteBank"
      :name="showDeleteName"
      ref="delete"
    ></delete-modal>
    <div class="row ml-3">
      <div class="col-sm-12">
        <h6>
          <router-link
            :to="{name:'employee-add-bank'}"
            style="cursor:pointer"
            class="text-right float-right pr-1 text-bold text-main"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="#0a5685"
              viewBox="0 0 30 30"
            >
              <path
                d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z"
              ></path>
            </svg>Add Banking Info
          </router-link>
        </h6>
      </div>
      <div class="col-sm-12">
        <div class="row" v-if="banks.length >0">
          <div class="col-sm-12 col-lg-3 mt-2 mb-2" v-for="(bank, index) in banks" :key="index">
            <div class="card card-bank pl-4 pt-4 pb-4">
              <span class="text-bold text-opacity-pale">
                Banking Information ({{index + 1}}) <sup> <span style="background:lightgreen; padding:4px;border-radius:3px;font-size:13px !important; text-transform:capitalize" v-if="bank.is_primary">Primary</span></sup>
                <span
                  class="text-right float-right mr-3"
                  @click="deleteBankState(bank.id)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#72809D"
                    viewBox="0 0 30 30"
                  >
                    <path
                      d="M9 13v6c0 .552-.448 1-1 1s-1-.448-1-1v-6c0-.552.448-1 1-1s1 .448 1 1zm7-1c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm-4 0c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm4.333-8.623c-.882-.184-1.373-1.409-1.189-2.291l-5.203-1.086c-.184.883-1.123 1.81-2.004 1.625l-5.528-1.099-.409 1.958 19.591 4.099.409-1.958-5.667-1.248zm4.667 4.623v16h-18v-16h18zm-2 14v-12h-14v12h14z"
                    ></path>
                  </svg>
                </span>
                <span class="text-right float-right mr-3">
                  <router-link
                    class="btn-icon-sm float-right"
                    :to="{name:'employee-edit-bank', params:{bid:bank.id}}"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#72809D"
                      viewBox="0 0 30 30"
                    >
                      <path
                        d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"
                      ></path>
                    </svg>
                  </router-link>
                </span>
              </span>

              <div class="bank-pre-info mr-4 mt-3 pl-3 pr-3 pb-3 pt-1">
                <ul class="main">
                  <li class="r pt-2">
                    <button>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#72809D"
                        viewBox="0 0 30 30"
                      >
                        <path
                          d="M7 21h-4v-11h4v11zm7-11h-4v11h4v-11zm7 0h-4v11h4v-11zm2 12h-22v2h22v-2zm-23-13h24l-12-9-12 9z"
                        ></path>
                      </svg>
                    </button>
                  </li>
                  <li class="r pl-3 pt-1">
                    <div>
                      <small class="text-opacity" v-if="bank.is_primary">Primary Bank account</small>
                      <small class="text-opacity" v-else>Bank account</small>
                      <h6
                        class="text-bold text-opacity-pale"
                      >{{bank.bank}}, {{bank.bank_address.country.name}}</h6>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="bank-details mt-2">
                <div class="row mt-3">
                  <div class="col-sm-12 col-lg-5">
                    <h6 class="text-bold text-opacity-pale">Account name:</h6>
                  </div>
                  <div class="col-sm-12 col-lg-7">
                    <h6>{{bank.account_name}}</h6>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-sm-12 col-lg-5">
                    <h6 class="text-bold text-opacity-pale">Account no:</h6>
                  </div>
                  <div class="col-sm-12 col-lg-7">
                    <h6>{{bank.account_number}}</h6>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-sm-12 col-lg-5">
                    <h6 class="text-bold text-opacity-pale">Bank name:</h6>
                  </div>
                  <div class="col-sm-12 col-lg-7">
                    <h6>{{bank.bank}}</h6>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-sm-12 col-lg-5">
                    <h6 class="text-bold text-opacity-pale">Routing no:</h6>
                  </div>
                  <div class="col-sm-12 col-lg-7">
                    <h6>{{bank.routing_number}}</h6>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-sm-12 col-lg-5">
                    <h6 class="text-bold text-opacity-pale">SWIFT:</h6>
                  </div>
                  <div class="col-sm-12 col-lg-7">
                    <h6>{{bank.swift_code}}</h6>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-sm-12 col-lg-5">
                    <h6 class="text-bold text-opacity-pale">Bank location:</h6>
                  </div>
                  <div class="col-sm-12 col-lg-7">
                    <h6 v-html="resolveAddress(bank.bank_address)"></h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-center mt-10">
          <h6 class="h7 text-opacity-pale">No Bank has been added by this user yet </h6>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<style lang="scss" scoped>
.card-bank {
  border-radius: 4px;
  box-shadow: 0 7px 30px 0 rgba(217, 217, 217, 0.36);
  background-color: #ffffff;
  border: 0px;

  span,
  h6 {
    text-transform: uppercase;
    font-size: 14px !important;
  }
  .bank-pre-info {
    height: auto;
    border-radius: 4px;
    background-color: #f0f4f8;

    ul {
      display: flex;
      li.r {
        // display: flex;
        padding-right: 5px;
        button {
          background: white;
          border: 0px;
          outline: none;
          height: 30px;
          width: 30px;
          border-radius: 30px;
        }
        span,
        small,
        h6 {
          text-transform: none;
        }
      }
    }
  }
  .bank-details {
    h6 {
      text-transform: none;
    }
  }
}
</style>
<script>
import DeleteModal from "@/components/Modals/Delete";
import {resolveAddress} from "@/helpers/random";

export default {
  data() {
    return {
      editBank: {},
      showDeleteBank: false,
      showDeleteName: "",
      deleteId: ""
    };
  },
  components: { DeleteModal },
  computed: {
    banks() {
      return this.$store.getters["employees/bank_accounts"];
    },
    employee() {
      return this.$store.getters["employees/employee"];
    }
  },
  methods: {
    resolveAddress,
    deleteBank() {
      this.$store.dispatch("employees/deleteBank", this.deleteId).then(s => {
        this.$toast.success("Bank Successfully Deleted", {
          position: "top-right",
          duration: 3000
        });
        this.showDeleteBank = false;
        this.$store.commit(
          "employees/SET_BANK_ACCOUNTS",
          this.banks.filter(s => s.id !== this.deleteId)
        );
      });
    },
    deleteBankState(id) {
      this.deleteId = id;
      this.showDeleteName = "Bank";
      this.showDeleteBank = true;
    }
  },
  mounted() {}
};
</script>
