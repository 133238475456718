<template>
  <div class="home">
    <reports-modal></reports-modal>
  </div>
</template>

<script>
import Headers from "@/components/Layouts/Headers";
import Breadcrumbs from "@/components/Breadcrumbs/Index";
import ReportsTable from "@/components/Tables/Report/Index";
import ReportsModal from "@/components/Modals/Reporting";
export default {
  title: "New | Reporting",
  components: {
    // Headers,
    // ReportsTable,
    // Breadcrumbs,
    ReportsModal
  },
  data() {
    return {
      project_meta: {},
      breadCrumbs: [
        {
          text: "Home",
          href: "#"
        },
        {
          text: "All Reports",
          active: true
        },
        {
          text: "New Report",
          active: true
        }
      ]
    };
  },

};
</script>
