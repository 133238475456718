<template>
  <b-modal @hide="close" :no-close-on-backdrop="true" :visible="showDelete" id="deletemodal2" class="delete">
    <template v-slot:modal-header>
      <button class="close" @click="close">
        Cancel
        <i class="zmdi zmdi-close-circle pl-2" style="font-size:20px"></i>
      </button>
    </template>
    <div class="d-block text-center pl-3 pb-4">
      <h2 class="pt-2 f-24 text-left">Are you sure about deleting this {{name}} ?</h2>
      <h6 class="text-opacity mb-0 text-left">This entry is non recoverable once deleted. Click on delete to proceed</h6>
    </div>
    <template v-slot:modal-footer>
      <button
        class="btn btn-primary"
        @click="close"
        style="background: #E4E4E4;color: black;"
      >Cancel</button>
      <button class="btn btn-primary" @click="deleteProject" style="background:#730b0b">Delete</button>
    </template>
  </b-modal>
</template>

<style  scoped>
#deletemodal .modal-dialog {
  display: none;
  max-width: 300px !important;
  width: 300px !important;
}
</style>

<script>
export default {
  props: ["showDelete", "name"],
  methods: {
    setDeleteState() {
      this.$emit("trigger");
    },
    deleteProject() {
      this.$emit("deleteAction", true);
    },
    close() {
      this.$emit("cancel");
    }
  }
};
</script>
