<template>
  <div>
    <delete-modal
      @trigger="setDeleteState"
      @cancel="cancelDelete"
      @deleteAction="deleteItem"
      :showDelete="showDelete"
      :name="showDeleteName"
      ref="delete"
    ></delete-modal>
    <log-expense
      v-if="task.id"
      @trigger="setExpenseLog"
      @cancelExpense="cancelExpense"
      :showExpenseForm="showExpenseForm"
      :expense="expense"
      :task="task"
      ref="edit"
    ></log-expense>

    <div class="mt-5 table-wrapper">
      <table
        v-if="expenses.length > 0"
        class="in-tab-table vuetable ui blue selectable celled stackable attached table"
      >
        <thead>
          <tr>
            <th>Subject</th>
            <th>Amount</th>
            <th>Category</th>
            <th>Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr :key="index" v-for="(expense, index) in expenses">
            <td v-b-tooltip.hover :title="expense.subject">{{expense.subject}}</td>
            <td>{{`${expense.currency && expense.currency.symbol} ${expense.amount}`}}</td>
            <td>{{expense.category && expense.category.name}}</td>
            <td
              v-b-tooltip.hover
              :title="expense.description"
            >{{html2text(expense.description) | truncate(25)}}</td>
            <td>
              <button
                v-b-tooltip.hover
                title="Edit Expense"
                @click="setExpenseLog(expense)"
                class="btn-lightgray-round"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 24 24">
                  <path
                    d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z"
                  ></path>
                </svg>
              </button>

              <button
                v-b-tooltip.hover
                title="Delete Expense"
                class="btn-lightgray-round-secondary btn-danger ml-2"
                @click="setDeleteState('Expense',expense.id)"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 24 24">
                  <path
                    d="M9 13v6c0 .552-.448 1-1 1s-1-.448-1-1v-6c0-.552.448-1 1-1s1 .448 1 1zm7-1c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm-4 0c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm4.333-8.623c-.882-.184-1.373-1.409-1.189-2.291l-5.203-1.086c-.184.883-1.123 1.81-2.004 1.625l-5.528-1.099-.409 1.958 19.591 4.099.409-1.958-5.667-1.248zm4.667 4.623v16h-18v-16h18zm-2 14v-12h-14v12h14z"
                  ></path>
                </svg>
              </button>

              <button v-if="optional($auth.user(), 'org_role.role_id') == 1"
                      v-b-tooltip.hover
                      :disabled="loading"
                      title="Approve Expense"
                      class="btn-lightgray-round-secondary btn-danger ml-2"
                      @click="approveExpense(expense.id)"
                    >
                      <i class="zmdi zmdi-check"></i>
                    </button>
            </td>
          </tr>
        </tbody>
      </table>
      <h6
        v-else
        class="text-opacity h7 text-opacity-pale text-center"
      >No expense has been logged to this task yet</h6>
    </div>
  </div>
</template>

<script>
import { toLocal } from "@/helpers/date";
import truncate from "@/mixins/truncate";
import DeleteModal from "@/components/Modals/Delete";
import LogExpense from "@/components/Modals/Expense";
import {optional, html2text} from "@/helpers/random";

export default {
  mixins: [truncate],
  components: { DeleteModal, LogExpense },
  data() {
    return {
      loaded: false,
      showSelect: false,
      showAssigned: true,
      newEmployee: {},
      showDelete: false,
      showDeleteName: "",
      selectedDeleteId: "",
      showExpenseForm: false,
      expense: {},
      loading:false,

    };
  },
  methods: {
    toLocal,
    optional,
    html2text,
    setTimeLog(timeLog) {
      this.$emit("setTimeLog", timeLog);
    },
    setDeleteState(name, id) {
      this.showDelete = true;
      this.showDeleteName = name;
      this.selectedDeleteId = id;
    },
    cancelDelete() {
      this.showDelete = false;
    },
    deleteItem() {
      if (this.showDeleteName == "TimeLog") {
        //delete milestone
        this.$store
          .dispatch("tasks/deleteTimeLog", this.selectedDeleteId)
          .then(s => {
            this.$store.dispatch("tasks/getTimeLogs", this.$route.params.id);
            // this.$refs.vuetableMilestone.refresh();
            this.$toast.success("🚮 Timelog successfully deleted", {
              position: "top-right",
              duration: 3000
            });
            this.showDelete = false;
          });
      } else if (this.showDeleteName == "Expense") {
        //delete task
        this.$store
          .dispatch("tasks/deleteExpense", this.selectedDeleteId)
          .then(s => {
            this.$store.dispatch("tasks/getExpenses", this.$route.params.id);
            this.$toast.success("🚮 Expense Successfully Deleted", {
              position: "top-right",
              duration: 3000
            });
            this.showDelete = false;
          });
      }
    },
    setExpenseLog(expense) {
      this.$emit("setExpenseLog", expense);
      this.expense = expense;
      this.showExpenseForm = true;
    },
    cancelExpense(expense) {
      this.expense = expense;
      this.showExpenseForm = false;
    },
    approveExpense(id) {
      this.loading = true;
      this.$store.dispatch('tasks/approveExpense', id)
      .then(() => {
        this.$toast.success('Expense marked as approved', {
            position: "top-right",
            duration: 3000
          });
      }).catch(() => {
        this.$toast.error('Failed to approve expense. Please try again.', {
            position: "top-right",
            duration: 3000
          });
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  computed: {
    expenses() {
      return this.$store.getters["tasks/expenses"];
    },
    task() {
      return this.$store.getters["tasks/task"];
    },
  },
  created() {
    Promise.all([
      this.$store.dispatch("tasks/getSingle", this.$route.params.id),
      this.$store.dispatch("tasks/getTimeLogs", this.$route.params.id),
      this.$store.dispatch("tasks/getExpenses", this.$route.params.id),
      this.$store.dispatch("employees/index")
    ]).finally(() => {
      this.loaded = true;
    });
  }
};
</script>