import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const tasks = {
    namespaced: true,
    state: {
        task: [],
        time_logs: [],
        all_time_logs: [],
        expenses: [],
        tasks: [],
        comments: [],
        attachments: [],
        metrics:"",
        table_type: "",
        cached: []
    },
    actions,
    getters,
    mutations
}
