<template>
  <div class="badge">
    <multiselect
      :options="priorities"
      v-model="value"
      :id="value"
      :searchable="false"
      @input="updatePriority"
      track-by="id"
      label="name"
      :close-on-select="true"
      class="no-caret-select"
      :show-labels="false"
      placeholder="Pick a value"
    >
      <template slot="caret">
        <div>
          <div class="multiselect__select d-flex align-items-center">
            <i
              class="zmdi zmdi-chevron-down pt-1"
              v-if="!isUpdating"
              style="font-size: 20px"
            ></i>
            <span
              class="spinner-border spinner-border-sm"
              v-else
              role="status"
              style="margin-top: 5px"
              aria-hidden="true"
            ></span>
          </div>
        </div>
      </template>
    </multiselect>
  </div>
</template>

<style lang="scss" scoped>
</style>
<script>
import Literals from "@/helpers/dashboard/literals.js";
export default {
  data() {
    return {
      Literals,
      value: {},
      priorities: [
        { id: 1, name: "Low" },
        { id: 2, name: "Medium" },
        { id: 3, name: "High" },
        { id: 4, name: "Highest" },
      ],
      isUpdating: false,
    };
  },
  props: {
    id: {
      required: false,
      type: Number,
    },
    task_id: {
      required: false,
      type: Number,
    },
  },
  methods: {
    refreshTasks() {
      let filters = this.$route.params.filterBy;
      let filteredParams = this.$_.omitBy(filters, _.isNil);
      let params = new URLSearchParams(filteredParams).toString();
      this.$store.dispatch("tasks/index", params);
    },
    updatePriority() {
      let data = {
        id: this.task_id,
        priority_id: this.value && this.value.id,
      };
      this.isUpdating = true;
      this.$store
        .dispatch("tasks/createUpdate", data)
        .then((s) => {
          this.$toast.success(Literals.TASK_UPDATE_SUCCESS, {
            position: "top-right",
            duration: 3000,
          });
          this.refreshTasks();
        })
        .catch((err) => {
          this.$toast.error("Something went wrong while saving your entry!", {
            position: "top-right",
            duration: 3000,
          });
        })
        .finally((s) => (this.isUpdating = false));
    },
  },
  mounted() {
    if (this.id) {
      this.value = this.priorities.find((s) => s.id == this.id);
    }
    else {
      this.value = { id: 3, name: "High" }
    }
  },
};
</script>