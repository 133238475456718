<template>
  <div class="wrapper">
    <div class="row">
      <div class="col-sm-12 col-lg-6 wrapper-content-left">
        <div class="logo pl-4 mb-4">
          <img src="@/assets/img/leaf.svg">
        </div>
      </div>
      <div class="col-sm-12 col-lg-6 wrapper-content-right">
        <div class="content">
          <div class="row mt-5 pt-5 pl-5 pr-5">
            <div class="col-sm-12 pt-5 mt-2">
              <div class>
                <h2>{{Literals.FORGOT_PASSWORD_HEADER_CAPTION}}</h2>
                <small class="text-opacity">{{Literals.FORGOT_PASSWORD_SUBHEADER_CAPTION}}</small>
              </div>
            </div>

            <!-- alerts -->
            <div class="col-sm-12 col-lg-10 mt-4" v-show="errorMsg.length > 0">
              <error-alert :message="errorMsg"></error-alert>
            </div>
            <div class="col-sm-12 col-lg-10 mt-4" v-show="successMsg">
              <success-alert :message="successMsg"></success-alert>
            </div>

            <!-- end -->

            <div class="col-sm-12 pt-5 mt-2">
              <form @submit.prevent="reset">
                <div class="row auth-form">
                  <div class="col-sm-12 col-lg-10">
                    <h6>{{Literals.EMAIL_INPUT_CAPTION}} <b>*</b></h6>
                    <input
                      type="email"
                      v-validate="'required|email'"
                      name="email"
                      v-model="body.email"
                      class="form-control auth-input"
                      placeholder="Enter your email address"
                    >
                    <small
                      class="form-text text-danger"
                      v-show="errors.has('email')"
                    >{{errors.first('email')}}</small>
                  </div>

                  <div class="col-sm-12 col-lg-10 mt--6">
                    <Auth-Button :loading="loading" text="Request"/>
                  </div>

                  <div class="col-sm-12 col-lg-10 mt--6">
                    <h6>
                      <span class="text-opacity">Don't have a Leaf account?</span>
                      <router-link :to="{name: 'Register'}" class="pl-1">Sign up</router-link> | <span class="text-opacity">Already have an account?</span>
                      <router-link :to="{name: 'Login'}" class="pl-1">Login</router-link>
                    </h6>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthButton from "@/components/Buttons/Auth.vue";
import ErrorAlert from "@/components/Alerts/Error.vue";
import SuccessAlert from "@/components/Alerts/Success.vue";
import Literals from "@/helpers/auth/literals.js";
import {updatePending} from "../../helpers/axiosIntercept";

export default {
  title: 'Forgot Password',
  data() {
    return {
      Literals,
      passwordType: "password",
      body: {
        email: ""
      },
      errorMsg: [],
      successMsg: "",
      loading: false
    };
  },
  components: { AuthButton, ErrorAlert, SuccessAlert },
  methods: {
    togglePassword() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    reset() {
      this.errorMsg = [];
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
      let url = this.$baseApi + "/password/forgot";
      this.$http
        .post(url, this.body)
        .then(() => {
          this.loading = false;
          this.successMsg = this.Literals.FORGOT_PASSWORD_SUCCESS;
        })
        .catch(err => {
          this.loading = false;
          if (err.response.status === 422) {
            Object.values(err.response.data.errors).forEach(s => {
              s.map(j => {
                this.errorMsg.push(j);
              });
            });
          }
        }).finally( () => {
          updatePending(url)
      });
    }
  }
};
</script>
