<template>
  <div class="container-fluid" :class="{ blur: !apiLoaded }">
    <div class="table pb-4 pl-3 pr-4">
      <div class="table-header">
        <div class="row">
          <div class="col-sm-12 col-lg-4 float-left text-left">
            <small class="text-opacity"
              >{{
                this.$refs.vuetable &&
                  this.$refs.vuetable.selectedTo &&
                  this.$refs.vuetable.selectedTo.length
              }}
              Employees selected</small
            >
          </div>

          <div class="col-sm-12 col-lg-8 float-right text-right">
            <payment-filters
              @setFilters="setFilters"
              @showMoreFilters="showVisibleFilter"
              @removeColumnFromTable="removeColumnFromTable"
              :columns="columns"
              :params="moreParams"
              :visibleFilter="visibleFilter"
            ></payment-filters>
          </div>
        </div>
      </div>
      <div class="table-wrapper mt-4">
        <vuetable
          ref="vuetable"
          :first-page="0"
          :api-mode="false"
          :track-by="'employee_id'"
          :data="employees"
          @vuetable:loaded="loadTable"
          @vuetable:cell-clicked="gotoInvoice"
          :fields="columns.filter((s) => s.active === true)"
          pagination-path
          :no-data-template="
            rowCount === null ? 'Loading data' : 'No Data Available'
          "
          @vuetable:pagination-data="onPaginationData"
        >
          <template slot="sn" slot-scope="props">
            <router-link
              :to="{ name: 'users-show', params: { id: optional(allEmployees.find(s => s.id == props.rowData.employee_id), 'user_id', null) } }"
              >{{ props.rowIndex + 1 }}</router-link
            >
          </template>

          <template slot="hourly_rate" slot-scope="{ rowData }">
            <h6 class="text-bold h7">
              {{ rowData.currency_symbol }}
              {{
                rowData.hourly_rate == 0
                  ? rowData.salary_rate
                  : rowData.hourly_rate
              }}
            </h6>
          </template>
          <template slot="amount" slot-scope="{ rowData }">
            <h6 class="text-bold h7">
              {{ rowData.currency_symbol }}
              {{
                numberWithCommas(
                  parseFloat(rowData.amount_payable || 0).toFixed(2)
                )
              }}
            </h6>
          </template>
          <template slot="logged_hours" slot-scope="{ rowData }">
            <h6 class="text-bold h7">{{ rowData.hours_logged || 0 }}</h6>
          </template>
          <template slot="additional_earnings" slot-scope="{ rowData }">
            <div class="d-form" style="position: relative; width: 100%">
              <span id="discount_label">{{ rowData.currency_symbol }}</span>
              <input
                type="number"
                style="width: 150px; margin-top: 0px"
                name="discount"
                id="discount"
                v-model="rowData.additional_earnings"
                class="form-control auth-input d-input pl-4"
              />
            </div>
          </template>
          <template slot="payment_method" slot-scope="{ rowData }">
            <multiselect
              v-model="rowData.payment_source"
              track-by="id"
              :multiple="false"
              class="mobile_dropdown"
              return="id"
              label="name"
              name="payment_source"
              placeholder="Select Payment Method"
              :options="paymentSources"
              :show-labels="false"
              data-vv-as="Payment Method"
            ></multiselect>
          </template>
          <template slot="actions" slot-scope="props">
            <router-link
              :to="{
                name: 'users-show',
                params: { id: optional(allEmployees.find(s => s.id == props.rowData.employee_id), 'user_id', null) },
              }"
            >
              <button class="btn-lightgray-round">
                <i class="zmdi zmdi-plus pr-2"></i>View Employee
              </button>
            </router-link>
          </template>
        </vuetable>
        <vuetable-pagination
          v-show="paginationData.total"
          :css="table_css.pagination"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
        <div class="text-center" v-if="rowCount <= 0 || rowCount === null">
          <slot name="empty"></slot>
        </div>
      </div>
      <div class="mt-3 text-right">
        <button
          @click="cancelPayroll()"
          class="btn btn-outline outline-primary text-primary pl-3 pr-3 mr-3"
        >
          Cancel
        </button>
        <button
          :disabled="loading"
          @click="save"
          class="btn btn-outline outline-primary outline-primary__clicked pl-3 pr-3"
        >
          Save and Continue
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#discount_label {
  position: absolute;
  z-index: 2;
  margin-top: 10px;
  margin-left: 10px;
}
</style>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import getOrganization from "@/helpers/getOrganization";
import PaymentFilters from "@/components/Filters/Payments/Index";
import PaymentFiltersDropdown from "@/components/Filters/Payments/More";
import VuetablePagination from "@/components/Layouts/VueTablePagination";
import getPaginationData from "@/helpers/pagination";
import { numberWithCommas } from "@/helpers/number";
import { toUTC } from "@/helpers/date";
import handleError from "@/helpers/error";
import {optional} from "@/helpers/random";

export default {
  components: {
    PaymentFilters,
    VuetablePagination,
  },
  data() {
    return {
      getOrganization,
      allEmployees: [],
      apiLoaded: false,
      loaded: false,
      loading: false,
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      api: `${process.env.VUE_APP_BASE_URI}/employees`,

      columns: [
        {
          name: "__checkbox",
          title: "Checkbox",
          active: true,
          cvisible: true,
        },
        {
          name: "__slot:sn",
          title: "S/N",
          cvisible: true,
          active: true,
        },
        {
          name: "employee_id",
          title: "Employee",
          cvisible: true,
          active: true,
          callback: (s) => {
            let e = this.allEmployees.find((e) => e.id == s);
            return e && e.user && e.user.data
              ? `${e.user.data.first_name} ${e.user.data.last_name}`
              : "N/A";
          },
        },
        {
          name: "employee_type",
          title: "Type",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:hourly_rate",
          title: "Rate",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:logged_hours",
          title: "Logged Hours",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:additional_earnings",
          title: "Additional Earnings",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:amount",
          title: "Amount Due",
          cvisible: true,
          active: true,
          sortable: true,
        },
        {
          name: "__slot:payment_method",
          title: "Payment Method",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:actions",
          title: "Actions",
          active: true,
          cvisible: false,
        },
      ],
      moreParams: {
        include: "employeeType,defaultCurrency",
      },
      paginationData: {},
      filterColumns: [],
    };
  },
  methods: {
    numberWithCommas,
    getPaginationData,
    optional,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    gotoInvoice(e) {
      this.$router.push({
        name: "users-show",
        params: { id: optional(allEmployees.find(s => s.id == e.employee_id), 'user_id', null) },
      });
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;

      if (d && d.length && !this.loaded) {
        this.loaded = true;
        this.$refs.vuetable.selectedTo = d.map((s) => s.employee_id); // select all items by default
      }
    },
    setFilters(d) {
      for (let [key, value] of Object.entries(this.moreParams)) {
        if (typeof value === "object") {
          //append id to params
          this.moreParams[key + "_id"] = value.id;
        }
        if (value === "") {
          delete this.moreParams[key];
        }
      }
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onChangePage(page) {
      this.moreParams.page = page;
      this.setFilters();
    },
    refreshTable() {
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find((s) => s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    },
    save() {
      this.loading = true;
      let selectedEmployees = this.$refs.vuetable.tableData.filter((s) =>
        this.$refs.vuetable.selectedTo.includes(s.employee_id)
      );

      //console.log(selectedEmployees);
      let data = {
        id: this.$route.params.id,
        beneficiaries: selectedEmployees
          ? selectedEmployees.map((s) => {
              return {
                employee_id: s.employee_id,
                payment_source_id: s.payment_source
                  ? s.payment_source.id
                  : null,
              };
            })
          : [],
      };

      this.$store
        .dispatch("payrolls/initialize", data)
        .then((data) => {
         
          if (data.errors.length) {
            this.$toast.error(data.errors.join(" "), {
              position: "top-right",
              duration: 3000,
            });
          } else {
            this.$toast.success("Employees Added", {
              position: "top-right",
              duration: 3000,
            });
            this.$router.push({
              path: `/payments/${this.$route.params.id}/review`,
            });
          }
        })
        .catch((error) => {
           
          handleError(this, error, "Error occurred while adding employees");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancelPayroll() {
      let r = confirm("Are you sure you want to cancel payroll?");
      if (!r) return;
      this.$router.push({ name: "payments" });
    },
  },
  computed: {
    paymentSources() {
      return this.$store.getters["global/payment_sources"];
    },
    employees() {
      return this.$store.getters["payrolls/employees"];
    },
  },
  created() {
    
    Promise.all([
      this.paymentSources.length ||
        this.$store.dispatch("global/getPaymentSources", "is_payroll_enabled=1"),
      this.employees.length ||
        this.$store.dispatch("payrolls/getEmployees", this.$route.params.id),
      this.$store.dispatch("employees/index"),
    ]).finally(() => {
      this.allEmployees = this.$store.getters["employees/employees"];
      this.apiLoaded = true;
    
    });
  },
};
</script>
