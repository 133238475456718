<template>
  <div class="home">
    <headers class="pb-5">
      <div slot="breadcrumbs">
        <breadcrumbs :items="breadCrumbs"></breadcrumbs>
      </div>
      <div slot="title">
        <h2>Projects</h2>
      </div>
      <div slot="action" v-if="optional($auth.user(), 'org_role.role_id') == 1">
        <router-link :to="{name: 'projects-create'}">
          <button class="btn btn-ds">
            <span class="pr-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path
                  fill="#FFF"
                  d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 4c-.552 0-1 .448-1 1v2H5c-.552 0-1 .448-1 1s.448 1 1 1h2v2c0 .552.448 1 1 1s1-.448 1-1V9h2c.552 0 1-.448 1-1s-.448-1-1-1H9V5c0-.552-.448-1-1-1z"
                ></path>
              </svg>
            </span>
            Create New Project
          </button>
        </router-link>
      </div>
    </headers>
    <overview>
      <div :class="{blur:!Object.keys(project_meta).length}" slot="list">
        <small class="text-opacity">Project Overview</small>
        <div class="row pt-3">
          <div class="col-sm-12 col-lg-3">
            <div class="card p-4">
              <div class="card-content">
                <span class="float-left text-opacity">Total Projects</span>
                <span class="float-right">{{project_meta.all}}</span>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-3">
            <div class="card p-4">
              <div class="card-content">
                <span class="float-left text-opacity">Completed Projects</span>
                <span class="float-right">{{project_meta.done}}</span>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-3">
            <div class="card p-4">
              <div class="card-content">
                <span class="float-left text-opacity">Ongoing Projects</span>
                <span class="float-right">{{project_meta.ongoing}}</span>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-3">
            <div class="card p-4">
              <div class="card-content">
                <span class="float-left text-opacity">Pending Projects</span>
                <span class="float-right">{{project_meta.unstarted}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </overview>
    <projects-table>
      <div slot="empty" class="pt-4">
        <h6 class="text-opacity" v-if="optional($auth.user(), 'org_role.role_id')== 1">You have not created any projects yet.</h6>
        <h6 class="text-opacity" v-if="optional($auth.user(), 'org_role.role_id') == 1">Click on the blue button to get started.</h6>
        <div class="mt-4" v-if="optional($auth.user(), 'org_role.role_id') == 1">
          <router-link :to="{name: 'projects-create'}">
            <button class="btn btn-ds">
              <span class="pr-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <path
                    fill="#FFF"
                    d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 4c-.552 0-1 .448-1 1v2H5c-.552 0-1 .448-1 1s.448 1 1 1h2v2c0 .552.448 1 1 1s1-.448 1-1V9h2c.552 0 1-.448 1-1s-.448-1-1-1H9V5c0-.552-.448-1-1-1z"
                  ></path>
                </svg>
              </span>
              Create New Project
            </button>
          </router-link>
        </div>
      </div>
    </projects-table>
    <router-view></router-view>
  </div>
</template>

<script>
import Headers from "@/components/Layouts/Headers";
import Breadcrumbs from "@/components/Breadcrumbs/Index";
import Overview from "@/components/Layouts/Overview";
import ProjectsTable from "@/components/Tables/Projects/Index";
import {optional} from "@/helpers/random"

export default {
  title: 'Index | Projects',
  components: {
    Headers,
    Overview,
    ProjectsTable,
    Breadcrumbs
  },
  data() {
    return {
      breadCrumbs: [
        {
          text: "Home",
          href: "#"
        },
        {
          text: "All Projects",
          active: true
        }
      ]
    };
  },
  methods: {
    optional,
  },
  computed : {
    project_meta() {
      return this.$store.getters['projects/metrics'];
    }
  },
  created() {
    this.$store.dispatch("projects/getMetrics");
    let path = window.localStorage.getItem('redirect_from');
    if(path) {
      //console.log('found invite url')
      window.localStorage.removeItem('redirect_from');
      this.$router.push({ path });
    }
  },
};
</script>
