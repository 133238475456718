import {optional} from "@/helpers/random";
export default {
    employees: state => optional(state.employees, 'data', []),
    employees_total: state => optional(state.employees, 'meta.pagination.total', 0),
    employees_simple: state => state.employees && state.employees.data && state.employees.data.length > 0 ? state.employees.data.map((s) => {
        return {
            id: s.id,
            user_id: s.user.data.id,
            name: `${s.user.data.first_name} ${s.user.data.last_name}`,
            f_initial: s.user.data.first_name ? s.user.data.first_name.charAt(0) : '',
            l_initial: s.user.data.last_name ? s.user.data.last_name.charAt(0) : '',
            email: s.user.data.email
        }
    }) : [],
    users: state => state.employees && state.employees.data ? state.employees.data.map((s) => {
        return {
            id: s.user_id,
            name: `${s.user.data.first_name} ${s.user.data.last_name}`,
            email: s.user.data.email
        }
    }) : [],
    employee: state => state.employee || {},
    user: state => state.user,
    bank_accounts: state => state.bank_accounts,
    bank_account: state => state.bank_account,
    employee_users: state => state.employee_users,
}