<template>
  <div class="float-right">
    <ul>
      <li class="pr-1 pt-1">
        <input
          type="text"
          class="form-control h-41 search-input pl-4"
          style="height:41px;width:250px !important; font-size:14px;"
          v-model="params['subject']"
          @input="setFilters('subject',params['subject'])"
          placeholder="Search Employees"
          aria-label="Search"
          aria-describedby="basic-addon1"
        >
      </li>
      <li class="pr-1">
        <select @change="setFilters('per_page', params['per_page'])" class="h-42 custom-select form-control" v-model="params['per_page']">
          <option :key="per_page" v-for="per_page in [20, 50, 100, 500]" :value="per_page">{{per_page}}</option>
        </select>
      </li>
      <li class="pr-1">
        <button
          :class="visibleFilter ? 'btn btn-primary' : 'btn btn-outline'"
          @click="showMoreFilters"
        >
          <i class="zmdi zmdi-filter-list pr-2"></i>Filters
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["visibleFilter", "columns", "params"],
  data() {
    return {
      columnName: 'company_name'
    }
  },
  methods: {
    showMoreFilters() {
      let visible = this.visibleFilter === true ? false : true;
      this.$emit("showMoreFilters", visible);
    },
    removeColumnFromTable(name, val) {
      let data = {
        name,
        val: !val
      };
      this.$emit("removeColumnFromTable", data);
    },
    setFilters(name, val) {
      val = typeof val === "object" ? (val && val.id ? val.id : val) : val;
      let data = {
        name,
        val
      };
      this.$emit("setFilters", data);
    }
  }
};
</script>
