<template>
  <div>
    <div class="col-sm-12 mt-3 mb-5">
      <div class="row">
        <div class="col-sm-12 col-lg-7">
          <div class="card w-70 full-width p-4">
            <div class="row">
              <div class="col-sm-12" style>
                <h6 class="h7 text-opacity-pale text-bold">MILESTONE DETAILS</h6>
              </div>
              <div class="col-sm-12 col-lg-12 pt-3">
                <small class="text-opacity">Name</small>
                <h6 class="h7 pt-2 text-opacity-pale">{{milestone.name}}</h6>
              </div>
              <div class="col-sm-12 col-lg-12 pt-3">
                <small class="text-opacity">Description</small>
                <h6 class="h7 pt-2 text-opacity-pale" v-html="milestone.description"></h6>
              </div>
              <div class="col-sm-12 col-lg-12 pt-3">
                <small class="text-opacity">Project</small>
                <h6 class="h7 pt-2 text-opacity-pale">{{optional(milestone, "project.name", "")}}</h6>
              </div>
              <div class="col-sm-12 col-lg-12 pt-3">
                <small class="text-opacity">Status</small>
                <h6 class="h7 pt-2 text-opacity-pale">{{milestone.status && milestone.status.name}}</h6>
              </div>
              <div class="col-sm-12 col-lg-12 pt-3">
                <small class="text-opacity">Amount</small>
                <h6 class="h7 pt-2 text-opacity-pale">{{optional(milestone, 'project.currency.symbol', '')}}{{parseFloat(milestone.cost || 0)}}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {optional} from "@/helpers/random";
export default {
  methods: {optional},
  computed: {
    taskStatuses() {
      return this.$store.getters["global/task_statuses"];
    },
    task() {
      return this.$store.getters["tasks/task"];
    },
    milestone() {
      return this.$store.getters["projects/milestone"];
    },
    milestoneStatuses() {
      return this.$store.getters["global/milestone_statuses"];
    }
  }
};
</script>
