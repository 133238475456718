import axios from 'axios';
import download from 'downloadjs'
import {updatePending} from "@/helpers/axiosIntercept";
import random from "@/helpers/random";

const API = process.env.VUE_APP_BASE_URI;
export default {
    createUpdate({
                     commit
                 }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/invoices${data && data.id ? `/${data.id}` : ''}`;
            let method = data && data.id ? axios.put(url, data) : axios.post(url, data);
            method.then(({
                          data: {
                              data
                          }
                      }) => {
                commit('SET_INVOICE', data);
                resolve(data);
            })
                .catch(error => reject(error.response))
                .finally(() => {
                    updatePending(url)
                });
        });
    },
    approve({
                     commit
                 }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/invoices/${id}/approve`;
            axios.post(url).then(({
                          data: {
                              data
                          }
                      }) => {
                resolve(data);
            })
                .catch(error => reject(error.response))
                .finally(() => {
                    updatePending(url)
                });
        });
    },
    getSingle({
                  commit
              }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/invoices/${id}?include=template,customer,currency,projects,tax,approver`;
            axios.get(url)
                .then(({
                           data: {
                               data
                           }
                       }) => {
                    commit('SET_INVOICE', data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_INVOICE', {});
                    reject(error.response)
                })
                .finally(() => {
                    updatePending(url)
                });
        });
    },
    index({
              commit
          }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/invoices`;
            axios.get(url)
                .then(({
                           data: {
                               data
                           }
                       }) => {
                    commit('SET_INVOICES', data);
                    resolve(data.id);
                })
                .catch(error => {
                    commit('SET_INVOICES', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    // /** Delete Project from table */
    delete({
               commit
           }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/invoices/${id}`;
            axios.delete(url)
                .then(({
                           data: {
                               data
                           }
                       }) => {
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getProjectInvoices({
                  commit
              }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/projects/${id}/invoices?include=`;
            axios.get(url)
                .then(({
                           data: {
                               data
                           }
                       }) => {
                    commit('SET_INVOICES', data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_INVOICES', {});
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getInvoiceItems({
              commit
          },id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/invoices/${id}/items?include=timelog.employee,expense.employee`;
            axios.get(url)
                .then(({
                           data: {
                               data
                           }
                       }) => {
                    commit('SET_INVOICE_ITEMS', data);
                    resolve(data.id);
                })
                .catch(error => {
                    commit('SET_INVOICE_ITEMS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getInvoiceItemsExpenses({
                        commit
                    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/invoices/${id}/items?filter=expenses&include=timelog.employee,expense.employee`;
            axios.get(url)
                .then(({
                           data: {
                               data
                           }
                       }) => {
                    commit('SET_INVOICE_ITEMS_EXPENSES', data);
                    resolve(data.id);
                })
                .catch(error => {
                    commit('SET_INVOICE_ITEMS_EXPENSES', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getInvoiceItemsTimeLogs({
                                commit
                            }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/invoices/${id}/items?filter=timelogs&include=timelog.employee,expense.employee`;
            axios.get(url)
                .then(({
                           data: {
                               data
                           }
                       }) => {
                    commit('SET_INVOICE_ITEMS_TIMELOGS', data);
                    resolve(data.id);
                })
                .catch(error => {
                    commit('SET_INVOICE_ITEMS_TIMELOGS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getInvoiceItemsMilestones({
                                commit
                            }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/invoices/${id}/items?filter=milestones&include=timelog.employee,expense.employee,milestone`;
            axios.get(url)
                .then(({
                           data: {
                               data
                           }
                       }) => {
                    commit('SET_INVOICE_ITEMS_MILESTONES', data);
                    resolve(data.id);
                })
                .catch(error => {
                    commit('SET_INVOICE_ITEMS_MILESTONES', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    createUpdateInvoiceItem({
                     commit
                 }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/invoice_items${data && data.id ? `/${data.id}` : ''}`;
            let method = data && data.id ? axios.put(url, data) : axios.post(url, data);
            method.then(({
                          data: {
                              data
                          }
                      }) => {
                resolve(data);
            })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    recordPayment({
                     commit
                 }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/payments`;
            axios.post(url, data).then(({
                          data: {
                              data
                          }
                      }) => {
                resolve(data);
            })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    }, sendInvoice({
                     commit
                 }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/invoices/${data.id}/send`;
            axios.post(url, data).then(({
                          data: {
                              data
                          }
                      }) => {
                resolve(data);
            })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },

    getPdf({
                  commit
              }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/invoices/${id}/preview`;
            axios.get(url, {
                responseType: 'blob', // had to add this one here
            })
                .then((response) => {
                    return response.data;
                })
                .then((blob) => {
                    var url = window.URL.createObjectURL(new Blob([blob], {type: "application/pdf"}))
                    var a = document.createElement('a')
                    a.href = url
                    a.download = `${random.string(8)}.pdf`
                    resolve(a);
                })
                .catch(error => {
                    
                    reject(error.response)
                })
                .finally(() => {
                    updatePending(url)
                });
        });
    },
    downloadPdf({
               commit
           }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/invoices/${id}/preview`;
            axios.get(url, {
                responseType: 'blob', // had to add this one here
            })
                .then((response) => {
                    const content = response.headers['content-type'];
                    download(response.data, `invoice_${random.string(8)}.pdf`, content)
                })
                .catch(error => {
                    reject(error.response)
                })
                .finally(() => {
                    updatePending(url)
                });
        });
    },
    getMetrics({
        commit
    }) {
return new Promise((resolve, reject) => {
  let url = `${API}/invoices/metrics`;
  axios.get(url)
      .then(({
                 data: {
                     data
                 }
             }) => {
          commit('SET_INVOICE_METRICS', data);
          resolve(data);
      })
      .catch(error => {
          commit('SET_INVOICE_METRICS', {});
          reject(error.response)
      })
      .finally(() => {
          updatePending(url)
      });
});
},
}
