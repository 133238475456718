<template>
    <div>
        <bank-modal></bank-modal>
    </div>
</template>
<script>
    import BankModal from "@/components/Modals/Banks";
    export default {
        title: `New | Banks`,
        components: { BankModal },
        data() {
            return {
                body : {

                }
            };
        },
        mounted() {

        }
    };
</script>
