import axios from 'axios';
import {
    updatePending
} from "@/helpers/axiosIntercept";
const API = process.env.VUE_APP_BASE_URI;

export default {
    getMilestones({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/projects/${data}/milestones?include=tasks,tasks.employee`;
            let method = axios.get(url);
            method.then(({
                    data: {
                        data
                    }
                }) => {

                    commit("SET_MILESTONES", data);
                    resolve(data.id);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
}