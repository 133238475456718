<template>
  <!-- Modal -->
  <b-modal
    :no-close-on-backdrop="true"
    @hide="close"
    ref="customer-bank-modal"
    class="deletemodal"
  >
    <template v-slot:modal-header>
      <h2 class="pl-3 pt-3 pr-3 f-24">
        Banking Details
        <!-- {{`${$route.params.id ? 'Edit' : 'Create'} Bank`}} -->
      </h2>
      <button class="close" @click="close">
        Cancel
        <i class="zmdi zmdi-close-circle pl-2" style="font-size: 20px"></i>
      </button>
    </template>
    <div class="d-block pl-3">
      <form class="d-form" @submit.prevent="save">
        <div class="row">
          <div class="col-sm-6 col-lg-6">
            <h6 class="text-bold text-opacity-pale">Bank name <b>*</b></h6>
            <!-- <multiselect
              v-if="banks.length"
              track-by="id"
              name="bank_id"
              v-validate="'required'"
              return="id"
              label="name"
              v-model="body.bank"
              :options="banks"
              :show-labels="false"
              data-vv-as="Bank"
            ></multiselect> -->
            <input
              type="text"
              v-model="body.bank"
              name="bank_name"
              class="form-control auth-input d-input"
              placeholder="Enter Bank"
              data-vv-as="Bank Name"
              v-validate="'required'"
            />
            <small
              class="form-text text-danger"
              v-show="errors.has('bank_name')"
              >{{ errors.first("bank_name") }}</small
            >
          </div>
          <div class="col-sm-6 col-lg-6">
            <h6 class="text-bold text-opacity-pale">Account number <b>*</b></h6>
            <input
              type="text"
              name="account_number"
              v-validate="'required'"
              v-model="body.account_number"
              class="form-control auth-input d-input"
              placeholder="Enter Account number"
              data-vv-as="Account number"
            />
            <small
              class="form-text text-danger"
              v-show="errors.has('account_number')"
              >{{ errors.first("account_number") }}</small
            >
          </div>
          <div class="col-sm-12 col-lg-12 mt-3">
            <h6 class="text-bold text-opacity-pale">Bank Country</h6>
            <multiselect
              track-by="id"
              return="id"
              label="name"
              v-model="body.bank_country"
              :options="countries"
              :show-labels="false"
              @input="setBanks(body.bank_country)"
              data-vv-as="Bank Country"
            ></multiselect>
          </div>

          <div class="col-sm-6 col-lg-6 mt-3">
            <h6 class="text-bold text-opacity-pale">Account name <b>*</b></h6>
            <input
              type="text"
              name="account_name"
              v-validate="'required'"
              v-model="body.account_name"
              class="form-control auth-input d-input"
              placeholder="Enter Account name"
              data-vv-as="Account name"
            />
            <small
              class="form-text text-danger"
              v-show="errors.has('account_name')"
              >{{ errors.first("account_name") }}</small
            >
          </div>

          <div class="col-sm-6 col-lg-6 mt-3">
            <div class="row">
              <div class="col-sm-12 col-lg-12">
                <h6 class="text-bold text-opacity-pale">
                  Receiving currency <b>*</b>
                </h6>
                <multiselect
                  track-by="id"
                  return="id"
                  label="name"
                  v-model="body.currency"
                  name="receiving_currency"
                  :options="currencies"
                  :show-labels="false"
                  v-validate="'required'"
                  data-vv-as="Receiving Currency"
                ></multiselect>
                <small
                  class="form-text text-danger"
                  v-show="errors.has('receiving_currency')"
                  >{{ errors.first("receiving_currency") }}</small
                >
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-6 mt-3">
            <h6 class="text-bold text-opacity-pale">Routing number <b>*</b></h6>
            <input
              type="text"
              v-model="body.routing_number"
              v-validate="'required|numeric'"
              class="form-control auth-input d-input"
              placeholder="Enter Routing Number"
              data-vv-as="Routing number"
              name="routing_number"
            />
            <small
              class="form-text text-danger"
              v-show="errors.has('routing_number')"
              >{{ errors.first("routing_number") }}</small
            >
          </div>

          <div class="col-sm-6 col-lg-6 mt-3">
            <div class="row">
              <div class="col-sm-12 col-lg-6">
                <h6 class="text-bold text-opacity-pale">Sort code <b>*</b></h6>
                <input
                  type="text"
                  v-model="body.sort_code"
                  v-validate="'required|numeric|min:6|max:6'"
                  class="form-control auth-input d-input"
                  placeholder="Enter Sort Code"
                  data-vv-as="Sort Code"
                  name="sort_code"
                />
                <small
                  class="form-text text-danger"
                  v-show="errors.has('sort_code')"
                  >{{ errors.first("sort_code") }}</small
                >
              </div>
              <div class="col-sm-12 col-lg-6">
                <h6 class="text-bold text-opacity-pale">
                  SWIFT/BIC code <b>*</b>
                </h6>
                <input
                  type="text"
                  v-model="body.swift_code"
                  v-validate="'required|min:8|max:11'"
                  class="form-control auth-input d-input"
                  placeholder="Enter Swift Code"
                  data-vv-as="Swift Code"
                  name="swift_code"
                />
                <small
                  class="form-text text-danger"
                  v-show="errors.has('swift_code')"
                  >{{ errors.first("swift_code") }}</small
                >
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-lg-12 mt-3">
            <div class="row">
              <div class="col-sm-12 col-lg-6">
                <h6 class="text-bold text-opacity-pale">IBAN <b>*</b></h6>
                <input
                  type="text"
                  v-model="body.iban"
                  v-validate="'required'"
                  class="form-control auth-input d-input"
                  placeholder="Enter IBAN"
                  data-vv-as="IBAN"
                  name="iban"
                />
                <small
                  class="form-text text-danger"
                  v-show="errors.has('iban')"
                >
                  {{ errors.first("iban") }}
                </small>
              </div>
              <div class="col-sm-12 col-lg-6">
                <h6 class="text-bold text-opacity-pale">
                  Recipient Type <b>*</b>
                </h6>
                <multiselect
                  track-by="id"
                  return="id"
                  label="name"
                  v-model="body.recipient_type"
                  :options="recipient_types"
                  :show-labels="false"
                  name="recipient_type"
                  data-vv-as="Recipient Type"
                  v-validate="'required'"
                ></multiselect>
                <small
                  class="form-text text-danger"
                  v-show="errors.has('recipient_type')"
                  >{{ errors.first("recipient_type") }}</small
                >
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-12 mt-3">
            <h6 class="text-bold text-opacity-pale">Reason for Payment</h6>
            <textarea
              v-model="body.payment_reason"
              name="rate"
              class="form-control auth-input d-input"
              placeholder="Enter reason for payment"
              data-vv-as="Reason for Payment"
            ></textarea>
            <small
              class="form-text text-danger"
              v-show="errors.has('payment_reason')"
              >{{ errors.first("payment_reason") }}</small
            >
          </div>

          <div class="col-sm-6 mt-1">
            <div class="row">
              <div class="col-sm-12 col-lg-12 mt-3">
                <h6 class="text-bold h6 text-opacity-pale text-center separator">Bank Address <b>*</b></h6>
              </div>
              <div class="col-sm-12 col-lg-12 mt-1">
                <h6 class="text-bold text-opacity-pale">Street 1</h6>
                <input
                  type="text"
                  name="bank_address_street"
                  v-validate="addressFilled() ? 'required' : null"
                  v-model="body.bank_address.address"
                  class="form-control auth-input d-input"
                  placeholder="Enter stress address"
                  data-vv-as="Street Address"
                />
                <small
                  class="form-text text-danger"
                  v-show="errors.has('bank_address_address')"
                  >{{ errors.first("bank_address_address") }}</small
                >
              </div>
              <div class="col-sm-12 col-lg-12 mt-4">
                <div class="row">
                  <div class="col-sm-12 col-lg-6 mt-1">
                    <h6 class="text-bold text-opacity-pale">
                      Country <b> {{ addressFilled() ? "*" : "" }} </b>
                    </h6>
                    <multiselect
                      track-by="id"
                      return="id"
                      label="name"
                      v-validate="addressFilled() ? 'required' : null"
                      v-model="body.bank_address.country"
                      name="bank_address_country_id"
                      :options="countries"
                      :show-labels="false"
                      @input="setStates(body.bank_address.country)"
                      data-vv-as="Country"
                    ></multiselect>
                    <small
                      class="form-text text-danger"
                      v-show="errors.has('bank_address_country_id')"
                      >{{ errors.first("bank_address_country_id") }}</small
                    >
                  </div>
                  <div class="col-sm-12 col-lg-6 mt-1">
                    <h6 class="text-bold text-opacity-pale">
                      State <b>{{ addressFilled() ? "*" : "" }} </b>
                    </h6>
                    <multiselect
                      track-by="id"
                      return="id"
                      label="name"
                      name="bank_address_region_id"
                      v-validate="addressFilled() ? 'required' : null"
                      v-model="body.bank_address.region"
                      :options="bankStates"
                      :show-labels="false"
                      @input="setCities(body.bank_address.region)"
                      data-vv-as="State"
                    ></multiselect>
                    <small
                      class="form-text text-danger"
                      v-show="errors.has('bank_address_region_id')"
                      >{{ errors.first("bank_address_region_id") }}</small
                    >
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-sm-12 col-lg-6 mt-1">
                    <h6 class="text-bold text-opacity-pale">
                      City <b>{{ addressFilled() ? "*" : "" }} </b>
                    </h6>
                    <multiselect
                      v-if="bankCities.length"
                      track-by="id"
                      return="id"
                      label="name"
                      name="bank_address_city_id"
                      v-validate="addressFilled() ? 'required' : null"
                      v-model="body.bank_address.city"
                      :options="bankCities"
                      :show-labels="false"
                      data-vv-as="City"
                    ></multiselect>
                    <input
                      v-else
                      type="text"
                      v-model="body.bank_address.city"
                      name="shipping_city"
                      class="form-control auth-input d-input"
                      placeholder="Enter City"
                      data-vv-as="Bank City"
                    />
                    <small
                      class="form-text text-danger"
                      v-show="errors.has('bank_address_city_id')"
                      >{{ errors.first("bank_address_city_id") }}</small
                    >
                  </div>
                  <div class="col-sm-12 col-lg-6 mt-1">
                    <h6 class="text-bold text-opacity-pale">Zip Code</h6>
                    <input
                      type="text"
                      v-model="body.bank_address.post_code"
                      class="form-control auth-input d-input"
                      placeholder="Enter shipping zip code"
                      data-vv-as="Zip Code"
                    />
                    <small
                      class="form-text text-danger"
                      v-show="errors.has('bank_address_post_code')"
                      >{{ errors.first("bank_address_post_code") }}</small
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mt-1">
            <div class="row">
              <div class="col-sm-12 col-lg-12 mt-3">
                <h6 class="text-bold text-opacity-pale text-center separator">
                  Recipient Address
                </h6>
              </div>
              <div class="col-sm-12 col-lg-12">
                <h6 class="text-bold text-opacity-pale">Street 1 <b>*</b></h6>
                <input
                  type="text"
                  name="recipient_address_address"
                  v-validate="addressFilled(1) ? 'required' : null"
                  v-model="body.recipient_address.address"
                  class="form-control auth-input d-input"
                  placeholder="Enter stress address"
                  data-vv-as="Recipient Street Address"
                />
                <small
                  class="form-text text-danger"
                  v-show="errors.has('recipient_address_address')"
                  >{{ errors.first("recipient_address_address") }}</small
                >
              </div>
              <div class="col-sm-12 col-lg-12 mt-4">
                <div class="row">
                  <div class="col-sm-12 col-lg-6 mt-1">
                    <h6 class="text-bold text-opacity-pale">Country</h6>
                    <multiselect
                      track-by="id"
                      name="recipient_address_country_id"
                      v-validate="addressFilled(1) ? 'required' : null"
                      return="id"
                      label="name"
                      v-model="body.recipient_address.country"
                      :options="countries"
                      @input="setStates(body.recipient_address.country, 1)"
                      :show-labels="false"
                      data-vv-as="Recipient Country"
                    ></multiselect>
                    <small
                      class="form-text text-danger"
                      v-show="errors.has('recipient_address_country_id')"
                      >{{ errors.first("recipient_address_country_id") }}</small
                    >
                  </div>
                  <div class="col-sm-12 col-lg-6 mt-1">
                    <h6 class="text-bold text-opacity-pale">State</h6>
                    <multiselect
                      track-by="id"
                      return="id"
                      label="name"
                      name="recipient_address_region_id"
                      v-validate="addressFilled(1) ? 'required' : null"
                      v-model="body.recipient_address.region"
                      :options="recipientStates"
                      @input="setCities(body.recipient_address.region, 1)"
                      :show-labels="false"
                      data-vv-as="State"
                    ></multiselect>
                    <small
                      class="form-text text-danger"
                      v-show="errors.has('recipient_address_region_id')"
                      >{{ errors.first("recipient_address_region_id") }}</small
                    >
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-sm-12 col-lg-6 mt-1">
                    <h6 class="text-bold text-opacity-pale">City</h6>
                    <multiselect
                      v-if="recipientCities.length"
                      track-by="id"
                      return="id"
                      label="name"
                      name="recipient_address_city_id"
                      v-validate="addressFilled(1) ? 'required' : null"
                      v-model="body.recipient_address.city"
                      :options="recipientCities"
                      :show-labels="false"
                      data-vv-as="Recipient City"
                    ></multiselect>
                    <input
                      v-else
                      type="text"
                      v-model="body.recipient_address.city"
                      name="shipping_city"
                      class="form-control auth-input d-input"
                      placeholder="Enter City"
                      data-vv-as="Recipient City"
                    />
                    <small
                      class="form-text text-danger"
                      v-show="errors.has('recipient_address_city_id')"
                      >{{ errors.first("recipient_address_city_id") }}</small
                    >
                  </div>
                  <div class="col-sm-12 col-lg-6 mt-1">
                    <h6 class="text-bold text-opacity-pale">Zip Code</h6>
                    <input
                      type="text"
                      v-model="body.recipient_address.post_code"
                      name="r_zipcode"
                      class="form-control auth-input d-input"
                      placeholder="Enter Zip code"
                      data-vv-as="Recipient ZipCode"
                    />
                    <small
                      class="form-text text-danger"
                      v-show="errors.has('recipient_address_post_code')"
                      >{{ errors.first("recipient_address_post_code") }}</small
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-lg-12 mt-3">
            <input id="primary" type="checkbox" v-model="body.is_primary" />
            <label for="primary"
              ><small class="pl-2">Set as Primary</small></label
            >
          </div>
        </div>
      </form>
    </div>
    <template v-slot:modal-footer>
      <dashboard-button
        @submit="save"
        :loading="loading"
        :text="'Submit'"
      ></dashboard-button>
    </template>
  </b-modal>
</template>

<style>
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #f5f5f5;
}
.separator::before {
  margin-right: 0.55em;
}
.separator::after {
  margin-left: 0.55em;
}
</style>
<script>
import DashboardButton from "@/components/Buttons/Dashboard";
import Literals from "@/helpers/dashboard/literals";
import Accordion from "@/components/Collapse/Index";
import getOrganization from "@/helpers/getOrganization";
import handleError from "@/helpers/error";
import { optional } from "@/helpers/random";

export default {
  name: "UserModal",
  data() {
    return {
      Literals,
      loading: false,
      accordions: [
        { name: "basic", visible: true },
        { name: "contact", visible: true },
        { name: "address", visible: false },
        { name: "payment", visible: false },
        { name: "others", visible: true },
      ],
      recipientStates: [],
      bankStates: [],
      recipientCities: [],
      bankCities: [],
      banks: [],
      body: {
        account_name: "",
        account_number: "",
        bank: null,
        currency: "",
        routing_number: "",
        swift_code: "",
        sort_code: "",
        iban: "",
        payment_reason: "",
        recipient_type_id: null,
        is_primary: true,
        bank_address: {
          city: null,
        },
        recipient_address: {
          city: null,
        },
      },
    };
  },
  components: { DashboardButton },
  props: ["bankAccount"],
  methods: {
    handleError,
    close() {
      return this.$router.go(-1);
    },
    toggleCollapse(index) {
      this.accordions[index].visible = !this.accordions[index].visible;
    },
    save() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    fillAddress(rawAddress) {
      let parsedAddress = {};
      if (rawAddress) {
        parsedAddress = {
          address: rawAddress.address,
          region_id: optional(rawAddress, "region.id", null),
          country_id: optional(rawAddress, "country.id", null),
          post_code: rawAddress.post_code,
        };

        rawAddress.city && rawAddress.city.id
          ? (parsedAddress.city_id = rawAddress.city.id)
          : (parsedAddress.city = rawAddress.city);
      }
      return parsedAddress.address &&
        parsedAddress.region_id &&
        parsedAddress.country_id 
        ? parsedAddress
        : null;
    },
    async submit() {
      let data = {};
      Object.assign(data, this.body);
      data.employee_id = this.employee.id;
      data.is_primary = this.body.is_primary;
      if (this.body.bank && this.body.bank.id) {
        data.bank_id = this.body.bank.id;
        delete data.bank;
      } else {
        data.bank = this.body.bank;
      }
      data.currency_id = this.body.currency ? this.body.currency.id : null;
      data.recipient_type_id = this.body.recipient_type
        ? this.body.recipient_type.id
        : null;

      // we need to update/create recipient and bank address before updating/creating employee bank account
      let bank_address = this.fillAddress(this.body.bank_address);
      if (bank_address) {
        await this.$store
          .dispatch("global/createUpdateAddress", bank_address)
          .then((id) => {
            data.bank_address_id = id;
          })
          .catch((error) => {
            let errorMessage = `Failed to ${
              bank_address.id ? "Update" : "Create"
            } Bank Address`;

            this.handleError(this, error, errorMessage);
            this.loading = false;
          });
      }

      let recipient_address = this.fillAddress(data.recipient_address);
      if (recipient_address) {
        await this.$store
          .dispatch("global/createUpdateAddress", recipient_address)
          .then((id) => {
            data.recipient_address_id = id;
          })
          .catch((error) => {
            let errorMessage = `Failed to ${
              bank_address.id ? "Update" : "Create"
            } Recipient Address`;
            this.handleError(this, error, errorMessage);
            this.loading = false;
          });
      }

      // this is to prevent the form from submitting if any of the above requests failed
      if (this.loading) {
        delete data.currency;
        delete data.recipient_type;
        delete data.bank_country;
        delete data.bank_address;
        delete data.recipient_address;
        delete data.created_at;

        let msgPass = this.body.id
          ? Literals.BANK_UPDATE_SUCCESS
          : Literals.BANK_CREATE_SUCCESS;

        this.$store
          .dispatch("employees/createUpdateBank", data)
          .then(async (user_id) => {
            this.$toast.success(msgPass, {
              position: "top-right",
              duration: 3000,
            });
            //TODO:
            this.$router.push({
              name: "users-bank",
              params: { id: this.$route.params.id },
            });
          })
          .catch((error) => {
            let errorMessage = data.id
              ? Literals.BANK_UPDATE_ERROR
              : Literals.BANK_CREATE_ERROR;
            this.handleError(this, error, errorMessage);
          })
          .finally(() => (this.loading = false));
      }
    },
    setStates(selectedCountry, type) {
      let states =
        selectedCountry &&
        selectedCountry.regions &&
        selectedCountry.regions.data
          ? selectedCountry.regions.data
          : [];
      type === 1 ? (this.recipientStates = states) : (this.bankStates = states);
    },
    setCities(state, type) {
      this.$store.dispatch("global/getState", state.id).then((data) => {
        let cities = data && data.cities && data.cities.data;

        if (type === 1) {
          this.recipientCities = cities;
          if (!this.recipientCities.length)
            this.body.recipient_address.city = "";
        } else {
          this.bankCities = cities;
          if (!this.bankCities.length) this.body.bank_address.city = "";
        }
      });
    },
    addressFilled(type) {
      let addressObj =
        type === 1 ? this.body.recipient_address : this.body.bank_address;
      return (
        addressObj.address ||
        addressObj.country ||
        addressObj.city ||
        addressObj.region ||
        addressObj.post_code
      );
    },
    setBanks(country) {
      this.$store.dispatch("global/getBanks", country.id).then((data) => {
        this.banks = data;
      });
    },
    formatData() {
      if (this.bankAccount) {
        Object.assign(this.body, this.bankAccount);
        this.body.bank && this.banks.push(this.body.bank);
        this.body.recipient_address.city && this.recipientCities.push(this.body.recipient_address.city);
        this.body.bank_address.city && this.bankCities.push(this.body.bank_address.city);
      }

      if (this.employee && this.employee.address) {
        //set address'
        this.body.recipient_address = this.employee.address.data;
        if (typeof this.employee.address.data.city == "object") {
          this.recipientCities.push(this.employee.address.data.city);
        }
      }
      if (this.$route && this.$route.params.bid) {
        //set bank country
        var userBanks = optional(this.employee, "accounts.data", {});
        let bank = userBanks.find((s) => s.id == this.$route.params.bid);
        this.body.bank_country = bank && bank.bank_address && bank.bank_address.country;
        this.body.bank = bank && bank.bank;
      }
    },
  },
  computed: {
    countries() {
      return this.$store.getters["global/countries"];
    },
    currencies() {
      return this.$store.getters["global/currencies"];
    },
    recipient_types() {
      return this.$store.getters["global/recipient_types"];
    },
    employee() {
      return this.$store.getters["employees/employee"];
    },
  },
  mounted() {
    this.$refs["customer-bank-modal"].show();
  },
  created() {
    Promise.all([
      this.countries.length || this.$store.dispatch("global/getCountries"),
      this.currencies.length || this.$store.dispatch("global/getCurrencies"),
      this.recipient_types.length ||this.$store.dispatch("global/getRecipientTypes"),
      this.$store.dispatch("employees/getByUserId", this.$route.params.id),
    ]).finally(() => {
      this.formatData();
    });
  },
};
</script>
