<template>
  <div class>
    <delete-modal
      @trigger="deleteState"
      @cancel="cancelDelete"
      @deleteAction="deleteItem"
      :showDelete="showDelete"
      :name="showDeleteName"
      ref="delete"
    ></delete-modal>
    <div class="table-wrapper">
      <vuetable
        ref="vuetable"
        :api-url="api"
        :http-options="{
          headers: {
            Authorization: 'Bearer ' + $auth.token(),
            xorg: getOrganization.organization_id(),
          },
        }"
        @vuetable:loaded="loadTable"
        :fields="columns"
        data-path="data"
        pagination-path
        :no-data-template="
          rowCount === null ? 'Loading data' : 'No Data Available'
        "
        @vuetable:pagination-data="onPaginationData"
      >
        <template slot="id" slot-scope="props">
          <router-link
            v-b-tooltip.hover
            title="Goto Project"
            :to="{
              name: 'projects-show-general',
              params: { id: props.rowData.id },
            }"
            >{{ props.rowData.name }}
          </router-link>
        </template>
        <template slot="actions" slot-scope="{ rowData }">
          <span id="delete" @click="deleteState(rowData.id)" class>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="#72809D"
              viewBox="0 0 30 30"
            >
              <path
                d="M9 13v6c0 .552-.448 1-1 1s-1-.448-1-1v-6c0-.552.448-1 1-1s1 .448 1 1zm7-1c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm-4 0c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm4.333-8.623c-.882-.184-1.373-1.409-1.189-2.291l-5.203-1.086c-.184.883-1.123 1.81-2.004 1.625l-5.528-1.099-.409 1.958 19.591 4.099.409-1.958-5.667-1.248zm4.667 4.623v16h-18v-16h18zm-2 14v-12h-14v12h14z"
              ></path>
            </svg>
          </span>
        </template>
      </vuetable>
      <vuetable-pagination
        v-show="paginationData.total"
        :css="table_css.pagination"
        ref="pagination"
      ></vuetable-pagination>
      <div class="text-center" v-if="rowCount <= 0 || rowCount === null">
        <slot name="empty"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import getOrganization from "@/helpers/getOrganization";
import VuetableCss from "@/helpers/vuetable_styling.js";
import getPaginationData from "@/helpers/pagination.js";
import VuetablePagination from "@/components/Layouts/VueTablePagination";
import DeleteModal from "@/components/Modals/Delete";

export default {
  title: "Index | Projects | Customer",
  components: { VuetablePagination, DeleteModal },
  data() {
    return {
      columns: [
        {
          name: "__slot:id",
          title: "Project Title",
          sortField: "name",
          cvisible: true,
          active: true,
        },
        {
          name: "name",
          title: "Customer",
          sortField: "name",
          cvisible: true,
          active: true,
        },
        {
          name: "billing_type",
          title: "Project Type",
          sortField: "project_status_id",
          cvisible: true,
          active: true,
          callback: (d) => {
            return d ? d.name : "N/A";
          },
        },
        {
          name: "manager",
          title: "Project Manager",
          cvisible: true,
          active: false,
          callback: (s) => {
            return s && s.data
              ? s.data.first_name + " " + s.data.last_name
              : "N/A";
          },
        },
        {
          name: "hourly_rate",
          title: "Project Hourly Rate",
          cvisible: true,
          active: false,
        },
        {
          name: "fixed_cost",
          title: "Cost",
          cvisible: true,
          active: false,
        },
        {
          name: "__slot:actions",
          title: "Actions",
          cvisible: true,
          active: false,
        },
      ],
      rowCount: null,
      table_css: VuetableCss,
      getOrganization,
      paginationData: {},
      showDelete: false,
      showDeleteName: "",
      selectedItem: null,
    };
  },
  methods: {
    getPaginationData,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
    },
    deleteState(id) {
      this.showDelete = true;
      this.showDeleteName = "Project";
      this.selectedItem = id;
    },
    cancelDelete() {
      this.showDelete = false;
      this.selectedItem = null;
    },
    deleteItem(s) {
      this.$store.dispatch("projects/delete", this.selectedItem).then((s) => {
        this.$router.push({
          name: "users-projects",
          params: { id: this.$route.params.id },
        });
        this.$toast.success("🚮 User Project Successfully Deleted", {
          position: "top-right",
          duration: 3000,
        });
      });
    },
  },
  computed: {
    employee() {
      return this.$store.getters["employees/employee"];
    },
    user() {
      return this.$store.getters["employees/user"];
    },
    api() {
      return (
        this.$baseApi + `/projects/` + this.$route.params.id + `/collaborating`
      );
    },
  },
};
</script>
