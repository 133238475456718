<template>
  <div class="home pb-5" :class="{blur:!loaded}">
    <delete-modal
            @trigger="deleteCustomerState"
            @cancel="cancelDelete"
            @deleteAction="deleteCustomer"
            :showDelete="showDelete"
            :name="showDeleteName"
            ref="delete"
    ></delete-modal>
    <delete-modal
            @trigger="deleteContactState"
      @cancel="cancelDeleteContact"
      @deleteAction="deleteContact"
      :showDelete="showDeleteContact"
      :name="showDeleteName"
      ref="deleteContact"
    ></delete-modal>
    <headers class="bg-ggray pb-5">
      <div slot="breadcrumbs">
        <breadcrumbs :items="breadCrumbs"></breadcrumbs>
      </div>
      <div slot="title">
        <h2>{{customer.company_name ? customer.company_name : 'N/A'}}</h2>
      </div>
      <div slot="action">
        <router-link :to="{name:'customers-edit'}">
          <button class="btn btn-ds btn-outline pl-3 pr-3">
            <span class="pr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#72809D"
                viewBox="0 0 30 30"
              >
                <path
                  d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"
                ></path>
              </svg>
            </span>
            Edit Customer
          </button>
        </router-link>
        <button
          @click="deleteCustomerState"
          class="btn btn-ds btn-outline btn-danger-custom pl-3 pr-3 ml-2"
        >
          Delete Customer
          <span class="pl-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="#72809D"
              viewBox="0 0 30 30"
            >
              <path
                d="M9 13v6c0 .552-.448 1-1 1s-1-.448-1-1v-6c0-.552.448-1 1-1s1 .448 1 1zm7-1c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm-4 0c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm4.333-8.623c-.882-.184-1.373-1.409-1.189-2.291l-5.203-1.086c-.184.883-1.123 1.81-2.004 1.625l-5.528-1.099-.409 1.958 19.591 4.099.409-1.958-5.667-1.248zm4.667 4.623v16h-18v-16h18zm-2 14v-12h-14v12h14z"
              ></path>
            </svg>
          </span>
        </button>
      </div>
    </headers>
    <div class="container-fluid tab-wrapper">
      <div class="row pl-3 pr-4">
        <div class="col-sm-12 col-lg-6">
          <ul class="tabs">
            <router-link :to="{name: 'customers-show'}">
              <li>CUSTOMER HIGHLIGHTS</li>
            </router-link>
            <router-link :to="{name: 'customers-show-finance'}">
              <li>CUSTOMER FINANCE</li>
            </router-link>
          </ul>
          <!-- <h6 class="text-bold text-opacity-pale">CUSTOMER HIGHLIGHTS</h6> -->
        </div>
      </div>
      <div class="">
        <router-view></router-view>
      </div>
      <!-- <div class="col-sm-12 col-lg-1"></div> -->
    </div>
  </div>
</template>

<script>
import Headers from "@/components/Layouts/Headers";
import Breadcrumbs from "@/components/Breadcrumbs/Index";
import DeleteModal from "@/components/Modals/Delete";
export default {
  title: `Show | Customers`,
  components: { Headers, Breadcrumbs, DeleteModal },
  data() {
    return {
      showDelete: false,
      showDeleteContact: false,
      showDeleteName: "",
      deleteContactId: null,
      showInvoiceModal: false,
      loaded: false
    };
  },
  methods: {
    cancelGenerateInvoice() {
      this.showInvoiceModal = false;
    },
    deleteCustomerState() {
      this.showDelete = true;
      this.showDeleteName = "Customer";
    },
    deleteContactState(id) {
      this.deleteContactId = id;
      this.showDeleteContact = true;
      this.showDeleteName = "Contact";
    },
    cancelDelete() {
      this.showDelete = false;
    },
    cancelDeleteContact() {
      this.showDeleteContact = false;
      this.deleteContactId = null;
    },
    deleteCustomer(s) {
      return this.$store
        .dispatch("customers/delete", this.$route.params.id)
        .then(s => {
          this.$router.push({ name: "customers-index" });
          this.$toast.success("Customer Successfully Deleted", {
            position: "top-right",
            duration: 3000
          });
        });
    },
    deleteContact() {
      let id = this.deleteContactId;
      return this.$store.dispatch("customers/deleteContact", id).then(s => {
        this.$toast.success("Contact Successfully Deleted", {
          position: "top-right",
          duration: 3000
        });
        this.cancelDeleteContact();
        this.$store.commit(
          "customers/SET_CONTACTS",
          this.contacts.filter(s => s.id !== id)
        );
      });
    }
  },
  computed: {
    customer() {
      return this.$store.getters["customers/customer"];
    },
    contacts() {
      return this.$store.getters["customers/contacts"];
    },
    customer_address() {
      let cust = this.customer.billing_address;
      let address = cust && cust.address ? cust.address : null;
      if (cust) {
        let country = cust && cust.country;
        let region = cust && cust.region;
        let city = cust && cust.city;

        if (country) {
          address += address ? ", " + country.name : country.name;
        }
        if (region) {
          address += address ? ", " + region.name : region.name;
        }
        if (city) {
          address += address ? ", " + city.name : city.name;
        }
        if (cust.post_code) {
          address += address ? ", " + cust.post_code : cust.post_code;
        }
      }

      return address;
    },
    projects() {
      return this.$store.getters["customers/projects"];
    },
    tasks() {
      return this.$store.getters["customers/tasks"];
    },
    tasks_overdue() {
      return this.tasks.filter(b => b.status && b.status.id == 2).length;
    },
    tasks_completed() {
      return this.tasks.filter(b => b.status && b.status.id == 2).length;
    },
    opportunities() {
      return this.$store.getters["customers/opportunities"];
    },
    opportunities_won() {
      return this.opportunities.filter(b => b.status && b.status.id == 2)
        .length;
    },
    opportunities_lost() {
      return this.opportunities.filter(b => b.status && b.status.id == 1)
        .length;
    },
    attachments() {
      return this.$store.getters["customers/attachments"];
    },
    invoices() {
      return this.$store.getters["customers/invoices"];
    },
    invoices_paid() {
      return this.invoices.filter(b => b.status && b.status.id == 2).length;
    },
    invoices_unpaid() {
      return this.invoices.filter(b => b.status && b.status.id == 1).length;
    },
    running_projects() {
      return this.projects.filter(
        project => project.status && project.status.id == 2
      ).length;
    },
    completed_projects() {
      return this.projects.filter(
        project => project.status && project.status.id == 6
      ).length;
    },
    breadCrumbs() {
      return [
        {
          text: "Home",
          to: { name: "home" }
        },
        {
          text: "All Customers",
          to: { name: "customers-index" }
        },
        {
          text: this.customer.company_name,
          to: { name: "customers-show", params: { id: this.customer.id } }
        }
      ];
    }
  },
  created() {
    Promise.all([
      this.$store.dispatch("customers/getSingle", this.$route.params.id),
      this.$store.dispatch("customers/getProjects", this.$route.params.id),
      this.$store.dispatch("customers/getOpportunities", this.$route.params.id),

      this.$store.dispatch("customers/getTasks", this.$route.params.id),
      this.$store.dispatch("customers/getAttachments", this.$route.params.id),
      this.$store.dispatch("customers/getInvoices", this.$route.params.id),
      this.$store.dispatch("customers/getContacts", this.$route.params.id)
    ]).finally(() => {
      this.loaded = true;
    })
  },
  watch: {
  $route(to, from) {
    this.$store.dispatch("customers/getSingle", this.$route.params.id);
  }
  }
};
</script>
