<template>
  <b-modal
    @hide="close"
    :no-close-on-backdrop="true"
    :visible="showPaymentModal"
    class="pl-4 pr-4"
    id="expenseModal"
    ref="expenseModal"
  >
    <template v-slot:modal-header>
      <h2 class="pl-1 pt-3 pr-3 f-24">{{'Record Payment'}}</h2>
      <button class="close" @click="close">
        Cancel
        <i class="zmdi zmdi-close-circle pl-2" style="font-size:20px"></i>
      </button>
    </template>
    <div class="d-block pb-4">
      <div class="mt-3">
        <form>
          <div class="row auth-form">
            <div class="col-sm-12 col-lg-12"></div>
            <div class="col-sm-12 col-lg-12 mt-1 date-range">
              <!-- this should autofill if task exist -->
              <h6 class="text-bold text-opacity-pale">
                Payment date
                <b>*</b>
              </h6>
              <vc-date-picker
                :masks="{ input: ['YYYY-MM-DD']}"
                :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: `Payment date`}"
                name="payment_date"
                v-model="body.payment_date"
                ref="startFrom"
                data-vv-as="Payment date"
                v-validate="'required'"
              />
              <small class="dpicker-icon">
                <i class="zmdi zmdi-calendar-alt"></i>
              </small>
              <small
                      class="form-text text-danger"
                      v-show="errors.has('payment_date')"
              >{{errors.first('payment_date')}}</small>
            </div>
            <div class="col-sm-12 col-lg-12 mt-4">
              <h6 class="text-bold text-opacity-pale">Amount <b>*</b></h6>
              <input
                type="text"
                v-validate="'required'"
                v-model="body.amount_paid"
                name="amount"
                class="form-control auth-input d-input"
                placeholder="Enter Amount"
                data-vv-as="Amount"
              >
              <small
                class="form-text text-danger"
                v-show="errors.has('amount')"
              >{{errors.first('amount')}}</small>
            </div>
            <div class="col-sm-12 col-lg-12 mt-4">
              <!-- this should autofill if task exist -->
              <h6 class="text-bold text-opacity-pale">
                Payment Method <b>*</b>
              </h6>
              <multiselect
                track-by="id"
                label="name"
                name="payment_source"
                :options="paymentSources"
                :show-labels="false"
                v-model="body.payment_source"
                placeholder="Select Payment Method"
                data-vv-as="Payment Method"
                v-validate="'required'"
              ></multiselect>
              <small
                class="form-text text-danger"
                v-show="errors.has('payment_source')"
              >{{errors.first('payment_source')}}</small>
            </div>
            <div class="col-sm-12 col-lg-12 mt-4">
              <h6 class="text-bold text-opacity-pale">
                Notes
        
              </h6>
              <textarea
                class="form-control d-input"
                name="notes"
                v-model="body.comments"
                placeholder="Enter Payment Notes"
                data-vv-as="Payment Notes"
              ></textarea>
              <small
                class="form-text text-danger"
                v-show="errors.has('notes')"
              >{{errors.first('notes')}}</small>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <div v-if="showPaymentModal">
        <dashboard-button @submit="save" :loading="loading" :text="'Save'"></dashboard-button>
      </div>
      <div v-else>
        <button class="btn btn-primary" @click="close">Hide Timer</button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import DashboardButton from "@/components/Buttons/Dashboard";
  import Literals from "@/helpers/dashboard/literals";
  import {toUTC} from "@/helpers/date";
  import handleError from "@/helpers/error";
  import {toLocal} from "@/helpers/date";
  import moment from 'moment';

  export default {
    components : {DashboardButton},
    data() {
      return {
        loading: false,
        body: {
          amount_paid: 0
        }
      };
    },
    props: ["showPaymentModal"],
    computed: {
      invoice() {
      return this.$store.getters["invoices/invoice"];
    },
    paymentSources() {
      return this.$store.getters['payments/all_payment_methods'];
    }
  },
  methods: {
    toUTC,
    handleError,
    save() {
      let data = {};
      Object.assign(data, this.body);
      data.payment_source_id = data.payment_source ? data.payment_source.id : null;
      data.date_paid = toUTC(data.payment_date).format('YYYY-MM-DD');
      data.invoice_id = this.invoice.id;

      delete data.payment_date;
      delete data.payment_source;

      this.$validator.validateAll().then((valid) => {

        if (valid) {
          this.loading = true;
          this.$store.dispatch("invoices/recordPayment", data)
              .then(s => {
                this.$store.dispatch("invoices/getSingle", this.invoice.id)
                this.$toast.success(`Payment recorded successfully`, {
                  position: "top-right",
                  duration: 3000
                });
                this.body = {};
                this.close();
              }).catch(error => {
                let errorMessage = "Failed to record payment";
                this.handleError(this, error, errorMessage);
              })
              .finally(() => {
                this.loading = false;
              });
        } else return;

      });
    },



     
    close() {
      this.$emit("close");
    }
  },
    created() {
    
      this.$store.dispatch("payments/getAllPaymentMethods", "is_invoice_enabled=1");
      this.body.amount_paid = this.invoice.amount_due;
      this.body.payment_date = new Date()
      
    }
  };
</script>
