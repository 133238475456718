<template>
  <div class="overlay">
    <delete-modal 
      :showDelete="showDeleteModal"
      @trigger="deleteNoteState"
      :name="showDeleteName"
      @cancel="cancelDelete"
      @deleteAction="deleteNote"
      ref="delete"></delete-modal>
    <tasks-modal
      v-if="showTaskModal"
      @close="showTaskModal = false;closeModal()"
      from="notes"
      :taskDesc="taskDesc"
      @saveTask="saveTask"
      :availableProject="project"
      :hasProject="true"
    ></tasks-modal>
    <div class="container task-wrapperx"></div>
    <div class="container back-btn">
      <router-link
        class="pl-1"
        id="backLink"
        :to="{ name: 'projects-show-notes' }"
        style="color: white !important; opacity: 0.7"
      >
        <button class="mr-2 btn-icon">
          <span class="text-bold text-opacity-pale">
            <span>
              <i
                class="zmdi zmdi-long-arrow-left"
                style="font-size: 20px; color: #72809d"
              ></i>
            </span>
          </span>
        </button>
        <small>Close Notes</small>
      </router-link>
    </div>
    <div class>
      <div class="container task-wrapper">
        <form class="medium-editor p-5">
          <div class="d-flex row align-items-center">
            <div class="d-flex flex-column col-lg-9 float-left">
              <input
              id="title"
              v-model="body.title"
              type="text"
              placeholder="What is the title of this note?"
            />
            </div>
            <div class="col-lg-3 float-right text-right" v-if="this.$route && this.$route.query.id">
              <button @click="deleteNoteState" type="button" class="btn btn-ds btn-outline btn-danger-custom">
                Delete Note
                <span class="pl-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#72809D"
                    viewBox="0 0 30 30"
                  >
                    <path
                      d="M9 13v6c0 .552-.448 1-1 1s-1-.448-1-1v-6c0-.552.448-1 1-1s1 .448 1 1zm7-1c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm-4 0c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm4.333-8.623c-.882-.184-1.373-1.409-1.189-2.291l-5.203-1.086c-.184.883-1.123 1.81-2.004 1.625l-5.528-1.099-.409 1.958 19.591 4.099.409-1.958-5.667-1.248zm4.667 4.623v16h-18v-16h18zm-2 14v-12h-14v12h14z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-between">
          </div>
          <div class="mt-5">
            <quill-editor
              ref="myQuillEditor"
              id="notes-editor"
              class="mt-1"
              v-model="content"
              @input="onChange"
              @ready="_html(content)"
              placeholder="Enter note description"
            >
              <div
                style="width: 100%; text-align: right; float: right"
                id="toolbar"
                class="ql-toolbar ql-snow"
                slot="toolbar"
              >
                <button
                  @click="renderText"
                  class="btn btn-custom"
                  style="width: 150px; float: right"
                >
                  + Create task
                </button>
              </div>
            </quill-editor>
          </div>

          <div class="d-flex mt-2 justify-content-end align-items-end" style="border-top:1px solid #F7F7F7; gap:10px">
              <small v-show="body.created_at">
                 <span v-if="saved">Created {{toLocalWithTime(body.created_at)}}</span> 
                <!-- <span v-else>{{toLocal(body.updated_at).fromNow()}}</span> -->
              </small>
              <span style="opacity:.4" class="text-opacity">|</span>
              <small v-show="body.updated_at">
                 <span v-if="saved">Updated {{toLocalWithTime(body.updated_at)}}</span> 
                <!-- <span v-else>{{toLocal(body.updated_at).fromNow()}}</span> -->
              </small>
              <small v-if="saving" style="color:grey">              
                <span
                class="spinner-grow spinner-grow-sm mr-3 text-light"
                style="background:grey"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </span>
              Saving your note
              </small>
              <small v-if="!body.updated_at && saved" style="color:green">Note saved a minute ago</small>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import TasksModal from "@/components/Modals/Tasks";
import DeleteModal from "@/components/Modals/Delete";
import Quill from "quill";
import { toLocal,toLocalWithTime } from "@/helpers/date";

export default {
  data() {
    return {
      content: "",
      selectedText: "",
      myText: "",
      body: {
        title: "",
        project_id: this.$route.params.id,
        updated_at:"",
        notes: "",
      },
      saving: false,
      saved:false,
      showTaskModal: false,
      taskDesc: "",
      textPosition: "",
      contentOld: "",
      showDeleteModal: false,
      showDeleteName : '',
    };
  },
  computed: {
    project() {
      return this.$store.getters["projects/project"];
    },
  },
  components: { TasksModal, DeleteModal },
  methods: {
    toLocal,
    toLocalWithTime,
    closeModal() {
      this.content = this.contentOld
    },
    async renderText(event) {
      console.log(this.content)
      event.preventDefault();

      this.textPosition = this.$refs.myQuillEditor.quill.getSelection();
      var selectedContent = this.$refs.myQuillEditor.quill.getContents(
        this.textPosition.index,
        this.textPosition.length
      );
      this.clearSelection();
      

      this.taskDesc = selectedContent["ops"][0]["insert"];
      console.log(this.taskDesc)
      this.showTaskModal = true;
      this.contentOld = this.content;
    },
    async clearSelection() {
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      } else if (document.selection) {
        document.selection.empty();
      }
    },
    async saveTask(s) {
      this.showTaskModal = false;

      const API = window.location.origin;

      let url = API + "/tasks/preview/" + s.id + "?from_path=tasks";
      document.querySelector(".ql-editor").innerHTML = this.contentOld;

      let textToFilter = this.content;
      textToFilter = textToFilter.replace(/<\/?[^>]+(>|$)/g, "");
      let newText = this.contentOld.replace(
        textToFilter,
        `<a href=${url}>${textToFilter}</a>`
      );
      document.querySelector(".ql-editor").innerHTML = newText;

      // mainText[0].innerHTML = newText;

      this.onChange(null, newText);
    },
    async onChange(operation, content = null) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      if (this.saving) return;
      this.content = content ? content : this.content;
      this.body.notes = this.content;

      if (this.content.length > 2) {
        // instantiate a new request
        this.saving = true;
        this.saved = false;

        //update notes
        this.$store
          .dispatch("projects/createNote", this.body)
          .then((s) => {
            this.body.id
              ? false
              : this.$store.dispatch(
                  "projects/getNotes",
                  this.$route.params.id
                );
            this.body.id = s.id;
            this.body.updated_at = s.updated_at
            this.saved = true;
          })
          .catch((err) => {
            this.$toast.error("Please fill in Title of note to proceed");
          })
          .finally(() => (this.saving = false));
      }
    },
    processEditOperation: function (operation) {
      // await new Promise(resolve => setTimeout(resolve, 1000));
      this.text = operation.api.origElements.innerHTML;
    },
    deleteNoteState() {
      this.showDeleteModal = true;
      this.showDeleteName = "Note";
    },
    cancelDelete() {
      this.showDeleteModal = false;
    },
    deleteNote() {
      let data = {'project_id': this.$route.params.id, 'note_id': this.$route.query.id }
      this.$store
        .dispatch("projects/deleteNote", data)
        .then(s => {
          this.$router.push({ name: "projects-show-notes" });
          this.$toast.success("🚮 Project Successfully Deleted", {
            position: "top-right",
            duration: 3000
          });
        });
    }
  },
  created() {
    // console.log(HighlightButton)

    window.taskHelper = this;
    let route = this.$route;
    if (route && route.query && route.query.id) {
      let data = {
        note_id: route.query.id,
        project_id: route.params.id,
      };
      this.$store.dispatch("projects/getSingleNote", data).then((s) => {
        this.body = s.data;
        this.content = this.body.notes;
        this.saved = '';
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.ql-container {
  height: 500px !important;
}
.medium-editor {
  label {
    opacity: 0.6;
  }
  input {
    height: 100px;
    border: none;
    border-bottom: 1px solid #f7f7f7;
    font-size: 1.75rem;

    &::placeholder {
      color: #bbb;
    }
  }
  .medium-editor-container .medium-editor-element:empty,
  .medium-editor-container .medium-editor-placeholder {
    font-size: 30px !important;
  }
  .medium-editor-element {
    outline: none;
    min-height: 120px;
    padding-top: 20px;
    font-family: "sansRegular";

    &::after {
      color: #bbb;
      font-weight: 100;
      font-family: "sansRegular";
    }
  }
  .medium-editor-placeholder:after {
    font-style: normal;
  }
}
.editable {
  position: relative;
  .editable-field {
    font-size: 1.25rem;
    border: 2px solid #f5f5f5;
    outline: none;
    resize: none;
    height: min-content;

    &:focus {
      outline: none;
    }
    &:hover {
      border: 2px solid #f5f5f5;
    }
  }
  .editable-save {
    visibility: hidden;
    background: #e4e4e4;
    border: none;
    height: 35px;
    border-radius: 10px;
    position: absolute;
    bottom: -35px;
    z-index: 5;

    &:hover {
      background: #2c3f58;
      color: white !important;
      h6 {
        color: white !important;
      }
    }
  }
  &:focus-within {
    .editable-save {
      visibility: visible;
    }
  }
}
.overlay {
  position: fixed;
  background: linear-gradient(rgba(20, 20, 20, 0.8), rgba(20, 20, 20, 0.8));
  height: 100%;
  top: 0;
  width: 100%;
  z-index: 4;
  left: 0;

  .scrollable {
    max-height: 98vh;
    overflow-y: scroll;
  }
  .back-btn {
    width: 200px;
    position: absolute;
    z-index: 100;
    margin-top: 30px;
    left: 0;
    top: 0;

    #backLink {
      &:hover {
        opacity: 1 !important;
      }
    }
  }
  .task-paned {
    background: linear-gradient(rgba(20, 20, 20, 0.8), rgba(20, 20, 20, 0.8));
    height: inherit;

    .task-wrapper {
      max-width: 100vw !important;
      width: 100vw !important;

      .scrollable {
        width: 600px !important;
        margin: auto;
      }
    }
  }
  .task-wrapper {
    background: white;
    position: absolute;
    width: 95vw;
    right: 0;
    height: 100vh;
  }
  .task-wrapperx {
    position: absolute;
    height: 100vh;
    width: 100%;
  }
}
.text-gray {
  color: #2c3f58;
}
button {
  &.btn-icon {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    border: 0;
    &:hover {
      background: #f7f7f7;
    }
  }
  &.btn-action {
    height: 40px;
    border-radius: 5px;
    border: 0px;
    &:hover {
      background: #f7f7f7;
    }
  }
}
</style>