<template>
  <!-- Modal -->
  <b-modal
    @hide="close"
    :no-close-on-backdrop="true"
    ref="user-create-modal"
    class="deletemodal"
  >
    <template v-slot:modal-header>
      <h2 class="pl-3 pt-3 pr-3 f-24">
        {{ user && user.id ? "Edit User" : "Create New User" }}
      </h2>
      <button class="close" @click="close">
        Cancel
        <i class="zmdi zmdi-close-circle pl-2" style="font-size: 20px"></i>
      </button>
    </template>
    <div class="d-block pl-3">
      <form class="d-form" @submit.prevent="save">
        <div class="form-accordion">
          <accordion
            @toggleCollapse="toggleCollapse(0)"
            :visible="accordions[0].visible"
            :name="'Employee details'"
          >
            <div slot="content">
              <div class="row">
                <div class="col-sm-6 col-lg-6 mt-1">
                  <h6 class="text-bold text-opacity-pale">
                    First Name
                    <b>*</b>
                  </h6>
                  <input
                    type="text"
                    v-validate="'required'"
                    v-model="body.first_name"
                    name="first_name"
                    class="form-control auth-input d-input"
                    placeholder="Enter first name"
                    data-vv-as="First Name"
                    :disabled="!!body.id"
                  />
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('first_name')"
                    >{{ errors.first("first_name") }}</small
                  >
                </div>
                <div class="col-sm-6 col-lg-6 mt-1">
                  <h6 class="text-bold text-opacity-pale">
                    Last Name
                    <b>*</b>
                  </h6>
                  <input
                    type="text"
                    v-validate="'required'"
                    v-model="body.last_name"
                    name="last_name"
                    class="form-control auth-input d-input"
                    placeholder="Enter last name"
                    data-vv-as="Last Name"
                    :disabled="!!body.id"
                  />
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('last_name')"
                    >{{ errors.first("last_name") }}</small
                  >
                </div>
                <div class="col-sm-6 col-lg-6 mt-4">
                  <h6 class="text-bold text-opacity-pale">
                    Email Address <b>*</b>
                  </h6>
                  <input
                    :disabled="!!body.id"
                    class="form-control auth-input d-input"
                    data-vv-as="Employee Email"
                    name="email"
                    placeholder="Enter Employee email"
                    type="email"
                    v-model="body.email"
                    v-validate="'required|email'"
                  />
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('email')"
                    >{{ errors.first("email") }}</small
                  >
                </div>
                <div class="col-sm-6 col-lg-6 mt-4">
                  <h6 class="text-bold text-opacity-pale">
                    Role
                    <b>*</b>
                  </h6>
                  <multiselect
                    track-by="id"
                    return="id"
                    label="name"
                    v-model="body.role"
                    v-validate="'required'"
                    :options="roles"
                    :show-labels="false"
                    name="role_id"
                    data-vv-as="Role"
                    :disabled="optional($auth.user(), 'org_role.role_id') !== 1"
                  ></multiselect>
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('role_id')"
                    >{{ errors.first("role_id") }}</small
                  >
                </div>
                <div class="col-sm-6 col-lg-6 mt-4">
                  <h6 class="text-bold text-opacity-pale">Employee Status</h6>
                  <multiselect
                    track-by="id"
                    label="name"
                    v-model="body.status"
                    :options="statuses"
                    :show-labels="false"
                    name="is_active"
                    data-vv-as="Status"
                    :disabled="optional($auth.user(), 'org_role.role_id') != 1"
                  ></multiselect>
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('is_active')"
                    >{{ errors.first("is_active") }}</small
                  >
                </div>
                <div class="col-sm-6 col-lg-6 mt-4">
                  <h6 class="text-bold text-opacity-pale">Employee Type</h6>
                  <multiselect
                    track-by="id"
                    label="name"
                    v-model="body.employee_type"
                    :options="employee_types"
                    :show-labels="false"
                    name="employee_type_id"
                    data-vv-as="Employee Type"
                    :disabled="optional($auth.user(), 'org_role.role_id') != 1"
                  ></multiselect>
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('employee_type_id')"
                    >{{ errors.first("employee_type_id") }}</small
                  >
                </div>
                <div
                  class="col-sm-6 col-lg-6 mt-4"
                  v-if="
                    body.employee_type.id == 2 || body.employee_type.id == 3
                  "
                >
                  <h6 class="text-bold text-opacity-pale">Hourly Rate</h6>
                  <input
                    type="number"
                    v-model="body.hourly_rate"
                    name="hourly_rate"
                    class="form-control auth-input d-input"
                    placeholder="Enter rate"
                    data-vv-as="Rate"
                    :disabled="optional($auth.user(), 'org_role.role_id') != 1"
                  />
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('hourly_rate')"
                    >{{ errors.first("hourly_rate") }}</small
                  >
                </div>
                <div
                  class="col-sm-6 col-lg-6 mt-4"
                  v-if="body.employee_type.id == 1"
                >
                  <h6 class="text-bold text-opacity-pale">Salary Rate</h6>
                  <input
                    type="number"
                    v-model="body.salary_rate"
                    name="salary_rate"
                    class="form-control auth-input d-input"
                    placeholder="Enter salary rate"
                    data-vv-as="Salary Rate"
                    :disabled="optional($auth.user(), 'org_role.role_id') != 1"
                  />
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('salary_rate')"
                    >{{ errors.first("salary_rate") }}</small
                  >
                </div>
                <div
                  :class="{
                    'col-sm-12': !body.employee_type.id,
                    'col-sm-6': body.employee_type.id,
                    'col-lg-12': !body.employee_type.id,
                    'col-lg-6': body.employee_type.id,
                    'mt-4': true,
                  }"
                >
                  <h6 class="text-bold text-opacity-pale">Default Currency</h6>
                  <multiselect
                    track-by="id"
                    return="id"
                    label="name"
                    v-model="body.currency"
                    :custom-label="currencySelectLabel"
                    name="currency_id"
                    :options="currencies"
                    :show-labels="false"
                    data-vv-as="Currency"
                    placeholder="Select Default Currency"
                    :disabled="optional($auth.user(), 'org_role.role_id') != 1"
                  ></multiselect>
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('default_currency_id')"
                    >{{ errors.first("default_currency_id") }}</small
                  >
                </div>
                <div class="col-sm-6 col-lg-6 mt-4">
                  <h6 class="text-bold text-opacity-pale">Hire Date</h6>
                  <datetime
                    v-model="body.hire_date"
                    :type="'date'"
                    input-class="form-control auth-input d-input"
                    name="hire_date"
                    placeholder="Hire Date"
                    data-vv-as="Hire Date"
                  />
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('hire_date')"
                    >{{ errors.first("hire_date") }}</small
                  >
                </div>
                <div class="col-sm-6 col-lg-6 mt-4 date-range">
                  <h6 class="text-bold text-opacity-pale">Departure Date</h6>
                  <datetime
                    v-model="body.departure_date"
                    :type="'date'"
                    input-class="form-control auth-input d-input"
                    name="departure_date"
                    placeholder="Depature Date"
                    data-vv-as="Depature Date"
                  />

                  <small
                    class="form-text text-danger"
                    v-show="errors.has('departure_date')"
                    >{{ errors.first("departure_date") }}</small
                  >
                </div>
                <div class="col-sm-12 col-lg-12 mt-4">
                  <h6 class="text-bold text-opacity-pale">Notes</h6>
                  <quill-editor
                    ref="myQuillEditor"
                    class="mt-1"
                    v-model="body.notes"
                    @ready="_html(body.notes)"
                    placeholder="Additional Note"
                  />
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('description')"
                    >{{ errors.first("description") }}</small
                  >
                </div>
              </div>
            </div>
          </accordion>
          <accordion
            @toggleCollapse="toggleCollapse(1)"
            :visible="accordions[1].visible"
            :name="'Contact Information'"
          >
            <div slot="content">
              <div class="row">
                <div class="col-sm-12 col-lg-12 mt-1">
                  <h6 class="text-bold text-opacity-pale">Phone Number</h6>
                  <div class="row">
                    <div class="col-lg-12 d-contact" style="margin-top: 1px">
                      <span>
                        <multiselect
                          v-model="body.ocountry"
                          track-by="id"
                          class="mobile_dropdown"
                          return="id"
                          :custom-label="countriesDialCode"
                          :disabled="!!body.id"
                          label="d_code"
                          name="d_code"
                          placeholder="Dial No."
                          :options="countries"
                          :show-labels="false"
                          data-vv-as="Dial Code"
                        >
                          <template slot="option" slot-scope="props">
                            <span class="text-opacity"
                              >({{ props.option.d_code }})</span
                            >
                            {{ props.option.name }}
                          </template>
                          <template slot="singleLabel" slot-scope="props">{{
                            props.option.d_code
                          }}</template>
                        </multiselect>
                      </span>
                      <span>
                        <input
                          class="form-control auth-input d-input"
                          data-vv-as="Employee Phone Number"
                          name="phone_number"
                          placeholder="Enter Employee phone number"
                          type="tel"
                          v-model="body.phone_number"
                          :disabled="!!body.id"
                        />
                      </span>
                    </div>
                  </div>
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('d_code')"
                    >{{ errors.first("d_code") }}</small
                  >
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('phone_number')"
                    >{{ errors.first("phone_number") }}</small
                  >
                </div>
              </div>
            </div>
          </accordion>

          <accordion
            @toggleCollapse="toggleCollapse(2)"
            :visible="accordions[2].visible"
            :name="'Address Information'"
          >
            <div slot="content">
              <div class="row">
                <div class="col-sm-12 col-lg-12 mt-1">
                  <div class="row">
                    <div class="col-sm-12 col-lg-12">
                      <h6 class="text-bold text-opacity-pale">Street</h6>
                      <input
                        type="text"
                        name="recipient_address_address"
                        v-model="body.recipient_address.address"
                        class="form-control auth-input d-input"
                        placeholder="Enter stress address"
                        data-vv-as="Recipient Street Address"
                      />
                      <small
                        class="form-text text-danger"
                        v-show="errors.has('recipient_address_address')"
                        >{{ errors.first("recipient_address_address") }}</small
                      >
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-sm-12 col-lg-6">
                      <h6 class="text-bold text-opacity-pale">Country</h6>
                      <multiselect
                        track-by="id"
                        return="id"
                        label="name"
                        v-model="body.recipient_address.country"
                        @input="setStates(body.recipient_address.country)"
                        name="country_id"
                        :options="countries"
                        :show-labels="false"
                        data-vv-as="Country"
                      ></multiselect>
                      <small
                        class="form-text text-danger"
                        v-show="errors.has('country_id')"
                        >{{ errors.first("country_id") }}</small
                      >
                    </div>
                    <div class="col-sm-12 col-lg-6">
                      <h6 class="text-bold text-opacity-pale">State</h6>
                      <multiselect
                        track-by="id"
                        return="id"
                        label="name"
                        name="recipient_address_region_id"
                        v-model="body.recipient_address.region"
                        :options="states"
                        :show-labels="false"
                        @input="setCities(body.recipient_address.region)"
                        data-vv-as="State"
                      ></multiselect>
                      <small
                        class="form-text text-danger"
                        v-show="errors.has('recipient_address_region_id')"
                        >{{
                          errors.first("recipient_address_region_id")
                        }}</small
                      >
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-sm-12 col-lg-6 mt-1">
                      <h6 class="text-bold text-opacity-pale">City</h6>

                      <multiselect
                        v-if="cities.length"
                        track-by="id"
                        return="id"
                        label="name"
                        name="recipient_address_city_id"
                        v-model="body.recipient_address.city"
                        :options="cities"
                        :show-labels="false"
                        data-vv-as="City"
                      ></multiselect>

                      <input
                        v-else
                        type="text"
                        v-model="body.recipient_address.city"
                        name="recipient_address_city"
                        class="form-control auth-input d-input"
                        placeholder="Enter City"
                        data-vv-as="State City"
                      />

                      <small
                        class="form-text text-danger"
                        v-show="errors.has('recipient_address_city_id')"
                        >{{ errors.first("recipient_address_city_id") }}</small
                      >
                    </div>

                    <div class="col-sm-12 col-lg-6 mt-1">
                      <h6 class="text-bold text-opacity-pale">Zip Code</h6>
                      <input
                        type="text"
                        v-model="body.recipient_address.post_code"
                        class="form-control auth-input d-input"
                        placeholder="Enter shipping zip code"
                        data-vv-as="Zip Code"
                      />
                      <small
                        class="form-text text-danger"
                        v-show="errors.has('bank_address_post_code')"
                        >{{ errors.first("bank_address_post_code") }}</small
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </accordion>
        </div>
      </form>
    </div>
    <template v-slot:modal-footer>
      <dashboard-button
        :disabled="optional($auth.user(), 'org_role.role_id') != 1"
        @submit="save"
        :loading="loading"
        :text="user && user.id ? 'Save' : 'Save'"
      ></dashboard-button>
    </template>
  </b-modal>
</template>

<script>
import DashboardButton from "@/components/Buttons/Dashboard";
import Literals from "@/helpers/dashboard/literals";
import Accordion from "@/components/Collapse/Index";
import getOrganization from "@/helpers/getOrganization";
import handleError from "@/helpers/error";
import { optional } from "@/helpers/random";
import { Datetime } from "vue-datetime";

export default {
  name: "UserModal",
  data() {
    return {
      Literals,
      loading: false,
      actionCreateUser: false,
      accordions: [
        { name: "basic", visible: true },
        { name: "contact", visible: true },
        { name: "address", visible: true },
        { name: "payment", visible: false },
        { name: "others", visible: true },
      ],
      statuses: [
        { id: true, name: "Active" },
        { id: false, name: "Inactive" },
      ],
      states: [],
      cities: [],
      passwordType: "password",
      body: {
        first_name: "",
        last_name: "",
        status_id: "",
        email: "",
        phone_number: "",
        hourly_rate: 0,
        role: "",
        type: {},
        country: {},
        ocountry: "",
        status: { id: true, name: "Active" },
        employee_type: "",
        currency: "",
        hire_date: "",
        departure_date: "",
        notes: "",
        recipient_address: {
          city: "",
          region: "",
          country: "",
        },
      },
    };
  },
  components: { Datetime, Accordion, DashboardButton },
  props: ["employee", "user"],
  methods: {
    handleError,
    optional,
    countriesDialCode({ d_code, name }) {
      return `(${d_code})  ${name}`;
    },
    close() {
      return this.$router.go(-1);
    },
    toggleCollapse(index) {
      this.accordions[index].visible =
        this.accordions[index].visible === false ? true : false;
    },
    togglePassword() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    currencySelectLabel({ code, name, symbol }) {
      return ` ${name} (${symbol} ${code}) `;
    },
    save() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          if (this.body.first_name.match(/^[0-9]+$/) != null) {
            return this.errors.add({
              field: "first_name",
              msg: "First name cannot be all numbers",
            });
          } else if (this.body.last_name.match(/^[0-9]+$/) != null) {
            return this.errors.add({
              field: "last_name",
              msg: "Last name cannot be all numbers",
            });
          }
          this.loading = true;
          if (this.body.employee) {
            this.submitEmployee();
          } else {
            this.submit();
          }
        }
      });
    },

    async submitEmployee(user_id) {
      let userData = {
        role_id: this.body.role ? this.body.role.id : null,
      };

      let data = {
        organization_id: getOrganization.organization_id(),
        default_currency_id: this.body.currency ? this.body.currency.id : false,
        employee_type_id: this.body.employee_type.id,
        user_id: user_id,
        hourly_rate: this.body.hourly_rate,
        salary_rate: this.body.salary_rate,
        notes: this.body.notes,
        hire_date: this.body.hire_date,
        depature_date: this.body.departure_date,
      };

      if (this.body.employee_id) {
        data.id = this.body.employee_id;
      }

      let msgPass = data.id
        ? Literals.EMPLOYEE_UPDATE_SUCCESS
        : Literals.EMPLOYEE_CREATE_SUCCESS;

      let recipient_address = this.fillAddress(this.body.recipient_address);

      if (recipient_address) {
        await this.$store
          .dispatch("global/createUpdateAddress", recipient_address)
          .then((id) => {
            data.address_id = id;
          })
          .catch((error) => {
            let msgPass = `Failed to ${
              recipient_address.id ? "update" : "create"
            } address`;

            this.handleError(this, error, msgPass);
            this.loading = false;
          });
      }

      if (this.loading) {
        if (this.body.id) {
          msgPass = Literals.USER_UPDATE_SUCCESS;
          userData.id = this.body.id;
        }

        this.$store
          .dispatch("employees/createUpdateUser", userData)
          .then(() => {
            this.$store
              .dispatch("employees/createUpdate", data)
              .then((data) => {
                this.$toast.success(msgPass, {
                  position: "top-right",
                  duration: 3000,
                });
                data.id
                  ? this.$router.push({
                      name: "users-show",
                      params: { id: data.user_id },
                    })
                  : this.$router.push({ name: "users-index" });
              })
              .catch((error) => {
                let errorMessage = data.id
                  ? Literals.EMPLOYEE_UPDATE_ERROR
                  : Literals.EMPLOYEE_CREATE_ERROR;

                this.handleError(this, error, errorMessage);
              });
          })
          .catch((error) => {
            let errorMessage = data.id
              ? Literals.EMPLOYEE_UPDATE_ERROR
              : Literals.EMPLOYEE_CREATE_ERROR;

            this.handleError(this, error, errorMessage);
          });
      }
    },
    async submit() {
      let data = {
        is_active: this.body.status ? this.body.status.id : true,
        country_id: this.body.country ? this.body.country.id : null,
        role_id: this.body.role ? this.body.role.id : null,
        phone_number: this.body.phone_number,
        country_code: this.body.ocountry ? this.body.ocountry.d_code : null,
        first_name: this.body.first_name,
        last_name: this.body.last_name,
        email: this.body.email,
        employee_type_id: this.body.employee_type
          ? this.body.employee_type.id
          : null,
        organization_id: getOrganization.organization_id(),
        default_currency_id: this.body.currency ? this.body.currency.id : null,
        hourly_rate: this.body.hourly_rate,
        salary_rate: this.body.salary_rate,
        hire_date: this.body.hire_date,
        depature_date: this.body.departure_date,
        notes: this.body.notes,
      };

      let msgPass = Literals.USER_CREATE_SUCCESS;

      if (this.body.id) {
        msgPass = Literals.USER_UPDATE_SUCCESS;
        data.id = this.body.id;
      }

      let recipient_address = this.fillAddress(this.body.recipient_address);

      if (recipient_address) {
        await this.$store
          .dispatch("global/createUpdateAddress", recipient_address)
          .then((id) => {
            data.address_id = id;
          })
          .catch((error) => {
            let msgPass = `Failed to ${
              recipient_address.id ? "update" : "create"
            } recipient address`;

            this.handleError(this, error, msgPass);
            this.loading = false;
          });
      }

      if (this.loading) {
        this.$store
          .dispatch("employees/createUpdateUser", data)
          .then((user) => {
            let msg = data.id
              ? Literals.EMPLOYEE_UPDATE_SUCCESS
              : Literals.EMPLOYEE_CREATE_SUCCESS;
            this.$toast.success(msg, {
              position: "top-right",
              duration: 3000,
            });

            this.$router.go(-1);
          })
          .catch((error) => {
            let errorMessage = this.body.id
              ? Literals.USER_UPDATE_ERROR
              : optional(error, "data.message", Literals.USER_CREATE_ERROR);

            this.handleError(this, error, errorMessage);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    setStates(selectedCountry) {
      let country_states =
        selectedCountry &&
        selectedCountry.regions &&
        selectedCountry.regions.data
          ? selectedCountry.regions.data
          : [];
      this.states = country_states;
    },
    setCities(state) {
      this.$store.dispatch("global/getState", state.id).then((data) => {
        let state_cities = data && data.cities && data.cities.data;

        this.cities = state_cities;
        if (!this.cities.length) this.body.recipient_address.city = "";
      });
    },
    fillAddress(rawAddress) {
      let parsedAddress = {};
      if (rawAddress) {
        parsedAddress = {
          address: rawAddress.address,
          region_id: rawAddress.region ? rawAddress.region.id : null,
          country_id: rawAddress.country ? rawAddress.country.id : null,
          post_code: rawAddress.post_code,
        };

        rawAddress.city && rawAddress.city.id
          ? (parsedAddress.city_id = rawAddress.city.id)
          : (parsedAddress.city = rawAddress.city);

        rawAddress.city && rawAddress.id
          ? (parsedAddress.id = rawAddress.id)
          : null;
      }
      return parsedAddress.address &&
        parsedAddress.region_id &&
        parsedAddress.country_id
        ? parsedAddress
        : null;
    },
    formatData() {
      if (this.user) {
        Object.assign(this.body, this.user);
        this.body.ocountry = this.countries.filter(
          (s) => s.d_code === this.body.country_code
        )[0];
      }

      if (this.employee) {
        this.body.status = this.statuses.filter(
          (s) => s.id === this.employee.is_active
        )[0];
        this.body.employee_id = this.employee.id;
        this.body.employee_type = this.employee.employee_type || {};
        this.body.hourly_rate = this.employee.hourly_rate;
        this.body.salary_rate = this.employee.salary_rate;
        this.body.currency = this.employee.default_currency;
        this.body.hire_date = this.employee.hire_date;
        this.body.departure_date = this.employee.hire_date;
      }
      if (this.body.password) this.body.confirm_password = this.body.password;

      if (this.$route.name == "users-edit") {
        let userEmployee = this.$store.getters["employees/employee"];

        this.body.recipient_address = optional(userEmployee, "address.data", {
          city: "",
          region: "",
          country: "",
        });

        this.body.recipient_address.city = optional(
          userEmployee,
          "address.data.city.name",
          null
        );

        this.body.hire_date = optional(userEmployee, "hire_date", "");
        this.body.depature_date = optional(userEmployee, "departure_date", "");
        this.body.notes = optional(userEmployee, "notes", "");
      }
    },
  },

  computed: {
    countries() {
      return this.$store.getters["global/countries"];
    },
    currencies() {
      return this.$store.getters["global/currencies"];
    },
    roles() {
      return this.$store.getters["global/roles"];
    },
    employee_types() {
      return this.$store.getters["global/employee_types"];
    },
  },
  mounted() {
    this.$refs["user-create-modal"].show();
  },

  created() {
    Promise.all([
      this.roles.length || this.$store.dispatch("global/getRoles"),
      this.countries.length || this.$store.dispatch("global/getCountries"),
      this.currencies.length || this.$store.dispatch("global/getCurrencies"),
      this.employee_types.length ||
        this.$store.dispatch("global/getEmployeeTypes"),
    ]).then(() => {
      let userOrgObject = this.$auth.user() && this.$auth.user().organization;

      if (userOrgObject && userOrgObject.data) {
        this.body.currency = this.currencies.find(
          (element) => element.id == userOrgObject.data.default_currency_id
        );
      }

      if (this.$route.name == "users-create") {
        this.actionCreateUser = true;
        let roles = this.$store.getters["global/roles"];
        let employeeTypes = this.$store.getters["global/employee_types"];

        this.body.role = roles.find((e) => e.id == 2 && e.name == "Employee");
        this.body.employee_type = employeeTypes.find((e) => e.id == 1);
        this.body.hire_date = new Date().toISOString();
      }
    });

    this.formatData();
  },
};
</script>
