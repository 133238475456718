<template>
  <div class="container-fluid">
    <div class="table pb-4 pl-3 pr-4">
      <div class="table-header">
        <div class="row">
          <div class="col-sm-12 col-lg-4 float-left text-left">
            <small class="text-opacity">{{paginationData.total}} total opportunities</small>
          </div>

          <div class="col-sm-12 col-lg-8 float-right text-right">
            <opportunities-filters
              @setFilters="setFilters"
              @showMoreFilters="showVisibleFilter"
              @removeColumnFromTable="removeColumnFromTable"
              :columns="columns"
              :params="moreParams"
              :visibleFilter="visibleFilter"
            ></opportunities-filters>
          </div>
        </div>
        <div class="text-right mt-3" v-show="visibleFilter">
          <button
            @click="moreParams = {}"
            class="p-2 pl-3 pr-3"
            style="background:#E4E4E4; font-size:13px; border-radius:50px; border:none"
          >Reset Filter</button>
        </div>
        <opportunities-filters-dropdown
          @setFilters="setFilters"
          :params="moreParams"
          :billingTypes="billing_types"
          :stages="opportunity_stages"
          :visibleFilter="visibleFilter"
        ></opportunities-filters-dropdown>
      </div>
      <div class="table-wrapper mt-4">
        <vuetable
          ref="vuetable"
          :api-url="api"
          :first-page="0"
          :append-params="moreParams"
          :http-options="{ headers:{ 'Authorization' : 'Bearer ' + $auth.token(),'xorg' : getOrganization.organization_id() } }"
          @vuetable:loaded="loadTable"
          @vuetable:cell-clicked="gotoOpportunity"
          :fields="columns.filter(s => s.active === true)"
          data-path="data"
          :no-data-template="rowCount === null ? 'Loading data' : 'No Data Available'"
          pagination-path
          @vuetable:pagination-data="onPaginationData"
        >
          <template
            slot="projected_revenue"
            slot-scope="props"
          >{{props.rowData && props.rowData.currency && props.rowData.currency.symbol}} {{props.rowData.projected_revenue}}</template>
          <template slot="cust" slot-scope="props">
            <router-link
              v-if="props.rowData.customer && props.rowData.customer.data"
              :to="{name: 'customers-show', params: {id: props.rowData.customer.data.id}}"
            >{{props.rowData.customer.data.company_name}}</router-link>
          </template>
          <template slot="actions" slot-scope="props">
            <router-link :to="{name: 'opportunities-show', params: {id: props.rowData.id}}">
              <button class="btn-lightgray-round">
                <i class="zmdi zmdi-plus pr-2"></i>View Opportunity
              </button>
            </router-link>
          </template>
        </vuetable>
        <vuetable-pagination
          v-show="paginationData.total"
          :css="table_css.pagination"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
        <div class="text-center" v-if="rowCount <=0 || rowCount === null">
          <slot name="empty"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import getOrganization from "@/helpers/getOrganization";
import OpportunitiesFilters from "@/components/Filters/Opportunities/Index";
import OpportunitiesFiltersDropdown from "@/components/Filters/Opportunities/More";
import VuetablePagination from "@/components/Layouts/VueTablePagination";
import getPaginationData from "@/helpers/pagination";

export default {
  components: {
    OpportunitiesFilters,
    OpportunitiesFiltersDropdown,
    VuetablePagination
  },
  data() {
    return {
      getOrganization,
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      api:
        this.$baseApi +
        `/opportunities?include=customer,employee,opportunity_stage,currency,creator`,
      loadingText: "Fetching data...",
      columns: [
        {
          name: "__checkbox",
          titleClass: "center aligned",
          dataClass: "center aligned",
          cvisible: false,
          active: true
        },
        {
          name: "name",
          title: "Opportunity Name",
          cvisible: true,
          active: true
        },
        {
          name: "__slot:cust",
          title: "Customer",
          cvisible: true,
          active: true
        },
        {
          name: "opportunity_stage.name",
          title: "Stage",
          cvisible: true,
          active: true,
          sortable: true
        },
        {
          name: "estimated_hours",
          title: "Estimated Hours",
          cvisible: true,
          active: true
        },
        {
          name: "__slot:projected_revenue",
          title: "Projected Revenue",
          cvisible: true,
          active: true
        },
        {
          name: "__slot:actions",
          title: "Actions",
          active: true,
          cvisible: false
        }
      ],
      moreParams: {},
      paginationData: {},
      filterColumns: []
    };
  },
  methods: {
    getPaginationData,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    gotoOpportunity(e) {
      this.$router.push({
        name: "opportunities-show",
        params: { id: e.id }
      });
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
    },
    setFilters(d, l) {
      if (this.moreParams && this.moreParams[l] == "") {
        delete this.moreParams[l];
      }
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onChangePage(page) {
      this.moreParams.page = page;
      this.setFilters();
    },
    refreshTable() {
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find(s => s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    }
  },
  computed: {
    billing_types() {
      return this.$store.getters["global/project_billing_types"];
    },
    opportunity_stages() {
      return this.$store.getters["global/opportunity_stages"];
    }
  },
  created() {
    this.billing_types.length ||
      this.$store.dispatch("global/getProjectBillingTypes");
    this.opportunity_stages.length ||
      this.$store.dispatch("global/getOpportunityStages");
  },
  watch: {
    $route(to, from) {
      this.refreshTable();
    }
  }
};
</script>

