<template>
  <invoice-table :api="api" :more-params="moreParams"></invoice-table>
</template>
<script>
import InvoiceTable from "@/components/Tables/Invoice/Index.vue";
export default {
  components: {InvoiceTable},
  data() {
    return {
      moreParams: {
        customer_id: this.$route.params.id
      },
      api: `${this.$baseApi}/invoices`,
    };
  },
  methods: {

  }
};
</script>

