<template>
  <div>
    <delete-modal
      @trigger="deleteState"
      @cancel="cancelDelete"
      @deleteAction="deleteItem"
      :showDelete="showDelete"
      :name="showDeleteName"
      ref="delete"
    ></delete-modal>
    <div class="mt-2 upload-wrapper" @click="uploadErrors = ''">
      <vue-dropzone :key="uploader"
        ref="attachments"
        id="dropzone"
        :options="dropzoneOptions"
        :useCustomSlot="true"
        @vdropzone-sending="sendingEvent"
        @vdropzone-files-added="setFileStatus"
        @vdropzone-error="updateUploadError"
        @vdropzone-total-upload-progress="setProgressStatus"
        @vdropzone-success="updateFileStatus"
      >
        <div class="dropzone-custom-content" >
          <h6 class="h6" style="font-size:inherit" v-show="!uploading && !uploadErrors && !uploaded">Drag-n-drop your file or</h6>
          <button v-show="!uploading && !uploaded && !uploadErrors" class="btn btn-primary" style="width:100%" ref="upload">Choose File</button>
        </div>
      </vue-dropzone>
     
     <div class="progress-u-bar" v-if="uploadErrors">
      <h6 class="h6" style="color:red">{{uploadErrors}}</h6>
    </div>
      <div class="progress-u-bar" v-else-if="uploading">
        <h6 class="h6">Uploading your file,Please hold on.</h6>
      </div>
     
      <div class="progress-u-bar" v-else-if="uploaded">
        
        <h6 class="h6">💪 File Successfully Uploaded</h6>
      </div>
       
      <div class="progress" v-if="uploading">
        <div
          class="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          :aria-valuenow="uploadNumber"
          aria-valuemin="0"
          aria-valuemax="100"
          :style="'width:' + uploadNumber + '%'"
        ></div>
      </div>
    </div>

   
    <div class="mt-4">
      <h6 class="h6">Your Files</h6>
      <div class="mt-2" :class="{ blur: !loaded }">
        <div class v-if="attachments.length > 0">
          <div class="uploaded-images mt-3">
            <div class="u-img pl-3">
              <div class="row mt-2" :key="index" v-for="(att, index) in attachments">
                <div class="col-sm-10 main">
                  <a
                    class="text-opacity-pale text-bold h6"
                    :href="att.file_url"
                    target="_blank"
                    rel="nofollow"
                  >{{ att.file_name || att.id }}</a>
                  <span
                    class="text-opacity-pale text-bold float-right text-right h6"
                  >{{ toLocal(att.created_at).fromNow() }}</span>
                </div>

                <button
                  v-if="optional($auth.user(), 'org_role.role_id') === 1"
                  v-b-tooltip.hover
                  title="Delete Task Attachment"
                  @click="deleteState(att)"
                  class="btn-lightgray-round-secondary btn-primary ml-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M9 13v6c0 .552-.448 1-1 1s-1-.448-1-1v-6c0-.552.448-1 1-1s1 .448 1 1zm7-1c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm-4 0c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm4.333-8.623c-.882-.184-1.373-1.409-1.189-2.291l-5.203-1.086c-.184.883-1.123 1.81-2.004 1.625l-5.528-1.099-.409 1.958 19.591 4.099.409-1.958-5.667-1.248zm4.667 4.623v16h-18v-16h18zm-2 14v-12h-14v12h14z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="p-4 assignee-card mt-4" v-else>
          <h6 class="h6">No File has been uploaded yet!</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.h6 {
  font-size: 14px !important;
}
.u-img {
  .main {
    border-radius: 4px;
    background: #d7e1ea;
    padding: 10px;
  }
}
button {
  &.btn-icon {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    border: 0;
    &:hover {
      background: #f7f7f7;
    }
  }
  &.btn-action {
    height: 40px;
    border-radius: 5px;
    border: 0px;
    &:hover {
      background: #f7f7f7;
    }
  }
}
.assignee-card {
  background: #fafbfd;
  font-size: 14px;
  font-family: "sansMedium";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #254050;

  .as-card-img {
    height: 30px;
    width: 30px;
    border-radius: 30px;
  }
}
</style>

<script>
import { toLocal } from "@/helpers/date";
import getOrganization from "@/helpers/getOrganization";
import DeleteModal from "@/components/Modals/Delete";
import {optional, string} from "@/helpers/random";

export default {
  components: { DeleteModal },
  data() {
    return {
      addMore: false,
      loaded: false,
      uploading: false,
      uploaded: false,
      uploadNumber: 0,
      uploadedFiles: [],
      showDelete: false,
      showDeleteName: "",
      toDelete: null,
      uploadErrors: '',
      uploader: true
    };
  },
  methods: {
    toLocal,
    optional,

    setFileStatus(file) {
  
      this.addMore = false;
      this.uploading = true;
      this.uploaded = false;
      this.uploadNumber = 0;
    },
    setProgressStatus(f, i, b) {
      
      this.uploadNumber = f;
    },
    updateFileStatus(f) {
 
      this.uploaded = true;
      this.uploading = false;
      this.uploader = string();
    
      //this.uploadedFiles.push(f);
      let vm = this;
      setTimeout(() => {
        vm.uploadErrors = '';
        vm.addMore = false;
      vm.loaded = true;
      vm.uploading = false;
      vm.uploaded = false;
      vm.uploadNumber = 0;
      vm.uploadedFiles = [];
      vm.uploader = string()
      }, 3000);
      this.uploadErrors || this.fetchAttachments();
    },
    sendingEvent(file, xhr, formData) {
      
      formData.append("attachment", file);
      formData.delete("file");
    },
    updateUploadError(file, message, xhr) {
     
      this.uploadErrors = message;
      this.uploading = false;
      this.addMore = true;
      this.$refs.attachments.removeFile(file);
      this.updateFileStatus();
    },
    fetchAttachments() {
      this.loaded = false;
      this.$store.dispatch("tasks/getAttachments", this.taskId).then(() => {
        this.loaded = true;
      });
    },
    deleteState(att) {
      this.showDelete = true;
      this.showDeleteName = "Task attachment";
      this.toDelete = att;
    },
    cancelDelete() {
      this.showDelete = false;
    },
    deleteItem() {
      this.$store
        .dispatch("tasks/deleteAttachment", {
          task_id: this.taskId,
          id: this.toDelete.id
        })
        .then(s => {
          this.showDelete = false;
          this.showDeleteName = "";
          this.toDelete = null;

          this.fetchAttachments();
          this.$toast.success("🚮 Task attachment Successfully Deleted", {
            position: "top-right",
            duration: 3000
          });
        });
    },
    setActiveHover(id) {
      this.activeHover = this.activeHover == id ? null : id;
    }
  },
  computed: {
    dropzoneOptions() {
      return {
        url: `${this.$baseApi}/tasks/${this.taskId}/attachments`,
        thumbnailWidth: 150,
        maxFilesize: 5,
        maxFiles: 1,
        sendingMultiple: false,
        headers: {
          Authorization: "Bearer " + this.$auth.token(),
          xorg: getOrganization.organization_id()
        }
      };
    },
    attachments() {
      return this.$store.getters["tasks/attachments"];
    },
    taskId() {
      return this.$route.params.tid || this.$route.params.id;
    }
  },
  created() {
    this.uploader = string();
    this.fetchAttachments();
  }
};
</script>
