<template>

  <div>
    <delete-modal
      @trigger="setDeleteState"
      @cancel="cancelDelete"
      @deleteAction="deleteInvoive"
      :showDelete="showDelete"
      :name="showDeleteName"
      ref="delete"
    ></delete-modal>

    <section :class="{blur:!loaded}">
    <headers class="bg-ggray pb-5">
      <div slot="breadcrumbs">
        <breadcrumbs :items="breadCrumbs"></breadcrumbs>
      </div>
      <div slot="title">
        <h2># {{invoice.id || 'Not Available'}}</h2>
      </div>
      <div slot="action">
        <button
                type="button"
          @click="submit"
          :disabled="loading"
          class="btn text-primary btn-ds btn-outline mr-2"
        >Update</button>

        <button
                type="button"
                @click="submitAndContinue"
                :disabled="loading"
                class="btn btn-ds mr-2"
        >Continue</button>

        <button v-if="invoice.status_id == 1"
                type="button"
                @click="setDeleteState('Invoice',invoice.id)"
                :disabled="loading"
                class="btn btn-ds btn-danger"
        >Delete</button>
      </div>
    </headers>


    <div class="container-fluid ml-3 pr-5 mt-4">
      <div class="row">
        <div class="col-sm-12 col-lg-3">
          <accordion
                  @toggleCollapse="toggleCollapse(6)"
                  :visible="accordions[6].visible"
                  class="invoice-accordion invoice-summary"
                  :name="'INVOICE SUMMARY'"
          >
            <div slot="content" class="pl-2 pr-2 pb-2">
              <div class="invoice-summary__list mt-2">
                <div class="item">
                  <button class="blue">
                    <svg
                      width="25"
                      height="25"
                      xmlns="http://www.w3.org/2000/svg"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                    >
                      <path
                        d="M11.63 15.214h.625l.004-3.197h-.626l-.003 3.197zm-1.161-1.894h.626l.01-1.738h-.627l-.009 1.738zm2.36-.013h.627l-.01-1.725h-.626l.009 1.725zm-1.916-9.135c0 .57.461 1.031 1.031 1.031.57 0 1.031-.461 1.032-1.031-.001-.569-.462-1.03-1.032-1.031-.57 0-1.031.462-1.031 1.031zm1.546 2.577c0-.285-.23-.515-.515-.515-.285 0-.515.23-.515.515 0 .285.23.515.515.516.285-.001.515-.231.515-.516zm-4.86 5.268c-.332-2.929 1.206-4.092 1.837-4.711l-.002-.009c-.129-.85-.692-4.502 2.503-7.297 3.249 2.807 2.723 6.416 2.594 7.306.631.619 2.174 1.782 1.842 4.711-.5-1.113-1.458-1.832-2.561-2.074-.088.216-.297.707-.393.907l-2.805.009c-.1-.203-.343-.707-.436-.93-1.025.191-1.912.682-2.579 2.088zm4.881 5.939v6.044l6.266-3.343v-2.464l-4.042 2.116-2.224-2.353zm-7.26.19v2.511l6.26 3.34v-5.998l-2.171 2.385-4.089-2.238zm1.617-5.01c-.607-1.281-.386-2.963-.031-4.001l-3.84 1.984 2.254 2.121-2.254 2.482 6.133 3.407 2.381-2.615v-.041l-5.45-2.892.807-.445zm11.909.104l2.254 2.591-6.089 3.24-2.431-2.57v-.004l5.457-2.916-.826-.455c.661-1.263.411-2.937.021-3.983l3.868 2.018-2.277 2.066.023.013z"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div class="item">
                  <small class="text-opacity">Project Name</small>
                  <h6
                    class="h7"
                  >{{body.projects && body.projects.data && body.projects.data[0] ? body.projects.data[0].name : 'Not Available'}}</h6>
                </div>
              </div>
              <div class="invoice-summary__list mt-2">
                <div class="item">
                  <button class="orange">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-1"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M6.02 7.389c.399-.285.85-.417 1.292-.417.944 0 1.852.6 2.15 1.599-.382-.294-.83-.437-1.281-.437-.458 0-.919.147-1.321.434-.799.57-1.153 1.541-.845 2.461-1.242-.89-1.247-2.747.005-3.64zm3.741 12.77c.994.334 4.071 1.186 7.635 3.841l6.604-4.71c-1.713-2.402-1.241-4.082-2.943-6.468-1.162-1.628-1.827-1.654-3.037-1.432l.599.84c.361.507-.405 1.05-.764.544l-.534-.75c-.435-.609-1.279-.229-2.053-.051l.727 1.019c.36.505-.403 1.051-.764.544l-.629-.882c-.446-.626-1.318-.208-2.095-.01l.769 1.078c.363.508-.405 1.049-.764.544l-3.118-4.366c-.968-1.358-3.088.083-2.086 1.489l4.605 6.458c-.494-.183-1.363-.349-1.93-.349-1.754 0-2.429 1.92-.222 2.661zm-3.286-2.159h-4.475v-14h10v6.688l2-.471v-8.217c0-1.104-.895-2-2-2h-10c-1.105 0-2 .896-2 2v18.678c-.001 2.213 3.503 3.322 7.005 3.322 1.812 0 3.619-.299 4.944-.894-2.121-.946-6.378-1.576-5.474-5.106z"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div class="item">
                  <small class="text-opacity">Contact Name</small>
                  <h6 class="h7">{{customer.name}}</h6>
                </div>
              </div>
              <div class="invoice-summary__list mt-2">
                <div class="item">
                  <button class="red">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M22.548 9l.452-2h-5.364l1.364-6h-2l-1.364 6h-5l1.364-6h-2l-1.364 6h-6.184l-.452 2h6.182l-1.364 6h-5.36l-.458 2h5.364l-1.364 6h2l1.364-6h5l-1.364 6h2l1.364-6h6.185l.451-2h-6.182l1.364-6h5.366zm-8.73 6h-5l1.364-6h5l-1.364 6z"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div class="item">
                  <small class="text-opacity">Invoice Reference</small>
                  <h6 class="h7">{{body.reference ? body.reference : 'Not Available'}}</h6>
                </div>
              </div>
              <div class="invoice-summary__list mt-2">
                <div class="item">
                  <button class="beige">
                    <svg
                      width="24"
                      height="24"
                      xmlns="http://www.w3.org/2000/svg"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                    >
                      <path
                        d="M6.178 4c-.914-1.493-2.944-3-6.178-3v-1c4.011 0 6.415 2.11 7.314 4h6.159l10.527 10.625-9.375 9.375-10.625-10.581v-6.242l-.282-.128c-1.043-.476-2.226-1.015-3.718-1.015v-1c1.641 0 2.943.564 4 1.044v-2.078h2.178zm10.944 9.109c-.415-.415-.865-.617-1.378-.617-.578 0-1.227.241-2.171.804-.682.41-1.118.584-1.456.584-.361 0-1.083-.408-.961-1.218.052-.345.25-.697.572-1.02.652-.651 1.544-.848 2.276-.106l.744-.744c-.476-.476-1.096-.792-1.761-.792-.566 0-1.125.227-1.663.677l-.626-.627-.698.699.653.652c-.569.826-.842 2.021.076 2.938 1.011 1.011 2.188.541 3.413-.232.6-.379 1.083-.563 1.475-.563.589 0 1.18.498 1.078 1.258-.052.386-.26.763-.621 1.122-.451.451-.904.679-1.347.679-.418 0-.747-.192-1.049-.462l-.739.739c.463.458 1.082.753 1.735.753.544 0 1.087-.201 1.612-.597l.54.538.697-.697-.52-.521c.743-.896 1.157-2.209.119-3.247zm-9.405-7.109c-.051.445-.215.83-.49 1.114-.387.398-.797.57-1.227.599.008.932.766 1.685 1.699 1.685.938 0 1.699-.761 1.699-1.699 0-.932-.751-1.69-1.681-1.699z"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div class="item">
                  <small class="text-opacity">Invoice Discount</small>
                  <h6 class="h7">{{parseFloat(body.discount_percent || 0).toFixed(2)}}%</h6>
                </div>

              </div>
              <div class="invoice-summary__list mt-2">
                <div class="item">
                <small class="text-opacity">Invoice Date</small>
                <h6 class="h7">{{toLocal(body.created_at).format('ll')}}</h6>
              </div>
              </div>
            </div>
          </accordion>
        </div>
        <div class="col-sm-12 col-lg-9">
          <form class="d-form" @submit.prevent="submit">
            <div class="form-accordion">
              <accordion
                @toggleCollapse="toggleCollapse(0)"
                :visible="accordions[0].visible"
                class="invoice-accordion"
                :name="'Business Contact Details, Invoice Subject & Logo'"
              >
                <div slot="content" class="pl-4 pr-4">
                  <div class="row pt-3">
                    <div class="col-sm-12">
                      <div
                        class="invoice-logo text-opacity"
                        style="height:150px; padding:20px; border:2px dashed #F5F5F5; display:flex; align-items:center;justify-content:center"
                      >
                        <img :src="optional(organization, 'data.logo_url', null)" style="height: 100%;">
                      </div>
                    </div>

                    <div class="col-sm-12">
                      <div class="row">
                        <div
                          class="col-sm-6 pt-4 border-right"
                          style="border-color:#F5F5F5 !important"
                        >
                          <div class="row pb-5">
                            <div class="col-sm-12 pr-3 mr-5">
                              <h6 class="text-bold text-opacity-pale">Invoice Subject: <b>*</b></h6>
                              <input
                                type="text"
                                name="subject"
                                v-model="body.subject"
                                v-validate="'required'"
                                class="form-control auth-input d-input"
                                placeholder="Enter subject of invoice"
                              >
                              <small
                                class="form-text text-danger"
                                v-show="errors.has('subject')"
                              >{{errors.first('subject')}}</small>
                            </div>
                            <div class="col-sm-12 pr-3 mr-5 mt-4">
                              <h6 class="text-bold text-opacity-pale">Invoice REF:</h6>
                              <input
                                type="text"
                                name="reference"
                                v-model="body.reference"
                                class="form-control auth-input d-input"
                                placeholder="Invoice Ref"
                              >
                              <small
                                class="form-text text-danger"
                                v-show="errors.has('reference')"
                              >{{errors.first('reference')}}</small>
                            </div>
                            <div class="col-sm-12 pr-3 mr-5 mt-4">
                              <h6 class="text-bold text-opacity-pale">Bill to:</h6>
                              <input
                                v-model="customer.name"
                                :disabled="true"
                                class="form-control auth-input d-input"
                              >
                            </div>
                            <!-- <div class="col-sm-8 pt-4 pb-5">
                              <h6 class="text-bold text-opacity-pale">Invoice Discount:</h6>
                              <input
                                type="number"
                                name="discount"
                                v-model="body.discount_percent"
                                class="form-control auth-input d-input"
                                placeholder="Invoice Discount"
                              >
                              <small
                                class="form-text text-danger"
                                v-show="errors.has('discount_percent')"
                              >{{errors.first('discount_percent')}}</small>
                            </div>-->
                          </div>
                        </div>
                        <div class="col-sm-6 pt-4">
                          <div class="row">
                            <div class="col-sm-12 pr-3 mr-5">
                              <h6 class="text-bold text-opacity-pale">Contact Email:</h6>
                              <input
                                type="text"
                                name="email"
                                :disabled="true"
                                :value="customer.email"
                                class="form-control auth-input d-input"
                                placeholder="Customer Email"
                              >
                            </div>
                            <div class="col-sm-12 pr-3 mr-5 pt-4 pb-5">
                              <h6 class="text-bold text-opacity-pale">Contact Phone Number:</h6>
                              <input
                                type="text"
                                :disabled="true"
                                :value="customer.phone_number"
                                class="form-control auth-input d-input"
                                placeholder="Contact Phone Number"
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </accordion>
              <accordion
                @toggleCollapse="toggleCollapse(1)"
                :visible="accordions[1].visible"
                class="invoice-accordion mt-3"
                :name="'Customer & Invoice details'"
              >
                <div slot="content" class="pl-4">
                  <div class="row">

                    <div class="col-sm-12 col-lg-6 pt-4 pb-5 pl-3 pr-5">
                      <div class="row">
                        <div class="col-sm-12 date-range mt-4">
                          <h6 class="text-bold text-opacity-pale">Invoice date:</h6>
                          <vc-date-picker
                            :masks="{ input: ['YYYY-MM-DD']}"
                            :input-props="{class:`d-input no-margin-top auth-input pl-2`,style: `margin-top:0px !important;`, placeholder: `Invoice Date`}"
                            v-model="body.start_date"
                            name="start_date"
                            ref="startFrom"
                            data-vv-as="Invoice Start date"
                          />
                          <small class="dpicker-icon">
                            <i class="zmdi zmdi-calendar-alt"></i>
                          </small>
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('start_date')"
                          >{{errors.first('start_date')}}</small>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <!-- <div class="col-sm-6">
                          <h6 class="text-bold text-opacity-pale">Payment Terms:</h6>
                          <input
                            type="url"
                            v-model="body.payment_link"
                            class="form-control auth-input d-input"
                          >
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('payment_link')"
                          >{{errors.first('payment_link')}}</small>
                        </div>-->
                        <!-- <div class="col-sm-12 date-range">
                          <h6 class="text-bold text-opacity-pale">Due date:</h6>
                          <vc-date-picker
                            :masks="{ input: ['YYYY-MM-DD']}"
                            :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important;`,placeholder: `Start Time`}"
                            v-validate="'required|after:startFrom'"
                            v-model="body.due_date"
                            name="due_date"
                            ref="dueAt"
                            data-vv-as="Invoice Due date"
                          />
                          <small class="dpicker-icon">
                            <i class="zmdi zmdi-calendar-alt"></i>
                          </small>
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('due_date')"
                          >{{errors.first('due_date')}}</small>
                        </div>-->
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-6 pt-4 pb-5 pl-3 pr-5">
                      <div class="row">
                        <div class="col-sm-12 date-range mt-4">
                          <h6 class="text-bold text-opacity-pale">Due date:</h6>
                          <vc-date-picker
                            :masks="{ input: ['YYYY-MM-DD']}"
                            :input-props="{class:`d-input no-margin-top auth-input pl-2`,style: `margin-top:0px !important;`,placeholder: `Due Date`}"
                            v-model="body.due_date"
                            name="due_date"
                            ref="DueDate"
                            data-vv-as="Invoice Due date"
                          />
                          <small class="dpicker-icon">
                            <i class="zmdi zmdi-calendar-alt"></i>
                          </small>
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('due_date')"
                          >{{errors.first('due_date')}}</small>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <!-- <div class="col-sm-6">
                          <h6 class="text-bold text-opacity-pale">Payment Terms:</h6>
                          <input
                            type="url"
                            v-model="body.payment_link"
                            class="form-control auth-input d-input"
                          >
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('payment_link')"
                          >{{errors.first('payment_link')}}</small>
                        </div>-->
                        <!-- <div class="col-sm-12 date-range">
                          <h6 class="text-bold text-opacity-pale">Due date:</h6>
                          <vc-date-picker
                            :masks="{ input: ['YYYY-MM-DD']}"
                            :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important;`,placeholder: `Start Time`}"
                            v-validate="'required|after:startFrom'"
                            v-model="body.due_date"
                            name="due_date"
                            ref="dueAt"
                            data-vv-as="Invoice Due date"
                          />
                          <small class="dpicker-icon">
                            <i class="zmdi zmdi-calendar-alt"></i>
                          </small>
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('due_date')"
                          >{{errors.first('due_date')}}</small>
                        </div>-->
                      </div>
                    </div>
                  </div>
                </div>
              </accordion>
              <accordion
                      @toggleCollapse="toggleCollapse(2)"
                      :visible="accordions[2].visible"
                      class="invoice-accordion mt-3"
                      :name="`Products & Services`"
              >
                <div slot="content" class="pl-4 pr-4">
                  <div class="row">
                    <div class="col-sm-12 pt-4">
                      <vuetable
                        ref="productServices"
                        :data="productData"
                        :api-mode="false"
                        class="no-border"
                        :fields="productColumns"
                      >
                        <template slot="amount" slot-scope="props">
                          {{invoice.currency && invoice.currency.symbol}} {{parseFloat(props.rowData.amount ||
                          0).toFixed(2)}}
                        </template>
                         <template slot="milestone" slot-scope="props">
                          {{ optional(props.rowData, 'milestone.data.name', 'N/A') }}
                        </template>
                        <template
                          slot="discounted_amount"
                          slot-scope="props"
                        >{{invoice.currency && invoice.currency.symbol}} {{parseFloat(props.rowData.amount_billed || 0).toFixed(2)}}</template>
                        <template
                          slot="unit_price"
                          slot-scope="{rowData}"
                        >{{canAddInvoiceItem ? `${invoice.currency && invoice.currency.symbol} ${parseFloat(rowData.amount/rowData.quantity || 0).toFixed(2)}` : '-'}}</template>
                      </vuetable>
                    </div>
                  </div>
                  <div class="row pb-2" v-if="canAddInvoiceItem">
                    <div class="col-sm-4">
                      <input
                        type="text"
                        name="subject"
                        v-model="data.subject"
                        class="form-control auth-input d-input"
                        placeholder="Item description"
                      >
                    </div>
                    <div class="col-sm-2">
                      <input
                        type="text"
                        v-model="data.amount"
                        name="price"
                        class="form-control auth-input d-input"
                        placeholder="Unit price"
                      >
                    </div>
                    <div class="col-sm-2">
                      <input
                        type="number"
                        name="quantity"
                        v-model="data.quantity"
                        class="form-control auth-input d-input"
                        placeholder="Quantity"
                      >
                    </div>
                    <div class="col-sm-2">
                      <div style="position:relative; width:100%">
                        <span id="discount_label">%</span>
                        <input
                          type="number"
                          name="discount"
                          id="discount"
                          v-model="data.discount_percent"
                          class="form-control auth-input d-input pl-5"
                          placeholder="Discount rate"
                        >
                      </div>
                    </div>
                    <div class="col-lg-2 col-sm-12 mt-2">
                      <button
                        type="button"
                        :disabled="loading"
                        @click="addInvoiceItem(data)"
                        class="btn btn-primary auth-btn pl-2 pr-2 mt-1"
                      >Add</button>
                    </div>
                  </div>
                  <hr style="border-color:#F7F7F7">
                  <div class="invoice-total pb-5 pl-3 pr-3">
                    <div class="row" style="background:#F7F7F7; border-radius:10px">
                      <!-- <div class="col-sm-12 col-lg-3 pt-1">
                        <h6 class="text-bold text-opacity-pale">TOTAL</h6>
                      </div>-->
                      <div class="col-sm-12 col-lg-6 text-right border-right pt-3 pr-4 pb-4">
                        <h6 class="text-small text-opacity text-left">Add Discount to Invoice Total</h6>
                        <div class="invoice-summary__footer">
                          <form>
                            <div style="position:relative; width:100%">
                              <span id="discount_label">%</span>
                              <input
                                type="number"
                                class="pl-5"
                                name="invoice-discount"
                                v-model="body.discount_percent"
                                placeholder="Enter Invoice Discount"
                              >
                            </div>
                            <div>
                              <button @click="submit" :disabled="loading">Update</button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-6" style="display:flex;flex-direction:row;justify-content:flex-end;align-items:center">
                        <span class="">
                          <h6 class="text-small text-opacity" title="Total invoice amount before line item discounts">Original Total</h6>
                          <h6 class="text-bold text-opacity-pale"
                          >{{invoice.currency && invoice.currency.symbol}}{{parseFloat(invoice.original_amount || 0).toFixed(2)}}</h6>
                        </span>
                        <span
                          class="pl-2 pr-2"
                          style="display: flex;align-items: center;justify-content: flex-end;width: 60px;font-size: 35px;opacity: .3;"
                        >//</span>
                        <span class="pl-3">
                          <h6 class="text-small text-opacity" title="Total invoice amount after line item discounts">Line Items Total</h6>
                          <h6
                                  class="text-bold text-opacity-pale"
                                  style="color:green"
                          >{{invoice.currency && invoice.currency.symbol}}{{parseFloat(invoice.invoice_items_total || 0).toFixed(2)}}</h6>
                        </span>
                        <span
                                class="pl-2 pr-2"
                                style="display: flex;align-items: center;justify-content: flex-end;width: 60px;font-size: 35px;opacity: .3;"
                        >//</span>
                        <span class="pl-3">
                          <h6 class="text-small text-opacity" title="Total invoice amount after invoice discount">Discounted Total</h6>
                          <h6
                            class="text-bold text-opacity-pale"
                            style="color:green"
                          >{{invoice.currency && invoice.currency.symbol}}{{parseFloat(invoice.amount || 0).toFixed(2)}}</h6>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </accordion>
              <!-- <div class="mt-4 mb-4">
                <button
                  type="button"
                  class="btn btn-outline pt-2"
                  @click="() => {body.has_timelog = !invoice.has_timelog; updateInvoice();}"
                >
                  <h6 class="text-bold text-opacity-pale">
                    <span class="pr-2" v-if="body.has_timelog">
                      <svg
                        fill="#0a5685"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M0 0v24h24v-24h-24zm10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"
                        ></path>
                      </svg>
                    </span>
                    <span class="pr-2" v-else>
                      <svg
                        fill="#0a5685"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                      ></svg>
                    </span>
                    Include Time sheets
                  </h6>
                </button>
                <button
                  type="button"
                  class="btn btn-outline ml-3 pt-2"
                  @click="() => {body.has_expense = !body.has_expense; updateInvoice();}"
                >
                  <h6 class="text-bold text-opacity-pale">
                    <span class="pr-2" v-if="invoice.has_expense">
                      <svg
                        fill="#0a5685"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M0 0v24h24v-24h-24zm10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"
                        ></path>
                      </svg>
                    </span>
                    <span class="pr-2" v-else>
                      <svg
                        fill="#0a5685"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                      ></svg>
                    </span>
                    Include expense sheets
                  </h6>
                </button>
              </div> -->

              <accordion
                      @toggleCollapse="toggleCollapse(3)"
                      :visible="accordions[3].visible"
                      class="invoice-accordion mt-3"
                      :name="isMilestoneInvoice ? 'Milestone' : 'Timesheet'"
              >
                <div slot="content" class>
                  <div class="total pt-3 pb-3 pl-4 pr-4" style="background:#f0f4f8">
                    <template v-if="!isMilestoneInvoice">
                    <span>
                      <small class="text-opacity">Total time:</small>
                      <span class="text-bold pl-2">{{totalLogTime()}}</span>
                    </span>
                    <span class="pl-5">
                      <small class="text-opacity">Billable:</small>
                      <span class="text-bold pl-2">{{totalLogTime(true)}}</span>
                    </span>
                    <span class="pl-5">
                      <small class="text-opacity">Amount:</small>
                      <span
                              class="text-bold pl-2"
                      >{{invoice.currency && invoice.currency.symbol}}{{timeLogsTotalAmount}}</span>
                    </span>
                    </template>
                    <template v-else>
                      <span class="pl-5">
                      <small class="text-opacity">Amount:</small>
                      <span
                              class="text-bold pl-2"
                      >{{invoice.currency && invoice.currency.symbol}} {{milestonesTotalAmount}}</span>
                    </span>
                    </template>
                  </div>

                  <div class="row pb-5">
                    <div class="col-sm-12">
                                            <vuetable
                                              ref="timeSheetTable"
                                              :data="isMilestoneInvoice ? milestones : timeLogs"
                                              :api-mode="false"
                                              :fields="isMilestoneInvoice ? milestones_columns : time_sheets_columns"
                                              data-path
                                              pagination-path
                                            >
                                              <template
                                                slot="rate"
                                                slot-scope="props"
                                              >{{invoice.currency && invoice.currency.symbol}} {{props.rowData.timelog && props.rowData.timelog.data && props.rowData.timelog.data.rate ? props.rowData.timelog.data.rate : 0}}</template>
                                              <template slot="discount_percent" slot-scope="props">
                                                <input
                                                        type="number"
                                                        min="1"
                                                        max="100"
                                                        name="subject"
                                                        style="width:100%"
                                                        @change="(event) => updateDiscount(event, props)"
                                                        :value="props.rowData.discount_percent"
                                                        class="form-control auth-input d-input no-margin-top mt-1"
                                                        placeholder="Discount"
                                                >
                                              </template>
                                              <template
                                                slot="amount"
                                                slot-scope="props"
                                              >{{invoice.currency && invoice.currency.symbol}} {{parseFloat(props.rowData.amount).toFixed(2)}}</template>
                                              <template slot="amount_billed" slot-scope="props">
                                                <input
                                                  type="text"
                                                  name="subject"
                                                  v-model="props.rowData.amount_billed"
                                                  class="form-control auth-input d-input no-margin-top mt-1"
                                                  style="width:80%"
                                                  placeholder="Amount"
                                                >
                                              </template>
                                              <template slot="actions" slot-scope="props">
                                                <button
                                                  :disabled="loading"
                                                  @click="updateTimeLog(props.rowData )"
                                                  type="button"
                                                  class="btn-lightgray-round"
                                                >
                                                  <i class="zmdi zmdi-plus pr-2"></i>Save
                                                </button>
                                              </template>
                                            </vuetable>
                    </div>
                  </div>
                </div>
              </accordion>

              <accordion
                      @toggleCollapse="toggleCollapse(4)"
                      :visible="accordions[4].visible"
                      class="invoice-accordion mt-3"
                      :name="'Expenses'"
              >
                <div slot="content" class>
                  <div class="total pt-3 pb-3 pl-4 pr-4" style="background:#f0f4f8">
                    <span class>
                      <small class="text-opacity">Total Amount:</small>
                      <span
                              class="text-bold pl-2"
                      >{{invoice.currency && invoice.currency.symbol}}{{expensesTotalAmount}}</span>
                    </span>

                    <!-- <a class="float-right">Save</a> -->
                  </div>
                  <div class="row pb-5">
                    <div class="col-sm-12">
                      <vuetable
                              ref="expensesTable"
                              :data="expenses"
                              :api-mode="false"
                              :fields="expenses_columns"
                              data-path="data"
                              pagination-path
                      >
                        <template slot="name" slot-scope="props">
                          <h6 class="pt-2 text-opacity-pale">{{props.rowData.name}}</h6>
                        </template>
                        <template
                                slot="rate"
                                slot-scope="props"
                        >{{invoice.currency && invoice.currency.symbol}} {{parseFloat(props.rowData.amount).toFixed(2)}}</template>
                        <template slot="category" slot-scope="props">
                          <h6
                                  class="pt-2 text-opacity-pale"
                          >{{props.rowData.category && props.rowData.category.name}}</h6>
                        </template>
                        <template slot="discount_percent" slot-scope="props">
                          <input
                                  type="number"
                                  min="1"
                                  max="100"
                                  name="discount_percent"
                                  :value="props.rowData.discount_percent"
                                  @change="(event) => updateDiscount(event, props)"
                                  class="form-control auth-input d-input no-margin-top mt-1"
                                  placeholder="Discount"
                          >
                        </template>

                        <template slot="amount" slot-scope="props">
                          <input
                                  type="text"
                                  name="subject"
                                  v-model="props.rowData.amount_billed"
                                  class="form-control auth-input d-input no-margin-top mt-1"
                                  style="width:60%"
                                  placeholder="Amount"
                          >
                        </template>
                        <template slot="actions" slot-scope="props">
                          <button
                                  :disabled="loading"
                                  @click="updateExpense(props.rowData)"
                                  type="button"
                                  class="btn-lightgray-round"
                          >
                            <i class="zmdi zmdi-plus pr-2"></i>Save
                          </button>
                        </template>
                      </vuetable>
                    </div>
                  </div>
                </div>
              </accordion>

              <accordion
                  @toggleCollapse="toggleCollapse(8)"
                  :visible="accordions[8].visible"
                  class="invoice-accordion mt-3"
                  :name="'Payments'"
              >
                <div slot="content" class>
                  <div class="total pt-3 pb-3 pl-4 pr-4" style="background:#f0f4f8">
                    <span class>
                      <small class="text-opacity">Total Amount:</small>
                      <span
                          class="text-bold pl-2"
                      >{{invoice.currency && invoice.currency.symbol}}{{paymentTotalAmount}}</span>
                    </span>

                    <!-- <a class="float-right">Save</a> -->
                  </div>
                  <div class="row pb-5">
                    <div class="col-sm-12">
                      <vuetable
                          ref="paymentsTable"
                          :api-url="paymentUrl"
                          :http-options="{ headers:{ 'Authorization' : 'Bearer ' + $auth.token(),'xorg' : getOrganization.organization_id() } }"
                          :fields="payment_columns"
                          data-path="data"
                          @vuetable:loaded="loadTable"
                          pagination-path
                      >
                        <template slot="name" slot-scope="props">
                          <h6 class="pt-2 text-opacity-pale">{{props.rowData.name}}</h6>
                        </template>
                        <template
                            slot="amount"
                            slot-scope="props"
                        >{{invoice.currency && invoice.currency.symbol}} {{parseFloat(props.rowData.amount_paid).toFixed(2)}}</template>


                      </vuetable>
                    </div>
                  </div>
                </div>
              </accordion>



              <accordion
                @toggleCollapse="toggleCollapse(5)"
                :visible="accordions[5].visible"
                class="invoice-accordion mt-3"
                :name="'Notes & Additional Details'"
              >
                <div slot="content" class>
                  <div class="row">
                    <div class="col-sm-12">
                      
                        <quill-editor
          ref="myQuillEditor"
          class="mt-1"
          v-model="body.payment_instructions"
           @ready="_html(body.payment_instructions)"
          placeholder="Enter invoice notes here"
        />
                      <small
                        class="form-text text-danger"
                        v-show="errors.has('payment_instructions')"
                      >{{errors.first('payment_instructions')}}</small>
                    </div>
                  </div>
                </div>
              </accordion>
            </div>
            <div class="mt-4 mb-5 text-right">
              <button
                type="button"
                @click="submit"
                :disabled="loading"
                class="btn text-primary btn-ds btn-outline mr-2"
              >Update</button>

              <button
                      type="button"
                      @click="submitAndContinue"
                      :disabled="loading"
                      class="btn btn-ds"
              >Continue</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>

  </div>
  
</template>


<style lang="scss" scoped>
#discount_label {
  position: absolute;
  z-index: 2;
  margin-top: 10px;
  margin-left: 10px;
}
.invoice-summary__footer {
  // position: absolute;
  width: 100%;
  background: #c4d7ea;
  left: 0;
  bottom: 0;
  // padding: 20px;
  padding: 5px;

  form {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    div:first-child {
      flex-basis: 80%;
    }
    div:nth-of-type(2) {
      flex-basis: 20%;
    }

    input {
      padding: 13px;
      font-size: 13px;
      width: 100%;
      border: none;
    }
    button {
      height: 100%;
      background: #0a5685;
      color: white;
      border: none;
      outline: none;
      font-size: 14px;
      padding-right: 25px;
      padding-left: 25px;

      &:hover {
        background: #2b8c2b;
      }
    }
  }
}
.invoice-summary {
  .invoice-summary__list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .item {
      padding-right: 20px;
    }
    button {
      height: 40px;
      width: 40px;
      border-radius: 40px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;

      &.blue {
        background: lightcyan;

        svg {
          fill: #295656;
        }
      }
      &.orange {
        background: #ffeac3;

        svg {
          fill: #58441e;
        }
      }
      &.red {
        background: #f5a6a6;

        svg {
        }
      }
      &.beige {
        background: #ffae8e;

        svg {
          fill: #4e2414;
        }
      }
    }
  }
  .invoice-summary__footer {
    position: absolute;
    width: 100%;
    background: #c4d7ea;
    left: 0;
    bottom: 0;
    // padding: 20px;
    padding: 5px;

    form {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      div:first-child {
        flex-basis: 80%;
      }
      div:nth-of-type(2) {
        flex-basis: 20%;
      }

      input {
        padding: 13px;
        font-size: 13px;
        width: 100%;
        border: none;
      }
      button {
        height: 100%;
        width: 100%;
        background: #0a5685;
        color: white;
        border: none;
        outline: none;
        font-size: 14px;
        padding-right: 25px;
        padding-left: 25px;

        &:hover {
          background: #2b8c2b;
        }
      }
    }
  }
  .collapsel .card-body {
    padding-bottom: unset !important;
  }
}
.vuetable-slot {
  .d-input {
    margin-top: -2px !important;
  }
}
</style>

<script>
import Breadcrumbs from "@/components/Breadcrumbs/Index";
import Headers from "@/components/Layouts/Headers";
import Accordion from "@/components/Collapse/Index";

import Literals from "@/helpers/dashboard/literals";
import { toUTC } from "@/helpers/date";
import { secondsToDhms } from "@/helpers/duration";
import handleError from "@/helpers/error";
import { toLocal } from "@/helpers/date";
import getOrganization from "@/helpers/getOrganization";
import DeleteModal from "@/components/Modals/Delete";
import { optional } from "@/helpers/random";

export default {
  title: "View Invoice | Invoices",
  components: { Headers, Breadcrumbs, Accordion, DeleteModal },
  data() {
    return {
      getOrganization,
      loading: false,
      accordions: [
        { name: "basic", visible: true },
        { name: "invoice_details", visible: true },
        { name: "product", visible: true },
        { name: "timesheet", visible: true },
        { name: "expenses", visible: true },
        { name: "notes", visible: true },
        { name: "summary", visible: true },
        { name: "milestones", visible: true },
        { name: "payments", visible: false }
      ],
      body: { customer: {}, projects: [], discount_percent: "" },
      data: {},
      product_data_columns: [
        {
          title: "Item",
          name: "subject"
        },
        //  {
        //   title: "Milestone",
        //   name: "__slot:milestone"
        // },
        {
          title: "Unit Price",
          name: "__slot:unit_price"
        },
        {
          title: "Quantity",
          name: "quantity"
        },

        {
          title: "Amount",
          name: "__slot:amount",
        },
        // {
        //   title: "Tax",
        //   name: "tax",
        //   callback : s => {
        //     return '-'
        //   }
        // },
        {
          title: "Discount Percent",
          name: "discount_percent",
          callback: s => {
            return "-";
          }
        },
        {
          title: "Amount Due",
          name: "__slot:discounted_amount"
        }
      ],
      invoice_item_columns: [
        {
          title: "Item",
          name: "subject"
        },
        //  {
        //   title: "Milestone",
        //   name: "__slot:milestone"
        // },
        {
          title: "Unit Price",
          name: "__slot:unit_price"
        },
        {
          title: "Quantity",
          name: "quantity"
        },
        {
          title: "Amount",
          name: "__slot:amount"
        },
        // {
        //   title: "Tax",
        //   name: "tax",
        //   callback: s => {
        //     return '-'
        //   }
        // },
        {
          title: "Discount Percent",
          name: "discount_percent"
        },

        {
          title: "Amount Due",
          name: "__slot:discounted_amount"
        }
      ],
      milestones_columns: [
        {
          title: "Name",
          name: "milestone.data.name",
          dataClass: "pt-4"
        },
        {
          title: "Description",
          name: "milestone.data.description",
          dataClass: "pt-4"
        },
        {
          title: "Start Date",
          name: "milestone.data.start_date",
          dataClass: "pt-4",
          callback: s => {
            return toLocal(s).format("lll");
          }
        },
        {
          title: "Undiscounted Amount",
          name: "__slot:amount",
          dataClass: "pt-4"
        },
        {
          title: "Discount",
          name: "__slot:discount_percent"
        },
        {
          title: "Amount",
          name: "__slot:amount_billed"
        },
        {
          title: "Actions",
          name: "__slot:actions"
        }
      ],
      time_sheets_columns: [
        {
          title: "Task",
          name: "timelog.data.task.data.name",
          dataClass: "pt-4"
        },
        {
          title: "Employee",
          name: "timelog.data.employee.data",
          dataClass: "pt-4",
          callback: s => {
            return s && s.user && s.user.data
              ? `${s.user.data.first_name} ${s.user.data.last_name}`
              : `N/A`;
          }
        },
        {
          title: "Duration",
          name: "timelog.data.hours",
          dataClass: "pt-4",
          callback: s => {
            return s + "" + "hours";
          }
        },
        {
          title: "Project Rate",
          name: "__slot:rate",
          dataClass: "pt-4"
        },
        {
          title: "Undiscounted Amount",
          name: "__slot:amount",
          dataClass: "pt-4"
        },
        {
          title: "Discount",
          name: "__slot:discount_percent"
        },
        // {
        //   title: "Invoice Discount",
        //   name: "discount_percent",
        //   callback: s => {
        //     return this.invoice.discount_percent
        //   },
        //   dataClass: "pt-4"
        // },
        {
          title: "Amount",
          name: "__slot:amount_billed"
        },
        {
          title: "Actions",
          name: "__slot:actions"
        }
      ],
      expenses_columns: [
        {
          title: "Subject",
          name: "subject",
          dataClass: "pt-4"
        },
        // {
        //   title: "Category",
        //   name: "expense.data.category.name",
        //   dataClass: "pt-4"
        // },
        {
          title: "Employee",
          name: "expense.data.employee.data",
          dataClass: "pt-4",
          callback: s => {
            return s && s.user && s.user.data
              ? `${s.user.data.first_name} ${s.user.data.last_name}`
              : `N/A`;
          }
        },
        {
          title: "Date",
          name: "expense.data.expense_date",
          dataClass: "pt-4",
          callback: s => {
            return toLocal(s).format("lll");
          }
        },
        {
          title: "Undiscounted Amount",
          name: "__slot:rate",
          dataClass: "pt-4"
        },
        {
          title: "Discount",
          name: "__slot:discount_percent"
        },
        {
          title: "Amount",
          name: "__slot:amount"
        },
        {
          title: "Actions",
          name: "__slot:actions"
        }
      ],

      payment_columns: [
        {
          title: "ID",
          name: "id",
          // dataClass: "pt-4"
        },
        {
          title: "Amount",
          name: "__slot:amount",
          // dataClass: "pt-4"
        },
        {
          title: "Comments",
          name: "comments",
          // dataClass: "pt-4"
        },
         {
          title: "Date Paid",
          name: "date_paid",
          callback: s => {
            return toLocal(s).format("lll");
          }
        },
          {
          title: "Payment Method",
          name: "payment_source.name",
          callback: s => {
            return s || 'N/A';
          }
        },
      ],
      loaded: false,
      paymentTotalAmount:0,
      showDelete: false,
      showDeleteName: "",
      selectedDeleteId: "",
    };
  },
  methods: {
    toLocal,
    secondsToDhms,
    handleError,
    optional,
    toggleCollapse(index) {
      this.accordions[index].visible = !this.accordions[index].visible;
    },
    truncate(str, n, useWordBoundary) {
      if (!str || str.length <= n) {
        return str;
      }
      const subString = str.substr(0, n - 1); // the original check
      return (
        (useWordBoundary
          ? subString.substr(0, subString.lastIndexOf(" "))
          : subString) + "..."
      );
    },
    submitAndContinue() {
      this.submit().then((check) => {
        this.$router.push({name:'invoices-send',params:{id:this.$route.params.id}})
      });
    },
    submit() {
      return this.$validator.validateAll().then(valid => {
        if(valid) {
          this.loading = true;
          return this.updateInvoice();
        }
      });
    },
    updateInvoice() {
      this.body.has_expense = this.body.has_expense ? 1 : 0;
      this.body.has_timelog = this.body.has_timelog ? 1 : 0;
      let data = {};
      Object.assign(data, this.body);
      data.customer_id = data.customer ? data.customer.id : null;
      data.start_date = this.body.start_date
        ? toUTC(this.body.start_date).format("YYYY-MM-DD")
        : toUTC(new Date()).format("YYYY-MM-DD");
      data.due_date = this.body.due_date
        ? toUTC(this.body.due_date).format("YYYY-MM-DD")
        : null;
      data.end_date = this.body.end_date
        ? toUTC(this.body.end_date).format("YYYY-MM-DD")
        : null;

      delete data.customer;
      delete data.creator;
      delete data.currency;
      delete data.projects;
      delete data.filteredProjects;
      delete data.approver;
      delete data.status;
      delete data.template;

      let msgPass = Literals.INVOICE_UPDATE_SUCCESS;
      return this.$store
        .dispatch("invoices/createUpdate", data)
        .then(s => {
          this.$store.dispatch("invoices/getSingle", this.invoice.id).then( () => {
            this.$toast.success(msgPass, {
              position: "top-right",
              duration: 3000
            });
            this.formatData();
          });
        })
        .catch(error => {
          let errorMessage = Literals.INVOICE_UPDATE_ERROR;
          this.handleError(this, error, errorMessage);
        }).finally(() => {
          this.loading = false;
        });
    },

    updateOrCreateInvoiceItem(input) {
      input.quantity = input.quantity > 0 ? input.quantity : 1;
      //console.log('updateOrCreateInvoiceItem', input)
      let data = {
        id: input.id,
        unit_price: optional(input, 'timelog.data.rate', 0),
        discount_percent: input.discount_percent,
        invoice_id: this.invoice.id,
        expense_id: input.is_expense ? input.expense_id : null,
        timelog_id: input.is_timelog ? input.timelog_id : null,
        milestone_id: input.is_milestone ? input.milestone_id : null,
        quantity: input.quantity,
        amount: parseFloat(input.unit_price) * parseFloat(input.quantity),
        subject: input.subject
      };
      let msgPass = `Invoice Item ${
        data.id ? "Updated" : "Added"
      } successfully`;
      this.$store
        .dispatch("invoices/createUpdateInvoiceItem", data)
        .then(s => {
          Promise.all([
            this.$store.dispatch("invoices/getSingle", this.$route.params.id),
            this.$store.dispatch("invoices/getInvoiceItems", this.$route.params.id),
            this.$store.dispatch("invoices/getInvoiceItemsExpenses", this.$route.params.id),
            this.$store.dispatch("invoices/getInvoiceItemsTimeLogs", this.$route.params.id),
            this.$store.dispatch("invoices/getInvoiceItemsMilestones", this.$route.params.id),

          ]).then(() => {
            this.formatData();
            this.$toast.success(msgPass, {
              position: "top-right",
              duration: 3000
            });
          });
        })
        .catch(error => {
          let errorMessage = "Error occurred while updating invoice line item";
          this.handleError(this, error, errorMessage);
        }).finally(() => {
          this.loading = false;
      });
    },
    addInvoiceItem(data) {
      this.updateOrCreateInvoiceItem(data);
    },

    fetchInvoice() {
      this.$store
        .dispatch("invoices/getSingle", this.$route.params.id)
        .then(() => {
          this.formatData();
        });
    },
    totalLogTime(billable = false) {
      let timeLogs = this.timeLogs.filter(s =>  s.timelog && s.timelog.data);
      if (billable) {
        timeLogs.filter(s => s.is_billable);
      }
      let hours = timeLogs
        .map(s => s.timelog.data)
        .reduce((total, obj) => total + parseFloat(obj ? obj.hours : 0), 0);

      return hours ? `${hours} hours` : "0 hours";
    },
    updateTimeLog(data) {
      data.is_timelog = true;
      this.updateOrCreateInvoiceItem(data);
    },
    updateExpense(data) {
      data.is_expense = true;
      this.updateOrCreateInvoiceItem(data);
    },
    updateMilestone(data) {
      data.is_milestone = true;
      this.updateOrCreateInvoiceItem(data);
    },
    formatData() {
      return new Promise((resolve, reject) => {
        Object.assign(this.body, this.invoice);
        this.body.discount_percent = this.body.discount_percent || "";
        this.body.start_date = this.body.start_date
                ? new Date(this.body.start_date)
                : null;
        this.body.end_date = this.body.end_date
                ? new Date(this.body.end_date)
                : null;
        this.body.due_date = this.body.due_date
                ? new Date(this.body.due_date)
                : null;

        this.body.customer = {
          id: this.body.customer.data
                  ? this.body.customer.data.id
                  : this.body.customer.id,
          name: this.body.customer.data
                  ? this.body.customer.data.company_name
                  : this.body.customer.company_name,
          phone_number: this.body.customer.data
                  ? this.body.customer.data.phone_number
                  : this.body.customer.phone_number,
          email: this.body.customer.data
                  ? this.body.customer.data.email
                  : this.body.customer.email
        };

        this.body.filteredProjects = this.body.projects.data
                ? this.body.projects.data.map(s => {
                  return {
                    id: s.id,
                    name: s.name
                  };
                })
                : this.body.filteredProjects;
        this.body.subject = this.body.subject ? this.body.subject : 'Invoice';
        this.body.reference = this.body.reference || String(`${optional(this.invoice, 'customer.data.company_name', '')}${optional(this.invoice, 'projects.data', []).length}${this.invoice.id}`).replace(/\s/g, '')
        resolve(this.body);
      });
    },
    updateDiscount(event, props) {
      let percent = parseInt(event.target.value);
      let percentage = percent / 100;
      props.rowData.discount_percent = percent;
      let amountBilled = parseFloat(props.rowData.amount || 0);
      props.rowData.amount_billed = parseFloat(amountBilled - (amountBilled * percentage)).toFixed(2)
    },
    loadTable() {
      let data = this.$refs.paymentsTable.tableData;

      if(data) {
        this.paymentTotalAmount = data.reduce((a,b) => a + parseFloat(b.amount_paid), 0);
      }
    },
    deleteInvoive() {
      this.$store.dispatch("invoices/delete", this.selectedDeleteId).then((s) => {
        this.$router.go(-1);
        this.$toast.success("🚮 Invoice Successfully Deleted", {
          position: "top-right",
          duration: 3000,
        });
        this.showDelete = false;
      });
    },
    setDeleteState(name, id) {
      this.showDelete = true;
      this.showDeleteName = name;
      this.selectedDeleteId = id;
    },
    cancelDelete() {
      this.showDelete = false;
    },
  },
  computed: {
    breadCrumbs() {
      return [
        {
          text: "Home",
          href: "#"
        },
        {
          text: "All Invoices",
          active: true
        },
        {
          text: `#${this.$route.params.id}`,
          active: true
        }
      ];
    },
    invoice() {
      return this.$store.getters["invoices/invoice"];
    },
    projects() {
      return this.invoice.projects && this.invoice.projects.data ? this.invoice.projects.data : [];
    },
    project() {
      return this.projects.length ? this.projects[0] : {};
    },
    timeLogs() {
      return this.$store.getters["invoices/invoice_items_timelogs"];
    },
    canAddInvoiceItem() {
      // if invoice doesn't have expense/timelog, then it's a manual invoice
      return (this.invoice.id && !(this.invoice.has_expense || this.invoice.has_timelog));
    },
    product_services() {
      let hours = this.timeLogs.reduce(
        (total, obj) => total + parseFloat(obj && obj.timelog && obj.timelog.data && obj.timelog.data.hours),
        0
      );
      let array = [];
      if(this.project && this.project.billing_type_id === 3 ) {
        array.push(
                {
                  subject: "Project Milestones",
                  unit_price: parseFloat(
                          this.milestonesTotalAmount / this.milestones.length
                  ).toFixed(2),
                  quantity: this.milestones.length,
                  tax: "-",
                  amount: this.milestonesTotal,
                  amount_billed: this.milestonesTotalAmount
                },
        );
      } else {

        array.push(
                {
                  subject: "Billable Hours",
                  unit_price: parseFloat(
                          this.timeLogsTotalAmount / this.timeLogs.length
                  ).toFixed(2),
                  quantity: isNaN(hours) ? 0 : hours,
                  tax: "-",
                  amount: this.timeLogsTotal,
                  amount_billed: this.timeLogsTotalAmount
                },
        );
      }

      array.push(
        {
          subject: "Project Expenses",
          unit_price: parseFloat(
                  this.expensesTotalAmount / this.expenses.length
          ).toFixed(2),
          quantity: this.expenses.length,
          tax: "-",
          amount: this.expensesTotal,
          amount_billed: this.expensesTotalAmount
        }
      )

      return array;
    },
    invoiceItems() {
      return this.$store.getters["invoices/invoice_items"];
    },
    expensesTotal() {
      let amount = parseFloat(
        this.expenses.reduce(
          (total, obj) => total + (obj ? parseFloat(obj.amount) : 0),
          0
        )
      ).toFixed(2);
      return isNaN(amount) ? "0.00" : amount;
    },
    milestonesTotal() {
      let amount = parseFloat(
              this.milestones.reduce(
                      (total, obj) => total + (obj ? parseFloat(obj.amount) : 0),
                      0
              )
      ).toFixed(2);
      return isNaN(amount) ? "0.00" : amount;
    },
    expensesTotalAmount() {
      let amount = parseFloat(
        this.expenses.reduce(
          (total, obj) => total + (obj ? parseFloat(obj.amount_billed) : 0),
          0
        )
      ).toFixed(2);
      return isNaN(amount) ? "0.00" : amount;
    },
    milestonesTotalAmount() {
      let amount = parseFloat(
        this.milestones.reduce(
          (total, obj) => total + (obj ? parseFloat(obj.amount_billed) : 0),
          0
        )
      ).toFixed(2);
      return isNaN(amount) ? "0.00" : amount;
    },
    timeLogsTotal() {
      let amount = parseFloat(
        this.timeLogs.reduce((total, obj) => total + parseFloat(obj.amount), 0)
      ).toFixed(2);

      return isNaN(amount) ? "0.00" : amount;
    },
    timeLogsTotalAmount() {
      let amount = parseFloat(
        this.timeLogs.reduce(
          (total, obj) => total + parseFloat(obj.amount_billed),
          0
        )
      ).toFixed(2);

      return isNaN(amount) ? "0.00" : amount;
    },
    expenses() {
      return this.$store.getters["invoices/invoice_items_expenses"];
    },
    milestones() {
      return this.$store.getters["invoices/invoice_items_milestones"];
    },
    customer() {
      return this.body.customer;
    },
    productData() {
      return this.canAddInvoiceItem ? this.invoiceItems : this.product_services;
    },
    productColumns() {
      return this.canAddInvoiceItem
        ? this.invoice_item_columns
        : this.product_data_columns;
    },
    organization() {
      let orgId = window.localStorage.getItem("selected_organization");
      if (this.$auth.user().organization) {
        return this.$auth.user().organization;
      }

      return this.$auth.user().organizations &&
        this.$auth.user().organizations.data
        ? this.$auth.user().organizations.data.find(s => s.id == orgId) || {}
        : {};
    },
    isMilestoneInvoice() {
      return (this.project && this.project.billing_type_id === 3);
    },
    paymentUrl() {
      return `${this.$baseApi}/invoices/${ this.$route.params.id}/payments?include=paymentSource`;
    }
  },
  created() {
    Promise.all([
      this.$store.dispatch("invoices/getSingle", this.$route.params.id),
      this.$store.dispatch("invoices/getInvoiceItems", this.$route.params.id),
      this.$store.dispatch("invoices/getInvoiceItemsTimeLogs", this.$route.params.id),
      this.$store.dispatch("invoices/getInvoiceItemsExpenses", this.$route.params.id),
      this.$store.dispatch("invoices/getInvoiceItemsMilestones", this.$route.params.id),
    ]).finally(() => {
      this.formatData().then(() => {
        this.loaded = true;
      })
    });
  }
};
</script>
