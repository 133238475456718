<template>
    <div>
        <task-modal :task="task" v-if="loaded" :hasProject='task.project != null'></task-modal>
    </div>
</template>
<script>
    import TaskModal from "@/components/Modals/Tasks";
    export default {
        components: { TaskModal },
        data() {
            return {
                loaded:false
            };
        },
        computed: {
            task() {
                return this.$store.getters['tasks/task'];
            }
        },
        created() {
            this.$store.dispatch("tasks/getSingle", this.$route.params.id).then((data) => {
                let projectId = data.project_id;
                Promise.all([
                        projectId && this.$store.dispatch("projects/getMilestones", projectId),
                        projectId && this.$store.dispatch("projects/getTasks", projectId)
                ]).finally(() => this.loaded = true);

            })
        }
    };
</script>
