<template>
  <!-- Modal -->
  <div>
    <b-modal @hide="close" :no-close-on-backdrop="true" ref="my-modal" class="deletemodal">
      <template v-slot:modal-header>
        <h2 class="pl-3 pt-3 pr-3 f-24">{{body && body.id ? 'Edit Project' : 'Create New Project'}}</h2>
        <button class="close" @click="close">
          Cancel
          <i class="zmdi zmdi-close-circle pl-2" style="font-size:20px"></i>
        </button>
      </template>
      <div class="d-block pl-3">
        <form class="d-form" @submit.prevent="save">
          <div class="row">
            <div class="col-sm-12 col-lg-6">
              <div class="d-flex pb-1 flex-row justify-content-between align-items-center">
                <div>
                  <h6 class="text-bold text-opacity-pale">
                    Customer
                    <b>*</b>
                  </h6>
                </div>
                <div class="text-bold text-opacity-pale">
                  <router-link
                    style="color:#3f92cc !important; text-decoration:none !important"
                    :to="{name: 'customers-create', query: {from: 'project'}}"
                  >
                    <h6 class="h7">
                      <span class="pl-2 pr-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill="#3f92cc"
                            d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 4c-.552 0-1 .448-1 1v2H5c-.552 0-1 .448-1 1s.448 1 1 1h2v2c0 .552.448 1 1 1s1-.448 1-1V9h2c.552 0 1-.448 1-1s-.448-1-1-1H9V5c0-.552-.448-1-1-1z"
                          ></path>
                        </svg>
                      </span>
                      Create Customer
                    </h6>
                  </router-link>
                </div>
              </div>
              <multiselect
                track-by="id"
                label="name"
                v-model="body.customer"
                @input="setCurrency"
                v-validate="'required'"
                name="customer_id"
                :options="customers"
                :show-labels="false"
                data-vv-as="Customer"
              ></multiselect>
              <small
                class="form-text text-danger"
                v-show="errors.has('customer_id')"
              >{{errors.first('customer_id')}}</small>
            </div>
            <div class="col-sm-12 col-lg-6">
              <h6 class="text-bold text-opacity-pale">
                Project Name
                <b>*</b>
              </h6>
              <input
                type="text"
                 v-validate="'required|min:1|max:100'"
                v-model="body.name"
                name="name"
                class="form-control auth-input d-input"
                placeholder="Enter project name"
                data-vv-as="Project Name"
              >
              <small
                class="form-text text-danger"
                v-show="errors.has('name')"
              >{{errors.first('name')}}</small>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-sm-12 col-lg-6">
              <h6 class="text-bold text-opacity-pale">
                Status
                <b>*</b>
              </h6>
              <multiselect
                track-by="id"
                return="id"
                label="name"
                v-model="body.project_status"
                v-validate="'required'"
                name="project_status_id"
                :options="statuses"
                :show-labels="false"
                placeholder="Select status"
                data-vv-as="Project Status"
              ></multiselect>
              <small
                class="form-text text-danger"
                v-show="errors.has('project_status_id')"
              >{{errors.first('project_status_id')}}</small>
            </div>
            <div class="col-sm-12 col-lg-6">
              <h6 class="text-bold text-opacity-pale">Project Manager</h6>
              <multiselect
                track-by="id"
                label="name"
                v-model="body.manager"
                data-vv-as="Project Manager"
                name="manager_user_id"
                :options="users"
                :show-labels="false"
                :searchable="true"
              ></multiselect>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-sm-12 col-lg-6">
              <h6 class="text-bold text-opacity-pale">
                Project Type
                <b>*</b>
                <span v-b-tooltip.hover
                    :title="'How is the project paid for? Fixed amount, hourly or by set milestones?.'"
                ><i class="zmdi zmdi-info"></i></span>
              </h6>
              <div class="input-group">
                <div id="radioBtn" style="width:100%" class="btn-group">
                  <a
                    v-for="billing_type in billing_types"
                    :key="billing_type.id"
                    :class="body.billing_type && body.billing_type.id === billing_type.id  ? 'btn btn-radio btn-sm active' : 'btn btn-radio btn-sm'"
                    @click="toggleSettlement(billing_type.id)"
                  >{{billing_type.name}}</a>
                </div>
                <input type="hidden" v-model="body.billing_type_id" name="fun" id="fun">
              </div>
            </div>
            <div class="col-sm-12 col-lg-6">
              <h6 class="text-bold text-opacity-pale">Project Duration</h6>
              <div class="row">
                <div class="col-sm-12 col-lg-6 date-range">
                  <vc-date-picker
                    :masks="{ input: ['YYYY-MM-DD']}"
                    :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: `Start date`}"
                    v-model="body.start_date"
                    name="start_date"
                    ref="startFrom"
                    data-vv-as="Project Start date"
                  />
                  <small class="dpicker-icon">
                    <i class="zmdi zmdi-calendar-alt"></i>
                  </small>
                </div>
                <div class="col-sm-12 col-lg-6 date-range">
                  <vc-date-picker
                    :masks="{ input: ['YYYY-MM-DD']}"
                    :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: ` End date`}"
                    v-model="body.end_date"
                    v-validate="'after:startFrom'"
                    name="end_date"
                    data-vv-as="Project End Date"
                  />
                  <small class="dpicker-icon">
                    <i class="zmdi zmdi-calendar-alt"></i>
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-sm-12 col-lg-6">
              <div class="row">
                <div
                  v-show="body.billing_type && body.billing_type.id === 2"
                  class="col-sm-12 col-lg-6"
                >
                  <h6 class="text-bold text-opacity-pale">Total estimated hours</h6>
                  <input
                    class="form-control auth-input d-input"
                    v-model="body.estimated_hours"
                    type="number"
                    name="estimated_hours"
                    data-vv-as="Estimated Hours"
                  >
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('estimated_hours')"
                  >{{errors.first('estimated_hours')}}</small>
                </div>
                <div
                  v-show="body.billing_type && body.billing_type.id === 2"
                  class="col-sm-12 col-lg-6"
                >
                  <h6 class="text-bold text-opacity-pale">Hours capped at</h6>
                  <input
                    class="form-control auth-input d-input"
                    v-model="body.hourly_cap"
                    type="number"
                    name="hourly_cap"
                    data-vv-as="Hourly Cap"
                  >
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('hourly_cap')"
                  >{{errors.first('hourly_cap')}}</small>
                </div>
              </div>
            </div>

            <div
              v-show="body.billing_type && body.billing_type.id === 2"
              class="col-sm-12 col-lg-6"
            >
              <h6 class="text-bold text-opacity-pale">
                Hourly Rate
                <b>*</b>
              </h6>
              <input
                class="form-control auth-input d-input"
                data-vv-as="Hourly Rate"
                name="hourly_rate"
                type="number"
                v-model="body.hourly_rate"
                v-validate="body.billing_type.id == 2 ? 'required' : ''"
              >
              <small
                class="form-text text-danger"
                v-show="errors.has('hourly_rate')"
              >{{errors.first('hourly_rate')}}</small>
            </div>
          </div>

          <div class="row mt-3">
            <div
              :class="{
            'col-sm-12':true,
            'col-lg-6':body.billing_type.id === 1,
            'col-lg-12':body.billing_type.id !== 1
          }"
            >
              <h6 class="text-bold text-opacity-pale">
                Currency
                <b>*</b>
              </h6>
              <multiselect
                :options="currencies"
                :show-labels="false"
                data-vv-as="Currency"
                :custom-label="currencySelectLabel"
                label="name"
                :disabled="!!$route.query.cid && !!body.currency"
                name="currency_id"
                placeholder="Select Currency"
                track-by="id"
                v-model="body.currency"
                v-validate="'required'"
              ></multiselect>
              <small
                class="form-text text-danger"
                v-show="errors.has('currency_id')"
              >{{errors.first('currency_id')}}</small>
            </div>

            <div
              v-show="body.billing_type && body.billing_type.id === 1"
              class="col-sm-12 col-lg-6"
            >
              <h6 class="text-bold text-opacity-pale">
                Total Project Value
                <b>*</b>
              </h6>
              <input
                class="form-control auth-input d-input"
                data-vv-as="Total Project Value"
                name="cost"
                placeholder="Enter Project value"
                type="number"
                v-model="body.estimated_cost"
                v-validate="body.billing_type && body.billing_type.id === 1 ? 'required': ''"
              >
              <small
                class="form-text text-danger"
                v-show="errors.has('cost')"
              >{{errors.first('cost')}}</small>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-sm-12 col-lg-12">
              <h6 class="text-bold text-opacity-pale">Project Description</h6>
              
              <quill-editor
                ref="myQuillEditor"
                class="mt-1"
                v-model="body.description"
                @ready="_html(body.description)"
                placeholder="Enter project description"
              />
            </div>
          </div>
        </form>
      </div>
      <template v-slot:modal-footer>
        <dashboard-button @submit="save" :loading="loading" :text="body && body.id ? 'Save' : 'Save'"></dashboard-button>

      </template>
    </b-modal>
  </div>
</template>

<script>
import DashboardButton from "@/components/Buttons/Dashboard";
import Literals from "@/helpers/dashboard/literals";
import getOrganization from "@/helpers/getOrganization";
import { setTimeout } from "timers";
import { toUTC } from "@/helpers/date";
import handleError from "@/helpers/error";
import random from "@/helpers/random";
import {optional} from "@/helpers/random";

export default {
  data() {
    return {
      Literals,
      loading: false,
      customerModal: false,
      body: {
        name: "",
        project_status: "",
        actual_cost: 0,
        customer: "",
        currency: "",
        manager: "",
        billing_type: {
          id: 1
        },
        start_date: null,
        end_date: null,
        estimated_hours: 0,
        hourly_rate: 0,
        hours_logged: 0,
        estimated_cost: null,
        revenue: 0,
        description: "",
        hourly_cap: 0
      }
    };
  },
  props: ["project"],
  components: { DashboardButton },
  computed: {
    statuses() {
      return this.$store.getters["global/project_statuses"];
    },
    activeSettlement() {
      return this.body.billing_type && this.body.billing_type.id
        ? this.body.billing_type.id
        : 1;
    },
    billing_types() {
      return this.$store.getters["global/project_billing_types"];
    },
    customers() {
      return this.$store.getters["customers/customers_simple"];
    },
    customer() {
      return this.$store.getters["customers/customer"];
    },
    currencies() {
      return this.$store.getters["global/currencies"];
    },
    users() {
      return this.$store.getters["employees/users"];
    }
  },
  methods: {
    handleError,
    toUTC,
    close() {
      return this.$router.go(-1);
    },
    setCurrency(val) {
      this.body.currency = this.currencies.find(s => s.id == val.currency);
      this.body.hourly_rate = optional(val, 'hourly_rate', '');
    },
    toggleSettlement(id) {
      // this.activeSettlement = id;
      this.body.billing_type.id = id;
    },
    openCustomerModal() {
      this.customerModal = true;
      this.$refs["customerModal"].show();
    },
    addCustomer(customer) {
      this.body.customer = { id: "", name: customer };
      this.customers.push(this.body.customer);
      let data = {
        company_name: customer,
        status: 1,
        organization_id: getOrganization.organization_id()
      };
      this.$store
        .dispatch("customers/createUpdate", data)
        .then(s => {
          this.body.customer.id = s;
        })
        .catch(error => {
          let errorMessage = "Could not create Customer";
          this.handleError(this, error, errorMessage);
        });
    },
    save() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    currencySelectLabel({ code, name, symbol }) {
      return ` ${name} (${symbol} ${code}) `;
    },
    submit() {
      let data = {
        name: this.body.name,
        project_status_id: this.body.project_status.id,
        actual_cost: this.body.actual_cost,
        customer_id: this.body.customer.id,
        manager_user_id: this.body.manager ? this.body.manager.id : null,
        billing_type_id: this.body.billing_type ? this.body.billing_type.id : 1,
        start_date: this.body.start_date
          ? toUTC(this.body.start_date).format("YYYY-MM-DD")
          : null,
        end_date: this.body.end_date
          ? toUTC(this.body.end_date).format("YYYY-MM-DD")
          : null,
        estimated_hours: this.body.estimated_hours,
        hours_logged: this.body.hours_logged,
        hourly_rate: this.body.hourly_rate,
        description: this.body.description,
        estimated_cost: this.body.estimated_cost,
        revenue: this.body.revenue,
        hourly_cap: this.body.hourly_cap,
        currency_id: this.body.currency.id,
        opportunity_id: this.body.opportunity_id,
        created_by: this.$auth.user().id,
        organization_id: getOrganization.organization_id()
      };

      let msgPass = Literals.PROJECT_CREATE_SUCCESS;
      if (this.body.id) {
        msgPass = Literals.PROJECT_UPDATE_SUCCESS;
        Object.assign(data, { id: this.body.id });
      }
      this.$store
        .dispatch("projects/createUpdate", data)
        .then(s => {
          this.$toast.success(msgPass, {
            position: "top-right",
            duration: 3000
          });
          this.$emit("refresh");
          if (
            this.$route &&
            this.$route.query &&
            this.$route.query.from
          ) {
            this.$router.go(-1);
          } else {
            data.id
              ? this.$router.push({
                  name: "projects-show-general",
                  params: { id: this.body.id }
                })
              : this.$router.push({ name: "projects-index" });
          }
        })
        .catch(error => {
          let errorMessage = this.body.id
            ? Literals.PROJECT_UPDATE_ERROR
            : Literals.PROJECT_CREATE_ERROR;
          this.handleError(this, error, errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatData() {
      
      if (this.project) {
        Object.assign(this.body, this.project);
        this.body.manager = this.project.manager && this.project.manager.data;
        this.body.customer = this.project.customer && this.project.customer.data ? this.project.customer.data : this.project.customer || {}

        // using map doesn't work currently cos the variable is not immediately available
        if (this.body.customer) {
          this.body.customer = {
            id: this.body.customer.id,
            name: this.body.customer.company_name
          };
        }
        if (this.body.manager) {
          this.body.manager = {
            id: this.body.manager.id,
            name: `${this.body.manager.first_name} ${
              this.body.manager.last_name
            }`
          };
        }
        this.body.start_date = this.body.start_date
          ? new Date(this.body.start_date)
          : null;
        this.body.end_date = this.body.end_date
          ? new Date(this.body.end_date)
          : null;
      }

      // if customer ID was passed in URL, pre-populate customer and hourly rate
      if (this.$route.query && this.$route.query.cid) {
        let customer = this.customers.find(c => c.id == this.$route.query.cid);
        Object.assign(this.body, { customer: customer });
        this.body.currency = this.currencies.find(
          s => s.id == this.body.customer.currency
        );
        this.body.hourly_rate = this.body.customer && this.body.customer.standard_hourly_rate;
      }
    }
  },
  mounted() {
    this.$refs["my-modal"].show();
  },
  created() {
    Promise.all([
      this.statuses.length || this.$store.dispatch("global/getProjectStatuses"),
      this.currencies.length || this.$store.dispatch("global/getCurrencies"),
      this.billing_types.length || this.$store.dispatch("global/getProjectBillingTypes"),
      this.$store.dispatch("employees/index"),
      this.$store.dispatch("customers/index")
    ]).finally(() => {
      this.formatData();
    });
  }
};
</script>
