import router from "../router";
import {
    get
} from 'lodash';

const getParams = (url) => {
    let queryParams = {};
    //create an anchor tag to use the property called search
    let anchor = document.createElement('a');
    //assigning url to href of anchor tag
    anchor.href = url;
    //search property returns the query string of url
    let queryStrings = anchor.search.substring(1);
    let params = queryStrings.split('&');

    for (var i = 0; i < params.length; i++) {
        var pair = params[i].split('=');
       
        if (pair[0] && pair[1]) {
            queryParams[pair[0]] = decodeURIComponent(pair[1]).trim();
        }
    }
    return queryParams;
};


const insertParam = (fullUrl, key, value) => {
    var url = new URL(fullUrl);
    url.searchParams.set(key, value);
    return url.href;
}

export function requestIntercept(config) {
    addPending(config.url);
    let urlObj = new URL(config.url);
    let url = `${urlObj.protocol}//${urlObj.hostname}${urlObj.pathname}`;
    let params = getParams(urlObj.href);

    for (let i in params) {
        url = insertParam(url, i, params[i]);
    }

    config.url = url;

    if (config.data) {
        try {
            let data = {};
            Object.keys(config.data).forEach(function (key) {
                if (config.data[key] === 0 || config.data[key]) data[key] = config.data[key];
            });
            config.data = data;
        } catch (e) {
            console.error(e);
        }
    }
    return config;
}

export function responseIntercept(error) {
    let conf = error.config;
    if(!conf || !conf.url) return Promise.reject(error);
    updatePending(conf.url);
    //this should be for get requests only
    //alsoooooooo temporal hack to ignore the one for getting paymethods.
    
    var pathname = new URL(conf.url).pathname;
    let x_path = window.location.pathname.split('/')[3];
    var exempted_paths = ["/api/v1/credentials"]

    let status = get(error, 'response.status', undefined);

    // if(error.message === 'Network Error') {
    //     router.push({name: 'Login'});
    // }
    if (conf && conf.method == "get" && !exempted_paths.includes(pathname) && x_path !== "finance") {
        
        if (status == 404) {
            //Vue.$auth.logout()
            router.push({
                name: 'error404'
            }).catch(() => {});
        } 
        if(status == 401) {
            router.push({name: 'login'});
        }
    }
    return (status < 200 || status > 299) ? Promise.reject(error) : Promise.resolve(error);
}

export function hasPending() {
    let pendingRequests = JSON.parse(sessionStorage.getItem('pendingRequests') || '{}');
    return (Object.values(pendingRequests).length > 0);
}

export function updatePending(url) {
    let pendingRequests = JSON.parse(sessionStorage.getItem('pendingRequests') || '{}');
    delete pendingRequests[decodeURIComponent(url)];
    sessionStorage.setItem('pendingRequests', JSON.stringify(pendingRequests));
}

export function addPending(url) {
    let pendingRequests = JSON.parse(sessionStorage.getItem('pendingRequests') || '{}');
    pendingRequests[decodeURIComponent(url)] = 1;
    sessionStorage.setItem('pendingRequests', JSON.stringify(pendingRequests));
}
