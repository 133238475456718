import axios from 'axios';
import { updatePending } from "@/helpers/axiosIntercept";
const API = process.env.VUE_APP_BASE_URI;

export default {
    index({
        commit
    }, query) {
        return new Promise((resolve, reject) => {
            let url = `${API}/employees${query ? `?${query}` : ''}`;
            axios.get(url)
                .then(({
                    data
                }) => {
                    commit('SET_EMPLOYEES', data);
                    resolve(data.data);
                })
                .catch(error => {
                    commit('SET_EMPLOYEES', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getSingle({
        commit
    }, id) {
            let url = `${API}/employees/${id}?include=employeeType,accounts,accounts.recipientType,accounts.bankAddress,accounts.recipientAddress,defaultCurrency,user`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_EMPLOYEE', data);
                    commit('SET_BANK_ACCOUNTS', data.accounts && data.accounts.data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_EMPLOYEE', {});
                    commit('SET_BANK_ACCOUNTS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
    },
    getByUserId({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/users/${id}?include=employee.address,employee.employeeType,employee.accounts,employee.accounts.recipientType,employee.accounts.bankAddress,employee.accounts.recipientAddress,employee.defaultCurrency,lastLogin`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_EMPLOYEE', data.employee && data.employee.data);
                    commit('SET_USER', data);
                    commit('SET_BANK_ACCOUNTS', data.employee && data.employee.data && data.employee.data.accounts && data.employee.data.accounts.data);
                    resolve(data);
                })
                .catch((error) => {
                    commit('SET_USER', {});
                    commit('SET_EMPLOYEE', {});
                    commit('SET_BANK_ACCOUNTS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    createUpdate({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/employees${data && data.id ? `/${data.id}` : ''}`;
            let method = data && data.id ? axios.put(url, data) : axios.post(url, data);
            method.then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    deleteBank({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/employee_accounts/${id}`;
            axios.delete(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    delete({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/employees/${id}`;
            axios.delete(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    search({
        commit
    }, keyword) {
        return new Promise((resolve, reject) => {
            let url = `${API}/employees?search=${keyword}`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_EMPLOYEES', data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_EMPLOYEES', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    searchUsers({
        commit
    }, keyword) {
        return new Promise((resolve, reject) => {
            let url = `${API}/users?search=${keyword}`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_EMPLOYEE_USERS', data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_EMPLOYEES', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getBankAccount({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/employee_accounts/${id}?include=employee,recipientType,bankAddress,recipientAddress`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_BANK_ACCOUNT', data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_BANK_ACCOUNT', {});
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getBankAccounts({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/users/${id}/employee_accounts`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_BANK_ACCOUNTS', data);
                    resolve();
                })
                .catch(error => {
                    commit('SET_BANK_ACCOUNTS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    createUpdateUser({
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            let url = payload && payload.id ? `${API}/users/${payload.id}` : `${API}/users/internal`;
            let method = payload && payload.id ? axios.put(url, payload) : axios.post(url, payload);
            method.then(({
                    data: {
                        data
                    }
                }) => {
                    // if it's a creation and users list already exist, append to users list
                    if (!payload.id) commit('ADD_USER', data);
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));

        });
    },
    createUpdateBank({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/employee_accounts${data && data.id ? `/${data.id}` : ''}`;
            let method = data && data.id ? axios.put(url, data) : axios.post(url, data);
            method.then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data.id);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
}