<template>
  <div class="home" :class="{blur:!user.id}">
    <delete-modal
      @trigger="deleteUserState"
      @cancel="cancelDelete"
      @deleteAction="deleteUser"
      :showDelete="showDelete"
      :name="showDeleteName"
      ref="delete"
    ></delete-modal>
    <headers class="bg-ggray pb-5">
      <div slot="breadcrumbs">
        <breadcrumbs :items="breadCrumbs"></breadcrumbs>
      </div>
      <div slot="title">
        <h2>
          <!-- <img src="@/assets/img/schooldent.png" class="user-img mr-2"> -->
          {{`${user.first_name} ${user.last_name}`}}
        </h2>
      </div>
      <div slot="action">
        <ul>
          <li style="width:200px">
            <multiselect
              track-by="id"
              :multiple="false"
              label="name"
              v-model="employee.status"
              class="no-caret-select mt-3"
              name="status"
              :options="statuses"
              @input="changeStatus"
              placeholder="Status"
              :show-labels="false"
            >
              <template slot="caret">
                <div>
                  <div class="multiselect__select">
                    <i class="zmdi zmdi-chevron-down pt-1" style="font-size:20px"></i>
                  </div>
                </div>
              </template>
            </multiselect>
          </li>
        </ul>
      </div>
    </headers>
    <tab-layout :user="user">
      <div slot="content">
        <router-view></router-view>
      </div>
    </tab-layout>
  </div>
</template>

<style lang="scss" scoped>
.user-img {
  height: 40px;
  width: 40px;
  //   object-fit: contain;
  border-radius: 40px;
}
</style>

<script>
import Headers from "@/components/Layouts/Headers";
import TabLayout from "@/components/Layouts/Users/Tab";
import Breadcrumbs from "@/components/Breadcrumbs/Index";
import DeleteModal from "@/components/Modals/Delete";
import {employees} from "../../store/employees/store";
export default {
  title: "Show | Users",
  components: { Headers, TabLayout, Breadcrumbs, DeleteModal },
  data() {
    return {
      showDelete: false,
      showDeleteName: "",
      statuses: [{id: true, name: "Active"}, {id: false, name: "Inactive"}],
    };
  },
  methods: {
    deleteUserState() {
      this.showDelete = true;
      this.showDeleteName = "User";
    },
    cancelDelete() {
      this.showDelete = false;
    },
    changeStatus() {
      let payload = {
        id: this.employee.id,
        is_active: this.employee.status.id
      }
      this.$store
        .dispatch("employees/createUpdate", payload)
        .then(s => {
          this.$forceUpdate();
          this.$store.commit('employees/SET_EMPLOYEE', this.employee);
          this.$toast.success(`🚮 Status Successfully Updated to ${this.employee.status.name}`, {
            position: "top-right",
            duration: 3000
          });
        }).catch((e) => {
          console.log(e);
        this.$toast.error("Failed to update status", {
          position: "top-right",
          duration: 3000
        });
      });
    },
    deleteUser(s) {
      this.$store
              .dispatch("employees/delete", this.employee.id)
              .then(s => {
                this.$router.push({ name: "users-index" });
                this.$toast.success("🚮 User Successfully Deleted", {
                  position: "top-right",
                  duration: 3000
                });
              });
    }
  },
  computed: {
    employee() {
      let e = this.$store.getters["employees/employee"];

      if (!e && this.$route.params.id == this.$auth.user().id) {
        e = this.$auth.user().employee && this.$auth.user().employee.data ? this.$auth.user().employee.data : {};
        this.$store.commit('employees/SET_EMPLOYEE', e)
      }
      if(!e.status) e.status = this.statuses.filter((s => s.id == e.is_active))[0];
      return e;
    },
    user() {
      let userObj =  this.$store.getters["employees/user"];

      if (!userObj && this.$route.params.id == this.$auth.user().id) {
        userObj = this.$auth.user();
        this.$store.commit('employees/SET_USER', userObj)
      }
      //console.log('is_user', userObj);
      userObj.status = this.statuses.filter((s) => userObj && s.id === userObj.is_banned)[0];
      return userObj;
    },
    breadCrumbs() {
      return [
        {
          text: "Home",
          to: { name: "home" }
        },
        {
          text: "All Users",
          to: { name: "users-index" }
        },
        {
          text: `${this.user.first_name} ${this.user.last_name}`,
          to: { name: "users-show-general", params: { id: this.user.id } }
        }
      ];
    }
  },
  created() {
    ['Admin', 'Super-Admin'].includes(this.$auth.user().role.name) && this.$store.dispatch("employees/getByUserId", this.$route.params.id);
  },
  beforeRouteUpdate (to, from, next) {
    this.$store.dispatch("employees/getByUserId", to.params.id);
    next();
  }
};
</script>
