<template>
  <div>
    <opportunities-modal></opportunities-modal>
  </div>
</template>
<script>
import OpportunitiesModal from "@/components/Modals/Opportunities";
export default {
  title: 'New | Opportunities',
  components: { OpportunitiesModal },
  data() {
    return {

    };
  }
};
</script>
