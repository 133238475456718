<template>
  <div>
    <tasks-table 
      :project_id="Number($route.params.id)" 
      :from="'Projects Task'" 
      :showProject="'true'" 
      :task_link="'project-tasks-show-general'">
    </tasks-table>
    <router-view></router-view>
  </div>
</template>
<script>
import TasksTable from "@/components/Tables/Tasks/Index";
export default {
  components: { TasksTable }
};
</script>
