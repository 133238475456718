<template>
  <div class="container-fluid">
    <div class="table pt-4 pb-4 pl-3 pr-4">
      <div class="table-header">
        <div class="row">
          <div class="col-sm-12 col-lg-4 float-left text-left">
            <small class="text-opacity">{{paginationData.total}} total projects</small>
          </div>

          <div class="col-sm-12 col-lg-8 float-right text-right">
            <project-filters
              @setFilters="setFilters"
              @showMoreFilters="showVisibleFilter"
              @removeColumnFromTable="removeColumnFromTable"
              :columns="columns"
              :params="moreParams"
              :visibleFilter="visibleFilter"
            ></project-filters>
          </div>
        </div>
        <div class="text-right mt-3" v-show="visibleFilter">
          <button
            @click="moreParams = {}"
            class="p-2 pl-3 pr-3"
            style="background:#E4E4E4; font-size:13px; border-radius:50px; border:none"
          >Reset Filter</button>
        </div>
        <project-filters-dropdown
          @setFilters="setFilters"
          @removeFilter="removeFilter"
          :params="moreParams"
          :statuses="statuses"
          :customers="customers"
          :billingTypes="billing_types"
          :visibleFilter="visibleFilter"
        ></project-filters-dropdown>
      </div>
      <div class="table-wrapper mt-4">
        <vuetable
          ref="vuetable"
          :api-url="api"
          :append-params="moreParams"
          :http-options="{ headers:{ 'Authorization' : 'Bearer ' + $auth.token(),'xorg' : getOrganization.organization_id() } }"
          @vuetable:loaded="loadTable"
          @vuetable:cell-clicked="gotoProject"
          :fields="columns.filter(s => s.active === true)"
          data-path="data"
          pagination-path
          :no-data-template="rowCount === null ? 'Loading data' : 'No Data Available'"
          @vuetable:pagination-data="onPaginationData"
        >
          <template slot="sn" slot-scope="props">
            <router-link
              :to="{name: 'projects-show-general', params: {id: props.rowData.id}}"
            >{{props.rowIndex + 1}}</router-link>
          </template>
          <template slot="cust" slot-scope="props">
            <router-link
              v-if="props.rowData.customer"
              :to="{name: 'customers-show', params: {id: props.rowData.customer_id}}"
            >{{optional(props.rowData, 'customer.data.company_name', optional(props.rowData, 'customer.company_name', 'N/A'))}}</router-link>
          </template>

          <template
            slot="billing_type_id"
            slot-scope="props"
          >{{props.rowData.billing_type && props.rowData.billing_type.name}}</template>
          <template slot="budget" slot-scope="props">{{props.rowData.estimated_hours}} hours</template>
          <template slot="actions" slot-scope="props">
            <router-link :to="{name: 'projects-show-general', params: {id: props.rowData.id}}">
              <button class="btn-lightgray-round">
                <i class="zmdi zmdi-plus pr-2"></i>View Project
              </button>
            </router-link>
          </template>
        </vuetable>
        <vuetable-pagination
          v-show="paginationData.total"
          :css="table_css.pagination"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
        <div class="text-center" v-if="rowCount <=0 || rowCount === null">
          <slot name="empty"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import moment from "moment";
import getOrganization from "@/helpers/getOrganization";
import ProjectFilters from "@/components/Filters/Project";
import ProjectFiltersDropdown from "@/components/Filters/Project/More";
import getPaginationData from "@/helpers/pagination";
import VuetablePagination from "@/components/Layouts/VueTablePagination";
import { toLocal } from "@/helpers/date";
import { optional, html2text } from "@/helpers/random";

export default {
  components: { ProjectFilters, ProjectFiltersDropdown, VuetablePagination },
  data() {
    return {
      getOrganization,
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      //   ['__checkbox','Project Title', 'Customer', 'Project Type', 'Time Budget','Start Date', 'End Date','Outstanding tasks']
      columns: [
        {
          name: "__checkbox",
          titleClass: "center aligned",
          dataClass: "center aligned",
          cvisible: false,
          active: true
        },
        {
          name: "name",
          title: "Project Title",
          sortField: "name",
          cvisible: true,
          active: true
        },
        {
          name: "description",
          title: "Project Description",
          cvisible: true,
          active: true,
          callback: d => {
            d = html2text(d);
            if (d) {
              if (d.length > 20) {
                return d.substring(0, 20) + "...";
              }
              return d;
            } else {
              return "N/A";
            }
          }
        },
        {
          name: "__slot:cust",
          title: "Customer",
          sortField: "customer_id",
          cvisible: true,
          active: true
        },
        {
          name: "project_status",
          title: "Project Status",
          sortField: "project_status_id",
          cvisible: true,
          active: true,
          callback: d => {
            return d ? d.name : "N/A";
          }
        },
        {
          name: "__slot:budget",
          title: "Time Budget",
          cvisible: true,
          active: true
        },
        {
          name: "currency",
          title: "Billed Currency",
          cvisible: true,
          active: false,
          callback: d => {
            return d ? d.name : "N/A";
          }
        },
        {
          name: "hourly_rate",
          title: "Project Hourly Rate",
          cvisible: true,
          active: false
        },
        {
          name: "hourly_cap",
          title: "Hourly Cap",
          cvisible: true,
          active: false
        },
        {
          name: "total_expenses",
          title: "Expenses",
          cvisible: true,
          active: false
        },
        {
          name: "revenue",
          title: "Revenue",
          cvisible: true,
          active: false
        },
        {
          name: "start_date",
          title: "Start Date",
          cvisible: true,
          active: true,
          callback: s => {
            return s ? toLocal(s).format("MM/DD/YYYY") : "N/A";
          }
        },
        {
          name: "end_date",
          title: "End Date",
          cvisible: true,
          active: true,
          callback: s => {
            return s ? toLocal(s).format("MM/DD/YYYY") : "N/A";
          }
        },
      
        {
          name: "__slot:project_cost",
          title: "Cost",
          cvisible: true,
          active: false
        },
        {
          name: "__slot:project_manager",
          title: "Project Manager",
          cvisible: true,
          active: false
        },
        // {
        //   name: "id",
        //   title: "Outstanding Tasks",
        //   cvisible: true,
        //   active: true,
        //   callback: d => {
        //     return "N/A";
        //   }
        // },
        {
          name: "__slot:actions",
          title: "Actions",
          active: true,
          cvisible: false
        }
      ],
      moreParams: {},
      paginationData: {},
      api: ""
    };
  },
  methods: {
    optional,
    toLocal,
    html2text,
    getPaginationData,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    removeFilter() {

    },
    gotoProject(e) {
      this.$router.push({
        name: "projects-show-general",
        params: { id: e.id }
      });
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
    },
    setFilters(d, l) {
      if (this.moreParams && this.moreParams[l] == "") {
        delete this.moreParams[l];
      }
      
      this.moreParams = d;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onChangePage(page) {
      this.moreParams.page = page;
      this.setFilters(this.moreParams, page);
    },
    refreshTable() {
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find(s => s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    }
  },
  computed: {
    statuses() {
      return this.$store.getters["global/project_statuses"];
    },
    billing_types() {
      return this.$store.getters["global/project_billing_types"];
    },
    customers() {
      return this.$store.getters["customers/customers_simple"];
    },
    xorgData() {
      return window.localStorage.getItem("selected_organization");
    }
  },
  created() {
    this.statuses.length || this.$store.dispatch("global/getProjectStatuses");
    this.billing_types.length || this.$store.dispatch("global/getProjectBillingTypes");
    this.$store.dispatch("customers/index");
    this.api = this.$baseApi + `/projects?include=milestones`;
  },
  watch: {
    $route(to, from) {
      this.refreshTable();
      this.$store.dispatch("projects/getMetrics");
    }
  }
};
</script>

