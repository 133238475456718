// Response messages
const GENERIC_CREATE_SUCCESS = "💪 Item created Successfully!"
const GENERIC_UPDATE_SUCCESS = "💪 Item updated Successfully!"
const GENERIC_CREATE_UPDATE_ERROR = "Failed to perform action!"

const USER_PROFILE_UPDATE_SUCCESS = "💪 Profile Updated Successfully!"

const PROJECT_CREATE_SUCCESS = "💪 Your Project was created Successfully!"
const PROJECT_UPDATE_SUCCESS = "💪 Your Project was Updated Successfully!"
const PROJECT_UPDATE_ERROR = "Something went wrong while updating your Project, Please Try again!"
const PROJECT_CREATE_ERROR = "Something went wrong while creating your Project, Please Try again!"

const CONTACTS_CREATE_SUCCESS = "💪 Contact was Created Successfully";
const CONTACTS_UPDATE_SUCCESS = "💪 Contact was Updated Successfully";
const CONTACTS_UPDATE_ERROR = "Something went wrong while updating Customer Contact, Please Try again!";
const CONTACTS_CREATE_ERROR = "Something went wrong while creating Customer Contact, Please Try again!";

const MILESTONE_CREATE_SUCCESS = "💪 Your Milestone was created Successfully!"
const MILESTONE_UPDATE_SUCCESS = "💪 Your Milestone was updated Successfully!"
const MILESTONE_CREATE_ERROR = "Something went wrong while creating your Milestone, Please Try again!"
const MILESTONE_UPDATE_ERROR = "Something went wrong while updating your Milestone, Please Try again!"

const TASK_CREATE_SUCCESS = "💪 Your Task was created Successfully!"
const TASK_UPDATE_SUCCESS = "💪 Your Task was Updated Successfully!"

const TASK_CREATE_ERROR = "Something went wrong while creating your Task, Please Try again!"
const TASK_UPDATE_ERROR = "Something went wrong while updating your Task, Please Try again!"
const TASK_COMMENT_DELETE = "Task's Comment was successfully  deleted!"
const PAYMENT_METHOD_CREATE_SUCCESS = "Payment method  added Succeessfully!"
const PAYMENT_METHOD_CREATE_ERROR = "Something went wrong while adding Credentials, Please Try again!"


const CUSTOMER_CREATE_SUCCESS = "💪 Your Customer was created Successfully!";
const CUSTOMER_UPDATE_SUCCESS = "💪 Your Customer was updated Successfully!";
const CUSTOMER_CREATE_ERROR = "Something went wrong while creating your Customer, Please Try again!";
const CUSTOMER_UPDATE_ERROR = "Something went wrong while updating your Customer, Please Try again!";

const USER_CREATE_SUCCESS = "💪 New User Profile was created Successfully!";
const USER_UPDATE_SUCCESS = "💪 User Profile was updated Successfully!";
const USER_UPDATE_ERROR = "Something went wrong while updating your User, Please Try again!";
const USER_CREATE_ERROR = "Something went wrong while creating your User, Please Try again!";

const ORG_CREATE_SUCCESS = "💪 Your Organization  was created Successfully!";
const ORG_UPDATE_SUCCESS = "💪 Your Organization  was updated Successfully!";
const ORG_UPDATE_ERROR = "Something went wrong while creating your Organization, Please Try again!";

const OPPORTUNITY_CREATE_SUCCESS = "💪 Your Opportunity was created Successfully!";
const OPPORTUNITY_UPDATE_SUCCESS = "💪 Your Opportunity was updated Successfully!";
const OPPORTUNITY_UPDATE_ERROR = "Something went wrong while updating your Opportunity, Please Try again!";
const OPPORTUNITY_CREATE_ERROR = "Something went wrong while creating your Opportunity, Please Try again!";

const BANK_CREATE_SUCCESS = "💪 Your Bank was created Successfully!";
const BANK_UPDATE_SUCCESS = "💪 Your Bank was updated Successfully!";
const BANK_UPDATE_ERROR = "Something went wrong while updating your Bank, Please Try again!";
const BANK_CREATE_ERROR = "Something went wrong while creating your Bank, Please Try again!";

const EMPLOYEE_CREATE_SUCCESS = "💪 Your Employee was created Successfully!";
const EMPLOYEE_UPDATE_SUCCESS = "💪 Your Employee was updated Successfully!";
const EMPLOYEE_UPDATE_ERROR = "Something went wrong while updating your Employee, Please Try again!";
const EMPLOYEE_CREATE_ERROR = "Something went wrong while creating your Employee, Please Try again!";

const COMMENT_CREATE_SUCCESS = "💪 Your Comment was created Successfully!";
const COMMENT_UPDATE_SUCCESS = "💪 Your Comment was updated Successfully!";

const INVOICE_UPDATE_SUCCESS = "💪 Your Invoice was updated Successfully!";
const INVOICE_CREATE_SUCCESS = "💪 Your Invoice was created Successfully!";
const INVOICE_UPDATE_ERROR = "Something went wrong while updating your Invoice, Please Try again!";
const INVOICE_CREATE_ERROR = "Something went wrong while creating your Invoice, Please Try again!";

export default {
    PROJECT_CREATE_SUCCESS,
    PROJECT_UPDATE_SUCCESS,
    PROJECT_CREATE_ERROR,
    PROJECT_UPDATE_ERROR,

    MILESTONE_CREATE_ERROR,
    MILESTONE_UPDATE_ERROR,
    MILESTONE_CREATE_SUCCESS,
    MILESTONE_UPDATE_SUCCESS,

    TASK_CREATE_ERROR,
    TASK_CREATE_SUCCESS,
    TASK_UPDATE_ERROR,
    TASK_UPDATE_SUCCESS,

    TASK_COMMENT_DELETE,

    ORG_CREATE_SUCCESS,
    ORG_UPDATE_SUCCESS,
    ORG_UPDATE_ERROR,

    CUSTOMER_CREATE_SUCCESS,
    CUSTOMER_UPDATE_SUCCESS,
    CUSTOMER_CREATE_ERROR,
    CUSTOMER_UPDATE_ERROR,

    COMMENT_CREATE_SUCCESS,
    COMMENT_UPDATE_SUCCESS,

    CONTACTS_CREATE_SUCCESS,
    CONTACTS_UPDATE_SUCCESS,
    CONTACTS_UPDATE_ERROR,
    CONTACTS_CREATE_ERROR,

    PAYMENT_METHOD_CREATE_SUCCESS,
    PAYMENT_METHOD_CREATE_ERROR,

    GENERIC_CREATE_SUCCESS,
    GENERIC_CREATE_UPDATE_ERROR,
    GENERIC_UPDATE_SUCCESS,

    USER_CREATE_SUCCESS,
    USER_CREATE_ERROR,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_ERROR,
    USER_PROFILE_UPDATE_SUCCESS,

    OPPORTUNITY_CREATE_SUCCESS,
    OPPORTUNITY_CREATE_ERROR,
    OPPORTUNITY_UPDATE_SUCCESS,
    OPPORTUNITY_UPDATE_ERROR,

    BANK_CREATE_SUCCESS,
    BANK_CREATE_ERROR,
    BANK_UPDATE_SUCCESS,
    BANK_UPDATE_ERROR,

    EMPLOYEE_CREATE_SUCCESS,
    EMPLOYEE_CREATE_ERROR,
    EMPLOYEE_UPDATE_SUCCESS,
    EMPLOYEE_UPDATE_ERROR,

    INVOICE_UPDATE_SUCCESS,
    INVOICE_UPDATE_ERROR,
    INVOICE_CREATE_SUCCESS,
    INVOICE_CREATE_ERROR,
}
