<template>
  <div>
    <user-modal></user-modal>
  </div>
</template>
<script>
import UserModal from "@/components/Modals/Users";
export default {
  title: `New | Users`,
  components: { UserModal },
  data() {
    return {

    };
  }
};
</script>
