<template>
  <div>
    <project-modal  @refresh="refresh" :project="project" v-if="project.id"></project-modal>
  </div>
</template>
<script>
import ProjectModal from "@/components/Modals/Projects";
export default {
  title: 'Edit | Projects',
  data() {
    return {

    };
  },
  components: { ProjectModal },
  computed: {
    project() {
      return this.$store.getters["projects/project"];
    },
  },
  methods: {
    refresh() {
      this.$store.dispatch("projects/getSingle", this.$route.params.id);
    }
  },
  created() {
    this.$store.dispatch("projects/getSingle", this.$route.params.id);
  }
};
</script>
