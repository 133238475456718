<template>
  <div
    :class="`mt-0 pt-5 pl-0 pr-0 d-flex flex-wrap h-100 ${loaded ? '' : 'blur'}`"
    style="background: rgba(218, 241, 255, 0.1)"
  >
   
    <stripe-modal
      v-show="showSubscribeModal"
      @close="close"
      @refreshSubscription="refresh"
    >
    </stripe-modal>
    <div class="receipt ml-5 mr-5 p-4">
      <div
        class="
          receipt__header
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <div>
          <small class="text-opacity">My Active</small>
          <h6 class="text-bold text-opacity-pale">Subscription</h6>
        </div>
        <div>
          <button
            @click="showSubscribeModal = true"
            class="pl-3 pr-3 d-flex align-items-center"
            style="
              background: #0a5685;
              color: white;
              border: none;
              height: 35px;
              border-radius: 20px;
            "
          >
            <h6 class="text-bold text-white text-opacity-pale mb-0">
              + Add Seats
            </h6>
          </button>
        </div>
      </div>
      <div v-if="loadedAll" class="receipt__info mt-3">
        <ul class="d-flex flex-column">
          <li
            :class="`d-flex flex-row justify-content-between pt-2 pb-2 pl-2 pr-2 ${loaded ? '' : 'blur'}`"
          >
            <h6 class="text-opacity">Plan Name</h6>
            <h6 class="text-opacity-pale text-bold">{{license.name}}</h6>
          </li>
          <li
            :class="`d-flex flex-row justify-content-between pt-2 pb-2 pl-2 pr-2 ${loaded ? '' : 'blur'}`"
          >
            <h6 class="text-opacity">Status</h6>
            <h6
              class="text-opacity-pale text-bold text-red"
              :style="
                subscription && subscription.stripe_status == 'active'
                  ? 'color:green'
                  : 'color: red'"
            >
              {{optional(subscription, 'stripe_status', '').toUpperCase()}}
            </h6>
          </li>
       
          <li
            :class="`
              d-flex
              flex-row
              justify-content-between
              align-items-center
              pt-2
              pb-2
              pl-2
              pr-2
              ${loaded ? '' : 'blur'}
            `"
          >
            <h6 class="text-opacity">Number of Seat</h6>
            <h6 class="text-opacity-pale text-bold">
              {{
                optional(subscription, 'quantity', "")
              }}
            </h6>
          </li>
          <li
            :class="`
              d-flex
              flex-row
              justify-content-between
              align-items-center
              pt-2
              pb-2
              pl-2
              pr-2
              ${loaded ? '' : 'blur'}
            `"
          >
            <h6 class="text-opacity">Price</h6>
            <h6 class="text-opacity-pale text-bold">${{parseFloat(license.monthly_cost || 0).toFixed(2)}} / seat / month</h6>
          </li>
          <li
            :class="`
              d-flex
              flex-row
              justify-content-between
              align-items-center
              pt-2
              pb-2
              pl-2
              pr-2
              ${loaded ? '' : 'blur'}
            `"
          >
            <h6 class="text-opacity">Next Billing</h6>
            <h6 class="text-opacity-pale text-bold text-main">
              {{subscription && subscription.created_at ? toLocal(moment(subscription.created_at).add(1, 'month')).format('ll') : 'N/A'}}
            </h6>
          </li>
          <li
            :class="`
              d-flex
              flex-row
              justify-content-between
              align-items-center
              pt-2
              pb-2
              pl-2
              pr-2
              ${loaded ? '' : 'blur'}
            `"
          >
            <h6 class="text-opacity">Last Payment Amount</h6>
            <h6 class="text-opacity-pale text-bold">
              ${{
                optional(this.cards, 'subtotal', 0) ? numberWithCommas(parseFloat(optional(this.cards, 'subtotal', 0))/100) : "0"
              }}
            </h6>
          </li>
          <li
            class="
              d-flex
              flex-row
              justify-content-between
              align-items-center
              pt-2
              pb-2
              pl-2
              pr-2
            "
          >
            <h6 class="text-opacity">Payment method</h6>
            <h6 class="text-opacity-pale text-bold">Stripe</h6>
          </li>
        </ul>
      </div>
      <div v-else>
        <h6 class="text-opacity-pale">Fetching Subscription information</h6>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.subs {
  background: white;
  width: 60%;
  border-radius: 10px;
}
.receipt {
  width: 100%;
  background: white;

  .receipt__header {
    border-bottom: 2px dashed #f0f3f3;
  }
  .receipt__info ul li:nth-child(2n) {
    background: #f0f3f3;
  }
}
.subs__in {
  background: #f0f3f3;
  border-radius: 10px;

  .subs-icon {
    height: 50px;
    width: 50px;
    background: white;
    border-radius: 50px;
    box-shadow: 0 7px 30px 0 rgba(217, 217, 217, 0.36);
    font-size: 25px;
  }
  .subs-title {
    width: 70%;
  }
  button.no-sub {
    background: transparent;
    border: none;
  }
}
</style>
<script>
// import SubscribeModal from "@/components/Modals/Subscribe.vue";
import StripeModal from "@/components/Modals/StripePayment.vue";
import { numberWithCommas } from "@/helpers/number";
import moment from 'moment';
import {toLocal} from '@/helpers/date';
import {optional} from '@/helpers/random';

export default {
  components: { StripeModal },
  data() {
    return {
   
      loaded: false,
      breadCrumbs: [
        {
          text: "Home",
          href: "#",
        },
        {
          text: "Subscriptions",
          active: true,
        },
      ],
      showSubscribeModal: false,
      body: {
        
      },
      isSubscribed: false,
      intentData: {},
      subscription: {},
      loadedAll : false,
      cards: undefined,

    };
  },
  methods: {
    moment,
    numberWithCommas,
    toLocal,
    optional,
    subscribe() {
      this.showSubscribeModal = true;
    },
    subscribed(d) {
      this.isSubscribed = true;
      this.intentData = d;
    },
    close(d) {
      this.showSubscribeModal = false;
    },
    refresh(d) {
      // this.subscription = d;
      this.fetchSubscription();
    },
    fetchAmount() {
      this.loaded = false;
      this.$store.dispatch("global/getStripeKey")
      .then((s) => {
        this.cards = optional(s, 'data.data.invoices', []).shift();
        
        this.body.amount = optional(this.cards, 'subtotal', 0);
        
      }).catch(() => {
        this.body.amount = 0
      }).finally(() => {
        this.loaded = true;
      });
    },
    fetchSubscription() {
     
      this.loaded = false;
      this.$store
        .dispatch("global/getStripeKey")
        .then(({data : {data}}) => {
          let subscription = optional(data, 'subscription', {stripe_status: 'trial', quantity: 0});
         
          this.subscription = subscription;
        })
        .catch(() => {
          this.subscription = {stripe_status: 'trial', quantity: null};
        })
        .finally(() => {
          this.loaded = true;
        });
    },
  },
  computed: {
    license() {
      return this.$store.getters['global/license']
    },
  
  },
  created() {
    
    Promise.all([
      typeof this.body.amount === 'undefined' && this.fetchAmount(),
      typeof this.subscription.stripe_status === 'undefined' && this.fetchSubscription(),
      typeof this.license.name === 'undefined' && this.$store.dispatch("global/getLicense"),
      ]).finally(() => this.loadedAll = true);
  },
};
</script>