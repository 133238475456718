import axios from "axios";
import {
    updatePending
} from "@/helpers/axiosIntercept";

const API = process.env.VUE_APP_BASE_URI;

export default {
    createUpdate({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/customers${data && data.id ? `/${data.id}` : ''}`;
            let method = data && data.id ? axios.put(url, data) : axios.post(url, data);
            method.then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_CUSTOMER', data);
                    resolve(data.id);
                })
                .catch(error => {
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getMilestones({
        commit
    }, project_id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/customers/${project_id}/milestones`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_MILESTONES', data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_MILESTONES', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    delete({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/customers/${id}`;
            axios.delete(url).then(({
                    data
                }) => {
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    index({
        commit
    }, queryParams = {}) {
        return new Promise((resolve, reject) => {
            let url = `${API}/customers`;
            axios.get(url, {
                    params: queryParams
                })
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_CUSTOMERS', data);
                    resolve();
                })
                .catch((error) => {
                    commit('SET_CUSTOMERS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getSingle({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/customers/${id}?include=billing_address,shipping_address,status,industry,source,contacts,primaryContact,currency`
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_CUSTOMER', data);
                    //  commit('SET_CONTACTS', data.contacts.data);
                    resolve(data);
                })
                .catch((error) => {
                    commit('SET_CUSTOMER', {});
                    //  commit('SET_CONTACTS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getCustomerMetrics({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/customers/${id}/metrics`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    // commit('SET_CUSTOMER_METRICS', data);
                    resolve(data);
                })
                .catch(error => {
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getProjects({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/customers/${id}/projects?include=timeLogs,expenses,manager`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_PROJECTS', data);
                    resolve();
                })
                .catch(error => {
                    commit('SET_PROJECTS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getTasks({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/customers/${id}/tasks`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_TASKS', data);
                    resolve();
                })
                .catch(error => {
                    commit('SET_TASKS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getInvoices({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/customers/${id}/invoices`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_INVOICES', data);
                    resolve();
                })
                .catch(error => {
                    commit('SET_INVOICES', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getAttachments({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/customers/${id}/attachments`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_ATTACHMENTS', data);
                    resolve();
                })
                .catch(error => {
                    commit('SET_ATTACHMENTS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getOpportunities({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/opportunities?customer_id=${id}`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_OPPORTUNITIES', data);
                    resolve();
                })
                .catch(error => {
                    commit('SET_OPPORTUNITIES', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    createUpdateContact({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/contacts${data && data.id ? `/${data.id}` : ''}`;
            let method = data && data.id ? axios.put(url, data) : axios.post(url, data);
            method.then(({
                    data
                    //update the customer with primary contact info
                }) => {
                    resolve(data && data.data);
                })
                .catch(error => {
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getContacts({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/contacts?customer_id=${id}`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_CONTACTS', data);
                    resolve();
                })
                .catch(error => {
                    commit('SET_CONTACTS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    deleteContact({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/contacts/${id}`;
            axios.delete(url)
                .then(({
                    data
                }) => {
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getContact({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/contacts/${id}`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_CONTACT', data)
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
}