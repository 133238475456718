import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import './assets/css/main.scss'
import './assets/css/tooltip.css'
import './assets/css/material-icons/css/material-design-iconic-font.min.css'
import { BootstrapVue,IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import VueAuth from '@websanova/vue-auth';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import "vue-datetime/dist/vue-datetime.css";
import Axios from 'axios'
import VueAxios from 'vue-axios'
import {requestIntercept, responseIntercept, updatePending} from "@/helpers/axiosIntercept"
import {optional} from "@/helpers/random";

import VueQuillEditor from 'vue-quill-editor'
// import { Mentionable } from "vue-mention";
import Quill from "quill";
Quill.register("modules/mention", Mention);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "quill-mention/dist/quill.mention.min.css";
import Mention from "quill-mention";

//medium editor
import 'medium-editor/dist/css/medium-editor.css'
import 'vuejs-medium-editor/src/themes/default.css'
// for the code highlighting
import 'highlight.js/styles/ocean.css';


import VeeValidate from 'vee-validate';
import Multiselect from 'vue-multiselect'
import Vuetable from 'vuetable-2';
import VCalendar from 'v-calendar';
import Vuex from 'vuex'
import store from '@/store';
import _ from 'lodash';   

import VueToast from 'vue-toast-notification';
import ReactiveStorage from "vue-reactive-localstorage";
import Skeleton from 'vue-loading-skeleton';
// Import any of available themes
import 'vue-toast-notification/dist/theme-default.css';
import getOrganization from "@/helpers/getOrganization";
import titleMixin from './mixins/titleMixin'
import editorMixin from './mixins/editor'
import VTooltip from "v-tooltip";

Vue.use(VTooltip);
Vue.config.productionTip = process.env.NODE_ENV === 'production';
Vue.prototype.$baseApi = process.env.VUE_APP_BASE_URI;

Object.defineProperty(Vue.prototype, '$_', { value: _ });
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueToast);
Vue.use(Skeleton);
Vue.mixin(titleMixin);
Vue.mixin(editorMixin);
Vue.use(VeeValidate, {
  fieldsBagName: 'veeFields'
});

Vue.use(ReactiveStorage, {
  "selected_organization": getOrganization.organization_id()
});
Vue.use(VueAxios, Axios);
Vue.use(Vuex);

Vue.component('multiselect', Multiselect);
Vue.component('vueDropzone', vue2Dropzone);
Vue.component('vuetable', Vuetable);
Vue.use(VueQuillEditor, {modules: {mention: {}}})

Vue.use(VCalendar, {
  componentPrefix: 'vc'
});
Vue.router = router;

Vue.prototype.$profileUrl = Vue.prototype.$baseApi + "/users/me?include=organization,organizations,employee,organization.payPeriod,organization.subscriptions,orgRole,organization.licence";
Vue.prototype.$loginUrl = Vue.prototype.$baseApi + "/auth/login";
Vue.prototype.$logoutUrl = Vue.prototype.$baseApi + "/auth/logout";
Vue.prototype.$registerUrl = Vue.prototype.$baseApi + "/users";
Vue.prototype.$otpLoginUrl = Vue.prototype.$baseApi + "/auth/otp";

sessionStorage.clear();
// always clear session storage on page reload
let orgCode = getOrganization.organization_id()
Vue.use(VueAuth, {
  auth: {
    request: function (req, token, org) {
      this.options.http._setHeaders.call(this, req, {
        Authorization: 'Bearer ' + token,
        xorg: getOrganization.organization_id()
      });
    },
    response: function (res) {
      // redundant check to ensure all requests resolve so the page doesn't get stuck loading
      updatePending(res.config.url);
      let parsedToken = optional(res, 'data.token', null);
      if (parsedToken) {
        parsedToken = parsedToken.split('Bearer ');

        parsedToken = parsedToken[parsedToken.length > 1 ? 1 : 0];
        window.localStorage.setItem('default_auth_token', parsedToken);
        return parsedToken;
      }
    }
  },
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require("@websanova/vue-auth/drivers/router/vue-router.2.x"),
  loginData: {
    url: Vue.prototype.$loginUrl,
    fetchUser: false,
    method: "POST",
  },
  logoutData: {
    url: Vue.prototype.$logoutUrl,
    method: "GET"
  },
  registerData: {
    url: Vue.prototype.$registerUrl,
    autoLogin: false,
    method: "POST"
  },
  refreshData: {
    url: Vue.prototype.$profileUrl,
    method: "GET",
    enabled: true,
    interval: 30,
  },
  fetchData: {
    url: Vue.prototype.$profileUrl,
    method: "GET",
    enabled: true,
    success() {
      let userData = Vue.auth.user();
      userData['role'] = userData.orgRole;
      Vue.auth.user(userData)
    }
  }
});

Vue.axios.interceptors.request.use(requestIntercept, requestIntercept);
Vue.axios.interceptors.response.use( responseIntercept, responseIntercept);

Vue.filter('optional', optional)

var vvm = new Vue({
  router,
  store: new Vuex.Store(store),
  render: h => h(App)
}).$mount('#app');

window.vvm = vvm;