<template>
  <div>
    <invoices-table :api="invoicesApi" :moreParams="moreParams" @setInvoiceTotal="getInvoiceTotal"></invoices-table>
  </div>
</template>

<script>
import InvoicesTable from "@/components/Tables/Invoice/Index";
export default {
  props: ["project_type", "id","moreParams"],
  components: {InvoicesTable },
  computed: {
    invoicesApi() {
      return `${process.env.VUE_APP_BASE_URI}/projects/${this.id}/invoices`;
    }
  },
  methods: {
    getInvoiceTotal(value) {
      this.$emit("getInvoiceTotal", value);
    }
  }
};
</script>
