<template>
  <div class="kanban-board" id="subtasks">
    <div class="d-flex justify-content-end mb-4">
      <router-link :to="{name: 'tasks-preview-subtasks-new'}">
        <h6 style="color:#3f92cc !important;cursor:pointer; text-decoration:none !important">
          <span class="pl-2 pr-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 16 16">
              <path
                fill="#3f92cc"
                d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 4c-.552 0-1 .448-1 1v2H5c-.552 0-1 .448-1 1s.448 1 1 1h2v2c0 .552.448 1 1 1s1-.448 1-1V9h2c.552 0 1-.448 1-1s-.448-1-1-1H9V5c0-.552-.448-1-1-1z"
              ></path>
            </svg>
          </span>
          Add SubTask
        </h6>
      </router-link>
    </div>
    <!-- {{subtasks}} -->
    <div
      class="kanban-board__status w-100"
      v-for="(sub,index) in subtasks"
      :key="index"
      :id="sub.status && sub.status.name.replace(' ', '').replace('/', '')"
    >
      <router-link
        :to="{name:  'tasks-preview-show',params: {tid:sub.id},query: {subtask: true, from: $route.params.tid}}"
      >
        <div class="kanban-board__tasks p-3">
          <div class="d-flex flex-row align-items-center">
            <div class="mr-3">
              <button
                class="kanban-board__tasks-profile"
                v-b-tooltip.hover
                :title="optional(sub, 'employee.data.user.data', null) ? sub.employee.user.first_name + ' ' +sub.employee.user.last_name : '-'"
              >{{optional(sub, 'employee.user', null) ? sub.employee.user.first_name.charAt(0)+sub.employee.user.last_name.charAt(0) : '-'}}</button>
            </div>
            <div class="mr-3">
              <h6 class="h6 text-opacity-pale mb-0">{{sub.name}}</h6>
            </div>
            <div class="mr-3">
              <span class="badge">{{sub.status && sub.status.name}}</span>
            </div>
          </div>
        </div>
      </router-link>
    </div>
    <h6
      class="h6 text-opacity text-bold text-center"
      v-show="subtasks &&  subtasks.length == 0"
    >No Subtasks created for this Task yet</h6>
    <!-- <div class="kanban-board__status w-100" id="NotStarted">
      <div class="kanban-board__tasks p-3">
        <div class="d-flex flex-row align-items-center">
          <div class="mr-3">
            <ul class="task-pane__profiles">
              <li class="d-flex align-items-center justify-content-center">OL</li>
            </ul>
          </div>
          <div class="mr-3">
            <h6 class="h6 text-opacity-pale">Help Admin do something that has do do with food</h6>
          </div>
          <div class="mr-3">
            <span class="badge">In Progress</span>
          </div>
        </div>
      </div>
    </div>
    <div class="kanban-board__status w-100" id="InProgress">
      <div class="kanban-board__tasks p-3">
        <div class="d-flex flex-row align-items-center">
          <div class="mr-3">
            <ul class="task-pane__profiles">
              <li class="d-flex align-items-center justify-content-center">OL</li>
            </ul>
          </div>
          <div class="mr-3">
            <h6 class="h6 text-opacity-pale">Help Admin do something that has do do with food</h6>
          </div>
          <div class="mr-3">
            <span class="badge">In Progress</span>
          </div>
        </div>
      </div>
    </div>-->
    <router-view></router-view>
  </div>
</template>

<style lang="scss" scoped >
$done: green;
$inprogress: #cccc11;
$pending: red;
$cancelled: #714242;
$testing: #0a5685;
#subtasks {
  .kanban-board__tasks {
    border: 2px solid #f5f5f5;
    &:not(:last-child) {
      border-bottom: none !important;
      display: none;
    }
  }
  .badge {
    padding: 6px;
  }
  #NotStarted {
    .badge {
      background: $pending;
      color: white !important;
    }
  }
  #InProgress {
    .badge {
      background: $inprogress;
      color: white !important;
    }
  }
  #TestingReview {
    .badge {
      background: $testing;
      color: white !important;
    }
  }
  #Done {
    .badge {
      background: $done;
      color: white !important;
    }
  }
  .kanban-board__status {
    &:not(:last-child) {
      .kanban-board__tasks {
        border-bottom: none !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
    }
  }
}
</style>

<script>
import { toLocal } from "@/helpers/date";
import getOrganization from "@/helpers/getOrganization";
import { optional } from "@/helpers/random";

export default {
  data() {
    return {
      optional,
      loaded: false,
      uploading: false,
      uploaded: false,
      uploadNumber: 0,
      uploadedFiles: []
    };
  },
  methods: {
    toLocal,
    fetchFiles() {
      return this.uploadedFiles.push(this.$refs.attachments.getAcceptedFiles());
    },
    setFileStatus(file) {
      this.uploading = true;
      this.uploaded = false;
      this.uploadNumber = 0;
    },
    setProgressStatus(f, i, b) {
      this.uploadNumber = f;
    },
    updateFileStatus(f) {
      this.uploaded = true;
      this.uploading = false;
      f["created_at"] = Date.now();
      this.uploadedFiles.push(f);
      console.log(f, this.uploadedFiles);
    },
    sendingEvent(file, xhr, formData) {
      // console.log(file, xhr, formData);
      formData.append("paramName", "some value or other");
    }
  },
  computed: {
    dropzoneOptions() {
      return {
        url: `${this.$baseApi}/tasks/${this.$route.params.id}/attachments`,
        thumbnailWidth: 150,
        maxFilesize: 5,
        headers: {
          Authorization: "Bearer " + this.$auth.token(),
          xorg: getOrganization.organization_id()
        }
      };
    },
    subtasks() {
      let tasks = this.$store.getters["tasks/task"];
      let subtasks = tasks.subtasks;
      return subtasks;
    }
  },
  created() {
    Promise.all([
      this.$store.dispatch("tasks/getSingle", this.$route.params.tid)
    ]);
    // console.log("Route change");
    //you can update the uploaded files array from value sent from server here
    //this.$store.dispatch('tasks/getAttachments', this.$route.params.id)
  },
};
</script>
