/**
 * This would be the home of our stores.
 */
import {
    global,
} from './global/store';
import {
    projects
} from './projects/store';
import {
    employees
} from "./employees/store";
import {
    customers
} from "./customers/store";
import {
    tasks
} from "./tasks/store";
import {
    invoices
} from "./invoices/store";
import {
    payments
} from "./payments/store";
import {
    milestones
} from "./milestones/store";
import {
    payrolls
} from "./payrolls/store";
import {
    reports
} from "./reports/store";


export default {
    modules: {
        global,
        projects,
        employees,
        customers,
        tasks,
        invoices,
        payments,
        milestones,
        payrolls,
        reports
    },
};
