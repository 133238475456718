import axios from 'axios';
import {
    updatePending
} from "@/helpers/axiosIntercept";

const API = process.env.VUE_APP_BASE_URI;

export default {
    getCountries({
        commit
    }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/countries?include=regions`;
            axios.get(url)
                .then(async ({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_COUNTRIES', data);
                    resolve(data);
                })
                .catch((error) => {
                    reject(error.response)
                }).finally(() => updatePending(url));
        });
    },
    setActiveOrganization({commit}, data) {
        return new Promise((resolve, reject) => {
            commit('SET_ORGANIZATION', data);
        })
    },
    getStripeKey({commit}) {
        return new Promise((resolve, reject) => {
            let url = `${API}/organizations/subscription`;
            axios.get(url).then(data => {
                commit('SET_SUBSCRIPTION', data && data.data && data.data.data);
                resolve(data)
            }).catch(err=> {
                reject(err.response)
            })
        })
    },
    setOrgKey({
        commit
    }, data) {
        commit('SET_ORGANIZATION_KEY', data);
    },
    updateProfile({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            axios.put(`${API}/users/${data.id}`, data)
                .then(
                    s => {
                        if (s) {
                            resolve(s);
                        }
                    })
                .catch(error => reject(error.response));
        });
    },
    updateOrganizationalProfile({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            axios.put(`${API}/organizations/${data.id}`, data)
                .then(
                    s => {
                        if (s) {
                            resolve(s);
                        }
                    })
                .catch(error => reject(error.response));
        });
    },
    verifyEmail({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/auth/verify`;
            axios.post(url, data)
                .then(
                    s => {
                        if (s) {
                            resolve(s);
                        }
                    })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getProjectBillingTypes({
        commit
    }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/project_billing_types`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_PROJECT_BILLING_TYPES', data);
                    resolve();
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getOpportunityStages({
        commit
    }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/opportunity_stages`
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_OPPORTUNITY_STAGES', data);
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getProjectStatuses({
        commit
    }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/project_statuses?sort_by=order`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_PROJECT_STATUSES', data);
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getCurrencies({
        commit
    }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/currencies`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_CURRENCIES', data);
                    resolve();
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getTaskStatuses({
        commit
    }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/task_statuses?order_by=order`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_TASK_STATUSES', data);
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getCustomerSources({
        commit
    }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/customer_sources?order=order`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_CUSTOMER_SOURCES', data);
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getIndustries({
        commit
    }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/industries`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_INDUSTRIES', data);
                    resolve();
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getEmployeeTypes({
        commit
    }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/employee_types`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_EMPLOYEE_TYPES', data);
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getPriorities({commit}) {
        return new Promise((resolve, reject) => {
            let url = `${API}/states?include=cities`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {

                    commit('SET_STATES', data);
                    resolve();
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getStates({
        commit
    }, id = null) {
        return new Promise((resolve, reject) => {
            let url = `${API}/states?include=cities`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {

                    commit('SET_STATES', data);
                    resolve();
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getState({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/states/${id}?include=cities`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {

                    commit('SET_STATE', data);
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getCities({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/cities`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {

                    commit('SET_CITIES', data);
                    resolve();
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getCity({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/cities/${id}`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {

                    commit('SET_CITY', data);
                    resolve();
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    // /job-roles
    getJobRoles({commit}) {
        return new Promise((resolve, reject) => {
            let url = `${API}/job-roles`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {

                    commit('SET_JOB_ROLES', data);
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getRoles({
        commit
    }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/roles`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {

                    commit('SET_ROLES', data);
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    createRole({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/roles`;
            axios.post(url, data)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getInvoicePdf({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/invoice/${id}/preview`;
            axios.get(url).then(s => {
                    resolve(s);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        })
    },
    createUpdateAddress({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/addresses${data && data.id ? `/${data.id}` : ''}`;
            let method = data && data.id ? axios.put(url, data) : axios.post(url, data);
            method.then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data.id);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getAddress({
        commit
    }, address_id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/addresses/${address_id}}`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getBanks({
        commit
    }, country_id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/banks?country_id=${country_id}`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {

                    commit('SET_BANKS', data);
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getRecipientTypes({
        commit
    }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/recipient_types`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_RECIPIENT_TYPES', data);
                    resolve();
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getExpenseCategories({
        commit
    }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/expense_categories`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {

                    commit('SET_EXPENSE_CATEGORIES', data);
                    resolve(data.id);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getInvoiceStatuses({
        commit
    }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/invoice_statuses`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {

                    commit('SET_INVOICE_STATUSES', data);
                    resolve(data.id);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getPaymentSources({
        commit
    }, params = '') {
        return new Promise((resolve, reject) => {
            let url = `${API}/organizations/payment-sources?${params}`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {

                    commit('SET_PAYMENT_SOURCES', data);
                    resolve(data.id);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    uploadLogo({
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            let url = `${API}/organizations/${payload.id}`;
            axios.patch(url, payload)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getMilestoneStatuses({
        commit
    }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/project_milestone_statuses`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_MILESTONE_STATUSES', data);
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getOrganizationAddresses({
        commit
    }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/organization-addresses`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_ORGANIZATION_ADDRESSES', data);
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    createUpdateOrgAddress({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/organization-addresses${data && data.id ? `/${data.id}` : ''}`;
            let method = data && data.id ? axios.put(url, data) : axios.post(url, data);
            method.then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data.id);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getPayPeriods({commit}) {
        return new Promise((resolve, reject) => {
            let url = `${API}/pay-periods`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_PAY_PERIODS', data)
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getLicense({commit}, id = 1) {
        return new Promise((resolve, reject) => {
            let url = `${API}/licenses/${id}`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_LICENSE', data)
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    
}