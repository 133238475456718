<template>
  <div>
    <contact-modal
      :contact="contact"
      :customer="customer"
      v-if="customer.id && fetchedContacts"
      @update="update"
    ></contact-modal>
  </div>
</template>
<script>
import ContactModal from "@/components/Modals/Contacts";
import moment from "moment";
export default {
  title: `Edit | Contact | Customers`,
  data() {
    return {
      loaded: false,
      fetchedContacts: false,
      contact: {},
    };
  },

  components: { ContactModal },
  methods: {
    update(value) {
      this.contact = value;
    },
  },
  computed: {
    customer() {
      return this.$store.getters["customers/customer"];
    },
    roles() {
      return this.loaded ? this.$store.getters["global/roles"] : [];
    },
  },
  created() {
    Promise.all([
      this.$store
        .dispatch("customers/getContact", this.$route.params.cid)
        .then((s) => {
          this.contact = s;
          this.$store.dispatch("global/getRoles").then((p) => {
            let _position = p.find((s) => s.name == this.contact.position);
            Object.assign(this.contact, { position: _position });
            this.fetchedContacts = true;
          });
        }),
      this.$store.dispatch("customers/getSingle", this.$route.params.id),
    ]).finally(() => {
      this.loaded = true;
    });
  },
};
</script>
