<template>
  <section>
    <headers class="bg-ggray pb-5">
      <div slot="breadcrumbs">
        <breadcrumbs :items="breadCrumbs"></breadcrumbs>
      </div>
      <div slot="title">
        <h2>{{'Create New Invoice'}}</h2>
      </div>
      <div slot="action">
        <button
          type="button"
          @click="submit"
          :disabled="loading"
          class="btn btn-ds pl-4 pr-4"
        >Create</button>
        <b-dropdown id="dropdown-1" class="m-md-2 options-dropdown">
          <template class="btn btn-ds btn-outline" v-slot:button-content>
            <i class="zmdi zmdi-more" style="font-size:25px"></i>
            <!-- </button> -->
          </template>
          <b-dropdown-item>Duplicate</b-dropdown-item>
          <b-dropdown-item>Delete</b-dropdown-item>
          <b-dropdown-item>Export</b-dropdown-item>
        </b-dropdown>
      </div>
    </headers>

    <div class="container-fluid ml-3 pr-5 mt-4" :class="{blur:!loaded}">
      <div class="row">
        <div class="col-sm-12 col-lg-12">
          <form class="d-form" @submit.prevent="submit">
            <div class="form-accordion">
              <accordion
                @toggleCollapse="toggleCollapse(0)"
                :visible="accordions[0].visible"
                class="invoice-accordion"
                :name="'Business Contact Details, Invoice Subject & Logo'"
              >
                <div slot="content" class="pl-4 pr-4 pb-5">
                  <div class="row pt-3">
                    <div class="col-sm-12 col-lg-4">
                      <div
                        class="invoice-logo text-opacity pt-4 pb-4"
                        style="height:100%; border:2px dashed #F5F5F5; display:flex; align-items:center;justify-content:center"
                      >
                        <img :src="optional(organization, 'logo_url', '@/assets/img/main_baobab.png')" style="width:30%">
                      </div>
                    </div>
                    <div class="col-sm-6 col-lg-2">
                      <h6 class="text-bold text-opacity-pale">Invoice Subject:</h6>
                      <input
                        type="text"
                        name="subject"
                        v-model="body.subject"
                        class="form-control auth-input d-input"
                        placeholder="Enter title of this invoice"
                      >
                      <small
                        class="form-text text-danger"
                        v-show="errors.has('subject')"
                      >{{errors.first('subject')}}</small>
                    </div>
                     <div class="col-sm-6 col-lg-2">
                      <h6 class="text-bold text-opacity-pale">Invoice Reference:</h6>
                      <input
                        type="text"
                        name="reference"
                        v-model="body.reference"
                        class="form-control auth-input d-input"
                        placeholder="Enter reference of this invoice"
                      >
                      <small
                        class="form-text text-danger"
                        v-show="errors.has('reference')"
                      >{{errors.first('reference')}}</small>
                    </div>
                    <div class="col-sm-12 col-lg-4">
                      <!-- This would be gotten from user organization info page -->
                      <ul class="contact-info">
                        <li>
                          <h6 class="text-bold text-opacity-pale">{{organization.name}}</h6>
                        </li>
                        <li>
                          <h6 class="text-bold text-opacity-pale">{{organization.business_email}}</h6>
                        </li>
                        <!-- <li>
                          <h6 class="text-bold text-opacity-pale">{{user.phone_number}}</h6>
                        </li>
                        <li>
                          <h6 class="text-bold text-opacity-pale">{{user.country}}</h6>
                        </li> -->
                        <li>
                          <h6
                            class="text-bold text-opacity-pale"
                          >{{customer.billing_address && customer.billing_address.post_code}}</h6>
                        </li>
                        <li>
                          <h6
                            class="text-bold text-opacity-pale"
                          >{{customer.billing_address && customer.billing_address.country && customer.billing_address.country.name}}</h6>
                        </li>
                        <!-- <li>
                          <router-link
                            v-if="user"
                            :to="{name:'customers-edit',params:{id:customer.id}}"
                          >
                            <button type="button" class="btn btn-outline">
                              <span class="pr-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="#72809D"
                                  viewBox="0 0 30 30"
                                >
                                  <path
                                    d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"
                                  ></path>
                                </svg>
                              </span>Edit Contact details
                            </button>
                          </router-link>
                        </li> -->
                      </ul>
                    </div>
                  </div>
                </div>
              </accordion>
              <accordion
                @toggleCollapse="toggleCollapse(1)"
                :visible="accordions[1].visible"
                class="invoice-accordion mt-3"
                :name="'Customer & Invoice details'"
              >
                <div slot="content" class="pl-4">
                  <div class="row">
                    <div
                      class="col-sm-12 col-lg-6 border-right pt-4 pb-5"
                      style="border-color:#F5F5F5 !important"
                    >
                      <div class="row">
                        <!-- <div class="col-sm-12 pr-5">
                          <h6 class="text-bold text-opacity-pale">Invoice subject:</h6>
                          <input
                            type="text"
                            name="subject"
                            v-model="body.subject"
                            class="form-control auth-input d-input"
                            placeholder="Enter invoice subject"
                          >
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('reference')"
                          >{{errors.first('reference')}}</small>
                        </div>-->
                        <!-- <div class="col-sm-12 pr-5">
                          <h6 class="text-bold text-opacity-pale">Invoice REF:</h6>
                          <input
                            type="text"
                            name="subject"
                            v-model="body.reference"
                            class="form-control auth-input d-input"
                            placeholder="Invoice Ref"
                          >
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('reference')"
                          >{{errors.first('reference')}}</small>
                        </div>-->
                        <div class="col-sm-12 pr-5">
                          <h6 class="text-bold text-opacity-pale">Bill to: <b>*</b></h6>
                          <multiselect
                            track-by="id"
                            return="id"
                            label="name"
                            v-model="body.customer"
                            v-validate="'required'"
                            name="customer_id"
                            @input="setCurrency(body.customer)"
                            :options="customers"
                            :show-labels="false"
                            placeholder="Select customer"
                            data-vv-as="Customer"
                          >
                            <template
                              slot="singleLabel"
                              slot-scope="props"
                            >{{props.option.company_name}}</template>
                            <template slot="option" slot-scope="props">{{props.option.company_name}}</template>
                          </multiselect>
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('customer_id')"
                          >{{errors.first('customer_id')}}</small>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12 mt-4 pr-5 mr-5">
                          <h6 class="text-bold text-opacity-pale">Currency: <b>*</b></h6>
                          <multiselect
                            track-by="id"
                            return="id"
                            label="name"
                            v-model="body.currency_id"
                            v-validate="'required'"
                            :custom-label="currencySelectLabel"
                            name="currency_id"
                            :options="currencies"
                            :show-labels="false"
                            placeholder="Select Currency"
                            data-vv-as="Currency"
                          ></multiselect>
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('currency_id')"
                          >{{errors.first('currency_id')}}</small>
                        </div>
                        <div class="col-sm-12 mt-4 pr-5 mr-5">
                          <h6 class="text-bold text-opacity-pale">Due date: <b>*</b></h6>
                          <vc-date-picker
                            :masks="{ input: ['YYYY-MM-DD']}"
                            :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important;`,placeholder: `Invoice Due Date`}"
                            v-validate="'required'"
                            v-model="body.due_date"
                            name="due_date"
                            ref="dueAt"
                            data-vv-as="Invoice Due date"
                          />
                          <small class="dpicker-icon pr-5">
                            <i class="zmdi zmdi-calendar-alt"></i>
                          </small>
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('due_date')"
                          >{{errors.first('due_date')}}</small>
                        </div>
                         <div class="col-sm-12 mt-4 pr-5 mr-5" v-if="typeof projects !== 'undefined'">
                          <h6 class="text-bold text-opacity-pale">Project: </h6>
                          <multiselect
                            track-by="id"
                            return="id"
                            label="name"
                            v-model="body.project"
                            name="project_id"
                            :options="projects"
                            :show-labels="false"
                            placeholder="Select Project"
                            data-vv-as="Project"
                            @input="fetchMilestones(body.project)"
                          ></multiselect>
                         
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('project_id')"
                          >{{errors.first('project_id')}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-6 pt-4 pb-5">
                      <div class="row">
                        <div class="col-sm-12 pr-5">
                          <h6 class="text-bold text-opacity-pale">Contact Email:</h6>
                          <input
                            type="text"
                            name="subject"
                            v-model="customer.email"
                            class="form-control auth-input d-input"
                            placeholder="Customer Email"
                          >
                        </div>
                        <div class="col-sm-12 pt-4 pb-4 pr-5">
                          <h6 class="text-bold text-opacity-pale">Contact Phone Number:</h6>
                          <input
                            type="text"
                            v-model="customer.phone_number"
                            class="form-control auth-input d-input"
                            placeholder="Contact Phone Number"
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12 pr-5 date-range">
                          <h6 class="text-bold text-opacity-pale">Invoice date:</h6>
                          <vc-date-picker
                            :masks="{ input: ['YYYY-MM-DD']}"
                            :input-props="{class:`d-input no-margin-top auth-input pl-2`,style: `margin-top:0px !important;`,placeholder: `Start Time`}"
                            v-model="body.start_date"
                            name="start_date"
                            ref="startFrom"
                            data-vv-as="Invoice Start date"
                          />
                          <small class="dpicker-icon pr-5">
                            <i class="zmdi zmdi-calendar-alt"></i>
                          </small>
                          <small
                            class="form-text text-danger"
                            v-show="errors.has('start_date')"
                          >{{errors.first('start_date')}}</small>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </accordion>
              <accordion
                @toggleCollapse="toggleCollapse(2)"
                :visible="accordions[2].visible"
                class="invoice-accordion mt-3"
                :name="'Products & Services'"
              >
                <div slot="content" class="pl-4 pr-4">
                  <div class="row">
                    <div class="col-sm-12 pt-4">
                      <vuetable
                        ref="productServices"
                        :data="productsAndServices"
                        :api-mode="false"
                        class="no-border"
                        :fields="product_data_columns"
                        data-path="data"
                        pagination-path
                      >
                        <template
                          slot="billable"
                          slot-scope="props"
                        >{{props.rowData.timelog_id ? props.rowData.quantity : 'N/A'}}</template>
                        <template slot="unit_price" slot-scope="props">
                          {{body.currency_id && body.currency_id.symbol}} {{props.rowData.unit_price}}
                          </template>
                        <template
                          slot="discount_percent"
                          slot-scope="props"
                        >{{props.rowData.discount_percent ? props.rowData.discount_percent : '0'}}%</template>

                        <template
                          slot="milestone"
                          slot-scope="props"
                        >{{props.rowData.milestone_id ? props.rowData.milestone_name : 'N/A'}}</template>
                        

                        <template slot="discounted_amount" slot-scope="props">
                          {{body.currency_id && body.currency_id.symbol}}  {{ calculateDiscountedAmount(props.rowData) }}
                        </template>
                        <template
                          slot="amount"
                          slot-scope="props"
                        >{{body.currency_id && body.currency_id.symbol}} {{props.rowData.amount ? props.rowData.amount : '0'}}</template>
                      </vuetable>
                    </div>
                  </div>
                  <div class="row pb-2">
                     <div class="col-sm-2 mt-2">
                    <multiselect
                            track-by="id"
                            return="id"
                            label="name"
                            v-model="data.milestone"
                            name="milestone_id"
                            :options="milestones"
                            :show-labels="false"
                            placeholder="Select Milestone"
                            data-vv-as="Milestone"
                            
                          ></multiselect>
                          </div>
                    <div class="col-sm-2">
                      <input
                        type="text"
                        name="description"
                        v-model="data.description"
                        class="form-control auth-input d-input"
                        placeholder="Item description"
                      >
                    </div>
                    <div class="col-sm-2">
                         <div style="position:relative; width:100%">
                      <span id="discount_label">{{ body.currency_id && body.currency_id.symbol }}</span>
                      <input
                        type="number"
                        v-model="data.unit_price"
                        name="price"
                        class="form-control auth-input d-input pl-5"
                        placeholder="Unit price"
                      >
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <input
                        type="number"
                        name="quantity"
                        v-model="data.quantity"
                        class="form-control auth-input d-input"
                        placeholder="Quantity"
                      >
                    </div>
                    <div class="col-sm-2">
                      <div style="position:relative; width:100%">
                        <span id="discount_label">%</span>
                        <input
                          type="number"
                          name="discount"
                          id="discount"
                          v-model="data.discount_percent"
                          class="form-control auth-input d-input pl-5"
                          placeholder="Discount rate"
                        >
                      </div>
                    </div>
                    <div class="col-lg-2 col-sm-12 mt-2">
                      <button
                        type="button"
                        :disabled="loading"
                        @click="addInvoiceItem()"
                        class="btn btn-primary auth-btn pl-2 pr-2 mt-1"
                      >Add</button>
                    </div>
                  </div>
                  <hr style="border-color:#F7F7F7">
                  <div class="invoice-total pb-5 pl-3 pr-3">
                    <div class="row" style="background:#F7F7F7; border-radius:10px">
                      <!-- <div class="col-sm-12 col-lg-3 pt-1">
                        <h6 class="text-bold text-opacity-pale">TOTAL</h6>
                      </div>-->
                      <div class="col-sm-12 col-lg-6 text-right pt-3 pr-4 pb-4"></div>
                      <div
                        class="col-sm-12 col-lg-6 text-right pt-4 pb-4"
                        style="display:flex;flex-direction:row;justify-content:flex-end;"
                      >
                        <span>
                          <h6 class="text-small text-opacity">Discounted Total</h6>
                          <h4
                            class="text-bold text-opacity-pale"
                            style="color:green"
                          >{{body.currency_id && body.currency_id.symbol}}{{discountedItemsTotalAmount}}</h4>
                        </span>
                        <span class="pl-2 pr-2" style="display: flex;align-items: center;justify-content: flex-end;width: 60px;font-size: 35px;opacity: .3;">//</span>
                        <span class="pl-3">
                          <h6 class="text-small text-opacity">Original Total</h6>
                          <h4
                            class="text-bold text-opacity-pale"
                          >{{body.currency_id && body.currency_id.symbol}}{{invoiceItemsTotalAmount}}</h4>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </accordion>
              <accordion
                @toggleCollapse="toggleCollapse(5)"
                :visible="accordions[5].visible"
                class="invoice-accordion mt-3"
                :name="'Notes & Additional Details'"
              >
                <div slot="content" class>
                  <div class="row">
                    <div class="col-sm-12">
                      
                        <quill-editor
          ref="myQuillEditor"
          class="mt-1"
          v-model="body.payment_instructions"
           @ready="_html(body.payment_instructions)"
           placeholder="Enter invoice notes here"
        />
                      <small
                        class="form-text text-danger"
                        v-show="errors.has('payment_instructions')"
                      >{{errors.first('payment_instructions')}}</small>
                    </div>
                  </div>
                </div>
              </accordion>
            </div>
            <div class="mt-4 mb-5 text-right">
              <button
                :disabled="loading"
                type="button"
                @click="submit()"
                class="btn btn-ds pl-4 pr-4"
              >Create</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
#discount_label {
  position: absolute;
  z-index: 2;
  margin-top: 10px;
  margin-left: 10px;
}
.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  li {
    margin-bottom: 5px;
  }
}
.invoice-summary__footer {
  // position: absolute;
  width: 100%;
  background: #c4d7ea;
  left: 0;
  bottom: 0;
  // padding: 20px;
  padding: 5px;

  form {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    div:first-child {
      flex-basis: 80%;
    }
    div:nth-of-type(2) {
      flex-basis: 20%;
    }

    input {
      padding: 13px;
      font-size: 13px;
      width: 100%;
      border: none;
    }
    button {
      height: 100%;
      width: 100%;
      background: #0a5685;
      color: white;
      border: none;
      outline: none;
      font-size: 14px;
      padding-right: 25px;
      padding-left: 25px;

      &:hover {
        background: #2b8c2b;
      }
    }
  }
}
.invoice-summary {
  .invoice-summary__list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .item {
      padding-right: 20px;
    }
    button {
      height: 40px;
      width: 40px;
      border-radius: 40px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;

      &.blue {
        background: lightcyan;

        svg {
          fill: #295656;
        }
      }
      &.orange {
        background: #ffeac3;

        svg {
          fill: #58441e;
        }
      }
      &.red {
        background: #f5a6a6;

      }
      &.beige {
        background: #ffae8e;

        svg {
          fill: #4e2414;
        }
      }
    }
  }
  .invoice-summary__footer {
    position: absolute;
    width: 100%;
    background: #c4d7ea;
    left: 0;
    bottom: 0;
    // padding: 20px;
    padding: 5px;

    form {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      div:first-child {
        flex-basis: 80%;
      }
      div:nth-of-type(2) {
        flex-basis: 20%;
      }

      input {
        padding: 13px;
        font-size: 13px;
        width: 100%;
        border: none;
      }
      button {
        height: 100%;
        width: 100% !important;
        background: #0a5685;
        color: white;
        border: none;
        outline: none;
        font-size: 14px;
        padding-right: 25px;
        padding-left: 25px;

        &:hover {
          background: #2b8c2b;
        }
      }
    }
  }
  .collapsel .card-body {
    padding-bottom: unset !important;
  }
}
.vuetable-slot {
  .d-input {
    margin-top: -2px !important;
  }
}
</style>

<script>
import Breadcrumbs from "@/components/Breadcrumbs/Index";
import Headers from "@/components/Layouts/Headers";
import Accordion from "@/components/Collapse/Index";

import Literals from "@/helpers/dashboard/literals";
import { toUTC } from "@/helpers/date";
import { secondsToDhms } from "@/helpers/duration";
import handleError from "@/helpers/error";
import { toLocal } from "@/helpers/date";
import getOrganization from "@/helpers/getOrganization"; 

import {optional} from "@/helpers/random";

export default {
  title: "New Invoice | Invoices",
  components: { Headers, Breadcrumbs, Accordion },
  data() {
    return {
      loading: false,
      loaded: false,
      accordions: [
        { name: "basic", visible: true },
        { name: "invoice_details", visible: true },
        { name: "product", visible: false },
        { name: "timesheet", visible: false },
        { name: "expenses", visible: true },
        { name: "notes", visible: true },
        { name: "summary", visible: true }
      ],
      body: { customer: "", projects: [], start_date : new Date(Date.now()) },
      data: {
        quantity: 1
      },
      product_services: [],
      product_data_columns: [
        {
          title: "Items",
          name: "subject",
          callback: d => {
            if (d) {
              if (d.length > 25) {
                return d.substring(0, 25) + "...";
              }
              return d;
            } else {
              return "N/A";
            }
          }
        },
         {
          title: "Milestone",
          name: "__slot:milestone"
        },
        {
          title: "Unit Price",
          name: "__slot:unit_price"
        },
        {
          title: "Quantity",
          name: "quantity",
          callback: d => {
            if (d) {
              return d;
            }
            return "--";
          }
        },
        {
          title: "Discount",
          name: "__slot:discount_percent"
        },
        {
          title: "Discounted Amount",
          name: "__slot:discounted_amount"
        },
        {
          title: "Amount",
          name: "__slot:amount"
        }
      ],
      milestones: []
    };
  },
  methods: {
    toLocal,
    secondsToDhms,
    handleError, 
    optional,
    toggleCollapse(index) {
      this.accordions[index].visible = !this.accordions[index].visible;
    },
    currencySelectLabel({ code, name, symbol }) {
      return ` ${name} (${symbol} ${code}) `;
    },
    truncate(str, n, useWordBoundary) {
      if (!str || str.length <= n) {
        return str;
      }
      const subString = str.substr(0, n - 1); // the original check
      return (
        (useWordBoundary
          ? subString.substr(0, subString.lastIndexOf(" "))
          : subString) + "..."
      );
    },
    async saveInvoiceItems(invoice_id) {
      await this.product_services.forEach(s => {
        //save each item
        let data = s;
        data["invoice_id"] = invoice_id;
        delete data.milestone_name;
        this.$store.dispatch("invoices/createUpdateInvoiceItem", data);
      });
    },
    submit() {
      this.$validator.validateAll().then(valid => {
        this.loading = true;
        this.updateInvoice();
      });
    },
    updateInvoice() {
      let data = {};
      Object.assign(data, this.body);
      data.customer_id = data.customer ? data.customer.id : null;
      data.project_id = data.project ? data.project.id : null;
      data.organization_id = getOrganization.organization_id();
      data.currency_id = data.currency_id ? data.currency_id.id : null;
      data.start_date = this.body.start_date
        ? toUTC(this.body.start_date).format("YYYY-MM-DD")
        : toUTC(new Date()).format("YYYY-MM-DD");
      data.due_date = this.body.due_date
        ? toUTC(this.body.due_date).format("YYYY-MM-DD")
        : null;
      data.end_date = this.body.end_date
        ? toUTC(this.body.end_date).format("YYYY-MM-DD")
        : null;

      delete data.customer;
      delete data.creator;
      delete data.currency;
      delete data.project;
      delete data.projects;

      let msgPass = Literals.INVOICE_UPDATE_SUCCESS;
      this.$store
        .dispatch("invoices/createUpdate", data)
        .then(s => {
          this.saveInvoiceItems(s.id).then(d => {
            this.$toast.success(msgPass, {
              position: "top-right",
              duration: 3000
            });

            this.$router.push({name:'invoices-index'});
          });
        })
        .catch(error => {
          let errorMessage = Literals.INVOICE_UPDATE_ERROR;
          this.handleError(this, error, errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateOrCreateInvoiceItem(input) {
      let data = {};
      Object.assign(data, input);
      let msgPass = `Invoice Item ${
        data.id ? "Updated" : "Added"
      } successfully`;
      return this.$store
        .dispatch("invoices/createUpdateInvoiceItem", data)
        .then(s => {
          this.$toast.success(msgPass, {
            position: "top-right",
            duration: 3000
          });
          this.$store.dispatch("invoices/getInvoiceItems", this.invoice.id);
        })
        .catch(error => {
          let errorMessage = "Error occurred";
          handleError(this, error, errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addInvoiceItem() {
      this.data["item"] = this.data.description;
      this.data.quantity = this.data.quantity > 0 ? this.data.quantity : 1;

      this.product_services.push({
        item: this.data.description,
        subject: this.data.description,
        unit_price: this.data.unit_price,
        amount: parseFloat(this.data.unit_price) * parseFloat(this.data.quantity),
        quantity: this.data.quantity,
        discount_percent: this.data.discount_percent,
        milestone_id: this.data.milestone && this.data.milestone.id,
        milestone_name: this.data.milestone && this.data.milestone.name,
      });
      this.data = {};
    },
    fetchInvoice() {
      this.$store
        .dispatch("invoices/getSingle", this.$route.params.id)
        .then(() => {
          this.body = this.invoice;
          this.formatDate();
          this.$store.dispatch("invoices/getInvoiceItems", this.invoice.id);
        });
    },
    totalLogTime(billable = false) {
      let timeLogs = this.timeLogs.map(s => s.timelog.data);
      if (billable) {
        timeLogs.filter(s => !!s.is_billable);
      }
      let hours = timeLogs.reduce(
        (total, obj) => total + parseFloat(obj ? obj.hours : 0),
        0
      );

      return hours ? `${hours} hours` : "0 hours";
    },
    updateTimeLog(data) {
      this.updateOrCreateInvoiceItem(data);
    },
    updateExpense(data) {
      this.updateOrCreateInvoiceItem(data);
    },
    setCurrency(customer) {
      this.body.currency_id = this.currencies.find(s => s.id == customer.currency_id) || "";
      this.body.customer = customer;
    },
    calculateDiscountedAmount(rowData) {
      let result = parseInt(rowData.amount) - ((parseInt(rowData.discount_percent)/100) * parseInt(rowData.amount));

      return isNaN(result) ? '0.00' : result;
    },
    formatDate() {
      let today = new Date(Date.now());
      today = today.setMonth(today.getMonth());
      this.body.start_date = new Date(today); 
    },
   fetchMilestones(project) {
     let projectId = project && project.id;
     if(!projectId) return;

     this.$store.dispatch("projects/getMilestones", projectId).then((data) => {
       
       this.milestones = data
     });
   },
  },
  computed: {
    user() {
      return this.$auth.user();
    },
    breadCrumbs() {
      return [
        {
          text: "Home",
          href: "#"
        },
        {
          text: "All Invoice",
          active: true
        },
        {
          text: "New invoice",
          active: true
        }
      ];
    },
    currencies() {
      return this.$store.getters["global/currencies"];
    },
    invoice() {
      return this.$store.getters["invoices/invoice"];
    },
    timeLogs() {
      return this.invoiceItems.filter(s => s.timelog);
    },
    productsAndServices() {
      //this is a combination of both expenses and timelogs
      // this.product_services = [];
      return this.product_services;
    },
    invoiceItems() {
      return this.$store.getters["invoices/invoice_items"];
    },
    invoiceItemsTotalAmount() {

        let amount = parseFloat( this.product_services
          .map(s => parseInt(s.amount))
          .reduce((a, b) => a + b, 0)
        ).toFixed(2);

      return isNaN(amount) ? "0.00" : amount;
    },
    discountedItemsTotalAmount() {
        let number = 0;
      if (this.product_services.length > 0) {
        number = parseFloat(this.product_services
          .map(s => parseInt(s.amount) - (parseInt(s.amount) * (parseInt(s.discount_percent)/100)))
          .reduce((a, b) => a + b, 0)
        ).toFixed(2);
      }
      return isNaN(number) ? "0.00" : number;
    },
    expensesTotalAmount() {
      let number = parseFloat(
        this.expenses.reduce(
          (total, obj) => total + (obj ? parseFloat(obj.amount) : 0),
          0
        )
      ).toFixed(2);
      return isNaN(number) ? "0.00" : number;
    },
    timeLogsTotalAmount() {
      let number = parseFloat(
        this.timeLogs
          .map(s => s.timelog.data)
          .reduce((total, obj) => total + parseFloat(obj.billed_cost), 0)
      ).toFixed(2);
      return isNaN(number) ? "0.00" : number;
    },
    expenses() {
      return this.invoiceItems.filter(s => s.expense);
    },
    customer() {
      return this.body.customer || {};
    },
    customers() {
      return this.$store.getters["customers/customers"];
    },
    projects() {
      return this.$store.getters["projects/projects"];
    },
    organization() {
      let orgId = window.localStorage.getItem("selected_organization");
      if (this.$auth.user().organization) {
        return this.$auth.user().organization;
      }

      return this.$auth.user().organizations &&
        this.$auth.user().organizations.data
        ? this.$auth.user().organizations.data.find(s => s.id == orgId) || {}
        : {};
    }
  },
  created() {
    Promise.all([
    this.$store.dispatch(
        "customers/index",
        {include: "billing_address.country,billing_address.region,billing_address.city"}
        ),
    this.$store.dispatch("projects/index"),
    this.currencies.length || this.$store.dispatch("global/getCurrencies"),
        ]).finally(() => {
        
          this.loaded = true;
    });
  }
};
</script>
