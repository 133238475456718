import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Auth/Login.vue';
import Register from '../views/Auth/Register.vue';
import ForgotPassword from '../views/Auth/ForgotPassword.vue';
import ResetPassword from '../views/Auth/ResetPassword';
import Verify from '../views/Auth/Verify';
import { hasPending } from "@/helpers/axiosIntercept";

import Organization from '../views/Auth/Organization';
import Invite from '../views/Invite';

import Dashboard from '../views/Dashboard';
//Projects
import ProjectsIndex from '../views/Projects/Index';
import ProjectsNew from '../views/Projects/New';
import ProjectsShow from '../views/Projects/Show';
import ProjectsEdit from '../views/Projects/Edit';
import ProjectsGeneral from '../views/Projects/General/Index';
import ProjectsFinance from '../views/Projects/Finance/Index';
import ProjectsCollaborators from '../views/Projects/Collaborators/Index';
import ProjectsMilestones from '../views/Projects/Milestones/Index';
import ProjectTasksNew from '../views/Projects/Tasks/New';
import ProjectsTasks from '../views/Projects/Tasks/Index';
import ProjectsNotes from '../views/Projects/Notes/Index';
import ProjectsNotesNew from '../views/Projects/Notes/New';
import ProjectsMilestoneNew from '../views/Projects/Milestones/New';
import ProjectsMilestoneEdit from '../views/Projects/Milestones/Edit';
import ProjectsTaskEdit from '../views/Projects/Tasks/Edit';

import ProjectMilestoneShow from '../views/Projects/Milestones/Show';

//milestones
import MilestonesShow from '../views/Milestones/Show';
import MilestonesShowTasks from '../views/Milestones/Tasks';
import MilestonesShowAbout from '../views/Milestones/About';
import MilestonesEditForm from '../views/Milestones/Edit';
//Customers
import CustomersIndex from '../views/Customers/Index';
import CustomersNew from '../views/Customers/New';
import CustomersShow from '../views/Customers/Show';
import CustomerEdit from '../views/Customers/Edit';
import CustomersProjects from '../views/Customers/Projects/Index';
import CustomerHighlights from '../views/Customers/Hightlights/Index';
import CustomerProjectTasks from '../views/Customers/Projects/Tasks';
import CustomerProjectOpportunities from '../views/Customers/Projects/Opportunities';
import CustomerProjectInvoices from '../views/Customers/Projects/Invoices';
import CustomerProjectAttachments from '../views/Customers/Projects/Attachments';
import CustomerContacts from '../views/Customers/Contacts';
import CustomerContactsEdit from '../views/Customers/ContactEdit';
import CustomersFinance from '../views/Customers/Finance/Index';
//Tasks
import TasksIndex from '../views/Tasks/Index';
import TaskShow from '../views/Tasks/Show/Index';

import TasksFullShow from '../views/Tasks/Show/FullPage/Index';
import TasksFullShowInfo from '../views/Tasks/Show/FullPage/TaskInfo';
import TasksFullTimeSheets from '../views/Tasks/Show/FullPage/TimeSheets';
import TaskFullExpenses from '../views/Tasks/Show/FullPage/Expenses';
import TaskFullAttachments from '../views/Tasks/Show/FullPage/Attachments';
import FullTaskSubtasks from '../views/Tasks/Show/FullPage/SubTasks';

import TaskEdit from '../views/Tasks/Edit';
import TaskGeneral from '../views/Tasks/Show/General';
import TaskComments from '../views/Tasks/Show/Comments';
import TaskAttachments from '../views/Tasks/Show/Attachments';
import TaskSubTasks from '../views/Tasks/Show/SubTasks/Index';
import TaskSubTasksNew from '../views/Tasks/Show/SubTasks/New';

//preview pages
import TaskPreviewShow from '../views/Tasks/Show/Preview/Index';
import TaskPreviewGeneral from '../views/Tasks/Show/Preview/General';
import TaskPreviewComments from '../views/Tasks/Show/Preview/Comments';
import TaskPreviewSubTasks from '../views/Tasks/Show/Preview/SubTasks/Index';
import TaskPreviewSubTasksNew from '../views/Tasks/Show/Preview/SubTasks/New';
import TaskPreviewAttachments from '../views/Tasks/Show/Preview/Attachments';
import TasksNew from '../views/Tasks/New';

//Opportunities
import OpportunitiesIndex from "../views/Opportunities/Index";
import OpportunitiesNew from "../views/Opportunities/New";
import OpportunitiesEdit from "../views/Opportunities/Edit";
import OpportunitiesShow from "../views/Opportunities/Show";
import OpportunitiesTasks from "../views/Opportunities/Tasks/Index";
import OpportunitiesAttachments from "../views/Opportunities/Attachments/Index";
import OpportunitiesComments from "../views/Opportunities/Comments/Index";
import OpportunitiesProject from "../views/Opportunities/Project";
//Users
import UsersIndex from "../views/Users/Index";
import UsersNew from "../views/Users/New";
import UsersEdit from "../views/Users/Edit";
import UsersShow from "../views/Users/Show";
import UserBasicInfo from "../views/Users/Info/User";
import UserBankInfo from "../views/Users/Info/Bank/Index";
import UserBankInfoAdd from "../views/Users/Info/Bank/New";
import UserBankInfoEdit from "../views/Users/Info/Bank/Edit";
import UserTime from "../views/Users/Info/Time";
import UserSchedule from "../views/Users/Info/Schedule";
//== sub links
import UserProjects from "../views/Users/Info/Work/Projects";
import UserTasks from "../views/Users/Info/Work/Tasks";

//Invoices
import InvoicesIndex from "../views/Invoice/Index";
import InvoicesShow from "../views/Invoice/Show";
import InvoicesSend from "../views/Invoice/Send";
import InvoicesNew from "../views/Invoice/New";

//Payments
import PaymentIndex from "../views/Payments/Index";
import PaymentNew from "../views/Payments/New";
import PaymentShow from "../views/Payments/Show";
import PaymentProcess from "../views/Payments/Process";
import PaymentEmployees from "../views/Payments/Employees";
import PaymentReview from "../views/Payments/Review";
import PaymentConfirm from "../views/Payments/Confirm";
//Error Pages
//account info
import AccountProfile from "../views/Users/Profile";
import EditAccountProfile from "../views/Users/EditProfile";
import Error404 from "../views/Errors/404.vue";

//Reporting
import ReportingIndex from "../views/Reporting/Index";
import ReportingNew from "../views/Reporting/New";
import ReportingShow from "../views/Reporting/Show";

//
import OrganizationIndex from "../views/Users/Organization/Index";
import OrganizationHome from "../views/Users/Organization/Home";
import OrganizationApiKeys from "../views/Users/Organization/ApiKeys";
import SettingsResources from "../views/Users/Organization/SettingsResources";
import SettingsIndex from "../views/Users/Organization/Settings";

import EditOrganization from "../views/Users/Organization/EditOrg";
import AddPaymentMethod from "../views/Users/Organization/PaymentMethod";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css';
import { Settings } from 'luxon';

//subscriptions
import SubscriptionIndex from "../views/Subscriptions/Index";
import SubscriptionPlans from "../views/Subscriptions/Plans";
import SubscriptionActive from "../views/Subscriptions/Active";
import SubscriptionCards from "../views/Subscriptions/Cards";
import SubscriptionHistory from "../views/Subscriptions/History";

Vue.use(VueRouter)

const routes = [{
    path: '/login',
    name: 'Login',
    component: Login,

},
{
    path: '/register',
    name: 'Register',
    component: Register,

},
{
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword
},
{
    path: '/verify/:id/:token',
    name: 'verify',
    component: Verify,
},
{
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword
},
{
    path: '/organization',
    name: 'organization',
    component: Organization,
    meta: {
        auth: true,
        hasOrg: true
    }
},
{
    path: '/invites/:id',
    name: 'invite',
    component: Invite,
    meta: {
        auth: true,
    }
},
{
    path: '/',
    name: 'Home',
    redirect: '/dashboard',
    component: Home,
    meta: {
        auth: true,
    },
    children: [{
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard
    },
    {
        path: '/settings',
        name: 'settings',
        component: OrganizationIndex,

        children: [{
            path: '/',
            name: 'settings',
            component: OrganizationHome,
            meta: {
                isOrgRoute: true,
            },
            children: [{
                path: '/',
                name: 'settings',
                component: SettingsIndex,
                meta: {
                    isOrgRoute: true
                },
                children: [{
                    path: 'add-payment',
                    name: 'settings-payment',
                    component: AddPaymentMethod,
                    meta: {
                        isOrgRoute: true
                    }
                },]
            },
            {
                path: 'resources',
                name: 'resources',
                component: SettingsResources,
                meta: {
                    isOrgRoute: true
                }
            }
            ]
        },
        {
            path: 'organization/edit',
            name: 'organization-edit',
            component: EditOrganization,
            meta: {
                isOrgRoute: true,
            }
        }, {
            path: 'api-keys/edit',
            name: 'settings-api-keys',
            component: OrganizationApiKeys,
            meta: {
                isOrgRoute: true,
            }
        },
        {
            path: 'profile',
            name: 'profile',
            component: AccountProfile,
            meta: {
                isProfileRoute: true,
            }
        },
        {
            path: 'profile/edit',
            name: 'profile-edit',
            component: EditAccountProfile,
            meta: {
                isProfileRoute: true,
            }
        },
        ]
    },

    {
        path: 'subscriptions',
        name: 'subscriptions',
        component: SubscriptionIndex,
        children: [
            {
                path: '/',
                component: SubscriptionActive,
                name: 'subcriptions-active'

            },
            {
                path: 'cards',
                component: SubscriptionCards,
                name: 'subscriptions-cards'
            },
            {
                path: 'history',
                component: SubscriptionHistory,
                name: 'subscriptions-history'
            },
            // {
            //     path: 'upgrade',
            //     component: SubscriptionHistory,
            //     name: 'subscriptions-upgrade'
            // }
        ]
    },
    {
        path: '/invoices',
        name: 'invoices',
        component: InvoicesIndex,
    },
    {
        path: '/milestones/:mid',
        name: 'milestones',
        component: MilestonesShow,
        children: [{
            name: 'milestones',
            path: '/',
            component: MilestonesShowTasks,
            children: [{
                path: 'preview/:tid',
                component: TaskPreviewShow,
                name: 'project-milestone-tasks-preview',
                children: [{
                    name: 'project-milestone-tasks-preview-show',
                    component: TaskPreviewGeneral,
                    path: '/',
                },
                {
                    name: 'project-milestone-tasks-preview-comments',
                    component: TaskPreviewComments,
                    path: 'comments'
                },
                {
                    component: TaskPreviewSubTasks,
                    name: 'project-milestone-tasks-preview-subtasks',
                    path: 'subtasks',
                    children: [{
                        name: 'project-milestone-tasks-preview-subtasks-new',
                        path: 'new',
                        component: TaskPreviewSubTasksNew
                    }]
                },
                {
                    name: 'project-milestone-tasks-preview-attachments',
                    component: TaskPreviewAttachments,
                    path: 'attachments'
                }
                ]
            },]
        },
        {
            name: 'milestones-about',
            path: 'about',
            component: MilestonesShowAbout
        },
        {
            name: 'edit-milestone',
            path: 'edit',
            component: MilestonesEditForm
        },
        ]
    },
    {
        path: '/invoices/new',
        name: 'invoices-create',
        component: InvoicesNew,
    },
    //
    {
        path: '/reporting',
        name: 'reporting-index',
        component: ReportingIndex,
        children: [{
            path: 'new',
            name: 'reporting-create',
            component: ReportingNew,
        }]
    },
    {
        path: '/reporting/:id',
        name: 'reporting-show',
        component: ReportingShow,
    },
    //
    {
        path: '/opportunities',
        name: 'opportunities-index',
        component: OpportunitiesIndex,

        children: [{
            path: 'new',
            name: 'opportunities-create',
            component: OpportunitiesNew
        }]
    },
    {
        path: '/opportunities/:id',
        // name: 'opportunities-show',
        component: OpportunitiesShow,

        children: [{
            name: 'opportunities-edit',
            path: 'edit',
            component: OpportunitiesEdit
        },
        {
            name: 'opportunities-show',
            path: '/',
            component: OpportunitiesTasks
        },
        {
            name: 'opportunities-attachments',
            path: 'attachments',
            component: OpportunitiesAttachments
        },
        {
            name: 'opportunities-comments',
            path: 'comments',
            component: OpportunitiesComments
        },
        {
            name: 'opportunities-project',
            path: 'project',
            component: OpportunitiesProject
        }
        ]
    },
    {
        name: 'users-index',
        path: '/users',
        component: UsersIndex,

        children: [{
            name: 'users-create',
            path: 'new',
            component: UsersNew
        },]
    },
    {
        // name: 'users-show',
        path: '/users/:id',
        component: UsersShow,

        children: [{
            name: 'users-edit',
            path: 'edit',
            component: UsersEdit,
        },
        {
            // name: 'users-show',
            path: '/',
            component: UserBasicInfo,

            children: [{
                name: 'users-show',
                path: '/',
                component: UserProjects
            },
            {
                name: 'users-show-tasks',
                path: 'users-task',
                component: UserTasks,
                children: [
                    {
                        name: 'user-show-task',
                        path: 'preview/:tid',
                        component: TaskPreviewShow,
                        children: [
                            {
                                name: 'user-tasks-preview-show',
                                component: TaskPreviewGeneral,
                                path: '/'
                            },
                            {
                                name: 'user-tasks-preview-comments',
                                component: TaskPreviewComments,
                                path: 'comments'
                            },
                            {
                                name: 'user-tasks-preview-subtasks',
                                component: TaskPreviewSubTasks,
                                path: 'subtasks',
                                children: [
                                    {
                                        name: 'user-tasks-preview-subtasks-new',
                                        component: TaskPreviewSubTasksNew,
                                        path: 'new'
                                    }
                                ]
                            },
                            {
                                name: 'user-tasks-preview-attachments',
                                component: TaskPreviewAttachments,
                                path: 'attachments'
                            }
                        ]
                    }

                ],
            },
            {
                path: 'upgrade',
                component: SubscriptionPlans,
                name: 'subscriptions-upgrade'
            }
            ]
        },
        {
            path: '/invoices',
            name: 'invoices',
            component: InvoicesIndex,
        },
        {
            path: '/milestones/:mid',
            name: 'milestones',
            component: MilestonesShow,
            children: [
                {
                    name: 'milestones',
                    path: '/',
                    component: MilestonesShowTasks,
                    children: [
                        {
                            name: 'user-show-task',
                            path: 'preview/:tid',
                            component: TaskPreviewShow,
                            children: [
                                {
                                    name: 'user-tasks-preview-show',
                                    component: TaskPreviewGeneral,
                                    path: '/'
                                },
                                {
                                    name: 'user-tasks-preview-comments',
                                    component: TaskPreviewComments,
                                    path: 'comments'
                                },
                                {
                                    name: 'user-tasks-preview-subtasks',
                                    component: TaskPreviewSubTasks,
                                    path: 'subtasks',
                                    children: [
                                        {
                                            name: 'user-tasks-preview-subtasks-new',
                                            component: TaskPreviewSubTasksNew,
                                            path: 'new'
                                        }
                                    ]
                                },
                                {
                                    name: 'user-tasks-preview-attachments',
                                    component: TaskPreviewAttachments,
                                    path: 'attachments'
                                }
                            ]
                        }
                    ],
                }
            ]
        },
        {
            path: 'bank-info',
            component: UserBankInfo,
            name: 'users-bank',
            children: [{
                path: 'new',
                component: UserBankInfoAdd,
                name: 'employee-add-bank'
            },
            {
                path: ':bid/edit',
                component: UserBankInfoEdit,
                name: 'employee-edit-bank'
            },
            ]
        },
        {
            path: 'time-logs',
            component: UserTime,
            name: 'users-time'
        },
        {
            path: 'comp-schedule',
            component: UserSchedule,
            name: 'users-schedule'
        },
        ]
    },
    {
        path: '/payments',
        name: 'payments',
        component: PaymentIndex,

        children: [{
            name: 'payments-create',
            path: 'new',
            component: PaymentNew
        }]
    },
    {
        name: 'payments-show',
        path: '/payments/:id/status',
        component: PaymentShow
    },
    {
        path: '/payments/:id',
        name: 'payments-process',
        component: PaymentProcess,
        children: [{
            path: 'process',
            component: PaymentEmployees,
            meta: {
                stage: 1
            }
        }, {
            path: 'review',
            component: PaymentReview,
            meta: {
                stage: 2
            },
        }, {
            path: 'confirm',
            component: PaymentConfirm,
            meta: {
                stage: 3
            },
        }]
    },
    {
        path: '/projects',
        name: 'projects-index',
        component: ProjectsIndex,
        meta: {
            hasOrg: true,
        },

        children: [{
            name: 'projects-create',
            path: 'new',
            component: ProjectsNew
        }]
    },
    {
        path: '/projects/:id',
        name: 'projects-show',
        component: ProjectsShow,
        meta: { hasOrg: true},
        children: [
            {
                name: 'projects-show-general',
                path: '/',
                component: ProjectsGeneral,
                children: [
                    {

                        name: 'projects-milestones-new',
                        path: 'milestones/new',
                        component: ProjectsMilestoneNew
                    },
                    {
                        path: 'milestones/:mid',
                        component: ProjectMilestoneShow,
                        name: 'project-milestones-show'
                    },
                    {

                        name: 'projects-milestones-edit',
                        path: 'milestones/:mid/edit',
                        component: ProjectsMilestoneEdit
                    },
                    {
                        name: 'projects-tasks-new',
                        path: 'tasks/new',
                        component: ProjectTasksNew,
                    },
                    {
                        name: 'projects-tasks-edit',
                        path: 'tasks/:mid/edit',
                        component: ProjectsTaskEdit
                    },
                    {
                        name: 'projects-edit',
                        path: 'edit',
                        component: ProjectsEdit
                    },
                ]
            },
            {
                name: 'projects-show-finance',
                path: 'finance',
                component: ProjectsFinance,
            },
            {
                name: 'projects-show-collaborators',
                path: 'collaborators',
                component: ProjectsCollaborators,

            },
            {
                name: 'projects-show-milestones',
                path: 'milestones',
                component: ProjectsMilestones,
                children: [{
                    path: 'preview/:tid',
                    component: TaskPreviewShow,
                    name: 'milestone-tasks-preview',
                    children: [{
                        name: 'milestone-tasks-preview-show',
                        component: TaskPreviewGeneral,
                        path: '/'
                    },
                    {
                        name: 'milestone-tasks-preview-comments',
                        component: TaskPreviewComments,
                        path: 'comments'
                    },
                    {
                        component: TaskPreviewSubTasks,
                        name: 'milestone-tasks-preview-subtasks',
                        path: 'subtasks',
                        children: [{
                            name: 'milestone-tasks-preview-subtasks-new',
                            path: 'new',
                            component: TaskPreviewSubTasksNew
                        }]
                    },
                    {
                        name: 'milestone-tasks-preview-attachments',
                        component: TaskPreviewAttachments,
                        path: 'attachments'
                    }
                    ]
                },]
            },
            {
                name: 'projects-show-tasks',
                path: 'tasks',
                component: ProjectsTasks,
                children: [
                    {
                        name: 'projects-show-task',
                        path: 'preview/:tid',
                        component: TaskShow,
                        children: [
                            {
                                name: 'project-tasks-show-general',
                                component: TaskGeneral,
                                path: '/'
                            },
                            {
                                name: 'project-tasks-show-comments',
                                component: TaskComments,
                                path: 'comments'
                            },
                            {
                                name: 'project-tasks-show-subtasks',
                                component: TaskSubTasks,
                                path: 'subtasks',
                                children: [
                                    {
                                        name: 'project-tasks-show-subtasks-new',
                                        component: TaskSubTasksNew,
                                        path: 'new'
                                    },
                                ]
                            },
                            {
                                name: 'project-tasks-show-attachments',
                                component: TaskAttachments,
                                path: 'attachments'
                            }
                        ]
                    },
                ]
            },
            {
                name: 'projects-show-notes',
                path: 'notes',
                component: ProjectsNotes,
                children: [
                    {
                        name: 'projects-show-notes-create',
                        path: 'new',
                        component: ProjectsNotesNew
                    }
                ]
            },
        ]
    },

    {
        path: '/customers',
        name: 'customers-index',
        component: CustomersIndex,
        meta: {
            hasOrg: true,
        },
        children: [{
            name: 'customers-create',
            path: 'new',
            component: CustomersNew
        }]
    },
    {
        path: '/customers/:id',
        component: CustomersShow,
        meta: {
            hasOrg: true,
        },
        children: [{
            path: '/',
            component: CustomerHighlights,
            children: [{
                name: 'customers-project-create',
                path: 'project/new',
                component: ProjectsNew,
            },
            {
                name: 'customers-project-task-create',
                path: 'task/new',
                component: TasksNew,
            },
            {
                name: 'customers-opportunities-create',
                path: 'opportunity/new',
                component: OpportunitiesNew,
            }, {
                name: 'customers-show',
                path: '/',
                component: CustomersProjects
            }, {
                name: 'customers-contacts-edit',
                path: 'contacts/:cid/edit',
                component: CustomerContactsEdit,
            },
            {
                name: 'customers-contacts-new',
                path: 'contacts/new',
                component: CustomerContacts,
            },
            {
                name: 'customers-edit',
                path: 'edit',
                component: CustomerEdit,
            },
            {
                path: '/projects/:id',
                name: 'projects-show',
                component: ProjectsShow,
                meta: {
                    hasOrg: true,
                },
                children: [{
                        name: 'projects-show-general',
                        path: '/',
                        component: ProjectsGeneral,

                        children: [{
                                name: 'projects-show-task',
                                path: 'task/:tid',
                                component: TaskShow,
                                children: [{
                                        name: 'project-tasks-show-general',
                                        component: TaskGeneral,
                                        path: '/'
                                    },
                                    {
                                        name: 'project-tasks-show-comments',
                                        component: TaskComments,
                                        path: 'comments'
                                    },
                                    {
                                        name: 'project-tasks-show-subtasks',
                                        component: TaskSubTasks,
                                        path: 'subtasks',
                                        children: [{
                                            name: 'project-tasks-show-subtasks-new',
                                            component: TaskSubTasksNew,
                                            path: 'new'
                                        }]
                                    },
                                    {
                                        name: 'project-tasks-show-attachments',
                                        component: TaskAttachments,
                                        path: 'attachments'
                                    }
                                ]
                            },
                            {

                                name: 'projects-milestones-new',
                                path: 'milestones/new',
                                component: ProjectsMilestoneNew
                            },
                            {
                                path: 'milestones/:mid',
                                component: ProjectMilestoneShow,
                                name: 'project-milestones-show'
                            },
                            {

                                name: 'projects-milestones-edit',
                                path: 'milestones/:mid/edit',
                                component: ProjectsMilestoneEdit
                            },
                            {
                                name: 'projects-tasks-new',
                                path: 'tasks/new',
                                component: ProjectTasksNew,
                            },
                            {
                                name: 'projects-tasks-edit',
                                path: 'tasks/:mid/edit',
                                component: ProjectsTaskEdit
                            },
                            {
                                name: 'projects-edit',
                                path: 'edit',
                                component: ProjectsEdit
                            }
                        ]
                    },
                    {
                        name: 'projects-show-finance',
                        path: 'finance',
                        component: ProjectsFinance,
                    },
                    {
                        name: 'projects-show-collaborators',
                        path: 'collaborators',
                        component: ProjectsCollaborators,

                    },
                    {
                        name: 'projects-show-milestones',
                        path: 'milestones',
                        component: ProjectsMilestones,
                        children: [{
                            path: 'preview/:tid',
                            component: TaskPreviewShow,
                            name: 'milestone-tasks-preview',
                            children: [{
                                    name: 'milestone-tasks-preview-show',
                                    component: TaskPreviewGeneral,
                                    path: '/'
                                },
                                {
                                    name: 'milestone-tasks-preview-comments',
                                    component: TaskPreviewComments,
                                    path: 'comments'
                                },
                                {
                                    component: TaskPreviewSubTasks,
                                    name: 'milestone-tasks-preview-subtasks',
                                    path: 'subtasks',
                                    children: [{
                                        name: 'milestone-tasks-preview-subtasks-new',
                                        path: 'new',
                                        component: TaskPreviewSubTasksNew
                                    }]
                                },
                                {
                                    name: 'milestone-tasks-preview-attachments',
                                    component: TaskPreviewAttachments,
                                    path: 'attachments'
                                }
                            ]
                        }, ]
                    },

                ]
            },
            {
                name: 'customers-show-tasks',
                path: 'tasks',
                component: CustomerProjectTasks,
            },
            {
                name: 'customers-show-opportunities',
                path: 'opportunities',
                component: CustomerProjectOpportunities,
            },
            {
                name: 'customers-show-invoices',
                path: 'invoices',
                component: CustomerProjectInvoices,
            },
            {
                name: 'customers-show-attachments',
                path: 'attachments',
                component: CustomerProjectAttachments,
            },
            ]
        },
        {
            name: 'customers-show-finance',
            path: 'finance',
            component: CustomersFinance,
        },
        ]
    },
    {
        name: 'tasks-index',
        path: '/tasks',
        component: TasksIndex,
        children: [{
            path: 'new',
            name: 'tasks-create',
            component: TasksNew
        },
        {
            path: 'preview/:tid',
            component: TaskPreviewShow,
            name: 'tasks-preview',
            children: [{
                name: 'tasks-preview-show',
                component: TaskPreviewGeneral,
                path: '/'
            },
            {
                name: 'tasks-preview-comments',
                component: TaskPreviewComments,
                path: 'comments'
            },
            {
                component: TaskPreviewSubTasks,
                name: 'tasks-preview-subtasks',
                path: 'subtasks',
                children: [{
                    name: 'tasks-preview-subtasks-new',
                    path: 'new',
                    component: TaskPreviewSubTasksNew
                }]
            },
            {
                name: 'tasks-preview-attachments',
                component: TaskPreviewAttachments,
                path: 'attachments'
            }
            ]
        },
        {
            name: 'tasks-edit',
            component: TaskEdit,
            path: ':id/edit'
        },
        {
            path: ':tid',
            component: TaskShow,

            children: [{
                name: 'tasks-show',
                component: TaskGeneral,
                path: '/'
            },
            {
                name: 'tasks-comments',
                component: TaskComments,
                path: 'comments'
            },
            {
                name: 'tasks-attachments',
                component: TaskAttachments,
                path: 'attachments'
            }
            ]
        },
        ]
    },
    {
        path: '/tasks/:id/f',
        component: TasksFullShow,
        // name: 'task-show-full',
        children: [{
            name: 'task-show-full',
            component: TasksFullShowInfo,
            path: '/',
            children: [{
                name: 'task-show-full',
                component: TasksFullShow,
                path: '/'
            },
            {
                name: 'tasks-show-full-attachments',
                component: TaskFullAttachments,
                path: 'attachments',
            },
            ]
        },
        {
            name: 'task-show-timesheets',
            component: TasksFullTimeSheets,
            path: 'timesheets'
        },
        {
            name: 'task-show-expenses',
            component: TaskFullExpenses,
            path: 'expenses'
        },
        {
            name: 'task-show-subtasks',
            component: FullTaskSubtasks,
            path: 'subtasks',
        },
        ]
    },
    {
        path: '/invoices',
        name: 'invoices-index',
        component: InvoicesIndex,
        meta: {
            auth: true,
            hasOrg: true
        }
    },
    {
        path: '/invoices/:id',
        name: 'invoices-show',
        component: InvoicesShow,
        meta: {
            auth: true,
            hasOrg: true
        }
    },
    {
        path: '/invoices/:id/send',
        name: 'invoices-send',
        component: InvoicesSend
    }
    ]
},
{
    path: '*',
    name: 'error404',
    component: Error404
},
];

const router = new VueRouter({
    mode: 'history',
    baseUrl: process.env.BASE_URL,
    linkActiveClass: 'is-active',
    scrollBehavior: (t) => {
        return {
            y: 0
        }
    },
    routes
})
const exempted_routes = ["Login", "Register", "organization", "verify", "reset-password", "forgot-password"];

router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start()
    }
    next();

});

router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    var waitForRequestToComplete = setInterval(function () {
        let awaiting = hasPending();
        if (!awaiting) {
            clearInterval(waitForRequestToComplete);
            NProgress.done();
        }
    }, 1000);

})

export default router