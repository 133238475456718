export default {
    SET_PROJECTS(state, array) {
        state.projects = array;
    },
    SET_PROJECT(state, array) {
        state.project = array;
    },
    SET_MILESTONES(state, array) {
        state.milestones = array;
    },
    SET_MILESTONE(state, array) {
        state.milestone = array;
    },
    SET_TASKS(state, array) {
        state.tasks = array;
    },
    SET_COLLABORATORS(state, array) {
        state.collaborators = array;
    },
    SET_EXPENSES(state, array) {
        state.expenses = array;
    },
    SET_TIME_LOGS(state, array) {
        state.time_logs = array;
    },
    SET_INVITE(state, array) {
        state.invite = array;
    },
    SET_OPPORTUNITY(state, array) {
        state.opportunity = array;
    },
    SET_METRICS(state, array) {
        state.metrics = array;
    },
    SET_PROJECTS_COLLABORATING(state, array) {
        state.projects_collaborating = array;
    }
}

