var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ blur: !_vm.loaded }},[(_vm.showSubTask)?_c('subTaskModal',{attrs:{"showSubTaskModal":_vm.showSubTask},on:{"close":_vm.cancelNewSubtask}}):_vm._e(),_c('div',{staticClass:"p-4 pt-4 pb-5"},[_c('div',{staticClass:"d-flex justify-content-end mb-4"},[_c('button',{staticClass:"btn-action pr-3 pl-2 mr-2",on:{"click":_vm.setAddNewSubtask}},[_c('span',{staticClass:"text-bold h7 text-opacity-pale"},[_c('span',{staticClass:"pl-2 pr-1",attrs:{"data-v-7301eedf":""}},[_c('svg',{attrs:{"data-v-7301eedf":"","xmlns":"http://www.w3.org/2000/svg","width":"14","height":"14","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"data-v-7301eedf":"","fill":"#3f92cc","d":"M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 4c-.552 0-1 .448-1 1v2H5c-.552 0-1 .448-1 1s.448 1 1 1h2v2c0 .552.448 1 1 1s1-.448 1-1V9h2c.552 0 1-.448 1-1s-.448-1-1-1H9V5c0-.552-.448-1-1-1z"}})])]),_vm._v(" Add New SubTask ")])])]),(_vm.subtasks.length > 0)?_c('div',{staticClass:"kanban-board",attrs:{"id":"subtasks"}},_vm._l((_vm.subtasks),function(sub,index){return _c('div',{key:index,staticClass:"kanban-board__status w-100",attrs:{"id":sub.status && sub.status.name.replace(' ', '').replace('/', '')}},[_c('router-link',{attrs:{"to":{
            name: 'tasks-preview-show',
            params: { tid: sub.id },
            query: { subtask: true, from: _vm.$route.params.tid },
          }}},[_c('div',{staticClass:"kanban-board__tasks p-3"},[_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('div',{staticClass:"mr-3"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"kanban-board__tasks-profile",attrs:{"title":_vm.optional(sub, 'employee.user', null)
                      ? sub.employee.user.first_name +
                        ' ' +
                        sub.employee.user.last_name
                      : '-'}},[_vm._v(" "+_vm._s(_vm.optional(sub, "employee.user", null) ? sub.employee.user.first_name.charAt(0) + sub.employee.user.last_name.charAt(0) : "-")+" ")])]),_c('div',{staticClass:"mr-3"},[_c('h6',{staticClass:"text-opacity-pale mb-0"},[_vm._v(_vm._s(sub.name))])]),_c('div',{staticClass:"mr-3"},[_c('span',{staticClass:"badge"},[_vm._v(_vm._s(sub.status && sub.status.name))])])])])])],1)}),0):_c('h6',{staticClass:"h7 text-opacity text-opacity-pale text-center"},[_vm._v(" No subtask as being created for this task yet ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }