<template>
  <div class="container-fluid pb-5" :class="{ blur: !loaded }">
    <div class="row pl-3 pr-4 pb-4 pt-4 ignite-wrapper">
      <div class="col-sm-12 col-lg-3">
        <ul class="invoice-customer_info">
          <li>
            <svg
              width="50px"
              height="50px"
              viewBox="0 0 45 45"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Work-Page"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="Invoice-6"
                  transform="translate(-32.000000, -304.000000)"
                >
                  <g
                    id="Invoice---Customer"
                    transform="translate(32.000000, 304.000000)"
                  >
                    <circle
                      id="Oval"
                      fill="#D5EFFF"
                      cx="22"
                      cy="22"
                      r="22"
                    ></circle>
                    <g
                      transform="translate(10.000000, 10.000000)"
                      stroke="#0A5685"
                      stroke-linecap="round"
                      stroke-width="1.5"
                    >
                      <path
                        d="M7.476,7.084 C9.11834331,8.78804894 11.3833412,9.75051057 13.75,9.75000035 C14.9363094,9.75033697 16.1102269,9.50874816 17.2,9.04"
                        id="Path"
                      ></path>
                      <circle id="Oval" cx="12" cy="9.75" r="5.25"></circle>
                      <path
                        d="M18.913,20.876 C17.0835478,19.035059 14.5953726,17.9998579 12,17.9998579 C9.40462739,17.9998579 6.91645218,19.035059 5.087,20.876"
                        id="Path"
                      ></path>
                      <circle id="Oval" cx="12" cy="12" r="11.25"></circle>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </li>
          <li>
            <h6 class="text-opacity">Employees</h6>
            <h5 class="text-bold">{{ employees.length }} employees total</h5>
          </li>
        </ul>
      </div>
      <div class="col-sm-12 col-lg-3">
        <ul class="invoice-customer_info">
          <li>
            <svg
              width="50px"
              height="50px"
              viewBox="0 0 45 45"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Work-Page"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="Invoice-6"
                  transform="translate(-389.000000, -304.000000)"
                >
                  <g
                    id="Invoice---Status"
                    transform="translate(389.000000, 304.000000)"
                  >
                    <circle
                      id="Oval"
                      fill="#D5EFFF"
                      cx="22"
                      cy="22"
                      r="22"
                    ></circle>
                    <g
                      id="Invoice---status"
                      transform="translate(16.000000, 10.000000)"
                      stroke="#0A5685"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    >
                      <path
                        d="M11.848,11.306 C12.0421347,10.9904081 12.0504476,10.5945114 11.8697317,10.2710486 C11.6890157,9.94758569 11.3475219,9.74712305 10.977,9.747 L7.5,9.747 L7.5,0.747 L0.152,12.689 C-0.0423027635,13.0044392 -0.0506807724,13.4003308 0.130103121,13.7237078 C0.310887015,14.0470847 0.65252009,14.2473026 1.023,14.247 L4.5,14.247 L4.5,23.247 L11.848,11.306 Z"
                        id="Path"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </li>
          <li>
            <h6 class="text-opacity">Debit Amount</h6>
            <h5 class="text-bold">
              {{ currency && currency.symbol }} {{ totalAmount }}
            </h5>
          </li>
        </ul>
      </div>
      <div class="col-sm-12 col-lg-3">
        <ul class="invoice-customer_info">
          <li>
            <svg
              width="50px"
              height="50px"
              viewBox="0 0 45 45"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Work-Page"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="Invoice-6"
                  transform="translate(-1103.000000, -304.000000)"
                >
                  <g
                    id="Invoice---Due-Date"
                    transform="translate(1103.000000, 304.000000)"
                  >
                    <circle
                      id="Oval-Copy-5"
                      fill="#D5EFFF"
                      cx="22"
                      cy="22"
                      r="22"
                    ></circle>
                    <g
                      transform="translate(10.000000, 10.000000)"
                      stroke="#0A5685"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    >
                      <path
                        d="M18.75,14.2499991 L16.717,14.2499991 C16.0734033,14.2492534 15.519894,14.7055212 15.3978091,15.337433 C15.2757241,15.9693448 15.6194422,16.5989563 16.217,16.838 L18.281,17.663 C18.8771734,17.9028758 19.2196241,18.5317157 19.0976786,19.1626613 C18.9757332,19.7936069 18.4236219,20.2495512 17.781,20.2499991 L15.75,20.2499991"
                        id="Path"
                        stroke-linecap="round"
                      ></path>
                      <line
                        x1="17.25"
                        y1="14.25"
                        x2="17.25"
                        y2="13.5"
                        id="Path"
                        stroke-linecap="round"
                      ></line>
                      <line
                        x1="17.25"
                        y1="21"
                        x2="17.25"
                        y2="20.25"
                        id="Path"
                        stroke-linecap="round"
                      ></line>
                      <circle
                        id="Oval"
                        stroke-linecap="round"
                        cx="17.25"
                        cy="17.25"
                        r="6"
                      ></circle>
                      <path
                        d="M8.25,17.25 L2.25,17.25 C1.42157288,17.25 0.75,16.5784271 0.75,15.75 L0.75,3.765 C0.75,2.93657288 1.42157288,2.265 2.25,2.265 L15.75,2.265 C16.5784271,2.265 17.25,2.93657288 17.25,3.765 L17.25,8.25"
                        id="Path"
                        stroke-linecap="round"
                      ></path>
                      <line
                        x1="0.75"
                        y1="6.75"
                        x2="17.25"
                        y2="6.75"
                        id="Path"
                      ></line>
                      <line
                        x1="5.243"
                        y1="3.75"
                        x2="5.243"
                        y2="0.75"
                        id="Path"
                        stroke-linecap="round"
                      ></line>
                      <line
                        x1="12.743"
                        y1="3.75"
                        x2="12.743"
                        y2="0.75"
                        id="Path"
                        stroke-linecap="round"
                      ></line>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </li>
          <li>
            <h6 class="text-opacity">Debit Date</h6>
            <h5 class="text-bold">
              {{ toLocal(payroll.created_at).format("ll") }}
            </h5>
          </li>
        </ul>
      </div>
      <div class="col-sm-12 col-lg-3">
        <ul class="invoice-customer_info">
          <li>
            <svg
              width="50px"
              height="50px"
              viewBox="0 0 45 45"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Work-Page"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="Invoice-6"
                  transform="translate(-1103.000000, -304.000000)"
                >
                  <g
                    id="Invoice---Due-Date"
                    transform="translate(1103.000000, 304.000000)"
                  >
                    <circle
                      id="Oval-Copy-5"
                      fill="#D5EFFF"
                      cx="22"
                      cy="22"
                      r="22"
                    ></circle>
                    <g
                      transform="translate(10.000000, 10.000000)"
                      stroke="#0A5685"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    >
                      <path
                        d="M18.75,14.2499991 L16.717,14.2499991 C16.0734033,14.2492534 15.519894,14.7055212 15.3978091,15.337433 C15.2757241,15.9693448 15.6194422,16.5989563 16.217,16.838 L18.281,17.663 C18.8771734,17.9028758 19.2196241,18.5317157 19.0976786,19.1626613 C18.9757332,19.7936069 18.4236219,20.2495512 17.781,20.2499991 L15.75,20.2499991"
                        id="Path"
                        stroke-linecap="round"
                      ></path>
                      <line
                        x1="17.25"
                        y1="14.25"
                        x2="17.25"
                        y2="13.5"
                        id="Path"
                        stroke-linecap="round"
                      ></line>
                      <line
                        x1="17.25"
                        y1="21"
                        x2="17.25"
                        y2="20.25"
                        id="Path"
                        stroke-linecap="round"
                      ></line>
                      <circle
                        id="Oval"
                        stroke-linecap="round"
                        cx="17.25"
                        cy="17.25"
                        r="6"
                      ></circle>
                      <path
                        d="M8.25,17.25 L2.25,17.25 C1.42157288,17.25 0.75,16.5784271 0.75,15.75 L0.75,3.765 C0.75,2.93657288 1.42157288,2.265 2.25,2.265 L15.75,2.265 C16.5784271,2.265 17.25,2.93657288 17.25,3.765 L17.25,8.25"
                        id="Path"
                        stroke-linecap="round"
                      ></path>
                      <line
                        x1="0.75"
                        y1="6.75"
                        x2="17.25"
                        y2="6.75"
                        id="Path"
                      ></line>
                      <line
                        x1="5.243"
                        y1="3.75"
                        x2="5.243"
                        y2="0.75"
                        id="Path"
                        stroke-linecap="round"
                      ></line>
                      <line
                        x1="12.743"
                        y1="3.75"
                        x2="12.743"
                        y2="0.75"
                        id="Path"
                        stroke-linecap="round"
                      ></line>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </li>
          <li>
            <h6 class="text-opacity">Employee pay date</h6>
            <h5 class="text-bold">
              {{ toLocal(payroll.start_date).format("ll") }} -
              {{ toLocal(payroll.end_date).format("ll") }}
            </h5>
          </li>
        </ul>
      </div>
    </div>
    <!-- <div class="pl-3 pr-4 pb-4 pt-4">
      <h6 class="h7 text-bold text-opacity-pale">PAYMENT SERVICES</h6>
      <div class="row services mt-5">
        <div class="col-sm-12 col-lg-4 mb-4" v-for="(service,index) in services" :key="index">
          <div class="service-card p-4 pt-5 pb-5">
            <div class="service-card__header d-flex flex-row justify-content-between">
              <div class="service-card__header-title">
                <span v-html="service.logo"></span>
              </div>
              <div class="service-card__header-status">
                <button
                  style="border:none;background:#639102;color:white;border-radius:20px"
                  class="p-1 pl-3 pr-3"
                >
                  <i class="zmdi zmdi-circle pr-2" style="font-size:12px;color:white"></i>
                  <span style="font-size:15px">Active</span>
                </button>
              </div>
            </div>
            <div class="service-card__description mt-4">
              <div>
                <small class="text-opacity">Employees</small>
                <h5 class="text-bold text-opacity-pale">32</h5>
              </div>
              <div>
                <small class="text-opacity">Amount to remit</small>
                <h5 class="text-bold text-opacity-pale">$4,500.00</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3 text-right mb-5">
        <button class="btn btn-outline outline-primary text-primary pl-3 pr-3 mr-3">Cancel</button>
        <button
          class="btn btn-outline outline-primary outline-primary__clicked pl-3 pr-3"
        >Submit Payroll</button>
      </div>
    </div>-->
    <div class="row">
      <div class="col-sm-12 col-lg-6 pl-5 pr-4 pb-4 pt-4">
        <ul class="d-flex flex-row justify-content-between">
          <li>
            <div class="d-flex flex-column">
              <h5 class="h7 text-bold text-opacity-pale mb-0">
                PAYMENT SERVICES
              </h5>
              <h6 class="text-opacity pt-0">
                All Available Payment Processors
              </h6>
            </div>
          </li>
        </ul>
        <div class="row mt-5">
          <div
            class="col-sm-12 border-bottom pb-3 pt-3"
            v-for="(method, key, index) in methods"
            :key="index"
          >
            <ul
              class="d-flex flex-row align-items-center justify-content-between"
            >
              <li>
                <span v-html="optional(services[key], 'logo', null)"></span>
              </li>
              <li class="pr-5">
                <span>
                  <i
                    class="zmdi zmdi-circle pr-2 pb-3"
                    style="font-size:5px;color:green"
                  ></i>
                  <small class="h7 text-bold text-opacity-pale mt-0"
                    >Active</small
                  >
                </span>
              </li>
            </ul>
            <div class="d-flex flex-row mt-3">
              <div class="pr-5">
                <small class="text-opacity">Employees</small>

                <h5 class="text-bold text-opacity-pale">
                  {{ method.employees }}
                </h5>
              </div>
              <div class="pr-5">
                <small class="text-opacity">Amount to remit</small>

                <h5 class="text-bold text-opacity-pale">
                  {{ method.buyCurrencySymbol }}
                  {{ parseFloat(method.buyAmount || 0).toFixed(2) }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-sm-12 col-lg-6 pr-4 pl-4 pb-4 pt-4"
        style="background:#F5F5F5"
      >
        <ul class="d-flex flex-row justify-content-between">
          <li>
            <div class="d-flex flex-column">
              <h5 class="h7 text-bold text-opacity-pale mb-0">QUOTATION</h5>
              <h6 class="text-opacity pt-0">
                Breakdown based off selected currency
              </h6>
            </div>
          </li>
          <li>
            <button
              @click="acceptQuote(quotes.map((s) => s.quoteId))"
              class="btn btn-outline pl-4 pr-4 mr-5"
              style="border-radius:40px"
            >
              Accept All
            </button>
          </li>
        </ul>
        <div class="row mt-5">
          <div
            class="col-sm-12 border-bottom pb-3 pt-3"
            v-for="(quote, index) in quotes"
            :key="index"
          >
            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <div class="w-100 pr-5">
                <ul
                  class="d-flex flex-row align-items-center justify-content-between w-100"
                >
                  <li class>
                    <button
                      style="background:#baffba; border:none;height:40px;width:40px;border-radius:10px"
                    >
                      <i
                        class="zmdi zmdi-arrow-right-top"
                        style="color:#355635 !important"
                      ></i>
                    </button>
                  </li>
                  <!-- <li class="pr-5 pl-5 d-flex flex-column">
                    <div>
                      <small class="text-opacity">Buying Currency</small>
                    </div>
                    <div>
                      <h5 class="text-bold mt-0">USD ($)</h5>
                    </div>
                  </li>
                  <li class="pr-5 pl-5 d-flex flex-column">
                    <div>
                      <small class="text-opacity">Selling Currency</small>
                    </div>
                    <div>
                      <h5 class="text-bold mt-0">CAD ($)</h5>
                    </div>
                  </li> -->
                </ul>
                <!-- <ul
                  class="d-flex flex-row align-items-center justify-content-between  mt-3"
                >-->
                <!-- <li>
                    <h6 class="text-opacity-pale text-bold">Buying Rate</h6>
                  </li>
                  <li class="pr-5">
                    <span>
                      <h5 class="text-bold text-opacity-pale mt-0">3,000</h5>
                    </span>
                  </li>
                </ul>
                <ul class="d-flex flex-row align-items-center justify-content-between line-between">
                  <li>
                    <h6 class="text-opacity-pale text-bold">Selling Rate</h6>
                  </li>
                  <li class="pr-5">
                    <span>
                      <h5 class="text-bold text-opacity-pale mt-0">3,000</h5>
                    </span>
                  </li>
                </ul>
                <ul class="d-flex flex-row align-items-center justify-content-between line-between">
                  <li>
                    <h6 class="text-opacity-pale text-bold">Selling Rate</h6>
                  </li>
                  <li class="pr-5">
                    <span>
                      <h5 class="text-bold text-opacity-pale mt-0">$3,000</h5>
                    </span>
                  </li>
                </ul>-->
                <ul
                  class="d-flex flex-row align-items-center justify-content-between"
                >
                  <li class="pr-5 pt-3 d-flex flex-column">
                    <div>
                      <small class="text-opacity">Selling Rate</small>
                    </div>
                    <div>
                      <h5 class="text-bold mt-0 text-opacity-pale pt-1">
                        {{ quote.sellCurrency }} {{ quote.sellAmount }}
                      </h5>
                    </div>
                  </li>
                  <li class="pr-5 w-50 pt-3 d-flex flex-column">
                    <div>
                      <small class="text-opacity">Buying Rate</small>
                    </div>
                    <div>
                      <h5 class="text-bold mt-0 text-opacity-pale pt-1">
                        {{ quote.buyCurrency }} {{ quote.buyAmount }}
                      </h5>
                    </div>
                  </li>
                </ul>
                <ul
                  class="d-flex flex-row align-items-center justify-content-between"
                >
                  <li class="pr-5 w-50 pt-3 d-flex flex-column">
                    <div>
                      <small class="text-opacity">Rate</small>
                    </div>
                    <div>
                      <h5 class="text-bold mt-0 text-opacity-pale pt-1">
                        {{ quote.sellCurrency }} {{ quote.customerRate }}
                      </h5>
                    </div>
                  </li>
                  <li class="pr-5 w-50 pt-3 d-flex flex-column">
                    <div>
                      <small class="text-opacity">Fees</small>
                    </div>
                    <div>
                      <h5 class="text-bold text-opacity-pale mt-0 pt-1">
                        {{ quote.feeCurrency }} {{ quote.fee }}
                      </h5>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="pr-5">
                <button
                  :disabled="acceptedQuotes.includes(quote.quoteId)"
                  @click="acceptQuote(quote.quoteId)"
                  class="btn btn-outline pl-4 pr-4"
                  style="border-radius:40px"
                >
                  {{
                    acceptedQuotes.includes(quote.quoteId)
                      ? "Accepted"
                      : "Accept"
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 text-right mb-4">
      <button
        @click="cancelPayroll()"
        class="btn btn-outline outline-primary text-primary pl-3 pr-3 mr-3"
      >
        Cancel
      </button>
      <button
        @click="save"
        :disabled="loading"
        class="btn btn-outline outline-primary outline-primary__clicked pl-3 pr-3"
      >
        Save and Continue
      </button>
    </div>
  </div>
</template>

<script>
import logosvg from "@/helpers/logosvg.js";
import { toLocal } from "@/helpers/date";
import handleError from "@/helpers/error";
import { optional } from "@/helpers/random";

export default {
  data() {
    return {
      services: {
        authorize: {
          name: "Authorize.NET",
          logo: logosvg.authorize,
        },
        ofx: {
          name: "OFX",
          logo: logosvg.ofx,
        },
        stripe: {
          name: "Stripe",
          logo: logosvg.stripe,
        },
        paypal: {
          name: "Paypal",
          logo: logosvg.paypal,
        },
      },
      loaded: false,
      loading: false,
      acceptedQuotes: [],
    };
  },
  methods: {
    toLocal,
    optional,
    acceptQuote(id) {
      Array.isArray(id)
        ? (this.acceptedQuotes = id)
        : this.acceptedQuotes.push(id);
    },
    save() {
      this.loading = true;

      let data = {
        quotes: this.acceptedQuotes,
      };

      this.$store
        .dispatch("payrolls/complete", {
          id: this.$route.params.id,
          payload: data,
        })
        .then((data) => {
          this.$toast.success("Payroll sent for completion", {
            position: "top-right",
            duration: 3000,
          });
          this.$router.push({
            path: `/payments/${this.$route.params.id}/confirm`,
          });
        })
        .catch((error) => {
          handleError(this, error, "Error occurred while sending request");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancelPayroll() {
      let r = confirm("Are you sure you want to cancel payroll?");
      if (!r) return;
      this.$router.push({ name: "payments" });
    },
  },
  computed: {
    paymentSources() {
      return this.$store.getters["global/payment_sources"];
    },
    employees() {
      return this.$store.getters["payrolls/employees"];
    },
    payroll() {
      return this.$store.getters["payrolls/payroll"];
    },
    quotes() {
      let methods = [];
      for (let name in this.paymentMethods) {
        for (let v of this.paymentMethods[name]) {
          methods.push(v);
        }
      }
      return methods;
    },
    checkbook() {
      return this.optional(
        this.$store.getters["payrolls/payroll_data"],
        "checkbook",
        {}
      );
    },
    paymentMethods() {
      let data = this.$store.getters["payrolls/payroll_data"]["providers"];

      return data;
    },
    methods() {
      //TODO: optimize loop
      let methods = {};
      for (let name in this.paymentMethods) {
        for (let v of this.paymentMethods[name]) {
          methods[name] = methods[name] || v;
          methods[name]["buyAmount"] =
            (methods[name]["buyAmount"] || 0) + v.buyAmount;
          methods[name]["employees"] =
            (methods[name]["employees"] || 0) + v.employees;
        }
      }
      return methods;
    },
    totalAmount() {
      let result = 0;

      for (let name in this.paymentMethods) {
        for (let v of this.paymentMethods[name]) {
          result += v.buyAmount;
        }
      }
      return isNaN(result) ? 0.0 : parseFloat(result).toFixed(2);
    },
    currencies() {
      return this.$store.getters["global/currencies"];
    },
    currency() {
      let org = this.$auth.user().organization;
      let curr = {};

      if (org) {
        let currencyId = org.default_currency_id;
        curr = this.currencies.find((s) => s.id == currencyId);
      }

      return curr;
    },
  },
  created() {
    let id = this.$route.params.id;
    Promise.all([
      this.$store.dispatch("global/getCurrencies"),
      this.$store.dispatch("payrolls/getSingle", id),
      this.paymentSources.length ||
        this.$store.dispatch("global/getPaymentSources"),
      this.employees.length ||
        this.$store.dispatch("payrolls/getEmployees", id),
    ]).finally(() => {
      this.loaded = true;
    });
  },
};
</script>

<style lang="scss" scoped>
.line-between {
  li {
    position: relative;
    &:not(:last-child) {
      &::after {
        content: "";
        position: absolute;
        width: 200px;
        height: 1px;
        background: #daf3ff;
        margin: 0 auto;
        transform: translate(130px, 10px);
      }
    }
  }
}
.border-bottom {
  border-bottom: 2px solid #f5f5f5 !important;
  &:first-child {
    border-top: 2px solid #f5f5f5;
  }

  &:hover {
    background: #f5f5f5;
  }
}
.tab-wrapper {
  .row {
    background: white;
    padding-bottom: 20px;
  }
  .ignite-wrapper {
    background: #fbfdff;
    transform: translateX(-12px);
    width: 100vw;
    padding-left: 30px !important;
    border-top: 2px solid #f5f5f5;
    border-bottom: 2px solid #f5f5f5;
  }
}
.invoice-customer_info {
  list-style-type: none;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  li {
    &:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        height: 50px;
        width: 50px;
        border-radius: 50px;
        background: #d5efff;
        border: none;
        outline: none;
        svg {
          fill: #0a5685;
        }
      }
    }
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      padding-left: 15px;
      padding-top: 10px;
      h5 {
        font-size: 18px;
        color: #00133e;
      }
    }
  }
}
</style>
