<template>
  <div class="mb-5" :class="{blur:!loaded}">
    <div class="row" v-if="collaborators.length > 0">
      <div
        class="col-sm-12 col-lg-3 mb-3"
        :key="index"
        v-for="(collaborator,index) in collaborators"
      >
        <router-link class="card-link" :to="{name: 'users-show',params: {id: collaborator.id}}">
          <div class="card collaborators--card">
            <div class="card-content p-3 pt-4 pb-5">
              <div class="card-profile">
                <button>{{ `${collaborator.first_name.charAt(0)}${collaborator.last_name.charAt(0)}`}}</button>
              </div>
              <div class="mt-4">
                <h6
                  class="h7 text-bold text-opacity-pale"
                >{{collaborator.first_name}} {{collaborator.last_name}}</h6>
                <small class="text-opacity">{{collaborator.email}}</small>

                <ul class="xl">
                  <li>
                    <small class="text-opacity">Status</small>
                    <small class="bigger">
                      <span class="status"></span>
                      {{collaborator.is_banned ? 'Deactivated' : 'Active'}}
                    </small>
                  </li>
                  <li>
                    <small class="text-opacity">Role</small>
                    <small class="bigger">{{collaborator.role && collaborator.role.name}}</small>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card-footer text-right">
              <small class="text-right">View User</small>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="row ml-2" v-else>
      <div class="col-sm-12 text-center">
        <small class="text-opacity-pale text-bold">No Collaborator has been added Yet!</small>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
a.card-link {
  text-decoration: none !important;
  color: black !important;
}
.collaborators--card {
  box-shadow: -8px 8px 16px 0 rgba(67, 86, 100, 0.07),
    -4px 4px 8px 0 rgba(67, 86, 100, 0.05);
  background-color: #ffffff;
  border: 0px;
  .card-content {
    .card-profile {
      button {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        border: none;
        outline: none;
        background: lightcoral;
        color: white;
      }
    }
    h6 {
      line-height: 2px;
    }
    .xl {
      display: flex;
      margin-top: 20px;
      padding-top: 20px;
      /* align-content: space-between; */
      justify-content: space-between;
      width: 70%;
      li {
        font-size: 14px;
        display: grid;

        .status {
          height: 20px;
          width: 20px;
          background: red;
        }

        .bigger {
          font-size: 14px;
          padding-top: 2px;
        }
      }
    }
  }
  .card-footer {
    border-top: 0px;
    background: #f0f4f8;
    color: #0a5685;
  }

  &:hover {
    .card-footer {
      background: #0a5685;
      color: white;
      transition: background 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

      small {
        &::after {
          content: "→";
          transform: rotate(-45deg);
          padding-left: 10px;
        }
      }
    }
  }
}
</style>

<script>
export default {
  data() {
    return {
      loaded: false
    };
  },
  created() {
    this.$store.dispatch("projects/getCollaborators", this.$route.params.id).then(() => {
      this.loaded = true;
    });
  },
  computed: {
    collaborators() {
      return this.$store.getters["projects/collaborators"];
    }
  }
};
</script>
