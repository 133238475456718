<template>
  <div class="home" :class="{blur:!task.id}">
    <headers class="bg-ggray pb-5">
      <div slot="breadcrumbs">
        <breadcrumbs :items="breadCrumbs"></breadcrumbs>
      </div>
      <div slot="title">
        <h2>{{milestone.name}}</h2>
      </div>
      <div slot="action">
        <router-link :to="{name:'edit-milestone'}">
          <button class="btn btn-ds btn-outline pl-3 pr-3">
            <span class="pr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#72809D"
                viewBox="0 0 30 30"
              >
                <path
                  d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"
                ></path>
              </svg>
            </span>
            Edit Milestone
          </button>
        </router-link>
        <!-- <button
          @click="setDeleteState('Task',$route.params.id)"
          class="btn btn-ds btn-danger pl-3 ml-2 pr-3"
        >Delete Milestone</button> -->
      </div>
      
    </headers>

    <div class="container-fluid tab-wrapper">
      <div class="row">
        <div class="col-lg-6 float-left">
          <ul class="tabs">
            <router-link :to="{name: 'milestones'}">
              <li>ALL TASKS</li>
            </router-link>
            <router-link  :to="{name: 'milestones-about'}">
              <li>ABOUT</li>
            </router-link>
          </ul>
        </div>
      </div>
      <div class="mt-4" v-if="milestone">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Headers from "@/components/Layouts/Headers";
import Breadcrumbs from  "@/components/Breadcrumbs/Index";
export default {
  components: { Headers, Breadcrumbs },
  data() {
    return {
      task: {
        id: 1
      },
      loaded: false,
      breadCrumbs: [
        {
          text: "Home",
          href: "#"
        },
        {
          text: "Milestones",
          active: true
        },
        {
          text: "Milestone",
          active: true
        }
      ]
    };
  },
  computed: {
    milestone() {
      return this.$store.getters["projects/milestone"];
    },
    milestoneStatuses() {
      return this.$store.getters["global/milestone_statuses"];
    }
  },
  created() {
    Promise.all([
      this.$store.dispatch("projects/getMilestone", this.$route.params.mid),
      this.$store.dispatch("global/getMilestoneStatuses")
    ]).finally(() => {
      this.loaded = true;
    });
  }
};
</script>
