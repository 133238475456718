<template>
  <div class="home">
    <headers>
      <div slot="breadcrumbs">
        <breadcrumbs :items="breadCrumbs"></breadcrumbs>
      </div>
      <div slot="title">
        <h2>Invoices</h2>
      </div>
      <div slot="action">
        <router-link :to="{name: 'invoices-create'}">
          <button class="btn btn-ds">
            <span class="pr-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path
                  fill="#FFF"
                  d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 4c-.552 0-1 .448-1 1v2H5c-.552 0-1 .448-1 1s.448 1 1 1h2v2c0 .552.448 1 1 1s1-.448 1-1V9h2c.552 0 1-.448 1-1s-.448-1-1-1H9V5c0-.552-.448-1-1-1z"
                ></path>
              </svg>
            </span>
            Create Invoice
          </button>
        </router-link>
      </div>
    </headers>

    <div class="container-fluid" v-if="loaded">
      <div class="row pt-4 pb-4 pl-3 pr-4">
        <div class="col-sm-12 col-lg-3">
          <div class="card invoice-metrics pt-4 pb-4 pr-5 pl-3">
            <div class="card-content">
              <ul>
                <li>
                  <svg
                    width="44px"
                    height="44px"
                    viewBox="0 0 44 44"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      id="Work-Page"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g id="Invoice-1---Landing" transform="translate(-48.000000, -171.000000)">
                        <g id="Group" transform="translate(32.000000, 140.000000)">
                          <g
                            id="Invoice-Dashboard---Total-Unpaid"
                            transform="translate(16.000000, 31.000000)"
                          >
                            <circle id="Oval" fill="#D5EFFF" cx="22" cy="22" r="22"></circle>
                            <path
                              d="M32.3142845,32.3714272 C32.3142845,33.2235237 31.6235239,33.9142843 30.7714275,33.9142843 L13.7999999,33.9142843 C12.9479035,33.9142843 12.2571428,33.2235237 12.2571428,32.3714272 L12.2571428,12.3142856 C12.2571428,11.4621892 12.9479035,10.7714285 13.7999999,10.7714285 L24.732685,10.7714285 C25.1415906,10.7714285 25.533738,10.933924 25.8229706,11.2229714 L31.8627417,17.2627424 C32.151789,17.551975 32.3142845,17.9441224 32.3142845,18.3530281 L32.3142845,32.3714272 Z"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.34999992"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M32.3142845,18.4857138 L26.1428563,18.4857138 C25.2907599,18.4857138 24.5999992,17.7949531 24.5999992,16.9428567 L24.5999992,10.7714285"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.34999992"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M16.3199997,21.9252564 C16.7720818,22.519033 17.4853864,22.8549541 18.2310853,22.8252564 C19.4026281,22.8252564 20.3530281,22.1124564 20.3530281,21.2340565 C20.3530281,20.3556565 19.4057138,19.6438851 18.2351996,19.6438851 C17.0646854,19.6438851 16.1142855,18.9310852 16.1142855,18.0516567 C16.1142855,17.1722281 17.0646854,16.4604568 18.2351996,16.4604568 C18.9810059,16.4302294 19.694543,16.7662601 20.1462852,17.3604567"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.34999992"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <line
                              x1="18.2351996"
                              y1="22.8252564"
                              x2="18.2351996"
                              y2="23.8857135"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.34999992"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></line>
                            <line
                              x1="18.2351996"
                              y1="15.3999997"
                              x2="18.2351996"
                              y2="16.4604568"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.34999992"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></line>
                            <line
                              x1="22.2857137"
                              y1="25.4285705"
                              x2="28.4571419"
                              y2="25.4285705"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.34999992"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></line>
                            <line
                              x1="16.885714"
                              y1="30.0571417"
                              x2="28.4571419"
                              y2="30.0571417"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.34999992"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></line>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </li>
                <li>
                  <h6 class="text-bold text-small text-opacity">Total unpaid</h6>
                  <h5 class="text-bold text-opacity-pale">{{numberWithCommas(parseFloat(metric.totalUnpaid || 0).toFixed(2))}}</h5>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-3">
          <div class="card invoice-metrics pt-4 pb-4 pr-5 pl-3">
            <div class="card-content">
              <ul>
                <li>
                  <svg
                    width="44px"
                    height="44px"
                    viewBox="0 0 44 44"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      id="Work-Page"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g id="Invoice-1---Landing" transform="translate(-398.000000, -171.000000)">
                        <g id="Group-2" transform="translate(382.000000, 140.000000)">
                          <g
                            id="Invoice-Dashboard---Amount-Overdue"
                            transform="translate(16.000000, 31.000000)"
                          >
                            <circle id="Oval-Copy-4" fill="#D5EFFF" cx="22" cy="22" r="22"></circle>
                            <path
                              d="M29.5,22.88 L29.5,12.25 C29.5,11.4215729 28.8284271,10.75 28,10.75 L16,10.75 C15.1715729,10.75 14.5,11.4215729 14.5,12.25 L14.5,22.88"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M31.284,21.465 C31.5093503,21.2863025 31.8170422,21.2522091 32.0760466,21.3772383 C32.3350509,21.5022675 32.4997471,21.764397 32.5,22.052 L32.5,31.752 C32.5,32.5804271 31.8284271,33.252 31,33.252 L13,33.252 C12.1715729,33.252 11.5,32.5804271 11.5,31.752 L11.5,22.052 C11.5002529,21.764397 11.6649491,21.5022675 11.9239534,21.3772383 C12.1829578,21.2522091 12.4906497,21.2863025 12.716,21.465 L20.147,27.359 C21.2346554,28.21324 22.7653446,28.21324 23.853,27.359 L31.284,21.465 Z"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <line
                              x1="25.808"
                              y1="25.808"
                              x2="28.75"
                              y2="28.75"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></line>
                            <line
                              x1="18.192"
                              y1="25.808"
                              x2="15.25"
                              y2="28.75"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></line>
                            <path
                              d="M22.2,20.094 C22.6395243,20.6712828 23.3330149,20.9978728 24.058,20.969 C25.197,20.969 26.121,20.276 26.121,19.422 C26.121,18.568 25.2,17.876 24.062,17.876 C22.924,17.876 22,17.183 22,16.328 C22,15.473 22.924,14.781 24.062,14.781 C24.7870895,14.7516123 25.4808062,15.0783088 25.92,15.656"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <line
                              x1="24.062"
                              y1="20.969"
                              x2="24.062"
                              y2="22"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></line>
                            <line
                              x1="24.062"
                              y1="13.75"
                              x2="24.062"
                              y2="14.781"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></line>
                            <line
                              x1="17.5"
                              y1="14.5"
                              x2="19"
                              y2="14.5"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></line>
                            <line
                              x1="17.5"
                              y1="17.5"
                              x2="19"
                              y2="17.5"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></line>
                            <line
                              x1="17.5"
                              y1="20.5"
                              x2="19"
                              y2="20.5"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></line>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </li>
                <li>
                  <h6 class="text-bold text-small text-opacity">Amount Overdue</h6>
                  <h5 class="text-bold text-opacity-pale">{{numberWithCommas(parseFloat(metric.totalOverdue || 0).toFixed(2))}}</h5>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-3">
          <div class="card invoice-metrics pt-4 pb-4 pr-5 pl-3">
            <div class="card-content">
              <ul>
                <li>
                  <svg
                    width="44px"
                    height="44px"
                    viewBox="0 0 44 44"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      id="Work-Page"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g id="Invoice-1---Landing" transform="translate(-748.000000, -171.000000)">
                        <g id="Group-3-Copy" transform="translate(732.000000, 140.000000)">
                          <g
                            id="Invoice-Dashboard---Due-Within-the-next-30-Days"
                            transform="translate(16.000000, 31.000000)"
                          >
                            <circle id="Oval-Copy-5" fill="#D5EFFF" cx="22" cy="22" r="22"></circle>
                            <path
                              d="M18.25,27.25 L12.25,27.25 C11.4215729,27.25 10.75,26.5784271 10.75,25.75 L10.75,13.75 C10.75,12.9215729 11.4215729,12.25 12.25,12.25 L25.75,12.25 C26.5784271,12.25 27.25,12.9215729 27.25,13.75 L27.25,18.25"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <line
                              x1="10.75"
                              y1="16.75"
                              x2="27.25"
                              y2="16.75"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></line>
                            <line
                              x1="15.25"
                              y1="13.75"
                              x2="15.25"
                              y2="10.75"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></line>
                            <line
                              x1="22.75"
                              y1="13.75"
                              x2="22.75"
                              y2="10.75"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></line>
                            <circle
                              id="Oval"
                              stroke="#0A5685"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              cx="27.25"
                              cy="27.25"
                              r="6"
                            ></circle>
                            <polyline
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              points="29.902 27.25 27.25 27.25 27.25 24.599"
                            ></polyline>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </li>
                <li>
                  <h6 class="text-bold text-small text-opacity">Due within 30 days</h6>
                  <h5 class="text-bold text-opacity-pale">{{numberWithCommas(parseFloat(metric.dueWithin30Days || 0).toFixed(2))}}</h5>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-3">
          <div class="card invoice-metrics pt-4 pb-4 pr-5 pl-3">
            <div class="card-content">
              <ul>
                <li>
                  <svg
                    width="44px"
                    height="44px"
                    viewBox="0 0 44 44"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      id="Work-Page"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g id="Invoice-1---Landing" transform="translate(-1098.000000, -171.000000)">
                        <g id="Group-3" transform="translate(1082.000000, 140.000000)">
                          <g
                            id="Invoice-Dashboard---Draft-Invoinces"
                            transform="translate(16.000000, 31.000000)"
                          >
                            <circle id="Oval-Copy-5" fill="#D5EFFF" cx="22" cy="22" r="22"></circle>
                            <polygon
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              points="18.492 21.265 17.432 25.508 21.674 24.447 28.038 18.083 24.856 14.901"
                            ></polygon>
                            <path
                              d="M31.751,14.371 L32.811,15.431 C33.0925125,15.7123322 33.2506798,16.0940087 33.2506798,16.492 C33.2506798,16.8899913 33.0925125,17.2716678 32.811,17.553 L29.5,20.863"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M28.038,18.083 L31.751,14.371 C32.3365729,13.7852502 32.3365729,12.8357498 31.751,12.25 L30.69,11.189 C30.1042502,10.6034271 29.1547498,10.6034271 28.569,11.189 L24.856,14.9"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M28.75,24.25 L28.75,31.75 C28.75,32.5784271 28.0784271,33.25 27.25,33.25 L12.25,33.25 C11.4215729,33.25 10.75,32.5784271 10.75,31.75 L10.75,16.75 C10.75,15.9215729 11.4215729,15.25 12.25,15.25 L19.75,15.25"
                              id="Path"
                              stroke="#0A5685"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </li>
                <li>
                  <h6 class="text-bold text-small text-opacity">Draft Invoices</h6>
                  <h5 class="text-bold text-opacity-pale">{{numberWithCommas(metric.drafts || 0)}}</h5>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <invoice-table @setInvoiceTotal="handleTotal"></invoice-table>
  </div>
</template>

<style lang="scss" scoped>
.invoice-metrics {
  border: solid 1px #dae3eb;
  border-radius: 8px;
  background: #fff;
}
.invoice-metrics ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  li {
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    h5 {
      font-size: 23px !important;
    }
    h6 {
      font-size: 14px !important;
      margin-bottom: 2px;
    }
  }
}
</style>

<script>
import Headers from "@/components/Layouts/Headers";
import Breadcrumbs from "@/components/Breadcrumbs/Index";
import InvoiceTable from "@/components/Tables/Invoice/Main";
import { toLocal } from "@/helpers/date";
import { numberWithCommas } from "@/helpers/number";

export default {
  title: "Index | Invoices",
  components: { Headers, Breadcrumbs, InvoiceTable },
  data() {
    return {
      breadCrumbs: [
        {
          text: "Home",
          href: "#"
        },
        {
          text: "All Invoices",
          active: true
        },
        {
          text: "Invoices",
          active: true
        }
      ],
      invoices:[],
      loaded: false
    };
  },
  methods: {
    toLocal,
    numberWithCommas,
    handleTotal(total) {
      this.invoices = total;
    }
  },
  computed:{
    metric() {
      return this.$store.getters['invoices/invoice_metrics'];
    },
    
  },
  created() {
    this.$store.dispatch('invoices/getMetrics').finally(() => {
      this.loaded = true;
  
    })
  }
};
</script>
