<template>
    <div>
        <notes-modal></notes-modal>
    </div>
</template>

<script>
import NotesModal from "@/components/Modals/Notes";
export default {
    components:{NotesModal}
}
</script>