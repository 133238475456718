<template>
  <div class="wrapper">
    <div class="row">
      <div class="col-sm-12 col-lg-6 wrapper-content-left">
        <div class="logo pl-4 mb-4">
          <img src="@/assets/img/leaf.svg">
        </div>
      </div>
      <div class="col-sm-12 col-lg-6 wrapper-content-right">
        <div class="content">
          <div class="row mt-5 pt-5 pl-5 pr-5">
            <div class="col-sm-12 pt-5 mt-2">
            <div v-if="$route.query && $route.query.welcome">
                <h2>{{Literals.WELCOME_PASSWORD_HEADER_CAPTION}}</h2>
                <small class="text-opacity">{{Literals.WELCOME_PASSWORD_SUBHEADER_CAPTION}}</small>
            </div>
              <div v-else>
                <h2 >{{Literals.RESET_PASSWORD_HEADER_CAPTION}}</h2>
                <small class="text-opacity">{{Literals.RESET_PASSWORD_SUBHEADER_CAPTION}}</small>
              </div>
            </div>

            <!-- alerts -->
            <div class="col-sm-12 col-lg-10 mt-4" v-show="errorMsg.length > 0">
              <error-alert :message="errorMsg"></error-alert>
            </div>
            <div class="col-sm-12 col-lg-10 mt-4" v-show="successMsg">
              <success-alert :message="successMsg">
                <small slot="extras" class="pl-2">
                  <router-link :to="{name: 'Login'}">Here</router-link>
                </small>
              </success-alert>
            </div>

            <!-- end -->

            <div class="col-sm-12 pt-5 mt-2">
              <form @submit.prevent="reset">
                <div class="row auth-form">
                  <div class="col-sm-12 col-lg-10">
                    <h6>{{Literals.NEW_PASSWORD_INPUT_CAPTION}} <b>*</b></h6>
                    <div>
                      <input
                        v-model="body.password"
                        :type="passwordType"
                        data-vv-as="password"
                        name="password"
                        v-validate="{ required: true, regex: /^(?=.*[0-9])(?=.*[\D])(.+)$/, min: 8 }" 
                        class="form-control auth-input"
                        placeholder="Enter your Password"
                        ref="password"
                      >
                      <small
                        class="form-text text-danger"
                        v-show="errors.has('password')"
                      >{{errors.first('password')}}</small>
                      <span @click="togglePassword" class="show-password">
                        <i
                          :class="passwordType === 'password' ? 'zmdi zmdi-eye' : 'zmdi zmdi-eye-off'"
                        ></i>
                      </span>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-10 mt--6">
                    <h6>{{Literals.CONFIRM_PASSWORD_INPUT_CAPTION}} <b>*</b></h6>
                    <div>
                      <input
                        :type="passwordType"
                        v-validate="{ required: true, regex: /^(?=.*[0-9])(?=.*[\D])(.+)$/, min: 8, confirmed: 'password' }" 
                        data-vv-as="confirm password"
                        name="password_confirmation"
                        class="form-control auth-input"
                        placeholder="Enter your Password"
                      >
                      <small
                        class="form-text text-danger"
                        v-show="errors.has('password_confirmation')"
                      >{{errors.first('password_confirmation')}}</small>
                      <span @click="togglePassword" class="show-password">
                        <i
                          :class="passwordType === 'password' ? 'zmdi zmdi-eye' : 'zmdi zmdi-eye-off'"
                        ></i>
                      </span>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-10 mt--6">
                    <Auth-Button :loading="loading" :text="body.token && $route.query.welcome ? 'Create Password' : 'Reset Password'"/>
                  </div>

                  <div class="col-sm-12 col-lg-10 mt--6">
                    <h6>
                      <span class="text-opacity">Already have an account?</span>
                      <router-link :to="{name: 'Login'}" class="pl-1">Sign in</router-link>
                    </h6>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthButton from "@/components/Buttons/Auth.vue";
import ErrorAlert from "@/components/Alerts/Error.vue";
import SuccessAlert from "@/components/Alerts/Success.vue";
import Literals from "@/helpers/auth/literals.js";
import {updatePending} from "@/helpers/axiosIntercept";
import handleError from "@/helpers/error";
import {handleRedirect} from "@/helpers/random";
import { Validator } from 'vee-validate';

export default {
  title: 'Reset Password',
  data() {
    return {
      Literals,
      passwordType: "password",
      body: {
        password: null,
        email: null,
        token: null
      },
      errorMsg: [],
      successMsg: "",
      loading: false,
      redirect: null,
    };
  },
  computed: {
    isReset() {
      return (this.body.email && this.body.token)
    }
  },
  mounted() {
    const dict =  {
      custom : {
        password_confirmation: {
          confirmed: "Please make sure the passwords match.",
          regex: "Please choose a password with at least 8 characters, 1 uppercase character and 1 digit."
        },
        password : {
          regex: "Please choose a password with at least 8 characters, 1 uppercase character and 1 digit."
        }
      }
    }
    this.$validator.localize("en", dict);
  },
  components: { AuthButton, ErrorAlert, SuccessAlert },
  methods: {
    handleRedirect,
    togglePassword() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    reset() {
      this.errorMsg = [];
      this.successMsg = "";
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.loading = true;
          this.resetPassword();
        }
      });
    },
    resetPassword() {
      let url = this.isReset
              ? this.$baseApi + "/password/reset"
              :`${this.$baseApi}/users/${this.$auth.user().id}`;

      let method = this.isReset ? this.$http
                      .post(url, this.body)
              : this.$http
                      .put(url, this.body);
      method
        .then((res) => {

          if(res.status !== 200 || (res.response && res.response.status !== 200)) {
            handleError(this, res, 'Failed to reset password');
          } else {
            this.$toast.success(this.Literals.RESET_PASSWORD_SUCCESS, {
              position: "top-right",
              duration: 3000
            });

            if (this.isReset) {
              this.autoLogin();
            } else {
              window.location.href = this.redirect;
            }
          }

        })
        .catch(err => {
          
          handleError(this, err.response, 'Failed to reset password');
        }).finally(() => {
          this.loading = false;
          updatePending(url)
      });
    },
    autoLogin() {

      this.$auth
          .login({
            data: this.body,
            rememberMe: this.body.rememberMe || true,
            staySignedIn: this.body.rememberMe || true,
            redirect: false,
          })
          .then((res) => {

            if (res.status === 202) {
              this.$toast.error(Literals.OTP_ERROR, {
                position: "top-right",
                duration: 3000
              });
              this.$router.push({name:'Login', query:{is_otp:this.body.email}})
            } else {
              handleRedirect(this);
            }
          })
          .catch(({ response }) => {
           
            this.loading = false;
            handleError(
                this,
                response,
                response && response.data && response.data.message
            );
          })
          .finally(() => {
            updatePending(this.$loginUrl);
          });
    }
  },
  created() {
    let query = window.location.search
    query = decodeURIComponent(query)
    query = query.replaceAll("&amp;", "&")
    const urlParams = new URLSearchParams(query);
    this.body.email = urlParams.get('email');
    this.body.token = urlParams.get('token');
    this.redirect = urlParams.get('redirect');
    
    let errorMsg = window.localStorage.getItem('error_message');
    if(errorMsg) {
      this.errorMsg.push( errorMsg);
      window.localStorage.removeItem('error_message');
    }
  }
};
</script>
