import axios from 'axios';
import {updatePending} from "@/helpers/axiosIntercept";
import download from 'downloadjs'
import random from "@/helpers/random";

const API = process.env.VUE_APP_BASE_URI;
export default {
    createUpdate({
                     commit
                 }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/payment-batches${data && data.id ? `/${data.id}` : ''}`;
            let method = data && data.id ? axios.put(url, data) : axios.post(url, data);
            method.then(({
                             data: {
                                 data
                             }
                         }) => {
                // commit('SET_PAYROL', data);
                resolve(data);
            })
                .catch(error => reject(error.response))
                .finally(() => {
                    updatePending(url)
                });
        });
    },
    getSingle({
                     commit
                 }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/payment-batches/${id}?include=creator`;
            axios.get(url).then(({
                             data: {
                                 data
                             }
                         }) => {
                commit('SET_PAYROLL', data);
                resolve(data);
            })
                .catch(error => reject(error.response))
                .finally(() => {
                    updatePending(url)
                });
        });
    },
    getTransactions({
                  commit
              }, {id, params}) {
        return new Promise((resolve, reject) => {
            let url = `${API}/payment-batches/${id}?include=${params}`;
            axios.get(url).then(({
                                     data: {
                                         data
                                     }
                                 }) => {
                commit('SET_PAYROLL', data);
                resolve(data.transactions.data);
            })
                .catch(error => reject(error.response))
                .finally(() => {
                    updatePending(url)
                });
        });
    },
    getEmployees({
                  commit
              }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/payment-batches/${id}/employees`;
            axios.get(url).then(({
                                     data: {
                                         data
                                     }
                                 }) => {
                commit('SET_PAYROLL_EMPLOYEES', data);
                resolve(data);
            })
                .catch(error => reject(error.response))
                .finally(() => {
                    updatePending(url)
                });
        });
    },
    initialize({
                     commit
                 }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/payment-batches/${data.id}/payroll`;
            axios.post(url, data).then(({
                                     data: {
                                         data
                                     }
                                 }) => {
                commit('SET_PAYROLL_DATA', data);
                resolve(data);
            })
                .catch(error => reject(error.response))
                .finally(() => {
                    updatePending(url)
                });
        });
    },
    complete({
                   commit
               }, {id, payload}) {
        return new Promise((resolve, reject) => {
            let url = `${API}/payment-batches/${id}/complete`;
            axios.post(url, payload).then(({
                                            data: {
                                                data
                                            }
                                        }) => {
                resolve(data);
            })
                .catch(error => reject(error.response))
                .finally(() => {
                    updatePending(url)
                });
        });
    },
    export({
        commit
    },id) {
return new Promise((resolve, reject) => {
 let url = `${API}/payment-batches/${id}/export`;
 axios.get(url, {
    responseType: 'blob', // had to add this one here
})
    .then((response) => {
        const content = response.headers['content-type'];
        download(response.data, `payroll_${random.string(8)}.pdf`, content)
    })
     .catch(error => {
         
         reject(error.response)
     })
     .finally(() => {
         updatePending(url)
     });
});
},
}
