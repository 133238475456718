<template>
  <div>
    <transition name="slide-fade">
      <div v-show="visibleFilter">
        <form class="d-form">
          <div class="row mt-3">
            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">Invoice Subject</h6>
              <input
                type="text"
                v-model="params['subject']"
                @input="setFilters('subject',params['subject'])"
                class="form-control auth-input d-input"
                placeholder="Invoice Subject"
              >
            </div>

            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">Project</h6>
              <multiselect
                track-by="id"
                return="id"
                label="name"
                @input="setFilters('project',params['project'])"
                v-model="params['project']"
                :options="projects"
                :show-labels="false"
              ></multiselect>
            </div>

            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">Customer</h6>
              <multiselect
                track-by="id"
                return="id"
                label="name"
                @input="setFilters('customer',params['customer'])"
                v-model="params['customer']"
                :options="customers"
                :show-labels="false"
              ></multiselect>
            </div>

            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">Close Date (From)</h6>
              <vc-date-picker
                :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important; height:42px !important`,placeholder: `End date`}"
                v-model="params['start_from']"
                @input="setFilters('start_from',params.start_from)"
                name="end"
              />
            </div>
            <!--            <div class="col-sm-12 col-lg-2">-->
            <!--              <h6 class="text-bold text-opacity-pale">- To</h6>-->
            <!--              <vc-date-picker-->
            <!--                :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important; height:42px !important`,placeholder: `End date`}"-->
            <!--                v-model="params['end_to']"-->
            <!--                @input="setFilters('end_to',params.end_to)"-->
            <!--                name="end"-->
            <!--              />-->
            <!--            </div>-->
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["params", "customers", "visibleFilter", "projects"],
  methods: {
    setFilters(name, val) {
      val = typeof val === "object" ? (val && val.id ? val.id : val) : val;
      let data = {
        name,
        val
      };
      this.$emit("setFilters", data);
    }
  }
};
</script>
