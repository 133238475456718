<template>
  <div>
    <task-modal :availableProject="project" :hasProject="true" :task="task" v-if="ready"></task-modal>
  </div>
</template>
<script>
import TaskModal from "@/components/Modals/Tasks";
export default {
  components: { TaskModal },
  data() {
    return {
      ready:false
    };
  },
  computed: {
      task() {
          return this.$store.getters['tasks/task']
      },
    project() {
      return this.$store.getters['projects/project']
    }
  },
  created() {
    Promise.all([
      this.$store.dispatch("tasks/getSingle", this.$route.params.mid),
      this.$store.dispatch("projects/getSingle", this.$route.params.id),
        ]).finally(() => {
          this.ready = true
    })
  }
};
</script>
