<template>
  <div class="container-fluid">
    <div class="table pb-4 pl-3 pr-4">
      <div class="table-header">
        <div class="row">
          <div class="col-sm-12 col-lg-4 float-left text-left">
            <small class="text-opacity">{{paginationData.total}} total users</small>
          </div>

          <div class="col-sm-12 col-lg-8 float-right text-right">
            <users-filters
              @setFilters="setFilters"
              @showMoreFilters="showVisibleFilter"
              @removeColumnFromTable="removeColumnFromTable"
              :columns="columns"
              :params="moreParams"
              :visibleFilter="visibleFilter"
            ></users-filters>
          </div>
        </div>
        <div class="text-right mt-3" v-show="visibleFilter">
          <button
            @click="moreParams = {}"
            class="p-2 pl-3 pr-3"
            style="background:#E4E4E4; font-size:13px; border-radius:50px; border:none"
          >Reset Filter</button>
        </div>
        <users-filters-dropdown
          @setFilters="setFilters"
          :params="moreParams"
          :roles="roles"
          :visibleFilter="visibleFilter"
        ></users-filters-dropdown>
      </div>
      <div class="table-wrapper mt-4">
        <vuetable
          ref="vuetable"
          :api-url="api"
          :first-page="0"
          :append-params="moreParams"
          :http-options="{ headers:{ 'Authorization' : 'Bearer ' + $auth.token(),'xorg' : getOrganization.organization_id() } }"
          @vuetable:loaded="loadTable"
          @vuetable:cell-clicked="gotoUser"
          :fields="columns.filter(s => s.active === true)"
          data-path="data"
          pagination-path
          :no-data-template="rowCount === null ? 'Loading data' : 'No Data Available'"
          @vuetable:pagination-data="onPaginationData"
        >
          <template slot="sn" slot-scope="props">
            <router-link
              :to="{name: 'users-show', params: {id: props.rowData.user_id}}"
            >{{props.rowIndex + 1}}</router-link>
          </template>

          <template slot="actions" slot-scope="props">
            <router-link :to="{name: 'users-show', params: {id: props.rowData.user_id}}">
              <button class="btn-lightgray-round">
                <i class="zmdi zmdi-plus pr-2"></i>View User
              </button>
            </router-link>
          </template>
          <template slot="email" slot-scope="props">
            <span
              v-b-tooltip.hover
              :title="props.rowData && props.rowData.user.data && props.rowData.user.data.email"
            >{{props.rowData && props.rowData.user.data && props.rowData.user.data.email | truncate(15)}}</span>
          </template>
        </vuetable>
        <vuetable-pagination
          v-show="paginationData.total"
          :css="table_css.pagination"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
        <div class="text-center" v-if="rowCount <=0 || rowCount === null">
          <slot name="empty"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import getOrganization from "@/helpers/getOrganization";
import UsersFilters from "@/components/Filters/Users/Index";
import UsersFiltersDropdown from "@/components/Filters/Users/More";
import VuetablePagination from "@/components/Layouts/VueTablePagination";
import getPaginationData from "@/helpers/pagination";
import { toLocal } from "../../../helpers/date";
import truncate from "@/mixins/truncate";

export default {
  mixins: [truncate],
  components: {
    UsersFilters,
    UsersFiltersDropdown,
    VuetablePagination
  },
  data() {
    return {
      getOrganization,
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      api: this.$baseApi + `/employees?include=employeeType,lastLogin`,

      columns: [
        {
          name: "__checkbox",
          titleClass: "center aligned",
          dataClass: "center aligned",
          cvisible: false,
          active: true
        },
        {
          name: "user.data",
          title: "Full Name",
          cvisible: true,
          active: true,
          callback: data => {
            return data ? `${data.first_name} ${data.last_name}` : "N/A";
          }
        },

        {
          name: "user.data.full_phone_number",
          title: "Phone Number",
          cvisible: true,
          active: true,
          sortable: true
        },
        {
          name: "__slot:email",
          title: "Email Address",
          cvisible: true,
          active: true,
          sortable: true
        },
        {
          name: "is_active",
          title: "Employee Status",
          cvisible: true,
          active: true,
          callback: d => {
            return d === true ? "Active" : "Inactive";
          }
        },
        {
          name: "employee_type",
          title: "Employee Type",
          cvisible: true,
          active: true,
          callback: d => {
            return d ? d.name : "N/A";
          }
        },
        {
          name: "user.data.orgRole.name",
          title: "Role",
          cvisible: true,
          active: true
        },
        {
          name: "created_at",
          title: "Date Added",
          cvisible: true,
          active: true,
          callback: s => {
            return s ? toLocal(s).format("MM/DD/YYYY hh:mm a") : "N/A";
          }
        },
        {
          name: "user.data.last_login",
          title: "Last Login",
          cvisible: true,
          active: true,
          callback: s => {
            return s ? toLocal(s).format("MM/DD/YYYY hh:mm a") : "N/A";
          }
        },
        {
          name: "__slot:actions",
          title: "Actions",
          active: true,
          cvisible: false
        }
      ],
      moreParams: {},
      paginationData: {},
      filterColumns: []
    };
  },
  methods: {
    getPaginationData,
    onPaginationData(paginationData) {
      delete paginationData.data;
      this.paginationData = getPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    gotoUser(e) {
      this.$router.push({
        name: "users-show",
        params: { id: e.user_id }
      });
    },
    loadTable() {
      //console.log('tabledata', this.$refs.vuetable)
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
    },
    setFilters(d, l) {
      if (this.moreParams && this.moreParams[l] == "") {
        delete this.moreParams[l];
      }
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onChangePage(page) {
      this.moreParams.page = page;
      this.setFilters();
    },
    refreshTable() {
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find(s => s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    }
  },
  computed: {
    roles() {
      return this.$store.getters["global/roles"];
    }
  },
  mounted() {
    this.roles.length || this.$store.dispatch("global/getRoles");
  },
  watch: {
    $route(to, from) {
      this.refreshTable();
    }
  }
};
</script>

