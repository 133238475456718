<template>
  <add-payment-method :showPaymentModal="visible" @cancelModal="cancelModal"></add-payment-method>
</template>

<script>
import AddPaymentMethod from "@/components/Modals/AddPaymentMethod";
export default {
  components: { AddPaymentMethod },
  data() {
    return {
      visible: true
    };
  },
  methods: {
    cancelModal() {
    //   this.visible = false;
      this.$router.go(-1)
    }
  }
};
</script>
