// Response messages
const ACCOUNT_CREATE_SUCCESS = "🚀 Your account has successfully beeen created. Please Verify your email to proceed "
const RESET_PASSWORD_SUCCESS = "🚀 Your Password has successfully been updated. You can now login with your new password"
const FORGOT_PASSWORD_SUCCESS = "🚀  We have sent you an email with instructions to help you reset your Password"
const VERIFIED_EMAIL = "🎉 Your Email has Successfully been verified! You can now login into your account below"
const VERIFIED_EMAIL_FAILURE = "Failed to verify your email.";
const OTP_ERROR = "Something isnt right. A mail has been sent with an OTP to help you login";
// Page literals
const LOGIN_HEADER_CAPTION = "Sign In";
const LOGIN_SUBHEADER_CAPTION = "Login to continue enjoying Leaf's excellent benefits!";

const REGISTER_HEADER_CAPTION = "Sign Up";
const REGISTER_SUBHEADER_CAPTION = "Enjoy Excellent benefits by signing up on Leaf Today!";

const FORGOT_PASSWORD_HEADER_CAPTION = "Forgot Password";
const FORGOT_PASSWORD_SUBHEADER_CAPTION = "Request a new password by entering your email below!";

const RESET_PASSWORD_HEADER_CAPTION = "Create new Password";
const RESET_PASSWORD_SUBHEADER_CAPTION = "Update your account by changing your password!";
const WELCOME_PASSWORD_HEADER_CAPTION = "Welcome to Leaf."
const WELCOME_PASSWORD_SUBHEADER_CAPTION = "Create a password below to enable you sign in to leaf"
const FORGOT_PASSWORD_CAPTION = "Forgot your Password?";

//Form inputs
const EMAIL_INPUT_CAPTION = "Email";
const PASSWORD_INPUT_CAPTION = "Password";
const FIRST_NAME_INPUT_CAPTION = "First Name";
const LAST_NAME_INPUT_CAPTION = "Last Name";
const TELEPHONE_INPUT_CAPTION = "Telephone";
const USERNAME_INPUT_CAPTION = "Username";
const COMPANY_INPUT_CAPTION = "Company name";
const COMPANY_INPUT_ROLE = "What best describe your role?";
const COUNTRY_INPUT_CAPTION = "Country";
const NEW_PASSWORD_INPUT_CAPTION = "New Password";
const CONFIRM_PASSWORD_INPUT_CAPTION = "Confirm Password";

export default {
    ACCOUNT_CREATE_SUCCESS,
    VERIFIED_EMAIL,
    VERIFIED_EMAIL_FAILURE,
    OTP_ERROR,
    RESET_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_CAPTION,
    LOGIN_HEADER_CAPTION,
    LOGIN_SUBHEADER_CAPTION,
    EMAIL_INPUT_CAPTION,
    WELCOME_PASSWORD_HEADER_CAPTION,
    WELCOME_PASSWORD_SUBHEADER_CAPTION,
    PASSWORD_INPUT_CAPTION,
    FIRST_NAME_INPUT_CAPTION,
    LAST_NAME_INPUT_CAPTION,
    TELEPHONE_INPUT_CAPTION,
    USERNAME_INPUT_CAPTION,
    COMPANY_INPUT_CAPTION,
    COMPANY_INPUT_ROLE,
    COUNTRY_INPUT_CAPTION,
    CONFIRM_PASSWORD_INPUT_CAPTION,
    REGISTER_HEADER_CAPTION,
    REGISTER_SUBHEADER_CAPTION,
    FORGOT_PASSWORD_HEADER_CAPTION,
    FORGOT_PASSWORD_SUBHEADER_CAPTION,
    RESET_PASSWORD_HEADER_CAPTION,
    RESET_PASSWORD_SUBHEADER_CAPTION,
    NEW_PASSWORD_INPUT_CAPTION
}
