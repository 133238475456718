<template>
  <div>
    <delete-modal
      @trigger="setDeleteState"
      @cancel="cancelDelete"
      @deleteAction="deleteInvoice(selectedDeleteId)"
      :showDelete="showDelete"
      :name="showDeleteName"
      ref="delete"
    ></delete-modal>

    <div class="container-fluid">
      <div class="table pb-4 pl-3 pr-4">
        <div class="table-header">
          <div class="row">
            <div class="col-sm-12 col-lg-4 float-left text-left">
              <small class="text-opacity">{{paginationData.total}} Total invoices</small>
            </div>

            <div class="col-sm-12 col-lg-8 float-right text-right">
              <invoice-filters
                @setFilters="setFilters"
                @showMoreFilters="showVisibleFilter"
                @removeColumnFromTable="removeColumnFromTable"
                :columns="columns"
                :params="moreParams"
                :visibleFilter="visibleFilter"
              ></invoice-filters>
            </div>
          </div>
          <div class="text-right mt-3" v-show="visibleFilter">
            <button
              @click="moreParams = {}"
              class="p-2 pl-3 pr-3"
              style="background:#E4E4E4; font-size:13px; border-radius:50px; border:none"
            >Reset Filter</button>
          </div>
          <invoice-filters-dropdown
            @setFilters="setFilters"
            :params="moreParams"
            :projects="projects"
            :customers="customers"
            :visibleFilter="visibleFilter"
          ></invoice-filters-dropdown>
        </div>
        <div class="table-wrapper mt-4">
          <vuetable
            ref="vuetable"
            :api-url="api"
            :first-page="0"
            :append-params="api.includes('/invoices/search/') ? {} : moreParams"
            :http-options="{ headers:{ 'Authorization' : 'Bearer ' + $auth.token(),'xorg' : getOrganization.organization_id() } }"
            @vuetable:loaded="loadTable"
            @vuetable:cell-clicked="gotoInvoice"
            :fields="columns.filter(s => s.active === true)"
            data-path="data"
            pagination-path
            :no-data-template="rowCount === null ? 'Loading data' : 'No Data Available'"
            @vuetable:pagination-data="onPaginationData"
          >
            <template slot="sn" slot-scope="props">
              <router-link
                :to="{name: 'invoices-show', params: {id: props.rowData.id}}"
              >{{props.rowIndex + 1}}</router-link>
            </template>

            <template
              slot="amount"
              slot-scope="{rowData}"
            >{{rowData.currency && rowData.currency.symbol}} {{numberWithCommas(parseFloat(rowData.amount).toFixed(2))}}</template>
            <template slot="budget" slot-scope="props">{{props.rowData.estimated_hours}} hours</template>

            <template
              slot="amount_due"
              slot-scope="{rowData}"
            >{{rowData.currency && rowData.currency.symbol}} {{numberWithCommas(parseFloat(rowData.amount_due).toFixed(2))}}</template>
            
            <template
              slot="amount_paid"
              slot-scope="{rowData}"
            >{{rowData.currency && rowData.currency.symbol}} {{numberWithCommas(parseFloat(rowData.amount_paid).toFixed(2))}}</template>
  
            <template slot="actions" slot-scope="props">
              <router-link :to="{name: 'invoices-show', params: {id: props.rowData.id}}">
                <button class="btn-lightgray-round">
                  <i class="zmdi zmdi-plus pr-2"></i>View Invoice
                </button>
              </router-link>

              <span
                v-if="props.rowData.status_id == 1"
                id="delete"
                @click="setDeleteState('Invoice', props.rowData.id)"
                class
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#72809D"
                  viewBox="0 0 30 30"
                >
                  <path
                    d="M9 13v6c0 .552-.448 1-1 1s-1-.448-1-1v-6c0-.552.448-1 1-1s1 .448 1 1zm7-1c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm-4 0c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm4.333-8.623c-.882-.184-1.373-1.409-1.189-2.291l-5.203-1.086c-.184.883-1.123 1.81-2.004 1.625l-5.528-1.099-.409 1.958 19.591 4.099.409-1.958-5.667-1.248zm4.667 4.623v16h-18v-16h18zm-2 14v-12h-14v12h14z"
                  ></path>
                </svg>
              </span>
            </template>
          </vuetable>
          <vuetable-pagination
            v-show="paginationData.total"
            :css="table_css.pagination"
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          ></vuetable-pagination>
          <div class="text-center" v-if="rowCount <=0 || rowCount === null">
            <slot name="empty"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
span#delete {
  margin-left: 20px;
}
</style>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import getOrganization from "@/helpers/getOrganization";
import InvoiceFilters from "@/components/Filters/Invoices/Index";
import InvoiceFiltersDropdown from "@/components/Filters/Invoices/More";
import VuetablePagination from "@/components/Layouts/VueTablePagination";
import getPaginationData from "@/helpers/pagination";
import { numberWithCommas } from "@/helpers/number";
import DeleteModal from "@/components/Modals/Delete";
import { toLocal } from "@/helpers/date";

export default {
  components: {
    InvoiceFilters,
    InvoiceFiltersDropdown,
    VuetablePagination,
    DeleteModal
  },
  data() {
    return {
      getOrganization,
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      api: `${process.env.VUE_APP_BASE_URI}/invoices`,
      columns: [
        {
          name: "__slot:sn",
          title: "S/N",
          cvisible: true,
          active: true
        },
        {
          name: "subject",
          title: "Subject",
          cvisible: true,
          active: true
        },
        {
          name: "customer.data",
          title: "Customer",
          cvisible: true,
          active: true,
          callback: s => {
            return s ? s.company_name : "N/A";
          }
        },
        {
          name: "__slot:amount",
          title: "Amount",
          cvisible: true,
          active: true,
          sortable: true
        },
         {
          name: "__slot:amount_paid",
          title: "Amount Paid",
          sortField: "amount_paid",
          cvisible: true,
          active: true
        },
         {
          name: "__slot:amount_due",
          title: "Amount Due",
          sortField: "amount_due",
          cvisible: true,
          active: true
        },
        {
          name: "creator.data",
          title: "Creator",
          cvisible: true,
          active: true,
          callback: m => {
            return m ? m.first_name + " " + m.last_name : "N/A";
          }
        },
         {
          name: "due_date",
          title: "Due Date",
          cvisible: true,
          active: true,
          callback: s => {
            return s ? toLocal(s).format("MM/DD/YYYY") : "N/A";
          }
        },
        {
          name: "created_at",
          title: "Created At",
          cvisible: true,
          active: true,
          callback: s => {
            return s ? toLocal(s).format("MM/DD/YYYY") : "N/A";
          }
        },
        {
          name: "end_at",
          title: "End At",
          cvisible: true,
          active: false,
          callback: s => {
            return s ? toLocal(s).format("MM/DD/YYYY") : "N/A";
          }
        },
        {
          name: "status",
          title: "Status",
          cvisible: true,
          active: true,
          callback: s => {
            return s ? s.name.toUpperCase() : "N/A";
          }
        },
        {
          name: "__slot:actions",
          title: "Actions",
          active: true,
          cvisible: false
        }
      ],
      moreParams: {
        include: "customer"
      },
      paginationData: {},
      filterColumns: [],
      showDelete: false,
      showDeleteName: "",
      selectedDeleteId: ""
    };
  },
  methods: {
    numberWithCommas,
    getPaginationData,
    toLocal,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    gotoInvoice(e) {
      this.$router.push({
        name: "invoices-show",
        params: { id: e.id }
      });
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
      this.$emit("setInvoiceTotal", this.$refs.vuetable.tablePagination.data);
    },
    setFilters(d, l) {
      if (this.moreParams && this.moreParams[l] == "") {
        delete this.moreParams[l];
      }
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onChangePage(page) {
      this.moreParams.page = page;
      this.setFilters();
    },
    refreshTable() {
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find(s => s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    },
    deleteInvoice(invoiceId) {
      this.$store.dispatch("invoices/delete", invoiceId).then(s => {
        this.showDelete = false;
        this.$refs.vuetable.reload();
        this.$toast.success("🚮 Invoice Successfully Deleted", {
          position: "top-right",
          duration: 3000
        });
      });
    },
    setDeleteState(name, id) {
      this.showDelete = true;
      this.showDeleteName = name;
      this.selectedDeleteId = id;
    },
    cancelDelete() {
      this.showDelete = false;
    }
  },
  computed: {
    projects() {
      return this.$store.getters["projects/projects_simple"];
    },
    customers() {
      return this.$store.getters["customers/customers_simple"];
    }
  },
  created() {
    this.projects.length ||
      this.$store.dispatch("customers/index", { per_page: 500 });
    this.customers.length ||
      this.$store.dispatch("projects/index", { per_page: 500 });
  }
};
</script>

