export default {
    SET_EMPLOYEES(state, array) {
        state.employees = array;
    },
    SET_BANK_ACCOUNTS(state, array) {
        state.bank_accounts = array;
    },
    SET_EMPLOYEE(state, array) {
        state.employee = array;
    },
    SET_USER(state, array) {
        state.user = array;
    },
    SET_EMPLOYEE_USERS(state, array) {
        state.employee_users = array;
    },
    SET_BANK_ACCOUNT(state, array) {
        state.bank_account = array;
    },
    ADD_USER(state, array) {
        if(state.users.length) {
            let users = state.users;
            users.push(array);
            state.users = users;
        }
    },

}
