<template>
  <div>
    <customer-modal :customer="customer" v-if="customer.id"></customer-modal>
  </div>
</template>
<script>
  import CustomerModal from "@/components/Modals/Customers";
  import moment from "moment";
  export default {
    title: `Edit | Customers`,
    data() {
      return {
        moment
      };
    },
    components: { CustomerModal },
    computed: {
      customer() {
        return this.$store.getters["customers/customer"];
      },
    },
    created() {
      this.$store.dispatch("customers/getSingle", this.$route.params.id);
    }
  };
</script>
