<template>
  <div>
    <task-modal :hasProject="false"></task-modal>
  </div>
</template>
<script>
import TaskModal from "@/components/Modals/Tasks";
export default {
  title: 'New | Tasks',
  components: { TaskModal },
  data() {
    return {
    };
  },
  computed: {

  },
  mounted() {
  }
};
</script>
