<template>
  <div>
    <div>
      <nav
        style="opacity:.6; user-select:none; pointer-events:none"
        class="navbar navbar-dashboard navbar-expand-lg navbar-light bg-light pb-3 pt-1"
      >
        <div class="container-fluid pl-4 pr-4">
          <a class="navbar-brand" href="#">
            <img src="@/assets/img/leaf.svg" class="logo-main">
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-5 error-link ml-auto" style="width:unset !important">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <div class="text-center mt-5 pt-4">
      <img src="@/assets/img/09.png" style="width:15%">
      <h1 class="text-opacity-pale pt-3">Page not found.</h1>
      <div class="mt-4">
        <h6 class="text-opacity">We couldn't find the page you are looking for.</h6>
        <h6 class="text-opacity">You can go back to Homepage if this is a mistake.</h6>
      </div>
      <button @click="() => $router.go(-2)" class="btn pl-4 pr-4 btn-ds mt-4">
        <i class="zmdi zmdi-long-arrow-left text-right pr-4"></i>
        Go back
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.error-link {
  list-style-type: none;
  display: flex;
  align-items: center;
  li {
    background: #e4e4e4;
    width: 100px;
    height: 25px;
    border-radius: 5px;
    margin-left: 10px;
    margin-top: 10px;
  }
}
</style>

<script>
export default {
  title: "Page could not be found",
  data() {
    return {

    };
  },
  computed: {
    disabled() {
      return !this.$auth.check()
    }
  },
  mounted() {}
};
</script>
