<template>
  <div>
    <transition name="slide-fade">
      <div class="filters-form" v-show="visibleFilter">
        <form class="d-form">
          <div class="row mt-3">
            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">First Name</h6>
              <div style="position:relative">
                <input
                  type="text"
                  v-model="params['first_name']"
                  @input="setFilters('first_name')"
                  class="form-control auth-input d-input"
                  placeholder="User First name"
                >
                <span class="close-icon" v-if="params && params.first_name">
                  <i
                    class="zmdi zmdi-close"
                    @click="params['first_name']='';setFilters('first_name')"
                  ></i>
                </span>
              </div>
            </div>

            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">Phone Number</h6>
              <div style="position:relative">
                <input
                  type="tel"
                  v-model="params['phone_number']"
                  name="User_phone"
                  @input="setFilters('phone_number')"
                  class="form-control auth-input d-input"
                  placeholder="User phone"
                >
                <span class="close-icon" v-if="params && params.phone_number">
                  <i
                    class="zmdi zmdi-close"
                    @click="params['phone_number']='';setFilters('phone_number')"
                  ></i>
                </span>
              </div>
            </div>
            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">Email</h6>
              <div style="position:relative">
                <input
                  type="email"
                  v-model="params['email']"
                  name="User_email"
                  @input="setFilters('email')"
                  class="form-control auth-input d-input"
                  placeholder="User email address"
                >
                <span class="close-icon" v-if="params && params.email">
                  <i class="zmdi zmdi-close" @click="params['email']='';setFilters('email')"></i>
                </span>
              </div>
            </div>
            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">Role</h6>
              <div style="position:relative">
                <multiselect
                  @input="setFilters('role_id')"
                  class="no-caret-select"
                  v-model="params['role_id']"
                  :custom-label="opt => roles.find(x => x.id == opt).name"
                  :options="roles.map(type=>type.id)"
                  :show-labels="false"
                >
                  <template slot="caret">
                    <span class="close-icon" v-if="params && params.role_id">
                      <i
                        class="zmdi zmdi-close"
                        @click="params['role_id']='';setFilters('role_id')"
                      ></i>
                    </span>
                    <div v-else>
                      <div class="multiselect__select">
                        <i class="zmdi zmdi-chevron-down pt-1" style="font-size: 20px"></i>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>

            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">Registration Date (From)</h6>
              <div style="position:relative">
                <vc-date-picker
                  :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important; height:42px !important`,placeholder: `Start From date`}"
                  v-model="params['filter_start']"
                  @input="setFilters('filter_start');params['range_filter'] = 'created_at'"
                  name="end"
                />
                <span class="close-icon" v-if="params && params.filter_start">
                  <i
                    class="zmdi zmdi-close"
                    @click="params['filter_start']='';setFilters('filter_start')"
                  ></i>
                </span>
              </div>
            </div>
            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">- To</h6>
              <div style="position:relative">
                <vc-date-picker
                  :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important; height:42px !important`,placeholder: `End To date`}"
                  v-model="params['filter_end']"
                  @input="setFilters('filter_end');params['range_filter'] = 'created_at'"
                  name="end"
                />
                <span class="close-icon" v-if="params && params.filter_end">
                  <i
                    class="zmdi zmdi-close"
                    @click="params['filter_end']='';setFilters('filter_end')"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["params", "User", "status", "settlement", "visibleFilter", "roles"],
  methods: {
    setFilters(name, val) {
      this.$emit("setFilters", this.params, name);
    }
  }
};
</script>
