<template>
  <div class="mb-5" :class="{blur:!loaded}">
    <div class="row mt-3" v-if="milestones.length">
      <div class="col-sm-12 col-lg-6 mb-5" v-for="(milestone,index) in milestones" :key="index">
        <h1 class="h1-overlap">{{index+1}}</h1>
        
        <card>
          <div slot="header" style="height:100% !important">
            <div class="row milestone">
              <div class="col-sm-12 col-lg-1">
                <div
                  :id="milestoneStatus(milestone.status.name)"
                  v-b-tooltip.hover
                  :title="milestone.status.name"
                  class="milestone-summary d-flex flex-row align-items-center justify-content-center"
                >
                  <span v-if="milestoneStatus(milestone.status.name) == 'done'">
                    <svg
                      width="24"
                      height="24"
                      fill="#FFF"
                      xmlns="http://www.w3.org/2000/svg"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                    >
                      <path
                        d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm7 7.457l-9.005 9.565-4.995-5.865.761-.649 4.271 5.016 8.24-8.752.728.685z"
                      ></path>
                    </svg>
                  </span>
                  <span v-if="milestoneStatus(milestone.status.name) == 'in-progress'">
                    <svg
                      fill="#FFFFFF"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M8.175 7.377l-3.042-5.27 1.732-1 3.045 5.273c-.635.238-1.222.573-1.735.997zm-.799.8l-5.27-3.042-1 1.732 5.274 3.045c.237-.635.572-1.223.996-1.735zm-1.376 3.823c0-.341.035-.673.09-.999h-6.09v1.999h6.09c-.055-.326-.09-.659-.09-1zm11.351-2.705l5.208-3.007-.333-.577-5.206 3.007c.121.185.23.379.331.577zm-5.351-3.295c.341 0 .673.035.999.09v-6.09h-1.999v6.09c.326-.055.659-.09 1-.09zm3.14.894l3.004-5.204-.288-.166-3 5.197.284.173zm1.685 8.662l5.234 3.022.666-1.154-5.229-3.019c-.181.41-.408.794-.671 1.151zm-10.444-1.467l-5.274 3.046 1 1.732 5.27-3.042c-.424-.513-.759-1.1-.996-1.736zm11.594-2.589l.025.5-.025.5h6.025v-1h-6.025zm-3.727 6.061l3.03 5.249 1.442-.833-3.031-5.25c-.437.34-.92.623-1.441.834zm-2.248.439c-.341 0-.674-.035-1-.09v6.09h1.999v-6.09c-.326.055-.658.09-.999.09zm-3.824-1.376l-3.042 5.27 1.732 1 3.045-5.274c-.635-.237-1.222-.572-1.735-.996z"
                      ></path>
                    </svg>
                  </span>
                  <span v-if="milestoneStatus(milestone.status.name) == 'review'">
                    <svg
                      width="24"
                      height="24"
                      xmlns="http://www.w3.org/2000/svg"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                    >
                      <path
                        d="M21.62 20.196c1.055-.922 1.737-2.262 1.737-3.772 0-1.321-.521-2.515-1.357-3.412v-6.946l-11.001-6.066-11 6v12.131l11 5.869 5.468-2.917c.578.231 1.205.367 1.865.367.903 0 1.739-.258 2.471-.676l2.394 3.226.803-.596-2.38-3.208zm-11.121 2.404l-9.5-5.069v-10.447l9.5 4.946v10.57zm1-.001v-10.567l5.067-2.608.029.015.021-.04 4.384-2.256v5.039c-.774-.488-1.686-.782-2.668-.782-2.773 0-5.024 2.252-5.024 5.024 0 1.686.838 3.171 2.113 4.083l-3.922 2.092zm6.833-2.149c-2.219 0-4.024-1.808-4.024-4.026s1.805-4.025 4.024-4.025c2.22 0 4.025 1.807 4.025 4.025 0 2.218-1.805 4.026-4.025 4.026zm-.364-3.333l-1.306-1.147-.66.751 2.029 1.782 2.966-3.12-.725-.689-2.304 2.423zm-16.371-10.85l4.349-2.372 9.534 4.964-4.479 2.305-9.404-4.897zm9.4-5.127l9.404 5.186-3.832 1.972-9.565-4.98 3.993-2.178z"
                      ></path>
                    </svg>
                  </span>
                  <span v-if="milestoneStatus(milestone.status.name) == 'pending'">
                    <svg
                      fill="#FFFFFF"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M15.728 3l5.272 5.272v7.456l-5.272 5.272h-7.456l-5.272-5.272v-7.456l5.272-5.272h7.456zm8.272 4.029v9.941l-7.029 7.03h-9.942l-7.029-7.029v-9.942l7.029-7.029h9.941l7.03 7.029zm-2 .829l-5.858-5.858h-8.284l-5.858 5.858v8.284l5.858 5.858h8.284l5.858-5.858v-8.284z"
                      ></path>
                    </svg>
                  </span>
                  <span v-if="milestoneStatus(milestone.status.name) == 'cancelled'">
                    <svg
                      fill="#FFFFFF"
                      width="24"
                      height="24"
                      xmlns="http://www.w3.org/2000/svg"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                    >
                      <path
                        d="M7 5h17v16h-17l-7-7.972 7-8.028zm7 6.586l-2.586-2.586-1.414 1.414 2.586 2.586-2.586 2.586 1.414 1.414 2.586-2.586 2.586 2.586 1.414-1.414-2.586-2.586 2.586-2.586-1.414-1.414-2.586 2.586z"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>
              <div class="col-sm-12 col-lg-8">

                <h6 class="h6 text-bold long-text-wrapper milestone-header m-0">{{milestone.name}} <router-link :to="{name: 'tasks-create',query:{from: $route.path, milestone_id: milestone.id, project_id: milestone.project_id}}">
          <button
                 
                    class="btn btn-primary auth-btn"
                  >
                    <div>Create Task</div>
                  </button>
        </router-link></h6>
                
 <ul class="d-flex flex-row pt-1">
                  <li class="milestone-status d-flex pr-3 flex-row align-items-center">
                    <span class="milestone-status__icons done mr-2"></span>
                    <span>
                      <small>
                        <b
                          class="text-bold"
                        >{{getMetrics(milestone.tasks && milestone.tasks.data,4)}}</b> Done
                      </small>
                    </span>
                  </li>
                  <li class="milestone-status d-flex pr-3 flex-row align-items-center">
                    <span class="milestone-status__icons review mr-2"></span>
                    <span>
                      <small>
                        <b
                          class="text-bold"
                        >{{getMetrics(milestone.tasks && milestone.tasks.data,3)}}</b> Review
                      </small>
                    </span>
                  </li>
                  <li class="milestone-status d-flex pr-3 flex-row align-items-center">
                    <span class="milestone-status__icons in-progress mr-2"></span>
                    <span>
                      <small>
                        <b
                          class="text-bold"
                        >{{getMetrics(milestone.tasks && milestone.tasks.data,2)}}</b> In-Progress
                      </small>
                    </span>
                  </li>
                  <li class="milestone-status d-flex pr-3 flex-row align-items-center">
                    <span class="milestone-status__icons pending mr-2"></span>
                    <span>
                      <small>
                        <b
                          class="text-bold"
                        >{{getMetrics(milestone.tasks && milestone.tasks.data,1)}}</b> Pending
                      </small>
                    </span>
                  </li>
                </ul>
              </div>
              <div class="col-sm-12 col-lg-3 mt-2">
                <ul
                  v-if="getAssignees(milestone && milestone.tasks && milestone.tasks.data).length > 0"
                  class="milestone-team d-flex flex-row align-items-center justify-content-center"
                >
                  <li
                    v-b-tooltip.hover
                    v-for="(user,index) in getAssignees(milestone.tasks.data).slice(0,4)"
                    :key="index"
                    :title="user.user.data.first_name + ' ' + user.user.data.last_name"
                    class="d-flex flex-row align-items-center justify-content-center p-1 mr-1"
                  >{{user.user && user.user.data && user.user.data.first_name | firstChar}}{{user.user && user.user.data && user.user.data.last_name | firstChar}}</li>
                  <li
                    v-b-tooltip.hover.html="tooltipAllUsers"
                    v-if="getAssignees.length > 4"
                    class="d-flex flex-row align-items-center justify-content-center p-1 mr-1"
                  >+{{getAssignees(milestone.tasks.data).length - 4}}</li>
                </ul>
              </div>
            </div>
          </div>
          <div slot="content" class="tasks">
            <ul
              class="d-flex flex-column"
              v-if="milestone.tasks && milestone.tasks.data && milestone.tasks.data.length > 0"
            >
              <li
                v-for="(task,taskIndex) in milestone.tasks.data"
                :key="taskIndex"
                :id="task && task.task_status_id == 1 ? 'pending' :  task && task.task_status_id== 2 ? 'in-progress' : task && task.task_status_id == 4 ? 'done' : 'review'"
                class="p-2 pl-4 task done d-flex flex-row justify-content-between align-items-center"
              >
                <div class="long-text-wrapper">
                  <h6
                    class="text-opacity-pale h7 mb-0"
                    v-b-tooltip.hover
                    :title="task.name"
                  >{{task.name}}</h6>
                </div>
                <div>
                  <button v-if="optional(task, 'employee.data', false)"
                    class="mr-3 profile-avatar"
                    v-b-tooltip.hover
                    :title="optional(task, 'employee.data.user.data', null) ? `${task.employee.data.user.data.first_name} ${task.employee.data.user.data.last_name}` : 'N/A'"
                  >{{optional(task, 'employee.data.user.data.first_name', '') | firstChar}}{{optional(task, 'employee.data.user.data.last_name', '') | firstChar}}</button>
                  <router-link
                    :to="{
                      name: 'milestone-tasks-preview-show',params: {tid: task.id}, 
                    }"
                  >
                    <button class="away-link">
                      <i class="zmdi zmdi-arrow-right-top"></i>
                    </button>
                  </router-link>
                </div>
              </li>
            </ul>
            <ul class="d-flex flex-column" v-else>
              <li class="text-center task d-flex flex-column p-4">
                <small class="text-opacity-pale text-bold">No Task has been added Yet</small>
              </li>
            </ul>
          </div>
          <div class slot="footer">
            <router-link :to="{name:'milestones', params:{mid:milestone.id}}">
              <h6 class="h7 text-opacity-pale text-bold text-center text-main">See All Tasks</h6>
            </router-link>
          </div>
        </card>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-sm-12 text-center">
        <small class="text-opacity-pale text-bold">No Milestone has been added Yet!</small>
      </div>
    </div>
    <div class="mt-5 pl-2 scrollable" style="height:60vh !important">
      <router-view></router-view>
    </div>
  </div>
</template>
<style scoped>
div.card#s-card .card-header {
  height: 100% !important;
}
</style>

<script>
import Card from "@/components/Cards/Index";
import random from "@/helpers/random";
import firstChar from "@/mixins/firstChar";
import { optional } from "@/helpers/random";

export default {
  mixins: [firstChar],
  data() {
    return {
      loaded: false
    };
  },
  methods: {
    optional,
    getMetrics(data, type) {
      let metrics = data.filter(s => s && s.task_status_id == type);
      return metrics.length;
    },
    getAssignees(data) {
      let assignees = [];
      data = data || [];
      data.filter(s => {
        if (
          optional(s, "employee.data", null) &&
          !random.inArray(s.employee.data, assignees)
        ) {
          assignees.push(s.employee.data);
        }
      });
      return assignees;
    },
    tooltipAllUsers() {
      return this.getAssignees().map(s => {
        return "<strong>Uepp0L</strong>";
      });
    },
    milestoneStatus(status) {
      //temporal fix for now
      if (status == "Not Started") {
        return "pending";
      } else if (status == "Done") {
        return "done";
      } else if (status == "Testing/Review") {
        return "review";
      } else if (status == "In Progress") {
        return "in-progress";
      } else {
        return "cancelled";
      }
    }
  },
  computed: {
    milestones() {
      return this.$store.getters["milestones/milestones"];
    }
  },
  created() {
    this.$store
      .dispatch("milestones/getMilestones", this.$route.params.id)
      .then(() => {
        this.loaded = true;
      });
  },
  components: { Card }
};
</script>