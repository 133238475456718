<template>
  <div class>
    <div class="table-wrapper">
      <vuetable
        ref="vuetable"
        :api-url="api"
        :http-options="{ headers:{ 'Authorization' : 'Bearer ' + $auth.token(),'xorg' : getOrganization.organization_id() } }"
        @vuetable:loaded="loadTable"
        :fields="columns"
        data-path="data"
        pagination-path=""
        :no-data-template="rowCount === null ? 'Loading data' : 'No Data Available'"
        @vuetable:pagination-data="onPaginationData"
      >
        <template slot="id" slot-scope="props">
          <router-link
                  v-b-tooltip.hover
                  title="Goto Task"
                  :to="{name: 'user-tasks-preview-show', params: {tid: props.rowData.id}}"
          >{{props.rowData.name}}
          </router-link>
        </template>

      </vuetable>
      <vuetable-pagination v-show="paginationData.total"
                           :css="table_css.pagination"
                           ref="pagination"
      ></vuetable-pagination>
      <div class="text-center" v-if="rowCount <=0 || rowCount === null">
        <slot name="empty"></slot>
      </div>

    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import getOrganization from "@/helpers/getOrganization";
import VuetableCss from "@/helpers/vuetable_styling.js";
import getPaginationData from "@/helpers/pagination.js";
import VuetablePagination from "@/components/Layouts/VueTablePagination"
export default {
  title: 'Index | Tasks | Customer',
  components: {VuetablePagination},
  data() {
    return {
      columns: [
        {
          name: "__slot:id",
          title: "Name",
          sortField: "name",
          cvisible: true,
          active: true
        },
        {
          name: "description",
          title: "Description",
          cvisible: true,
          active: true,
        },
        {
          name: "project",
          title: "Project",
          cvisible: true,
          active: true,
          callback: p => {
            return p ? p.name : 'N/A'
          }
        },
        {
          name: "hours_logged",
          title: "Hours Logged",
          sortField: "hours_logged",
          cvisible: true,
          active: true,
        },
        {
          name: "status",
          title: "Status",
          cvisible: true,
          active: true,
          callback: s => {
            return s  ? s.name : 'N/A'
          }
        },
      ],
      rowCount: null,
      table_css: VuetableCss,
      getOrganization,
      paginationData: {},
    };
  },
  computed: {
    employee() {
      return this.$store.getters["employees/employee"];
    },
    user() {
      return this.$store.getters["employees/user"]
    },
    api() { return `${this.$baseApi}/tasks?employee_id=${this.employee.id}&include=project`}
  },
  methods: {
    getPaginationData,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData);
     this.$refs.pagination.setPaginationData(this.paginationData)
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
    },
  },
  mounted() {

  }
};
</script>

