<template>
  <transition name="fade-slide">
    <div v-if="showNotifBar">
       <div
        v-if="subscription && subscription.stripe_status !== 'active'"
        class="
          subscribe-wrapper
          d-flex
          flex-row
          justify-content-between
          pl-5
          pr-5
          align-items-center
        "
      >
        <div></div>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <h6 class="text-bold text-opacity-pale text-center mt-0 mb-0">
            You are currently on Trial Mode. Upgrade to enjoy more benefits on
            Leaf
          </h6>
          <router-link :to="{ name: 'subcriptions-active' }"
            ><button class="upgrade ml-5">
              <h6 class="text-opacity-pale mb-0 text-bold text-white pl-3 pr-3">
                Upgrade
              </h6>
            </button></router-link
          >
        </div>
        <button @click="closeBanner" class="close-x">
          <i class="zmdi zmdi-close-circle"></i>
        </button>
      </div>
    </div>
   
  </transition>
</template>

<style lang="scss" scoped>
.subscribe-wrapper {
  height: 60px;
  background: #583c1261;
}
.close-x {
  background: transparent;
  border: none;
  font-size: 20px;
  color: white;
  opacity: 0.6;
}
.upgrade {
  border: none;
  border-radius: 5px;
  background: #583c1261;
  height: 30px;
  &:hover {
    opacity: 0.7;
  }
}
</style>

<script>
import subscription from "@/mixins/subscription";

export default {
  mixins: [subscription],
  data() {
    return {
      showNotifBar: true,
      fetching: true,
    };
  },
  methods:{
    closeBanner() {
      this.showNotifBar = false;
    }
  
  },
  mounted() {
   
  }
};
</script>