<template>
  <b-modal
    @hide="close"
    :no-close-on-backdrop="true"
    :visible="showExpenseForm"
    id="expenseModal"
    ref="expenseModal"
  >
    <template v-slot:modal-header>
      <h2 class="pl-1 pt-3 pr-3 f-24">{{ "Log Expense" }}</h2>
      <button class="close" @click="close">
        Cancel
        <i class="zmdi zmdi-close-circle pl-2" style="font-size: 20px"></i>
      </button>
    </template>
    <div class="d-block pb-4">
      <div class="mt-3">
        <div class="expense-upload-wrapper">
          <vue-dropzone
            ref="attachments"
            id="dropzone"
            :options="dropzoneOptions"
            :useCustomSlot="true"
            @vdropzone-files-added="setFileStatus"
            @vdropzone-total-upload-progress="setProgressStatus"
            @vdropzone-success="updateFileStatus"
          >
            <div class="dropzone-custom-content">
              <h6 class="h6">Drag-n-drop your file or</h6>
              <button class="btn btn-primary" style="width: 100%">
                Choose File
              </button>
            </div>
          </vue-dropzone>
        </div>
        <form>
          <div class="row auth-form">
            <div class="col-sm-12 col-lg-12"></div>
            <div class="col-sm-12 col-lg-12 mt-3">
              <!-- this should autofill if task exist -->
              <h6 class="text-bold text-opacity-pale">
                Subject
                <b>*</b>
              </h6>
              <input
                type="text"
                name="subject"
                v-model="body.subject"
                v-validate="'required'"
                class="form-control auth-input"
                placeholder="Tell us about the Expense"
              />
              <small
                class="form-text text-danger"
                v-show="errors.has('subject')"
                >{{ errors.first("subject") }}</small
              >
            </div>
            <div class="col-sm-12 col-lg-12 mt-3" v-if="!optional(task, 'project.data', optional(task, 'project', null))">
              <!-- this should autofill if task exist -->
              <h6 class="text-bold text-opacity-pale">
                Project
                <b>*</b>
              </h6>
              <multiselect
                track-by="id"
                label="name"
                v-model="body.project"
                v-validate="'required'"
                class="no-caret-select"
                @input="setCurrency"
                name="project"
                :options="projects"
                :show-labels="false"
              >
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <i
                        class="zmdi zmdi-chevron-down pt-1"
                        style="font-size: 20px"
                      ></i>
                    </div>
                  </div>
                </template>
              </multiselect>
              <small
                class="form-text text-danger"
                v-show="errors.has('project')"
                >{{ errors.first("project")}}</small
              >
            </div>
            <div class="col-sm-12 mt-3">
              <div class="row">
                <div class="col-sm-6">
                  <h6 class="text-bold text-opacity-pale">
                    Expense Currency
                    <b>*</b>
                  </h6>
                  <multiselect
                    track-by="id"
                    label="name"
                    :disabled="true"
                    v-model="body.currency"
                    v-validate="'required'"
                    class="no-caret-select"
                    name="currency"
                    :options="currencies"
                    :show-labels="false"
                  >
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <i
                            class="zmdi zmdi-chevron-down pt-1"
                            style="font-size: 20px"
                          ></i>
                        </div>
                      </div>
                    </template>
                  </multiselect>
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('currency')"
                    >{{ errors.first("currency") }}</small
                  >
                </div>
                <div class="col-sm-6">
                  <h6 class="text-bold text-opacity-pale">
                    Expense Amount
                    <b>*</b>
                  </h6>
                  <input
                    type="number"
                    name="amount"
                    v-validate="'required'"
                    v-model="body.amount"
                    class="form-control auth-input"
                    placeholder="Amount spent"
                  />
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('amount')"
                    >{{ errors.first("amount") }}</small
                  >
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-lg-12 mt-3">
              <h6 class="text-bold text-opacity-pale">
                Expense Date
                <b>*</b>
              </h6>
              <vc-date-picker
                :masks="{ input: ['YYYY-MM-DD'] }"
                :input-props="{
                  class: `d-input no-margin-top auth-input pl-3`,
                  style: `margin-top:0px !important; background:white !important`,
                  placeholder: `Expense Date`,
                }"
                v-validate="'required'"
                v-model="body.expense_date"
                name="date"
                data-vv-as="Expense Start date"
              />
              <small class="dpicker-icon">
                <i class="zmdi zmdi-calendar"></i>
              </small>
              <small
                class="form-text text-danger"
                v-show="errors.has('expense_date')"
                >{{ errors.first("expense_date") }}</small
              >
            </div>
            <div class="col-sm-12 col-lg-12 mt-3">
              <h6 class="text-bold text-opacity-pale">
                Expense Category
                <b>*</b>
              </h6>
              <multiselect
                track-by="id"
                label="name"
                v-validate="'required'"
                class="no-caret-select"
                name="expense_category"
                v-model="body.category"
                :options="categories"
                :show-labels="false"
              >
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <i
                        class="zmdi zmdi-chevron-down pt-1"
                        style="font-size: 20px"
                      ></i>
                    </div>
                  </div>
                </template>
              </multiselect>
              <small
                class="form-text text-danger"
                v-show="errors.has('expense_category')"
                >{{ errors.first("expense_category") }}</small
              >
            </div>

            <div class="col-sm-12 col-lg-12 mt-3" v-if="!userEmployeeId">
              <h6 class="text-bold text-opacity-pale">Employee</h6>
              <multiselect
                track-by="id"
                label="name"
                class="no-caret-select"
                name="employee_id"
                v-model="body.employee"
                :options="employees"
                :show-labels="false"
              >
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <i
                        class="zmdi zmdi-chevron-down pt-1"
                        style="font-size: 20px"
                      ></i>
                    </div>
                  </div>
                </template>
              </multiselect>
              <small
                class="form-text text-danger"
                v-show="errors.has('employee_id')"
                >{{ errors.first("employee_id") }}</small
              >
            </div>

            <div class="col-sm-12 col-lg-12 mt-3">
              <h6 class="text-bold text-opacity-pale">Expense description</h6>

              <quill-editor
                ref="myQuillEditor"
                class="mt-1"
                v-model="body.description"
                @ready="_html(body.description)"
                placeholder="Detailed description of expense"
              />
              <small
                class="form-text text-danger"
                v-show="errors.has('description')"
                >{{ errors.first("description") }}</small
              >
            </div>
            <div class="col-sm-6 col-lg-6 mt-3">
              <input
                style="margin-left: 10px"
                name="is_reimbursed"
                id="isReim"
                type="checkbox"
                v-model="body.is_reimbursed"
              />
              <label for="isReim" class="pl-2">
                <small>Is Reimbursable</small>
              </label>
              <small
                class="form-text text-danger"
                v-show="errors.has('is_reimbursed')"
                >{{ errors.first("is_reimbursed") }}</small
              >
            </div>
            <div class="col-sm-6 col-lg-6 mt-3">
              <input
                style="margin-left: 10px"
                name="is_billable"
                id="isBill"
                type="checkbox"
                v-model="body.is_billable"
              />
              <label for="isBill" class="pl-2">
                <small>Is Billable</small>
              </label>
              <small
                class="form-text text-danger"
                v-show="errors.has('is_billable')"
                >{{ errors.first("is_billable") }}</small
              >
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <div v-if="showLogForm">
        <button class="btn btn-primary" @click="save">
          {{ body.id ? "Update" : "Create" }}
        </button>
        <button
          class="btn btn-primary text-main"
          @click="close"
          style="background: transparent"
        >
          Cancel
        </button>
      </div>
      <div v-else>
        <button class="btn btn-primary" @click="close">Hide Timer</button>
      </div>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped>
.modal-dialog {
  display: none;
  max-width: 400px !important;
}

.timer-countdown {
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #2c3f58;
}

.upload-wrapper .dropzone.dz-clickable {
  background: red;
}

.stopwatch {
  width: 158px;
  height: 36px;
  border-radius: 4px;
  background-color: #d7e1ea;
  border: 0px !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #778699;

  svg {
    g {
      &#time-stopwatch {
        stroke: #778699;
      }
    }
  }

  &.stop-green {
    background-color: #639100;
    color: white;

    svg {
      g {
        &#time-stopwatch {
          stroke: #fff;
        }
      }
    }
  }

  &.stop-red {
    &:hover {
      background-color: #a22a2a;
      color: white;

      svg {
        g {
          &#time-stopwatch {
            stroke: #fff;
          }
        }
      }
    }
  }
}
</style>

<script>
import { toLocal, toUTC } from "@/helpers/date";
import handleError from "@/helpers/error";
import {optional} from "@/helpers/random";

export default {
  props: ["showExpenseForm", "task", "expense"],
  data() {
    return {
      loaded: false,
      showLogForm: true,
      uploading: false,
      uploaded: false,
      is_billable: true,
      uploadNumber: 0,
      uploadedFiles: [],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 100,
        thumbnailHeight: 50,
        maxFilesize: 5,
        headers: { "My-Awesome-Header": "header value" },
      },
      body: {
        is_reimbursed: true,
        is_billable: true,
        employee: {},
         currency: {},
      },
    };
  },
  methods: {
    optional,
    toLocal,
    toUTC,
    setTimeState() {
      this.$emit("trigger");
    },
    close() {
      this.body = {};
      this.$emit("cancelExpense", this.body);
    },
    startTimer() {
      this.showLogForm = false;
    },
    fetchFiles() {
      return this.uploadedFiles.push(this.$refs.attachments.getAcceptedFiles());
    },
    setFileStatus(file) {
      this.uploading = true;
      this.uploaded = false;
      this.uploadNumber = 0;
    },
    setProgressStatus(f, i, b) {
      this.uploadNumber = f;
    },
    updateFileStatus(f) {
      this.uploaded = true;
      this.uploading = false;
      f["created_at"] = Date.now();
      this.uploadedFiles.push(f);
    },
    setCurrency() {
      this.body.currency = this.body.project.currency;
    },
    formatData() {
      this.body.currency = this.currency;
      this.body.is_billable = true;
      this.body.is_reimbursed = true;
      if (this.expense && this.expense.id) {
        Object.assign(this.body, this.expense);
        this.body.start_time = toLocal(this.expense.start_time).format();
        this.body.end_time = toLocal(this.expense.end_time).format();
        this.body.expense_date = new Date(this.expense.expense_date);

        let employee = this.employees.find(
          (s) => s.id == this.expense.employee_id
        );
        this.body["employee"] = employee;
      }
     
    },
    save() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
      let data = {
        subject: this.body.subject,
        task_id: this.task.id,
        description: this.body.description,
        is_reimbursed: this.body.is_reimbursed ? 1 : 0,
        is_billable: this.body.is_billable ? 1 : 0,
        project_id: this.task ? this.task.project_id : this.body.project.id,
        employee_id: this.userEmployeeId
          ? this.userEmployeeId
          : this.body.employee
          ? this.body.employee.id
          : null,
        expense_category_id: this.body.category ? this.body.category.id : null,
        currency_id: this.body.currency ? this.body.currency.id : null,
        amount: this.body.amount,
        expense_date: toUTC(this.body.expense_date).format("YYYY-MM-DD"),
        created_by: this.$auth.user().id,
      };
      if (this.body && this.body.id) {
        Object.assign(data, { id: this.body.id });
      }

      this.$store
        .dispatch("tasks/createUpdateExpense", data)
        .then((s) => {
          this.body = {
            currency:
              this.task && this.task.project && this.task.project.data
                ? this.task.project.data.currency
                : {},
          };
          this.$toast.success("Expense successfully Logged!", {
            position: "top-right",
            duration: 3000,
          });

          let vm = this;
          this.$store.dispatch("tasks/getExpenses", data.task_id).then(() => {
            vm.$refs["expenseModal"].hide();
            vm.close();
          });
        })
        .catch((error) => {
          let errorMessage = `Failed to ${
            data.id ? "update" : "create"
          } expense`;
          handleError(this, error, errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    currencies() {
      return this.$store.getters["global/currencies"];
    },
    projects() {
      return this.$store.getters["projects/projects"];
    },
    categories() {
      return this.$store.getters["global/expense_categories"];
    },
    employees() {
      return this.$store.getters["employees/employees_simple"];
    },
    userEmployeeId() {
      return this.$auth.user().employee ? this.$auth.user().employee.id : null;
    },
    currency() {
      
      let taskProject = optional(this.task, 'project.data', optional(this.task, 'project', {}));
  
      return taskProject.currency
        ? taskProject.currency
        : {};
    },
    currentTask() {
      return this.$store.getters["tasks/task"];
    },
  },

  mounted() {
    this.body.employee = this.$store.getters["employees/employees_simple"].find(
      (s) => s.id == this.currentTask.employee_id
    );
  },

  created() {

    Promise.all([
      this.$store.dispatch("projects/index"),
      this.currencies.length || this.$store.dispatch("global/getCurrencies"),
      this.categories.length ||
        this.$store.dispatch("global/getExpenseCategories"),
      this.$store.dispatch("employees/index"),
    ]).then((s) => {
      this.formatData();
    });
  },
  watch: {
    expense: function (val) {
      this.formatData();
    },
  },
};
</script>
