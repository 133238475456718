<template>
  <div>
    <div role="tablist">
      <b-card no-body class="mb-1 collapsel">
        <b-card-header @click="toggleCollapse" header-tag="header" class="pt-3 pb-3" role="tab">
          {{name}}
          <slot name="icon"></slot>

          <span @click="toggleCollapse" v-if="notoggable == false" class="text-right float-right">
            <i class="zmdi zmdi-chevron-down" style="font-size:20px"></i>
          </span>
          <!-- <b-button block href="#" v-b-toggle.accordion-1 variant="info">Accordion 1</b-button> -->
        </b-card-header>
        <b-collapse id="accordion-1" :visible="visible" :accordion="name" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <slot name="content"></slot>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  // props: ["visible", "name", "notoggable"],
  props: {
    visible: {
      type: Boolean
    },
    name: {
      type: String
    },
    notoggable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleCollapse(event) {
      //disable toggle if you are clicking on a link from collapse pane
      if (this.notoggable) {
        return false;
      } else {
        if (event.target instanceof HTMLAnchorElement) {
          return false;
        } else {
          this.$emit("toggleCollapse");
        }
      }
    }
  }
};
</script>
