<template>
  <div>
    <button
      type="submit"
      @click="submit"
      :disabled="loading ? true: false"
      class="btn pl-5 pr-5 btn-primary"
    >
      <div v-if="!loading">{{text}}</div>
      <div v-else class="spinner-grow spinner-grow-sm text-light" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  props: ["loading", "text"],
  methods: {
    submit() {
      this.$emit("submit");
    }
  }
};
</script>
