<template>
    <div>
        <project-modal :project="formattedBody" v-if="opportunity.id"></project-modal>
    </div>
</template>
<script>
    import ProjectModal from "@/components/Modals/Projects";
    import {toLocal} from "@/helpers/date";
    export default {
        title: 'New | Opportunity to Project',
        components: { ProjectModal },
        data() {
            return {

            };
        },
        computed : {
            opportunity() {
                return this.$store.getters['projects/opportunity'];
            },
            formattedBody() {
                let body = {};
                Object.assign(body, this.opportunity);
                body.opportunity_id = body.id;
                body.customer_id = body.customer.data;
                body.currency_id = body.currency;
                body.estimated_cost = body.project_cost;
                body.project_status = {id: 1, name: 'Not Started'};
                body.start_date = toLocal(body.proposed_start_date).format('YYYY-MM-DD');
                body.end_date = toLocal(body.proposed_end_date).format('YYYY-MM-DD');
                delete body.id;
                // delete body.customer;
                //   delete body.currency;
                delete body.proposed_start_date;
                delete body.proposed_end_date;
                return body;
            }

        },
        methods : {},
        created() {
            this.$store.dispatch('projects/getOpportunity', this.$route.params.id);
        }
    };
</script>
