<template>
    <div>
        <div class="d-flex align-items-end justify-content-end mb-3">
            <router-link :to="{name:'projects-show-notes-create'}"><button class="btn btn-ds">+ New Note</button></router-link>
        </div>
        <div class="row" v-if="notes.length > 0">
            <div class="col-sm-3 mb-3" v-for="(note,index) in notes" :key="index">
                <router-link :to="{name: 'projects-show-notes-create',query:{id: note.id}}">
                <div class="card card-hover p-4">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <h6 class="text-bold h6 text-opacity-pale">{{note.title}}</h6>
                        <button style="border-radius:100%;border:1px solid green"><i class="zmdi zmdi-edit"></i></button>
                    </div>
                    <h6 class="text-opacity h7" style="color:black">{{note.notes | stripContent |  truncate(70)}}</h6>
                    <div class="text-right">
                        <small style="opacity:.7;color:black">Updated : {{toLocal(note.updated_at).fromNow()}} </small>
                    </div>
                </div>
                </router-link>
            </div>
        </div> 
        <div class="text-center" v-else>
            <h6 class="h7 text-opacity-pale text-medium">No Note has been created yet!</h6>
        </div> 

        <router-view></router-view>
    </div>
</template>
<style scoped>
.card-hover {
    cursor: pointer !important;
}
.card-hover:hover {
    border:1px solid green;
}
</style>
<script>
import truncate from "@/mixins/truncate";
import { toLocal } from "@/helpers/date";
export default {
    data() {
        return {
            notes:[]
        }
    },
    mixins: [truncate],
    methods: {
        toLocal
        
    },
    created() {
        Promise.all([
            this.$store.dispatch('projects/getNotes',this.$route.params.id).then(s => {
                // console.log(s)
                this.notes = s.data;
            })
        ])
    },
    filters: {
        stripContent(val) {
            return val.replace(/<\/?[^>]+(>|$)/g, "")
        }
    },
    watch: {
        $route() {
            this.$store.dispatch('projects/getNotes',this.$route.params.id).then(s => {
                // console.log(s)
                this.notes = s.data;
            })
        }
    }
}
</script>