<template>
  <div class="container-fluid tab-wrapper">
    <div
      class="row pl-3 pr-4"
      :style="showCollaboratorsModal ? 'position:absolute;margin-left:-27px; width:100%; z-index: 9999; background:white' : ''"
    >
      <div class="col-lg-10 float-left">
        <ul class="tabs">
          <router-link :to="{name: 'users-show'}">
            <li>USER INFO</li>
          </router-link>
          <router-link :to="{name: 'users-bank'}">
            <li>BANKING DETAILS</li>
          </router-link>
          <router-link :to="{name: 'users-time'}">
            <li>TIME LOG ENTRIES</li>
          </router-link>
          <router-link :to="{name: 'users-schedule'}">
            <li>COMP SCHEDULE</li>
          </router-link>
        </ul>
      </div>
    </div>
    <div class="pl-0 pr-4 pt-5">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user"],
  data() {
    return {
      showCollaboratorsModal: false,
      showDeleteName: ""
    };
  },
  computed: {

    firstThreeCollaborators() {
      return this.collaborators.slice(0, 3);
    }
  },
  methods: {
    showCollaborators() {
      this.showCollaboratorsModal = true;
    },
    hideCollaborators() {
      this.showCollaboratorsModal = false;
    }
  },
  mounted() {

  }
};
</script>
