<template>
  <div>
    <payroll-modal></payroll-modal>
  </div>
</template>

<script>
import PayrollModal from "@/components/Modals/Payroll";
export default {
  components: { PayrollModal },
  computed : {
    employeeTypes() {
      return this.$store.getters['global/employee_types'];
    },
  },
  created() {
    this.$store.dispatch("global/getEmployeeTypes");
  },
};
</script>

