<template>
  <div class="home">
    <headers>
      <div slot="breadcrumbs">
        <breadcrumbs :items="breadCrumbs"></breadcrumbs>
      </div>
      <div slot="title">
        <h2>Payroll</h2>
      </div>

    </headers>
    <div class="c-list container-fluid">
      <div class="pt-4 pb-4 pl-3 pr-4 row">
        <div class="col-sm-3">
          <div class="card p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              fill="#254050"
              viewBox="0 0 24 24"
            >
              <path
                d="M12.164 7.165c-1.15.191-1.702 1.233-1.231 2.328.498 1.155 1.921 1.895 3.094 1.603 1.039-.257 1.519-1.252 1.069-2.295-.471-1.095-1.784-1.827-2.932-1.636zm1.484 2.998l.104.229-.219.045-.097-.219c-.226.041-.482.035-.719-.027l-.065-.387c.195.03.438.058.623.02l.125-.041c.221-.109.152-.387-.176-.453-.245-.054-.893-.014-1.135-.552-.136-.304-.035-.621.356-.766l-.108-.239.217-.045.104.229c.159-.026.345-.036.563-.017l.087.383c-.17-.021-.353-.041-.512-.008l-.06.016c-.309.082-.21.375.064.446.453.105.994.139 1.208.612.173.385-.028.648-.36.774zm10.312 1.057l-3.766-8.22c-6.178 4.004-13.007-.318-17.951 4.454l3.765 8.22c5.298-4.492 12.519-.238 17.952-4.454zm-2.803-1.852c-.375.521-.653 1.117-.819 1.741-3.593 1.094-7.891-.201-12.018 1.241-.667-.354-1.503-.576-2.189-.556l-1.135-2.487c.432-.525.772-1.325.918-2.094 3.399-1.226 7.652.155 12.198-1.401.521.346 1.13.597 1.73.721l1.315 2.835zm2.843 5.642c-6.857 3.941-12.399-1.424-19.5 5.99l-4.5-9.97 1.402-1.463 3.807 8.406-.002.007c7.445-5.595 11.195-1.176 18.109-4.563.294.648.565 1.332.684 1.593z"
              ></path>
            </svg>
            <h6 class="text-bold text-opacity-pale pt-2">Salaried Employee Payroll</h6>
            <h6
              class="h7 text-opacity"
            >Review your users and employees and the money they are to be paid at fixed rate.</h6>
            <div class="pt-2">
              <router-link
                :to="{name: 'payments-create', query:{employee_type_id:1}}"
                class="text-bold d-flex flex-row align-items-center"
                style="text-transform: none !important;font-size:15px"
              >
                <span>Run Payroll (Fixed)</span>
                <span>
                  <i class="zmdi pl-2 zmdi-chevron-right" style="font-size: 20px;"></i>
                </span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              fill="#254050"
              viewBox="0 0 24 24"
            >
              <path
                d="M12.164 7.165c-1.15.191-1.702 1.233-1.231 2.328.498 1.155 1.921 1.895 3.094 1.603 1.039-.257 1.519-1.252 1.069-2.295-.471-1.095-1.784-1.827-2.932-1.636zm1.484 2.998l.104.229-.219.045-.097-.219c-.226.041-.482.035-.719-.027l-.065-.387c.195.03.438.058.623.02l.125-.041c.221-.109.152-.387-.176-.453-.245-.054-.893-.014-1.135-.552-.136-.304-.035-.621.356-.766l-.108-.239.217-.045.104.229c.159-.026.345-.036.563-.017l.087.383c-.17-.021-.353-.041-.512-.008l-.06.016c-.309.082-.21.375.064.446.453.105.994.139 1.208.612.173.385-.028.648-.36.774zm10.312 1.057l-3.766-8.22c-6.178 4.004-13.007-.318-17.951 4.454l3.765 8.22c5.298-4.492 12.519-.238 17.952-4.454zm-2.803-1.852c-.375.521-.653 1.117-.819 1.741-3.593 1.094-7.891-.201-12.018 1.241-.667-.354-1.503-.576-2.189-.556l-1.135-2.487c.432-.525.772-1.325.918-2.094 3.399-1.226 7.652.155 12.198-1.401.521.346 1.13.597 1.73.721l1.315 2.835zm2.843 5.642c-6.857 3.941-12.399-1.424-19.5 5.99l-4.5-9.97 1.402-1.463 3.807 8.406-.002.007c7.445-5.595 11.195-1.176 18.109-4.563.294.648.565 1.332.684 1.593z"
              ></path>
            </svg>
            <h6 class="text-bold text-opacity-pale pt-2">Hourly Employee Payroll</h6>
            <h6
              class="h7 text-opacity"
            >Review your users and employees and the money they are to be paid monthly.</h6>
            <div class="pt-2">
              <router-link
                :to="{name: 'payments-create', query:{employee_type_id:3}}"
                class="text-bold d-flex flex-row align-items-center"
                style="text-transform: none !important;font-size:15px"
              >
                <span>Run Payroll (Hourly)</span>
                <span>
                  <i class="zmdi pl-2 zmdi-chevron-right" style="font-size: 20px;"></i>
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <payments-table></payments-table>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import Headers from "@/components/Layouts/Headers";
import Breadcrumbs from "@/components/Breadcrumbs/Index";
import PaymentsTable from "@/components/Tables/Payments/Index";
export default {
  title:'Payroll',
  components: { Headers, Breadcrumbs, PaymentsTable },
  data() {
    return {
      breadCrumbs: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "All Payments",
          href: "payments",
        },
        {
          text: "Payments",
          active: true
        }
      ]
    };
  }
};
</script>
