<template>
  <div class="home">
    <navbar :disabled="true"></navbar>
    <router-view></router-view>
    <div class="row">
      <div class="col-sm-12 col-lg-12 wrapper-content-right">
        <div class="content">
          <div class="row mt-5 pt-5 pl-5 pr-5">
            <!-- end -->

            <div class="col-sm-12 pt-5 mt-5" v-if="!fetchingInvite">
              <div class="wr mt-5">
                <div class="text-center">
                  <h1>🤝</h1>
                </div>
                <div class="text-center">
                  <span class="caption">Collaborator Invite</span>
                </div>
                <br><br>
                <template v-if="validUser">
                <div class="text-center mt-3">
                  <h6
                    v-if="creator && project"
                    class="lighter"
                  >{{`${creator.first_name} ${creator.last_name}`}} has invited you as a collaborator to {{project.name}}. You can either accept or decline the invite with the buttons below</h6>
                </div>

                <div class="mt-5" v-if="canUpdate">
                  <button
                    :disabled="loading"
                    @click="acceptDecline(true)"
                    style="width:49%"
                    class="btn btn-ds mr-1"
                  >Accept</button>
                  <button
                    :disabled="loading"
                    @click="acceptDecline(false)"
                    style="width:49%"
                    class="btn btn-ds btn-danger"
                  >Decline</button>
                </div>
                <div class="mt-2" v-else-if="!canUpdate && !loading">
                  <div
                    class="alert alert-danger mt-2"
                    style="font-size:13px"
                  >This Invite has already been accepted/rejected. No further action can be performed</div>
                </div>
                </template>
                <template v-else>
                  <div class="mt-12">
                    <div class="alert alert-danger">This Invite link is not valid or it belongs to another user</div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar/Index";
export default {
  name: "Invite",
  title: "Invite",
  components: {
    Navbar
  },
  data() {
    return {
      loading: false,
      fetchingInvite: true,
    };
  },
  methods: {
    acceptDecline(bool) {
      this.loading = true;
      const data = {
        id: this.invite.id,
        email: this.invite.email,
        accepted: bool
      };

      this.$store
        .dispatch("projects/createUpdateInvite", data)
        .then(s => {
          this.$toast.success(
            `You have ${bool ? "Accepted" : "Rejected"}  the project invite`,
            {
              position: "top-right",
              duration: 3000
            }
          );

          if (bool === true) {
            this.$router.push({
              name: "projects-show-general",
              params: { id: this.invite.project_id }
            });
          }
        })
        .catch(error => {
          let errorMessage = `Failed to ${
            bool ? "Accept" : "Reject"
          } project invite. Please try again`;

          this.$toast.error(errorMessage, {
            position: "top-right",
            duration: 3000
          });
        })
        .then(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    invite() {
      return this.$store.getters["projects/invite"];
    },
    project() {
      return this.invite && this.invite.project && this.invite.project.data;
    },
    creator() {
      return this.invite && this.invite.creator && this.invite.creator.data;
    },
    canUpdate() {
      return this.invite.accepted == null;
    },
    validUser()
    {
      return this.invite.email == this.$auth.user().email;
    }
  },
  mounted() {
    this.$store.dispatch('projects/getInvite', this.$route.params.id).then(() => this.fetchingInvite = false);
  }
};
</script>
