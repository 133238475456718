<template>
  <div class="home pb-5" :class="{ blur: !loaded }">
    <delete-modal
      @trigger="deleteState"
      @cancel="cancelDelete"
      @deleteAction="deleteItem"
      :showDelete="showDelete"
      :name="showDeleteName"
      ref="delete"
    ></delete-modal>
    <headers class="bg-ggray pb-5">
      <div slot="breadcrumbs">
        <breadcrumbs :items="breadCrumbs"></breadcrumbs>
      </div>
      <div slot="title">
        <h2>{{ opportunity.name }}</h2>
      </div>
      <div slot="action">
        <button
          @click="deleteState"
          class="btn  btn-ds btn-outline btn-danger-custom pl-3 pr-3 ml-2"
        >
          Delete Opportunity
          <span class="pl-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="#72809D"
              viewBox="0 0 30 30"
            >
              <path
                d="M9 13v6c0 .552-.448 1-1 1s-1-.448-1-1v-6c0-.552.448-1 1-1s1 .448 1 1zm7-1c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm-4 0c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm4.333-8.623c-.882-.184-1.373-1.409-1.189-2.291l-5.203-1.086c-.184.883-1.123 1.81-2.004 1.625l-5.528-1.099-.409 1.958 19.591 4.099.409-1.958-5.667-1.248zm4.667 4.623v16h-18v-16h18zm-2 14v-12h-14v12h14z"
              ></path>
            </svg>
          </span>
        </button>
        <router-link :to="{ name: 'opportunities-project' }">
          <button class="btn btn-ds btn-outline pl-3 pr-3">
            Convert to Project
          </button>
        </router-link>
        <b-dropdown id="dropdown-1" class="m-md-2 options-dropdown">
          <template class="btn btn-ds btn-outline" v-slot:button-content>
            <i class="zmdi zmdi-more"></i>
            <!-- </button> -->
          </template>
          <b-dropdown-item>Duplicate</b-dropdown-item>
          <b-dropdown-item>Delete</b-dropdown-item>
          <b-dropdown-item>Export</b-dropdown-item>
        </b-dropdown>
      </div>
    </headers>
    <div class="container-fluid tab-wrapper">
      <div class="row pl-3  pb-4 pr-4">
        <div class="col-sm-12 col-lg-6 pt-3">
          <h6 class="text-bold text-opacity-pale">OPPORTUNITY STAGE</h6>
        </div>
        <div class="col-sm-12 col-lg-12 mt-3 opportunity-wrapper">
          <ul class="opportunity-stages">
            <li
              v-for="(stage, index) in stages"
              :key="index"
              :class="{
                active: stage.id == opportunity.opportunity_stage_id,
                done:
                  !(
                    (stage.id == 5 && opportunity.opportunity_stage_id == 6) ||
                    (stage.id == 6 && opportunity.opportunity_stage_id == 5)
                  ) && stage.id < opportunity.opportunity_stage_id,
                pending: stage.id > opportunity.opportunity_stage_id,
                button: opportunity.opportunity_stage_id <= 4,
                unclickable: opportunity.opportunity_stage_id > 4,
              }"
              @click="completeStage(stage.id)"
            >
              <span class="pr-3">
                <i
                  v-if="stage.id == opportunity.opportunity_stage_id"
                  class="zmdi zmdi-flag"
                ></i>
                <i
                  v-else-if="
                    !(
                      (stage.id == 5 &&
                        opportunity.opportunity_stage_id == 6) ||
                      (stage.id == 6 && opportunity.opportunity_stage_id == 5)
                    ) && stage.id < opportunity.opportunity_stage_id
                  "
                  class="zmdi zmdi-check-circle"
                ></i>
              </span>
              {{ stage.name }}
              <span></span>
              <span></span>
            </li>
          </ul>
        </div>
      </div>
      <div class="pr-4">
        <div class="mt-4 row">
          <div class="col-sm-12 col-lg-3">
            <div class="col-sm-12">
              <div class="card w-70">
                <div class="card-header no-right-padding-card">
                  <span class="pt-2">Opportunity Details</span>
                  <router-link
                    :to="{
                      name: 'opportunities-edit',
                      params: { id: this.$route.params.id },
                    }"
                  >
                    <span class="btn-icon-sm float-right">
                      <svg
                        clip-rule="evenodd"
                        fill="#72809D"
                        fill-rule="evenodd"
                        height="20"
                        viewBox="0 0 30 30"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
                        ></path>
                      </svg>
                    </span>
                  </router-link>
                  <router-link
                    :to="{
                      name: 'opportunities-edit',
                      params: { id: this.$route.params.id },
                    }"
                  >
                    <span class="btn-icon-sm float-right">
                      <svg
                        fill="#72809D"
                        height="20"
                        viewBox="0 0 30 30"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"
                        ></path>
                      </svg>
                    </span>
                  </router-link>
                </div>
                <div class="card-content p-3">
                  <div class="row">
                    <div class="col-sm-6">Opportunity:</div>
                    <div class="col-sm-6 float-right text-right">
                      {{ opportunity.name }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">Customer name:</div>
                    <div class="col-sm-6 float-right text-right">
                      <router-link
                        v-if="customer"
                        :to="{
                          name: 'customers-show',
                          params: { id: customer.id },
                        }"
                        >{{ customer.company_name }}</router-link
                      >
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-sm-6">Type:</div>
                    <div class="col-sm-6 float-right text-right">
                      {{ billingType && billingType.name }}
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-sm-6">Projected revenue:</div>
                    <div class="col-sm-6 float-right text-right">
                      {{ currency && currency.symbol }}
                      {{ opportunity.projected_revenue }}
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-sm-6">Proposed start:</div>
                    <div class="col-sm-6 float-right text-right">
                      {{
                        opportunity.proposed_start_date
                          ? toLocal(opportunity.proposed_start_date).format(
                              "lll"
                            )
                          : ""
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 mt-4" v-if="false">
              <div class="card w-70">
                <div class="card-header no-right-padding-card">
                  Related Contacts
                  <span class="btn-icon-sm float-right">
                    <svg
                      clip-rule="evenodd"
                      fill="#72809D"
                      fill-rule="evenodd"
                      height="20"
                      viewBox="0 0 30 30"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
                      ></path>
                    </svg>
                  </span>
                  <span class="btn-icon-sm float-right">
                    <svg
                      fill="#72809D"
                      height="20"
                      viewBox="0 0 30 30"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"
                      ></path>
                    </svg>
                  </span>
                  <router-link :to="{ name: 'customers-contacts-new' }">
                    <span class="btn-icon-sm float-right">
                      <svg
                        fill="#72809D"
                        height="20"
                        viewBox="0 0 30 30"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z"
                        ></path>
                      </svg>
                    </span>
                  </router-link>
                </div>
                <div class="card-content p-3">
                  <div class="row card-border-top">
                    <div
                      :key="primaryContact.id"
                      class="col-sm-12 list"
                      v-for="primaryContact in []"
                    >
                      <div class="row mt-3">
                        <div class="col-sm-12">
                          <h4 class="h8">
                            {{
                              `${primaryContact.first_name} ${primaryContact.last_name}`
                            }}
                          </h4>
                          <h6 class="text-opacity h10">
                            {{ primaryContact.email }}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-9 pl-5 col-md-8 float-right">
            <div class="tab-wrapper tab-wrapper-mobile">
              <ul class="tabs">
                <router-link :to="{ name: 'opportunities-show' }">
                  <li>Tasks</li>
                </router-link>
                <router-link :to="{ name: 'opportunities-attachments' }">
                  <li>Attachments</li>
                </router-link>
                <router-link :to="{ name: 'opportunities-comments' }">
                  <li>Comments</li>
                </router-link>
              </ul>
              <ul class="bg-sky p-3 text-right">
                <li class="pr-3" v-if="$route.name === 'opportunities-show'">
                  <router-link
                    :to="{ name: 'tasks-create' }"
                    class="btn-action btn-table"
                  >
                    <button class="btn-action btn-table">
                      <small class="text-bold text-main">
                        <svg
                          fill="#0a5685"
                          height="20"
                          viewBox="0 0 30 30"
                          width="20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z"
                          ></path>
                        </svg>
                        Create New
                      </small>
                    </button>
                  </router-link>
                </li>
                <li class="pr-3">
                  <button class="btn-action btn-table">
                    <small class="text-bold text-main">
                      <svg
                        fill="#0a5685"
                        height="20"
                        viewBox="0 0 30 30"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19 11h-14v-2h14v2zm0 2h-14v2h14v-2zm0 4h-14v2h14v-2zm3-11v16h-20v-16h20zm2-6h-24v24h24v-24z"
                        ></path>
                      </svg>
                      Edit List
                    </small>
                  </button>
                </li>
                <li class="pr-3">
                  <button class="btn-action btn-table">
                    <small class="text-bold text-main">
                      <svg
                        fill="#0a5685"
                        height="20"
                        viewBox="0 0 30 30"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
                        ></path>
                      </svg>
                      View all
                    </small>
                  </button>
                </li>
              </ul>
              <div class>
                <router-view></router-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.tab-wrapper {
  .row {
    background: white;
    padding-bottom: 20px;
  }
}
.unclickable {
  pointer-events: none;
}
</style>

<script>
import Headers from "@/components/Layouts/Headers";
import Breadcrumbs from "@/components/Breadcrumbs/Index";
import { toLocal } from "@/helpers/date";
import DeleteModal from "@/components/Modals/Delete";

export default {
  title: `Show | Opportunities`,
  components: { Headers, Breadcrumbs, DeleteModal },
  data() {
    return {
      loading: false,
      showDelete: false,
      showDeleteName: "",
      loaded: false,
    };
  },
  methods: {
    toLocal,
    completeStage(id) {
      this.loading = true;
      let newStageId = id;
      let newStage = this.stages.find((s) => s.id == newStageId);
      let msgPass = `Opportunity moved to ${newStage.name} stage`;

      let data = {
        id: this.opportunity.id,
        opportunity_stage_id: newStageId,
      };

      this.$store
        .dispatch("projects/createUpdateOpportunity", data)
        .then((s) => {
          this.$toast.success(msgPass, {
            position: "top-right",
            duration: 3000,
          });
          this.loading = false;
          let opportunity = {};
          Object.assign(opportunity, this.opportunity);
          opportunity.opportunity_stage_id = newStageId;
          opportunity.opportunity_stage = newStage;
          this.$store.commit("projects/SET_OPPORTUNITY", opportunity);
        })
        .catch((error) => {
          let errorMessage = `Failed to move opportunity to ${newStage.name} stage`;
          this.handleError(this, error, errorMessage);
          this.loading = false;
        });
    },
    deleteState() {
      this.showDelete = true;
      this.showDeleteName = "Opportunity";
    },
    cancelDelete() {
      this.showDelete = false;
    },
    deleteItem(s) {
      this.$store
        .dispatch("projects/deleteOpportunity", this.$route.params.id)
        .then((s) => {
          this.$router.push({ name: "opportunities-index" });
          this.$toast.success("🚮 Opportunity Successfully Deleted", {
            position: "top-right",
            duration: 3000,
          });
        });
    },
  },
  computed: {
    customer() {
      return this.opportunity.customer && this.opportunity.customer.data
        ? this.opportunity.customer.data
        : this.opportunity.customer;
    },
    billingType() {
      return this.opportunity && this.opportunity.billing_type;
    },
    currency() {
      return this.opportunity && this.opportunity.currency;
    },
    breadCrumbs() {
      return [
        {
          text: "Home",
          to: { name: "home" },
        },
        {
          text: "All Opportunities",
          to: { name: "opportunities-index" },
        },
        {
          text: this.opportunity.name,
          to: {
            name: "opportunities-show",
            params: { id: this.$route.params.id },
          },
        },
      ];
    },
    opportunity() {
      return this.$store.getters["projects/opportunity"];
    },
    stages() {
      return this.$store.getters["global/opportunity_stages"];
    },
  },
  created() {
    Promise.all([
      this.$store.dispatch("projects/getOpportunity", this.$route.params.id),
      this.stages.length || this.$store.dispatch("global/getOpportunityStages"),
    ]).finally(() => {
      this.loaded = true;
    });
  },
};
</script>
