import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const invoices = {
    namespaced: true,
    state: {
        invoices: [],
        invoice_items: [],
        invoice: {},
        invoice_items_expenses: [],
        invoice_items_timelogs: [],
        invoice_items_milestones: [],
        invoice_metrics: {}
    },
    actions,
    getters,
    mutations
}
