<template>
  <div>
    <milestone-modal :milestone="milestone" v-if="milestone.id"></milestone-modal>
  </div>
</template>
<script>
import MilestoneModal from "@/components/Modals/Milestones";
export default {
  title: "Edit | Milestones ",
  components: { MilestoneModal },
  data() {
    return {
    };
  },
  computed : {
    milestone() {
      return this.$store.getters['projects/milestone'];
    }
  },
  created() {
    this.$store.dispatch('projects/getMilestone', this.$route.params.mid);
  }
};
</script>
