<template>
  <div class="col-sm-12 col-lg-9 pb-5 ml-0 pl-0">
    <div class="card-content_wrapper">
      <div class="card-caption d-flex justify-content-between pt-3 pb-2 pl-4">
        <div>
          <h6 class="text-bold text-opacity-pale">Payment Methods</h6>
        </div>
        <div class="mr-3">
          <router-link :to="{name: 'settings'}">
            <button class="card-btn">Back to Organization</button>
          </router-link>
        </div>
      </div>
      <div class="card-description" v-if="user.organization && user.organization.id">
        <form class="d-form">
          <div class="row api-keys pl-4 pr-4 pt-4">
            <div class="col-sm-12 col-lg-6 p-4">
              <img class="logo" src="@/assets/img/stripe-logo.png">
              <div class="mt-3">
                <small class="text-opacity">Stripe API Key <b>*</b></small>
                <input
                  type="text"
                  v-validate="'required'"
                  name="stripe"
                  class="form-control auth-input d-input"
                  placeholder="Enter your Stripe API Key"
                  data-vv-as="Stripe Key"
                >
                <small
                  class="form-text text-danger"
                  v-show="errors.has('first_name')"
                >{{errors.first('first_name')}}</small>
              </div>
            </div>
            <div class="col-sm-12 col-lg-6 pl-3 pr-4 pb-4 pt-3">
              <img class="logo" style="width:40%" src="@/assets/img/authorize.png">
              <div class="mt-3 ml-3">
                <small class="text-opacity">Authorize.NET API Key <b>*</b></small>
                <input
                  type="text"
                  v-validate="'required'"
                  name="authorize"
                  class="form-control auth-input d-input"
                  placeholder="Enter your Authorize.NET API Key"
                  data-vv-as="Authorize.NET Key"
                >
                <small
                  class="form-text text-danger"
                  v-show="errors.has('first_name')"
                >{{errors.first('first_name')}}</small>
              </div>
            </div>
            <div class="col-sm-12 col-lg-6">
              <i class="zmdi zmdi-more pt-4 pl-4 pr-4"></i>
              <h6 class="h7 pt-3 pl-3 text-opacity-pale">More Payment Options soon to come..</h6>
            </div>
            <div class="col-sm-12 col-lg-6 p-4">
              <!-- <img class="logo" style="width:40%" src="@/assets/img/authorize.png"> -->
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer text-right mt-5">
        <dashboard-button @submit="save" :loading="loading" :text="'Update'"></dashboard-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.api-keys {
  .logo {
    width: 15%;
  }
  .col-lg-6 {
    &:nth-child(1) {
      // display:
      border-right: 1px dashed #e4e4e4;
      border-bottom: 1px dashed #e4e4e4;
    }
    &:nth-child(4) {
      border-left: 1px dashed #e4e4e4;
      border-top: 1px dashed #e4e4e4;
      //   margin-top: 1px;
    }
  }
}
</style>

<script>
import DashboardButton from "@/components/Buttons/Dashboard";
import { toLocal } from "@/helpers/date";
export default {
  components: { DashboardButton },
  data() {
    return {
      user: {
        organization: {}
      },
      loaded: false
    };
  },
  methods: {
    toLocal
  },
  computed: {
    breadCrumbs() {
      return [
        {
          text: "Home",
          to: { name: "home" }
        },
        {
          text: "Organization",
          to: { name: "users-index" }
        },
        {
          text:
            this.user && this.user.organization && this.user.organization.name,
          to: ""
        }
      ];
    }
  },
  created() {
    Promise.all([
      this.$store.getters["global/countries"].length ||
        this.$store.dispatch("global/getCountries"),
      this.$store.getters["global/industries"].length ||
        this.$store.dispatch("global/getIndustries"),
      this.$auth.refresh()
    ]).finally(() => {
      this.user = JSON.parse(JSON.stringify(this.$auth.user()));
      this.user.country = this.$store.getters["global/countries"].find(
        s => s.id == this.user.country_id
      );
      this.user.industry = this.user.organization ? this.$store.getters["global/industries"].find(
        s => s.id == this.user.organization.industry_id
      ) : {};
      this.loaded = true;
    });
  }
};
</script>
