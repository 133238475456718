<template>
  <div>
    <milestone-modal @project='getProject'></milestone-modal>
  </div>
</template>
<script>
import MilestoneModal from "@/components/Modals/Milestones";
export default {
  title: "New | Milestones | Projects",
  components: { MilestoneModal },
  data() {
    return {};
  },
  computed: {},
  methods: {
    getProject() {
      return this.$store.dispatch("projects/getSingle", this.$route.params.id);
    }
  },
  mounted() {},
};
</script>
