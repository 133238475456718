<template>
  <div class="col-sm-12 col-lg-9 pb-0 ml-0 pl-0">
    <div class="card-content_wrapper">
      <form class="d-form" @submit.prevent="save" :class="{blur:!loaded}">
        <div class="card-caption d-flex justify-content-between pt-3 pb-2 pl-4">
          <div>
            <h6 class="text-bold text-opacity-pale">Organization Information</h6>
          </div>
          <div class="mr-3">
            <router-link :to="{ name: 'settings' }">
              <button class="card-btn">Back to Organization</button>
            </router-link>
          </div>
        </div>
        <div class="card-description" v-if="optional($auth.user(), 'org_role.role_id') == 1">
          <div class="row pl-4 pt-3 pr-4">
            <div class="col-sm-12 col-lg-6">
              <small class="text-opacity">
                Name
                <b>*</b>
              </small>
              <input
                type="text"
                v-validate="'required'"
                v-model="body.org_name"
                name="company_name"
                class="form-control auth-input d-input"
                placeholder="Enter your Company Name"
                data-vv-as="Company Name"
              >
              <small
                class="form-text text-danger"
                v-show="errors.has('company_name')"
              >{{ errors.first("company_name") }}</small>
            </div>
            <div class="col-sm-12 col-lg-6">
              <small class="text-opacity">
                Business Email
                <b>*</b>
              </small>
              <input
                type="email"
                v-validate="'required|email'"
                v-model="body.business_email"
                name="business_email"
                class="form-control auth-input d-input"
                placeholder="Enter your Business Email"
                data-vv-as="Business Email"
              >
              <small
                class="form-text text-danger"
                v-show="errors.has('business_email')"
              >{{ errors.first("business_email") }}</small>
            </div>
          </div>
          <div class="row pl-4 pt-3 pr-4">
            <div class="col-sm-12 col-lg-6">
              <small class="text-opacity">
                Website
                <b>*</b>
              </small>
              <input
                type="url"
                v-validate="'required|url'"
                v-model="body.website"
                name="website"
                class="form-control auth-input d-input"
                placeholder="Enter your Business Website"
                data-vv-as="Business Website"
              >
              <small
                class="form-text text-danger"
                v-show="errors.has('website')"
              >{{ errors.first("website") }}</small>
            </div>
            <div class="col-sm-12 col-lg-6">
              <small class="text-opacity">
                Industry
                <b>*</b>
              </small>
              <multiselect
                track-by="id"
                return="id"
                label="name"
                v-model="body.industry"
                v-validate="'required'"
                name="industry_id"
                :options="industries"
                :show-labels="false"
                class="mt-2"
                placeholder="Select Industry"
                data-vv-as="Industry"
              ></multiselect>
              <small
                class="form-text text-danger"
                v-show="errors.has('industry_id')"
              >{{ errors.first("industry_id") }}</small>
            </div>
          </div>
          <div class="row pl-4 pt-3 pr-4">
            <div class="col-sm-12 col-lg-6">
              <small class="text-opacity">
                Default Currency
                <b>*</b>
              </small>
              <multiselect
                track-by="id"
                return="id"
                label="name"
                v-model="body.default_currency"
                :custom-label="currencySelectLabel"
                v-validate="'required'"
                name="currency_id"
                :options="currencies"
                class="mt-2"
                :show-labels="false"
                placeholder="Select Currency"
                data-vv-as="Currency"
              ></multiselect>
              <small
                class="form-text text-danger"
                v-show="errors.has('currency_id')"
              >{{ errors.first("currency_id") }}</small>
            </div>
            
          </div>

          <div class="row pl-4 pt-3 pr-4">
            <div class="col-sm-12 col-lg-12">
              <div class="row mt-4">
                <div class="col-sm-12 col-lg-6">
                  <small class="text-opacity">
                    Street
                    <b>*</b>
                  </small>
                  <input
                    type="text"
                    name="recipient_address_address"
                    v-validate="'required'"
                    v-model="body.address.address"
                    class="form-control auth-input d-input"
                    placeholder="Enter stress address"
                    data-vv-as="Street Address"
                  >
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('address_address')"
                  >{{ errors.first("address_address") }}</small>
                </div>
                <div class="col-sm-12 col-lg-6">
                  <small class="text-opacity">
                    Country
                    <b>*</b>
                  </small>
                  <multiselect
                    track-by="id"
                    return="id"
                    label="name"
                    v-model="body.address.country"
                    v-validate="'required'"
                    @input="setStates(body.address.country)"
                    name="country_id"
                    :options="countries"
                    :show-labels="false"
                    data-vv-as="Country"
                    class="multiselect mt-2"
                  ></multiselect>
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('country_id')"
                  >{{ errors.first("country_id") }}</small>
                </div>
              </div>

              <div class="row mt-1">
                <div class="col-sm-12 col-lg-6 mt-1">
                  <small class="text-opacity">
                    State
                    <b>*</b>
                  </small>
                  <multiselect
                    track-by="id"
                    return="id"
                    label="name"
                    name="recipient_address_region_id"
                    v-validate="'required'"
                    v-model="body.address.region"
                    :options="states"
                    :show-labels="false"
                    @input="setCities(body.address.region)"
                    data-vv-as="State"
                    class="multiselect mt-2"
                  ></multiselect>
                  <small class="form-text text-danger" v-show="errors.has('address_region_id')">
                    {{
                    errors.first("address_region_id")
                    }}
                  </small>
                </div>
                <div class="col-sm-12 col-lg-6 mt-1">
                  <small class="text-opacity">
                    City
                    <b>*</b>
                  </small>
                  <multiselect
                    v-if="cities.length"
                    track-by="id"
                    return="id"
                    label="name"
                    name="recipient_address_city_id"
                    v-validate="'required'"
                    v-model="body.address.city"
                    :options="cities"
                    :show-labels="false"
                    data-vv-as="City"
                    class="multiselect mt-2"
                  ></multiselect>
                  <input
                    v-else
                    type="text"
                    v-model="body.address.city"
                    name="recipient_address_city"
                    class="form-control auth-input d-input"
                    placeholder="Enter City"
                    data-vv-as="State City"
                  >
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('address_city_id')"
                  >{{ errors.first("address_city_id") }}</small>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-sm-12 col-lg-6 mt-1">
                  <small class="text-opacity">Zip Code</small>
                  <input
                    type="text"
                    v-model="body.address.post_code"
                    class="form-control auth-input d-input"
                    placeholder="Enter shipping zip code"
                    data-vv-as="Zip Code"
                  >
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('address_post_code')"
                  >{{ errors.first("address_post_code") }}</small>
                </div>
                <div class="col-sm-12 col-lg-6 mt-1">
                  <small class="text-opacity">
                    Pay Periods
                    <b>*</b>
                  </small>
                  <multiselect
                    track-by="id"
                    return="id"
                    label="name"
                    name="pay_period"
                    v-validate="'required'"
                    v-model="body.pay_period"
                    :options="payPeriods"
                    :show-labels="false"
                    data-vv-as="Pay Period"
                    class="multiselect mt-2"
                  ></multiselect>
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('pay_period')"
                  >{{ errors.first("pay_period") }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-right mt-5">
          <dashboard-button @submit="save" :loading="loading" :text="'Update'"></dashboard-button>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-footer {
  background: transparent;
  height: 80px;
}
</style>

<script>
import DashboardButton from "@/components/Buttons/Dashboard";
import Literals from "@/helpers/dashboard/literals";
import { toLocal } from "@/helpers/date";
import handleError from "@/helpers/error";
import getOrganization from "@/helpers/getOrganization";
import {optional} from "@/helpers/random"

export default {
  components: { DashboardButton },
  name: "EditProfile",
  title: "Edit Profile",
  data() {
    return {
      Literals,
      states: [],
      cities: [],
      body: {
        first_name: "",
        last_name: "",
        email: "",
        country: "",
        name: "",
        website: "",
        business_email: "",
        pay_period: "",
        org_id: "",
        org_name: "",
        industry: "",
        address: {
          city: null
        }
      },
      loaded: false,
      loading: false,
      getOrganization
    };
  },
  methods: {
    toLocal,
    handleError,
    optional,
    save() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    currencySelectLabel({ code, name, symbol }) {
      return ` ${name} (${symbol} ${code}) `;
    },
    async submit() {
      let org_data = {
        id: this.body.org_id,
        name: this.body.org_name,
        business_email: this.body.business_email,
        pay_period_id: this.body.pay_period && this.body.pay_period.id,
        website: this.body.website,
        industry_id: this.body.industry ? this.body.industry.id : null,
        default_currency_id: this.body.default_currency
          ? this.body.default_currency.id
          : ""
      };
      let orgAddress = this.fillAddress(this.body.address);
      if (orgAddress) {
        await this.$store
          .dispatch("global/createUpdateAddress", orgAddress)
          .then(async id => {
            let newData = {
              address_id: id,
              organization_id: this.body.org_id
            };

            await this.$store
              .dispatch("global/createUpdateOrgAddress", newData)
              .then(id => {})
              .catch(error => {
                let errorMessage = `Failed to Save Org Address`;
                this.handleError(this, error, errorMessage);
                this.loading = false;
              });
          })
          .catch(error => {
            let errorMessage = `Failed to Create Address`;
            this.handleError(this, error, errorMessage);
            this.loading = false;
          });
      }

      if (this.loading) {
        this.$store
          .dispatch("global/updateOrganizationalProfile", org_data)
          .then(s => {
            this.$auth.refresh().then(({ data: { data: user } }) => {
              this.$auth.user(user);
              this.$toast.success(Literals.ORG_UPDATE_SUCCESS, {
                position: "top-right",
                duration: 3000
              });
              this.$router.go(-1);
            });
          })
          .catch(error => {
            let errorMessage = Literals.USER_UPDATE_ERROR;
            handleError(this, error, errorMessage);
          })
          .finally(s => {
            this.loading = false;
          });
      }
    },
    setStates(selectedCountry) {
      this.states =
        selectedCountry &&
        selectedCountry.regions &&
        selectedCountry.regions.data
          ? selectedCountry.regions.data
          : [];
    },
    setCities(state) {
      this.$store.dispatch("global/getState", state.id).then(data => {
        this.cities = data && data.cities && data.cities.data;
        if (!this.cities.length) this.body.address.city = "";
      });
    },
    fillAddress(rawAddress) {
      let parsedAddress = {};
      if (rawAddress) {
        parsedAddress = {
          address: rawAddress.address,
          region_id: rawAddress.region ? rawAddress.region.id : null,
          country_id: rawAddress.country ? rawAddress.country.id : null,
          post_code: rawAddress.post_code
        };

        rawAddress.city && rawAddress.city.id
          ? (parsedAddress.city_id = rawAddress.city.id)
          : (parsedAddress.city = rawAddress.city);

        rawAddress.city && rawAddress.id
          ? (parsedAddress.id = rawAddress.id)
          : null;
      }
      return parsedAddress.address &&
        parsedAddress.region_id &&
        parsedAddress.country_id &&
        parsedAddress.post_code
        ? parsedAddress
        : null;
    },
    formatData() {
       let body = {
        id: this.$auth.user().id,
        first_name: this.$auth.user().first_name,
        last_name: this.$auth.user().last_name,
        email: this.$auth.user().email,
        phone_number: this.$auth.user().phone_number,
        country: this.countries.find(s => s.id == this.$auth.user().country_id)
      };
      let organization = this.$auth.user().organization && this.$auth.user().organization.data
      ? this.$auth.user().organization.data : (
        this.$auth.user().organizations && this.$auth.user().organizations.data
        ? this.$auth.user().organizations.data.find(s => s.id == getOrganization.organization_id())
        : null
      )
      if (organization) {
        body.business_email = organization.business_email;
        body.website = organization.website;
        body.org_id = organization.id;
        body.org_name = organization.name;
        body.pay_period = this.payPeriods.find( s => s.id == organization.pay_period_id);
        body.industry = this.industries.find( s => s.id == organization.industry_id );
        body.default_currency = organization.default_currency_id
        ? this.currencies.find( s => s.id == organization.default_currency_id )
        : "";
      }

      this.body = { ...this.body, ...body };
      if (this.addresses && this.addresses[0]) {
        let orgAddress = this.addresses[0];
        this.body.organization_address_id = orgAddress.id;
        this.body.address_id = orgAddress.address_id;
        this.body.address = orgAddress.address && orgAddress.address.data ? orgAddress.address.data : {};
        this.body.address.city = orgAddress.address && orgAddress.address.data
        ? orgAddress.address && orgAddress.address.data.city.name
        : {};
      }
    }
  },
  computed: {
    industries() {
      return this.$store.getters["global/industries"];
    },
    breadCrumbs() {
      return [
        {
          text: "Home",
          to: { name: "home" }
        },
        {
          text: "Organization",
          to: { name: "users-index" }
        },
        {
          text:
            this.$auth.user().organization &&
            this.$auth.user().organization.name,
          to: ""
        }
      ];
    },
    countries() {
      return this.$store.getters["global/countries"];
    },
    currencies() {
      return this.$store.getters["global/currencies"];
    },
    addresses() {
      return this.$store.getters["global/organization_addresses"];
    },
    payPeriods() {
      
      return this.$store.getters["global/pay_periods"];
    }
  },
  created() {
    Promise.all([
      this.industries.length || this.$store.dispatch("global/getCountries"),
      this.currencies.length || this.$store.dispatch("global/getCurrencies"),
      this.payPeriods.length || this.$store.dispatch("global/getPayPeriods"),
      this.countries.length || this.$store.dispatch("global/getIndustries"),
      this.addresses.length || this.$store.dispatch("global/getOrganizationAddresses"),
    ]).finally(() => {
      this.formatData();
      this.loaded = true;
    });
  }
};
</script>
