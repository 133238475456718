import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const global = {
    namespaced: true,
    state: {
        pay_periods:[],
        countries: [],
        states: [],
        state: [],
        selected_org: {},
        cities: [],
        city: [],
        currencies: [],
        project_billing_types: [],
        project_statuses: [],
        task_statuses: [],
        milestones: [],
        tasks: [],
        organization_key: "",
        industries: [],
        roles: [],
        job_roles: [],
        token: window.localStorage.getItem('token'),
        isAuthenticated: !!window.localStorage.getItem('token'),
        banks: [],
        recipient_types: [],
        opportunity_stages: [],
        customer_sources: [],
        employee_types: [],
        expense_categories: [],
        invoice_statuses: [],
        payment_sources: [],
        milestone_statuses: [],
        organization_addresses: [],
        license: {},
        subscription: {},
    },
    actions,
    getters,
    mutations
}
