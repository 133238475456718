<template>
  <div>
    <customer-modal ></customer-modal>
  </div>
</template>
<script>
import CustomerModal from "@/components/Modals/Customers";
export default {
  title: `New | Customers`,
  components: { CustomerModal },
  data() {
    return {

    };
  }
};
</script>
