<template>
  <div class="float-right">
    <ul class="filters-form">
      <li class="pr-1 pt-1">
        <div style="position:relative">
          <input
            type="text"
            class="form-control h-41 search-input pl-4"
            style="height:41px;width:250px !important; font-size:14px;"
            v-model="params['name']"
            @input="setFilters('name')"
            placeholder="Search Projects"
            aria-label="Search"
            aria-describedby="basic-addon1"
          >
          <span class="close-icon" v-if="params && params.name">
            <i class="zmdi zmdi-close" @click="params['name']='';setFilters('name')"></i>
          </span>
        </div>
      </li>
      <li class="pr-1">
        <select class="h-42 custom-select form-control">
          <option>Sort by</option>
        </select>
      </li>
      <li class="pr-1">
        <button
          :class="visibleFilter ? 'btn btn-primary' : 'btn btn-outline'"
          @click="showMoreFilters"
        >
          <i class="zmdi zmdi-filter-list pr-2"></i>Filters
        </button>
      </li>
      <li>
        <b-dropdown id="dropdown-1" class="dropdown-filters" no-caret>
          <template class="btn-primary" v-slot:button-content>
            <i class="zmdi zmdi-more pr-2"></i>Edit List
          </template>

          <b-dropdown-item-button class="header">Edit Columns</b-dropdown-item-button>
          <b-dropdown-item-button
            :key="index"
            @click="removeColumnFromTable(column.name,column.active)"
            v-for="(column,index) in columns.filter(s => s.cvisible === true)"
          >
            <input type="checkbox" v-model="column.active" class="mr-2" value>
            <span>{{column.title}}</span>
            <span class="float-right">
              <i class="zmdi zmdi-view-headline"></i>
            </span>
          </b-dropdown-item-button>
        </b-dropdown>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["visibleFilter", "columns", "params"],
  methods: {
    showMoreFilters() {
      let visible = this.visibleFilter === true ? false : true;
      this.$emit("showMoreFilters", visible);
    },
    removeColumnFromTable(name, val) {
      let data = {
        name,
        val: !val
      };
      this.$emit("removeColumnFromTable", data);
    },
    setFilters(name, val) {
      this.$emit("setFilters", this.params, name);
    }
  }
};
</script>
