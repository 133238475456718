<template>
  <div class>
    <div class="table-wrapper">
      <vuetable
        ref="vuetable"
        :api-url="api"
        :http-options="{ headers:{ 'Authorization' : 'Bearer ' + $auth.token(),'xorg' : getOrganization.organization_id() } }"
        @vuetable:loaded="loadTable"
        :fields="columns"
        data-path="data"
        pagination-path=""
        :no-data-template="rowCount === null ? 'Loading data' : 'No Data Available'"
        @vuetable:pagination-data="onPaginationData"
      >
        <template slot="id" slot-scope="props">
          <router-link
                  v-b-tooltip.hover
                  title="Goto Project"
                  :to="{name: 'projects-show-general', params: {id: props.rowData.id}}"
          >{{props.rowData.name}}
          </router-link>
        </template>
        <template slot="hourly_rate" slot-scope="{rowData}">
          {{rowData.currency && rowData.currency.symbol}}{{parseFloat(rowData.hourly_rate).toFixed(2)}}
        </template>
        <template slot="manager" slot-scope="{rowData}">
          {{rowData.manager&& rowData.manager.data ? `${rowData.manager.data.first_name} ${rowData.manager.data.last_name}` : 'N/A'}}
        </template>
      </vuetable>
      <vuetable-pagination v-show="paginationData.total"
                           :css="table_css.pagination"
                           ref="pagination"
      ></vuetable-pagination>
      <div class="text-center" v-if="rowCount <=0 || rowCount === null">
        <slot name="empty"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import getOrganization from "@/helpers/getOrganization";
import VuetableCss from "@/helpers/vuetable_styling.js";
import getPaginationData from "@/helpers/pagination.js";
import VuetablePagination from "@/components/Layouts/VueTablePagination"
export default {
  title: 'Index | Projects | Customer',
  components: {VuetablePagination},
  data() {
    return {
      columns: [
        {
          name: "__slot:id",
          title: "Project Title",
          sortField: "name",
          cvisible: true,
          active: true
        },
        {
          name: "project_status",
          title: "Project Type",
          sortField: "project_status_id",
          cvisible: true,
          active: true,
          callback: d => {
            return d.name;
          }
        },
        {
          name: "__slot:manager",
          title: "Project Manager",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:hourly_rate",
          title: "Project Hourly Rate",
          cvisible: true,
          active: true
        },
        {
          name: "fixed_cost",
          title: "Cost",
          cvisible: true,
          active: false,
        }
      ],
      api: `${this.$baseApi}/customers/${this.$route.params.id}/projects?include=manager`,
      rowCount: null,
      table_css: VuetableCss,
      getOrganization,
      paginationData: {},
    };
  },
  methods: {
    getPaginationData,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(this.paginationData)
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
    },
  }
};
</script>

