export default {
    SET_PAYROLL(state, array) {
        state.payroll = array;
    },
    SET_PAYROLLS(state, array) {
        state.payrolls = array;
    },
    SET_PAYROLL_EMPLOYEES(state, array) {
        state.employees = array;
    },
    SET_PAYROLL_DATA(state, array) {
        state.payroll_data = array;
        localStorage.setItem('payroll_data', JSON.stringify(array));
    },
    SET_PAYROLL_TRANSACTIONS(state, array) {
        state.transactions = array;
    },
}
