import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const projects = {
    namespaced: true,
    state: {
        projects: [],
        project: {},
        milestones: [],
        expenses: [],
        time_logs: [],
        tasks: [],
        collaborators: [],
        invite: {},
        opportunity: {},
        milestone: {},
        metrics: {},
        projects_collaborating: []
    },
    actions,
    getters,
    mutations
}
