<template>
  <div class="home" :class="{blur:!loaded}">
    <headers class="bg-ggray pb-5">
      <div slot="breadcrumbs">
        <breadcrumbs :items="breadCrumbs"></breadcrumbs>
      </div>
      <div slot="title">
        <h2>Salary for {{ toLocal(payroll.start_date).format('ll') }} - {{toLocal(payroll.end_date).format('ll')}}</h2>
        <button class="btn btn-ds" @click="exportPayroll">
            <span class="pr-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path
                  fill="#FFF"
                  d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 4c-.552 0-1 .448-1 1v2H5c-.552 0-1 .448-1 1s.448 1 1 1h2v2c0 .552.448 1 1 1s1-.448 1-1V9h2c.552 0 1-.448 1-1s-.448-1-1-1H9V5c0-.552-.448-1-1-1z"
                ></path>
              </svg>
            </span>
            Export Payroll
          </button>
      </div>
       
    </headers>

    <div class="container-fluid pl-3 pr-4 pt-5">
      <div class="row pl-3">
        <div class="col-sm-12 col-lg-3">
          <div class="row">
            <div class="col-sm-12">
              <div class="card w-70">
                <div class="card-header no-right-padding-card">
                  Payroll Details
                  <span class="btn-icon-sm float-right">
                    <svg
                      width="20"
                      height="20"
                      fill="#72809D"
                      viewBox="0 0 30 30"
                      xmlns="http://www.w3.org/2000/svg"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                    >
                      <path
                        d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
                      ></path>
                    </svg>
                  </span>
                  <router-link :to="{path: `/payments/${payroll.id}/process`}">
                    <span class="btn-icon-sm float-right">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#72809D"
                        viewBox="0 0 30 30"
                      >
                        <path
                          d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"
                        ></path>
                      </svg>
                    </span>
                  </router-link>
                </div>
                <div class="card-content p-3 pb-5">
                  <div class="row">
                    <div class="col-sm-6">Pay date:</div>
                    <div class="col-sm-6 float-right text-right">{{ toLocal(payroll.created_at).format('ll') }}</div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-sm-6">Period:</div>
                    <div class="col-sm-6 float-right text-right">{{ toLocal(payroll.start_date).format('ll') }} - {{toLocal(payroll.end_date).format('ll')}}</div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-sm-6">Process date:</div>
                    <div class="col-sm-6 float-right text-right">{{ payroll.schedule_date ? toLocal(payroll.schedule_date).format('ll') : 'N/A'}}</div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-sm-6">Type:</div>
                    <div class="col-sm-6 float-right text-right">{{payroll.employee_type && payroll.employee_type.name}}</div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-sm-6">From:</div>
                    <div class="col-sm-6 float-right text-right">{{payroll.creator && payroll.creator.data ? `${payroll.creator.data.first_name} ${payroll.creator.data.last_name}` : 'N/A'}}</div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-sm-6">Purpose:</div>
                    <div class="col-sm-6 float-right text-right">{{payroll.purpose}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-9 pl-5 col-md-8 float-right">
          <div class="tab-wrapper tab-wrapper-mobile mb-1">
            <ul class="tabs">
              <router-link :to="{name: 'payments-show', params: {id:payroll.id}}">
                <li>Transactions</li>
              </router-link>
            </ul>
          </div>

          <div class="table" v-if="loaded">
            <vuetable
              ref="vuetable"
              :api-mode="false"
              :data="payroll.transactions.data"
              :first-page="0"
              :fields="columns.filter(s => s.active === true)"
              :no-data-template="rowCount === null ? 'Loading data' : 'No Data Available'"
            >
              <template slot="sn" slot-scope="props">
                <router-link
                    :to="{ name: 'users-show', params: { id: optional(props.rowData.employee, 'data.user.data.id', null) } }"
                >{{ props.rowIndex + 1 }}</router-link
                >
              </template>

              <template slot="hourly_rate" slot-scope="{ rowData }">
                <h6 class="text-bold h7">
                  {{ rowData.currency | optional('symbol', '') }}
                  {{ optional(rowData.employee, 'data.employee_type_id', 0) == 3
                    ? optional(rowData.employee, 'data.hourly_rate', '')
                    : optional(rowData.employee, 'data.salary_rate', '')
                  }}
                </h6>
              </template>
              <template slot="amount" slot-scope="{ rowData }">
                <h6 class="text-bold h7">
                  {{ rowData.currency | optional('symbol', '') }} {{numberWithCommas(parseFloat(rowData.amount || 0).toFixed(2))}}
                </h6>
              </template>
              <template slot="actions" slot-scope="{ rowData }">
                <router-link :to="{name: 'users-show', params: { id: optional(rowData.employee, 'data.user.data.id', null) }}">
                  <button class="btn-lightgray-round">
                    <i class="zmdi zmdi-plus pr-2"></i>View Employee
                  </button>
                </router-link>
              </template>
            </vuetable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Headers from "@/components/Layouts/Headers";
import Breadcrumbs from "@/components/Breadcrumbs/Index";
import getOrganization from "@/helpers/getOrganization";
import {toLocal} from "@/helpers/date";
import { numberWithCommas } from "@/helpers/number";
import { optional } from "@/helpers/random";

export default {
  title: 'Show Details - Payroll',
  components: { Headers, Breadcrumbs },
  data() {
    return {
      getOrganization,
      rowCount: null,
      columns: [
        {
          name: "__slot:sn",
          title: "S/N",
          cvisible: true,
          active: true,
        },
        {
          name: "reference_id",
          title: "Reference",
          cvisible: true,
          active: true,
        },
        {
          name: "employee.data.user.data",
          title: "Employee",
          cvisible: true,
          active: true,
          callback: (e) => {
            return e ? `${e.first_name} ${e.last_name}` : "N/A";
          },
        },
        {
          name: "__slot:hourly_rate",
          title: "Rate",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:amount",
          title: "Amount Due",
          cvisible: true,
          active: true,
          sortable: true,
        },
        {
          name: "payment_source.name",
          title: "Payment Method",
          cvisible: true,
          active: true,
        },
        {
          name: "status",
          title: "Status",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:actions",
          title: "Actions",
          active: true,
          cvisible: false,
        },
      ],
      loaded:false,
      breadCrumbs: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "All Payments",
          href: "payments"
        },
        {
          text: "Project",
          active: true
        }
      ],
    };
  },
  computed : {
    payroll() {
      return this.$store.getters['payrolls/payroll'];
    },
  },
  methods : {
    toLocal,
    optional,
    numberWithCommas,
    exportPayroll() {
      this.$store.dispatch('payrolls/export', this.payroll.id)
      .then((data) => {
        console.log('what we have', data)
      }).catch(err => {
        
        this.$toast.error('Export failed. Please try again', {
              position: "top-right",
              duration: 3000,
            });
      })
    }
  },
  created() {
    this.$store.dispatch(
          'payrolls/getTransactions',
          {id: this.$route.params.id, params: `transactions.paymentSource,creator`}
    ).then(() => {
      this.loaded = true;
      this.rowCount = this.payroll.transactions.data.length;
    });
  }
};
</script>
