<template>
  <div class="wrapper">
    <div class="row">
      <div class="col-sm-12 col-lg-6 wrapper-content-left">
        <div class="logo pl-4 mb-4">
          <img src="@/assets/img/leaf.svg" />
        </div>
      </div>
      <div class="col-sm-12 col-lg-6 wrapper-content-right">
        <div class="content">
          <div class="row mt-5 pt-5 pl-5 pr-5">
            <div class="col-sm-12 pt-5 mt-2">
              <div class>
                <h2>{{ Literals.LOGIN_HEADER_CAPTION }}</h2>
              </div>
            </div>

            <!-- alerts -->
            <div class="col-sm-12 col-lg-10 mt-4" v-show="errorMsg.length > 0">
              <error-alert :message="errorMsg"></error-alert>
            </div>
            <div class="col-sm-12 col-lg-10 mt-4" v-show="successMsg">
              <success-alert :message="successMsg"></success-alert>
            </div>
            <div
              class="col-sm-12 col-lg-10 mt-4"
              v-show="$route.query && $route.query.verified"
            >
              <success-alert :message="Literals.VERIFIED_EMAIL"></success-alert>
            </div>

            <!-- end -->

            <div class="col-sm-12 pt-5 mt-2">
              <form v-show="!showOtp" @submit.prevent="login">
                <div class="row auth-form">
                  <div class="col-sm-12 col-lg-10">
                    <h6>{{ Literals.EMAIL_INPUT_CAPTION }} <b>*</b></h6>
                    <input
                      type="email"
                      name="email"
                      v-model="body.email"
                      data-vv-as="email"
                      v-validate="'required|email'"
                      class="form-control auth-input"
                      placeholder="Enter your email address"
                    />
                    <small
                      class="form-text text-danger"
                      v-show="errors.has('email')"
                      >{{ errors.first("email") }}</small
                    >
                  </div>

                  <div class="col-sm-12 col-lg-10 mt--6">
                    <span class="h6">{{
                      Literals.PASSWORD_INPUT_CAPTION
                    }}</span>
                    <small class="text-link text-opacity float-right">
                      <router-link :to="{ name: 'forgot-password' }">{{
                        Literals.FORGOT_PASSWORD_CAPTION
                      }}</router-link>
                    </small>

                    <div>
                      <input
                        :type="passwordType"
                        name="password"
                        v-model="body.password"
                        v-validate="'required|min:8'"
                        data-vv-as="password"
                        class="form-control auth-input"
                        placeholder="Enter your Password"
                      />
                      <small
                        class="form-text text-danger"
                        v-show="errors.has('password')"
                        >{{ errors.first("password") }}</small
                      >
                      <span @click="togglePassword" class="show-password">
                        <i
                          :class="
                            passwordType === 'password'
                              ? 'zmdi zmdi-eye'
                              : 'zmdi zmdi-eye-off'
                          "
                        ></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-sm-12 col-lg-10 mt--6">
                    <label class="checkbox-inline">
                      <input type="checkbox" v-model="body.rememberMe" value />
                      <small class="pl-2 font-14">Keep me logged in</small>
                    </label>
                  </div>

                  <div class="col-sm-12 col-lg-10 mt--6">
                    <Auth-Button :loading="loading" text="Log in" />
                  </div>

                  <div class="col-sm-12 col-lg-10 mt--6">
                    <h6>
                      <span class="text-opacity"
                        >Don't have a Leaf account?</span
                      >
                      <router-link :to="{ name: 'Register' }" class="pl-1"
                        >Sign up</router-link
                      >
                    </h6>
                  </div>
                </div>
              </form>
              <transition name="slide-fade">
                <form v-show="showOtp" @submit.prevent="otpLogin">
                  <div class="row auth-form">
                    <div class="col-sm-12 col-lg-10">
                      <h6>Enter your 5 Digit OTP</h6>
                      <!-- <input
                        type="hidden"
                        name="email"
                        v-model="body.email"
                        class="form-control auth-input"
                        placeholder="Enter your email address"
                      >-->
                      <input
                        type="number"
                        name="otp"
                        v-model="body.otp"
                        class="form-control auth-input"
                        placeholder="Enter 5 digits OTP"
                      />
                      <small
                        class="form-text text-danger"
                        v-show="errors.has('otp')"
                        >{{ errors.first("otp") }}</small
                      >
                    </div>

                    <div class="col-sm-12 col-lg-10 mt--6">
                      <Auth-Button :loading="loading" text="Log in" />
                    </div>

                    <div class="col-sm-12 col-lg-10 mt--6">
                      <h6>
                        <span class="text-opacity"
                          >Don't have a Leaf account?</span
                        >
                        <router-link :to="{ name: 'Register' }" class="pl-1"
                          >Sign up</router-link
                        >
                      </h6>
                    </div>
                  </div>
                </form>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthButton from "@/components/Buttons/Auth.vue";
import ErrorAlert from "@/components/Alerts/Error.vue";
import SuccessAlert from "@/components/Alerts/Success.vue";
import Literals from "@/helpers/auth/literals.js";
import { updatePending } from "@/helpers/axiosIntercept";
import handleError from "@/helpers/error";
import { handleRedirect } from "@/helpers/random";

export default {
  title: "Login",
  data() {
    return {
      Literals,
      passwordType: "password",
      body: {
        email: "",
        password: "",
        otp: "",
        rememberMe: false,
      },
      loading: false,
      showOtp: false,
      errorMsg: [],
      successMsg: this.$route.params.msg,
    };
  },
  components: { AuthButton, ErrorAlert, SuccessAlert },
  methods: {
    togglePassword() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    login() {
      this.errorMsg = [];
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    otpLogin() {
      this.errorMsg = [];
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          let otp_data = {
            email: this.body.email,
            otp: this.body.otp,
          };

          //let's log the user in using otp
          this.$auth
            .login({
              url: this.$otpLoginUrl,
              data: otp_data,
              rememberMe: this.body.rememberMe,
              staySignedIn: this.body.rememberMe,
              redirect: false,
            })
            .then(({ response }) => {
              if (response && response.status !== 200) {
                handleError(
                  this,
                  response,
                  response.data && response.data.message
                );
              } else {
                handleRedirect(this);
                // this.$router.push({name: 'organization'})
              }
            })
            .catch((error) => {
              handleError(
                this,
                error.response,
                error &&
                  error.response &&
                  error.response.data &&
                  error.response.data.message
              );
            })
            .finally(() => {
              updatePending(this.$otpLoginUrl);
              this.loading = false;
            });
        }
      });
    },
    submit() {
      this.$auth
        .login({
          data: this.body,
          rememberMe: this.body.rememberMe,
          staySignedIn: this.body.rememberMe,
          redirect: false,
        })
        .then((res) => {
          if (
            (res.response && res.response.status === 202) ||
            res.status === 202
          ) {
            //if it has otp in login
            this.errorMsg.push(Literals.OTP_ERROR);
            this.showOtp = true;
          } else if (
            (res.response && res.response.status !== 200) ||
            res.status !== 200
          ) {
            handleError(
              this,
              res.response,
              res.response && res.response.data && res.response.data.message
            );
          } else {
            handleRedirect(this);
          }
        })
        .catch((err) => {
          let response = err.response;
          handleError(
            this,
            response,
            response && response.data && response.data.message
          );
        })
        .finally(() => {
          updatePending(this.$loginUrl);
          this.loading = false;
        });
    },
  },
  created() {
  
    window.localStorage.setItem(
        "redirect_from",
        this.$auth.redirect() && this.$auth.redirect().from.path != '/' ? this.$auth.redirect().from.name : 'dashboard'
      );

    if (this.$auth.redirect() && this.$auth.redirect().from.name == "invite") {
      
      this.errorMsg.push(
        "You have to be logged in to accept/decline an invite"
      );
      
      }
    
    let errorMsg = window.localStorage.getItem("error_message");

    if (errorMsg) {
      this.errorMsg.push(errorMsg);
      window.localStorage.removeItem("error_message");
    }

    if (this.$route.query.is_otp) {
      this.body.email = this.$route.query.is_otp;
      this.showOtp = true;
    }
  },
};
</script>
