<template>
    <div>
        <user-modal :user="user" :employee="employee" v-if="user || employee" ></user-modal>
    </div>
</template>
<script>
    import UserModal from "@/components/Modals/Users";
    export default {
        title: `Edit | Users`,
        components: { UserModal },
        data() {
            return {
                statuses: [{id: true, name: "Active"}, {id: false, name: "Inactive"}],
            };
        },
        computed: {
            employee() {
                return this.$store.getters["employees/employee"];
            },
            user() {
                return this.$store.getters["employees/user"];
            },
        },
        created() {
            this.$store.dispatch("employees/getByUserId", this.$route.params.id);
        }
    };
</script>
