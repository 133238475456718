<template>
  <div class="overlay" :class="{blur:!loaded}">
    <div @click="closeTask" class="container task-wrapperx"></div>
    <div class="container back-btn" v-if="task && task.project">
      <router-link
        :to="$route.path.includes('milestones') ? {name: 'projects-show-milestones', params:{mid:task.project_milestone_id}} : {name: 'tasks-index', params: {id: task.project_id}}"
        class="pl-1"
        id="backLink"
        style="color:white !important;opacity:.7"
      >
        <button class="mr-2 btn-icon">
          <span class="text-bold text-opacity-pale">
            <span>
              <i class="zmdi zmdi-long-arrow-left" style="font-size:20px; color:#72809D"></i>
            </span>
          </span>
        </button>
        <small>{{ `Back to ${$route.path.includes('milestones') ? 'Milestones' : 'Tasks'}` }}</small>
      </router-link>
    </div>
    <div class>
      <div class="container task-wrapper">
        <div class="row pt-4">
          <div class="col-sm-6">
            <router-link :to="{name: 'milestones',params: {mid:this.milestone.id}}">
              <button class="btn-action pr-3 pl-2 mr-2">
                <span class="text-bold h7 text-opacity-pale">
                  <span class="pr-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <rect width="24" height="24" fill="#FFF" rx="4"></rect>
                        <g
                          stroke="#72809D"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                          transform="translate(5 3)"
                        >
                          <path
                            d="M11.233 5.641L12.656 4.219M12.375 3.938L12.938 4.5M6.75 3.938L6.75.563M8.438.563L5.063.563"
                          ></path>
                          <circle cx="6.75" cy="10.688" r="6.75"></circle>
                          <path
                            d="M6.75 6.188L6.75 6.188M6.75 6.188c-.155 0-.281.125-.281.28 0 .156.126.282.281.282.155 0 .281-.126.281-.281 0-.156-.126-.282-.281-.282M6.75 14.625c-.155 0-.281.126-.281.281 0 .156.126.281.281.281.155 0 .281-.125.281-.28 0-.156-.126-.282-.281-.282h0M2.531 10.406c-.155 0-.281.126-.281.281 0 .156.126.282.281.282.156 0 .281-.126.281-.281 0-.156-.125-.282-.28-.282h0M10.969 10.406c-.156 0-.281.126-.281.281 0 .156.125.282.28.282.156 0 .282-.126.282-.281 0-.156-.126-.282-.281-.282h0M3.767 7.425c-.114 0-.216.068-.26.173-.044.105-.02.226.06.307.11.11.289.11.399 0 .11-.11.11-.288 0-.397-.053-.053-.124-.083-.199-.083h0M9.733 13.39c-.075 0-.146.03-.199.082-.095.095-.11.244-.035.355.075.112.218.155.342.104.124-.052.194-.184.168-.315-.026-.132-.142-.226-.276-.226h0M3.767 13.39c-.114 0-.216.068-.26.173-.044.105-.02.226.06.307.072.07.175.099.272.073.098-.026.173-.102.2-.199.026-.097-.002-.2-.073-.272-.053-.053-.124-.082-.199-.082h0M9.932 7.506c-.053-.053-.124-.083-.2-.083h0c-.074 0-.145.03-.198.083-.11.11-.11.288 0 .398.11.11.288.11.398 0 .11-.11.11-.288 0-.398"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </span>
                  Go to Tasks
                </span>
              </button>
            </router-link>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12 float-right text-right">
                <router-link :to="{name: 'task-show-full', params: {id: this.$route.params.tid}}">
                  <button
                    class="mr-2 btn-icon"
                    v-b-tooltip.hover
                    title="Open Milestone in Fullpage"
                  >
                    <span class="text-bold text-opacity-pale">
                      <span>
                        <i class="zmdi zmdi-open-in-new" style="font-size:20px; color:#72809D"></i>
                      </span>
                    </span>
                  </button>
                </router-link>
                <router-link
                  :to="{name: `${$route.path.includes('milestones') ? 'milestone-' : ''}tasks-preview-subtasks-new`, params: {id: this.$route.params.tid}}"
                >
                  <button class="btn-icon mr-2" v-b-tooltip.hover title="Create Milestone">
                    <span class="text-bold text-opacity-pale">
                      <span class>
                        <i
                          class="zmdi zmdi-close"
                          style="font-size:20px; color:#72809D;transform:rotate(45deg)"
                        ></i>
                      </span>
                    </span>
                  </button>
                </router-link>
                <button class="btn-icon" @click="closeTask" v-b-tooltip.hover title="Close task">
                  <span class="text-bold text-opacity-pale">
                    <span class>
                      <i class="zmdi zmdi-close" style="font-size:20px; color:#72809D"></i>
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div class="col-sm-12 mt-4 pl-4">
            <!-- <h5 class="text-gray">{{task.name}}</h5> -->
            <div class>
              <div v-if="!isEditingTask" class="d-flex flex-row align-items-center">
                <h5 class="text-gray">
                  {{milestone.name}}
                  <sup class="mt-1">
                    <button
                      class="ml-1 mt-2"
                      @click="isEditingTask=true;"
                      type="button"
                      style="height:25px;width:25px;border-radius:25px;background:#F5F5F5;border:none"
                    >
                      <i class="zmdi zmdi-edit"></i>
                    </button>
                  </sup>
                </h5>
              </div>
              <div class="d-flex flex-column editable" v-else>
                <textarea
                  name="task_name"
                  ref="editableField"
                  class="editable-field text-gray pt-2 pb-2 w-100"
                  v-model="milestone.name"
                ></textarea>
                <div class="d-flex justify-content-end mt-1">
                  <button class="editable-save pl-3 pr-3" @click="updateTaskInfo">
                    <h6 class="h6 text-opacity-pale text-bold mb-0">Save</h6>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 mt-2 pl-4">
            <div class="row">
              <div class="col-sm-5">
                <multiselect
                  track-by="id"
                  label="name"
                  :disabled="taskStatusUpdating"
                  :class="generateTaskClass()"
                  v-model="milestone.status"
                  :options="milestoneStatuses"
                  @input="changeTaskStatus"
                  :show-labels="false"
                >
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <i class="zmdi zmdi-chevron-down pt-1" style="font-size:20px"></i>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-12 mt-4">
            <div class="tab-wrapper tab-wrapper-mobile">
              <ul class="tabs">
                <router-link
                  :class="'router-link-exact-active'"
                  :to="{name: `${$route.path.includes('milestones') ? 'milestone-' : ''}tasks-preview-show`}"
                >
                  <li>General</li>
                </router-link>
              </ul>
            </div>
          </div>
          <div class="mt-2 pl-2 scrollable" style="height:60vh !important">
            <div class="col-sm-12 content-header pl-4 mt-0">
              <h6 class="text-bold text-opacity-pale text-opacity">
                Description
                <span class="ml-2" v-show="!isEditingDescription">
                  <sup class="mt-1">
                    <button
                      class="ml-1 mt-2"
                      @click="isEditingDescription=true"
                      type="button"
                      style="height:25px;width:25px;border-radius:25px;background:#F5F5F5;border:none;font-size:15px"
                    >
                      <i class="zmdi zmdi-edit"></i>
                    </button>
                  </sup>
                </span>
              </h6>
              <div class="d-flex flex-row" v-if="!isEditingDescription">
                 <span class="h6 h6-e" v-html="milestone.description"></span>
              </div>
              <!-- <span>NA</span> -->
              <div class="d-flex flex-column editable" v-else>
                <quill-editor
                  ref="myQuillEditor"
                  id="o-edit"
                  class="mt-1"
                  style="height:100px"
                  v-model="milestone.description"
                   @ready="_html(milestone.description)"
                  placeholder="Enter Message"
                  :options="customToolbar"
                />
                <div class="d-flex justify-content-end mt-5" @click="updateTaskInfo">
                  <button class="editable-save pl-3 pr-3">
                    <h6 class="h6 text-opacity-pale text-bold mb-0">Save</h6>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-sm-12 pl-4 mt-4">
              <h6 class="text-bold text-opacity-pale text-opacity">Amount</h6>
              {{milestone.amount ? milestone.amount : 'N/A'}}
            </div>
            <div class="col-sm-12 pl-4 mt-4">
              <h6 class="text-bold text-opacity-pale text-opacity">Tasks</h6>
              <div
                class="mt-3"
                v-if="milestone.tasks && milestone.tasks.data && milestone.tasks.data.length == 0"
              >
                <h6
                  class="h7 text-opacity-pale text-bold"
                >No task has been added to this milestone yet</h6>
              </div>
              <div class="mt-3 kanban-board" v-else>
                <div
                  class="kanban-board__status w-100"
                  v-for="(sub,index) in milestone.tasks.data"
                  :key="index"
                  :id="sub.status && sub.status.name.replace(' ', '').replace('/', '')"
                >
                  <router-link
                    :to="{name:  'project-tasks-show-general',params: {tid:sub.id},query: {subtask: true, from: $route.params.tid}}"
                  >
                    <div class="kanban-board__tasks p-3">
                      <div class="d-flex flex-row align-items-center">
                        <div class="mr-3">
                          <button
                            class="kanban-board__tasks-profile"
                            v-b-tooltip.hover
                            :title="optional(sub, 'employee.data.user.data', null) ? sub.employee.data.user.data.first_name + ' ' +sub.employee.data.user.data.last_name : '-'"
                          >{{optional(sub, 'employee.data.user.data', null) ? sub.employee.data.user.data.first_name.charAt(0)+sub.employee.data.user.data.last_name.charAt(0) : '-'}}</button>
                        </div>
                        <div class="mr-3">
                          <h6 class="h6 text-opacity-pale">{{sub.name}}</h6>
                        </div>
                        <div class="mr-3">
                          <span class="badge">{{sub.status && sub.status.name}}</span>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped >
$done: green;
$inprogress: #cccc11;
$pending: red;
$cancelled: #714242;
$testing: #0a5685;
#subtasks {
  .kanban-board__tasks {
    border: 2px solid #f5f5f5;
    &:not(:last-child) {
      border-bottom: none !important;
      display: none;
    }
  }
  .badge {
    padding: 6px;
  }
  #NotStarted {
    .badge {
      background: $pending;
      color: white !important;
    }
  }
  #InProgress {
    .badge {
      background: $inprogress;
      color: white !important;
    }
  }
  #TestingReview {
    .badge {
      background: $testing;
      color: white !important;
    }
  }
  #Done {
    .badge {
      background: $done;
      color: white !important;
    }
  }
  .kanban-board__status {
    &:not(:last-child) {
      .kanban-board__tasks {
        border-bottom: none !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.editable {
  position: relative;
  .editable-field {
    font-size: 1.25rem;
    border: 2px solid #f5f5f5;
    outline: none;
    resize: none;
    height: min-content;

    &:focus {
      outline: none;
    }
    &:hover {
      border: 2px solid #f5f5f5;
    }
  }
  .editable-save {
    visibility: hidden;
    background: #e4e4e4;
    border: none;
    height: 35px;
    border-radius: 10px;
    position: absolute;
    bottom: -35px;
    z-index: 5;

    &:hover {
      background: #2c3f58;
      color: white !important;
      h6 {
        color: white !important;
      }
    }
  }
  &:focus-within {
    .editable-save {
      visibility: visible;
    }
  }
}
.overlay {
  position: fixed;
  background: linear-gradient(rgba(20, 20, 20, 0.8), rgba(20, 20, 20, 0.8));
  height: 100%;
  top: 0;
  width: 100%;
  z-index: 4;
  left: 0;

  .scrollable {
    max-height: 98vh;
    overflow-y: scroll;
  }
  .back-btn {
    width: 200px;
    position: absolute;
    z-index: 100;
    margin-top: 30px;
    left: 0;
    top: 0;

    #backLink {
      &:hover {
        opacity: 1 !important;
      }
    }
  }
  .task-paned {
    background: linear-gradient(rgba(20, 20, 20, 0.8), rgba(20, 20, 20, 0.8));
    height: inherit;

    .task-wrapper {
      max-width: 100vw !important;
      width: 100vw !important;

      .scrollable {
        width: 600px !important;
        margin: auto;
      }
    }
  }
  .task-wrapper {
    background: white;
    position: absolute;
    width: 520px;
    right: 0;
    height: 100vh;
  }
  .task-wrapperx {
    position: absolute;
    height: 100vh;
    width: 100%;
  }
}
.text-gray {
  color: #2c3f58;
}
button {
  &.btn-icon {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    border: 0;
    &:hover {
      background: #f7f7f7;
    }
  }
  &.btn-action {
    height: 40px;
    border-radius: 5px;
    border: 0px;
    &:hover {
      background: #f7f7f7;
    }
  }
}
</style>

<script>
import LogTime from "@/components/Modals/Time";
import LogExpense from "@/components/Modals/Expense";
import Literals from "@/helpers/dashboard/literals";
import { optional } from "@/helpers/random";
export default {
  title: "Show | Tasks",
  components: {},
  data() {
    return {
      optional,
      Literals,
      loaded: true,
      isEditingTask: false,
      showLogTime: false,
      showExpenseForm: false,
      taskStatusUpdating: false,
      isEditingDescription: false,
      currentTaskStatus: {},
      timeLog: {},
      expense: {},
      customToolbar: {
        modules: {}
      }
    };
  },
  methods: {
    closeTask() {
      this.$router.go(-1);
    },
    updateFieldStatus(status) {
      this.isEditingTask = status;
      if (this.isEditingTask) {
        document.querySelector(".editable-field").spellcheck = true;
      } else {
        // textarea.focus();
        let textarea = document.querySelector(".editable-field");
        textarea.spellcheck = false;
        textarea.removeAttribute("spellcheck");
      }
    },
    updateTaskInfo() {
      this.$store
        .dispatch("projects/createUpdateMilestone", {
          id: this.milestone.id,
          name: this.milestone.name,
          project_id: this.milestone.project_id,
          description: this.milestone.description,
          status_id:
            this.milestone && this.milestone.status && this.milestone.status.id
        })
        .then(s => {
          this.$toast.success(Literals.MILESTONE_UPDATE_SUCCESS, {
            position: "top-right",
            duration: 3000
          });
          this.isEditingTask = false;
          this.$store.dispatch("tasks/index");
        });
    },
    setTimeLog(timeLog) {
      this.timeLog = timeLog;
      this.showLogTime = true;
    },
    setExpenseLog(expense) {
      this.expense = expense;
      this.showExpenseForm = true;
    },
    cancelExpense(expense) {
      this.expense = expense;
      this.showExpenseForm = false;
    },
    cancelTimeLog() {
      this.timeLog = {};
      this.showLogTime = false;
    },
    changeTaskStatus($event) {
      this.taskStatusUpdating = true;
      this.$store
        .dispatch("projects/createUpdateMilestone", {
          id: this.milestone.id,
          name: this.milestone.name,
          project_id: this.milestone.project_id,
          status_id:
            this.milestone && this.milestone.status && this.milestone.status.id
        })
        .then(s => {
          this.$toast.success(
            `Milestone successfully changed to ${this.milestone.status.name}`,
            {
              position: "top-right",
              duration: 3000
            }
          );
          this.$store.dispatch("tasks/index");
          this.taskStatusUpdating = false;
        })
        .catch(() => {
          this.$toast.error("Failed to update status!", {
            position: "top-right",
            duration: 3000
          });
          this.taskStatusUpdating = false;
        });
    },
    generateTaskClass() {
      return this.milestone.status
        ? this.milestone.status.name
            .toLowerCase()
            .replace(" ", "_")
            .replace("/", "") + " table-select task-status "
        : "table-select task-status";
    }
  },
  computed: {
    taskStatuses() {
      return this.$store.getters["global/task_statuses"];
    },
    task() {
      return this.$store.getters["tasks/task"];
    },
    milestone() {
      return this.$store.getters["projects/milestone"];
    },
    milestoneStatuses() {
      return this.$store.getters["global/milestone_statuses"];
    }
  },
  created() {
    Promise.all([
      this.$store.dispatch("projects/getMilestone", this.$route.params.mid),
      this.$store.dispatch("global/getMilestoneStatuses")
    ]).finally(() => {
      this.loaded = true;
    });
  },
  watch: {
    // $route(from) {
    //   Promise.all([
    //     this.$store.dispatch("tasks/getSingle", this.$route.params.tid)
    //   ]);
    // }
  }
};
</script>
