<template>
  <div v-if="showAlert" class="alert alert-success alert-dismissible fade show" role="alert">
    <small>{{message}}</small>
    <slot name="extras"></slot>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="closeAlert()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
export default {
  props: ["message"],
  data() {
    return {
      showAlert: true
    }
  },
  methods : {
    closeAlert() {
      this.showAlert = false;
    }
  }
};
</script>
