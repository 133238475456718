<template>
  <!-- Modal -->
  <b-modal @hide="close" :no-close-on-backdrop="true" ref="my-modal" class="deletemodal">
    <template v-slot:modal-header>
      <h2 class="pl-3 pt-3 pr-3 f-24">{{`${body.id ? 'Edit' : 'Create New'} Opportunity`}}</h2>
      <button class="close" @click="close">
        Cancel
        <i class="zmdi zmdi-close-circle pl-2" style="font-size:20px"></i>
      </button>
    </template>
    <div class="d-block pl-3">
      <form class="d-form" @submit.prevent="save">
        <div class="row">
          <div class="col-sm-12 col-lg-6">
            <h6 class="text-bold text-opacity-pale">
              Opportunity Name
              <b>*</b>
            </h6>
            <input
              type="text"
              v-model="body.name"
              v-validate="'required'"
              name="name"
              class="form-control auth-input d-input"
              placeholder="Enter Opportunity name"
              data-vv-as="Opportunity Name"
            >
            <small
              class="form-text text-danger"
              v-show="errors.has('name')"
            >{{errors.first('name')}}</small>
          </div>
          <div class="col-sm-12 col-lg-6">
            <h6 class="text-bold text-opacity-pale">Anticipated Project personnel</h6>
            <multiselect
              track-by="id"
              return="id"
              label="name"
              v-model="body.employee"
              name="employee"
              :options="employees"
              :show-labels="false"
              data-vv-as="Personnel"
              placeholder="Select Project Personnel"
            ></multiselect>
            <small
              class="form-text text-danger"
              v-show="errors.has('employee_id')"
            >{{errors.first('employee_id')}}</small>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-12 col-lg-6">
            <h6 class="text-bold text-opacity-pale">
              Customer
              <b>*</b>
            </h6>
            <multiselect
              track-by="id"
              label="name"
              v-model="body.customer"
              name="customer"
              :options="customers"
              v-validate="'required'"
              :show-labels="false"
              data-vv-as="Customer"
              placeholder="Select Customer"
            ></multiselect>
            <small
              class="form-text text-danger"
              v-show="errors.has('customer')"
            >{{errors.first('customer')}}</small>
          </div>
          <div class="col-sm-12 col-lg-6">
            <h6 class="text-bold text-opacity-pale">Project Duration</h6>
            <div class="row">
              <div class="col-sm-12 col-lg-6 date-range">
                <vc-date-picker
                  :masks="{ input: ['YYYY-MM-DD']}"
                  :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: `Start date`}"
                  v-model="body.proposed_start_date"
                  name="proposed_start_date"
                  ref="startFrom"
                  data-vv-as="Project Start date"
                />
                <small class="dpicker-icon">
                  <i class="zmdi zmdi-calendar-alt"></i>
                </small>
              </div>
              <div class="col-sm-12 col-lg-6 date-range">
                <vc-date-picker
                  :masks="{ input: ['YYYY-MM-DD']}"
                  :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: ` End date`}"
                  v-model="body.proposed_end_date"
                  v-validate="'after:startFrom'"
                  name="proposed_end_date"
                  data-vv-as="Project End Date"
                />
                <small class="dpicker-icon">
                  <i class="zmdi zmdi-calendar-alt"></i>
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-12 col-lg-6">
            <h6 class="text-bold text-opacity-pale">
              Stage
              <b>*</b>
            </h6>
            <multiselect
              track-by="id"
              label="name"
              v-validate="'required'"
              v-model="body.opportunity_stage"
              name="opportunity_stage_id"
              :options="opportunity_stages"
              :show-labels="false"
              data-vv-as="Stage"
              placeholder="Select Opportunity Stage"
            ></multiselect>
            <small
              class="form-text text-danger"
              v-show="errors.has('opportunity_stage_id')"
            >{{errors.first('opportunity_stage_id')}}</small>
          </div>

          <div class="col-sm-12 col-lg-6">
            <h6 class="text-bold text-opacity-pale">
              Currency
              <b>*</b>
            </h6>
            <multiselect
              :options="currencies"
              :show-labels="false"
              data-vv-as="Currency"
              label="name"
              name="currency"
              :custom-label="currencySelectLabel"
              placeholder="Select Currency"
              track-by="id"
              v-validate="'required'"
              v-model="body.currency"
            ></multiselect>
            <small
              class="form-text text-danger"
              v-show="errors.has('currency')"
            >{{errors.first('currency')}}</small>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-12 col-lg-12">
            <h6 class="text-bold text-opacity-pale">Project Type</h6>
            <div class="input-group">
              <div id="radioBtn" style="width:100%" class="btn-group">
                <a
                  :key="index"
                  v-for="(billing_type, index) in project_billing_types"
                  :class="{
                                          'btn btn-radio btn-sm active' : body.billing_type.id === project_billing_types[index].id,
                                          'btn btn-radio btn-sm' : !body.billing_type || body.billing_type.id !== project_billing_types[index].id
                                        }"
                  @click="toggleSettlement(billing_type.id)"
                >{{billing_type.name}}</a>
              </div>
              <input
                type="hidden"
                v-model="body.billing_type_id"
                name="billing_type"
                id="billing_type"
              >
            </div>

            <div class="row mt-4">
              <div
                v-if="body.billing_type && body.billing_type.id === 1"
                class="col-sm-12 col-lg-12"
              >
                <h6 class="text-bold text-opacity-pale">
                  Projected Revenue
                  <b v-if="body.billing_type.id == 1">*</b>
                </h6>
                <input
                  class="form-control auth-input d-input"
                  type="number"
                  v-model="body.projected_revenue"
                  name="projected_revenue"
                  data-vv-as="Projected Revenue"
                  v-validate=" body.billing_type.id === 1 ? 'required': ''"
                  placeholder="Enter Project Revenue"
                >
                <small
                  class="form-text text-danger"
                  v-show="errors.has('projected_revenue')"
                >{{errors.first('projected_revenue')}}</small>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div v-if="body.billing_type && body.billing_type.id === 2" class="col-sm-12 col-lg-6">
            <h6 class="text-bold text-opacity-pale">
              Hourly Rate
              <b v-if="body.billing_type.id == 2">*</b>
            </h6>
            <input
              class="form-control auth-input d-input"
              data-vv-as="Hourly Rate"
              name="hourly_rate"
              placeholder="Enter Hourly Rate"
              type="number"
              v-model="body.hourly_rate"
              v-validate="body.billing_type.id === 2 ? 'required' : ''"
            >
            <small
              class="form-text text-danger"
              v-show="errors.has('hourly_rate')"
            >{{errors.first('hourly_rate')}}</small>
          </div>
          <div class="col-sm-12 col-lg-6">
            <div class="row">
              <div
                v-if="body.billing_type && body.billing_type.id === 2"
                class="col-sm-12 col-lg-6"
              >
                <h6 class="text-bold text-opacity-pale">Total estimated hours</h6>
                <input
                  class="form-control auth-input d-input"
                  v-model="body.estimated_hours"
                  type="number"
                  name="estimated_hours"
                  data-vv-as="Estimated Hours"
                  placeholder="Enter Estimated hours"
                >
                <small
                  class="form-text text-danger"
                  v-show="errors.has('estimated_hours')"
                >{{errors.first('estimated_hours')}}</small>
              </div>
              <div
                v-show="body.billing_type && body.billing_type.id === 2"
                class="col-sm-12 col-lg-6"
              >
                <h6 class="text-bold text-opacity-pale">Hours capped at</h6>
                <input
                  class="form-control auth-input d-input"
                  v-model="body.hourly_cap"
                  type="number"
                  name="hourly_cap"
                  data-vv-as="Hourly Cap"
                  placeholder="Enter Hourly Cap"
                >
                <small
                  class="form-text text-danger"
                  v-show="errors.has('hourly_cap')"
                >{{errors.first('hourly_cap')}}</small>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <!-- <-- means same as total project revenue -->
          <!-- <div class="col-sm-12 col-lg-6">
            <h6 class="text-bold text-opacity-pale">Projected Revenue</h6>
            <input
              class="form-control auth-input d-input"
              type="number"
              v-model="body.projected_revenue"
              name="projected_revenue"
              data-vv-as="Projected Revenue"
              placeholder="Enter Project Revenue"
            >
            <small
              class="form-text text-danger"
              v-show="errors.has('projected_revenue')"
            >{{errors.first('projected_revenue')}}</small>
          </div>-->

          <div class="col-sm-12 col-lg-12">
            <h6 class="text-bold text-opacity-pale">Notes</h6>
            <quill-editor
              ref="myQuillEditor"
              class="mt-1"
              v-model="body.additional_notes"
               @ready="_html(body.additional_notes)"
              placeholder="Enter Additional Note for Opportunity"
            />
            <small
              class="form-text text-danger"
              v-show="errors.has('additional_notes')"
            >{{errors.first('additional_notes')}}</small>
          </div>
        </div>
      </form>
    </div>
    <template v-slot:modal-footer>
      <dashboard-button
        @submit="save"
        :loading="loading"
        :text="$route.params.id ? 'Save' : 'Save'"
      ></dashboard-button>
    </template>
  </b-modal>
</template>

<script>
import DashboardButton from "@/components/Buttons/Dashboard";
import Literals from "@/helpers/dashboard/literals";
import getOrganization from "@/helpers/getOrganization";
import { setTimeout } from "timers";
import { toUTC } from "@/helpers/date";
import handleError from "@/helpers/error";

export default {
  data() {
    return {
      Literals,
      loading: false,
      body: {
        billing_type: { id: 1 },
        opportunity_stage: {},
        employee: {},
        customer: {},
        currency: {}
      }
    };
  },
  props: ["opportunity"],
  components: { DashboardButton },
  computed: {
    statuses() {
      return this.$store.getters["global/project_statuses"];
    },
    activeSettlement() {
      return this.body.billing_type && this.body.billing_type.id
        ? this.body.billing_type.id
        : 1;
    },
    billing_types() {
      return this.$store.getters["global/project_billing_types"];
    },
    employees() {
      return this.$store.getters["employees/employees_simple"];
    },
    customers() {
      return this.$store.getters["customers/customers_simple"];
    },
    currencies() {
      return this.$store.getters["global/currencies"];
    },
    users() {
      return this.$store.getters["employees/users"];
    },
    project_billing_types() {
      return this.$store.getters["global/project_billing_types"];
    },
    opportunity_stages() {
      return this.$store.getters["global/opportunity_stages"];
    }
  },
  methods: {
    handleError,
    toUTC,
    close() {
      return this.$router.go(-1);
    },
    toggleSettlement(id) {
      Object.assign(this.body.billing_type, { id: id });
    },
    save() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    currencySelectLabel({ code, name, symbol }) {
      return ` ${name} (${symbol} ${code}) `;
    },
    submit() {
      let data = {
        name: this.body.name,
        additional_notes: this.body.additional_notes,
        projected_revenue: this.body.projected_revenue,
        actual_cost: this.body.actual_cost,
        opportunity_stage_id: this.body.opportunity_stage
          ? this.body.opportunity_stage.id
          : null,
        customer_id: this.body.customer ? this.body.customer.id : null,
        manager_user_id: this.body.employee ? this.body.employee.id : null,
        billing_type_id: this.body.billing_type
          ? this.body.billing_type.id
          : null,
        currency_id: this.body.currency ? this.body.currency.id : null,
        proposed_start_date: this.body.proposed_start_date
          ? toUTC(this.body.proposed_start_date).format("YYYY-MM-DD")
          : null,
        proposed_end_date: this.body.proposed_end_date
          ? toUTC(this.body.proposed_end_date).format("YYYY-MM-DD")
          : null,
        estimated_hours: this.body.estimated_hours,
        hours_logged: this.body.hours_logged,
        hourly_rate: this.body.hourly_rate,
        project_cost: this.body.project_cost,
        hourly_cap: this.body.hourly_cap,
        created_by: this.$auth.user().id,
        organization_id: getOrganization.organization_id()
      };

      let msgPass = Literals.OPPORTUNITY_CREATE_SUCCESS;
      if (this.body.id) {
        msgPass = Literals.OPPORTUNITY_UPDATE_SUCCESS;
        Object.assign(data, { id: this.body.id });
      }

      this.$store
        .dispatch("projects/createUpdateOpportunity", data)
        .then(opportunity => {
          this.$toast.success(msgPass, {
            position: "top-right",
            duration: 3000
          });

          if (
            this.$route &&
            this.$route.query &&
            this.$route.query.from
          ) {
            this.$router.go(-1);
          } else {
            data.id
              ? this.$router.push({
                  name: "opportunities-show",
                  params: { id: this.$route.params.id }
                })
              : this.$router.push({ name: "opportunities-index" });
          }
        })
        .catch(error => {
          let errorMessage = this.$route.params.id
            ? Literals.OPPORTUNITY_UPDATE_ERROR
            : Literals.OPPORTUNITY_CREATE_ERROR;
          this.handleError(this, error, errorMessage);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatData() {
      if (this.opportunity) {
        Object.assign(this.body, this.opportunity);
        this.body.proposed_start_date = this.opportunity.proposed_start_date
          ? new Date(this.opportunity.proposed_start_date)
          : null;
        this.body.proposed_end_date = this.opportunity.proposed_end_date
          ? new Date(this.opportunity.proposed_end_date)
          : null;
        this.body.customer = this.opportunity.customer &&
          this.opportunity.customer.data && {
            id: this.opportunity.customer.data.id,
            name: this.opportunity.customer.data.company_name
          };
      } else {
        //set multiselect values to empty so it shows placeholder - workaround from them
        this.body.customer = "";
        this.body.employee = "";
        this.body.opportunity_stage = "";
        this.body.currency = "";
      }

      if (this.$route.query && this.$route.query.cid) {
        this.body.customer = this.customers.find(
          c => c.id === this.$route.query.cid
        );
        this.body.hourly_rate =
          this.body.customer && this.body.customer.standard_hourly_rate;
      }
    }
  },
  mounted() {
    this.$refs["my-modal"].show();
  },
  created() {
    this.statuses.length || this.$store.dispatch("global/getProjectStatuses");
    this.currencies.length || this.$store.dispatch("global/getCurrencies");
    this.project_billing_types.length ||
      this.$store.dispatch("global/getProjectBillingTypes");
    this.opportunity_stages.length ||
      this.$store.dispatch("global/getOpportunityStages");
    this.$store.dispatch("employees/index");
    this.$store.dispatch("customers/index");

    this.formatData();
  }
};
</script>
