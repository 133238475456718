<template>
  <div>
    <div class="container-fluid">
      <div class="header pt-5  pl-3 pr-4">
        <div class="row">
          <div class="col-sm-12 col-lg-6 float-left">
            <slot name="breadcrumbs"></slot>
            <slot name="title"></slot>
          </div>
          <div class="col-sm-12 col-lg-6 float-right text-right">
            <slot name="action"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { optional } from "@/helpers/random";
export default {
  components: {},
  computed: {
    organizations() {
      // if user is org owner, this will exist
      let org = optional(this.$auth.user(), 'organization.data', []);


// this will always exist as long as user belongs to an org
      if(this.$auth.user() && this.$auth.user().organizations) {
        org = [org, ...optional(this.$auth.user(), 'organizations.data', [])];
      }

      let seen = [];

// we wanna only show unique orgs
      org = org.filter(s => {
        let notSeen = !seen.includes(s.id);
        seen.push(s.id);
        return notSeen;
      })

      return org;
    
    }
  },
  methods: {
    optional
  },
  created() {
    console.log('load');
    let org = window.localStorage.getItem('selected_organization')
    let organization = this.organizations.find(s => s.id == org.id)
    this.$store.dispatch("global/setActiveOrganization", organization);

    console.log(this.$store.getters['global/selected_org']);
  }
};
</script>
