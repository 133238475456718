<template>
  <div class>
    <div class="table-wrapper">
      <vuetable
        ref="vuetable"
        :api-url="api"
        :http-options="{ headers:{ 'Authorization' : 'Bearer ' + $auth.token(),'xorg' : getOrganization.organization_id() } }"
        @vuetable:loaded="loadTable"
        :fields="columns"
        data-path="data"
        pagination-path=""
        @vuetable:pagination-data="onPaginationData"
        :no-data-template="rowCount === null ? 'Loading data' : 'No Data Available'"
      ></vuetable>
      <vuetable-pagination v-show="paginationData.total"
                           :css="table_css.pagination"
                           ref="pagination"
      ></vuetable-pagination>
      <div class="text-center" v-if="rowCount <=0 || rowCount === null">
        <slot name="empty"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import getOrganization from "@/helpers/getOrganization";
import VuetableCss from "@/helpers/vuetable_styling.js";
import getPaginationData from "@/helpers/pagination.js";
export default {
  data() {
    return {
      columns: [
        {
          name: "name",
          title: "Project Title",
          sortField: "name",
          cvisible: true,
          active: true
        },
        {
          name: "customer",
          title: "Customer",
          sortField: "customer_id",
          cvisible: true,
          active: true,
          callback: d => {
            return d.data && d.data.last_name + " " + d.data.first_name;
          }
        },
        {
          name: "project_status",
          title: "Project Type",
          sortField: "project_status_id",
          cvisible: true,
          active: true,
          callback: d => {
            return d.name;
          }
        },
        {
          name: "__slot:project_manager",
          title: "Project Manager",
          cvisible: true,
          active: false,
          callback: s => {
            return s;
          }
        },
        {
          name: "hourly_rate",
          title: "Project Hourly Rate",
          cvisible: true,
          active: false
        },
        {
          name: "__slot:project_cost",
          title: "Cost",
          cvisible: true,
          active: false,
          callback: s => {
            return s;
          }
        }
      ],
      api: `${this.$baseApi}/customers/${this.$route.params.id}/attachments`,
      rowCount: null,
      table_css: VuetableCss,
      getOrganization,
      paginationData: {},
    };
  },
  methods: {
    getPaginationData,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(this.paginationData)
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
    },
  }
};
</script>

