<template>
  <div>
    <contacts-modal :customer="customer" v-if="customer.id"></contacts-modal>
  </div>
</template>
<script>
import ContactsModal from "@/components/Modals/Contacts";
export default {
  components: { ContactsModal },
  data() {
    return {
    };
  },
  computed : {
    customer() {
      return this.$store.getters['customers/customer'];
  },
},
  created() {
    this.$store.dispatch("customers/getSingle", this.$route.params.id);
  }
};
</script>
