import { render, staticRenderFns } from "./Expense.vue?vue&type=template&id=4de63ab0&scoped=true&"
import script from "./Expense.vue?vue&type=script&lang=js&"
export * from "./Expense.vue?vue&type=script&lang=js&"
import style0 from "./Expense.vue?vue&type=style&index=0&id=4de63ab0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4de63ab0",
  null
  
)

export default component.exports