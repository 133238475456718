
import {optional} from '@/helpers/random';

export default {
    computed: {
        subscription() {
            let data = this.$store.getters['global/subscription'];
            
            return optional(data, 'subscription', {stripe_status: 'trial', quantity: 0});
        }
    }
}