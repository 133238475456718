<template>
  <div
    class="mt-5 mb-5 plans p-5 h-100"
    style="background: rgba(218, 241, 255, 0.1)"
  >
    <subscribe-modal
      @subscibe="subscribe"
      @close="close"
      :showSubscribeModal="showSubscribeModal"
    ></subscribe-modal>
    <h6 class="text-opacity-pale text-bold text-center mb-5">
      Available Plans
    </h6>

    <div
      class="mt-5 d-flex flex-row justify-content-center align-items-center mt-5"
    >
      <div class="plan-card mr-2">
        <div
          class="p-4 d-flex flex-column items-center text-center justify-content-center"
        >
          <div class="mr-4 d-flex align-items-center justify-content-center">
            <button class="subs-icon">🚀</button>
          </div>
          <h5 class="text-opacity-pale text-bold mt-4">Basic Plan</h5>

          <div class="plan-card__price mt-3">
            <h6
              class="h5 text-opacity-pale text-bold text-main"
              style="font-size: 17px !important"
            >
              $56
              <span class="text-opacity" style="font-size: 14px">/month</span>
            </h6>
          </div>
        </div>

        <div class="plan-card__features p-4">
          <h6 class="text-opacity">Features</h6>

          <ul class="d-flex flex-column">
            <li>
              <span class="mr-2"
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.04 5.5925L6.8225 8.8175L5.585 7.58C5.51777 7.50149 5.43503 7.43772 5.34198 7.39271C5.24893 7.34769 5.14758 7.32239 5.04429 7.3184C4.941 7.31441 4.83801 7.33182 4.74176 7.36952C4.64552 7.40723 4.55811 7.46442 4.48502 7.53751C4.41192 7.6106 4.35473 7.69801 4.31703 7.79426C4.27932 7.8905 4.26192 7.9935 4.2659 8.09679C4.26989 8.20008 4.29519 8.30143 4.34021 8.39448C4.38523 8.48753 4.44899 8.57026 4.5275 8.6375L6.29 10.4075C6.36008 10.477 6.4432 10.532 6.53457 10.5693C6.62595 10.6067 6.7238 10.6256 6.8225 10.625C7.01926 10.6242 7.20781 10.5461 7.3475 10.4075L11.0975 6.6575C11.1678 6.58778 11.2236 6.50483 11.2617 6.41343C11.2998 6.32204 11.3194 6.22401 11.3194 6.125C11.3194 6.02599 11.2998 5.92796 11.2617 5.83657C11.2236 5.74517 11.1678 5.66222 11.0975 5.5925C10.957 5.45281 10.7669 5.37441 10.5688 5.37441C10.3706 5.37441 10.1805 5.45281 10.04 5.5925ZM8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96019 0.693993 8.98492 0.5 8 0.5ZM8 14C6.81332 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19974 1.88378 7.99334 2.11529 6.82946C2.3468 5.66557 2.91825 4.59647 3.75736 3.75736C4.59648 2.91824 5.66558 2.3468 6.82946 2.11529C7.99335 1.88378 9.19975 2.0026 10.2961 2.45672C11.3925 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14Z"
                    fill="#2FD69A"
                  />
                </svg>
              </span>
              <h6 class="text-bold mb-0 text-opacity-pale">Feature 1</h6>
            </li>
            <li>
              <span class="mr-2"
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.04 5.5925L6.8225 8.8175L5.585 7.58C5.51777 7.50149 5.43503 7.43772 5.34198 7.39271C5.24893 7.34769 5.14758 7.32239 5.04429 7.3184C4.941 7.31441 4.83801 7.33182 4.74176 7.36952C4.64552 7.40723 4.55811 7.46442 4.48502 7.53751C4.41192 7.6106 4.35473 7.69801 4.31703 7.79426C4.27932 7.8905 4.26192 7.9935 4.2659 8.09679C4.26989 8.20008 4.29519 8.30143 4.34021 8.39448C4.38523 8.48753 4.44899 8.57026 4.5275 8.6375L6.29 10.4075C6.36008 10.477 6.4432 10.532 6.53457 10.5693C6.62595 10.6067 6.7238 10.6256 6.8225 10.625C7.01926 10.6242 7.20781 10.5461 7.3475 10.4075L11.0975 6.6575C11.1678 6.58778 11.2236 6.50483 11.2617 6.41343C11.2998 6.32204 11.3194 6.22401 11.3194 6.125C11.3194 6.02599 11.2998 5.92796 11.2617 5.83657C11.2236 5.74517 11.1678 5.66222 11.0975 5.5925C10.957 5.45281 10.7669 5.37441 10.5688 5.37441C10.3706 5.37441 10.1805 5.45281 10.04 5.5925ZM8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96019 0.693993 8.98492 0.5 8 0.5ZM8 14C6.81332 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19974 1.88378 7.99334 2.11529 6.82946C2.3468 5.66557 2.91825 4.59647 3.75736 3.75736C4.59648 2.91824 5.66558 2.3468 6.82946 2.11529C7.99335 1.88378 9.19975 2.0026 10.2961 2.45672C11.3925 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14Z"
                    fill="#2FD69A"
                  />
                </svg>
              </span>
              <h6 class="text-bold mb-0 text-opacity-pale">Feature 1</h6>
            </li>
            <li>
              <span class="mr-2"
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.04 5.5925L6.8225 8.8175L5.585 7.58C5.51777 7.50149 5.43503 7.43772 5.34198 7.39271C5.24893 7.34769 5.14758 7.32239 5.04429 7.3184C4.941 7.31441 4.83801 7.33182 4.74176 7.36952C4.64552 7.40723 4.55811 7.46442 4.48502 7.53751C4.41192 7.6106 4.35473 7.69801 4.31703 7.79426C4.27932 7.8905 4.26192 7.9935 4.2659 8.09679C4.26989 8.20008 4.29519 8.30143 4.34021 8.39448C4.38523 8.48753 4.44899 8.57026 4.5275 8.6375L6.29 10.4075C6.36008 10.477 6.4432 10.532 6.53457 10.5693C6.62595 10.6067 6.7238 10.6256 6.8225 10.625C7.01926 10.6242 7.20781 10.5461 7.3475 10.4075L11.0975 6.6575C11.1678 6.58778 11.2236 6.50483 11.2617 6.41343C11.2998 6.32204 11.3194 6.22401 11.3194 6.125C11.3194 6.02599 11.2998 5.92796 11.2617 5.83657C11.2236 5.74517 11.1678 5.66222 11.0975 5.5925C10.957 5.45281 10.7669 5.37441 10.5688 5.37441C10.3706 5.37441 10.1805 5.45281 10.04 5.5925ZM8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96019 0.693993 8.98492 0.5 8 0.5ZM8 14C6.81332 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19974 1.88378 7.99334 2.11529 6.82946C2.3468 5.66557 2.91825 4.59647 3.75736 3.75736C4.59648 2.91824 5.66558 2.3468 6.82946 2.11529C7.99335 1.88378 9.19975 2.0026 10.2961 2.45672C11.3925 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14Z"
                    fill="#2FD69A"
                  />
                </svg>
              </span>
              <h6 class="text-bold mb-0 text-opacity-pale">Feature 1</h6>
            </li>
            <li>
              <span class="mr-2"
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.04 5.5925L6.8225 8.8175L5.585 7.58C5.51777 7.50149 5.43503 7.43772 5.34198 7.39271C5.24893 7.34769 5.14758 7.32239 5.04429 7.3184C4.941 7.31441 4.83801 7.33182 4.74176 7.36952C4.64552 7.40723 4.55811 7.46442 4.48502 7.53751C4.41192 7.6106 4.35473 7.69801 4.31703 7.79426C4.27932 7.8905 4.26192 7.9935 4.2659 8.09679C4.26989 8.20008 4.29519 8.30143 4.34021 8.39448C4.38523 8.48753 4.44899 8.57026 4.5275 8.6375L6.29 10.4075C6.36008 10.477 6.4432 10.532 6.53457 10.5693C6.62595 10.6067 6.7238 10.6256 6.8225 10.625C7.01926 10.6242 7.20781 10.5461 7.3475 10.4075L11.0975 6.6575C11.1678 6.58778 11.2236 6.50483 11.2617 6.41343C11.2998 6.32204 11.3194 6.22401 11.3194 6.125C11.3194 6.02599 11.2998 5.92796 11.2617 5.83657C11.2236 5.74517 11.1678 5.66222 11.0975 5.5925C10.957 5.45281 10.7669 5.37441 10.5688 5.37441C10.3706 5.37441 10.1805 5.45281 10.04 5.5925ZM8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96019 0.693993 8.98492 0.5 8 0.5ZM8 14C6.81332 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19974 1.88378 7.99334 2.11529 6.82946C2.3468 5.66557 2.91825 4.59647 3.75736 3.75736C4.59648 2.91824 5.66558 2.3468 6.82946 2.11529C7.99335 1.88378 9.19975 2.0026 10.2961 2.45672C11.3925 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14Z"
                    fill="#2FD69A"
                  />
                </svg>
              </span>
              <h6 class="text-bold mb-0 text-opacity-pale">Feature 1</h6>
            </li>
            <li>
              <span class="mr-2"
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.04 5.5925L6.8225 8.8175L5.585 7.58C5.51777 7.50149 5.43503 7.43772 5.34198 7.39271C5.24893 7.34769 5.14758 7.32239 5.04429 7.3184C4.941 7.31441 4.83801 7.33182 4.74176 7.36952C4.64552 7.40723 4.55811 7.46442 4.48502 7.53751C4.41192 7.6106 4.35473 7.69801 4.31703 7.79426C4.27932 7.8905 4.26192 7.9935 4.2659 8.09679C4.26989 8.20008 4.29519 8.30143 4.34021 8.39448C4.38523 8.48753 4.44899 8.57026 4.5275 8.6375L6.29 10.4075C6.36008 10.477 6.4432 10.532 6.53457 10.5693C6.62595 10.6067 6.7238 10.6256 6.8225 10.625C7.01926 10.6242 7.20781 10.5461 7.3475 10.4075L11.0975 6.6575C11.1678 6.58778 11.2236 6.50483 11.2617 6.41343C11.2998 6.32204 11.3194 6.22401 11.3194 6.125C11.3194 6.02599 11.2998 5.92796 11.2617 5.83657C11.2236 5.74517 11.1678 5.66222 11.0975 5.5925C10.957 5.45281 10.7669 5.37441 10.5688 5.37441C10.3706 5.37441 10.1805 5.45281 10.04 5.5925ZM8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96019 0.693993 8.98492 0.5 8 0.5ZM8 14C6.81332 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19974 1.88378 7.99334 2.11529 6.82946C2.3468 5.66557 2.91825 4.59647 3.75736 3.75736C4.59648 2.91824 5.66558 2.3468 6.82946 2.11529C7.99335 1.88378 9.19975 2.0026 10.2961 2.45672C11.3925 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14Z"
                    fill="#2FD69A"
                  />
                </svg>
              </span>
              <h6 class="text-bold mb-0 text-opacity-pale">Feature 1</h6>
            </li>
          </ul>

          <div class="mt-3 text-right border-top pt-3">
            <button @click="subscribe" class="mr-3 sub btn btn-ds pl-4 pr-4">
              <h6 class="mb-0">Subscribe</h6>
            </button>
          </div>
        </div>
      </div>
      <div class="plan-card mr-2">
        <div
          class="p-4 d-flex flex-column items-center text-center justify-content-center"
        >
          <div class="mr-4 d-flex align-items-center justify-content-center">
            <button class="subs-icon">💎</button>
          </div>
          <h5 class="text-opacity-pale text-bold mt-4">Standard Plan</h5>

          <div class="plan-card__price mt-3">
            <h6
              class="h5 text-opacity-pale text-bold text-main"
              style="font-size: 17px !important"
            >
              $56
              <span class="text-opacity" style="font-size: 14px">/month</span>
            </h6>
          </div>
        </div>

        <div class="plan-card__features p-4">
          <h6 class="text-opacity">Features</h6>

          <ul class="d-flex flex-column">
            <li>
              <span class="mr-2"
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.04 5.5925L6.8225 8.8175L5.585 7.58C5.51777 7.50149 5.43503 7.43772 5.34198 7.39271C5.24893 7.34769 5.14758 7.32239 5.04429 7.3184C4.941 7.31441 4.83801 7.33182 4.74176 7.36952C4.64552 7.40723 4.55811 7.46442 4.48502 7.53751C4.41192 7.6106 4.35473 7.69801 4.31703 7.79426C4.27932 7.8905 4.26192 7.9935 4.2659 8.09679C4.26989 8.20008 4.29519 8.30143 4.34021 8.39448C4.38523 8.48753 4.44899 8.57026 4.5275 8.6375L6.29 10.4075C6.36008 10.477 6.4432 10.532 6.53457 10.5693C6.62595 10.6067 6.7238 10.6256 6.8225 10.625C7.01926 10.6242 7.20781 10.5461 7.3475 10.4075L11.0975 6.6575C11.1678 6.58778 11.2236 6.50483 11.2617 6.41343C11.2998 6.32204 11.3194 6.22401 11.3194 6.125C11.3194 6.02599 11.2998 5.92796 11.2617 5.83657C11.2236 5.74517 11.1678 5.66222 11.0975 5.5925C10.957 5.45281 10.7669 5.37441 10.5688 5.37441C10.3706 5.37441 10.1805 5.45281 10.04 5.5925ZM8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96019 0.693993 8.98492 0.5 8 0.5ZM8 14C6.81332 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19974 1.88378 7.99334 2.11529 6.82946C2.3468 5.66557 2.91825 4.59647 3.75736 3.75736C4.59648 2.91824 5.66558 2.3468 6.82946 2.11529C7.99335 1.88378 9.19975 2.0026 10.2961 2.45672C11.3925 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14Z"
                    fill="#2FD69A"
                  />
                </svg>
              </span>
              <h6 class="text-bold mb-0 text-opacity-pale">Feature 1</h6>
            </li>
            <li>
              <span class="mr-2"
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.04 5.5925L6.8225 8.8175L5.585 7.58C5.51777 7.50149 5.43503 7.43772 5.34198 7.39271C5.24893 7.34769 5.14758 7.32239 5.04429 7.3184C4.941 7.31441 4.83801 7.33182 4.74176 7.36952C4.64552 7.40723 4.55811 7.46442 4.48502 7.53751C4.41192 7.6106 4.35473 7.69801 4.31703 7.79426C4.27932 7.8905 4.26192 7.9935 4.2659 8.09679C4.26989 8.20008 4.29519 8.30143 4.34021 8.39448C4.38523 8.48753 4.44899 8.57026 4.5275 8.6375L6.29 10.4075C6.36008 10.477 6.4432 10.532 6.53457 10.5693C6.62595 10.6067 6.7238 10.6256 6.8225 10.625C7.01926 10.6242 7.20781 10.5461 7.3475 10.4075L11.0975 6.6575C11.1678 6.58778 11.2236 6.50483 11.2617 6.41343C11.2998 6.32204 11.3194 6.22401 11.3194 6.125C11.3194 6.02599 11.2998 5.92796 11.2617 5.83657C11.2236 5.74517 11.1678 5.66222 11.0975 5.5925C10.957 5.45281 10.7669 5.37441 10.5688 5.37441C10.3706 5.37441 10.1805 5.45281 10.04 5.5925ZM8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96019 0.693993 8.98492 0.5 8 0.5ZM8 14C6.81332 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19974 1.88378 7.99334 2.11529 6.82946C2.3468 5.66557 2.91825 4.59647 3.75736 3.75736C4.59648 2.91824 5.66558 2.3468 6.82946 2.11529C7.99335 1.88378 9.19975 2.0026 10.2961 2.45672C11.3925 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14Z"
                    fill="#2FD69A"
                  />
                </svg>
              </span>
              <h6 class="text-bold mb-0 text-opacity-pale">Feature 1</h6>
            </li>
            <li>
              <span class="mr-2"
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.04 5.5925L6.8225 8.8175L5.585 7.58C5.51777 7.50149 5.43503 7.43772 5.34198 7.39271C5.24893 7.34769 5.14758 7.32239 5.04429 7.3184C4.941 7.31441 4.83801 7.33182 4.74176 7.36952C4.64552 7.40723 4.55811 7.46442 4.48502 7.53751C4.41192 7.6106 4.35473 7.69801 4.31703 7.79426C4.27932 7.8905 4.26192 7.9935 4.2659 8.09679C4.26989 8.20008 4.29519 8.30143 4.34021 8.39448C4.38523 8.48753 4.44899 8.57026 4.5275 8.6375L6.29 10.4075C6.36008 10.477 6.4432 10.532 6.53457 10.5693C6.62595 10.6067 6.7238 10.6256 6.8225 10.625C7.01926 10.6242 7.20781 10.5461 7.3475 10.4075L11.0975 6.6575C11.1678 6.58778 11.2236 6.50483 11.2617 6.41343C11.2998 6.32204 11.3194 6.22401 11.3194 6.125C11.3194 6.02599 11.2998 5.92796 11.2617 5.83657C11.2236 5.74517 11.1678 5.66222 11.0975 5.5925C10.957 5.45281 10.7669 5.37441 10.5688 5.37441C10.3706 5.37441 10.1805 5.45281 10.04 5.5925ZM8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96019 0.693993 8.98492 0.5 8 0.5ZM8 14C6.81332 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19974 1.88378 7.99334 2.11529 6.82946C2.3468 5.66557 2.91825 4.59647 3.75736 3.75736C4.59648 2.91824 5.66558 2.3468 6.82946 2.11529C7.99335 1.88378 9.19975 2.0026 10.2961 2.45672C11.3925 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14Z"
                    fill="#2FD69A"
                  />
                </svg>
              </span>
              <h6 class="text-bold mb-0 text-opacity-pale">Feature 1</h6>
            </li>
            <li>
              <span class="mr-2"
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.04 5.5925L6.8225 8.8175L5.585 7.58C5.51777 7.50149 5.43503 7.43772 5.34198 7.39271C5.24893 7.34769 5.14758 7.32239 5.04429 7.3184C4.941 7.31441 4.83801 7.33182 4.74176 7.36952C4.64552 7.40723 4.55811 7.46442 4.48502 7.53751C4.41192 7.6106 4.35473 7.69801 4.31703 7.79426C4.27932 7.8905 4.26192 7.9935 4.2659 8.09679C4.26989 8.20008 4.29519 8.30143 4.34021 8.39448C4.38523 8.48753 4.44899 8.57026 4.5275 8.6375L6.29 10.4075C6.36008 10.477 6.4432 10.532 6.53457 10.5693C6.62595 10.6067 6.7238 10.6256 6.8225 10.625C7.01926 10.6242 7.20781 10.5461 7.3475 10.4075L11.0975 6.6575C11.1678 6.58778 11.2236 6.50483 11.2617 6.41343C11.2998 6.32204 11.3194 6.22401 11.3194 6.125C11.3194 6.02599 11.2998 5.92796 11.2617 5.83657C11.2236 5.74517 11.1678 5.66222 11.0975 5.5925C10.957 5.45281 10.7669 5.37441 10.5688 5.37441C10.3706 5.37441 10.1805 5.45281 10.04 5.5925ZM8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96019 0.693993 8.98492 0.5 8 0.5ZM8 14C6.81332 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19974 1.88378 7.99334 2.11529 6.82946C2.3468 5.66557 2.91825 4.59647 3.75736 3.75736C4.59648 2.91824 5.66558 2.3468 6.82946 2.11529C7.99335 1.88378 9.19975 2.0026 10.2961 2.45672C11.3925 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14Z"
                    fill="#2FD69A"
                  />
                </svg>
              </span>
              <h6 class="text-bold mb-0 text-opacity-pale">Feature 1</h6>
            </li>
            <li>
              <span class="mr-2"
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.04 5.5925L6.8225 8.8175L5.585 7.58C5.51777 7.50149 5.43503 7.43772 5.34198 7.39271C5.24893 7.34769 5.14758 7.32239 5.04429 7.3184C4.941 7.31441 4.83801 7.33182 4.74176 7.36952C4.64552 7.40723 4.55811 7.46442 4.48502 7.53751C4.41192 7.6106 4.35473 7.69801 4.31703 7.79426C4.27932 7.8905 4.26192 7.9935 4.2659 8.09679C4.26989 8.20008 4.29519 8.30143 4.34021 8.39448C4.38523 8.48753 4.44899 8.57026 4.5275 8.6375L6.29 10.4075C6.36008 10.477 6.4432 10.532 6.53457 10.5693C6.62595 10.6067 6.7238 10.6256 6.8225 10.625C7.01926 10.6242 7.20781 10.5461 7.3475 10.4075L11.0975 6.6575C11.1678 6.58778 11.2236 6.50483 11.2617 6.41343C11.2998 6.32204 11.3194 6.22401 11.3194 6.125C11.3194 6.02599 11.2998 5.92796 11.2617 5.83657C11.2236 5.74517 11.1678 5.66222 11.0975 5.5925C10.957 5.45281 10.7669 5.37441 10.5688 5.37441C10.3706 5.37441 10.1805 5.45281 10.04 5.5925ZM8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96019 0.693993 8.98492 0.5 8 0.5ZM8 14C6.81332 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19974 1.88378 7.99334 2.11529 6.82946C2.3468 5.66557 2.91825 4.59647 3.75736 3.75736C4.59648 2.91824 5.66558 2.3468 6.82946 2.11529C7.99335 1.88378 9.19975 2.0026 10.2961 2.45672C11.3925 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14Z"
                    fill="#2FD69A"
                  />
                </svg>
              </span>
              <h6 class="text-bold mb-0 text-opacity-pale">Feature 1</h6>
            </li>
          </ul>

          <div class="mt-3 text-right border-top pt-3">
            <button @click="subscribe" class="mr-3 sub btn btn-ds pl-4 pr-4">
              <h6 class="mb-0">Subscribe</h6>
            </button>
          </div>
        </div>
      </div>
      <div class="plan-card mr-2">
        <div
          class="p-4 d-flex flex-column items-center text-center justify-content-center"
        >
          <div class="mr-4 d-flex align-items-center justify-content-center">
            <button class="subs-icon">🔱</button>
          </div>
          <h5 class="text-opacity-pale text-bold mt-4">Premium Plan</h5>

          <div class="plan-card__price mt-3">
            <h6
              class="h5 text-opacity-pale text-bold text-main"
              style="font-size: 17px !important"
            >
              $56
              <span class="text-opacity" style="font-size: 14px">/month</span>
            </h6>
          </div>
        </div>

        <div class="plan-card__features p-4">
          <h6 class="text-opacity">Features</h6>

          <ul class="d-flex flex-column">
            <li>
              <span class="mr-2"
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.04 5.5925L6.8225 8.8175L5.585 7.58C5.51777 7.50149 5.43503 7.43772 5.34198 7.39271C5.24893 7.34769 5.14758 7.32239 5.04429 7.3184C4.941 7.31441 4.83801 7.33182 4.74176 7.36952C4.64552 7.40723 4.55811 7.46442 4.48502 7.53751C4.41192 7.6106 4.35473 7.69801 4.31703 7.79426C4.27932 7.8905 4.26192 7.9935 4.2659 8.09679C4.26989 8.20008 4.29519 8.30143 4.34021 8.39448C4.38523 8.48753 4.44899 8.57026 4.5275 8.6375L6.29 10.4075C6.36008 10.477 6.4432 10.532 6.53457 10.5693C6.62595 10.6067 6.7238 10.6256 6.8225 10.625C7.01926 10.6242 7.20781 10.5461 7.3475 10.4075L11.0975 6.6575C11.1678 6.58778 11.2236 6.50483 11.2617 6.41343C11.2998 6.32204 11.3194 6.22401 11.3194 6.125C11.3194 6.02599 11.2998 5.92796 11.2617 5.83657C11.2236 5.74517 11.1678 5.66222 11.0975 5.5925C10.957 5.45281 10.7669 5.37441 10.5688 5.37441C10.3706 5.37441 10.1805 5.45281 10.04 5.5925ZM8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96019 0.693993 8.98492 0.5 8 0.5ZM8 14C6.81332 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19974 1.88378 7.99334 2.11529 6.82946C2.3468 5.66557 2.91825 4.59647 3.75736 3.75736C4.59648 2.91824 5.66558 2.3468 6.82946 2.11529C7.99335 1.88378 9.19975 2.0026 10.2961 2.45672C11.3925 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14Z"
                    fill="#2FD69A"
                  />
                </svg>
              </span>
              <h6 class="text-bold mb-0 text-opacity-pale">Feature 1</h6>
            </li>
            <li>
              <span class="mr-2"
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.04 5.5925L6.8225 8.8175L5.585 7.58C5.51777 7.50149 5.43503 7.43772 5.34198 7.39271C5.24893 7.34769 5.14758 7.32239 5.04429 7.3184C4.941 7.31441 4.83801 7.33182 4.74176 7.36952C4.64552 7.40723 4.55811 7.46442 4.48502 7.53751C4.41192 7.6106 4.35473 7.69801 4.31703 7.79426C4.27932 7.8905 4.26192 7.9935 4.2659 8.09679C4.26989 8.20008 4.29519 8.30143 4.34021 8.39448C4.38523 8.48753 4.44899 8.57026 4.5275 8.6375L6.29 10.4075C6.36008 10.477 6.4432 10.532 6.53457 10.5693C6.62595 10.6067 6.7238 10.6256 6.8225 10.625C7.01926 10.6242 7.20781 10.5461 7.3475 10.4075L11.0975 6.6575C11.1678 6.58778 11.2236 6.50483 11.2617 6.41343C11.2998 6.32204 11.3194 6.22401 11.3194 6.125C11.3194 6.02599 11.2998 5.92796 11.2617 5.83657C11.2236 5.74517 11.1678 5.66222 11.0975 5.5925C10.957 5.45281 10.7669 5.37441 10.5688 5.37441C10.3706 5.37441 10.1805 5.45281 10.04 5.5925ZM8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96019 0.693993 8.98492 0.5 8 0.5ZM8 14C6.81332 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19974 1.88378 7.99334 2.11529 6.82946C2.3468 5.66557 2.91825 4.59647 3.75736 3.75736C4.59648 2.91824 5.66558 2.3468 6.82946 2.11529C7.99335 1.88378 9.19975 2.0026 10.2961 2.45672C11.3925 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14Z"
                    fill="#2FD69A"
                  />
                </svg>
              </span>
              <h6 class="text-bold mb-0 text-opacity-pale">Feature 1</h6>
            </li>
            <li>
              <span class="mr-2"
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.04 5.5925L6.8225 8.8175L5.585 7.58C5.51777 7.50149 5.43503 7.43772 5.34198 7.39271C5.24893 7.34769 5.14758 7.32239 5.04429 7.3184C4.941 7.31441 4.83801 7.33182 4.74176 7.36952C4.64552 7.40723 4.55811 7.46442 4.48502 7.53751C4.41192 7.6106 4.35473 7.69801 4.31703 7.79426C4.27932 7.8905 4.26192 7.9935 4.2659 8.09679C4.26989 8.20008 4.29519 8.30143 4.34021 8.39448C4.38523 8.48753 4.44899 8.57026 4.5275 8.6375L6.29 10.4075C6.36008 10.477 6.4432 10.532 6.53457 10.5693C6.62595 10.6067 6.7238 10.6256 6.8225 10.625C7.01926 10.6242 7.20781 10.5461 7.3475 10.4075L11.0975 6.6575C11.1678 6.58778 11.2236 6.50483 11.2617 6.41343C11.2998 6.32204 11.3194 6.22401 11.3194 6.125C11.3194 6.02599 11.2998 5.92796 11.2617 5.83657C11.2236 5.74517 11.1678 5.66222 11.0975 5.5925C10.957 5.45281 10.7669 5.37441 10.5688 5.37441C10.3706 5.37441 10.1805 5.45281 10.04 5.5925ZM8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96019 0.693993 8.98492 0.5 8 0.5ZM8 14C6.81332 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19974 1.88378 7.99334 2.11529 6.82946C2.3468 5.66557 2.91825 4.59647 3.75736 3.75736C4.59648 2.91824 5.66558 2.3468 6.82946 2.11529C7.99335 1.88378 9.19975 2.0026 10.2961 2.45672C11.3925 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14Z"
                    fill="#2FD69A"
                  />
                </svg>
              </span>
              <h6 class="text-bold mb-0 text-opacity-pale">Feature 1</h6>
            </li>
            <li>
              <span class="mr-2"
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.04 5.5925L6.8225 8.8175L5.585 7.58C5.51777 7.50149 5.43503 7.43772 5.34198 7.39271C5.24893 7.34769 5.14758 7.32239 5.04429 7.3184C4.941 7.31441 4.83801 7.33182 4.74176 7.36952C4.64552 7.40723 4.55811 7.46442 4.48502 7.53751C4.41192 7.6106 4.35473 7.69801 4.31703 7.79426C4.27932 7.8905 4.26192 7.9935 4.2659 8.09679C4.26989 8.20008 4.29519 8.30143 4.34021 8.39448C4.38523 8.48753 4.44899 8.57026 4.5275 8.6375L6.29 10.4075C6.36008 10.477 6.4432 10.532 6.53457 10.5693C6.62595 10.6067 6.7238 10.6256 6.8225 10.625C7.01926 10.6242 7.20781 10.5461 7.3475 10.4075L11.0975 6.6575C11.1678 6.58778 11.2236 6.50483 11.2617 6.41343C11.2998 6.32204 11.3194 6.22401 11.3194 6.125C11.3194 6.02599 11.2998 5.92796 11.2617 5.83657C11.2236 5.74517 11.1678 5.66222 11.0975 5.5925C10.957 5.45281 10.7669 5.37441 10.5688 5.37441C10.3706 5.37441 10.1805 5.45281 10.04 5.5925ZM8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96019 0.693993 8.98492 0.5 8 0.5ZM8 14C6.81332 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19974 1.88378 7.99334 2.11529 6.82946C2.3468 5.66557 2.91825 4.59647 3.75736 3.75736C4.59648 2.91824 5.66558 2.3468 6.82946 2.11529C7.99335 1.88378 9.19975 2.0026 10.2961 2.45672C11.3925 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14Z"
                    fill="#2FD69A"
                  />
                </svg>
              </span>
              <h6 class="text-bold mb-0 text-opacity-pale">Feature 1</h6>
            </li>
            <li>
              <span class="mr-2"
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.04 5.5925L6.8225 8.8175L5.585 7.58C5.51777 7.50149 5.43503 7.43772 5.34198 7.39271C5.24893 7.34769 5.14758 7.32239 5.04429 7.3184C4.941 7.31441 4.83801 7.33182 4.74176 7.36952C4.64552 7.40723 4.55811 7.46442 4.48502 7.53751C4.41192 7.6106 4.35473 7.69801 4.31703 7.79426C4.27932 7.8905 4.26192 7.9935 4.2659 8.09679C4.26989 8.20008 4.29519 8.30143 4.34021 8.39448C4.38523 8.48753 4.44899 8.57026 4.5275 8.6375L6.29 10.4075C6.36008 10.477 6.4432 10.532 6.53457 10.5693C6.62595 10.6067 6.7238 10.6256 6.8225 10.625C7.01926 10.6242 7.20781 10.5461 7.3475 10.4075L11.0975 6.6575C11.1678 6.58778 11.2236 6.50483 11.2617 6.41343C11.2998 6.32204 11.3194 6.22401 11.3194 6.125C11.3194 6.02599 11.2998 5.92796 11.2617 5.83657C11.2236 5.74517 11.1678 5.66222 11.0975 5.5925C10.957 5.45281 10.7669 5.37441 10.5688 5.37441C10.3706 5.37441 10.1805 5.45281 10.04 5.5925ZM8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96019 0.693993 8.98492 0.5 8 0.5ZM8 14C6.81332 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19974 1.88378 7.99334 2.11529 6.82946C2.3468 5.66557 2.91825 4.59647 3.75736 3.75736C4.59648 2.91824 5.66558 2.3468 6.82946 2.11529C7.99335 1.88378 9.19975 2.0026 10.2961 2.45672C11.3925 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14Z"
                    fill="#2FD69A"
                  />
                </svg>
              </span>
              <h6 class="text-bold mb-0 text-opacity-pale">Feature 1</h6>
            </li>
          </ul>

          <div class="mt-3 text-right border-top pt-3">
            <button @click="subscribe" class="mr-3 sub btn btn-ds pl-4 pr-4">
              <h6 class="mb-0">Subscribe</h6>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SubscribeModal from "../../components/Modals/Subscribe.vue";
export default {
  components: { SubscribeModal },
  data() {
    return {
      showSubscribeModal: false,
    };
  },
  methods: {
    subscribe() {
      this.showSubscribeModal = true;
    },
    close(d) {
      this.showSubscribeModal = d;
    },
  },
};
</script>

<style lang="scss" scoped>
.plans {
  .plan-card {
    width: 25vw;
    background: white;
    // border:2px solid #0a5685;
    box-shadow: 0 7px 30px 0 rgba(217, 217, 217, 0.36);
    border-radius: 7px;

    &:nth-child(odd) {
      margin-top: 100px;
    }

    .plan-card__price {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        height: 1px;
        width: 100px;
        background: transparent;
        border: 1px dashed #f0f3f3;
        transform: translateY(10px);
      }
      &::after {
        content: "";
        position: absolute;
        right: 0;
        height: 1px;
        width: 100px;
        background: transparent;
        border: 1px dashed #f0f3f3;
        transform: translateY(10px);
        top: 0;
      }
    }
    .plan-card__features {
      background: #b3dce86b;
    }
  }
}
.subs-icon {
  height: 50px;
  width: 50px;
  background: white;
  border-radius: 50px;
  box-shadow: 0 7px 30px 0 rgba(217, 217, 217, 0.36);
  font-size: 25px;
  border: none;
}
</style>