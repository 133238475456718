<template>
  <div>
    <transition name="slide-fade">
      <div class="filters-form" v-show="visibleFilter">
        <form class="d-form">
          <div class="row mt-3">
            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">Name</h6>
              <div style="position:relative">
                <input
                  type="text"
                  v-model="params['name']"
                  @input="setFilters('name')"
                  class="form-control auth-input d-input"
                  placeholder="Opportunity name"
                >
                <span class="close-icon" v-if="params && params.name">
                  <i class="zmdi zmdi-close" @click="params['name']='';setFilters('name')"></i>
                </span>
              </div>
            </div>

            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">Stage</h6>
              <div style="position:relative">
                <multiselect
                  @input="setFilters('opportunity_stage_id')"
                  class="no-caret-select"
                  v-model="params['opportunity_stage_id']"
                  :custom-label="opt => stages.find(x => x.id == opt).name"
                  :options="stages.map(type=>type.id)"
                  :show-labels="false"
                >
                  <template slot="caret">
                    <span class="close-icon" v-if="params && params.opportunity_stage_id">
                      <i
                        class="zmdi zmdi-close"
                        @click="params['opportunity_stage_id']='';setFilters('opportunity_stage_id')"
                      ></i>
                    </span>
                    <div v-else>
                      <div class="multiselect__select">
                        <i class="zmdi zmdi-chevron-down pt-1" style="font-size: 20px"></i>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>

            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">Billing Type</h6>
              <div style="position:relative">
                <multiselect
                  @input="setFilters('billing_type_id')"
                  class="no-caret-select"
                  v-model="params['billing_type_id']"
                  :custom-label="opt => billingTypes.find(x => x.id == opt).name"
                  :options="billingTypes.map(type=>type.id)"
                  :show-labels="false"
                >
                  <template slot="caret">
                    <span class="close-icon" v-if="params && params.billing_type_id">
                      <i
                        class="zmdi zmdi-close"
                        @click="params['billing_type_id']='';setFilters('billing_type_id')"
                      ></i>
                    </span>
                    <div v-else>
                      <div class="multiselect__select">
                        <i class="zmdi zmdi-chevron-down pt-1" style="font-size: 20px"></i>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>

            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">Close Date (From)</h6>
              <div style="position:relative">
                <vc-date-picker
                  :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important; height:42px !important`,placeholder: `Closing From date`}"
                  v-model="params['filter_start']"
                  @input="setFilters('filter_start');params['range_filter']='proposed_end_date'"
                  name="end"
                />
                <span class="close-icon" v-if="params && params.filter_start">
                  <i
                    class="zmdi zmdi-close"
                    @click="params['filter_start']='';setFilters('filter_start')"
                  ></i>
                </span>
              </div>
            </div>
            <div class="col-sm-12 col-lg-2">
              <h6 class="text-bold text-opacity-pale">- To</h6>
              <div style="position:relative">
                <vc-date-picker
                  :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important; height:42px !important`,placeholder: `Closing To date`}"
                  v-model="params['filter_end']"
                  @input="setFilters('filter_end');params['range_filter']='proposed_end_date'"
                  name="end"
                />
                <span class="close-icon" v-if="params && params.filter_end">
                  <i
                    class="zmdi zmdi-close"
                    @click="params['filter_end']='';setFilters('filter_end')"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["params", "billingTypes", "stages", "visibleFilter"],
  methods: {
    setFilters(name) {
      this.$emit("setFilters", this.params, name);
    }
  }
};
</script>
