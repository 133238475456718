<template>
  <!-- Modal -->
  <div class="invoice-modal" v-show="visible">
    <div class="invoice-modal__overlay">
      <div class="invoice-modal__overlay-wrapper">
        <div class="invoice-modal-card">
          <div class="invoice-modal-card__main">
            <div class="invoice-modal-card__main-list">
              <ul class="invoice-modal__items">
                <li :class="invoice_stage == 1 ? 'active' : invoice_stage > 1 ? 'done' : ''">
                  <button>1</button>
                </li>
                <li :class="invoice_stage == 2 ? 'active' : invoice_stage > 2 ? 'done' : '' ">
                  <button>2</button>
                </li>
                <li :class="invoice_stage == 3 ? 'active' : ''">
                  <button>3</button>
                </li>
              </ul>

            </div>
            <div class="invoice-modal-card__main-content">
              <div class="invoice-modal-close pt-4 pr-3">
                <button class="close" @click="cancel">
                  Cancel
                  <i class="zmdi zmdi-close-circle pl-2" style="font-size: 20px;"></i>
                </button>
              </div>

              <div class="invoice-modal-card-content pt-5">
                <div class="invoice-modal-title text-center">
                  <button class="invoice-modal-title__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M8 24l2.674-9h-9.674l16-15-2.674 9h8.674l-15 15zm-1.586-11h6.912l-1.326 4 5.739-6h-6.065l1.304-4-6.564 6z"
                      ></path>
                    </svg>
                  </button>
                  <h5 class="text-bold h7 pt-2">Generate Invoice</h5>
                </div>
                <div class="invoice-modal-content pt-5 pl-3 pr-3">
                  <form class="d-form">
                    <div v-if="invoice_stage ==1">
                      <h5 class="text-opacity-pale text-bold">Select Invoice Period</h5>

                      <ul class="invoice-select-list mt-3">
                        <li
                          @click="setInvoicePeriod(index)"
                          :class="selected_period.name === period.name ? 'active' : ''"
                          v-for="(period,index) in invoice_period"
                          :key="index"
                        >
                          <span class="pt-1">
                            <svg
                              v-if="selected_period.name === period.name"
                              fill="#0a5685"
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M0 0v24h24v-24h-24zm10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"
                              ></path>
                            </svg>
                            <svg
                              v-else
                              xmlns="http://www.w3.org/2000/svg"
                              fill="#E4E4E4"
                              width="17"
                              height="17"
                              viewBox="0 0 24 24"
                            >
                              <path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24z"></path>
                            </svg>
                          </span>
                          <aside class="pl-3">
                            <h6 class="text-bold h7 text-opacity-pale">{{period.name}}</h6>
                            <div
                              class="mt-2"
                              v-if="period.can_select && selected_period.name === period.name"
                            >
                              <div class="row mt-3">
                                <div class="col-sm-6">
                                  <vc-date-picker
                                    :masks="{ input: ['YYYY-MM-DD']}"
                                    :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: ` Start date`}"
                                    v-model="period.from"
                                    v-validate="'after:startFrom'"
                                    name="end_date"
                                    data-vv-as="Start Date"
                                  />
                                  <small class="dpicker-icon">
                                    <i class="zmdi zmdi-calendar-alt"></i>
                                  </small>
                                </div>
                                <div class="col-sm-6">
                                  <vc-date-picker
                                    :masks="{ input: ['YYYY-MM-DD']}"
                                    :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: ` End date`}"
                                    v-model="period.to"
                                    v-validate="'after:startFrom'"
                                    name="end_date"
                                    data-vv-as="End Date"
                                  />
                                  <small class="dpicker-icon">
                                    <i class="zmdi zmdi-calendar-alt"></i>
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div v-if="!period.can_select">
                              <small
                                class="text-opacity"
                              >Period between {{period.from}} - {{period.to}}</small>
                            </div>
                          </aside>
                        </li>
                      </ul>
                    </div>

                    <div v-if="invoice_stage == 2">
                      <h5 class="text-opacity-pale text-bold">Invoice items</h5>
                      <div class="mt-4 invoice-selected-project text-bold text-opacity-pale p-4">{{project.name}}</div>
                      <ul class="invoice-select-list mt-3">
                        <li v-if="project.billing_type_id === 3">

                          <multiselect
                              v-model="selected_milestones"
                              track-by="id"
                              :multiple="true"
                              class="mobile_dropdown"
                              return="id"
                              label="name"
                              name="milestone"
                              placeholder="Select Milestones"
                              :options="milestones"
                              :show-labels="false"
                              data-vv-as="Milestones"
                          ></multiselect>
                        </li>
                        <li
                          @click="setInvoiceItem(item.id)"
                          :class="selected_invoice_option.includes(item.id)  ? 'active' : ''"
                          v-for="(item,index) in invoice_items_option"
                          :key="index"
                        >
                          <span class="pt-1">
                            <svg
                              v-if="selected_invoice_option.includes(item.id)"
                              fill="#0a5685"
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M0 0v24h24v-24h-24zm10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"
                              ></path>
                            </svg>
                            <svg
                              v-else
                              xmlns="http://www.w3.org/2000/svg"
                              fill="#E4E4E4"
                              width="17"
                              height="17"
                              viewBox="0 0 24 24"
                            >
                              <path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24z"></path>
                            </svg>
                          </span>
                          <aside class="pl-3">
                            <h6 class="text-bold h7 text-opacity-pale">Include {{item.name}}</h6>
                            <div>
                              <small
                                class="text-opacity"
                              >This project contains {{item.number}} {{item.name}}</small>
                            </div>
                          </aside>
                        </li>
                      </ul>
                    </div>

                    <div v-if="invoice_stage==3" class="text-center">
                      <svg
                        class="ft-green-tick"
                        xmlns="http://www.w3.org/2000/svg"
                        height="70"
                        width="70"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <circle class="circle" fill="#5bb543" cx="24" cy="24" r="22"></circle>
                        <path
                          class="tick"
                          fill="none"
                          stroke="#FFF"
                          stroke-width="6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-miterlimit="10"
                          d="M14 27l5.917 4.917L34 17"
                        ></path>
                      </svg>
                      <h5 class="text-bold pt-3">Invoice Generated Successfully</h5>
                      <h6 style="width:60%;margin:0 auto;line-height:23px" class="text-opacity">
                        Your invoice has been generated with ID <b>#{{invoice.id}}</b>.
                        <br> You can click on the link below to see invoice or close the window to continue working on this page
                      </h6>
                      <router-link :to="{name:'invoices-show', params:{id:invoice.id}}">
                        <button class="btn btn-primary auth-btn mt-3">
                          Open Invoice
                          <i class="zmdi zmdi-arrow-right-top pl-2"></i>
                        </button>
                      </router-link>
                    </div>
                  </form>
                </div>
              </div>

              <div class="invoice-modal-card__footer pl-3 pt-5 pr-3">
                <div class="row" v-show="invoice_stage < 3">
                  <div class="col-sm-6">
                    <button
                      @click="prevStage"
                      v-if="invoice_stage > 1"
                      class="btn btn-ds btn-transparent"
                    >Previous</button>
                  </div>
                  <div class="col-sm-6 text-right">
                    <button :disabled="loading" @click="nextStage" class="btn btn-ds pl-5 pr-5">
                      <div v-if="!loading">Next</div>
                      <div v-else class="spinner-grow spinner-grow-sm text-light" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@supports (animation: grow 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards) {
  .tick {
    stroke-opacity: 0;
    stroke-dasharray: 29px;
    stroke-dashoffset: 29px;
    animation: draw 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards;
    animation-delay: 0.6s;
  }

  .circle {
    fill-opacity: 0;
    stroke: #219a00;
    stroke-width: 16px;
    transform-origin: center;
    transform: scale(0);
    animation: grow 1s cubic-bezier(0.25, 0.25, 0.25, 1.25) forwards;
  }
}

@keyframes grow {
  60% {
    transform: scale(0.8);
    stroke-width: 4px;
    fill-opacity: 0;
  }
  100% {
    transform: scale(0.9);
    stroke-width: 8px;
    fill-opacity: 1;
    fill: #219a00;
  }
}

@keyframes draw {
  0%,
  100% {
    stroke-opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

button {
  &.btn-transparent {
    background: transparent;
    color: #0a5685 !important;
    opacity: 0.7;

    &:hover {
      color: #0a5685 !important;
      opacity: 1;
    }
  }
}
div {
  &.invoice-selected-project {
    background: #d7e1ea;
    border-radius: 5px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
 {
  opacity: 0;
}
</style>

<script>
import { setTimeout } from "timers";
import { toLocal, toUTC } from "@/helpers/date";
import moment from "moment";

export default {
  props: ["visible", "project", "timelogs", "expenses", "milestones"],
  data() {
    return {
      loading: false,
      invoice: {},
      selected_invoice_option: [],
      selected_milestones: [],
      selected_period: {},
      invoice_stage: 1
    };
  },
  computed: {
    invoice_items_option() {
      let array =  [];

      // if is milestone project
      if(this.project.billing_type_id !== 3) {
        array.push(
          {
            id: "has_timelog",
            name: "billable time entries",
            number: this.totalTimeLogs
          }
        );
      }

      array.push( {
        id: "has_expense",
        name: "logged expenses",
        number: this.totalExpenses
      });
      this.selected_invoice_option = [this.project.billing_type_id !== 3 ? "has_timelog": null ,"has_expense"]
      return array;
    },
    totalExpenses() {
      let expenses = this.expenses;
      return this.selected_period.from && this.selected_period.to ? expenses.filter(s => {
        return (moment(s.created_at).diff(moment(this.selected_period.from), 'd') >= 1
        || moment(s.created_at).diff(moment(this.selected_period.to ), 'd') <= 1)
      }).length : expenses.length;
    },
    totalTimeLogs() {
      let timelogs = this.timelogs;
      return this.selected_period.from && this.selected_period.to ? timelogs.filter(s => {
        return (moment(s.created_at).diff(moment(this.selected_period.from), 'd') >= 1
                || moment(s.created_at).diff(moment(this.selected_period.to ), 'd') <= 1)
      }).length : timelogs.length;
    },
    totalMilestones() {
      let milestones = this.milestones;
      return this.selected_period.from && this.selected_period.to ? milestones.filter(s => {
        return (moment(s.start_date).diff(moment(this.selected_period.from), 'd') >= 1
                || moment(s.due_date).diff(moment(this.selected_period.to ), 'd') <= 1)
      }).length : milestones.length;
    },
    invoice_period() {
      let lastMonth = toLocal(moment().subtract(1, "M")).format("ll");
      let today = toLocal(moment()).format("ll");
      let projectCreation = toLocal(this.project.created_at).format("ll");

      return [
        { name: "Last month", from: lastMonth, to: today },
        { name: "All time", from: projectCreation, to: today },
        { name: "Custom period", from: "", to: "", can_select: true }
      ];
    }
  },
  methods: {
    cancel() {
      this.invoice_stage = 1;
      this.$emit("cancel");
    },
    submit() {
      this.loading = true;
      let data = {
        template_id: null,
        start_date: toUTC(this.selected_period.from).format("YYYY-MM-DD"),
        end_date: toUTC(this.selected_period.to).format("YYYY-MM-DD"),
        customer_id: this.project.customer_id,
        organization_id: this.project.organization_id,
        project_id: this.project.id,
        currency_id: this.project.currency_id,
        tax_id: null,
        parent_invoice_id: null,
        discount_percent: 0,
        created_by: this.$auth.user().id,
        approved_by: null,
        hours: null,
        hourly_rate: this.project.hourly_rate,
        milestones : this.selected_milestones.map(s => s.id)
      };
      Object.values(this.selected_invoice_option).forEach(key => {
        data[key] = true;
      });

      this.$store
        .dispatch("invoices/createUpdate", data)
        .then(data => {
          this.invoice_stage = this.invoice_stage + 1;
          this.invoice = data;
        })
        .catch(error => {})
        .finally(() => {
          this.loading = false;
        });
    },
    setInvoicePeriod(index) {
      this.selected_period = this.invoice_period[index];
    },
    setInvoiceItem(id) {
      if (!this.selected_invoice_option.includes(id)) {
        this.selected_invoice_option.push(id);
      } else {
        this.selected_invoice_option.splice(
          this.selected_invoice_option.indexOf(id),
          1
        );
      }
    },
    nextStage() {
      if (this.invoice_stage !== 3) {
        if (this.invoice_stage == 2) {
          this.submit();
        } else {
          this.invoice_stage = this.invoice_stage + 1;
        }
      }
    },
    prevStage() {
      if (this.invoice_stage !== 1) {
        this.invoice_stage = this.invoice_stage - 1;
      }
    }
  },
  mounted() {

  }
};
</script>
