<template>
  <div class="home" :class="{blur:!loaded}">
    <div class="container-fluid ml-3 pr-5 mt-5 mb-5">
      <div class="row profile-area">
        <div class="col-sm-12 col-lg-12">
          <div class="card">
            <div class="row">
              <div class="col-sm-12 col-lg-3 card-profile border-right"  :class="{blur: !loaded}">
                <div class="card-profile_wrapper">
                  <div class="card-profile_wrapper-img">
                    <img v-if="imageUrl" :src="imageUrl">
                    <div
                      class="card-profile_wrapper-img-overlay"
                      v-b-tooltip.hover
                      title="Change Profile Image"
                      @click="$refs.profile.click()"
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#FFF"
                          width="40"
                          height="40"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M9 12c0-.552.448-1 1.001-1s.999.448.999 1-.446 1-.999 1-1.001-.448-1.001-1zm6.2 0l-1.7 2.6-1.3-1.6-3.2 4h10l-3.8-5zm5.8-7v-2h-21v15h2v-13h19zm3 2v14h-20v-14h20zm-2 2h-16v10h16v-10z"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <div>
                      <input
                        :disabled="loading"
                        id="img-upload"
                        ref="profile"
                        type="file"
                        style="display:none"
                        class="text-opacity"
                        @change="importFileAndPreview"
                      >
                    </div>
                  </div>
                  <div class="pt-4 text-left">
                    <h6
                      class="text-left text-opacity-pale text-bold h7"
                    >@{{user.organization ? user.organization.name : 'N/A'}}</h6>
                  </div>
                  <div class="pt-3">
                    <ul class="card-profile-menu_list">
                      <router-link
                        :to="{name: 'profile'}"
                        :class="$route.meta && $route.meta.isProfileRoute ? 'router-link-exact-active' : ''"
                      >
                        <li>
                          <h6>
                            <svg
                              data-v-0139a417
                              width="18"
                              height="18"
                              viewBox="0 0 26 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                data-v-0139a417
                                d="M10.0761 3.16311C10.136 2.50438 10.6883 2 11.3497 2H12.6503C13.3117 2 13.864 2.50438 13.9239 3.16311C13.9731 3.70392 14.3623 4.14543 14.8708 4.336C15.0015 4.38499 15.1307 4.43724 15.2582 4.49263C15.7613 4.71129 16.3531 4.66938 16.7745 4.31818C17.2953 3.8842 18.0611 3.91894 18.5404 4.39829L19.4584 5.31623C19.9154 5.77326 19.9485 6.50338 19.5347 6.99992C19.1901 7.41349 19.158 7.99745 19.3897 8.48341C19.49 8.69386 19.5816 8.90926 19.664 9.12916C19.8546 9.63767 20.2961 10.0269 20.8369 10.0761C21.4956 10.136 22 10.6883 22 11.3497V12.6503C22 13.3117 21.4956 13.864 20.8369 13.9239C20.2961 13.9731 19.8546 14.3623 19.664 14.8708C19.59 15.0682 19.5086 15.262 19.4202 15.4518C19.2053 15.913 19.2401 16.4637 19.5658 16.8546C19.962 17.33 19.9303 18.0291 19.4927 18.4667L18.4667 19.4927C18.0291 19.9303 17.33 19.962 16.8546 19.5658C16.4637 19.2401 15.913 19.2053 15.4518 19.4202C15.262 19.5086 15.0682 19.59 14.8708 19.664C14.3623 19.8546 13.9731 20.2961 13.9239 20.8369C13.864 21.4956 13.3117 22 12.6503 22H11.3497C10.6883 22 10.136 21.4956 10.0761 20.8369C10.0269 20.2961 9.63767 19.8546 9.12917 19.664C8.90927 19.5816 8.69387 19.49 8.48343 19.3897C7.99746 19.158 7.4135 19.1901 6.99992 19.5347C6.50338 19.9485 5.77325 19.9154 5.31622 19.4584L4.39829 18.5404C3.91893 18.0611 3.8842 17.2953 4.31818 16.7745C4.66939 16.3531 4.71129 15.7613 4.49263 15.2582C4.43724 15.1307 4.385 15.0016 4.336 14.8708C4.14544 14.3623 3.70392 13.9731 3.16311 13.9239C2.50438 13.864 2 13.3117 2 12.6503V11.3497C2 10.6883 2.50438 10.136 3.16311 10.0761C3.70393 10.0269 4.14544 9.63768 4.33601 9.12917C4.3936 8.9755 4.45568 8.82402 4.52209 8.67489C4.7571 8.14716 4.71804 7.52257 4.34821 7.07877C3.89722 6.53758 3.93332 5.74179 4.43145 5.24365L5.24364 4.43146C5.74178 3.93332 6.53757 3.89722 7.07876 4.34822C7.52256 4.71805 8.14715 4.7571 8.67488 4.52209C8.82401 4.45568 8.97549 4.3936 9.12916 4.33601C9.63767 4.14544 10.0269 3.70393 10.0761 3.16311Z"
                                stroke="#000"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                data-v-0139a417
                                d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                                stroke="#000"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </h6>
                          <h6 class="h7">My Profile</h6>
                          <h6 class="text-right">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 26 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8 4L16 12L8 20"
                                stroke="#000"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </h6>
                        </li>
                      </router-link>
                      <router-link v-if="optional($auth.user(), 'org_role.role_id') == 1"
                        :to="{name: 'settings'}"
                        :class="$route.meta && $route.meta.isOrgRoute ? 'router-link-exact-active' : ''"
                      >
                        <li>
                          <h6>
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 26 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="9"
                                cy="7"
                                r="4"
                                stroke="#000"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></circle>
                              <path
                                d="M2 21V17C2 15.8954 2.89543 15 4 15H14C15.1046 15 16 15.8954 16 17V21"
                                stroke="#000"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M16 3C16.8604 3.2203 17.623 3.7207 18.1676 4.42231C18.7122 5.12392 19.0078 5.98683 19.0078 6.875C19.0078 7.76317 18.7122 8.62608 18.1676 9.32769C17.623 10.0293 16.8604 10.5297 16 10.75"
                                stroke="#000"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M19 15H20C21.1046 15 22 15.8954 22 17V21"
                                stroke="#000"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </h6>
                          <h6 class="h7">Organization</h6>
                          <h6 class="text-right">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 26 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8 4L16 12L8 20"
                                stroke="#000"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </h6>
                        </li>
                      </router-link>
                      <router-link :to="{name: 'logout'}">
                        <li>
                          <h6>
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 26 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4 12H15"
                                stroke="#000"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M8 7L3 12L8 17"
                                stroke="#000"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M21 3L21 21"
                                stroke="#000"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </h6>
                          <h6 class="h7">Sign out</h6>
                          <h6 class="text-right">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 26 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8 4L16 12L8 20"
                                stroke="#000"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </h6>
                        </li>
                      </router-link>
                    </ul>
                  </div>
                </div>
              </div>
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.profile-area {
  margin: 0 auto;
  width: 90%;

  .card {
    position: relative;
    height: 100% !important;
    box-shadow: -8px 8px 16px 0 rgba(67, 86, 100, 0.07),
      -4px 4px 8px 0 rgba(67, 86, 100, 0.05);

    .card-caption {
      background: #eef3f8;
    }
    .card-profile {
      background: #eff5f9;
      padding-right: 0;
      padding-top: 10em !important;
      padding-bottom: 30em;

      .card-profile-menu_list {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 230px;

        a {
          height: 45px;
          border-radius: 5px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;

          &.router-link-exact-active {
            background: #daf1ff;
            font-family: "sansBold";

            h6 {
              color: #0a5685 !important;
            }

            svg {
              circle,
              path {
                stroke: #0a5685;
              }
            }
          }
          li {
            color: initial;
            display: flex;
            padding-left: 20px;
            flex-direction: row;
            align-items: center;
            width: 100%;
            h6:first-child {
              padding-right: 10px;
              flex-basis: 10%;
            }
            h6:not(:first-child) {
              flex-basis: 50%;
            }
            .h7 {
              margin-bottom: 0 !important;
            }
          }
        }
      }

      &.border-right {
        border-right-style: dashed !important;
      }
    }

    .card-profile_wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .card-profile_wrapper-img {
        height: 200px;
        width: 200px;
        border-radius: 20px;
        margin-top: -40px;
        border: 2px solid #eff3f7;
        position: relative;

        .card-profile_wrapper-img-overlay {
          height: 200px;
          width: 200px;
          position: absolute;
          top: 0;
          border-radius: 20px;
          background: #eef3f8;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          //   &:hover {
          //     background: linear-gradient(
          //       rgba(20, 20, 20, 0.5),
          //       rgba(20, 20, 20, 0.5)
          //     );
          //   }
          span {
            &:first-child {
              font-size: 12px;
              opacity: 0.7;
            }
          }

          i {
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // height: 100%;
            margin-top: 10px;
            font-size: 50px;
            // color: black;
            opacity: 0.6;
          }
        }
        img {
          height: 200px;
          width: 200px;
          border-radius: 20px;
          object-fit: contain;
          //   border: 1px solid #eff3f7;
        }
      }
    }
  }
}
</style>

<script>
import { toLocal } from "@/helpers/date";
import Literals from "@/helpers/dashboard/literals";
import handleError from "@/helpers/error";
import getOrganization from "@/helpers/getOrganization";
import {optional} from "@/helpers/random";

export default {
  data() {
    return {
      user: {
        organization: {}
      },
      loading: false,
      imageUrl: null,
      loaded: false,
      getOrganization
    };
  },
  methods: {
    toLocal,
    handleError,
    optional,
    importFileAndPreview() {
      var file = document.getElementById("img-upload").files[0];
      var reader = new FileReader();

      let vm = this;
      reader.addEventListener(
        "load",
        function() {
          vm.imageUrl = reader.result;
          vm.uploadFile(reader.result);
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file);
      }
    },
    uploadFile(file) {
      this.loading = true;
      let data = {
        id: this.user.organization.id,
        logo_url: file
      };
      this.$store
        .dispatch("global/uploadLogo", data)
        .then(async data => {
          let user = this.$auth.user();
          this.$store.dispatch("global/setActiveOrganization", data);
          user.organization = data;
          this.imageUrl = user.organization.logo_url;
          this.$auth.user(user);
          this.$auth.refresh().then(() => {
            this.$toast.success(Literals.ORG_UPDATE_SUCCESS, {
              position: "top-right",
              duration: 3000
            });
          });
        })
        .catch(e => {
          this.handleError(this, e, Literals.ORG_UPDATE_ERROR);
        })
        .finally(e => {
          this.loading = false;
        });
    }
  },
  created() {
    Promise.all([
      this.$store.getters["global/countries"].length || this.$store.dispatch("global/getCountries"),
      this.$store.getters["global/industries"].length || this.$store.dispatch("global/getIndustries"),
    ]).finally(() => {
      this.user.organization = this.$store.getters['global/selected_org'];
      this.imageUrl = this.user.organization && this.user.organization.logo_url;

      this.user.country = this.$store.getters["global/countries"].find(
        s => s.id == this.user.country_id
      ) || {};
      this.user.industry = this.user.organization ? this.$store.getters["global/industries"].find(
        s => s.id == this.user.organization.industry_id
      ) : {};
      this.imageUrl = this.user.organization && this.user.organization.logo_url;

      this.loaded = true;
    });
  }
};
</script>
