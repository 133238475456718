<template>
  <div v-if="showAlert" class="alert alert-danger alert-dismissible fade show" role="alert">
    <h6 class="text-small text-danger" :key="index" v-for="(err,index) in message">{{err}}</h6>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="closeAlert">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
export default {
  props: ["message"],
  data() {
    return {
      showAlert: true
    }
  },
  methods : {
    closeAlert() {
      this.showAlert = false;
    }
  }
};
</script>
