export default {
    projects: state => state.projects && state.projects.data,
    projects_collaborating: state => state.projects_collaborating && state.projects_collaborating.data,
    projects_total: state => state.projects && state.projects.meta && state.projects.meta.pagination && state.projects.meta.pagination.total ? state.projects.meta.pagination.total : 0,
    projects_simple: state => state.projects && state.projects.data && state.projects.data.length > 0 ? state.projects.data.map((p) => {
        return {
            id: p.id,
            name: p.name
        }
    }) : [],
    project: state => state.project,
    metrics: state => state.metrics,
    milestones: state => state.milestones,
    milestone: state => state.milestone,
    expenses: state => state.expenses,
    time_logs: state => state.time_logs.data.data,
    total_time_logs: state => state.time_logs && state.time_logs.data && state.time_logs.data.meta && state.time_logs.data.meta.totalHours ? state.time_logs.data.meta.totalHours : 0,
    tasks: state => state.tasks,
    collaborators: state => state.collaborators,
    collaborators_simple: state => state.collaborators.map(s => {
        return {
            id: s.id,
            first_name: s.first_name,
            last_name: s.last_name
        }
    }),
    invite: state => state.invite,
    opportunity: state => state.opportunity,
}