<template>
  <div :class="{blur:!loaded}">
    <delete-modal
            @trigger="deleteCustomerState"
            @cancel="cancelDelete"
            @deleteAction="deleteCustomer"
            :showDelete="showDelete"
            :name="showDeleteName"
            ref="delete"
    ></delete-modal>
    <delete-modal
            @trigger="deleteContactState"
      @cancel="cancelDeleteContact"
      @deleteAction="deleteContact"
      :showDelete="showDeleteContact"
      :name="showDeleteName"
      ref="deleteContact"
    ></delete-modal>
    <invoice-modal :visible="showInvoiceModal" :customer="customer" @cancel="cancelGenerateInvoice"></invoice-modal>
    <div class="row mt-4 ml-1">
      <div class="col-sm-12 col-lg-3">
        <div class="card card-blue card-p-active w-60 pl-3 pt-3 pb-4 ">
          <h6 class="text-bold h7 text-opacity-pale">Projects</h6>
          <div class="pt-1 pb-3 pr-4">
            <h6 class="pt-1 text-main text-opacity">
              Running
              <span class="float-right">{{running_projects}}</span>
            </h6>
            <h6 class="pt-1 text-main text-opacity">
              Completed
              <span class="float-right">{{completed_projects}}</span>
            </h6>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-3">
        <div class="card card-orange w-60 pl-3 pt-3 pb-4">
          <h6 class="text-bold h7 text-opacity-pale">Opportunities</h6>
          <div class="pt-1 pb-3 pr-4">
            <h6 class="pt-1 text-main text-opacity">
              Won
              <span class="float-right">{{opportunities_won}}</span>
            </h6>
            <h6 class="pt-1 text-main text-opacity">
              Lost
              <span class="float-right">{{opportunities_lost}}</span>
            </h6>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-3">
        <div class="card card-orange w-60 pl-3 pt-3 pb-4">
          <h6 class="text-bold h7 text-opacity-pale">Invoices</h6>
          <div class="pt-1 pb-3 pr-4">
            <h6 class="pt-1 text-main text-opacity">
              Paid
              <span class="float-right">{{invoices_paid}}</span>
            </h6>
            <h6 class="pt-1 text-main text-opacity">
              Unpaid
              <span class="float-right">{{invoices_unpaid}}</span>
            </h6>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-3">
        <div class="card card-orange w-60 pl-3 pt-3 pb-4">
          <h6 class="text-bold h7 text-opacity-pale">Tasks</h6>
          <div class="pt-1 pb-3 pr-4">
            <h6 class="pt-1 text-main text-opacity">
              Overdue
              <span class="float-right">{{tasks_overdue}}</span>
            </h6>
            <h6 class="pt-1 text-main text-opacity">
              Completed
              <span class="float-right">{{tasks_completed}}</span>
            </h6>
          </div>
        </div>
      </div>
    </div>
    <div class="pr-4">
      <div class="mt-4 row">
        <div class="col-sm-12 col-lg-3">
          <div class="col-sm-12">
            <div class="card w-70">
              <div class="card-header no-right-padding-card">
                <span class="pt-2">Customer Details</span>
                <router-link :to="{name: 'customers-edit'}">
                  <span class="btn-icon-sm float-right">
                    <svg
                      width="20"
                      height="20"
                      fill="#72809D"
                      viewBox="0 0 30 30"
                      xmlns="http://www.w3.org/2000/svg"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                    >
                      <path
                        d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
                      ></path>
                    </svg>
                  </span>
                </router-link>
                <router-link :to="{name: 'customers-edit'}">
                  <span class="btn-icon-sm float-right">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#72809D"
                      viewBox="0 0 30 30"
                    >
                      <path
                        d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"
                      ></path>
                    </svg>
                  </span>
                </router-link>
              </div>
              <div class="card-content p-3">
                <div class="row">
                  <div class="col-sm-6">Customer Name:</div>
                  <div class="col-sm-6 float-right text-right">{{customer.company_name}}</div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-6">Industry:</div>
                  <div
                    class="col-sm-6 float-right text-right"
                  >{{customer.industry && customer.industry.name}}</div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-6">Phone:</div>
                  <div
                    class="col-sm-6 float-right text-right"
                  >{{customer.country_code && customer.phone_number ? customer.full_phone_number : ''}}</div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-6">Address:</div>
                  <div
                    class="col-sm-6 float-right text-right"
                    v-if="customer_address"
                  >{{customer_address}}</div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-6">Email:</div>
                  <div class="col-sm-6 float-right text-right" v-if="customer.email">{{customer.email}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 mt-4 mb-2">
            <div class="card w-70">
              <div class="card-header no-right-padding-card">
                Related Contacts
                <span class="btn-icon-sm float-right">
                  <svg
                    width="20"
                    height="20"
                    fill="#72809D"
                    viewBox="0 0 30 30"
                    xmlns="http://www.w3.org/2000/svg"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                  >
                    <path
                      d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
                    ></path>
                  </svg>
                </span>
                <!-- <span class="btn-icon-sm float-right">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#72809D"
                    viewBox="0 0 30 30"
                  >
                    <path
                      d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"
                    ></path>
                  </svg>
                </span>-->
                <router-link :to="{name: 'customers-contacts-new'}">
                  <span class="btn-icon-sm float-right">
                    <svg
                      width="20"
                      height="20"
                      fill="#72809D"
                      viewBox="0 0 30 30"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z"
                      ></path>
                    </svg>
                  </span>
                </router-link>
              </div>
              <div class="card-content p-3">
                <div class="row card-border-top contacts-card-scroll">
                  <div :key="contact.id" v-for="contact in contacts" class="col-sm-12 list">
                    <div class="row mt-3">
                      <div class="col-sm-12">
                        <h6 class="h7 text-bold text-opacity-pale">
                          <span
                            v-b-tooltip.hover
                            :title="'Primary Contact'"
                            v-if="contact.id == customer.primary_contact_id"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              width="20"
                              height="20"
                              viewBox="0 0 64 64"
                              style=" fill:#000000;"
                            >
                              <path
                                fill="green"
                                d="M 19 6 C 17.347656 6 16 7.347656 16 9 L 16 55.027344 C 16 55.785156 16.417969 56.46875 17.09375 56.8125 C 17.769531 57.152344 18.574219 57.085938 19.183594 56.640625 L 31.40625 47.671875 C 31.761719 47.414063 32.238281 47.414063 32.59375 47.671875 L 44.816406 56.640625 C 45.171875 56.898438 45.585938 57.027344 46 57.027344 C 46.308594 57.027344 46.621094 56.957031 46.90625 56.8125 C 47.582031 56.46875 48 55.785156 48 55.027344 L 48 9 C 48 7.347656 46.652344 6 45 6 Z M 19 8 L 45 8 C 45.550781 8 46 8.449219 46 9 L 46 55.027344 L 33.773438 46.0625 C 33.242188 45.671875 32.621094 45.476563 32 45.476563 C 31.378906 45.476563 30.757813 45.671875 30.226563 46.0625 L 18 55.027344 L 18 9 C 18 8.449219 18.449219 8 19 8 Z M 22 10 C 21.445313 10 21 10.445313 21 11 L 21 13 C 21 13.554688 21.445313 14 22 14 C 22.554688 14 23 13.554688 23 13 L 23 11 C 23 10.445313 22.554688 10 22 10 Z M 27 10 C 26.445313 10 26 10.445313 26 11 L 26 13 C 26 13.554688 26.445313 14 27 14 C 27.554688 14 28 13.554688 28 13 L 28 11 C 28 10.445313 27.554688 10 27 10 Z M 32 10 C 31.445313 10 31 10.445313 31 11 L 31 13 C 31 13.554688 31.445313 14 32 14 C 32.554688 14 33 13.554688 33 13 L 33 11 C 33 10.445313 32.554688 10 32 10 Z M 37 10 C 36.445313 10 36 10.445313 36 11 L 36 13 C 36 13.554688 36.445313 14 37 14 C 37.554688 14 38 13.554688 38 13 L 38 11 C 38 10.445313 37.554688 10 37 10 Z M 42 10 C 41.445313 10 41 10.445313 41 11 L 41 13 C 41 13.554688 41.445313 14 42 14 C 42.554688 14 43 13.554688 43 13 L 43 11 C 43 10.445313 42.554688 10 42 10 Z"
                              ></path>
                            </svg>
                          </span>
                          {{`${contact.first_name} ${contact.last_name}`}}
                          <button
                            class="btn btn-icon float-right"
                            @click="deleteContactState(contact.id)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#72809D"
                              viewBox="0 0 30 30"
                            >
                              <path
                                d="M9 13v6c0 .552-.448 1-1 1s-1-.448-1-1v-6c0-.552.448-1 1-1s1 .448 1 1zm7-1c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm-4 0c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm4.333-8.623c-.882-.184-1.373-1.409-1.189-2.291l-5.203-1.086c-.184.883-1.123 1.81-2.004 1.625l-5.528-1.099-.409 1.958 19.591 4.099.409-1.958-5.667-1.248zm4.667 4.623v16h-18v-16h18zm-2 14v-12h-14v12h14z"
                              ></path>
                            </svg>
                          </button>
                          <router-link
                            :to="{name: 'customers-contacts-edit', params:{id:customer.id,cid:contact.id}}"
                          >
                            <span class="btn btn-icon float-right">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#72809D"
                                viewBox="0 0 30 30"
                              >
                                <path
                                  d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"
                                ></path>
                              </svg>
                            </span>
                          </router-link>
                        </h6>
                        <h6 class="text-opacity h7">{{contact.email}}</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12" v-if="contacts.length <= 0">
                    <h6 class="h7 text-opacity">No Contact has been created yet!</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-9 pl-5 col-md-8 float-right">
          <div class="tab-wrapper tab-wrapper-mobile">
            <ul class="tabs">
              <router-link :to="{name: 'customers-show'}">
                <li>Projects</li>
              </router-link>
              <router-link :to="{name: 'customers-show-tasks'}">
                <li>Tasks</li>
              </router-link>
              <router-link :to="{name: 'customers-show-opportunities'}">
                <li>Opportunities</li>
              </router-link>
              <router-link :to="{name: 'customers-show-invoices'}">
                <li>Invoices</li>
              </router-link>
<!--              <router-link :to="{name: 'customers-show-attachments'}">-->
<!--                <li>Attachments</li>-->
<!--              </router-link>-->
            </ul>
            <ul
              class="bg-sky p-3 text-right"
              style="display:flex;align-items:center;justify-content:flex-end"
            >
              <li class="pr-3" v-if="$route.name != 'customers-show-invoices'">
                <router-link
                  :to="$route.name == 'customers-show-tasks'
                  ? {name:'customers-project-task-create', query: {cid:customer && customer.id,from :'customer'}}
                  : (
                      $route.name == 'customers-show-opportunities'
                      ? {name:'customers-opportunities-create', query :{cid:customer && customer.id, from: 'customer'}}
                      : {name:'customers-project-create', query :{cid:customer && customer.id,from:'customer'}}
                      )"
                  class="btn-action btn-table"
                  style="color:inherit !important"
                >
                  <span
                    class="btn-text float-right pt-2 pl-3 pr-3 pb-2"
                    style="font-weight:bold;font-size:14px;background:#ece0c9;cursor:pointer;color:#946712 !important"
                  >
                    <span class="pr-2">
                      <svg
                        style="fill:#946712 !important"
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        fill="#946712"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M19 11h-14v-2h14v2zm0 2h-14v2h14v-2zm0 4h-14v2h14v-2zm3-11v16h-20v-16h20zm2-6h-24v24h24v-24z"
                        ></path>
                      </svg>
                    </span>
                    Create New
                  </span>
                </router-link>
              </li>

              <li class="pr-3" v-if="$route.name == 'customers-show-invoices'">
                <span
                  @click="showInvoiceModal = true"
                  class="btn-text float-right pt-2 pl-3 pr-3 pb-2"
                  style="font-weight:bold;font-size:14px;background:#daf1ff;cursor:pointer"
                >
                  <span class="pr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M8 24l2.674-9h-9.674l16-15-2.674 9h8.674l-15 15zm-1.586-11h6.912l-1.326 4 5.739-6h-6.065l1.304-4-6.564 6z"
                      ></path>
                    </svg>
                  </span>
                  Generate Invoice
                </span>
              </li>
            </ul>
            <div class>
              <router-view ></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <router-view ></router-view> -->
  </div>
</template>

<script>
import DeleteModal from "@/components/Modals/Delete";
import InvoiceModal from "@/components/Modals/CustomerInvoice";
export default {
  title: `Show | Customers`,
  data() {
    return {
      showDelete: false,
      showDeleteContact: false,
      showDeleteName: "",
      deleteContactId: null,
      showInvoiceModal: false,
      loaded: false
    };
  },
  components: {DeleteModal,InvoiceModal},
  methods: {
    cancelGenerateInvoice() {
      this.showInvoiceModal = false;
    },
    deleteCustomerState() {
      this.showDelete = true;
      this.showDeleteName = "Customer";
    },
    deleteContactState(id) {
      this.deleteContactId = id;
      this.showDeleteContact = true;
      this.showDeleteName = "Contact";
    },
    cancelDelete() {
      this.showDelete = false;
    },
    cancelDeleteContact() {
      this.showDeleteContact = false;
      this.deleteContactId = null;
    },
    deleteCustomer(s) {
      return this.$store
        .dispatch("customers/delete", this.$route.params.id)
        .then(s => {
          this.$router.push({ name: "customers-index" });
          this.$toast.success("Customer Successfully Deleted", {
            position: "top-right",
            duration: 3000
          });
        });
    },
    deleteContact() {
      let id = this.deleteContactId;
      return this.$store.dispatch("customers/deleteContact", id).then(s => {
        this.$toast.success("Contact Successfully Deleted", {
          position: "top-right",
          duration: 3000
        });
        this.cancelDeleteContact();
        this.$store.commit(
          "customers/SET_CONTACTS",
          this.contacts.filter(s => s.id !== id)
        );
      });
    }
  },
  computed: {
    customer() {
      return this.$store.getters["customers/customer"];
    },
    contacts() {
      return this.$store.getters["customers/contacts"];
    },
    customer_address() {
      let cust = this.customer.billing_address;
      let address = cust && cust.address ? cust.address : null;
      if (cust) {
        let country = cust && cust.country;
        let region = cust && cust.region;
        let city = cust && cust.city;

        if (country) {
          address += address ? ", " + country.name : country.name;
        }
        if (region) {
          address += address ? ", " + region.name : region.name;
        }
        if (city) {
          address += address ? ", " + city.name : city.name;
        }
        if (cust.post_code) {
          address += address ? ", " + cust.post_code : cust.post_code;
        }
      }

      return address;
    },
    projects() {
      return this.$store.getters["customers/projects"];
    },
    tasks() {
      return this.$store.getters["customers/tasks"];
    },
    tasks_overdue() {
      return this.tasks.filter(b => b.status && b.status.id == 2).length;
    },
    tasks_completed() {
      return this.tasks.filter(b => b.status && b.status.id == 2).length;
    },
    opportunities() {
      return this.$store.getters["customers/opportunities"];
    },
    opportunities_won() {
      return this.opportunities.filter(b => b.opportunity_stage && b.opportunity_stage.id == 5)
        .length;
    },
    opportunities_lost() {
      return this.opportunities.filter(b => b.opportunity_stage && b.opportunity_stage.id == 6)
        .length;
    },
    attachments() {
      return this.$store.getters["customers/attachments"];
    },
    invoices() {
      return this.$store.getters["customers/invoices"];
    },
    invoices_paid() {
      return this.invoices.filter(b => b.status && b.status.id == 2).length;
    },
    invoices_unpaid() {
      return this.invoices.filter(b => b.status && b.status.id == 1).length;
    },
    running_projects() {
      return this.projects.filter(
        project => project.project_status && project.project_status.id == 16
      ).length;
    },
    completed_projects() {
      return this.projects.filter(
        project => project.project_status && project.project_status.id == 6
      ).length;
    },
    breadCrumbs() {
      return [
        {
          text: "Home",
          to: { name: "home" }
        },
        {
          text: "All Customers",
          to: { name: "customers-index" }
        },
        {
          text: this.customer.company_name,
          to: { name: "customers-show", params: { id: this.customer.id } }
        }
      ];
    }
  },
  created() {
    Promise.all([
      this.$store.dispatch("customers/getSingle", this.$route.params.id),
      this.$store.dispatch("customers/getProjects", this.$route.params.id),
      this.$store.dispatch("customers/getOpportunities", this.$route.params.id),
      this.$store.dispatch("customers/getTasks", this.$route.params.id),
      this.$store.dispatch("customers/getAttachments", this.$route.params.id),
      this.$store.dispatch("customers/getInvoices", this.$route.params.id),
      this.$store.dispatch("customers/getContacts", this.$route.params.id),
    ]).finally(() => {
      this.loaded = true;
    })
  }
};
</script>
