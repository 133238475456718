<template>
  <div class="stripe-modal" id="expenseModal">
    <div class="stripe-modal__wrapper">
      <div
        class="stripe-modal__header"
        style="display: flex; justify-content: space-between"
      >
        <h2 class="pl-1 pt-3 pr-3 f-24">Add Card</h2>
        <button type="button" class="close" @click="close" style="width: 30%">
          Cancel
          <i class="zmdi zmdi-close-circle pl-2" style="font-size: 20px"></i>
        </button>
      </div>
      <div class="pt-4 pb-2 pl-3 pr-3">
        <div id="card-element" class="form-control"></div>
      </div>
      <footer style="background: #f0f4f8">
        <div>
          <button
            type="button"
            class="btn btn-primary text-main"
            style="background: transparent"
            @click="close"
          >
            Cancel
          </button>
          <button class="btn btn-primary ml-3" @click="subscribe">
            <div v-if="!loading">{{ "Continue " }}</div>
            <div
              v-else
              class="spinner-grow spinner-grow-sm text-light"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import subscription from "@/mixins/subscription";
import {optional} from "@/helpers/random";

export default {
  //   props: ["intentData"],
  mixins: [subscription],
  data() {
    return {
      body: {
        quantity: "",
      },
      intentData: {},
      card: "",
      elements: "",
      stripeInit: "",
      loading: false,
      stripe: "",
    };
  },
  mounted() {
   
    Promise.all([
      this.$store.dispatch("global/getStripeKey").then((s) => {
        let key = optional(s, 'data.data.stripe_key', null);
      
        this.intentData = s;
     
        if (key) {
          this.stripeInit = loadStripe(key);
        }
      }),
    ]).finally(() => {
      this.configureStripe();
    });
  },
  methods: {
    loadStripe,
    close() {
      this.$emit("close");
    },
    async configureStripe() {
      
      this.stripeInit.then((s) => {
        this.elements = s.elements();
        this.card = this.elements.create("card");

        this.card.mount("#card-element");
      });
    },
    subscribe() {
      this.loading = true;
      let url = this.$baseApi + "/organizations/subscribe";
      this.loading = true;
   

      this.body["cs"] = optional(this.intentData, 'data.data.intent.client_secret');
      this.pay();
 
    },
    async pay() {
      let vm = this;
      this.stripeInit.then((stripe) => {
        stripe
          .confirmCardSetup(this.body && this.body.cs, {
            payment_method: {
              card: this.card,
              billing_details: {
                name:
                  this.$auth.user().first_name +
                  " " +
                  this.$auth.user().last_name,
              },
            },
          })
          .then(function (result) {
            if (result && result.error) {
              vm.$toast.error(result.error.message, {
                position: "top-right",
                duration: 7000,
              });
            } else {
    

              let token = result.setupIntent.payment_method;
              let url = vm.$baseApi + "/organizations/subscribe";
              let data = {
                quantity: 0,
                token,
              };
              vm.$http
                .post(url, data)
                .then((s) => {
                  vm.$toast.success("Card added successfully.", {
                    position: "top-right",
                    duration: 3000,
                  });
                  vm.$emit("fetchCards");
                  vm.close();
                })
                .finally(() => {
                  vm.loading = false;
                });
            }
          })
          .catch(function (error) {
            vm.$toast.error(error.message, {
              position: "top-right",
              duration: 7000,
            });
          })
          .finally(() => (this.loading = false));
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.stripe-modal {
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.24);
  width: 100vw;
  left: 0;
  top: 0;

  z-index: 2;

  &__wrapper {
    background: white;
    width: 30vw;
    height: 270px;
    border-radius: 10px;
    margin: 70px auto;
    position: relative;
  }
  &__header {
    padding-left: 30px !important;
    padding-right: 30px;
    padding-top: 10px;
  }
  footer {
    background: rgb(240, 244, 248);
    /* position: absolute; */
    /* bottom: 0; */
    height: auto;
    padding: 0.75rem;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    width: inherit;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
</style>