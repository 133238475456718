function getTitle(vm) {
    const {
        title
    } = vm.$options
    
    if (title) {
        return typeof title === 'function' ?
            title.call(vm) :
            title
    }
}
export default {
    created() {
        const t = getTitle(this)
        if (t) {
            document.title = `${t} | ${process.env.VUE_APP_NAME || 'Leaf'}`
            sessionStorage.setItem('page_title', document.title);
        }
    }
}