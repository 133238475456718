<template>
  <!-- Modal -->
  <b-modal
    @hide="close"
    :no-close-on-backdrop="true"
    ref="customer-create-modal"
    class="deletemodal"
  >
    <template v-slot:modal-header>
      <h2 class="pl-3 pt-3 pr-3 f-24">
        {{
          $route.params && $route.params.id
            ? "Edit Customer"
            : "Create New Customer"
        }}
      </h2>
      <button class="close" @click="close">
        Cancel
        <i class="zmdi zmdi-close-circle pl-2" style="font-size: 20px"></i>
      </button>
    </template>
    <div class="d-block pl-3">
      <form class="d-form" @submit.prevent="save">
        <div class="form-accordion">
          <accordion
            @toggleCollapse="toggleCollapse(0)"
            :visible="accordions[0].visible"
            :name="'Customer details'"
          >
            <div slot="content">
              <div class="row">
                <div class="col-sm-12 col-lg-6 mt-1">
                  <h6 class="text-bold text-opacity-pale">
                    Company Name
                    <b>*</b>
                  </h6>
                  <input
                    type="text"
                    v-validate="'required'"
                    v-model="body.company_name"
                    name="company_name"
                    class="form-control auth-input d-input"
                    placeholder="Enter company name"
                    data-vv-as="Company Name"
                  />
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('company_name')"
                    >{{ errors.first("company_name") }}</small
                  >
                </div>
                <div class="col-sm-12 col-lg-6 mt-1">
                  <h6 class="text-bold text-opacity-pale">Industry</h6>
                  <multiselect
                    track-by="id"
                    return="id"
                    label="name"
                    name="industry"
                    v-model="body.industry"
                    :options="industries"
                    :show-labels="false"
                    data-vv-as="Project Status"
                  ></multiselect>
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('industry_id')"
                    >{{ errors.first("industry_id") }}</small
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-lg-6 mt-4">
                  <h6 class="text-bold text-opacity-pale">
                    Account Status
                    <b>*</b>
                  </h6>
                  <multiselect
                    track-by="id"
                    return="id"
                    label="name"
                    v-model="body.status"
                    v-validate="'required'"
                    :options="statuses"
                    :show-labels="false"
                    name="status"
                    data-vv-as="Account Status"
                  ></multiselect>
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('status')"
                    >{{ errors.first("status") }}</small
                  >
                </div>
                <div class="col-sm-12 col-lg-6 mt-4">
                  <h6 class="text-bold text-opacity-pale">Source</h6>
                  <multiselect
                    track-by="id"
                    return="id"
                    label="name"
                    name="source"
                    v-model="body.customer_source"
                    :options="customer_sources"
                    :show-labels="false"
                    data-vv-as="Customer Source"
                    placeholder="Select Customer Source"
                  ></multiselect>
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('source_id')"
                    >{{ errors.first("source_id") }}</small
                  >
                </div>
              </div>
            </div>
          </accordion>
          <accordion
            @toggleCollapse="toggleCollapse(1)"
            :visible="accordions[1].visible"
            :name="'Contact Information'"
          >
            <div slot="content">
              <div class="row">
                <div class="col-sm-12 col-lg-6">
                  <h6 class="text-bold text-opacity-pale">Phone Number</h6>
                  <div class="row">
                    <div class="col-lg-12 pt-1 d-contact">
                      <span>
                        <multiselect
                          v-model="body.ocountry"
                          track-by="id"
                          class="mobile_dropdown"
                          return="id"
                          label="d_code"
                          name="country"
                          :custom-label="countriesDialCode"
                          placeholder="Dial No."
                          :options="countries"
                          :show-labels="false"
                          data-vv-as="Dial Code"
                        >
                          <template slot="option" slot-scope="props">
                            <span class="text-opacity"
                              >({{ props.option.d_code }})</span
                            >
                            {{ props.option.name }}
                          </template>
                          <template slot="singleLabel" slot-scope="props">{{
                            props.option.d_code
                          }}</template>
                        </multiselect>
                      </span>
                      <span>
                        <input
                          type="tel"
                          v-model="body.phone_number"
                          name="office_phone"
                          class="form-control auth-input d-input"
                          placeholder="Enter Contact's office phone number"
                          data-vv-as="Contact's Office phone no"
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-lg-6">
                  <div class="row">
                    <div class="col-lg-12">
                      <span>
                        <h6 class="text-bold text-opacity-pale">
                          Email Address
                        </h6>
                        <input
                          type="email"
                          v-model="body.email"
                          v-validate="'email'"
                          name="email"
                          class="form-control auth-input d-input"
                          placeholder="Enter customer email"
                          data-vv-as="Customer Email"
                        />
                      </span>
                      <small
                        class="form-text text-danger"
                        v-show="errors.has('email')"
                        >{{ errors.first("email") }}</small
                      >
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-12 mt-4">
                  <h6 class="text-bold text-opacity-pale">Website</h6>
                  <input
                    type="url"
                    v-model="body.website"
                    v-validate="'url'"
                    name="website"
                    class="form-control auth-input d-input"
                    placeholder="Enter customer website address"
                    data-vv-as="Customer Website"
                  />
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('website')"
                    >{{ errors.first("website") }}</small
                  >
                </div>
              </div>
            </div>
          </accordion>
          <accordion
            @toggleCollapse="toggleCollapse(2)"
            :visible="accordions[2].visible"
            :name="'Payment Information'"
          >
            <div slot="content">
              <div class="row">
                <div class="col-sm-12 col-lg-6 mt-1">
                  <h6 class="text-bold text-opacity-pale">Currency <b>*</b></h6>
                  <multiselect
                    :options="currencies"
                    :show-labels="false"
                    data-vv-as="Currency"
                    :custom-label="currencySelectLabel"
                    label="name"
                    name="currency_id"
                    placeholder="Select Currency"
                    track-by="id"
                    v-model="body.currency"
                    v-validate="'required'"
                  ></multiselect>
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('currency_id')"
                    >{{ errors.first("currency_id") }}</small
                  >
                </div>
                <div class="col-sm-12 col-lg-6 mt-1">
                  <h6 class="text-bold text-opacity-pale">
                    Standard Hourly Rate
                  </h6>
                  <input
                    type="number"
                    v-model="body.standard_hourly_rate"
                    class="form-control auth-input d-input"
                    placeholder="Enter Standard Hourly Rate"
                    data-vv-as="Standard Hourly Rate"
                  />
                  <small
                    class="form-text text-danger"
                    v-show="errors.has('standard_hourly_rate')"
                    >{{ errors.first("standard_hourly_rate") }}</small
                  >
                </div>
              </div>
            </div>
          </accordion>

          <accordion
            @toggleCollapse="toggleCollapse(3)"
            :visible="accordions[3].visible"
            :name="'Address Information'"
          >
            <div slot="content">
              <div class="row">
                <div class="col-sm-12 col-lg-6 mt-1">
                  <div class="row">
                    <div class="col-sm-12 col-lg-12">
                      <h6 class="text-bold text-opacity-pale">
                        Shipping Address
                      </h6>
                    </div>
                    <div class="col-sm-12 col-lg-12 mt-4">
                      <h6 class="text-bold text-opacity-pale">Street 1</h6>
                      <input
                        type="text"
                        v-model="body.shipping_address.address"
                        class="form-control auth-input d-input"
                        placeholder="Enter stress address"
                        data-vv-as="Street Address"
                      />
                    </div>
                    <div class="col-sm-12 col-lg-12 mt-4">
                      <div class="row">
                        <div class="col-sm-12 col-lg-6">
                          <h6 class="text-bold text-opacity-pale">Country</h6>
                          <multiselect
                            track-by="id"
                            return="id"
                            label="name"
                            v-model="body.shipping_address.country"
                            name="country_id"
                            :options="countries"
                            :show-labels="false"
                            @input="setStates(body.shipping_address.country)"
                            data-vv-as="Country"
                          ></multiselect>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <h6 class="text-bold text-opacity-pale">State</h6>
                          <multiselect
                            track-by="id"
                            return="id"
                            :disabled="
                              !(
                                body.shipping_address &&
                                body.shipping_address.country &&
                                body.shipping_address.country.id
                              )
                            "
                            label="name"
                            v-model="body.shipping_address.region"
                            :options="shippingStates"
                            :show-labels="false"
                            @input="setCities(body.shipping_address.region)"
                            data-vv-as="State"
                          ></multiselect>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-sm-12 col-lg-6">
                          <h6 class="text-bold text-opacity-pale">City</h6>
                          <multiselect
                            v-if="
                              shippingCities.length ||
                              typeof body.shipping_address.city === 'object'
                            "
                            :disabled="
                              !(
                                body.shipping_address.region &&
                                body.shipping_address.region.id
                              )
                            "
                            track-by="id"
                            return="id"
                            label="name"
                            v-model="body.shipping_address.city"
                            name="city"
                            :options="shippingCities"
                            :show-labels="false"
                            data-vv-as="City"
                          ></multiselect>

                          <input
                            v-else
                            type="text"
                            v-model="body.shipping_address.city"
                            name="shipping_city"
                            class="form-control auth-input d-input"
                            placeholder="Enter City"
                            data-vv-as="Shipping City"
                          />
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <h6 class="text-bold text-opacity-pale">Zip Code</h6>
                          <input
                            type="text"
                            v-model="body.shipping_address.post_code"
                            class="form-control auth-input d-input"
                            placeholder="Enter shipping zip code"
                            data-vv-as="Zip Code"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-lg-6 mt-1">
                  <div class="row">
                    <div class="col-sm-12 col-lg-12">
                      <div class="row">
                        <div class="col-sm-6 col-lg-5">
                          <h6 class="text-bold text-opacity-pale">
                            Billing Address
                          </h6>
                        </div>
                        <div class="col-sm-6 col-lg-7">
                          <input
                            type="checkbox"
                            v-model="body.billing_same_shipping"
                            @change="setBillingAddress"
                            true-value="yes"
                            false-value="no"
                          />
                          <small class="pl-2">Same as Shipping Address</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-12 mt-4">
                      <h6 class="text-bold text-opacity-pale">Street 1</h6>
                      <input
                        type="text"
                        v-model="body.billing_address.address"
                        class="form-control auth-input d-input"
                        placeholder="Enter stress address"
                        data-vv-as="Billing Street Address"
                      />
                    </div>
                    <div class="col-sm-12 col-lg-12 mt-4">
                      <div class="row">
                        <div class="col-sm-12 col-lg-6">
                          <h6 class="text-bold text-opacity-pale">Country</h6>
                          <multiselect
                            track-by="id"
                            return="id"
                            label="name"
                            v-model="body.billing_address.country"
                            :options="countries"
                            @input="setStates(body.billing_address.country, 1)"
                            :show-labels="false"
                            data-vv-as="Billing Country"
                          ></multiselect>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <h6 class="text-bold text-opacity-pale">State</h6>
                          <multiselect
                            track-by="id"
                            :disabled="
                              !(
                                body.billing_address.country &&
                                body.billing_address.country.id
                              )
                            "
                            return="id"
                            label="name"
                            v-model="body.billing_address.region"
                            :options="billingStates"
                            @input="setCities(body.billing_address.region, 1)"
                            :show-labels="false"
                            data-vv-as="State"
                          ></multiselect>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-sm-12 col-lg-6">
                          <h6 class="text-bold text-opacity-pale">City</h6>
                          <multiselect
                            v-if="
                              billingCities.length ||
                              typeof body.billing_address.city === 'object'
                            "
                            track-by="id"
                            :disabled="
                              !(
                                body.billing_address.region &&
                                body.billing_address.region.id
                              )
                            "
                            return="id"
                            label="name"
                            v-model="body.billing_address.city"
                            :options="billingCities"
                            :show-labels="false"
                            data-vv-as="Billing City"
                          ></multiselect>
                          <input
                            v-else
                            type="text"
                            v-model="body.billing_address.city"
                            name="billing_city"
                            class="form-control auth-input d-input"
                            placeholder="Enter City"
                            data-vv-as="Billing City"
                          />
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <h6 class="text-bold text-opacity-pale">Zip Code</h6>
                          <input
                            type="number"
                            v-model="body.billing_address.post_code"
                            name="billing_zipcode"
                            class="form-control auth-input d-input"
                            placeholder="Enter Zip code"
                            data-vv-as="Billing ZipCode"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </accordion>
        </div>
      </form>
    </div>
    <template v-slot:modal-footer>
      <dashboard-button
        @submit="save"
        :loading="loading"
        :text="$route.params && $route.params.id ? 'Save' : 'Save'"
      ></dashboard-button>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped>
.d-contact {
  display: grid;
  grid-template-columns: 27% 72%;
  grid-gap: 1%;

  input {
    margin-top: 0px;
  }
}
</style>
<script>
import DashboardButton from "@/components/Buttons/Dashboard";
import Literals from "@/helpers/dashboard/literals";
import Accordion from "@/components/Collapse/Index";
import { setTimeout } from "timers";
import getOrganization from "@/helpers/getOrganization";
import handleError from "@/helpers/error";

export default {
  data() {
    return {
      Literals,
      loading: false,
      accordions: [
        { name: "basic", visible: true },
        { name: "contact", visible: true },
        { name: "payment", visible: true },
        { name: "address", visible: false },
        { name: "others", visible: true },
      ],
      statuses: [
        { id: true, name: "Active" },
        { id: false, name: "Inactive" },
      ],
      states: [],
      shippingCities: [],
      billingCities: [],
      shippingStates: [],
      billingStates: [],
      body: {
        shipping_address: {
          address: "",
          post_code: "",
          country: {},
          city: null,
          region: {},
        },
        billing_address: {
          address: "",
          post_code: "",
          country: {},
          city: null,
          region: {},
        },
        ocountry: "",
        customer_source: "",
        currency: "",
        status: { id: false, name: "Inactive" },
        billing_same_shipping: "no",
      },
    };
  },
  components: { Accordion, DashboardButton },
  props: ["customer"],
  methods: {
    handleError,
    close() {
      return this.$router.go(-1);
    },
    toggleCollapse(index) {
      this.accordions[index].visible =
        this.accordions[index].visible === false ? true : false;
    },
    countriesDialCode({ d_code, name }) {
      return `(+${d_code})  ${name}`;
    },
    currencySelectLabel({ code, name, symbol }) {
      return ` ${name} (${symbol} ${code}) `;
    },
    save() {
      this.$validator.validateAll().then((valid) => {

        if (valid) {

          if (this.body.company_name.match(/^[0-9]+$/) != null) {

            return this.errors.add({
              field: "company_name",
              msg: "Company name cannot be all numbers",
            });
          }
          this.loading = true;
          this.submit();
        }
      });
    },
    fillAddress(rawAddress) {
      let parsedAddress = {};
      if (rawAddress) {
        parsedAddress = {
          address: rawAddress.address,
          region_id: rawAddress.region ? rawAddress.region.id : null,
          country_id: rawAddress.country ? rawAddress.country.id : null,
          post_code: rawAddress.post_code,
        };

        rawAddress.city && rawAddress.city.id
          ? (parsedAddress.city_id = rawAddress.city.id)
          : (parsedAddress.city = rawAddress.city);

        rawAddress.city && rawAddress.id
          ? (parsedAddress.id = rawAddress.id)
          : null;
      }
      return parsedAddress.address &&
        parsedAddress.region_id &&
        parsedAddress.country_id
        ? parsedAddress
        : null;
    },
    async submit() {

      let data = {
        company_name: this.body.company_name,
        email: this.body.email,
        phone_number: this.body.phone_number,
        country_code: this.body.ocountry ? this.body.ocountry.d_code : null,
        website: this.body.website,
        industry_id: this.body.industry ? this.body.industry.id : null,
        standard_hourly_rate: this.body.standard_hourly_rate,
        is_active: this.body.status ? this.body.status.id : true,
        organization_id: getOrganization.organization_id(),
        currency_id: this.body.currency ? this.body.currency.id : null,
        source_id: this.body.customer_source
          ? this.body.customer_source.id
          : null,
      };

      //TODO:should be enclosed in bracket once supported by API

      let shipping_address = this.fillAddress(this.body.shipping_address);
      let billing_address = this.fillAddress(this.body.billing_address);

      if (shipping_address) {
       
        await this.$store
          .dispatch("global/createUpdateAddress", shipping_address)
          .then((id) => {
            data.shipping_address_id = id;
          })
          .catch((error) => {
            let msgPass = `Failed to ${
              shipping_address.id ? "update" : "create"
            } shipping address`;

            this.handleError(this, error, msgPass);
            this.loading = false;
          });
      }

      if (billing_address) {
       
        await this.$store
          .dispatch("global/createUpdateAddress", billing_address)
          .then((id) => {
            data.billing_address_id = id;
          })
          .catch((error) => {
            let msgPass = `Failed to ${
              billing_address.id ? "update" : "create"
            } recipient address`;

            this.handleError(this, error, msgPass);
            this.loading = false;
          });
      }

      let msgPass = "";
      if (this.$route.params && this.$route.params.id) {
        msgPass = Literals.CUSTOMER_UPDATE_SUCCESS;
        Object.assign(data, { id: this.$route.params.id });
      } else {
        msgPass = Literals.CUSTOMER_CREATE_SUCCESS;
      }

      if (this.loading) {
     
        this.$store
          .dispatch("customers/createUpdate", data)
          .then((s) => {
        
            this.$toast.success(msgPass, {
              position: "top-right",
              duration: 3000,
            });
            if (this.$route && this.$route.query && this.$route.query.from) {
              //push back to customer page with id
              this.$router.push({
                name: "projects-create",
                query: { cid: s },
              });
            } else {
              this.$router.push({
                name: "customers-show",
                 params: { id: s },
              });
            }
          })
          .catch((error) => {
           
            let errorMessage = data.id
              ? Literals.CUSTOMER_UPDATE_ERROR
              : Literals.CUSTOMER_CREATE_ERROR;

            this.handleError(this, error, errorMessage);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    setBillingAddress() {
      // set billing address equal shipping address
      let billing_address = {
        country: {},
        region: {},
        city: this.billingCities.length ? {} : "",
        post_code: {},
        address: "",
      };

      if (this.body.billing_same_shipping === "yes") {
        Object.assign(billing_address, this.body.shipping_address);
        if (billing_address.country.id)
          this.setStates(billing_address.country, 1);
        if (billing_address.region.id)
          this.setCities(billing_address.region, 1);
      }

      this.body.billing_address = billing_address;
    },
    setStates(selectedCountry, type) {
      let states =
        selectedCountry &&
        selectedCountry.regions &&
        selectedCountry.regions.data
          ? selectedCountry.regions.data
          : [];
      type === 1
        ? (this.billingStates = states)
        : (this.shippingStates = states);
    },
    setCities(state, type) {
      this.$store.dispatch("global/getState", state.id).then((data) => {
        let cities = data && data.cities && data.cities.data;

        if (type === 1) {
          this.billingCities = cities;
          if (!this.billingCities.length) this.body.billing_address.city = "";
        } else {
          this.shippingCities = cities;
          if (!this.shippingCities.length) this.body.shipping_address.city = "";
        }
      });
    },
    formatData() {
      if (this.customer) {
        Object.assign(this.body, this.customer);
        this.body.ocountry = this.countries.find(
          (s) => s.d_code == this.customer.country_code
        );
        this.body.status = this.statuses.find(
          (s) => s.id == this.body.is_active
        );
        this.body.currency = this.currencies.find(
          (s) => s.id == this.customer.currency_id
        );
        if (!this.body.shipping_address) {
          this.body.shipping_address = this.emptyAddress;
        }
        if (!this.body.billing_address) {
          this.body.billing_address = this.emptyAddress;
        }
      }
    },
  },
  computed: {
    customer_sources() {
      return this.$store.getters["global/customer_sources"];
    },
    currencies() {
      return this.$store.getters["global/currencies"];
    },
    countries() {
      return this.$store.getters["global/countries"];
    },
    industries() {
      return this.$store.getters["global/industries"];
    },
    emptyAddress() {
      return {
        address: "",
        post_code: "",
        country: {},
        city: null,
        region: {},
      };
    },
  },
  mounted() {
    this.$refs["customer-create-modal"].show();
  },
  created() {
    this.customer_sources.length ||
      this.$store.dispatch("global/getCustomerSources");
    this.industries.length || this.$store.dispatch("global/getIndustries");
    this.countries.length || this.$store.dispatch("global/getCountries");
    this.currencies.length || this.$store.dispatch("global/getCurrencies");
    this.body.status = this.statuses.filter((s) => s.name === "Active");
    this.formatData();
  },
};
</script>
