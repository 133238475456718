var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('delete-modal',{ref:"delete",attrs:{"showDelete":_vm.showDelete,"name":_vm.showDeleteName},on:{"trigger":_vm.deleteState,"cancel":_vm.cancelDelete,"deleteAction":_vm.deleteItem}}),_c('div',{staticClass:"table-wrapper"},[_c('vuetable',{ref:"vuetable",attrs:{"api-url":_vm.api,"http-options":{
        headers: {
          Authorization: 'Bearer ' + _vm.$auth.token(),
          xorg: _vm.getOrganization.organization_id(),
        },
      },"fields":_vm.columns,"data-path":"data","pagination-path":"","no-data-template":_vm.rowCount === null ? 'Loading data' : 'No Data Available'},on:{"vuetable:loaded":_vm.loadTable,"vuetable:pagination-data":_vm.onPaginationData},scopedSlots:_vm._u([{key:"id",fn:function(props){return [_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Goto Project","to":{
            name: 'projects-show-general',
            params: { id: props.rowData.id },
          }}},[_vm._v(_vm._s(props.rowData.name)+" ")])]}},{key:"actions",fn:function(ref){
          var rowData = ref.rowData;
return [_c('span',{attrs:{"id":"delete"},on:{"click":function($event){return _vm.deleteState(rowData.id)}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"20","fill":"#72809D","viewBox":"0 0 30 30"}},[_c('path',{attrs:{"d":"M9 13v6c0 .552-.448 1-1 1s-1-.448-1-1v-6c0-.552.448-1 1-1s1 .448 1 1zm7-1c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm-4 0c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm4.333-8.623c-.882-.184-1.373-1.409-1.189-2.291l-5.203-1.086c-.184.883-1.123 1.81-2.004 1.625l-5.528-1.099-.409 1.958 19.591 4.099.409-1.958-5.667-1.248zm4.667 4.623v16h-18v-16h18zm-2 14v-12h-14v12h14z"}})])])]}}])}),_c('vuetable-pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.paginationData.total),expression:"paginationData.total"}],ref:"pagination",attrs:{"css":_vm.table_css.pagination}}),(_vm.rowCount <= 0 || _vm.rowCount === null)?_c('div',{staticClass:"text-center"},[_vm._t("empty")],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }