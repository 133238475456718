import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const milestones = {
    namespaced: true,
    state: {
        milestones: []
    },
    actions,
    getters,
    mutations
}