<template>
  <div>
    <transition name="slide-fade">
      <div class="filters-form" v-show="visibleFilter">
        <form class="d-form" @submit.prevent="save">
          <div class="row mt-3">
            <div class="col-sm">
              <h6 class="text-bold text-opacity-pale">Project Title</h6>
              <div style="position:relative">
                <input
                  type="text"
                  v-model="params['name']"
                  @input="setFilters('name')"
                  name="name"
                  class="form-control auth-input d-input"
                  placeholder="Enter project name"
                >
                <span class="close-icon" v-if="params && params.name">
                  <i class="zmdi zmdi-close" @click="params['name']='';setFilters('name')"></i>
                </span>
              </div>
            </div>
            <div class="col-sm">
              <h6 class="text-bold text-opacity-pale">Customer</h6>
              <div style="position:relative">
                <multiselect
                  @select="setFilters('customer_id')"
                  v-model="params['customer_id']"
                  name="customer"
                  class="no-caret-select"
                  :custom-label="opt => customers.find(x => x.id == opt).name"
                  :options="customers.map(type=>type.id)"
                  :show-labels="false"
                >
                  <template slot="caret">
                    <span class="close-icon" v-if="params && params.customer_id">
                      <i
                        class="zmdi zmdi-close"
                        @click="params['customer_id']='';setFilters('customer_id')"
                      ></i>
                    </span>
                    <div v-else>
                      <div class="multiselect__select">
                        <i class="zmdi zmdi-chevron-down pt-1" style="font-size: 20px"></i>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
            <div class="col-sm">
              <h6 class="text-bold text-opacity-pale">Status</h6>
              <div style="position:relative">
                <multiselect
                  @select="setFilters('project_status_id')"
                  v-model="params['project_status_id']"
                  name="status"
                  class="no-caret-select"
                  :custom-label="opt => statuses.find(x => x.id == opt).name"
                  :options="statuses.map(type=>type.id)"
                  :show-labels="false"
                >
                  <template slot="caret">
                    <span class="close-icon" v-if="params && params.project_status_id">
                      <i
                        class="zmdi zmdi-close"
                        @click="params['project_status_id']='';setFilters('project_status_id')"
                      ></i>
                    </span>
                    <div v-else>
                      <div class="multiselect__select">
                        <i class="zmdi zmdi-chevron-down pt-1" style="font-size: 20px"></i>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
            <div class="col-sm">
              <h6 class="text-bold text-opacity-pale">Billing Types</h6>
              <div style="position:relative">
                <multiselect
                  @select="setFilters('billing_type_id')"
                  v-model="params['billing_type_id']"
                  name="billing_type_id"
                  class="no-caret-select"
                  :custom-label="opt => billingTypes.find(x => x.id == opt).name"
                  :options="billingTypes.map(type=>type.id)"
                  :show-labels="false"
                >
                  <template slot="caret">
                    <span class="close-icon" v-if="params && params.billing_type_id">
                      <i
                        class="zmdi zmdi-close"
                        @click="params['billing_type_id']='';setFilters('billing_type_id')"
                      ></i>
                    </span>
                    <div v-else>
                      <div class="multiselect__select">
                        <i class="zmdi zmdi-chevron-down pt-1" style="font-size: 20px"></i>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
            <div class="col-sm">
              <h6 class="text-bold text-opacity-pale">End date (From)</h6>
              <div style="position:relative">
                <vc-date-picker
                  :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important; height:42px !important`,placeholder: `Start From date`}"
                  v-model="params['filter_start']"
                  is-range
                  @input="setFilters('filter_start');params['range_filter']='end_date'"
                  name="end"
                />
                <span class="close-icon" v-if="params && params.filter_start">
                  <i class="zmdi zmdi-close" @click="params['filter_start']='';setFilters('filter_start')"></i>
                </span>
              </div>
            </div>
            <div class="col-sm">
              <h6 class="text-bold text-opacity-pale">To</h6>
              <div style="position:relative">
                <vc-date-picker
                  :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important; height:42px !important`,placeholder: `End To date`}"
                  v-model="params['filter_end']"
                  is-range
                  @input="setFilters('filter_end');params['range_filter']='end_date'"
                  name="end"
                />
                <span class="close-icon" v-if="params && params.filter_end">
                  <i class="zmdi zmdi-close" @click="params['filter_end']='';setFilters('filter_end')"></i>
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["params", "customers", "statuses", "billingTypes", "visibleFilter"],
  data() {
    return {
      range: {
        start: new Date(2020, 0, 1),
        end: new Date(2020, 0, 5)
      }
    };
  },
  methods: {
    setFilters(name) {
      this.$emit("setFilters", this.params, name);
    },
    removeFilter(namee) {
      this.$emit("removeFilter", name);
    }
  }
};
</script>
