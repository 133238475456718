import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const payments = {
    namespaced: true,
    state: {
        payment_methods: [],
        all_payment_methods: [],
        single_payment_method: {}
    },
    actions,
    getters,
    mutations
}