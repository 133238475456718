<template>
  <b-breadcrumb :items="items"></b-breadcrumb>
</template>

<script>
export default {
  props: ["items"],
  data() {
    return {};
  }
};
</script>