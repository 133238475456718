<template>
  <div class="home" :class="{blur:!loaded}">
    <delete-modal
      @trigger="deleteProjectState"
      @cancel="cancelDelete"
      @deleteAction="deleteProject"
      :showDelete="showDelete"
      :name="showDeleteName"
      ref="delete"
    ></delete-modal>
    <collaborators-modal
      @cancel="hideCollaborators"
      :collaborators="collaborators"
      :showCollaboratorsModal= showModal
      ref="delete"
    ></collaborators-modal>

    <headers class="bg-ggray pb-5">
      <div slot="breadcrumbs">
        <breadcrumbs :items="breadCrumbs"></breadcrumbs>
      </div>
      <div slot="title">
        <h2>{{project.name}}</h2>
      </div>
      <div slot="action" v-if="optional($auth.user(), 'org_role.role_id') == 1">
        <router-link :to="{name:'projects-edit'}">
          <button class="btn btn-ds btn-outline pl-3 pr-3">
            <span class="pr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#72809D"
                viewBox="0 0 30 30"
              >
                <path
                  d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"
                ></path>
              </svg>
            </span>
            Edit Project
          </button>
        </router-link>
        <button @click="deleteProjectState" class="btn  btn-ds btn-outline btn-danger-custom pl-3 pr-3 ml-2">
          Delete Project
          <span class="pl-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="#72809D"
              viewBox="0 0 30 30"
            >
              <path
                d="M9 13v6c0 .552-.448 1-1 1s-1-.448-1-1v-6c0-.552.448-1 1-1s1 .448 1 1zm7-1c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm-4 0c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm4.333-8.623c-.882-.184-1.373-1.409-1.189-2.291l-5.203-1.086c-.184.883-1.123 1.81-2.004 1.625l-5.528-1.099-.409 1.958 19.591 4.099.409-1.958-5.667-1.248zm4.667 4.623v16h-18v-16h18zm-2 14v-12h-14v12h14z"
              ></path>
            </svg>
          </span>
        </button>
      </div>
    </headers>
    <tab-layout :project="project" :triggerModal="showCollabModal">
      <div slot="content">
        <router-view></router-view>
      </div>
    </tab-layout>
  </div>
</template>

<script>
import Headers from "@/components/Layouts/Headers";
import TabLayout from "@/components/Layouts/Projects/Tab";
import Breadcrumbs from "@/components/Breadcrumbs/Index";
import DeleteModal from "@/components/Modals/Delete";
import CollaboratorsModal from "@/components/Modals/Collaborators";
import {optional} from "@/helpers/random"
export default {
  title: 'Show | Projects',
  components: { Headers, TabLayout, Breadcrumbs, DeleteModal, CollaboratorsModal },
  data() {
    return {
      showDelete: false,
      showModal: false,
      showDeleteName: "",
      loaded : false,
    };
  },
  methods: {
    optional,
    showCollabModal() {
      this.showModal = !this.showModal;
    },
    deleteProjectState() {
      this.showDelete = true;
      this.showDeleteName = "Project";
    },
    cancelDelete() {
      this.showDelete = false;
    },
    deleteProject(s) {
      this.$store
        .dispatch("projects/delete", this.$route.params.id)
        .then(s => {
          this.$router.push({ name: "projects-index" });
          this.$toast.success("🚮 Project Successfully Deleted", {
            position: "top-right",
            duration: 3000
          });
        });
    },
    hideCollaborators() {
      this.showModal = false;
    },
    
  },
  computed: {
    project() {
      return this.$store.getters["projects/project"];
    },

    collaborators() {
      return this.$store.getters["projects/collaborators"];
    },
    
    breadCrumbs() {
      return [
        {
          text: "Home",
          to: { name: "home" }
        },
        {
          text: "All Projects",
          to: { name: "projects-index" }
        },
        {
          text: this.project.name,
          to: { name: "projects-show-general", params: { id: this.$route.params.id} }
        }
      ];
    }
  },
  created() {
    Promise.all([
      this.$store.dispatch("projects/getSingle", this.$route.params.id),
      this.$store.dispatch("projects/getCollaborators", this.$route.params.id)])
    .finally(() => {
      this.loaded = true;
    })
  }
};
</script>
