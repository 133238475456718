export default {
    SET_REPORTS(state, array) {
        state.reports = array;
    },
    SET_TABLES(state, array) {
        state.tables = array;
    },
    SET_CACHED(state, array) {
        state.cached = array;
    },
}
