export default {
    SET_USER_PAYMENT_METHODS(state, array) {
        state.payment_methods = array;
    },
    SET_PAYMENT_METHODS(state, array) {
        state.all_payment_methods = array;
    },
    SET_SINGLE_PAYMENT_METHOD(state, array) {
        state.single_payment_method = array;
    }
}