<template>
  <div class="container-fluid tab-wrapper">
    <div
      class="row pl-3 pr-4"
    >
      <div class="col-lg-7 float-left">
        <ul class="tabs">
          <router-link :to="{name: 'projects-show-general'}">
            <li>GENERAL</li>
          </router-link>
          <router-link :to="{name: 'projects-show-finance'}" v-if="optional($auth.user(), 'org_role.role_id') === 1" >
            <li>PROJECT FINANCE</li>
          </router-link>
          <router-link :to="{name: 'projects-show-collaborators'}">
            <li>COLLABORATORS</li>
          </router-link>
          <router-link
            v-if="project.billing_type_id == 3|| optional(project, 'milestones.data', []).length > 0"
            :to="{name: 'projects-show-milestones'}"
          >
            <li>MILESTONE TIMELINE</li>
          </router-link>
          <router-link  :to="{name: 'projects-show-tasks'}">
            <li>TASKS LOG</li>
          </router-link>
          <router-link  :to="{name: 'projects-show-notes'}">
            <li>NOTES</li>
          </router-link>
        </ul>
      </div>

      <div class="col-lg-5 float-right text-right pr-3">
        <ul class="d-flex flex-row justify-content-end align-items-center h-100">
          <li :key="index" v-for="(collaborator, index) in firstThreeCollaborators">
            <div
              class="collab-users mr-1"
              v-b-tooltip.hover
              :title="`${collaborator.first_name} ${collaborator.last_name}`"
            >{{ `${collaborator.first_name.charAt(0)}${collaborator.last_name.charAt(0)}`}}</div>
          </li>
          <li v-if="this.collaborators.length - 3 > 0">
            <div class="collab-users mr-1">{{this.collaborators.length - 3}}</div>
          </li>
          <li class="ml-2">
            <h6
              @click="triggerModal"
              class="pr-4 text-bold"
              style="color:#3f92cc; cursor:pointer"
            >
              <span class="pr-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <path
                    fill="#3f92cc"
                    d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 4c-.552 0-1 .448-1 1v2H5c-.552 0-1 .448-1 1s.448 1 1 1h2v2c0 .552.448 1 1 1s1-.448 1-1V9h2c.552 0 1-.448 1-1s-.448-1-1-1H9V5c0-.552-.448-1-1-1z"
                  ></path>
                </svg>
              </span>
              Invite
            </h6>
          </li>
        </ul>
      </div>
    </div>
    <div class="pl-3 pr-4 pt-5">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import {optional} from "@/helpers/random";

export default {
  props: {
    triggerModal: {
      type: Function,
      require: true,
    },
    project: {
      type: Object,
    }
  },
  data() {
    return {
      showCollaboratorsModal: false,
      showDeleteName: ""
    };
  },
  computed: {
    collaborators() {
      return this.$store.getters["projects/collaborators"];
    },
    firstThreeCollaborators() {
      return this.collaborators.slice(0, 3);
    }
  },
  methods: {
    optional,
    hideCollaborators() {
      this.showCollaboratorsModal = false;
    }
  },
  mounted() {
    this.$store.dispatch("projects/getCollaborators", this.$route.params.id);
  }
};
</script>
