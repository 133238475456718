<template>
  <div>
    <task-modal v-if="project.id" :availableProject="project" :hasProject="true"></task-modal>
  </div>
</template>
<script>
import TaskModal from "@/components/Modals/Tasks";
export default {
  components: { TaskModal },
  data() {
    return {
    };
  },
  computed: {
    project() {
      return this.$store.getters["projects/project"];
    }
  },
  created() {
      this.$store.dispatch("projects/getSingle", this.$route.params.id);
  }
};
</script>
