<template>
  <div class="col-sm-12 col-lg-9 pb-5 ml-0 pl-0">
    <div class="card-content_wrapper" :class="{blur:!loaded}">
      <div class="card-caption d-flex justify-content-between pt-3 pb-2 pl-4">
        <div>
          <h6 class="text-bold text-opacity-pale">Basic Information</h6>
        </div>
        <div class="mr-3">
          <router-link :to="{name: 'profile-edit'}">
            <button class="card-btn">Edit Profile</button>
          </router-link>
        </div>
      </div>
      <div class="card-description">
        <div class="row pl-4 pt-3 pr-4">
          <div class="col-sm-12 col-lg-6">
            <small class="text-opacity">First Name</small>
            <h6 class="h7 pt-1 text-opacity-pale">{{user.first_name}}</h6>
          </div>
          <div class="col-sm-12 col-lg-6">
            <small class="text-opacity">Last Name</small>
            <h6 class="h7 pt-1 text-opacity-pale">{{user.last_name}}</h6>
          </div>
        </div>
        <div class="row pl-4 pt-3 pr-4">
          <div class="col-sm-12 col-lg-6">
            <small class="text-opacity">Email Address</small>
            <h6 class="h7 pt-1 text-opacity-pale">{{user.email}}</h6>
          </div>
          <div class="col-sm-12 col-lg-6">
            <small class="text-opacity">Telephone</small>
            <h6 class="h7 pt-1 text-opacity-pale">{{user.full_phone_number}}</h6>
          </div>
        </div>
        <div class="row pl-4 pt-3 pr-4">
          <div class="col-sm-12 col-lg-6">
            <small class="text-opacity">Country</small>
            <h6 class="h7 pt-1 text-opacity-pale">{{user.country ? user.country.name : 'N/A'}}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toLocal } from "@/helpers/date";
export default {
  data() {
    return {
      user: {
        organization: {}
      },
      loaded: false
    };
  },
  methods: {
    toLocal
  },
  computed: {
    breadCrumbs() {
      return [
        {
          text: "Home",
          to: { name: "home" }
        },
        {
          text: "Organization",
          to: { name: "users-index" }
        },
        {
          text:
            this.user && this.user.organization && this.user.organization.name,
          to: ""
        }
      ];
    }
  },
  created() {
    Promise.all([
      this.$store.getters["global/countries"].length || this.$store.dispatch("global/getCountries"),
    ]).finally(() => {
      this.user = JSON.parse(JSON.stringify(this.$auth.user()));
      this.user.country = this.$store.getters["global/countries"].find(
        s => s.id == this.user.country_id
      );
      this.loaded = true;
    });
  }
};
</script>
