<template>
    <div>
        <opportunities-modal :opportunity="opportunity" v-if="opportunity.id"></opportunities-modal>
    </div>
</template>
<script>
    import OpportunitiesModal from "@/components/Modals/Opportunities";
    export default {
        title: 'Edit | Opportunities',
        components: {OpportunitiesModal},
        data() {
            return {};
        },
        computed: {
            opportunity() {
                return this.$store.getters['projects/opportunity'];
            }
        },
        created() {
            this.$store.dispatch("projects/getOpportunity", this.$route.params.id);
        }
    };
</script>
