export default {
    payroll: state => state.payroll,
    payrolls: state => state.payrolls,
    employees: state => state.employees,
    payroll_data: state => {
        let data = localStorage.getItem('payroll_data');
        data = data ? JSON.parse(data) : [];
        return data || state.payroll_data
    },
    transactions: state => state.transactions,
}
