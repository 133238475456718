<template>
  <div
    class="card-content_wrapper"
    :class="{ blur: !loaded }"
    v-if="optional($auth.user(), 'org_role.role_id') === 1"
  >
    <resource-modal
      :showModal="showResource"
      :resource="resourceData"
      :isSavingResource="isSavingResource"
      @close="close"
      @addNewResource="addNewResource"
      @deleteResource="deleteResource"
      @saveResource="saveResource"
    ></resource-modal>
    <div class="card-caption d-flex justify-content-between pt-3 pb-2 pl-4">
      <div>
        <h6 class="text-bold text-opacity-pale">Resources</h6>
      </div>
      <div class="mr-3">
        <router-link :to="{ name: 'organization-edit' }">
          <button class="card-btn">Edit Organization</button>
        </router-link>
      </div>
    </div>
    <div class="card-description">
      <div class="row pl-4 pt-3 pr-4">
        <div
          class="col-sm-12 mb-2"
          v-for="(resource, index) in resources"
          :key="index"
        >
          <div class="cr p-5">
            <h6 class="h7 text-bold text-opacity-pale">
              {{ resource.data.name }}
              <sup>
                <button
                  class="ml-1 mt-2"
                  @click="editResource(resource)"
                  type="button"
                  style="
                    height: 25px;
                    width: 25px;
                    border-radius: 25px;
                    background: #f5f5f5;
                    border: none;
                    font-size: 15px;
                  "
                >
                  <i class="zmdi zmdi-edit"></i></button
              ></sup>
            </h6>
            <div class="mt-3">
              <button
                v-for="(v, indexx) in resource.data.value"
                :key="indexx"
                class="btn-text mr-2 mb-2"
                style="
                  font-weight: bold;
                  font-size: 14px;
                  background: #daf1ff;
                  cursor: pointer;
                  border: none;
                "
              >
                {{ v.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr {
  border: 1px dashed #e4e4e4;
  border-radius: 10px;
}
</style>
<script>
import ResourceModal from "@/components/Modals/Resource.vue";
import {optional} from "@/helpers/random"

export default {
  data() {
    return {
      loaded: true,
      resources: [
        {
          name: "task_statuses",
          data: { value: [], name: "Tasks / Statuses" },
          path: "task_statuses",
          path2: 'task_statuses/move'
        },
        {
          name: "industry_sources",
          data: { value: [], name: "Customers / Industry Sources" },
          path: "customer_sources",
        },
        {
          name: "project",
          data: { value: [], name: "Projects / Statuses" },
          path: "project_statuses",
        },
      ],
      resourceData: {},
      showResource: false,
      isSavingResource: false,
    };
  },
  components: { ResourceModal },
  methods: {
    optional,
    editResource(data) {
      this.showResource = true;
      this.resourceData = data;
    },
    close() {
      // this.resourceData = {};
      this.showResource = false;
    },
    addNewResource(d) {
      let selected = this.resources.find((s) => s.name == d);
      selected.data.value.push({});
    },
    deleteResource(d) {
      let url = this.$baseApi;
      let selected = this.resources.find((s) => s.name == d.key);
      // console.log(d);
      selected.data.value.splice(d.index, 1);

      //if it has an id,  delete from server
      if (d.value) {
        url = url + "/" + selected.path + `/${d.value}`;
        this.$http
          .delete(url)
          .then((s) => {
            this.refreshResources();
            this.$toast.success("Resource successfully deleted", {
              position: "top-right",
              duration: 3000,
            });
          })
          .catch((err) => {
            this.$toast.error("Something went wrong, please try again", {
              position: "top-right",
              duration: 3000,
            });
          });
      }
    },
    refreshResources() {
      this.$store.dispatch("global/getProjectStatuses").then((data) => {
        this.resources.find((s) => {
          if (s.name == "project") {
            s.data.value = data;
          }
        });
      }),
        this.$store.dispatch("global/getTaskStatuses").then((data) => {
          this.resources.find((s) => {
            if (s.name == "task_statuses") {
              s.data.value = data;
            }
          });
        }),
        this.$store.dispatch("global/getCustomerSources").then((data) => {
          this.resources.find((s) => {
            if (s.name == "industry_sources") {
              s.data.value = data;
            }
          });
        });
    },
    saveResource(d) {
      let url = this.$baseApi;
      let selected = this.resources.find((s) => s.name == d.key);
      this.isSavingResource = true;

      let data = {
        id: d.id,
        name: d.value,
        order:
          d.order && d.order.moved && d.order.moved.newIndex
            ? d.order.moved.newIndex
            : d.order,
      };

     
      if (d.id) {
        url = url + "/" + selected.path + `/${d.id}`;
        this.$http
          .put(url, data)
          .then((s) => {
            this.refreshResources();
            this.$toast.success("Resource successfully updated", {
              position: "top-right",
              duration: 3000,
            });
          })
          .catch((err) => {
            this.$toast.error("Something went wrong, please try again", {
              position: "top-right",
              duration: 3000,
            });
          })
          .finally((s) => {
            this.isSavingResource = false;
          });
      } else {
        url = url + "/" + (d.move ? selected.path2 : selected.path);
      
        this.$http.post(url, data)
          .then((s) => {
            this.refreshResources();
            this.$toast.success(`Resource successfully ${d.move ? 'updated' : 'added'}`, {
              position: "top-right",
              duration: 3000,
            });
          })
          .catch((err) => {
            this.$toast.error("Something went wrong, please try again", {
              position: "top-right",
              duration: 3000,
            });
          })
          .finally(() => {
            this.isSavingResource = false;
          });
      }
    },
  },
  created() {
    Promise.all([this.refreshResources()]).finally(() => {
      this.loaded = true;
    });
  },
};
</script>