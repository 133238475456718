<template>
  <div :class="{blur:!loaded}">
    <delete-comment
      :showDelete="isAboutToDeleteComment"
      @deleteAction="deleteAction"
      @cancel="cancel"
      :name="'Comment'"
    ></delete-comment>
    <div class="content-header">
      <div class="comment-box">
        <div class="box-wrapper mb-3">
          <div class="pt-2 pb-2">
            <small class="text-opacity">
              <i v-show="reply" class="zmdi zmdi-mail-reply pr-2"></i>
              {{isCurrentlyReplying ? `Replying to @${isReplyingTo}` : isCurrentlyEditing ? 'Editing Comment' : '- New Comment'}}
            </small>
            <small
              class="text-right float-right"
              @click="showComment = !showComment"
              style="font-size:20px"
            >
              <button class="comment-box--toggle">
                <i class="zmdi zmdi-chevron-down"></i>
              </button>
            </small>
          </div>
          <transition name="slide-fade">
            <div
              v-show="!showComment"
              class="comment-dummy p-2 pl-3"
              @click="showCommentBox"
            >Enter comment here</div>
          </transition>
          <transition name="slide-fade">
            <div class="comment" v-show="showComment">
              <form @submit.prevent="submit(data)">
                <quill-editor
                  ref="myQuillEditor"
                  class="mt-1"
                  v-model="text"
                  placeholder="Enter Message"
                  :options="customToolbar"
                />
                <div class="float-right">
                  <button
                    type="button"
                    @click="cancelShowBox"
                    :disabled="loading ? true: false"
                    class="btn pl-4 pr-4 btn-default"
                  >
                    <div class="text-main">Cancel</div>
                  </button>
                  <button
                    type="submit"
                    @click="submit"
                    :disabled="loading ? true: false"
                    class="btn pl-4 pr-4 btn-primary"
                  >
                    <div v-if="!loading">Save</div>
                    <div v-else class="spinner-grow spinner-grow-sm text-light" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </transition>
        </div>
      </div>
      <div class="comments">
        <div v-for="(data, index) in filteredComments(null)" :key="index" class="comment mb-3">
          <div class="com-comp">
            <div>
              <div class="text-center comment-img mt-1">
                <span
                  style="display:flex; justify-content:center"
                >{{data.user.first_name.charAt(0)}}{{data.user.last_name.charAt(0)}}</span>
              </div>
            </div>
            <div class="com-desc">
              <div class="com-desc--author">
                <h6
                  class="text-opacity h6 text-bold"
                >{{`${data.user.first_name} ${data.user.last_name}`}}</h6>
                <h6>
                  <small class="text-opacity">
                    <i class="zmdi zmdi-time pr-1"></i>
                    {{fromNow(data.created_at)}}
                  </small>
                </h6>
              </div>
              
              <span v-html="linkify(data.comment)"></span>
              <div class="text-right pb-2">
                <small
                  class="comment-reply-btn"
                  @click="setCommentReply(data.id,data.user.last_name)"
                >
                  <i class="zmdi zmdi-mail-reply pr-2"></i> Reply
                </small>
              </div>
            </div>
            <div
              class="com-dropdown pt-2 pl-2"
              @mouseenter="toggleHover(data.id)"
              @mouseleave="toggleHover('')"
            >
              <button @click="toggleHover(data.id)" class>
                <i class="zmdi zmdi-more-vert" style="font-size:20px"></i>
              </button>

              <ul class="com-dropdown__list pt-2 pb-3" v-show="data.id === activeHover">
                <li
                  class="pl-4 pr-5 pb-2"
                  @click="setCommentReply(data.id,data.user.last_name)"
                >Reply</li>
                <li class="pl-4 pr-5 pb-2" @click="editComment(data)" v-if="$auth.user().id === data.user.id">Edit</li>
                <li
                  v-if="$auth.user().id === data.user.id"
                  class="pl-4 pr-5 pb-2"
                  @click="toggleDelete(data.id)"
                >Delete</li>
              </ul>
            </div>
          </div>

          <div
            class="innner-wrapper"
            :key="index"
            v-for="(data, index) in filteredComments(data.id)"
          >
            <div class="comment ml-5 comment-inner mt-2">
              <div class="com-comp">
                <div>
                  <div class="text-center comment-img mt-1">
                    <sup class="comment-inner--icon">
                      <button class>
                        <i class="zmdi zmdi-mail-reply" style="font-size:12px"></i>
                      </button>
                    </sup>
                    <span
                      style="display:flex;justify-content:center"
                    >{{data.user.first_name.charAt(0)}}{{data.user.last_name.charAt(0)}}</span>
                  </div>
                </div>
                <div class="com-desc">
                  <div class="com-desc--author">
                    <h6
                      class="text-opacity h6 text-bold"
                    >{{`${data.user.first_name} ${data.user.last_name}`}}</h6>
                    <h6>
                      <small class="text-opacity">
                        <i class="zmdi zmdi-time pr-1"></i>
                        {{fromNow(data.created_at)}}
                      </small>
                    </h6>
                  </div>
                 
                  <span v-html="linkify(data.comment)"></span>
                </div>
                <div
                  class="com-dropdown pt-2 pl-2"
                  @mouseenter="toggleHover(data.id)"
                  @mouseleave="toggleHover('')"
                >
                  <button @click="toggleHover(data.id)" class>
                    <i class="zmdi zmdi-more-vert" style="font-size:20px"></i>
                  </button>

                  <ul class="com-dropdown__list pt-2 pb-3" v-if="data.id === activeHover && $auth.user().id === data.user.id">
                    <!-- <li class="pl-4 pr-5 pb-2">Reply</li> -->
                    <li class="pl-4 pr-5 pb-2" @click="editComment(data)">Edit</li>
                    <li
                      
                      class="pl-4 pr-5 pb-2"
                      @click="toggleDelete(data.id)"
                    >Delete</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import DeleteComment from "@/components/Modals/Delete";
import Literals from "@/helpers/dashboard/literals";
import { fromNow } from "@/helpers/date";
import { customToolbar } from "@/helpers/editor";
import moment from "moment";
import _ from "lodash";

export default {
  components: { DeleteComment },
  data() {
    return {
      loaded: false,
      text: "",
      isReplyingTo: "",
      showComment: false,
      reply: false,
      isCurrentlyReplying: "",
      isCurrentlyEditing: "",
      isCurrentlyDeleting: "",
      isAboutToDeleteComment: false,
      loading: false,
      activeHover: "",
      comment: "",
      customToolbar: customToolbar(this)
    };
  },
  methods: {
    fromNow,
    moment,
    showCommentBox() {
      this.text = " ";
      this.showComment = true;
    },
    setCommentReply(id, name) {
      this.reply = true;
      this.isCurrentlyReplying = id;
      this.isCurrentlyEditing = "";
      this.isReplyingTo = name;
      this.showComment = true;
      this.text = " ";
    },
    editComment(data) {
      this.showComment = true;
      this.isCurrentlyEditing = data.id;
      this.isCurrentlyReplying = "";
      this.text = data.comment;
      this.$refs.myQuillEditor.focus();
    },
    toggleDelete(id) {
      this.isAboutToDeleteComment = !this.isAboutToDeleteComment;
      this.isCurrentlyDeleting = id;
      this.isCurrentlyReplying = "";
      this.isCurrentlyEditing = "";
    },
    deleteAction() {
      this.$store
        .dispatch("tasks/deleteTaskComment", this.isCurrentlyDeleting)
        .then(s => {
          this.$toast.success("Your Comment was successfully  deleted!", {
            position: "top-right",
            duration: 3000
          });
          this.$store.dispatch("tasks/getComments", this.$route.params.tid);
          this.isAboutToDeleteComment = false;
        })
        .catch(error => {
          this.$toast.error("Failed to save comment, Try again :" + error, {
            position: "top-right",
            duration: 3000
          });
        });
    },
    toggleHover(id) {
      this.activeHover = this.activeHover == id ? "" : id;
    },
    closeCommentReply() {
      this.reply = false;
      this.isCurrentlyReplying = "";
      this.isCurrentlyEditing = "";
    },
    cancelShowBox() {
      this.showComment = false;
      this.isCurrentlyReplying = "";
      this.isCurrentlyEditing = "";
    },
    linkify(inputText) {

      let doc = document.createElement('div');
      doc.innerHTML = inputText;
      doc.querySelectorAll('a').forEach((a) => {

          let url = window.location.href;
          url = url.substring(0, url.lastIndexOf("/"));
          let link = a.href.replace(`${url}/`, "");
          if (!(link.includes('https') && link.includes('http'))) {
            a.href = `https://${link}`
          }

      });

      return doc.innerHTML;
    },
    getMentioned(str) {
      let tmpStr  = str.matchAll(`data-id="(.*?)"`);
      let ids = [];
      for(let i of tmpStr) {
        ids.push(i[1]);
      }

      return ids;
    },
    submit() {
      let payload = {
        comment: this.text,
        task_id: this.$route.params.tid,
        mentioned : this.getMentioned(this.text)
      };
      if (this.isCurrentlyEditing) {
        Object.assign(payload, { id: this.isCurrentlyEditing });
      }
      if (this.isCurrentlyReplying) {
        Object.assign(payload, { parent_comment_id: this.isCurrentlyReplying });
      }
      this.loading = true;
      this.$store
        .dispatch("tasks/createUpdateComment", payload)
        .then(comment => {
          this.$toast.success(
            this.isCurrentlyEditing
              ? Literals.COMMENT_UPDATE_SUCCESS
              : Literals.COMMENT_CREATE_SUCCESS,
            {
              position: "top-right",
              duration: 3000
            }
          );
          this.isCurrentlyReplying = "";
          this.isCurrentlyEditing = "";
          this.text = "";
          //appending to the state object doesn't reflect immediately
          // because we're using methods, not computed property to render commends
          this.$store.dispatch("tasks/getComments", this.$route.params.tid);
        })
        .catch(error => {
          this.$toast.error("Failed to save comment, Try again :" + error, {
            position: "top-right",
            duration: 3000
          });
        })
        .then(() => {
          this.loading = false;
          this.showComment = false;
          this.text = "";
          if (this.isCurrentlyReplying) {
            this.reply = false;
            this.closeCommentReply();
          }
        });
    },
    filteredComments(id) {
      return this.comments
        .filter(data => data.parent_comment_id === id)
        .reverse();
    },
    cancel() {
      this.isAboutToDeleteComment = false;
    }
  },
  computed: {
    comments() {
      return this.$store.getters["tasks/comments"];
    },
    users() {
      return this.$store.getters["employees/users"];
    }
  },
  created() {
    this.$store.dispatch("tasks/getComments", this.$route.params.tid).then(() => {
      this.loaded = true
    });
  }
};
</script>
