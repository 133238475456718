<template>
  <div :class="{ blur: !loaded }">
    <delete-modal
      @trigger="setDeleteState"
      @cancel="cancelDelete"
      @deleteAction="deleteItem"
      :showDelete="showDelete"
      :name="showDeleteName"
      ref="delete"
    ></delete-modal>
    <div class="content-header">
      <div class="mb-5" v-show="task && task.parenttask">
        <h6 class="text-bold text-opacity-pale text-opacity">Parent Task</h6>
        <router-link
          v-if="optional(task, 'parenttask.id', null)"
          :to="{
            name: $route.name,
            params: { tid: task.parenttask.id },
          }"
        >
          <h6 class="h6 text-bold">
            {{ task.parenttask.name }}
            <i class="zmdi zmdi-arrow-right-top pl-2"></i>
          </h6>
        </router-link>
      </div>
      <h6 class="text-bold text-opacity-pale text-opacity">
        Description
        <span class="ml-2" v-show="!isEditingDescription">
          <sup class="mt-1">
            <button
              class="ml-1 mt-2"
              @click="isEditingDescription = true"
              type="button"
              style="
                height: 25px;
                width: 25px;
                border-radius: 25px;
                background: #f5f5f5;
                border: none;
                font-size: 15px;
              "
            >
              <i class="zmdi zmdi-edit"></i>
            </button>
          </sup>
        </span>
      </h6>
      <div class="d-flex flex-row" v-if="!isEditingDescription">
        <h6 v-html="linkify(task.description)"></h6>
      </div>

      <div class="d-flex flex-column editable" v-else>
        <quill-editor
          ref="myQuillEditor"
          id="o-edit"
          class="mt-1"
          style="height: 100px"
          v-model="task.description"
          @ready="_html(task.description)"
          placeholder="Enter Message"
          :options="customToolbar"
        />
        <div class="d-flex justify-content-end mt-5" @click="updateTaskInfo">
          <button class="editable-save pl-3 pr-3">
            <h6 class="h6 text-opacity-pale text-bold mb-0">Save</h6>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 mt-4">
        <h6 class="text-bold text-opacity-pale text-opacity">
          Assigned to
          <span class="ml-2">
            <sup class="mt-1">
              <button
                class="ml-1 mt-2"
                @click="switchView()"
                type="button"
                v-show="showAssigned"
                style="
                  height: 25px;
                  width: 25px;
                  border-radius: 25px;
                  background: #f5f5f5;
                  border: none;
                  font-size: 15px;
                "
              >
                <i class="zmdi zmdi-edit"></i>
              </button>
            </sup>
          </span>
        </h6>
        <multiselect
          v-if="showSelect"
          v-model="newEmployee"
          track-by="id"
          label="name"
          v-validate="'required'"
          class="no-caret-select mt-3"
          @input="changeAssignee"
          name="employee"
          :options="employees"
          :show-labels="false"
          placeholder="Reassign Task"
        >
          <template slot="caret">
            <div>
              <div class="multiselect__select d-flex align-items-center">
                <i
                  class="zmdi zmdi-chevron-down pt-1"
                  v-if="!changingAssignee"
                  style="font-size: 20px"
                ></i>
                <span
                  class="spinner-border spinner-border-sm"
                  v-else
                  role="status"
                  style="margin-top: 5px"
                  aria-hidden="true"
                ></span>
              </div>
            </div>
          </template>
        </multiselect>
        <div v-else class="d-flex flex-row mt-3 align-items-center">
          <button
            style="
              height: 35px;
              width: 35px;
              border: 2px solid #e4e4e4;
              background: #f5f5f5;
              border-radius: 35px;
              position: relative;
              cursor: pointer;
              font-size: 12px;
            "
            v-b-tooltip.hover
            :title="newEmployee.name"
          >
            {{ newEmployee.f_initial }}{{ newEmployee.l_initial }}
          </button>
          <h6 class="pl-2 text-bold text-opacity-pale">
            {{ newEmployee ? newEmployee.name : "Unassigned" }}
            {{
              newEmployee
                ? newEmployee.id === this.$auth.user().id
                  ? "(Me)"
                  : ""
                : null
            }}
          </h6>
        </div>
      </div>
      <div class="col-sm-6 mt-4">
        <h6 class="text-bold text-opacity-pale text-opacity">
          Milestone
          <span class="ml-2">
            <sup class="mt-1">
              <button
                class="ml-1 mt-2"
                @click="viewMilestone()"
                type="button"
                v-show="showAssigned"
                style="
                  height: 25px;
                  width: 25px;
                  border-radius: 25px;
                  background: #f5f5f5;
                  border: none;
                  font-size: 15px;
                "
              >
                <i class="zmdi zmdi-edit"></i>
              </button>
            </sup>
          </span>
        </h6>
        <multiselect
          v-if="!showMilestones"
          track-by="id"
          :multiple="false"
          v-model="milestone"
          label="name"
          v-validate="'required'"
          class="no-caret-select mt-3"
          name="milestone"
          :options="milestones"
          @input="changeMilestone"
          placeholder="Choose Milestone"
          :show-labels="false"
        >
          <template slot="caret">
            <div>
              <div class="multiselect__select d-flex align-items-center">
                <i
                  class="zmdi zmdi-chevron-down pt-1"
                  v-if="!changingMilestone"
                  style="font-size: 20px"
                ></i>
                <span
                  class="spinner-border spinner-border-sm"
                  v-else
                  role="status"
                  style="margin-top: 5px"
                  aria-hidden="true"
                ></span>
              </div>
            </div>
          </template>
        </multiselect>
        <div v-else class="d-flex flex-row mt-3">
          <h6 class="text-bold text-opacity-pale">
            {{ optional(task, "milestone.name", "Unassigned") }}
          </h6>
        </div>
      </div>

      <div class="col-sm-6 mt-4">
        <h6 class="text-bold text-opacity-pale text-opacity">Manager</h6>
        <div class="d-flex flex-row align-items-center">
          <button
            style="
              height: 35px;
              width: 35px;
              border: 2px solid #e4e4e4;
              background: #f5f5f5;
              border-radius: 35px;
              position: relative;
              cursor: pointer;
              font-size: 12px;
            "
            v-b-tooltip.hover
            :title="
              optional(task, 'project.manager', null)
                ? task.project.manager.first_name +
                  ' ' +
                  task.project.manager.last_name
                : '-'
            "
          >
            {{
              optional(task, "project.manager", null)
                ? task.project.manager.first_name.charAt(0) +
                  task.project.manager.last_name.charAt(0)
                : "-"
            }}
          </button>
          <h6 class="pl-2 text-bold text-opacity-pale">
            {{
              optional(task, "project.manager", null)
                ? task.project.manager.first_name +
                  " " +
                  task.project.manager.last_name
                : "-"
            }}
          </h6>
        </div>
      </div>
      <div class="col-sm-6 mt-4">
        <h6 class="text-bold text-opacity-pale text-opacity">Created By</h6>
        <div class="d-flex flex-row align-items-center">
          <button
            style="
              height: 35px;
              width: 35px;
              border: 2px solid #e4e4e4;
              background: #f5f5f5;
              border-radius: 35px;
              position: relative;
              cursor: pointer;
              font-size: 12px;
            "
            v-b-tooltip.hover
            :title="
              optional(creator, 'user.data', null)
                ? creator.user.data.first_name +
                  ' ' +
                  creator.user.data.last_name
                : '-'
            "
          >
            {{
              optional(creator, "user.data", null)
                ? creator.user.data.first_name.charAt(0) +
                  creator.user.data.last_name.charAt(0)
                : "-"
            }}
          </button>
          <h6 class="pl-2 text-bold text-opacity-pale">
            {{
              optional(creator, "user.data", null)
                ? `${creator.user.data.first_name} ${creator.user.data.last_name}`
                : ""
            }}
            {{ task.created_by == $auth.user().id ? "(Me)" : "" }}
          </h6>
        </div>
      </div>
      <div class="col-sm-6 mt-4">
        <h6
          class="text-bold text-opacity-pale text-opacity"
          v-b-tooltip.hover="
            optional(task, 'created_at', null)
              ? toLocal(task.created_at).format('lll').toLocaleString()
              : ''
          "
        >
          Created At
        </h6>
        <h6 class="pl-2 text-bold text-opacity-pale">
          {{ toLocal(task.created_at).fromNow() }}
        </h6>
      </div>
      <div class="col-sm-6 mt-4">
        <h6
          class="text-bold text-opacity-pale text-opacity"
          v-b-tooltip.hover="
            optional(task, 'updated_at', null)
              ? toLocal(task.updated_at).format('lll').toLocaleString()
              : ''
          "
        >
          Updated At
        </h6>
        <h6 class="pl-2 text-bold text-opacity-pale">
          {{ toLocal(task.updated_at).fromNow() }}
        </h6>
      </div>
    </div>

    <div class="mt-5">
      <div class="row">
        <div class="col-sm-12 col-lg-6">
          <h6 class="text-bold text-opacity-pale text-opacity">
            Time Estimate
          </h6>
          <h6 class="text-bold text-opacity-pale">
            {{ secondsToDhms(task.estimated_hours * 3600) }}
          </h6>
        </div>
        <div class="col-sm-12 col-lg-6">
          <h6 class="text-bold text-opacity-pale text-opacity">
            Complexity
            <span class="ml-2" v-show="!isEditComplexity">
              <sup class="mt-1">
                <button
                  class="ml-1 mt-2"
                  @click="isEditComplexity = true"
                  type="button"
                  style="
                    height: 25px;
                    width: 25px;
                    border-radius: 25px;
                    background: #f5f5f5;
                    border: none;
                    font-size: 15px;
                  "
                >
                  <i class="zmdi zmdi-edit"></i>
                </button>
              </sup>
            </span>
          </h6>
          <div class="d-flex flex-row" v-if="!isEditComplexity">
            <h6 class="text-bold text-opacity-pale">
              {{ task.complexity || "N/A" }}
            </h6>
          </div>
          <div class="d-flex flex-column editable" v-else>
            <multiselect
              placeholder="Pick a value"
              @input="updateComplexity"
              :close-on-select="true"
              class="no-caret-select"
              :show-labels="false"
              v-model="complexityValue"
              :searchable="false"
              :options="complexities"
            >
              <template slot="caret">
                <div>
                  <div class="multiselect__select d-flex align-items-center">
                    <i
                      class="zmdi zmdi-chevron-down pt-1"
                      v-if="!isUpdating"
                      style="font-size: 20px"
                    ></i>
                    <span
                      class="spinner-border spinner-border-sm"
                      v-else
                      role="status"
                      style="margin-top: 5px"
                      aria-hidden="true"
                    ></span>
                  </div>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <div class="row">
        <div class="col-sm-12 col-lg-12">
          <span class="text-bold h6 text-opacity-pale text-opacity"
            >Time Logs</span
          >
          <span class="text-bold h6 float-right text-opacity-pale">
            <button
              :disabled="timerExist"
              @click="setTimeLog"
              class="btn-action pr-3 pl-2"
            >
              <span class="text-bold h7 text-opacity-pale">
                <span class="pr-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <rect width="24" height="24" fill="#FFF" rx="4"></rect>
                      <g
                        stroke="#72809D"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        transform="translate(5 3)"
                      >
                        <path
                          d="M11.233 5.641L12.656 4.219M12.375 3.938L12.938 4.5M6.75 3.938L6.75.563M8.438.563L5.063.563"
                        ></path>
                        <circle cx="6.75" cy="10.688" r="6.75"></circle>
                        <path
                          d="M6.75 6.188L6.75 6.188M6.75 6.188c-.155 0-.281.125-.281.28 0 .156.126.282.281.282.155 0 .281-.126.281-.281 0-.156-.126-.282-.281-.282M6.75 14.625c-.155 0-.281.126-.281.281 0 .156.126.281.281.281.155 0 .281-.125.281-.28 0-.156-.126-.282-.281-.282h0M2.531 10.406c-.155 0-.281.126-.281.281 0 .156.126.282.281.282.156 0 .281-.126.281-.281 0-.156-.125-.282-.28-.282h0M10.969 10.406c-.156 0-.281.126-.281.281 0 .156.125.282.28.282.156 0 .282-.126.282-.281 0-.156-.126-.282-.281-.282h0M3.767 7.425c-.114 0-.216.068-.26.173-.044.105-.02.226.06.307.11.11.289.11.399 0 .11-.11.11-.288 0-.397-.053-.053-.124-.083-.199-.083h0M9.733 13.39c-.075 0-.146.03-.199.082-.095.095-.11.244-.035.355.075.112.218.155.342.104.124-.052.194-.184.168-.315-.026-.132-.142-.226-.276-.226h0M3.767 13.39c-.114 0-.216.068-.26.173-.044.105-.02.226.06.307.072.07.175.099.272.073.098-.026.173-.102.2-.199.026-.097-.002-.2-.073-.272-.053-.053-.124-.082-.199-.082h0M9.932 7.506c-.053-.053-.124-.083-.2-.083h0c-.074 0-.145.03-.198.083-.11.11-.11.288 0 .398.11.11.288.11.398 0 .11-.11.11-.288 0-.398"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </span>
                Log Time
              </span>
            </button>
          </span>
          <div class="mt-5">
            <div class="table-wrapper" v-if="timeLogs.length > 0">
              <table
                class="
                  in-tab-table
                  vuetable
                  ui
                  blue
                  selectable
                  celled
                  stackable
                  attached
                  table
                "
              >
                <thead>
                  <tr>
                    <!-- <th>Name</th> -->
                    <th>Start Date</th>
                    <th>Hours Logged</th>
                    <th>Notes</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="index" v-for="(timeLog, index) in timeLogs">
                    <!-- <td>{{timeLog.task && timeLog.task.data && timeLog.task.data.name}}</td> -->
                    <td>
                      {{
                        toLocal(timeLog.start_time)
                          .format("lll")
                          .toLocaleString()
                      }}
                    </td>
                    <td>{{ timeLog.hours }} hours</td>
                    <td>{{ html2text(timeLog.note) }}</td>
                    <td>
                      <button
                        v-b-tooltip.hover
                        title="Edit Timelog"
                        @click="setTimeLog(timeLog)"
                        class="btn-lightgray-round"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="20"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z"
                          ></path>
                        </svg>
                      </button>

                      <button
                        v-b-tooltip.hover
                        title="Delete Timelog"
                        class="btn-lightgray-round-secondary btn-danger ml-2"
                        @click="setDeleteState('TimeLog', timeLog.id)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="20"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M9 13v6c0 .552-.448 1-1 1s-1-.448-1-1v-6c0-.552.448-1 1-1s1 .448 1 1zm7-1c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm-4 0c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm4.333-8.623c-.882-.184-1.373-1.409-1.189-2.291l-5.203-1.086c-.184.883-1.123 1.81-2.004 1.625l-5.528-1.099-.409 1.958 19.591 4.099.409-1.958-5.667-1.248zm4.667 4.623v16h-18v-16h18zm-2 14v-12h-14v12h14z"
                          ></path>
                        </svg>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h6 v-else class="text-opacity text-opacity-pale text-center">
              No time has been logged to this task yet
            </h6>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <div class="row">
        <div class="col-sm-12 col-lg-12">
          <span class="text-bold h6 text-opacity-pale text-opacity"
            >Expense Logs</span
          >
          <span class="text-bold h6 float-right text-opacity-pale">
            <button @click="setExpenseLog" class="btn-action pr-3 pl-2">
              <span class="text-bold h7 text-opacity-pale">
                <span class="pr-1">
                  <svg
                    height="24px"
                    version="1.1"
                    viewBox="0 0 24 24"
                    width="24px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      id="Work-Page"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="24-Tasks-view,-detailed-task"
                        transform="translate(-1100.000000, -24.000000)"
                      >
                        <g
                          id="Group-2-Copy-2"
                          transform="translate(1100.000000, 24.000000)"
                        >
                          <rect
                            id="Rectangle"
                            fill="#FFFFFF"
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                            rx="4"
                          ></rect>
                          <g
                            id="accounting-bills"
                            transform="translate(0.000000, 4.000000)"
                            stroke="#72809D"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          >
                            <path
                              d="M4.125,3.5 C4.33210678,3.5 4.5,3.66789322 4.5,3.875 C4.5,4.08210678 4.33210678,4.25 4.125,4.25 C3.91789322,4.25 3.75,4.08210678 3.75,3.875 C3.75,3.66789322 3.91789322,3.5 4.125,3.5"
                              id="Path"
                            ></path>
                            <path
                              d="M16.875,8.75 C17.0821068,8.75 17.25,8.91789322 17.25,9.125 C17.25,9.33210678 17.0821068,9.5 16.875,9.5 C16.6678932,9.5 16.5,9.33210678 16.5,9.125 C16.5,8.91789322 16.6678932,8.75 16.875,8.75"
                              id="Path"
                            ></path>
                            <rect
                              id="Rectangle"
                              x="0.75"
                              y="0.5"
                              width="19.5"
                              height="12"
                              rx="1.5"
                            ></rect>
                            <circle id="Oval" cx="10.5" cy="6.5" r="3"></circle>
                            <path
                              d="M23.25,5 L23.25,14 C23.25,14.8284271 22.5784271,15.5 21.75,15.5 L5.25,15.5"
                              id="Path"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
                Log expense
              </span>
            </button>
          </span>
          <div class="mt-5 table-wrapper">
            <table
              v-if="expenses.length > 0"
              class="
                in-tab-table
                vuetable
                ui
                blue
                selectable
                celled
                stackable
                attached
                table
              "
            >
              <thead>
                <tr>
                  <th>Subject</th>
                  <th>Amount</th>
                  <th>Category</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="index" v-for="(expense, index) in expenses">
                  <td>{{ expense.subject }}</td>
                  <td>
                    {{
                      `${expense.amount} ${
                        expense.currency && expense.currency.symbol
                      }`
                    }}
                  </td>
                  <td>{{ expense.category && expense.category.name }}</td>
                  <td>{{ html2text(expense.description) }}</td>
                  <td>
                    <button
                      v-b-tooltip.hover
                      title="Edit Expense"
                      @click="setExpenseLog(expense)"
                      class="btn-lightgray-round"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="20"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z"
                        ></path>
                      </svg>
                    </button>

                    <button
                      v-b-tooltip.hover
                      title="Delete Expense"
                      class="btn-lightgray-round-secondary btn-danger ml-2"
                      @click="setDeleteState('Expense', expense.id)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="20"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M9 13v6c0 .552-.448 1-1 1s-1-.448-1-1v-6c0-.552.448-1 1-1s1 .448 1 1zm7-1c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm-4 0c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1zm4.333-8.623c-.882-.184-1.373-1.409-1.189-2.291l-5.203-1.086c-.184.883-1.123 1.81-2.004 1.625l-5.528-1.099-.409 1.958 19.591 4.099.409-1.958-5.667-1.248zm4.667 4.623v16h-18v-16h18zm-2 14v-12h-14v12h14z"
                        ></path>
                      </svg>
                    </button>

                    <button
                      v-if="optional($auth.user(), 'org_role.role_id') == 1"
                      v-b-tooltip.hover
                      :disabled="loading"
                      title="Approve Expense"
                      class="btn-lightgray-round-secondary btn-danger ml-2"
                      @click="approveExpense(expense.id)"
                    >
                      <i class="zmdi zmdi-check"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <h6 v-else class="text-opacity text-opacity-pale text-center">
              No expense has been logged to this task yet
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.editable {
  position: relative;
  .editable-field {
    font-size: 1.25rem;
    border: 2px solid transparent;
    outline: none;
    resize: none;
    height: min-content;

    &:focus {
      outline: none;
    }
    &:hover {
      border: 2px solid #f5f5f5;
    }
  }
  .editable-save {
    visibility: hidden;
    background: #e4e4e4;
    border: none;
    height: 35px;
    border-radius: 10px;
    position: absolute;
    bottom: -35px;
    z-index: 5;

    &:hover {
      background: #2c3f58;
      color: white !important;
      h6 {
        color: white !important;
      }
    }
  }
  &:focus-within {
    .editable-save {
      visibility: visible;
    }
  }
}
.assignee-card {
  background: #fafbfd;
  font-size: 14px;
  font-family: "sansMedium";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #254050;

  .as-card-img {
    height: 30px;
    width: 30px;
    border-radius: 30px;
  }
}
.h6-e {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #254050;
  font-family: "sansMedium";
}
button {
  &.btn-icon {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    border: 0;
    &:hover {
      background: #f7f7f7;
    }
  }
  &.btn-action {
    height: 40px;
    border-radius: 5px;
    border: 0px;
    &:hover {
      background: #f7f7f7;
    }
  }
}
</style>
<script>
import { secondsToDhms } from "@/helpers/duration";
import { toLocal } from "@/helpers/date";
import DeleteModal from "@/components/Modals/Delete";
import { optional, html2text, timerExist, linkify } from "@/helpers/random";
import { quillEditor } from "vue-quill-editor";
import Literals from "@/helpers/dashboard/literals";

export default {
  data() {
    return {
      Literals,
      changingAssignee: false,
      changingMilestone: false,
      isEditingDescription: false,
      isEditComplexity: false,
      isUpdating: false,
      showSelect: false,
      showAssigned: true,
      showMilestones: true,
      newEmployee: {},
      milestone: {},
      showDelete: false,
      showDeleteName: "",
      selectedDeleteId: "",
      loaded: false,
      loading: false,
      customToolbar: {
        modules: {
          toolbar: [
            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            ["bold", "italic", "underline", "strike", "image"], // toggled buttons
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }], // text direction
            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            ["clean"], // remove formatting button
          ],
        },
      },
      description: "",
      employee: {},
      complexityValue: "",
      complexities: [1, 2, 3, 4, 5, 6, 7],
    };
  },
  components: { DeleteModal, quillEditor },
  methods: {
    secondsToDhms,
    toLocal,
    html2text,
    linkify,
    optional,
    switchView() {
      this.showSelect = !this.showSelect;
      this.showAssigned = !this.showAssigned;
    },
    viewMilestone() {
      this.showMilestones = !this.showMilestones;
    },
    refreshTasks() {
      let filters = this.$route.params.filterBy;
      let filteredParams = this.$_.omitBy(filters, _.isNil);
      let params = new URLSearchParams(filteredParams).toString();
      this.$store.dispatch("tasks/index", params);
    },
    updateTaskInfo() {
      let data = {
        id: this.task.id,
        name: this.task.name,
        description: this.task.description,
      };
      this.$store.dispatch("tasks/createUpdate", data).then((s) => {
        this.$toast.success(Literals.TASK_UPDATE_SUCCESS, {
          position: "top-right",
          duration: 3000,
        });
        this.isEditingDescription = false;
        // this.$store.dispatch("tasks/index");
        this.refreshTasks();
      });
    },
    setTimeLog(timeLog) {
      this.$emit("setTimeLog", timeLog);
    },
    setDeleteState(name, id) {
      this.showDelete = true;
      this.showDeleteName = name;
      this.selectedDeleteId = id;
    },
    cancelDelete() {
      this.showDelete = false;
    },
    deleteItem() {
      if (this.showDeleteName == "TimeLog") {
        //delete milestone
        this.$store
          .dispatch("tasks/deleteTimeLog", this.selectedDeleteId)
          .then((s) => {
            this.$store.dispatch("tasks/getTimeLogs", this.$route.params.tid);
            // this.$refs.vuetableMilestone.refresh();
            this.$toast.success("🚮 Timelog successfully deleted", {
              position: "top-right",
              duration: 3000,
            });
            this.showDelete = false;
          });
      } else if (this.showDeleteName == "Expense") {
        //delete task
        this.$store
          .dispatch("tasks/deleteExpense", this.selectedDeleteId)
          .then((s) => {
            this.$store.dispatch("tasks/getExpenses", this.$route.params.tid);
            this.$toast.success("🚮 Expense Successfully Deleted", {
              position: "top-right",
              duration: 3000,
            });
            this.showDelete = false;
          });
      }
    },
    setExpenseLog(expense) {
      this.$emit("setExpenseLog", expense);
    },
    changeAssignee() {
      this.changingAssignee = true;
      this.$store
        .dispatch("tasks/reAssign", {
          id: this.task.id,
          employee_id: this.newEmployee.id,
        })
        .then((s) => {
          this.$store.dispatch("tasks/getSingle", this.$route.params.tid);
          this.refreshTasks();
          this.$toast.success(`Task assigned to ${this.newEmployee.name}`, {
            position: "top-right",
            duration: 3000,
          });
          // this.newEmployee = employee;
        })
        .catch((e) => {
          // console.log(e);
          this.$toast.error("Failed to reassign task!", {
            position: "top-right",
            duration: 3000,
          });
        })
        .finally(() => {
          this.switchView();
          this.changingAssignee = false;
        });
    },
    changeMilestone(milestone) {
      this.changingMilestone = true;
      let data = {
        id: this.task.id,
        project_milestone_id: milestone.id,
      };
      this.$store
        .dispatch("tasks/createUpdate", data)
        .then((s) => {
          this.$store.dispatch("tasks/getSingle", this.$route.params.tid);
          this.refreshTasks();
          this.$toast.success(`Task linked to ${milestone.name} milestone`, {
            position: "top-right",
            duration: 3000,
          });
          this.viewMilestone();
        })
        .finally((this.changingMilestone = false));
    },
    approveExpense(id) {
      this.loading = true;
      this.$store
        .dispatch("tasks/approveExpense", id)
        .then(() => {
          this.$toast.success("Expense marked as approved", {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch(() => {
          this.$toast.error("Failed to approve expense. Please try again.", {
            position: "top-right",
            duration: 3000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onEditorFocus(event) {
      let el = document.querySelector(".ql-editor");
      el.innerHTML = this.task.description;
    },
    updateComplexity() {
      let data = {
        id: this.task.id,
        complexity: this.complexityValue,
      };
      this.isUpdating = true;
      this.$store
        .dispatch("tasks/createUpdate", data)
        .then((s) => {
          this.$toast.success(Literals.TASK_UPDATE_SUCCESS, {
            position: "top-right",
            duration: 3000,
          });
          this.refreshTasks();
        })
        .catch((err) => {
          this.$toast.error("Something went wrong while saving your entry!", {
            position: "top-right",
            duration: 3000,
          });
        })
        .finally((s) => {
          this.isUpdating = false;
          this.isEditComplexity = false;
        });
    },
  },
  computed: {
    timerExist,
    task() {
      return this.$store.getters["tasks/task"];
    },
    timeLogs() {
      return this.$store.getters["tasks/time_logs"];
    },
    expenses() {
      return this.$store.getters["tasks/expenses"];
    },
    employees() {
      return this.$store.getters["employees/employees_simple"];
    },
    milestones() {
      return this.$store.getters["projects/milestones"];
    },
    // employee() {
    //   if (this.newEmployee && this.newEmployee.id) {
    //     return this.newEmployee;
    //   } else if (this.task.employee_id) {
    //     let user = this.employees.filter(
    //       (e) => e.id === this.task.employee_id
    //     )[0];
    //     return {
    //       id: user.id,
    //       name: `${user.first_name} ${user.last_name}`,
    //     };
    //   }

    //   return {};
    // },
    creator() {
      return (
        this.$store.getters["employees/employees"].find(
          (s) => s.user_id == this.task.created_by
        ) || {}
      );
    },
  },
  created() {
    Promise.all([
      this.$store
        .dispatch("tasks/getSingle", this.$route.params.tid)
        .then((res) => {
          let task = res;
          // let employee = this.task";
          this.newEmployee = {
            id: `${Number(optional(task, "employee.id"))}`,
            name: `${optional(
              task,
              "employee.user.first_name"
            )} ${optional(task, "employee.user.last_name")}`,
            f_initial: optional(task, "employee.user.first_name")
              ? task.employee.user.first_name.charAt(0)
              : "-",
            l_initial: optional(task, "employee.user.last_name")
              ? task.employee.user.last_name.charAt(0)
              : "-",
            email: optional(task, "employee.user.email"),
          };
          this.$store.dispatch("projects/getMilestones", task.project_id);
        }),
      this.$store.dispatch("tasks/getTimeLogs", this.$route.params.tid),
      this.$store.dispatch("tasks/getExpenses", this.$route.params.tid),
      this.$store.dispatch("employees/index"),
    ]).finally(() => {
      this.loaded = true;
    });
  },

  watch: {
    refreshTimes() {
      this.$store.dispatch("tasks/getTimeLogs", this.$route.params.tid);
    },
  },
};
</script>
