export default {
    SET_COUNTRIES(state, array) {
        state.countries = array;
    },
    SET_ORGANIZATION(state, array) {
        state.selected_org = array;
    },
    SET_STATES(state, array) {
        state.states = array;
    },
    SET_STATE(state, array) {
        state.state = array;
    },
    SET_PROJECT_BILLING_TYPES(state, array) {
        state.project_billing_types = array;
    },
    SET_PROJECT_STATUSES(state, array) {
        state.project_statuses = array;
    },
    SET_CURRENCIES(state, array) {
        state.currencies = array;
    },
    SET_TASK_STATUSES(state, array) {
        state.task_statuses = array;
    },
    SET_ORGANIZATION_KEY(state, array) {
        state.organization_key = array;
    },
    SET_INDUSTRIES(state, array) {
        state.industries = array;
    },
    SET_CUSTOMER_SOURCES(state, array) {
        state.customer_sources = array;
    },
    SET_EMPLOYEE_TYPES(state, array) {
        state.employee_types = array;
    },
    SET_CITIES(state, array) {
        state.cities = array;
    },
    SET_CITY(state, array) {
        state.city = array;
    },
    SET_ROLES(state, array) {
        state.roles = array;
    },
    SET_JOB_ROLES(state, array) {
        state.job_roles = array;
    },
    SET_BANKS(state, array) {
        state.banks = array;
    },
    SET_RECIPIENT_TYPES(state, array) {
        state.recipient_types = array
    },
    SET_OPPORTUNITY_STAGES(state, array) {
        state.opportunity_stages = array
    },
    SET_EXPENSE_CATEGORIES(state, array) {
        state.expense_categories = array
    },
    SET_INVOICE_STATUSES(state, array) {
        state.invoice_statuses = array
    },
    SET_PAYMENT_SOURCES(state, array) {
        state.payment_sources = array
    },
    SET_MILESTONE_STATUSES(state, array) {
        state.milestone_statuses = array
    },
    SET_ORGANIZATION_ADDRESSES(state, array) {
        state.organization_addresses = array
    },
    SET_PAY_PERIODS(state, array) {
        state.pay_periods = array;
    },
    SET_LICENSE(state, array) {
        state.license = array;
    },
    SET_SUBSCRIPTION(state, array) {
        state.subscription = array;
    },
}