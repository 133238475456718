import axios from 'axios';
import {
    updatePending
} from "@/helpers/axiosIntercept";

const API = process.env.VUE_APP_BASE_URI;
export default {
    fetchTables({commit}) {
        return new Promise((resolve, reject) => {
            let url = `${API}/report-entities`;
            axios.get(url)
                .then(({
                    data
                }) => {
                    commit('SET_TABLES', data.data)
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    index({commit}, data) {
        return new Promise((resolve, reject) => {
           
            let url = `${API}/reports/${data.table}?${data.query}`;
            axios.get(url)
                .then(({
                    data
                }) => {
                    commit('SET_REPORTS', data.data)
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    }
}