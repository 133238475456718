<template>
  <div>
    <div class="kanban-board__status-task" style="top:0 !important">
      <div class="d-flex flex-row justify-content-between">
        <h6 class="text-bold text-opacity-pale h6">New Sub Task</h6>
        <span @click="$router.go(-1)" style="cursor:pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            fill="#c39e9e"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z"
            ></path>
          </svg>
        </span>
      </div>
      <form class="d-form" @submit.prevent="handleTaskAdded()">
        <div class="row">
          <div class="col-sm-12">
            <input
              type="text"
              :name="'name'"
              v-model="task.name"
              :style="errors.has('name') ? 'border:1px solid red' : ''"
              v-validate="'required'"
              class="form-control auth-input"
              :placeholder="'Title  of task'"
            >
          </div>
          <div class="col-sm-12 mt-3" v-show="!currentTask.project_id">
            <multiselect
              track-by="id"
              label="name"
              class="no-caret-select"
              :style="errors.has('project') ? 'border:1px solid red' : ''"
              name="project"
              v-model="task.project"
              :options="projects"
              placeholder="What project is this for?"
              :show-labels="false"
            >
              <template slot="caret">
                <div>
                  <div class="multiselect__select">
                    <i class="zmdi zmdi-chevron-down pt-1" style="font-size:20px"></i>
                  </div>
                </div>
              </template>
            </multiselect>
          </div>
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-6 mt-3">
                <multiselect
                  track-by="id"
                  label="name"
                  :style="errors.has('assignee') ? 'border:1px solid red' : ''"
                  v-validate="'required'"
                  class="no-caret-select"
                  name="assignee"
                  v-model="task.assignee"
                  :options="employees"
                  placeholder="Who's to work on this task?"
                  :show-labels="false"
                >
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <i class="zmdi zmdi-chevron-down pt-1" style="font-size:20px"></i>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
              <div class="col-sm-6 mt-3">
                <multiselect
                  track-by="id"
                  label="name"
                  :style="errors.has('task_status') ? 'border:1px solid red' : ''"
                  class="no-caret-select"
                  name="assignee"
                  v-model="task.task_status"
                  :options="task_statuses"
                  placeholder="Select Task Status"
                  :show-labels="false"
                >
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <i class="zmdi zmdi-chevron-down pt-1" style="font-size:20px"></i>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-12 mt-2 mb-2">
            <quill-editor
              ref="myQuillEditor"
              style="height: 100px;background: white;"
              id="subtask"
              class="mt-1"
              v-model="task.description"
               @ready="_html(task.description)"
              placeholder="Enter Message"
              :options="customToolbar"
            />
          </div>
          <div class="col-sm-12 mt-5">
            <vc-date-picker
              :masks="{ input: ['YYYY-MM-DD']}"
              :style="errors.has('due_date') ? 'border:1px solid red' : ''"
              :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: `Due date`}"
              v-model="task.due_date"
              :popover="{ visibility: 'click' }"
              name="due_date"
              ref="DueDate"
              data-vv-as="Task Due date"
            />
          </div>
          <div class="col-sm-12 text-right mt-3">
            <Auth-Button :loading="loading" text="Save"/>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>
#subtask .ql-editor {
  background: white !important;
}
</style>

<script>
import AuthButton from "@/components/Buttons/Auth.vue";
import Literals from "@/helpers/dashboard/literals";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "quill-mention/dist/quill.mention.min.css";
import Mention from "quill-mention";
import { quillEditor } from "vue-quill-editor";
import { toLocal } from "@/helpers/date";
import { toUTC } from "@/helpers/date";
import Quill from "quill";
Quill.register("modules/mention", Mention);
export default {
  components: { AuthButton, quillEditor },
  data() {
    return {
      Literals,
      newTaskBar: "",
      task: {
        name: "",
        description: "",
        assignee: "",
        project: "",
        task_status: "",
        employee_id: "",
        due_date: ""
      },
      customToolbar: {
        modules: {
          mention: {
            allowedChars: /^[a-zA-Z0-9_]*$/,
            mentionDenotationChars: ["@"],
            renderItem: item => {
              return item.display;
            },
            source: (searchTerm, renderList, mentionChar) => {
              let users = this.$store.getters["employees/users"];
              users.filter(s => {
                s["display"] = s.name;
                s["value"] = s.name;
                s["target"] = window.location.origin + "/users/" + s.id;
                s["link"] = window.location.origin + "/users/" + s.id;
              });

              let values = users;

              if (searchTerm.length === 0) {
                renderList(values, searchTerm);
              } else {
                const matches = [];
                for (let i = 0; i < values.length; i++)
                  if (
                    ~values[i].value
                      .toLowerCase()
                      .indexOf(searchTerm.toLowerCase())
                  )
                    matches.push(values[i]);
                renderList(matches, searchTerm);
              }
            }
          }
        }
      },
      loaded: false,
      loading: false,
      moreParams: {}
    };
  },
  methods: {
    handleTaskAdded() {
      let data = {
        name: this.task.name,
        employee_id: this.task.assignee && this.task.assignee.id,
        due_date: this.task.due_date
          ? toUTC(this.task.due_date).format("YYYY-MM-DD")
          : null,
        project_id:
          this.currentTask && this.currentTask.project_id
            ? this.currentTask.project_id
            : this.task.project && this.task.project.id,
        description:
          this.task && this.task.description ? this.task.description : null,
        task_status_id: this.task.task_status ? this.task.task_status.id : null,
        parent_task_id: this.$route.params.tid
      };
      let msgPass = Literals.TASK_CREATE_SUCCESS;
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("tasks/createUpdate", data)
            .then(k => {
              this.$toast.success(msgPass, {
                position: "top-right",
                duration: 3000
              });
              this.$store.dispatch("tasks/getSingle", this.$route.params.tid);
              this.$router.go(-1);
            })
            .catch(err => console.log(err))
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  },
  computed: {
    projects() {
      return this.$store.getters["projects/projects_simple"];
    },
    employees() {
      return this.$store.getters["employees/employees_simple"];
    },
    task_statuses() {
      return this.$store.getters["global/task_statuses"];
    },
    currentTask() {
      return this.$store.getters["tasks/task"];
    }
  },
  created() {
    Promise.all([
      this.$store.dispatch("projects/index"),
      this.$store.dispatch("employees/index"),
      this.$store.dispatch("tasks/index",),
      this.$store.dispatch("global/getTaskStatuses"),
      this.$store.dispatch("tasks/getSingle", this.$route.params.id)
    ]).finally(() => {
      this.loaded = true;
    });
  }
};
</script>
