<template>
  <div class="home">
    <headers>
      <div slot="breadcrumbs">
        <breadcrumbs :items="breadCrumbs"></breadcrumbs>
      </div>
      <div slot="title">
        <h2>Run employee Payroll</h2>
        <h6 class="h7 text-bolder text-opacity-pale pt-2">
          <i class="zmdi zmdi-calendar-alt pr-2" style="font-size:18px"></i>Payroll period: {{ toLocal(payroll.start_date).format('ll') }} - {{toLocal(payroll.end_date).format('ll')}}
        </h6>
      </div>
      <div slot="action">
        <router-link :to="{path: `/payments/${$route.params.id}/process`, params:{id:$route.params.id}, meta:{stage:1}}" v-if="$route.path != `/payments/${$route.params.id}/process`">
          <button class="btn btn-ds">
            <span class="pr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#FFF"
                width="17"
                height="17"
                viewBox="0 0 24 27"
              >
                <path
                  d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"
                ></path>
              </svg>
            </span>
            Edit Payroll
          </button>
        </router-link>
      </div>
    </headers>
    <div class="container-fluid mt-1">
      <div class="row pl-3 pb-4 pr-4">
        <div class="col-sm-12 col-lg-12 mt-3 opportunity-wrapper">
          <ul class="opportunity-stages">
            <li
              v-for="(stage,index) in stages"
              :key="index"
              :class="{
              active: stage.id == payroll_stage,
              done: payroll_stage > stage.id,
              pending: payroll_stage < stage.id,
              button: payroll_stage > stage.id,
              unclickable: payroll_stage <= stage.id
            }"
            >
            <router-link :disabled="payroll_stage <= stage.id" :to="{path: stage.path}">
              <span class="pr-3">
                <i v-if="stage.id == payroll_stage" class="zmdi zmdi-flag"></i>
                <i
                  v-else-if="payroll_stage > stage.id"
                  class="zmdi zmdi-check-circle"
                ></i>
              </span>
              {{stage.name}}
              <span></span>
              <span></span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <router-view></router-view>
    </div>
  </div>
</template>


<script>
import Headers from "@/components/Layouts/Headers";
import Breadcrumbs from "@/components/Breadcrumbs/Index";
import {toLocal} from "@/helpers/date";

export default {
  title: 'Payroll',
  components: { Headers, Breadcrumbs },
  data() {
    return {
      payroll_stage:1,
      breadCrumbs: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "All Payments",
          href: '/payments'
        },
        {
          text: "Payroll",
          active: true
        }
      ],
     
    };
  },
  computed : {
    payroll() {
      return this.$store.getters['payrolls/payroll'];
    },
     stages() {
       return [
        { name: "View & edit earnings", id: 1, path : `/payments/${this.$route.params.id}/process` },
        { name: "Review payroll", id: 2, path : `/payments/${this.$route.params.id}/review`},
        { name: "Confirm", id: 3, path : `/payments/${this.$route.params.id}/confirm` }
      ]
     },
  },
  methods : {
    toLocal,
  },
  created() {
    this.$store.dispatch('payrolls/getSingle', this.$route.params.id)
 
  },
  beforeRouteUpdate(to, from, next) {
   
    this.payroll_stage = to.meta.stage;
    next();
  }
};
</script>
