<template>
  <b-modal
    :no-close-on-backdrop="true"
    @hide="close"
    :visible="showSubscribeModal"
    id="expenseModal"
    ref="expenseModal"
  >
  </b-modal>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
const stripeInit = loadStripe('pk_test_YWvWACTJDpJkwAL7Y03JOTzm00JzflkDOR');
export default {
  props: ["showSubscribeModal"],
  data() {
    return {
      body: {
        quantity: "",
      },
      loading: false,

      stripe: "",
      elements: "",
      card: "",
    };
  },
  mounted() {
    this.configureStripe();
  },
  methods: {
    showModal() {},
    close() {
      this.$emit("close", false);
    },
    save() {},
    async configureStripe() {
      this.stripe = stripeInit;
      this.stripe.then((s) => {
        console.log(s);
        this.elements = s.elements();
        this.card = this.elements.create("card");

        this.card.mount("#card-element");
      });
    },
    subscribe() {
      let url = this.$baseApi + "/organizations/subscribe";
      this.loading = true;
      this.$http
        .post(url, this.body)
        .then((s) => {
          this.$toast.success("Seats successfully added to your plan!", {
            position: "top-right",
            duration: 3000,
          });
          this.$emit("close", false);
          s["card"] = this.card;
          this.$emit("subscribed", s);
        })
        .catch((err) => {
          this.$toast.error("Something went wrong while saving your entry!", {
            position: "top-right",
            duration: 3000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>