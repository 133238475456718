<template>
  <div>
    <div class="table">
      <div class="table-wrapper">
        <div
          id="expensesTable"
          style="padding-left:0px !important"
          class="no-padding container-fluid"
        >
          <vuetable
            ref="vuetable"
            :css="table_css"
            :api-url="api"
            :first-page="0"
            :append-params="moreParams"
            :http-options="{ headers:{ 'Authorization' : 'Bearer ' + $auth.token(),'xorg' : getOrganization.organization_id() } }"
            @vuetable:loaded="loadTable"
            :fields="columns.filter(s => s.active === true)"
            data-path="data"
            :transform="transform"
            pagination-path
            :no-data-template="rowCount === null ? 'Loading data' : 'No Data Available'"
            @vuetable:pagination-data="onPaginationData"
          >
            <template slot="sn" slot-scope="props">
              <router-link
                :to="{name: 'invoices-show', params: {id: props.rowData.id}}"
              >{{props.rowIndex + 1}}</router-link>
            </template>

            <template
              slot="amount"
              slot-scope="props"
            >
            {{props.rowData.currency && props.rowData.currency.symbol}} {{numberWithCommas(parseFloat(props.rowData.amount))}}
            </template>

                <template
              slot="amount_due"
              slot-scope="props"
            >
            {{props.rowData.currency && props.rowData.currency.symbol}} {{numberWithCommas(parseFloat(props.rowData.amount_due))}}
            </template>

                <template
              slot="amount_paid"
              slot-scope="props"
            >
            {{props.rowData.currency && props.rowData.currency.symbol}} {{numberWithCommas(parseFloat(props.rowData.amount_paid))}}
            </template>
           
            <template slot="task" slot-scope="props">
              <router-link
                v-if="props.rowData.task"
                :to="{name: 'tasks-show', params: {id: props.rowData.task.id}}"
              >{{props.rowData.task.name}}</router-link>
            </template>
            <template slot="actions" slot-scope="props">
              <router-link :to="{name: 'invoices-show', params: {id: props.rowData.id}}">
                <button class="btn-lightgray-round">
                  <i class="zmdi zmdi-plus pr-2"></i>Open Invoice
                </button>
              </router-link>
            </template>
          </vuetable>
          <vuetable-pagination
            v-show="paginationData.total"
            :css="table_css.pagination"
            ref="pagination"
            class="pl-2 pt-3 pr-2"
            @vuetable-pagination:change-page="onChangePage"
          ></vuetable-pagination>
          <div class="text-center" v-if="rowCount <=0 || rowCount === null">
            <slot name="empty"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import moment from "moment";
import getOrganization from "@/helpers/getOrganization";
import VuetablePagination from "@/components/Layouts/VueTablePagination";
import getPaginationData from "@/helpers/pagination";
import { toLocal } from "@/helpers/date";
import { numberWithCommas } from "@/helpers/number";
export default {
  components: { VuetablePagination },
  props: {
    api: {
      type: String,
      default: `${process.env.VUE_APP_BASE_URI}/invoices`,
      required: false
    },
    moreParams: {
      required: false
    }
  },
  data() {
    return {
      getOrganization,
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      columns: [
        {
          name: "__checkbox",
          titleClass: "center aligned",
          dataClass: "center aligned",
          cvisible: false,
          active: true
        },
        {
          name: "subject",
          title: "Subject",
          cvisible: true,
          active: true,
          callback: d => {
            return d ? d : "-";
          }
        },
        {
          name: "description",
          title: "Description",
          cvisible: true,
          active: true,
          callback: d => {
            if (d) {
              if (d.length > 20) {
                return d.substring(0, 20) + "...";
              }
              return d;
            } else {
              return "N/A";
            }
          }
        },
        {
          name: "__slot:amount",
          title: "Amount",
          sortField: "amount",
          cvisible: true,
          active: true
        },
         {
          name: "__slot:amount_paid",
          title: "Amount Paid",
          sortField: "amount_paid",
          cvisible: true,
          active: true
        },
         {
          name: "__slot:amount_due",
          title: "Amount Due",
          sortField: "amount_due",
          cvisible: true,
          active: true
        },
        {
          name: "tax.data.company_name",
          title: "Tax",
          cvisible: true,
          active: true,
          callback: d => {
            return d ? d : "-";
          }
        },
        {
          name: "due_date",
          title: "Due Date",
          cvisible: true,
          active: true,
          callback: s => {
            return s ? toLocal(s).format("MM/DD/YYYY") : "N/A";
          }
        },
        {
          name: "created_at",
          title: "Invoice Date",
          cvisible: true,
          active: true,
          callback: s => {
            return s ? toLocal(s).format("MM/DD/YYYY") : "N/A";
          }
        },
        {
          name: "status.name",
          title: "Invoice Status",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:actions",
          title: "Action",
          cvisible: true,
          active: true,
          callback: t => {
            return t && t.data ? t.data.name : "N/A";
          }
        }
      ],
      paginationData: {}
    };
  },
  methods: {
    numberWithCommas,
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
      this.$emit("setInvoiceTotal", this.$refs.vuetable.tablePagination);
    },
    setFilters(d, l) {
      if (this.moreParams && this.moreParams[l] == "") {
        delete this.moreParams[l];
      }
      let sm = this;
      // this.moreParams = this.moreParams.filter(s => typeof s !== "object");
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },

    refreshTable() {
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find(s => s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    },
    transform(data) {
      let entries = data.data;
      entries = entries.filter(s => !s.parent_invoice_id);
      entries.forEach(function(part, index, theArray) {
        theArray[index]["children"] = data.data.filter(
          s => s.parent_invoice_id == theArray[index]["id"]
        );
      });

      return {
        data: entries,
        links: data.links,
        message: data.message,
        meta: data.meta,
        success: data.success
      };
    }
  },
  computed: {},
  mounted() {},
  watch: {
    moreParams: {
      deep: true,
      handler(m) {
        this.setFilters();
      }
    },
    $route(to, from) {
      this.refreshTable();
    }
    // deep: true
  }
};
</script>

