<template>
  <!-- Modal -->
  <div>
    <b-modal
      @hide="close"
      :no-close-on-backdrop="true"
      ref="report-modal"
      id="newReportModal"
    >
      <template v-slot:modal-header>
        <h2 class="pl-3 pt-3 pr-3 f-24">{{ "New Report" }}</h2>
        <button class="close" @click="close">
          Cancel
          <i class="zmdi zmdi-close-circle pl-2" style="font-size:20px"></i>
        </button>
      </template>
      <div class="d-block pl-3">
        <div>
          <h5 class="text-bold text-opacity-pale h5">Tables</h5>
          <div class="mt-4">
            <ul class="tab-select d-flex align-items">
              <li
                class="mr-3 d-flex flex-wrap justify-content-center align-items-center"
                v-for="(table, index) in tables"
                :id="body.table_name == table.name ? 'selected' : ''"
                :key="index"
              >
                <h6
                  @click="setTable(table.name)"
                  class="h7 text-opacity-pale text-bold"
                >
                  {{ table.name }}
                </h6>
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-5">
          <h5 class="text-bold text-opacity-pale h5">Columns</h5>
          <div class="mt-4">
            <ul style="flex-wrap:wrap"
              class="tab-select d-flex align-items grid grid-rows-3 grid-flow-col gap-4"
            >
              <li
                class="mr-3 d-flex m-2 flex-wrap justify-content-center align-items-center row-span-3"
                v-for="(column, i) in tableColumns"
                :id="body.columns[column.column_name || column.COLUMN_NAME] ? 'selected' : ''"
                :key="i"
              >
                <span
                  @click="setColumn(column.column_name || column.COLUMN_NAME, column.data_type || column.DATA_TYPE)"
                  class="h7 text-opacity-pale text-bold"
                >
                  {{ column.column_name || column.COLUMN_NAME }}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-5">
          <h5 class="text-bold text-opacity-pale h5">Selected columns</h5>
          <div class="mt-4">
            <table class="table table-bordered d-form">
              <thead>
                <tr>
                  <th scope="col">Column</th>
                  <th scope="col">Value</th>
                  <!-- <th scope="col">Add to Export?</th> -->
                </tr>
              </thead>
              <tbody v-for="(c, ind) in selectedColumns" :key="ind">
                <tr>
                  <th scope="row">
                    <h6 class="h6 text-bold text-opacity-pale">{{ c }}</h6>
                  </th>
                  <td style="flex-direction: row;flex-wrap: no-wrap !important;display: flex;">
                    <input
                      :type="resolveInputType(body.columns[c].type)"
                      v-validate="'min:3|max:80'"
                      v-model="body.columns[c].value"
                      class="form-control auth-input mt-0 w-50 d-input"
                      :placeholder="`Enter ${c} Value`"
                      :data-vv-as="`${body.table_name} ${c}`"
                    />
                    <input v-if="resolveInputType(body.columns[c].type).includes('date')"
                      :type="resolveInputType(body.columns[c].type)"
                      v-validate="'min:3|max:80'"
                      v-model="body.columns[c].value_to"
                      class="form-control auth-input mt-0 w-50 d-input"
                      :placeholder="`Enter ${c} Value to`"
                      :data-vv-as="`${body.table_name} ${c}`"
                    />
                  </td>
                  <!-- <td>
                    <input
                      type="checkbox"
                      v-model="body.columns[c].add"
                      class="mr-3"
                    />
                    <label for="addExport">
                      <h6 class="text-bold h6 text-opacity-pale">Yes, Add</h6>
                    </label>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer>
        <dashboard-button
          @submit="save"
          :loading="loading"
          :text="'Create'"
        ></dashboard-button>
      </template>
    </b-modal>
  </div>
</template>
<style lang="scss" scoped>
.tab-select {
  li {
    height: 50px;
    width: 150px;
    background: white;
    border: 1px dashed #e4e4e4;
    border-radius: 5px;

    &:hover,
    &#selected {
      background: #0a5685;
      border-color: white !important;
      h6 {
        color: white !important;
      }
    }
  }
}
</style>

<script>
import DashboardButton from "@/components/Buttons/Dashboard";
import handleError from "@/helpers/error";
export default {
  components: { DashboardButton },
  data() {
    return {
      body: {
        columns: [],
      },
      loading: false,
      tableColumns: [],
      selectedColumns: [],
      closed: false,
      url: "",
    };
  },
  methods: {
    close() {
      //this.$refs["report-modal"].hide();
      this.$router.push({ name: "reporting-index", query: { url: this.url } });
    },
    setTable(name) {
      if (this.body.table_name == name) {
        this.body.table_name = null;
        this.tableColumns = [];
      }
      this.body.table_name = name;
      let table = this.tables.find((s) => s.name == name);
      this.tableColumns = table ? table.columns : [];
      // console.log('table selected', this.body.table_name, this.tableColumns )
    },
    setColumn(name, type) {
      if (this.body.columns[name]) {
        delete this.body.columns[name];
        this.selectedColumns = Object.keys(this.body.columns);
        return;
      }
      this.body.columns[name] = { type };

      this.selectedColumns = Object.keys(this.body.columns);
    },
    save() {
      this.loading = true;
      let params = [];
      let columns = [];

      Object.keys(this.body.columns).forEach((key) => {
        columns.push(key);
        let value = this.body.columns[key].value || "";
        if(this.body.columns[key].value_to) {
          value += `|${this.body.columns[key].value_to}`;
        }
        params[key] = value;
      });
   
      let data = {
        table: this.body.table_name.slice(0, -1),
        columns,
        query: Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")+'&columns='+columns.join(','),
      };

      this.$store
        .dispatch("reports/index", data)
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
          this.url = `/${data.table}?${data.query}`;
          this.close();
        })
        .catch((error) => {
          let errorMessage = "Could not create report";
          handleError(this, error, errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resolveInputType(name) {
      let type = "";
      switch (name) {
        case "integer":
        case "tinyint":
        case "int":
        case "bigint":
          type = "number";
          break;
        case "timestamp":
        case "datetime":
          type = "datetime-local";
          break;
        case "time":
          type = "time";
          break;
        default:
          type = "text";
          break;
      }

      return type;
    },
  },
  computed: {
    tables() {
      return this.$store.getters["reports/tables"];
    },
  },
  mounted() {
    this.$refs["report-modal"].show();
  },
  created() {
    this.$store.dispatch("reports/fetchTables");
  },
};
</script>
