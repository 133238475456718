<template>
  <!-- Modal -->
  <b-modal
    @hide="close;"
    :no-close-on-backdrop="true"
    ref="my-modal"
    :class="{ blur: !loaded }"
  >
    <template v-slot:modal-header>
      <h2 class="pl-3 pt-3 pr-3 f-24 long-text-wrapper">
        {{ task && task.id ? `Edit Task - ${task.name}` : "Create New Task" }}
      </h2>
      <button class="close" @click="close">
        Cancel
        <i class="zmdi zmdi-close-circle pl-2" style="font-size: 20px"></i>
      </button>
    </template>
    <div class="d-block pl-3 pb-1">
      <form class="d-form" @submit.prevent="save">
        <div class="row">
          <div class="col-sm-12 col-lg-6">
            <h6 class="text-bold text-opacity-pale">
              Task Name
              <b>*</b>
            </h6>
            <input
              type="text"
              v-validate="'required|min:3|max:80'"
              v-model="body.name"
              name="name"
              class="form-control auth-input d-input"
              placeholder="Enter Task name"
              data-vv-as="Task name"
            />
            <small class="form-text text-danger" v-show="errors.has('name')">
              {{ errors.first("name") }}
            </small>
          </div>
          <div class="col-sm-12 col-lg-6">
            <h6 class="text-bold text-opacity-pale">
              Status
              <b>*</b>
            </h6>
            <multiselect
              track-by="id"
              label="name"
              v-model="body.status"
              v-validate="'required'"
              name="status"
              :options="taskStatuses"
              placeholder="Select Status"
              :show-labels="false"
              data-vv-as="Task Status"
            ></multiselect>
            <small
              class="form-text text-danger"
              v-show="errors.has('status')"
              >{{ errors.first("status") }}</small
            >
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-12 col-lg-12">
            <h6 class="text-bold text-opacity-pale">Task Description</h6>

            <quill-editor
              name="description"
              ref="myQuillEditor"
              class="mt-1"
              v-validate="'min:3'"
              v-model="body.description"
              @ready="_html(body.description)"
              :options="customToolbar"
              placeholder="Enter task description"
            />
            <small
              class="form-text text-danger"
              v-show="errors.has('description')"
              >{{ errors.first("description") }}</small
            >
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-12 col-lg-6">
            <div class="row">
              <div class="col-sm-12">
                <h6 class="text-bold text-opacity-pale">
                  Assign to Project
                  <b>*</b>
                </h6>
                <multiselect
                  track-by="id"
                  label="name"
                  v-model="body.project"
                  name="project_id"
                  :options="projects"
                  :disabled="this.hasProject"
                  placeholder="Select Project"
                  @input="fetchMilestones"
                  v-validate="'required'"
                  :show-labels="false"
                  data-vv-as="Project"
                ></multiselect>
                <small
                  class="form-text text-danger"
                  v-show="errors.has('project_id')"
                  >{{ errors.first("project_id") }}</small
                >
              </div>
              <div class="col-sm-12 mt-4">
                <h6 class="text-bold text-opacity-pale">Link to Milestone</h6>
                <multiselect
                  track-by="id"
                  label="name"
                  v-model="body.milestone"
                  name="project_milestone_id"
                  :options="milestones"
                  placeholder="Select Milestone if available"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-6">
            <div class="row">
              <div class="col-sm-12 col-lg-12 date-range">
                <h6 class="text-bold text-opacity-pale">Assign Task to</h6>
                <multiselect
                  track-by="id"
                  label="name"
                  v-model="body.employee"
                  name="employee_id"
                  placeholder="Select Employee to assign task to"
                  :options="employees"
                  :show-labels="false"
                  data-vv-as="Assignee"
                ></multiselect>
                <small
                  class="form-text text-danger"
                  v-show="errors.has('employee_id')"
                  >{{ errors.first("employee_id") }}</small
                >
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-lg-12">
                <h6 class="text-bold text-opacity-pale">
                  Total estimated hours
                </h6>
                <input
                  class="form-control auth-input d-input"
                  v-validate="'numeric'"
                  v-model="body.estimated_hours"
                  type="number"
                  name="estimated_hours"
                  placeholder="Enter Total estimated hours"
                  data-vv-as="Estimated hours"
                />

                <small
                  class="form-text text-danger"
                  v-show="errors.has('estimated_hours')"
                  >{{ errors.first("estimated_hours") }}</small
                >
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-12">
            <div class="row mt-4">
              <div class="col-sm-6">
                <h6 class="text-bold text-opacity-pale">Preceding Task</h6>
                <multiselect
                  track-by="id"
                  label="name"
                  v-model="body.parent_task"
                  name="parent_task_id"
                  :options="filteredTasks"
                  :show-labels="false"
                ></multiselect>
              </div>
              <div class="col-sm-6">
                <h6 class="text-bold text-opacity-pale">Complexity</h6>
                <input
                  class="form-control auth-input d-input"
                  v-validate="'numeric'"
                  v-model="body.complexity"
                  type="number"
                  name="complexity"
                  placeholder="Enter Task Complexity"
                  data-vv-as="Complexity"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-4 date-range">
            <h6 class="text-bold text-opacity-pale">Start Date</h6>
            <vc-date-picker
              :masks="{ input: ['YYYY-MM-DD'] }"
              :input-props="{
                class: `d-input no-margin-top auth-input pl-3`,
                style: `margin-top:0px !important`,
                placeholder: `Start date`,
              }"
              v-model="body.start_date"
              name="start_date"
              ref="startFrom"
              data-vv-as="Expected Start date"
            />
            <small class="dpicker-icon">
              <i class="zmdi zmdi-calendar-alt"></i>
            </small>
            <small
              class="form-text text-danger"
              v-show="errors.has('start_date')"
              >{{ errors.first("start_date") }}</small
            >
          </div>
          <div class="col-sm-4 date-range mt-6">
            <h6 class="text-bold text-opacity-pale">Due Date</h6>
            <vc-date-picker
              :masks="{ input: ['YYYY-MM-DD'] }"
              :input-props="{
                class: `d-input no-margin-top auth-input pl-3`,
                style: `margin-top:0px !important`,
                placeholder: ` End date`,
              }"
              v-model="body.due_date"
              v-validate="body.start_date != '' ? 'after:startFrom' : ''"
              name="end_date"
              data-vv-as="Due Date"
            />
            <small class="dpicker-icon">
              <i class="zmdi zmdi-calendar-alt"></i>
            </small>
            <small
              class="form-text text-danger"
              v-show="errors.has('end_date')"
              >{{ errors.first("end_date") }}</small
            >
          </div>
          <div class="col-sm-4 date-range mt-6">
            <h6 class="text-bold text-opacity-pale">Completion Date</h6>
            <vc-date-picker
              :masks="{ input: ['YYYY-MM-DD'] }"
              :input-props="{
                class: `d-input no-margin-top auth-input pl-3`,
                style: `margin-top:0px !important`,
                placeholder: ` End date`,
              }"
              v-model="body.completed_date"
              v-validate="'after:startFrom'"
              name="end_date"
              data-vv-as="Completion Date"
            />
            <small class="dpicker-icon">
              <i class="zmdi zmdi-calendar-alt"></i>
            </small>
            <small
              class="form-text text-danger"
              v-show="errors.has('completed_date')"
              >{{ errors.first("completed_date") }}</small
            >
          </div>
        </div>
      </form>
    </div>
    <template v-slot:modal-footer>
      <dashboard-button
        @submit="save"
        :loading="loading"
        :text="task && task.id ? 'Save' : 'Save'"
      ></dashboard-button>
    </template>
  </b-modal>
</template>

<script>
import DashboardButton from "@/components/Buttons/Dashboard";
import Literals from "@/helpers/dashboard/literals";
import { optional } from "@/helpers/random";
import handleError from "@/helpers/error";
import { toUTC } from "@/helpers/date";

export default {
  data() {
    return {
      Literals,
      activeSettlement: 1,
      loading: false,
      body: {
        name: "",
        project_status_id: "",
        actual_cost: "",
        customer_id: "",
        currency_id: "",
        manager_user_id: "",
        billing_type_id: 1,
        start_date: "",
        end_date: "",
        estimated_hours: "",
        hours_logged: "",
        estimated_cost: 0,
        revenue: 0,
        hourly_cap: 0,
        employee: "",
        project: "",
        milestone: "",
        parent_task: "",
        status: "",
        complexity: 0,
      },
      customToolbar: {
        modules: {
          toolbar: [
            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            ["bold", "italic", "underline", "strike", "image"], // toggled buttons
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }], // text direction
            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            ["clean"], // remove formatting button
          ],
        },
      },
      fromTime: false,
      loaded: false,
    };
  },
  components: { DashboardButton },
  props: ["task", "hasProject", "availableProject", "taskName", "taskDesc", "from"],
  computed: {
    employees() {
      return this.$store.getters["employees/employees_simple"];
    },
    projects() {
      return this.$store.getters["projects/projects_simple"];
    },
    taskStatuses() {
      return this.$store.getters["global/task_statuses"];
    },
    milestones() {
      return this.$store.getters["projects/milestones"];
    },
    tasks() {
      return this.$store.getters["projects/tasks"];
    },
    filteredTasks() {
      let tasks = this.tasks.filter((s) => s.id != this.$route.params.id);
      if (this.task && this.task.parent_task) {
        tasks.push(this.task.parent_task);
      }
      return [
        ...new Set(
          tasks.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          })
        ),
      ];
    },
  },
  methods: {
    toUTC,
    optional,
    close() {
      if(this.from) {
        this.$emit('close');
      }
      else {
        return this.$router.go(-1);
      }
    },
    toggleSettlement(id) {
      this.activeSettlement = id;
      this.body.billing_type_id = this.activeSettlement;
    },
    save() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    fetchMilestones() {
      this.body.milestone = "";
      let projectId = this.body && this.body.project.id;
      this.$store.dispatch("projects/getMilestones", projectId);
    },
    submit() {
      let data = {
        name: this.body.name,
        description: this.body.description,
        project_id: this.body.project ? this.body.project.id : this.project.id,
        estimated_hours: this.body.estimated_hours,
        task_status_id: this.body.status ? this.body.status.id : null,
        project_milestone_id: this.body.milestone
          ? this.body.milestone.id
          : null,
        parent_task_id: this.body.parent_task ? this.body.parent_task.id : null,
        employee_id: this.body.employee ? this.body.employee.id : null,
        start_date: this.body.start_date
          ? toUTC(this.body.start_date).format("YYYY-MM-DD")
          : null,
        due_date: this.body.due_date
          ? toUTC(this.body.due_date).format("YYYY-MM-DD")
          : null,
        completed_date: this.body.completed_date
          ? toUTC(this.body.completed_date).format("YYYY-MM-DD")
          : null,
        complexity: this.body.complexity,
      };

      let msgPass = Literals.TASK_CREATE_SUCCESS;
      if (this.task) {
        Object.assign(data, { id: this.task.id });
        msgPass = Literals.TASK_UPDATE_SUCCESS;
      }

      this.$store
        .dispatch("tasks/createUpdate", data)
        .then(({ id: taskId }) => {
          this.$toast.success(msgPass, {
            position: "top-right",
            duration: 3000,
          });
          this.$store.dispatch("tasks/index");

          if (this.taskDesc) {
            // nothing;
          } else if (this.hasProject && this.availableProject != null) {
            let vm = this;
            setTimeout(function() {
              vm.$router.push({name:'projects-show-general', params: { id: this.availableProject.id },});
            }, 1000);
          } else if (
            this.$route.query.from &&
            this.$route.query.from == "customer"
          ) {
            this.$router.push({ name: "tasks-index" });
          } else if (
            data.id ||
            (this.$route.query.from && this.$route.query.from == "time")
          ) {
            this.$router.push({
              name: "task-show-full",
              params: { id: data.id || taskId },
              query:
                this.$route.query.from && this.$route.query.from == "time"
                  ? { trigger: "timer" }
                  : {},
            });
          }
          
          if (this.taskDesc) {
            let taskData = data;
            taskData["id"] = taskId;
            this.$emit("saveTask", data);
          } else {
            this.$router.go(-1);
          }
        })
        .catch((error) => {
          let errorMessage = data.id
            ? Literals.TASK_UPDATE_ERROR
            : Literals.TASK_CREATE_ERROR;
          handleError(this, error, errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatData() {
      if (this.task) {
        Object.assign(this.body, this.task);
        this.body.start_date = this.body.start_date
          ? new Date(this.body.start_date)
          : null;
        this.body.due_date = this.body.due_date
          ? new Date(this.body.due_date)
          : null;
        this.body.completed_date = this.body.completed_date
          ? new Date(this.body.completed_date)
          : null;
        this.body.project = optional(this.task, "project", {});
        this.body.employee = optional(this.task, "employee.user", null)
          ? {
              id: this.task.employee.id,
              name: `${this.task.employee.user.first_name} ${this.task.employee.user.last_name}`,
            }
          : this.employees.find((s) => s.user_id == this.$auth.user().id);
        this.body.milestone = optional(this.task, "milestone", "");
      }

      if (this.hasProject) {
        // use sent project
        this.body.project = {
          id: this.availableProject.id,
          name: this.availableProject.name,
        };
      }

      if (this.$route.query.project_id) {
        this.body.project = this.projects.find(
          (s) => s.id == this.$route.query.project_id
        );
      }
      if (this.$route.query.milestone_id) {
        this.body.milestone = this.milestones.find(
          (s) => s.id == this.$route.query.milestone_id
        );
      }

      this.fromTime = this.$route.query.from == "time";
    },
  },
  mounted() {
    this.$refs["my-modal"].show();
    //since endpoint isn't sending parent task
  },
  created() {
    let projectId = this.task
      ? this.task.project_id
      : this.availableProject
      ? this.availableProject.id
      : this.$route.query.project_id;

    Promise.all([
      this.taskStatuses.length ||
        this.$store.dispatch("global/getTaskStatuses"),
      this.$store.dispatch("employees/index"),
      projectId && this.$store.dispatch("projects/getMilestones", projectId),
      projectId && this.$store.dispatch("projects/getTasks", projectId),
      this.$route.query.cid &&
        this.$store.dispatch("customer/getProjects", this.$route.query.cid),
      (this.body.name = this.taskName ? this.taskName : this.body.name),
      (this.body.description = this.taskDesc
        ? this.taskDesc
        : this.body.description),
    ])
      .finally(() => {
        this.formatData();
        let taskStatus = this.$store.getters["global/task_statuses"];
        this.body.status = taskStatus.find(
          (e) => e.id == 1 && e.name == "Not Started"
        );
      })
      .then(() => {
        this.loaded = true;
      });
  },
};
</script>
