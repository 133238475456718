<template>
  <!-- Modal -->
  <b-modal @hide="close" :no-close-on-backdrop="true" :visible="showScheduleInvoiceModal" id="sendInvoiceModal" ref="my-modal">
    <template v-slot:modal-header>
      <h2 class="pl-3 pt-3 pr-3 f-24">{{'Schedule Invoice' }}</h2>
      <button class="close" @click="close">
        Cancel
        <i class="zmdi zmdi-close-circle pl-2" style="font-size:20px"></i>
      </button>
    </template>
    <div class="d-block pl-3 pb-4">
      <form class="d-form" @submit.prevent="save" v-if="loaded">
        <div class="row">
          <div class="col-sm-12">
            <h6 class="text-bold text-opacity-pale">
              <i class="zmdi zmdi-notifications pr-2"></i> Schedule Invoice
            </h6>
            <div class="row">
              <div class="col-sm-12 col-lg-3 mt-3">
                <h6 class="text-bold text-opacity-pale">Send Invoice by</h6>
                <vc-date-picker
                  :masks="{ input: ['YYYY-MM-DD']}"
                  :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`, placeholder: `Schedule date`}"
                  name="schedule_at"
                  v-model="body.schedule_date"
                  ref="startFrom"
                  data-vv-as="Schedule date"
                />
                <small class="dpicker-icon">
                  <i class="zmdi zmdi-calendar-alt"></i>
                </small>
                <small
                        class="form-text text-danger"
                        v-show="errors.has('schedule_date')"
                >{{errors.first('schedule_date')}}</small>
              </div>
              <div class="col-sm-12 mt-4">
                <ul class="schedule-rate">
                  <li>
                    <input name="rate" @change="setRate" type="radio" value="2" id="rate1">
                    <label for="rate1" class="pl-2">
                      <h6>2 Days</h6>
                    </label>
                  </li>
                  <li>
                    <input name="rate" @change="setRate" type="radio" value="7" id="rate2">
                    <label for="rate2" class="pl-2">
                      <h6>1 Week</h6>
                    </label>
                  </li>
                  <li>
                    <input name="rate" @change="setRate" type="radio" value="14" id="rate3">
                    <label for="rate3" class="pl-2">
                      <h6>2 Weeks</h6>
                    </label>
                  </li>
                  <li>
                    <input name="rate" @change="setRate" type="radio" value="30" id="rate4">
                    <label for="rate4" class="pl-2">
                      <h6>1 Month</h6>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-12 col-lg-5">
            <div class="row">
              <div class="col-sm-12 col-lg-12">
                <h6 class="text-bold text-opacity-pale">
                  From
                  <b>*</b>
                </h6>
                <input
                  type="text"
                  v-validate="'required'"
                  v-model="body.sender_email"
                  name="sender_email"
                  class="form-control auth-input d-input"
                  placeholder="Enter Sender's Email"
                  data-vv-as="Sender's Email"
                >
                <small
                  class="form-text text-danger"
                  v-show="errors.has('sender_email')"
                >{{errors.first('sender_email')}}</small>
              </div>
              <div class="col-sm-12 col-lg-12 mt-4">
                <h6 class="text-bold text-opacity-pale">
                  To
                  <b>*</b>
                </h6>
                <multiselect
                        track-by="email"
                        return="email"
                        v-validate="'required'"
                        label="email"
                        :multiple="true"
                        v-model="body.receivers"
                        :options="contacts"
                        :show-labels="false"
                        data-vv-as="Recipient"
                        @select="empty = true"
                ></multiselect>
                <small  v-show="!empty" class="form-text">Recipients need to be in customer contacts</small>
                <small
                  class="form-text text-danger"
                  v-show="errors.has('receiver_email')"
                >{{errors.first('receiver_email')}}</small>
              </div>

              <div class="col-sm-12 col-lg-12 mt-4">
                <h6 class="text-bold text-opacity-pale">
                  Payment Method
                  <b>*</b>
                </h6>
                <multiselect
                  track-by="id"
                  return="name"
                  v-validate="'required'"
                  label="name"
                  class="flex-multiselect"
                  :multiple="false"
                  v-model="body.payment_method"
                  :options="paymentMethods"
                  :show-labels="false"
                  data-vv-as="Payment Method"
                ></multiselect>

                <small
                  class="form-text text-danger"
                  v-show="errors.has('payment_method_id')"
                >{{errors.first('payment_method_id')}}</small>
              </div>


              <div class="col-sm-12 col-lg-12 mt-4">
                <h6 class="text-bold text-opacity-pale">
                  Subject
                  <b>*</b>
                </h6>
                <input
                  type="text"
                  v-validate="'required'"
                  v-model="body.subject"
                  name="subject"
                  class="form-control auth-input d-input"
                  placeholder="Enter Mail Subject"
                  data-vv-as="Mail Subject"
                >
                <small
                  class="form-text text-danger"
                  v-show="errors.has('subject')"
                >{{errors.first('subject')}}</small>
              </div>
              <div class="col-sm-12 col-lg-12 mt-4 pb-3">
                <h6 class="text-bold text-opacity-pale">
                  Message
                  <b>*</b>
                </h6>
                <quill-editor
                        ref="myQuillEditor"
                        class="mt-1"
                        style="height:200px"
                        v-model="body.message"
                         @ready="_html(body.message)"
                        placeholder="Enter Message"
                        :options="customToolbar"
                />
                <small
                  class="form-text text-danger"
                  v-show="errors.has('message')"
                >{{errors.first('message')}}</small>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-7">
            <div class="email-preview">
              <div class="email-preview__header p-4">
                <h4 class="text-bold text-medium text-primary">{{body.subject ? body.subject : 'Invoice Email Subject'}}</h4>
                <h6
                  class="text-bold text-opacity-pale"
                >From: {{"&lt;"}}{{ body.sender_email ? body.sender_email : 'sender@email.com'}}{{'&gt;'}}</h6>
                <h6
                  class="text-bold text-opacity-pale"
                >To:  {{"&lt;"}}{{ body.receiver ? body.receiver.map(s => s.email).join(',') : 'receiveer@email.com'}}{{'&gt;'}}</h6>
              </div>
              <div class="email-preview__content p-4 pb-5">
                <div class="email-preview__content-wrapper p-4 pb-5" v-html="body.message ? body.message : 'Message body goes here'">

                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <template v-slot:modal-footer>
      <dashboard-button @submit="save" :loading="loading" :text="'Create'"></dashboard-button>
    </template>
  </b-modal>
</template>
<style lang="scss" scoped>
.schedule-rate {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  //   justify-content: space-between;

  li {
    border: 1px solid #d7e1ea;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    height: 40px;
    margin-right: 10px;
    padding-left: 14px;
    padding-right: 14px;
    // align-items: center;
    padding-top: 10px;
    cursor: pointer;
  }
}
</style>

<script>
import DashboardButton from "@/components/Buttons/Dashboard";
import Literals from "@/helpers/dashboard/literals";
import handleError from "@/helpers/error";
import { quillEditor } from "vue-quill-editor";
import {toLocal, toUTC} from "@/helpers/date";
import moment from "moment";

export default {
  data() {
    return {
      body: {
        receivers:[]
      },
      loaded:false,
      loading:false,
      empty: false,
      customToolbar: {
        modules: {}
      }
    };
  },
  components: { DashboardButton, quillEditor },
  props: ["showScheduleInvoiceModal"],
  computed: {
    invoice() {
      return this.$store.getters['invoices/invoice']
    },
    customer() {
      return this.invoice && this.invoice.customer && this.invoice.customer.data ? {
        id:this.invoice.customer.data.id,
        email:this.invoice.customer.data.email
      } : {};
    },
    contacts() {
      return this.$store.getters['customers/contacts'].map(s => {
        return {
          id:s.id,
          email:s.email
        }
      });
    },
      paymentMethods() {
      return (
        this.$store.getters["payments/all_payment_methods"].filter(
          s => s.is_invoice_enabled
        ) || []
      );
    }
  },
  methods: {
    toLocal,
    close() {
      this.$emit("close");
    },
    save() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
      this.loading = true;
      let data = {};
      Object.assign(data, this.body);
      data.schedule_date = data.schedule_date ? toUTC(data.schedule_date).format("YYYY-MM-DD") : null;
      data.recipients = this.body.receivers ? this.body.receivers.map(s => s.id) : [];
      data.id = this.$route.params.id;
      data.template_id = 1;
      data.payment_source_id = this.body.payment_method
        ? this.body.payment_method.id
        : null;

      delete data.receivers;
      delete data.payment_method;

      this.$store.dispatch("invoices/sendInvoice", data)
              .then(s => {
                this.$store.dispatch("invoices/getSingle", this.invoice.id)
                this.$toast.success('invoice scheduled successfully', {
                  position: "top-right",
                  duration: 3000
                });
                this.body = {};
                this.close();
              }).catch(error => {
        let errorMessage = "Failed to schedule invoice for sending";
        this.handleError(this, error, errorMessage);
      })
              .finally(() => {
                this.loading = false;
              });

    },
    setRate(event) {
      let value = event.target.value;
      this.body.schedule_date = new Date(moment().add(value, 'd').format('YYYY-MM-DD'));
    }
  },
  created() {
    this.$store.dispatch("invoices/getSingle", this.$route.params.id).then( (data) => {
     Promise.all([
       this.$store.dispatch("customers/getContacts", data.customer_id),
          this.$store.dispatch("payments/getAllPaymentMethods"),
     ]).then(() => {
      this.body.subject = data.subject;
      this.body.sender_email = this.$auth.user().email
      this.loaded = true;
      // this.body.receivers.push(data && data.customer && data.customer.data ? {
      //   id: data.customer.data.id,
      //   email: data.customer.data.email
      // } : {});
    });
    
  })
  }
};
</script>
