<template>
  <div class="wrapper" id="wrapper">
    <div class="row">
      <div class="col-sm-12 col-lg-6 wrapper-content-left">
        <div class="logo pl-4 mb-4">
          <img src="@/assets/img/leaf.svg">
        </div>
      </div>
      <div class="col-sm-12 col-lg-6 wrapper-content-right">
        <div class="content pb-5">
          <form @submit.prevent="register" id="registerForm">
            <div class="row mt-5 pt-5 pl-5 pr-5">
              <div class="col-sm-12 pt-5 mt-2">
                <div class v-show="!successMsg">
                  <h2>{{Literals.REGISTER_HEADER_CAPTION}}</h2>
                  <small class="text-opacity">{{Literals.REGISTER_SUBHEADER_CAPTION}}</small>
                </div>
              </div>

              <!-- alerts -->
              <div class="col-sm-12 col-lg-10 mt-4" v-show="apiErrors.length > 0">
                <error-alert :message="apiErrors"></error-alert>
              </div>
              <!-- <div class="col-sm-12 col-lg-10 mt-4" v-if="successMsg">
                <success-alert :message="successMsg">
                  <small slot="extras" class="pl-2"></small>
                </success-alert>
              </div>-->
              <div class="col-sm-12 col-lg-10 text-center pt-5" v-show="successMsg">
                <div>
                  <span style="font-size:50px">🎉</span>
                </div>
                <h5 class="text-bold text-opacity-pale">...Almost done!</h5>
                <div>
                  <small class="h7 text-opacity">
                    Thank you for signing up.
                    <br>Please check your email for verification link.
                  </small>
                </div>
                <div class="mt-5 text-left">
                  <button class="auth-btn btn btn-primary" @click="gotoLogin"><i class="zmdi zmdi-chevron-left pr-3"></i>Back to Login</button>
                </div>
              </div>

              <!-- end -->

              <div class="col-sm-12 pt-5" v-show="!successMsg">
                <div class="row auth-form" :class="{blur:!countries.length}">
                  <div class="col-sm-12 col-lg-10">
                    <div class="row">
                      <div class="col-sm-12 col-lg-6 mt-4">
                        <h6>{{Literals.FIRST_NAME_INPUT_CAPTION}} <b>*</b></h6>
                        <input
                          type="text"
                          name="first_name"
                          v-validate="'required'"
                          data-vv-as="first name"
                          v-model="body.first_name"
                          class="form-control auth-input"
                          placeholder="Enter your first name"
                        >
                        <small
                          class="form-text text-danger"
                          v-if="!successMsg"
                          v-show="errors.has('first_name')"
                        >{{errors.first('first_name')}}</small>
                      </div>
                      <div class="col-sm-12 col-lg-6 mt-4">
                        <h6>{{Literals.LAST_NAME_INPUT_CAPTION}} <b>*</b></h6>
                        <input
                          type="text"
                          name="last_name"
                          v-model="body.last_name"
                          data-vv-as="last name"
                          v-validate="'required'"
                          class="form-control auth-input"
                          placeholder="Enter your last name"
                        >
                        <small
                          class="form-text text-danger"
                          v-if="!successMsg"
                          v-show="errors.has('last_name')"
                        >{{errors.first('last_name')}}</small>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-10 mt-4">
                    <h6>{{Literals.EMAIL_INPUT_CAPTION}} <b>*</b></h6>
                    <input
                      type="email"
                      v-model="body.email"
                      v-validate="'required|email'"
                      data-vv-as="email"
                      name="email"
                      class="form-control auth-input"
                      placeholder="Enter your  email address"
                    >
                    <small
                      class="form-text text-danger"
                      v-if="!successMsg"
                      v-show="errors.has('email')"
                    >{{errors.first('email')}}</small>
                  </div>
                  <div class="col-sm-12 col-lg-10 mt-4">
                    <div class="row">
                      <div class="col-sm-12 col-lg-12">
                        <h6>{{Literals.COUNTRY_INPUT_CAPTION}} <b>*</b></h6>
                        <multiselect
                          v-model="body.country"
                          track-by="id"
                          label="name"
                          v-validate="'required'"
                          name="country"
                          data-vv-as="country"
                          :options="countries"
                          :show-labels="false"
                        ></multiselect>
                        <small
                          class="form-text text-danger"
                          v-if="!successMsg"
                          v-show="errors.has('country')"
                        >{{errors.first('country')}}</small>
                      </div>
                      <!-- <div class="col-sm-12 col-lg-6">
                      <h6>Regions</h6>
                      <multiselect v-model="value" :options="['options']"></multiselect>
                      </div>-->
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-10 mt-4">
                    <h6>{{Literals.TELEPHONE_INPUT_CAPTION}} <b>*</b></h6>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span
                          class="input-group-text"
                          id="validationTooltipUsernamePrepend"
                        >{{body && body.country ? body.country.d_code : ''}}</span>
                      </div>
                      <input
                        type="tel"
                        v-model="body.m_phone_number_plain"
                        v-validate="'required|numeric|min:5'"
                        data-vv-as="telephone"
                        name="phone_number"
                        class="form-control auth-input"
                        placeholder="Enter your telephone"
                      >
                    </div>
                    <small
                      class="form-text mt-3 text-danger"
                      v-if="!successMsg"
                      v-show="errors.has('phone_number')"
                    >{{errors.first('phone_number')}}</small>
                  </div>

                  <div class="col-sm-12 col-lg-10 mt-4 pt-3">
                    <h6>{{Literals.COMPANY_INPUT_CAPTION}}</h6>
                    <input
                      type="text"
                      v-model="body.name"
                      name="company_name"
                      class="form-control auth-input"
                      placeholder="Enter your Company Name"
                    >
                  </div>

                  <div class="col-sm-12 col-lg-10 mt-4">
                    <h6>{{Literals.COMPANY_INPUT_ROLE}}</h6>
                    <multiselect
                          v-model="body.job_role"
                          track-by="id"
                          label="name"
                          data-vv-as="job_role"
                          name="job_role"
                          :options="jobroles"
                          :show-labels="false"
                        ></multiselect>
                        <small
                          class="form-text text-danger"
                          v-if="!successMsg"
                          v-show="errors.has('job_role')"
                        >{{errors.first('job_role')}}</small>
                  </div>

                  <div class="col-sm-12 col-lg-10 mt-4">
                    <h6>{{Literals.PASSWORD_INPUT_CAPTION}} <b>*</b></h6>
                    <div>
                      <input
                        v-model="body.password"
                        :type="passwordType"
                        name="password"
                        v-validate="{ required: true, regex: /^(?=.*[0-9])(?=.*[\D])(.+)$/, min: 8 }" 
                        data-vv-as="password"
                        class="form-control auth-input"
                        placeholder="Enter your Password"
                        ref="password"
                      >
                      <small
                        class="form-text text-danger"
                        v-if="!successMsg"
                        v-show="errors.has('password')"
                      >{{errors.first('password')}}</small>
                      <span @click="togglePassword" class="show-password">
                        <i
                          :class="passwordType === 'password' ? 'zmdi zmdi-eye' : 'zmdi zmdi-eye-off'"
                        ></i>
                      </span>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-10 mt-4">
                    <h6>{{Literals.CONFIRM_PASSWORD_INPUT_CAPTION}} <b>*</b></h6>
                    <div>
                      <input
                        :type="passwordType"
                        v-validate="{ required: true, regex: /^(?=.*[0-9])(?=.*[\D])(.+)$/, min: 8, confirmed: 'password' }" 
                        v-model="body.confirm_password"
                        data-vv-as="confirm password"
                        name="password_confirmation"
                        class="form-control auth-input"
                        placeholder="Enter your Password"
                      >
                      <small
                        class="form-text text-danger"
                        v-if="!successMsg"
                        v-show="errors.has('password_confirmation')"
                      >{{errors.first('password_confirmation')}}</small>
                      <span @click="togglePassword" class="show-password">
                        <i
                          :class="passwordType === 'password' ? 'zmdi zmdi-eye' : 'zmdi zmdi-eye-off'"
                        ></i>
                      </span>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-10 mt-4">
                    <Auth-Button :loading="loading" text="Sign up"/>
                  </div>

                  <div class="col-sm-12 col-lg-10 mt--6">
                    <h6>
                      <span class="text-opacity">Already have an account?</span>
                      <router-link :to="{name: 'Login'}" class="pl-1">Sign in</router-link>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
div.input-group .input-group-prepend {
  margin-top: 10px !important;
  height: 40px;

  .input-group-text {
    background: #f7f7f7;
    font-size: 12px;
    letter-spacing: 0.7px;
  }
}
</style>

<script>
import AuthButton from "@/components/Buttons/Auth.vue";
import ErrorAlert from "@/components/Alerts/Error.vue";
import Literals from "@/helpers/auth/literals.js";
import { updatePending } from "../../helpers/axiosIntercept";
import handleError from "../../helpers/error";
import { Validator } from 'vee-validate';

export default {
  title: "Register",
  data() {
    return {
      Literals,
      passwordType: "password",
      value: "",
      body: {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        m_phone_number_plain: "",
        username: null,
        name: "",
        password: "",
        confirm_password: "",
        country: ""
      },
      registered_email: "",
      loading: false,
      successMsg: "",
      apiErrors: []
    };
  },
  components: { AuthButton, ErrorAlert },
  methods: {
    togglePassword() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    register() {
      this.apiErrors = [];
      this.$validator.validateAll().then(valid => {
        if (valid) {
          if (this.body.first_name.match(/^[0-9]+$/) != null) {
            return this.errors.add({
              field: "first_name",
              msg: "First name cannot be all numbers"
            });
          } else if (this.body.last_name.match(/^[0-9]+$/) != null) {
            return this.errors.add({
              field: "last_name",
              msg: "Last name cannot be all numbers"
            });
          }
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
      let data = {};
      Object.assign(data, this.body);
      data.country_id =
        this.body.country && this.body.country.id
          ? this.body.country.id
          : this.body.country;
      data.phone_number = data.m_phone_number_plain;
      data.country_code = this.body.country.d_code;
      data.job_role_id = this.body.job_role != null ? this.body.job_role.id : '';
      data.role_id = 1;
      delete data.country;
      delete data.job_role;
      this.$auth
        .register({
          data: data,
          //TODO: enable if autologin becomes supported by API
          staySignedIn: false,
          autoLogin: false,
          fetchUser: false,
          success: ({ response }) => {
            if (response && response.data) {
              if (response.data.status_code === 422) {
                handleError(this, response, "Failed to Register");
              } else if (response.data.status_code === 403) {
                // registration succeeded, but login failed
                // because email has not been verified
                this.body = {};
                document.getElementById("registerForm").reset();
                this.successMsg = this.Literals.ACCOUNT_CREATE_SUCCESS;
              }
            } else {
              this.registered_email = this.body.email;
              this.body = {};
              document.getElementById("registerForm").reset();
              this.successMsg = this.Literals.ACCOUNT_CREATE_SUCCESS;
            }

            this.loading = false;
            document.getElementById("app").scrollIntoView();
          },
          error: ({ response }) => {
            this.loading = false;
            // let's grab api validation and push to an error array
            // so they can be passed to the error component for rendering
            if (response.data.status_code === 422) {
              handleError(this, response, "Failed to Register");
            } else if (response.data.status_code === 403) {
              // registration succeeded, but login failed
              // because email has not been verified
              this.body = {};
              document.getElementById("registerForm").reset();
              this.successMsg = this.Literals.ACCOUNT_CREATE_SUCCESS;
            }

            this.loading = false;
            document.getElementById("wrapper").scrollIntoView();
          },
          //TODO: Enable if user can login immediately
          redirect: false
          //redirect: { name: "Login", params:{msg:this.Literals.ACCOUNT_CREATE_SUCCESS} }
        })
        .then(() => {
          updatePending(this.$registerUrl);
        });
    },
    gotoLogin() {
      this.$router.push({name:'Login'});
    }
  },
  mounted() {
    const dict =  {
      custom : {
        password_confirmation: {
          confirmed: "Please make sure the passwords match.",
          regex: "Please choose a password with at least 8 characters, 1 uppercase character and 1 digit."
        },
        password : {
          regex: "Please choose a password with at least 8 characters, 1 uppercase character and 1 digit."
        }
      }
    }
    this.$validator.localize("en", dict);
  },
  computed: {
    countries() {
      return this.$store.getters["global/countries"];
    },
    jobroles() {
      return this.$store.getters["global/job_roles"];
    }
  },
  created() {
    Promise.all([
      this.jobroles.length || this.$store.dispatch("global/getJobRoles"),
      this.countries.length || this.$store.dispatch("global/getCountries"),
    ]).then((_) => true);

  }
};
</script>
