<template>
  <!-- Modal -->
  <b-modal
    @hide="close"
    :no-close-on-backdrop="true"
    :visible="showSendInvoiceModal"
    id="sendInvoiceModal"
    ref="my-modal"
  >
    <template v-slot:modal-header>
      <h2 class="pl-3 pt-3 pr-3 f-24">{{'Send Invoice' }}</h2>
      <button class="close" @click="close">
        Cancel
        <i class="zmdi zmdi-close-circle pl-2" style="font-size:20px"></i>
      </button>
    </template>
    <div class="d-block pl-3 pb-4">
      <div class="row">
        <div class="col-sm-12 col-lg-5">
          <form class="d-form" @submit.prevent="save" v-if="loaded">
            <div class="row">
              <div class="col-sm-12 col-lg-12">
                <h6 class="text-bold text-opacity-pale">
                  From
                  <b>*</b>
                </h6>
                <input
                  type="email"
                  v-validate="'required|email'"
                  v-model="body.sender_email"
                  name="sender_email"
                  class="form-control auth-input d-input"
                  placeholder="Enter Sender's Email"
                  data-vv-as="Sender's Email"
                >
                <small
                  class="form-text text-danger"
                  v-show="errors.has('sender_email')"
                >{{errors.first('sender_email')}}</small>
              </div>
              <div class="col-sm-12 col-lg-12 mt-4">
                <div class="d-flex flex-row pb-1 justify-content-between align-items-center">
                  <div>
                    <h6 class="text-bold text-opacity-pale">
                      To
                      <b>*</b>
                    </h6>
                  </div>
                  <div class="text-bold text-opacity-pale">
                    <router-link
                      style="color:#3f92cc !important; text-decoration:none !important"
                      :to="{name: 'customers-contacts-new',params: {id: this.invoice.customer_id}, query: {from: 'invoice',invoice_id: this.invoice.id}}"
                    >
                      <h6 class="h7">
                        <span class="pl-2 pr-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill="#3f92cc"
                              d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 4c-.552 0-1 .448-1 1v2H5c-.552 0-1 .448-1 1s.448 1 1 1h2v2c0 .552.448 1 1 1s1-.448 1-1V9h2c.552 0 1-.448 1-1s-.448-1-1-1H9V5c0-.552-.448-1-1-1z"
                            ></path>
                          </svg>
                        </span>
                        Create Contact
                      </h6>
                    </router-link>
                  </div>
                </div>
                <multiselect
                  track-by="id"
                  return="email"
                  v-validate="'required'"
                  label="email"
                  name="receiver_email"
                  class="flex-multiselect"
                  :multiple="true"
                  v-model="body.receivers"
                  :options="contacts"
                  :show-labels="false"
                  data-vv-as="Recipient"
                  @select="empty = true"
                ></multiselect>
                <small v-show="!empty" class="form-text">Recipients need to be in customer contacts</small>
                <small
                  class="form-text text-danger"
                  v-show="errors.has('receiver_email')"
                >{{errors.first('receiver_email')}}</small>
              </div>

              <div class="col-sm-12 col-lg-12 mt-4">
                <h6 class="text-bold text-opacity-pale">
                  Payment Method
                  <b>*</b>
                </h6>
                <multiselect
                  track-by="id"
                  return="name"
                  v-validate="'required'"
                  label="name"
                  class="flex-multiselect"
                  :multiple="false"
                  v-model="body.payment_method"
                  :options="paymentMethods"
                  :show-labels="false"
                  name="payment_method"
                  data-vv-as="Payment Method"
                ></multiselect>

                <small
                  class="form-text text-danger"
                  v-show="errors.has('payment_method')"
                >{{errors.first('payment_method')}}</small>
                <small
                  class="form-text text-danger"
                  v-show="errors.has('payment_method_id')"
                >{{errors.first('payment_method_id')}}</small>
              </div>

              <div class="col-sm-12 col-lg-12 mt-4">
                <h6 class="text-bold text-opacity-pale">
                  Subject
                  <b>*</b>
                </h6>
                <input
                  type="text"
                  v-validate="'required'"
                  v-model="body.subject"
                  name="subject"
                  class="form-control auth-input d-input"
                  placeholder="Enter Mail Subject"
                  data-vv-as="Mail Subject"
                >
                <small
                  class="form-text text-danger"
                  v-show="errors.has('subject')"
                >{{errors.first('subject')}}</small>
              </div>
              <div class="col-sm-12 col-lg-12 mt-4 pb-3">
                <h6 class="text-bold text-opacity-pale">
                  Message
                  <b>*</b>
                </h6>
                <quill-editor
                  ref="myQuillEditor"
                  class="mt-1"
                  v-validate="'required'"
                  name="message"
                  style="height:200px"
                  v-model="body.message"
                   @ready="_html(body.message)"
                  placeholder="Enter Message"
                  :options="customToolbar"
                />
                <small
                  class="form-text text-danger"
                  v-show="errors.has('message')"
                >{{errors.first('message')}}</small>
              </div>
            </div>
          </form>
        </div>
        <div class="col-sm-12 col-lg-7">
          <div class="email-preview">
            <div class="email-preview__header p-4">
              <h4 class="text-bold text-medium text-primary">{{body.subject || 'Mail Subject'}}</h4>
              <h6
                class="text-bold text-opacity-pale"
              >From: {{"&lt;"}}{{ body.sender_email || 'sender@email.com'}}{{'&gt;'}}</h6>
              <h6
                class="text-bold text-opacity-pale"
              >To: {{"&lt;"}}{{ body.receivers ? body.receivers.map(s => s.email).join(', ') : 'receiver@email.cok'}}{{'&gt;'}}</h6>
            </div>
            <div class="email-preview__content p-4 pb-5">
              <div
                class="email-preview__content-wrapper p-4 pb-5"
                v-html="body.message || 'Type your message here'"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:modal-footer>
      <dashboard-button @submit="save" :loading="loading" :text="'Send'"></dashboard-button>
    </template>
  </b-modal>
</template>

<script>
import DashboardButton from "@/components/Buttons/Dashboard";
import { quillEditor } from "vue-quill-editor";
import { toUTC } from "@/helpers/date";
import handleError from "@/helpers/error";

export default {
  data() {
    return {
      body: {
        receivers: [],
        subject: "",
        message: ""
      },
      loaded: false,
      loading: false,
      empty: false,
      customToolbar: {
        modules: {}
      }
    };
  },
  components: { DashboardButton, quillEditor },
  props: ["showSendInvoiceModal"],
  computed: {
    invoice() {
      return this.$store.getters["invoices/invoice"];
    },
    customer() {
      return this.invoice && this.invoice.customer && this.invoice.customer.data
        ? {
            id: this.invoice.customer.data.id,
            email: this.invoice.customer.data.email
          }
        : {};
    },
    contacts() {
      return this.$store.getters["customers/contacts"].map(s => {
        return {
          id: s.id,
          email: s.email
        };
      });
    },
    paymentMethods() {
      return (
        this.$store.getters["payments/all_payment_methods"].filter(
          s => s.is_invoice_enabled
        ) || []
      );
    }
  },
  methods: {
    toUTC,
    close() {
      this.$emit("close");
    },
    save() {
      this.$validator.validateAll().then(valid => {
        
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
      this.loading = true;
      let data = {};
      Object.assign(data, this.body);
      data.recipients = this.body.receivers
        ? this.body.receivers.map(s => s.id)
        : [];
      data.payment_source_id = this.body.payment_method
        ? this.body.payment_method.id
        : null;
      data.id = this.$route.params.id;
      data.template_id = 1;

      delete data.receivers;
      delete data.payment_method;

      this.$store
        .dispatch("invoices/sendInvoice", data)
        .then(s => {
          this.$store.dispatch("invoices/getSingle", this.invoice.id);
          this.$toast.success('Invoice was sent successfully.', {
            position: "top-right",
            duration: 3000
          });
          this.body = {};
          this.close();
        })
        .catch(error => {
          let errorMessage = "Failed to send invoice";
          handleError(this, error, errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  created() {
    Promise.all([
      this.$store.dispatch("invoices/getSingle", this.$route.params.id),
      this.$store.dispatch("global/getPaymentSources"),
      this.$store.dispatch("payments/getAllPaymentMethods")
    ]).finally(() => {
      this.$store
        .dispatch("customers/getContacts", this.invoice.customer_id)
        .then(() => {
          this.body.subject = this.invoice.subject;
          this.loaded = true;
          if (
            this.$route &&
            this.$route.query &&
            this.$route.query.contact_id
          ) {
            let contacts = this.contacts.filter(
              s => s.id == this.$route.query.contact_id
            );
            this.body.receivers = contacts;
          }
          // this.body.receivers.push(data && data.customer && data.customer.data ? {
          //   id: data.customer.data.id,
          //   email: data.customer.data.email
          // } : {});
        });
    });

    this.body.sender_email = this.$auth.user().email;
  }
};
</script>
