<template>
  <!-- Modal -->
  <b-modal @hide="close" :no-close-on-backdrop="true" ref="my-modal">
    <template v-slot:modal-header>
      <h2 class="pl-3 pt-3 pr-3 f-24">
        {{ contact && contact.id ? "Edit Contact" : "Create New Contact" }}
      </h2>
      <button class="close" @click="close">
        Cancel
        <i class="zmdi zmdi-close-circle pl-2" style="font-size: 20px"></i>
      </button>
    </template>
    <div class="d-block pl-3 pb-1">
      <form class="d-form" @submit.prevent="save">
        <div class="row">
          <div class="col-sm-6 col-lg-6">
            <h6 class="text-bold text-opacity-pale">First Name</h6>
            <input
              type="text"
              v-model="fullBody.first_name"
              name="first_name"
              class="form-control auth-input d-input"
              placeholder="Enter Contact's name"
              data-vv-as="Contact's name"
            />
            <small
              class="form-text text-danger"
              v-show="errors.has('first_name')"
              >{{ errors.first("first_name") }}</small
            >
          </div>
          <div class="col-sm-6 col-lg-6">
            <h6 class="text-bold text-opacity-pale">
              Last Name
              <b>*</b>
            </h6>
            <input
              type="text"
              v-validate="'required'"
              v-model="fullBody.last_name"
              name="last_name"
              class="form-control auth-input d-input"
              placeholder="Enter Contact's last name"
              data-vv-as="Contact's last name"
            />
            <small
              class="form-text text-danger"
              v-show="errors.has('last_name')"
              >{{ errors.first("last_name") }}</small
            >
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-12 col-lg-6">
            <h6 class="text-bold text-opacity-pale">Position/Title</h6>
            <multiselect
              track-by="id"
              label="name"
              name="position"
              v-model="fullBody.position"
              :options="roles"
              @tag="createNewRole"
              :taggable="true"
              tagPlaceholder
              :show-labels="false"
              :preserve-search="true"
              :multiple="false"
              data-vv-as="Contact Position"
              placeholder="Select Role or Create new role"
            ></multiselect>
            <small
              class="form-text text-danger"
              v-show="errors.has('position')"
              >{{ errors.first("position") }}</small
            >
          </div>
          <div class="col-sm-12 col-lg-6">
            <h6 class="text-bold text-opacity-pale">Email Address <b>*</b></h6>
            <input
              type="email"
              v-model="fullBody.email"
              v-validate="'required'"
              name="email"
              class="form-control auth-input d-input"
              placeholder="Enter Contact's Email Address"
              data-vv-as="Contact's Email"
            />
            <small class="form-text text-danger" v-show="errors.has('email')">{{
              errors.first("email")
            }}</small>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-12 col-lg-6">
            <h6 class="text-bold text-opacity-pale">Mobile Phone No.</h6>
            <div class="row">
              <div class="col-lg-12 d-contact">
                <span>
                  <multiselect
                    v-model="fullBody.country"
                    class="mobile_dropdown"
                    :custom-label="countriesDialCode"
                    track-by="id"
                    return="id"
                    label="d_code"
                    name="country_id"
                    placeholder="Dial No."
                    :options="countries"
                    :show-labels="false"
                  >
                    <template slot="option" slot-scope="props">
                      <span class="text-opacity"
                        >({{ props.option.d_code}})</span
                      >
                      {{ props.option.name }}
                    </template>
                    <template slot="singleLabel" slot-scope="props">{{
                      props.option.d_code
                    }}</template>
                  </multiselect>
                </span>
                <span>
                  <input
                    type="tel"
                    v-model="fullBody.mobile_number"
                    v-validate="'min:9|max:11'"
                    name="mobile_phone"
                    class="form-control auth-input d-input"
                    placeholder="Enter Contact's mobile number"
                    data-vv-as="Contact's mobile number"
                  />
                </span>
              </div>
            </div>
            <small
              class="form-text text-danger"
              v-show="errors.has('mobile_number')"
              >{{ errors.first("mobile_number") }}</small
            >
          </div>

          <div class="col-sm-12 col-lg-6">
            <h6 class="text-bold text-opacity-pale">Office Phone Number</h6>
            <div class="row">
              <div class="col-lg-12 d-contact">
                <span>
                  <multiselect
                    v-model="fullBody.ocountry"
                    track-by="id"
                    class="mobile_dropdown"
                    return="id"
                    :custom-label="countriesDialCode"
                    label="d_code"
                    name="country"
                    placeholder="Dial No."
                    :options="countries"
                    :show-labels="false"
                    data-vv-as="Dial Code"
                  >
                    <template slot="option" slot-scope="props">
                      <span class="text-opacity"
                        >({{ props.option.d_code }})</span
                      >
                      {{ props.option.name }}
                    </template>
                    <template slot="singleLabel" slot-scope="props">{{
                      props.option.d_code
                    }}</template>
                  </multiselect>
                </span>
                <span>
                  <input
                    type="tel"
                    v-model="fullBody.phone_number"
                    v-validate="'min:9|max:11'"
                    name="office_phone"
                    class="form-control auth-input d-input"
                    placeholder="Enter Contact's office phone number"
                    data-vv-as="Contact's Office phone no"
                  />
                </span>
              </div>
            </div>
            <small
              class="form-text text-danger"
              v-show="errors.has('d_code')"
              >{{ errors.first("d_code") }}</small
            >
            <small
              class="form-text text-danger"
              v-show="errors.has('phone_number')"
              >{{ errors.first("phone_number") }}</small
            >
          </div>
        </div>
        <div class="row mt-4">
          <!-- <div class="col-sm-12 col-lg-6">
            <h6 class="text-bold text-opacity-pale">Mailing Address</h6>
            <input
              type="text"
              v-model="fullBody.mailing_address"
              name="mailing_address"
              class="form-control auth-input d-input"
              placeholder="Enter Contact's Mailing Address"
              data-vv-as="Contact's Mailing Address"
            >
            <small
              class="form-text text-danger"
              v-show="errors.has('mailing_address')"
            >{{errors.first('mailing_address')}}</small>
          </div>-->

          <div class="col-sm-12 col-lg-6">
            <h6 class="text-bold text-opacity-pale">Set as Primary Contact?</h6>
            <div class="input-group">
              <div id="radioBtn" style="width: 100%" class="btn-group">
                <a
                  :class="
                    fullBody.is_primary === 1
                      ? 'btn btn-radio btn-sm active'
                      : 'btn btn-radio btn-sm'
                  "
                  @click="toggleSetPrimary(1)"
                  >Yes</a
                >
                <a
                  :class="
                    fullBody.is_primary === 0
                      ? 'btn btn-radio btn-sm active'
                      : 'btn btn-radio btn-sm'
                  "
                  @click="toggleSetPrimary(0)"
                  >No</a
                >
              </div>
              <input
                type="hidden"
                v-model="fullBody.is_primary"
                name="fun"
                id="fun"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
    <template v-slot:modal-footer>
      <dashboard-button
        @submit="save"
        :loading="loading"
        :text="contact && contact.id ? 'Save' : 'Create'"
      ></dashboard-button>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped>
.d-contact {
  display: grid;
  grid-template-columns: 27% 72%;
  grid-gap: 1%;

  input {
    margin-top: 0px;
  }
}
</style>

<script>
import DashboardButton from "@/components/Buttons/Dashboard";
import Literals from "@/helpers/dashboard/literals";
import handleError from "@/helpers/error";
import randomGenerator from "@/helpers/random";
import { setTimeout } from "timers";

export default {
  data() {
    return {
      Literals,
      activeSettlement: 1,
      loading: false,
      is_primary: 0,
      fullBody: {
        is_primary: 0,
        country: "",
        ocountry: "",
        position: "",
      },
    };
  },
  components: { DashboardButton },
  props: ["contact", "customer"],
  computed: {
    countries() {
      return this.$store.getters["global/countries"];
    },
    roles() {
      return this.$store.getters["global/roles"];
    },
  },
  methods: {
    handleError,
    countriesDialCode({ d_code, name }) {
      return `(+${d_code})  ${name}`;
    },

    createNewRole(data) {
      this.$store.dispatch("global/createRole", { name: data }).then((s) => {
        this.fullBody.position = s;
        this.$store.dispatch("global/getRoles");
      });
    },

    close() {
      return this.$router.go(-1);
    },
    toggleSetPrimary(state) {
      this.fullBody.is_primary = state;
    },
    toggleSettlement(id) {
      this.activeSettlement = id;
      this.fullBody.billing_type_id = this.activeSettlement;
    },
    save() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          if (this.fullBody.first_name.match(/^[0-9]+$/) != null) {
            return this.errors.add({
              field: "first_name",
              msg: "First name cannot be all numbers",
            });
          } else if (this.fullBody.last_name.match(/^[0-9]+$/) != null) {
            return this.errors.add({
              field: "last_name",
              msg: "Last name cannot be all numbers",
            });
          }
          this.loading = true;
          this.submit();
        }
      });
    },
    appendContact(contact) {
      let contacts = this.$store.getters["customers/contacts"];
      let contactIndex = this.findIndexByKeyValue(contacts, "id", contact.id);
      if (!contactIndex) {
        contacts.push(contact);
      } else {
        contacts[contactIndex] = contact;
      }
      this.$store.commit("customers/SET_CONTACT", contact);
      this.$store.commit("customers/SET_CONTACTS", contacts);
    },
    findIndexByKeyValue(arraytosearch, key, valuetosearch) {
      for (var i = 0; i < arraytosearch.length; i++) {
        if (arraytosearch[i][key] == valuetosearch) {
          return i;
        }
      }
      return null;
    },
    submit() {
      let data = {};
      Object.assign(data, this.fullBody);

      data.country_code = data.country ? data.country.d_code : null;
      data.ocountry_code = data.ocountry ? data.ocountry.d_code : null;
      data.role_id = data.position ? data.position.id : null;
      data.customer_id = this.customer.id;
      data.organization_id = this.customer.organization_id;
      data.position = data.position ? data.position.name : null;

      delete data.country;
      delete data.ocountry;

      let successMsg = data.id
        ? Literals.CONTACTS_UPDATE_SUCCESS
        : Literals.CONTACTS_CREATE_SUCCESS;

      this.$store
        .dispatch("customers/createUpdateContact", data)
        .then(async (contact) => {
          if (data.is_primary === 1) {
            await this.setPrimary(contact.id);
          }

          this.$toast.success(successMsg, {
            position: "top-right",
            duration: 3000,
          });
          this.loading = false;

          this.$emit("update", this.fullBody);

          this.$store.dispatch("customers/getContacts", data.customer_id);
          if (this.$route && this.$route.query && this.$route.query.from) {
            this.$router.push({
              name: "invoices-send",
              params: { id: this.$route.query.invoice_id },
              query: { contact_id: contact.id },
            });
          } else {
            this.$router.push({
              name: "customers-show-tasks",
              params: { id: data.customer_id },
            });
          }
        })
        .catch((error) => {
          let errorMessage = `Failed to ${
            data.id ? "Update" : "Create"
          } Contact`;
          this.handleError(this, error, errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setPrimary(id) {
      this.$store
        .dispatch("customers/createUpdate", {
          id: this.customer.id,
          primary_contact_id: id,
        })
        .then((data) => {
          let customer = this.$store.getters["customers/customer"];
          this.customer.primary_contact_id = customer.primary_contact_id = id;
          this.$store.commit("customers/SET_CUSTOMER", customer);
        });
    },
    formatData() {
      if (this.contact) {
        Object.assign(this.fullBody, this.contact);
      }
      this.fullBody.is_primary =
        this.customer && this.customer.primary_contact_id === this.fullBody.id
          ? 1
          : 0;

      if (this.countries.length) {
        this.fullBody.country = this.fullBody.ocountry = this.countries.filter(
          (s) => s.d_code == this.fullBody.country_code
        )[0];
      } else {
        this.$store.dispatch("global/getCountries").then(() => {
          this.fullBody.country = this.fullBody.ocountry =
            this.countries.filter(
              (s) => s.d_code == this.fullBody.country_code
            )[0];
        });
      }
    },
  },
  mounted() {
    this.$refs["my-modal"].show();
  },
  created() {
    Promise.all([
      this.countries.length || this.$store.dispatch("global/getCountries"),
      this.roles.length || this.$store.dispatch("global/getRoles"),
    ]).then((s) => this.formatData());
  },
};
</script>
