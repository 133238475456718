<template>
  <!-- Modal -->
  <div>
    <b-modal
      @hide="close"
      :no-close-on-backdrop="true"
      :visible="showMultipleTime"
      ref="my-modal"
      id="sendInvoiceModal"
      class="invoice-modal"
    >
      <template v-slot:modal-header>
        <h2 class="pl-3 pt-3 pr-3 f-24">{{ "Log Time" }}</h2>
        <button class="close" @click="close">
          Cancel
          <i class="zmdi zmdi-close-circle pl-2" style="font-size:20px"></i>
        </button>
      </template>
      <div class="d-block pl-3">
        <div class="row pl-3">
          <div
            class="col-sm-4 task-field p-3 mr-2 mb-2"
            v-for="(entry, index) in timesheets"
            :key="index"
          >
            <div
              class="text-right d-flex flex-row align-items-center justify-content-between"
            >
              <h6 class="h6 text-bold text-opacity-pale">
                Time Sheet {{ index + 1 }}
              </h6>
              <i class="zmdi zmdi-close-circle-o" style="font-size:20px"></i>
            </div>
            <div class="mt-3">
              <form class="d-form">
                <div>
                  <h6 class="text-bold text-opacity">Project worked on</h6>
                  <multiselect
                    track-by="id"
                    label="name"
                    class="no-caret-select"
                    :name="`project_${index}`"
                    v-validate="'required'"
                    :options="projects"
                    v-model="entry.project"
                    :show-labels="false"
                    placeholder="Select Project"
                    data-vv-as="Project"
                  >
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <i
                            class="zmdi zmdi-chevron-down pt-1"
                            style="font-size:20px"
                          ></i>
                        </div>
                      </div>
                    </template>
                  </multiselect>
                  <small
                        class="form-text text-danger"
                        v-show="errors.has(`project_${index}`)"
                      >{{ errors.first(`project_${index}`) }}</small>
                </div>
                <div class="mt-2">
                  <h6 class="text-bold text-opacity">Task worked on</h6>
                  <multiselect
                    track-by="id"
                    label="name"
                    class="no-caret-select"
                    :name="`task_${index}`"
                    :options="filteredTasks(entry.project)"
                    v-model="entry.task"
                    v-validate="'required'"
                    :show-labels="false"
                    placeholder="Select Task"
                    data-vv-as="Task"
                  >
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <i
                            class="zmdi zmdi-chevron-down pt-1"
                            style="font-size:20px"
                          ></i>
                        </div>
                      </div>
                    </template>
                  </multiselect>
                  <small
                        class="form-text text-danger"
                        v-show="errors.has(`task_${index}`)"
                      >{{ errors.first(`task_${index}`) }}</small>
                </div>
                <div class="mt-2">
                  <h6 class="text-bold text-opacity">Total Hours</h6>
                  <input
                    type="number"
                    v-validate="'required|min:1|max:100'"
                    :name="`hours_${index}`"
                    v-model="entry.hours"
                    class="form-control auth-input d-input"
                    placeholder="Enter number of hours"
                    data-vv-as="Task Hours"
                  />
                  <small
                        class="form-text text-danger"
                        v-show="errors.has(`hours_${index}`)"
                      >{{ errors.first(`hours_${index}`) }}</small>
                </div>
                <div class="mt-2">
                  <h6 class="text-bold text-opacity">About time entry</h6>
                  <textarea
                    class="form-control d-input"
                    :name="`note_${index}`"
                    v-model="entry.note"
                    style="background:white !important"
                    placeholder="Any additional note?"
                    data-vv-as="Additonal Note"
                  ></textarea>
                  <small
                        class="form-text text-danger"
                        v-show="errors.has(`note_${index}`)"
                      >{{ errors.first(`note_${index}`) }}</small>
                </div>
                <div class="mt-2">
                  <h6 class="text-bold text-opacity">Time log date</h6>
                  <vc-date-picker
                    :masks="{ input: ['YYYY-MM-DD'] }"
                    :input-props="{
                      class: `d-input no-margin-top auth-input pl-3`,
                      style: `margin-top:0px !important`,
                      placeholder: `Start date`,
                    }"
                    :name="`start_date_${index}`"
                    v-model="entry.start_time"
                    v-validate="'required'"
                    ref="startFrom"
                    data-vv-as="Start date"
                  />
                  <small
                        class="form-text text-danger"
                        v-show="errors.has(`start_date_${index}`)"
                      >{{ errors.first(`start_date_${index}`) }}</small>
                </div>
              </form>
            </div>
          </div>
          <!-- <div class="col-sm-4 task-field p-3 mr-2" style="height:min-content">
            <div class="text-right d-flex flex-row align-items-center justify-content-between">
              <h6 class="h6 text-bold text-opacity-pale">Time Sheet 1</h6>
              <i class="zmdi zmdi-close-circle-o" style="font-size:20px"></i>
            </div>
            <div class="mt-3">
              <form class="d-form">
                <div>
                  <multiselect
                    track-by="id"
                    label="name"
                    class="no-caret-select"
                    name="task"
                    :options="projects"
                    :show-labels="false"
                    placeholder="Select Project"
                  >
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <i class="zmdi zmdi-chevron-down pt-1" style="font-size:20px"></i>
                        </div>
                      </div>
                    </template>
                  </multiselect>
                </div>
                <div class="mt-2">
                  <multiselect
                    track-by="id"
                    label="name"
                    class="no-caret-select"
                    name="task"
                    :options="tasks"
                    :show-labels="false"
                    placeholder="Select Task"
                  >
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <i class="zmdi zmdi-chevron-down pt-1" style="font-size:20px"></i>
                        </div>
                      </div>
                    </template>
                  </multiselect>
                </div>
                <div class="mt-2">
                  <input
                    type="text"
                    v-validate="'required|min:1|max:100'"
                    name="name"
                    class="form-control auth-input d-input"
                    placeholder="Enter number of hourss"
                    data-vv-as="Task Hours"
                  >
                </div>
                <div class="mt-2">
                  <textarea
                    class="form-control d-input"
                    name="description"
                    style="background:white !important"
                    placeholder="Any additional note?"
                    data-vv-as="Additonal Note"
                  ></textarea>
                </div>
                <div class="mt-2">
                  <vc-date-picker
                    :masks="{ input: ['YYYY-MM-DD']}"
                    :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important`,placeholder: `Start date`}"
                    name="start_date"
                    ref="startFrom"
                    data-vv-as="Project Start date"
                  />
                </div>
              </form>
            </div>
          </div>-->
          <div
            class="col-sm-2 p-4 new-entry ml-2"
            @click="addEntry"
            style="background:#F7F7F7;border:1px dashed grey;border-radius:5px;height:max-content"
          >
            <div class="mt-4 text-center">
              <i class="zmdi zmdi-plus-circle-o" style="font-size:25px"></i>
            </div>
            <div class="mt-3">
              <h6 class="h6 text-center text-opacity-pale text-bold">
                Add New entry
              </h6>
            </div>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer>
        <div>
          <button
            class="btn btn-primary"
            @click="submit"
            :disabled="loading"
          >
            Submit
          </button>
          <button
            class="btn btn-primary text-main"
            @click="close"
            style="background: transparent"
          >
            Cancel
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
.new-entry {
  cursor: pointer;
}
.task-field {
  background: white;
  border: 2px solid #f7f7f7;
  border-radius: 5px;
}
.new-entry:hover {
  background: #daf1ff !important;
}
</style>

<script>
import handleError from "@/helpers/error";
import { toUTC } from "@/helpers/date";
import moment from "moment";
import { optional } from "@/helpers/random";

export default {
  data() {
    return {
      loading: false,
      timesheets: [
        {
          task: "",
          project: "",
          hours: "",
          note: "",
          start_time: "",
        },
      ],
    };
  },
  props: ["showMultipleTime", "tasks", "projects"],
  methods: {
    close() {
      this.$emit("cancelMultiple", false);
    },
    filteredTasks(project) {
      return project ? this.tasks.filter(s => s.project_id == project.id) : this.tasks;
    },
    addEntry() {
      this.timesheets.push({
        task: "",
        project: "",
        hours: "",
        note: "",
        start_time: "",
      });
    },
    submit() {
      this.errors.clear();
      this.$validator.validateAll().then((valid) => {
      
        if (valid) {
          this.submitTime();
        }
      });
    },
    submitTime() {
      this.loading = true;
      let queues = [];
      let emp = optional(this.$auth.user(), 'employee.data', this.employees.find(s => s.user_id == this.$auth.user().id));
      const vm = this;

      this.timesheets.forEach((entry) => {
        //dispatch
        let data = {
          id: entry.id,
          is_billable: entry.is_billable ? 1 : 0,
          project_id: entry.project ? entry.project.id : entry.task.project_id,
          task_id: entry.task && entry.task.id,
          employee_id: emp ? emp.id : null,
          start_time: toUTC(entry.start_time).format("YYYY-MM-DD HH:mm"),
          hours: entry.hours,
          note: entry.node,
        };

        data.end_time = toUTC(moment(data.start_time).add(data.hours, "hours")).format("YYYY-MM-DD HH:mm");

        queues.push(vm.$store.dispatch("tasks/createUpdateTimeLog", data));
      });
      Promise.all(queues).then((...s) => {
        
        vm.$toast
          .success(`(${s[0].length}) Time Entries Created`, {
            position: "top-right",
            duration: 3000,
          })
      })
      .catch((err) => {
            handleError(vm, err);
        })
        .finally(() => {
            vm.loading = false;
        });
    },
  },
 
  computed: {
   employees() {
      return this.$store.getters["employees/employees_simple"];
    },
  },
  mounted() {
 
  }
};
</script>
