<template>
  <div class="mt-1 row" :class="{blur:!user.id}">
    <div class="col-sm-12 col-lg-3">
      <div class="col-sm-12">
        <div class="card w-70">
          <div class="card-header no-right-padding-card">
            <span class="pt-2">Personal info</span>
            <router-link :to="{ name: 'users-edit', params: { id: user.id } }">
              <span class="btn-icon-sm float-right">
                <svg
                  width="20"
                  height="20"
                  fill="#72809D"
                  viewBox="0 0 30 30"
                  xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                >
                  <path
                    d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
                  ></path>
                </svg>
              </span>
            </router-link>
            <router-link v-if="optional($auth.user(), 'org_role.role_id') == 1" :to="{ name: 'users-edit', params: { id: user.id } }">
              <span class="btn-icon-sm float-right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#72809D"
                  viewBox="0 0 30 30"
                >
                  <path
                    d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"
                  ></path>
                </svg>
              </span>
            </router-link>
          </div>
          <div class="card-content p-3">
            <div class="row">
              <div class="col-sm-6">First Name:</div>
              <div class="col-sm-6 float-right text-right">
                {{ user.first_name }}
              </div>
            </div>
            <!--            <div class="row mt-3">-->
            <!--              <div class="col-sm-6">Middlename:</div>-->
            <!--              <div class="col-sm-6 float-right text-right">{{'Igor'}}</div>-->
            <!--            </div>-->
            <div class="row mt-3">
              <div class="col-sm-6">Last name:</div>
              <div class="col-sm-6 float-right text-right">
                {{ user.last_name }}
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-3">Email:</div>
              <div class="col-sm-9 float-right text-right">
                {{ user.email }}
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-6">Phone:</div>
              <div class="col-sm-6 float-right text-right">
                {{
                  user.country_code && user.phone_number
                    ? user.full_phone_number
                    : ""
                }}
              </div>
            </div>
             <div class="row mt-3">
              <div class="col-sm-3">Address:</div>
              <div class="col-sm-9 float-right text-right" v-html="resolveAddress(optional(user, 'employee.data.address.data', {}))">
         
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 mt-4">
        <div class="card w-70">
          <div class="card-header no-right-padding-card">
            <span class="pt-2">Employment Info</span>
            <router-link :to="{ name: 'users-edit' }">
              <span class="btn-icon-sm float-right">
                <svg
                  width="20"
                  height="20"
                  fill="#72809D"
                  viewBox="0 0 30 30"
                  xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                >
                  <path
                    d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
                  ></path>
                </svg>
              </span>
            </router-link>
            <router-link :to="{ name: 'users-edit' }" v-if="optional($auth.user(), 'org_role.role_id') == 1">
              <span class="btn-icon-sm float-right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#72809D"
                  viewBox="0 0 30 30"
                >
                  <path
                    d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"
                  ></path>
                </svg>
              </span>
            </router-link>
          </div>
          <div class="card-content p-3" v-if="employee.id">
            <div class="row">
              <div class="col-sm-6">Employee ID</div>
              <div class="col-sm-6 float-right text-right">
                {{ employee.id }}
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-6">Employee Type:</div>
              <div class="col-sm-6 float-right text-right">
                {{ employee.employee_type ? employee.employee_type.name : "N/A" }}
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-6">Date Added:</div>
              <div class="col-sm-6 float-right text-right">
                {{ toLocal(employee.created_at).format("lll") }}
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-3">Role:</div>
              <div class="col-sm-9 float-right text-right">
                {{ user.role ? user.role.name : "N/A" }}
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-6">Pay Rate:</div>
              <div class="col-sm-6 float-right text-right">
                {{ employee.default_currency && employee.default_currency.symbol }}
                {{ !employee.employee_type || employee.employee_type.id != 1 ? parseFloat(employee.hourly_rate || 0).toFixed(2) : parseFloat(employee.salary_rate || 0).toFixed(2) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 mt-4">
        <div class="card w-70">
          <div class="card-header no-right-padding-card">
            <span class="pt-2">Other Info</span>
            <router-link :to="{ name: 'users-edit' }">
              <span class="btn-icon-sm float-right">
                <svg
                  width="20"
                  height="20"
                  fill="#72809D"
                  viewBox="0 0 30 30"
                  xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                >
                  <path
                    d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
                  ></path>
                </svg>
              </span>
            </router-link>
            <router-link :to="{ name: 'users-edit' }" v-if="optional($auth.user(), 'org_role.role_id') == 1">
              <span class="btn-icon-sm float-right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#72809D"
                  viewBox="0 0 30 30"
                >
                  <path
                    d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"
                  ></path>
                </svg>
              </span>
            </router-link>
          </div>
          <div class="card-content p-3" v-if="employee.id">
            <div class="row">
              <div class="col-sm-6">Notes</div>
              <div class="col-sm-6 float-right text-right" v-html="optional(employee, 'notes', 'Currently unavailable')">
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 mt-4" v-if="primaryBank">
        <div class="card w-70">
          <div class="card-header no-right-padding-card">
            <span class="pt-2">BANKING INFO (primary)</span>
            <router-link :to="{ name: 'users-bank' }">
              <span class="btn-icon-sm float-right">
                <svg
                  width="20"
                  height="20"
                  fill="#72809D"
                  viewBox="0 0 30 30"
                  xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                >
                  <path
                    d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
                  ></path>
                </svg>
              </span>
            </router-link>
          </div>
          <div class="card-content p-3" v-if="employee.id">
            <div class="row">
              <div class="col-sm-6">Bank account</div>
              <div class="col-sm-6 float-right text-right">
                <span>{{optional(primaryBank, 'bank', '')}}</span>
                <span class="pl-1">{{optional(primaryBank, 'bank_address.country.name', '')}}</span>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">Account account</div>
              <div class="col-sm-6 float-right text-right">
                <span class="pl-1">{{optional(primaryBank, 'account_name', '')}}</span>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">Account number</div>
              <div class="col-sm-6 float-right text-right">
                <span class="pl-1">{{optional(primaryBank, 'account_number', '')}}</span>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">Routing number</div>
              <div class="col-sm-6 float-right text-right">
                <span class="pl-1">{{optional(primaryBank, 'routing_number', '')}}</span>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">Swift code</div>
              <div class="col-sm-6 float-right text-right">
                <span class="pl-1">{{optional(primaryBank, 'swift_code', '')}}</span>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">Bank address</div>
              <div class="col-sm-6 float-right text-right">
                <span class="pl-1" v-html="resolveAddress(primaryBank.bank_address)"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-lg-9 pl-5 col-md-12 float-right">
      <div class="tab-wrapper tab-wrapper-mobile">
        <ul class="tabs">
          <router-link :to="{ name: 'users-show' }">
            <li>Projects</li>
          </router-link>
          <router-link :to="{ name: 'users-show-tasks' }">
            <li>Tasks</li>
          </router-link>
        </ul>
        <ul class="bg-sky p-3 text-right">
          <li class="pr-3">
            <router-link
              :to="$route.name == 'users-show-tasks' ? { name: 'tasks-create' } : { name: 'projects-create' }"
              class="btn-action btn-table"
            >
              <button class="btn btn-ds btn-outline pl-3 pr-3">
                <small class="text-bold text-main">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#0a5685"
                    viewBox="0 0 30 30"
                  >
                    <path
                      d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z"
                    ></path>
                  </svg>
                  Create New
                </small>
              </button>
            </router-link>
          </li>
          <li class="pr-3">
            <button class="btn-action btn-table">
              <small class="text-bold text-main">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#0a5685"
                  viewBox="0 0 30 30"
                >
                  <path
                    d="M19 11h-14v-2h14v2zm0 2h-14v2h14v-2zm0 4h-14v2h14v-2zm3-11v16h-20v-16h20zm2-6h-24v24h24v-24z"
                  ></path>
                </svg>
                Edit List
              </small>
            </button>
          </li>
          <li class="pr-3">
            <button class="btn-action btn-table">
              <small class="text-bold text-main">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#0a5685"
                  viewBox="0 0 30 30"
                >
                  <path
                    d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
                  ></path>
                </svg>
                View all
              </small>
            </button>
          </li>
        </ul>
        <div class>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toLocal } from "@/helpers/date";
import {optional, resolveAddress} from "@/helpers/random";

export default {
  data() {
    return {
      milestone_acc: false,
      accordions: [
        { name: "project", visible: true },
        { name: "milestone", visible: false },
        { name: "tasks", visible: false },
        { name: "timetracking", visible: false },
        { name: "additional", visible: false },
      ],
    };
  },
  computed: {
    employee() {
      return this.$store.getters["employees/employee"];
    },
    user() {
      return this.$store.getters["employees/user"];
    },
    primaryBank() {
       let banks =  this.$store.getters["employees/bank_accounts"];
       return banks.find(s => s.is_primary);
    }
  },
  methods: {
    resolveAddress,
    toLocal,
    optional,
  },
};
</script>

