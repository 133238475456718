<template>
  <div class></div>
</template>

<script>
import handleError from "@/helpers/error";
import Literals from "@/helpers/auth/literals";

export default {
  created() {
    let route = this.$route.params;
    let data = {
      user_id: route.id,
      token: route.token,
    };
    this.$store
      .dispatch("global/verifyEmail", data)
      .then(s => {
        this.$router.push({name:'Login', params:{msg:Literals.VERIFIED_EMAIL}})
          // TODO: We don't want to auto-login after email verification
        // this.$auth.token("default_auth_token", s.data.token);
        // this.$auth.watch.loaded = true;
        // this.$auth.watch.authenticated = true;
          // this.$auth.refresh({
          //   makeRequest: true,
          //   params: {
          //     token: s.token
          //   }, // data: {} in axios
          //   headers: {
          //     Authorization: "Bearer " + s.data.token
          //   },
          //   success: function(s) {
          //       //this.$router.push({ name: "organization" });
          //       this.$auth.token("default_auth_token", s.data.token);
          //       this.$auth.watch.data = s.data.data;
          //       this.$auth.watch.loaded = true;
          //       this.$auth.watch.authenticated = true;
          //
          //       this.$router.push({name: "organization"});
          //
          //   },
          //   error: function() {},
          //   redirect: "/#/login"
          //   // etc...
          // });
          // this.$router.push({ name: "Login", query: { verified: true } });
      })
      .catch((err) => {
        handleError(this, err, Literals.VERIFIED_EMAIL_FAILURE)
      });
  }
};
</script>
