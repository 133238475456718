import actions from './actions';
import getters from './getters';
import mutations from './mutations';


export const reports = {
    namespaced: true,
    state: {
        reports: [],
        tables: [],
        cached: {}
    },
    actions,
    getters,
    mutations
}
