<template>
  <div>
    <project-modal ></project-modal>
  </div>
</template>
<script>
import ProjectModal from "@/components/Modals/Projects";
export default {
  title: 'New | Projects',
  components: { ProjectModal },
  data() {
    return {

    };
  }
};
</script>
