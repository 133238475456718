<template>
  <div>
    <transition name="slide-fade">
      <div class="filters-form" v-show="visibleFilter">
        <form class="d-form">
          <div class="row mt-3">
            <!-- <div class="col-sm">
              <h6 class="text-bold text-opacity-pale">Customer Name</h6>
              <div style="position:relative">
                <input
                  type="text"
                  v-model="params['company_name']"
                  @input="setFilters('company_name')"
                  class="form-control auth-input d-input"
                  placeholder="Company name"
                >
                <span class="close-icon" v-if="params && params.company_name">
                  <i
                    class="zmdi zmdi-close"
                    @click="params['company_name']='';setFilters('company_name')"
                  ></i>
                </span>
              </div>
            </div>

            <div class="col-sm">
              <h6 class="text-bold text-opacity-pale">Phone Number</h6>
              <div style="position:relative">
                <input
                  type="tel"
                  v-model="params['phone_number']"
                  name="customer_phone"
                  @input="setFilters('phone_number')"
                  class="form-control auth-input d-input"
                  placeholder="Customer phone"
                >
                <span class="close-icon" v-if="params && params.phone_number">
                  <i
                    class="zmdi zmdi-close"
                    @click="params['phone_number']='';setFilters('phone_number')"
                  ></i>
                </span>
              </div>
            </div> -->
            <!-- <div class="col-sm">
              <h6 class="text-bold text-opacity-pale">Email</h6>
              <div style="position:relative">
                <input
                  type="email"
                  v-model="params['email']"
                  name="customer_email"
                  @input="setFilters('email')"
                  class="form-control auth-input d-input"
                  placeholder="Customer email address"
                >
                <span class="close-icon" v-if="params && params.email">
                  <i class="zmdi zmdi-close" @click="params['email']='';setFilters('email')"></i>
                </span>
              </div>
            </div> -->
            <div class="col-sm">
              <h6 class="text-bold text-opacity-pale">Status</h6>
              <div style="position:relative">
                <multiselect
                  @input="setFilters('is_active')"
                  v-model="params['is_active']"
                  class="no-caret-select"
                  :custom-label="opt => statuses.find(x => x.id == opt).name"
                  :options="statuses.map(type=>type.id)"
                  :show-labels="false"
                >
                  <template slot="caret">
                    <span class="close-icon" v-if="params && params.is_active">
                      <i
                        class="zmdi zmdi-close"
                        @click="params['is_active']='';setFilters('is_active')"
                      ></i>
                    </span>
                    <div v-else>
                      <div class="multiselect__select">
                        <i class="zmdi zmdi-chevron-down pt-1" style="font-size: 20px"></i>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
            <div class="col-sm">
              <h6 class="text-bold text-opacity-pale">Source</h6>
              <div style="position:relative">
                <multiselect
                  @input="setFilters('source_id')"
                  v-model="params['source_id']"
                  class="no-caret-select"
                  :custom-label="opt => sources.find(x => x.id == opt).name"
                  :options="sources.map(type=>type.id)"
                  :show-labels="false"
                >
                  <template slot="caret">
                    <span class="close-icon" v-if="params && params.source_id">
                      <i
                        class="zmdi zmdi-close"
                        @click="params['source_id']='';setFilters('source_id')"
                      ></i>
                    </span>
                    <div v-else>
                      <div class="multiselect__select">
                        <i class="zmdi zmdi-chevron-down pt-1" style="font-size: 20px"></i>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>

            <div class="col-sm">
              <h6 class="text-bold text-opacity-pale">Industry</h6>
              <div style="position:relative">
                <multiselect
                  @input="setFilters('industry_id')"
                  class="no-caret-select"
                  :custom-label="opt => industries.find(x => x.id == opt).name"
                  :options="industries.map(type=>type.id)"
                  v-model="params['industry_id']"
                  :show-labels="false"
                >
                  <template slot="caret">
                    <span class="close-icon" v-if="params && params.industry_id">
                      <i
                        class="zmdi zmdi-close"
                        @click="params['industry_id']='';setFilters('industry_id')"
                      ></i>
                    </span>
                    <div v-else>
                      <div class="multiselect__select">
                        <i class="zmdi zmdi-chevron-down pt-1" style="font-size: 20px"></i>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>

            <div class="col-sm">
              <h6 class="text-bold text-opacity-pale">Created At Date (From)</h6>
              <div style="position:relative">
                <vc-date-picker
                  :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important; height:42px !important`,placeholder: `Start From date`}"
                  v-model="params['filter_start']"
                  @input="setFilters('filter_start');params['range_filter'] = 'created_at'"
                  name="end"
                />
                <span class="close-icon" v-if="params && params.filter_start">
                  <i
                    class="zmdi zmdi-close"
                    @click="params['filter_start']='';setFilters('filter_start')"
                  ></i>
                </span>
              </div>
            </div>
            <div class="col-sm">
              <h6 class="text-bold text-opacity-pale">- To</h6>
              <div style="position:relative">
                <vc-date-picker
                  :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important; height:42px !important`,placeholder: `End To date`}"
                  v-model="params['filter_end']"
                  @input="setFilters('filter_end');params['range_filter'] = 'created_at'"
                  name="end"
                />
                <span class="close-icon" v-if="params && params.filter_end">
                  <i
                    class="zmdi zmdi-close"
                    @click="params['filter_end']='';setFilters('filter_end')"
                  ></i>
                </span>
              </div>
            </div>
            <!--            <div class="col-sm-12 col-lg-2">-->
            <!--              <h6 class="text-bold text-opacity-pale">- To</h6>-->
            <!--              <vc-date-picker-->
            <!--                :input-props="{class:`d-input no-margin-top auth-input pl-3`,style: `margin-top:0px !important; height:42px !important`,placeholder: `End date`}"-->
            <!--                v-model="params['end_to']"-->
            <!--                @input="setFilters('end_to',params.end_to)"-->
            <!--                name="end"-->
            <!--              />-->
            <!--            </div>-->
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["params", "industries", "visibleFilter", "sources"],
  data() {
    return{
      statuses: [{ id: '1', name: "Active" }, { id: '0', name: "Inactive" }],
    }
  },
  methods: {
    setFilters(name) {
      this.$emit("setFilters", this.params, name);
    }
  }
};
</script>
