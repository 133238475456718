<template>
  <div class="home" :class="{ blur: !task.id }">
    <log-time
      v-if="showLogTime"
      @trigger="setTimeLog"
      @cancelTimeLog="cancelTimeLog"
      :showLogTime="showLogTime"
      :singleTask="task"
      :timeLog="timeLog"
      ref="delete"
    ></log-time>
    <log-expense
      v-if="task.id"
      @trigger="setExpenseLog"
      @cancelExpense="cancelExpense"
      :showExpenseForm="showExpenseForm"
      :expense="expense"
      :task="task"
      ref="delete"
    ></log-expense>
    <delete-modal
      @trigger="setDeleteState"
      @cancel="cancelDelete"
      @deleteAction="deleteItem"
      :showDelete="showDelete"
      :name="showDeleteName"
      ref="delete"
    ></delete-modal>
    <headers class="bg-ggray pb-5">
      <div slot="breadcrumbs">
        <breadcrumbs :items="breadCrumbs"></breadcrumbs>
      </div>
      <div  slot="title" class="d-flex flex-row">
        <div>
          <h6 class="h7 text-opacity-pale">
            <span>
              <i class="zmdi zmdi-tag" style="color: green"></i>
            </span>
            <span class="pl-1 text-bold">Task ID:</span>
            <span class="pl-1">#{{ task.id }}</span>
          </h6>
          <h2 style="position:relative">
            {{ task.name }}
          </h2>
          <div class="d-flex flex-row">
            <h6 class="h7 text-opacity">
              <span class="text-bold">Status:</span>
              {{ task.status && task.status.name }},
            </h6>
            <h6 class="h7 pl-3 text-opacity">
              <span class="text-bold">Date Created:</span>
              {{ toLocal(task.created_at).format("lll") }}
            </h6>
          </div>
        </div>
        <div>
           <h6 class="mb-0 ml-1 text-medium text-opacity-pale" style="font-size: 13px !important; color: black; opacity: 0.5">
              Priority
            </h6>
            <priority-badge style="z-index: 1000;" v-if="task && task.id" :id="task.priority_id" :task_id="task.id"></priority-badge>
        </div>
      </div>
      <div slot="action">
        <router-link :to="{ name: 'tasks-edit' }">
          <button class="btn btn-ds btn-outline pl-3 pr-3">
            <span class="pr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#72809D"
                viewBox="0 0 30 30"
              >
                <path
                  d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"
                ></path>
              </svg>
            </span>
            Edit Task
          </button>
        </router-link>
        <button
          @click="setDeleteState('Task', $route.params.id)"
          class="btn btn-ds btn-danger pl-3 ml-2 pr-3"
        >
          Delete Task
        </button>
      </div>
    </headers>

    <div class="container-fluid tab-wrapper">
      <div class="row">
        <div class="col-lg-6 float-left">
          <ul class="tabs">
            <router-link :to="{ name: 'task-show-full' }">
              <li>TASK INFORMATION</li>
            </router-link>
            <router-link
              :to="{ name: 'task-show-subtasks' }"
              v-if="!task.parent_task_id"
            >
              <li>
                SUBTASKS
                <span
                  class="badge ml-2"
                  style="background: #e61919; height: 15px; color: white"
                >
                  {{
                    task.subtasks &&
                    task.subtasks.length
                  }}
                </span>
              </li>
            </router-link>
            <router-link :to="{ name: 'task-show-timesheets' }">
              <li>TIME SHEETS</li>
            </router-link>
            <router-link :to="{ name: 'task-show-expenses' }">
              <li>EXPENSES</li>
            </router-link>
          </ul>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-4 col-sm-12 text-left">
          <h6 class="h7 text-opacity-pale">
            Total Hours Logged:
            <span class="text-opacity">{{ task.hours_logged }} hour(s)</span>
          </h6>
        </div>
        <div class="col-lg-8 col-sm-12 text-right">
          <button
            :disabled="timerExist"
            @click="setTimeLog"
            class="btn-action pr-3 pl-2 mr-2"
          >
            <span class="text-bold h7 text-opacity-pale">
              <span class="pr-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g fill="none" fill-rule="evenodd">
                    <rect width="24" height="24" fill="#FFF" rx="4"></rect>
                    <g
                      stroke="#72809D"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      transform="translate(5 3)"
                    >
                      <path
                        d="M11.233 5.641L12.656 4.219M12.375 3.938L12.938 4.5M6.75 3.938L6.75.563M8.438.563L5.063.563"
                      ></path>
                      <circle cx="6.75" cy="10.688" r="6.75"></circle>
                      <path
                        d="M6.75 6.188L6.75 6.188M6.75 6.188c-.155 0-.281.125-.281.28 0 .156.126.282.281.282.155 0 .281-.126.281-.281 0-.156-.126-.282-.281-.282M6.75 14.625c-.155 0-.281.126-.281.281 0 .156.126.281.281.281.155 0 .281-.125.281-.28 0-.156-.126-.282-.281-.282h0M2.531 10.406c-.155 0-.281.126-.281.281 0 .156.126.282.281.282.156 0 .281-.126.281-.281 0-.156-.125-.282-.28-.282h0M10.969 10.406c-.156 0-.281.126-.281.281 0 .156.125.282.28.282.156 0 .282-.126.282-.281 0-.156-.126-.282-.281-.282h0M3.767 7.425c-.114 0-.216.068-.26.173-.044.105-.02.226.06.307.11.11.289.11.399 0 .11-.11.11-.288 0-.397-.053-.053-.124-.083-.199-.083h0M9.733 13.39c-.075 0-.146.03-.199.082-.095.095-.11.244-.035.355.075.112.218.155.342.104.124-.052.194-.184.168-.315-.026-.132-.142-.226-.276-.226h0M3.767 13.39c-.114 0-.216.068-.26.173-.044.105-.02.226.06.307.072.07.175.099.272.073.098-.026.173-.102.2-.199.026-.097-.002-.2-.073-.272-.053-.053-.124-.082-.199-.082h0M9.932 7.506c-.053-.053-.124-.083-.2-.083h0c-.074 0-.145.03-.198.083-.11.11-.11.288 0 .398.11.11.288.11.398 0 .11-.11.11-.288 0-.398"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>
              Log Time
            </span>
          </button>
          <button @click="setExpenseLog" class="btn-action pr-3 pl-2">
            <span class="text-bold h7 text-opacity-pale">
              <span class="pr-1">
                <svg
                  height="24px"
                  version="1.1"
                  viewBox="0 0 24 24"
                  width="24px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Work-Page"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="24-Tasks-view,-detailed-task"
                      transform="translate(-1100.000000, -24.000000)"
                    >
                      <g
                        id="Group-2-Copy-2"
                        transform="translate(1100.000000, 24.000000)"
                      >
                        <rect
                          id="Rectangle"
                          fill="#FFFFFF"
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                          rx="4"
                        ></rect>
                        <g
                          id="accounting-bills"
                          transform="translate(0.000000, 4.000000)"
                          stroke="#72809D"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        >
                          <path
                            d="M4.125,3.5 C4.33210678,3.5 4.5,3.66789322 4.5,3.875 C4.5,4.08210678 4.33210678,4.25 4.125,4.25 C3.91789322,4.25 3.75,4.08210678 3.75,3.875 C3.75,3.66789322 3.91789322,3.5 4.125,3.5"
                            id="Path"
                          ></path>
                          <path
                            d="M16.875,8.75 C17.0821068,8.75 17.25,8.91789322 17.25,9.125 C17.25,9.33210678 17.0821068,9.5 16.875,9.5 C16.6678932,9.5 16.5,9.33210678 16.5,9.125 C16.5,8.91789322 16.6678932,8.75 16.875,8.75"
                            id="Path"
                          ></path>
                          <rect
                            id="Rectangle"
                            x="0.75"
                            y="0.5"
                            width="19.5"
                            height="12"
                            rx="1.5"
                          ></rect>
                          <circle id="Oval" cx="10.5" cy="6.5" r="3"></circle>
                          <path
                            d="M23.25,5 L23.25,14 C23.25,14.8284271 22.5784271,15.5 21.75,15.5 L5.25,15.5"
                            id="Path"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
              Log Expense
            </span>
          </button>
        </div>

        <div class="col-sm-12 mt-3 mb-5">
          <div class="row">
            <div class="col-sm-12 col-lg-7">
              <div class="card w-70 full-width">
                <router-view></router-view>
              </div>
            </div>
            <div class="col-sm-12 col-lg-5">
              <div class="card w-50 full-width">
                <div class="tab-wrapper">
                  <ul class="tabs">
                    <router-link :to="{ name: 'task-show-full' }">
                      <li>Comments</li>
                    </router-link>
                    <router-link :to="{ name: 'tasks-show-full-attachments' }">
                      <li>Attachments</li>
                    </router-link>
                  </ul>
                </div>

                <div class="row pl-5 pt-0 pr-5 pb-5">
                  <div class="col-sm-12 v-scroll-400">
                    <comments
                      v-show="$route.name == 'task-show-full'"
                    ></comments>
                    <attachments
                      v-show="$route.name == 'tasks-show-full-attachments'"
                    ></attachments>
                    <!-- <router-view></router-view> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.task-status-dots i {
  font-size: 7px;
}
</style>

<script>
import Headers from "@/components/Layouts/Headers";
import Breadcrumbs from "@/components/Breadcrumbs/Index";
import DeleteModal from "@/components/Modals/Delete";
import Comments from "@/components/Comments/Index";
import Attachments from "@/components/Attachments/Index";
import LogTime from "@/components/Modals/Time";
import LogExpense from "@/components/Modals/Expense";
import { toLocal } from "@/helpers/date";
import { timerExist, optional } from "@/helpers/random";
import PriorityBadge from "@/components/Badges/Priority";
export default {
  title() {
    return `Task #${this.$route.params.id} | Tasks`;
  },
  components: {
    Headers,
    DeleteModal,
    Breadcrumbs,
    Comments,
    LogTime,
    LogExpense,
    Attachments,
    PriorityBadge
  },
  data() {
    return {
      loading: false,
      showSelect: false,
      showAssigned: true,
      newEmployee: {},
      showDelete: false,
      showDeleteName: "",
      selectedDeleteId: "",
      loaded: false,
      showLogTime: false,
      showExpenseForm: false,
      taskStatusUpdating: false,
      currentTaskStatus: {},
      timeLog: {},
      expense: {},
    };
  },
  methods: {
    toLocal,
    optional,
    setTimeLog(timeLog) {
      this.timeLog = timeLog;
      this.showLogTime = true;
    },
    setExpenseLog(expense) {
      this.expense = expense;
      this.showExpenseForm = true;
    },
    setDeleteState(name, id) {
      this.showDelete = true;
      this.showDeleteName = name;
      this.selectedDeleteId = id;
    },
    cancelDelete() {
      this.showDelete = false;
    },
    cancelExpense(expense) {
      this.expense = expense;
      this.showExpenseForm = false;
    },
    cancelTimeLog() {
      this.timeLog = {};
      this.showLogTime = false;
    },
    deleteItem() {
      if (this.showDeleteName == "Task") {
        //delete task
        this.$store
          .dispatch("tasks/delete", this.selectedDeleteId)
          .then((s) => {
            this.$router.push({ name: "tasks-index" });
            this.$toast.success("🚮 Task Successfully Deleted", {
              position: "top-right",
              duration: 3000,
            });
            this.showDelete = false;
          });
      }
    },
  },
  computed: {
    timerExist,
    taskStatuses() {
      return this.$store.getters["global/task_statuses"];
    },
    task() {
      return this.$store.getters["tasks/task"];
    },
    breadCrumbs() {
      return [
        {
          text: "Home",
          to: { name: "home" },
        },
        {
          text: optional(this.task, 'project.data.name', optional(this.task, 'project.name', 'Project')),
          to: {
            name: "projects-show-general",
            params: { id: this.task.project_id },
          },
        },
        {
          text: `Task #${this.task.id}`,
          to: {
            name: "projects-show-general",
            params: { id: this.task.project_id },
          },
        },
      ];
    },
  },
  created() {
    Promise.all([
      this.$store.dispatch("tasks/getSingle", this.$route.params.id),
      this.taskStatuses.length ||
        this.$store.dispatch("global/getTaskStatuses"),
    ]).finally(() => {
      this.loaded = true;
      document.title = `${this.task.name} | Tasks | ${
        process.env.VUE_APP_NAME || "Leaf"
      }`;
      if (this.$route.query.trigger && this.$route.query.trigger == "timer") {
        this.showLogTime = true;
      }
    });
  },
};
</script>
