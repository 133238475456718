import axios from 'axios';
import {
    updatePending
} from "@/helpers/axiosIntercept";
// import { reject, resolve } from 'core-js/fn/promise';

const API = process.env.VUE_APP_BASE_URI;
export default {
    createUpdate({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/projects${data && data.id ? `/${data.id}` : ''}`;
            let method = data && data.id ? axios.put(url, data) : axios.post(url, data);
            method.then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_PROJECT', data);
                    resolve(data.id);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    createNote({commit},data) {
        return new Promise((resolve,reject) => {
            let url = data.id ? `${API}/projects/${data.project_id}/notes/${data.id}` : `${API}/projects/${data.project_id}/notes`;
            let method = data.id ? axios.put(url,data) : axios.post(url,data);
    
            method.then(({data: {data}}) => {
                resolve(data);
            })
            .catch(error => reject(error.response))
            .finally(() => updatePending(url))
        })
    },
    getNotes({commit},data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/projects/${data}/notes`;
            axios.get(url)
            .then(data => {
                resolve(data.data);
            })
            .catch(error=> {
                reject(error.response)
            })

        });
    },
    getSingleNote({commit},data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/projects/${data.project_id}/notes/${data.note_id}`;
            axios.get(url)
            .then(data => {
                resolve(data.data);
            })
            .catch(error=> {
                reject(error.response)
            })

        });
    },
    deleteNote({commit}, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/projects/${data.project_id}/notes/${data.note_id}`;
            axios.delete(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getSingle({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/projects/${id}?include=employees,manager,customer,status,timeLogs,expenses,invoices,milestones`
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_PROJECT', data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_PROJECT', {});
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    index({
        commit
    }, queryParams = {}) {
        return new Promise((resolve, reject) => {
            
            let url = `${API}/projects`;
            axios.get(url, {
                    params: queryParams
                })
                .then(({data}) => {
                    commit('SET_PROJECTS', data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_PROJECTS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    // /** Delete Project from table */
    delete({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/projects/${id}`
            axios.delete(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getMetrics({
        commit
    }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/projects/metrics`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_METRICS', data);
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getMilestones({
        commit
    }, project_id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/projects/${project_id}/milestones`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_MILESTONES', data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_MILESTONES', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getMilestone({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/milestones/${id}`;
            axios.get(`${API}/milestones/${id}`)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_MILESTONE', data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_MILESTONE', {});
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getTasks({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/projects/${id}/tasks`
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_TASKS', data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_TASKS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getExpenses({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = id ? `${API}/projects/${id}/expenses` : `${API}/expenses`;

            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_EXPENSES', data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_EXPENSES', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getTimeLogs({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/projects/${id}/timelogs`;
            axios.get(url)
                .then((data) => {
                    commit('SET_TIME_LOGS', data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_TIME_LOGS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    //Delete Task from Specified Project
    deleteMilestone({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/milestones/${id}`;
            axios.delete(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    createUpdateMilestone({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/milestones${data && data.id ? `/${data.id}` : ''}`;
            let method = data && data.id ? axios.put(url, data) : axios.post(url, data);
            method.then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data.id);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getCollaborators({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/projects/${id}/collaborators`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_COLLABORATORS', data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_COLLABORATORS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    getInvites({
        commit
    }, project_id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/project_invites/${project_id}?include=project,creator,status`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_COLLABORATORS', data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_COLLABORATORS', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    createUpdateInvite({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/project_invites${data && data.id ? `/${data.id}` : ''}`;
            let method = data && data.id ? axios.put(url, data) : axios.post(url, data);
            method.then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data.id);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    createUpdateCollaborator({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/projects/${data.project_id}/collaborators${data && data.id ? `/${data.id}` : ''}`;
            let method = data && data.id ? axios.put(url, data) : axios.post(url, data);
            method.then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data.id);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getInvite({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/project_invites/${id}?include=project,creator,status`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_INVITE', data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_INVITE', {});
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    createUpdateOpportunity({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/opportunities${data && data.id ? `/${data.id}` : ''}`;
            let method = data && data.id ? axios.put(url, data) : axios.post(url, data);
            method.then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_OPPORTUNITY', data);
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    getOpportunity({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/opportunities/${id}?include=customer,managerUser,employee,opportunity_stage,billing_type,currency`;
            axios.get(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    commit('SET_OPPORTUNITY', data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_OPPORTUNITY', {});
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
    deleteOpportunity({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/opportunities/${id}`
            axios.delete(url)
                .then(({
                    data: {
                        data
                    }
                }) => {
                    resolve(data);
                })
                .catch(error => reject(error.response))
                .finally(() => updatePending(url));
        });
    },
    collaborating({
        commit
    }, queryParams = {}) {
        return new Promise((resolve, reject) => {
            
            let url = `${API}/projects/collaborating`;
            axios.get(url, {
                    params: queryParams
                })
                .then(({data}) => {
                    commit('SET_PROJECTS_COLLABORATING', data);
                    resolve(data);
                })
                .catch(error => {
                    commit('SET_PROJECTS_COLLABORATING', []);
                    reject(error.response)
                })
                .finally(() => updatePending(url));
        });
    },
}