<template>
    <div>
        <bank-modal v-if="bank.id" :bankAccount="bank"></bank-modal>
    </div>
</template>
<script>
    import BankModal from "@/components/Modals/Banks";
    export default {
        title: `Edit | Banks`,
        components: { BankModal },
        computed : {
            bank() {
               return this.$store.getters['employees/bank_account'] || this.$store.getters['employees/bank_accounts'].find((s) => s.id == this.$route.params.bid);
            }
        },
        created() {
            this.$store.dispatch('employees/getBankAccount', this.$route.params.bid)
        }
    };
</script>
