<template>
  <div class="home">
    <headers>
      <div slot="breadcrumbs">
        <breadcrumbs :items="breadCrumbs"></breadcrumbs>
      </div>
      <div slot="title">
        <h2>Billing & Subscriptions</h2>
      </div>
      <div slot="action">
        <!-- <router-link :to="{ name: 'reporting-create' }">
          <button class="btn btn-ds">
            <span class="pr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  fill="#FFF"
                  d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 4c-.552 0-1 .448-1 1v2H5c-.552 0-1 .448-1 1s.448 1 1 1h2v2c0 .552.448 1 1 1s1-.448 1-1V9h2c.552 0 1-.448 1-1s-.448-1-1-1H9V5c0-.552-.448-1-1-1z"
                ></path>
              </svg>
            </span>
            Upgrade
          </button>
        </router-link> -->
      </div>
    </headers>

    <div class="row p-5 pt-0 pr-0">
      <div class="col-md-3 mt-5 mr-0">
        <b-list-group>
          <b-list-group-item
            class="d-flex align-items-center"
            :to="{ name: 'subcriptions-active' }"
            ><h6 class="text-bold">
              My Active Subscription
            </h6></b-list-group-item
          >
          <b-list-group-item :to="{ name: 'subscriptions-cards' }"
            ><h6 class="text-bold">
              Cards & Payment methods
            </h6></b-list-group-item
          >
          <b-list-group-item :to="{ name: 'subscriptions-history' }"
            ><h6 class="text-bold">Transaction History</h6></b-list-group-item
          >
        </b-list-group>
      </div>
      <div class="col-md-9 ml-0">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.list-group .router-link-exact-active {
  background: #0a5685;
  color: white !important;
}
.subs {
  background: white;
  width: 60%;
  border-radius: 10px;
}
.receipt {
  width: 30%;
  background: white;

  .receipt__header {
    border-bottom: 2px dashed #f0f3f3;
  }
  .receipt__info ul li:nth-child(2n) {
    background: #f0f3f3;
  }
}
.subs__in {
  background: #f0f3f3;
  border-radius: 10px;

  .subs-icon {
    height: 50px;
    width: 50px;
    background: white;
    border-radius: 50px;
    box-shadow: 0 7px 30px 0 rgba(217, 217, 217, 0.36);
    font-size: 25px;
  }
  .subs-title {
    width: 70%;
  }
  button.no-sub {
    background: transparent;
    border: none;
  }
}
</style>
<script>
import Headers from "@/components/Layouts/Headers";
import Breadcrumbs from "@/components/Breadcrumbs/Index";
export default {
  components: { Headers, Breadcrumbs },
  data() {
    return {
      breadCrumbs: [
        {
          text: "Home",
          href: "#",
        },
        {
          text: "Billing & Subscriptions",
          active: true,
        },
      ],
    };
  },
};
</script>