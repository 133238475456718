<template>
  <div class="stripe-modal" id="expenseModal" :class="{blur: !loaded}">
    <div class="stripe-modal__wrapper">
      <div
        class="stripe-modal__header"
        style="display: flex; justify-content: space-between"
      >
        <h2 class="pl-1 pt-3 pr-3 f-24">Add Seats</h2>
        <button type="button" class="close" @click="close" style="width: 30%">
          Cancel
          <i class="zmdi zmdi-close-circle pl-2" style="font-size: 20px"></i>
        </button>
      </div>
      <div v-show="!fetching">
        <div class="pt-4 pl-3 pr-3 text-right">
          <button
            style="
              background: #0a5685;
              color: white;
              border: none;
              height: 30px;
              font-size: 12px;
              border-radius: 20px;
            "
            @click="() => { mode = mode == 'select' ? 'card' : 'select'; card_method = '';}"
            class="pl-3 pr-3"
          >
            <span>{{ mode == "select" ? "+ Add Card" : "Select Cards" }}</span>
          </button>
          <multiselect
            class="no-caret-select mt-4 pb-4"
            v-show="mode == 'select'"
            :options="cards"
            track-by="last4"
            v-model="card_method"
            :custom-label="customCards"
            placeholder="Select Payment Method"
            :show-labels="false"
          >
            <template slot="caret">
              <div class="multiselect__select">
                <i
                  class="zmdi zmdi-chevron-down pt-1"
                  style="font-size: 20px"
                ></i>
              </div>
            </template>
            <template slot="singleLabel" slot-scope="props">
              {{ props.option.card && props.option.card.brand.toUpperCase() }}
              Card - ending with
              {{ props.option.card && props.option.card.last4 }}
            </template>
          </multiselect>
        </div>
        <div class="pt-4 pb-4 pl-3 pr-3" v-show="mode !== 'select'">
          <div id="card-element" class="form-control"></div>
        </div>
        <div
          class="
            pt-3
            pb-3
            pl-3
            pr-2
            d-flex
            flex-row
            justify-content-between
            align-items-center
            border-top
          "
        >
          <h6 class="text-opacity text-bold">Selected Plan</h6>
          <h6 class="text-opacity-pale text-bold">{{license.name}}</h6>
        </div>
        <div
          class="
            d-flex d-form
            mt-1
            pl-3
            pr-3
            flex-row
            justify-content-between
            border-top
            pt-3
            align-items-center
          "
        >
          <h6 class="text-opacity text-bold">Number of Seats</h6>
          <input
            type="number"
            name="no_of_seats"
            v-model="body.quantity"
            v-validate="'required'"
            class="form-control w-50 auth-input d-input"
            placeholder="Enter number of seats"
            data-vv-as="Account number"
          />
        </div>
        <div
          class="
            d-flex
            flex-row
            mt-4
            justify-content-between
            border-top
            pt-3
            align-items-center
          "
        >
          <h6 class="text-opacity text-bold">Amount</h6>
          <h6 class="text-opacity-pale text-bold">{{parseFloat(license.monthly_cost || 0).toFixed(2)}} monthly</h6>
        </div>
      </div>
      <div class="pt-4 pl-4 pr-3" v-show="fetching">
        <h6>Fetching Cards...</h6>
      </div>
      <footer style="background: #f0f4f8">
        <div>
          <button
            type="button"
            class="btn btn-primary text-main"
            style="background: transparent"
            @click="close"
          >
            Cancel
          </button>
          <button
            class="btn btn-primary ml-3"
            @click="subscribe"
            :disabled="loading || !body.quantity"
          >
            <div v-if="!loading">{{ "Continue " }}</div>
            <div
              v-else
              class="spinner-grow spinner-grow-sm text-light"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import subscription from "@/mixins/subscription";
import {optional} from "@/helpers/random";
import {numberWithCommas} from "@/helpers/number";
import moment from 'moment';

export default {

  mixins: [subscription],
  data() {
    return {
      body: {
        quantity: "",
      },
      intentData: {},
      cards: [],
      card: "",
      mode: "select",
      card_method: "",
      fetching: true,
      elements: "",
      stripeInit: "",
      loading: false,
      loaded: false,
      stripe: "",
      license: {}
    };
  },
 
  mounted() {
   
    Promise.all([
      this.fetchCards(),
      this.subscription || this.fetchSubscription(),
      this.fetchLicense(),
      ]).then(() => {
      this.fetching = false;
      this.loaded = true;
      
    });
  },
  methods: {
    numberWithCommas,
    loadStripe,
    close() {
      this.$emit("close");
    },
    fetchSubscription() {
       this.fetching = true;
      this.$store.dispatch("global/getStripeKey").then((s) => {
        let key = optional(s, 'data.data.stripe_key');
      
        this.intentData = s;
     
        if (key) {
           this.fetching = true;
          this.stripeInit = loadStripe(key);
          this.stripeInit.then((s) => {
            this.elements = s.elements();
            this.card = this.elements.create("card");

            this.card.mount("#card-element");
             this.fetching = false;
          });
          
        }
         this.fetching = false;
      });
    },
    fetchCards() {
       this.fetching = true;
      this.$store.dispatch("global/getStripeKey").then((s) => {
        this.cards = optional(s, 'data.data.payment_methods');
        this.fetching = false;
      });
    },
    fetchLicense() {
       this.fetching = true;
      this.$store.dispatch("global/getLicense").then((s) => {
        this.license = this.$store.getters['global/license']
        this.fetching = false;
      });
    },
   
    customCards({ card }) {
      return `${card && card.brand.toUpperCase()} Card - ending with ${
        card.last4
      }`;
    },
    subscribe() {
      let totalAmount = parseFloat(Number(this.license.monthly_cost) * Number(this.body.quantity)).toFixed(2);
      let totalSeats = Number(optional(this.subscription, 'quantity', 0)) + Number(this.body.quantity);
      let nextDue = moment().add(1, 'month').format('ll');
      let nextTotal = parseFloat(Number(this.license.monthly_cost) * totalSeats).toFixed(2)
      let r = confirm(`You will be charged $${numberWithCommas(totalAmount)} today for ${this.body.quantity} additional seats bringing you up to a total of ${totalSeats} seats. Your next payment of $${numberWithCommas(nextTotal)} will be due on ${nextDue}.`);
      if(!r) {
        return;
      }
      this.loading = true;
      let url = this.$baseApi + "/organizations/subscribe";
      this.loading = true;

      this.body["cs"] = optional(this.intentData, 'data.data.intent.client_secret');
      
      if (this.card_method) {
        this.payWithoutIntent();
      } else {
        this.pay();
      }
    },
    async payWithoutIntent() {
      let token = this.card_method.id;
      let url = this.$baseApi + "/organizations/subscribe";
      let data = {
        quantity: this.body.quantity,
        token,
      };
      this.$http
        .post(url, data)
        .then((s) => {
          this.$toast.success("Seats added successfully!", {
            position: "top-right",
            duration: 3000,
          });
          // this.$auth.fetch();
          this.$emit("refreshSubscription");

          this.close();
          this.$router.go(0);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async pay() {
      let vm = this;
      this.stripeInit.then((stripe) => {
        stripe
          .confirmCardSetup(this.body && this.body.cs, {
            payment_method: {
              card: this.card,
              billing_details: {
                name:
                  this.$auth.user().first_name +
                  " " +
                  this.$auth.user().last_name,
              },
            },
          })
          .then(function (result) {
            if (result && result.error) {
              vm.$toast.error(result.error.message, {
                position: "top-right",
                duration: 7000,
              });
            } else {
              let token = result.setupIntent.payment_method;
              let url = vm.$baseApi + "/organizations/subscribe";
              let data = {
                quantity: vm.body.quantity,
                token,
              };
              vm.$http
                .post(url, data)
                .then((s) => {
                  vm.$toast.success("Seats added successfully!", {
                    position: "top-right",
                    duration: 3000,
                  });

                  vm.$emit("refreshSubscription");
                  vm.close();
                  vm.$router.go(0);
                })
                .finally(() => {
                  vm.loading = false;
                });
            }
          })
          .catch(function (error) {
            vm.$toast.error(error.message, {
              position: "top-right",
              duration: 7000,
            });
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.stripe-modal {
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.24);
  width: 100vw;
  left: 0;
  top: 0;

  z-index: 2;

  &__wrapper {
    background: white;
    width: 30vw;
    height: 420px;
    border-radius: 10px;
    margin: 70px auto;
    position: relative;
  }
  &__header {
    padding-left: 30px !important;
    padding-right: 30px;
    padding-top: 10px;
  }
  footer {
    background: rgb(240, 244, 248);
    /* position: absolute; */
    /* bottom: 0; */
    height: auto;
    padding: 0.75rem;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    width: inherit;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
</style>